import { useEffect, useRef, useState, FC } from 'react'
import { useI18n } from 'builder/hooks/useI18n'

import AddCardButton from 'builder/components/AddCardButton'
import { SkillAssistant } from 'builder/components/SkillAssistant'

import SectionTitle from 'builder/components/SectionTitle'
import { TunerSectionTooltips } from 'builder/components/Tuner'
import DialogModal from 'builder/components/DialogModal'
import { SectionNames } from 'builder/modules/resumeEditor'
import { TemplateName } from '../Helper/constants'
import { ReferenceExtra } from './ReferenceExtra'
import { SkillsExtra } from './SkillsExtra'

import { SectionHint } from './SectionHint'
import { SectionHeader } from './SectionHeader'
import { SectionContainer, SectionContent } from './styles'
import { SectionComponentProps } from './types'
import { SectionBodyFoldableContainer } from './SectionBodyFoldableContainer'
import { SectionHeaderFoldableContainer } from './SectionHeaderFoldableContainer'

export const NewSection: FC<SectionComponentProps> = props => {
  const {
    triggerScroll,
    setContentOffset,
    item,
    isDragging,
    onAdd,
    template,
    skills = [],
    locale,
    position,
    hideSkillLevel,
    updateSimpleField,
    onRename,
    onDelete,
    children,
    isFoldableSectionsEnabled,
    onToggle,
    isOpened,
  } = props
  const {
    isCustom,
    id,
    title,
    customTitle,
    hintText,
    addButtonText,
    single,
    sortable,
    removable,
    renamable,
    items,
    badgeComponents,
  } = item
  const [isModalOpen, setIsModalOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const { i18n } = useI18n()
  const hideExperienceLevel = template === TemplateName.Prague
  const stringSectionId = `${id}`

  useEffect(() => {
    if (triggerScroll && ref.current) {
      setContentOffset?.(ref.current.offsetTop)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerScroll])

  const handleAddCard = () => {
    onAdd?.(item.id)
  }

  const handleAddSkill = (skill: string) => {
    const options = {
      position: 'before' as const,
      fields: {
        skill,
      },
    }
    onAdd?.('skills', options)
  }

  const handleRename = (value: string | null) => {
    onRename?.({ id: item.id, value })
  }

  const handleDelete = () => {
    setIsModalOpen(true)
  }

  const handleModalConfirm = () => {
    setIsModalOpen(false)
    onDelete?.(item.id, { sortId: item.sortId, isCustom: item.isCustom })
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
  }

  const handleToggle = () => {
    onToggle?.(id)
  }

  return (
    <SectionContainer
      isDragging={isDragging}
      ref={ref}
      className="content"
      value={stringSectionId}
      {...{ isFoldableSectionsEnabled }}
    >
      <DialogModal
        title={i18n.t('builder.resume_editor.delete_section')}
        text={i18n.t('builder.resume_editor.sure_to_delete_section')}
        primaryButtonText={i18n.t('builder.resume_editor.delete')}
        secondaryButtonText={i18n.t('builder.resume_editor.cancel')}
        isOpen={isModalOpen}
        primaryAction={handleModalConfirm}
        secondaryAction={handleModalCancel}
        closeAction={handleModalCancel}
      />
      {(title || customTitle) && (
        <SectionHeader {...{ isFoldableSectionsEnabled, isOpened, onClick: handleToggle }}>
          <SectionTitle
            {...{
              id,
              title,
              customTitle,
              sortable,
              renamable,
              removable,
              isDragging,
              isCustom,
              badgeComponents,
              onRename: handleRename,
              onDelete: handleDelete,
              isFoldableSectionsEnabled,
              isOpened,
            }}
          />
          <SectionHeaderFoldableContainer {...{ isFoldableSectionsEnabled, isOpened, isDragging }}>
            {hintText && <SectionHint>{hintText}</SectionHint>}
            {id === SectionNames.references && <ReferenceExtra />}
            {id === SectionNames.skills && !!items?.length && !hideExperienceLevel && (
              <SkillsExtra hideSkillLevel={hideSkillLevel} updateSimpleField={updateSimpleField} />
            )}
            {id === SectionNames.skills && (
              <SkillAssistant
                onAddSkill={handleAddSkill}
                skills={skills}
                locale={locale}
                position={position}
              />
            )}
          </SectionHeaderFoldableContainer>
        </SectionHeader>
      )}
      <SectionBodyFoldableContainer {...{ isFoldableSectionsEnabled, isOpened, isDragging }}>
        <SectionContent isDragging={isDragging}>
          {children}
          {onAdd && !single && <AddCardButton onAdd={handleAddCard} text={addButtonText} />}
        </SectionContent>

        <TunerSectionTooltips sectionId={stringSectionId} />

        {item.contentAfter}
      </SectionBodyFoldableContainer>
    </SectionContainer>
  )
}
