import { appTypography } from '@rio/typography'
import { css, CSSObject } from 'styled-components'

export const FontWeights = {
  Regular: css({ fontWeight: 400 }),
  Medium: css({ fontWeight: 500 }),
  DemiBold: css({ fontWeight: 600 }),
  Bold: css({ fontWeight: 700 }),
}

// TODO: Automatically map all `appTypography` object values into styled-component-compatible ones
export const Typography = {
  XL: css(appTypography.XL),
  L: css(appTypography.L),
  M: css(appTypography.M),
  S: css(appTypography.S),
  Subhead: css(appTypography.Subhead),
  Body: css(appTypography.Body),
  Caps: css(appTypography.Caps as CSSObject),
  Caption: css(appTypography.Caption),
  Tiny: css(appTypography.Tiny),
  ButtonM: css(appTypography.ButtonM),
  ButtonS: css(appTypography.ButtonS),
  ButtonV2M: css(appTypography.ButtonV2M),
  TextS: css(appTypography.TextS),
  TextScore: css(appTypography.TextScore),
}

export default Typography
