import { Job } from '../../JobSearchResult/types'
import { hasHTMLContent } from '../../utils'
import { Container, Body } from './styles'

interface Props {
  job: Job
  title: React.ReactElement
}
export const JobSummary = ({ title, job }: Props) => {
  return (
    <Container>
      {title}
      <Body
        isHTMLContent={hasHTMLContent(job.description)}
        dangerouslySetInnerHTML={{
          __html: job.description,
        }}
      />
    </Container>
  )
}
