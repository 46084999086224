import styled, { css } from 'styled-components'
import Sizes from 'builder/styles/sizes'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { LayoutType } from './types'

interface Props {
  layout?: LayoutType
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: flex-start;
  gap: ${Sizes.S};
  padding: ${Sizes.M};
  width: 100%;
  background-color: ${Colors.Blue10};
  border-radius: ${Sizes['2XS']};
  box-shadow: 0px 2px 4px 0px ${hexToRgba(Colors.Blue80, 0.08)},
    0px 1px 2px 0px ${hexToRgba(Colors.Blue80, 0.12)},
    0px 0px 1px 0px ${hexToRgba(Colors.Blue80, 0.32)};

  ${Media.Laptop`
    flex-wrap: wrap;
    `}

  ${props => {
    switch (props.layout) {
      case 'vertical':
        return css`
          flex-direction: column;
          height: 100%;
          gap: 20px;
          padding: ${Sizes.L};
        `
    }
  }};
`

export const ImageWrapper = styled.aside<Props>`
  width: 107.31px;
  height: 62.09px;
  flex-shrink: 0;

  ${props => {
    switch (props.layout) {
      case 'vertical':
        return css`
          width: 119.53px;
          height: 96px;
        `
    }
  }};
`

export const DetailsWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  ${props => {
    switch (props.layout) {
      case 'vertical':
        return css`
          flex-grow: 1;
        `
    }
  }};
`

export const Title = styled.span`
  ${FontWeights.DemiBold};
  ${Typography.Body};
`

export const SubTitle = styled.span`
  word-wrap: break-word;
  word-break: break-word;
  ${FontWeights.Regular};
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const ButtonWrapper = styled.div<Props>`
  ${props => {
    switch (props.layout) {
      case 'vertical':
        return css`
          margin-top: auto;
        `
    }
  }};
`

export const LocationPin = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const TargetLocationImage = styled.img`
  width: 273.46px;
  height: 158.22px;
  margin-bottom: 36px;
  object-fit: contain;
`
