import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, LeftSide } from '../ResumeEditorView/styles'
import { actions as generalEditorActions } from 'builder/modules/generalEditor'
import { withRouter } from 'builder/hocs/withRouter'
import ResumeEditorGuest from 'builder/components/DocumentEditor/ResumeEditorGuest'
import PreviewPanelGuest from 'builder/components/PreviewPanel/PreviewPanelGuest'

class ResumeEditorViewGuest extends Component {
  static propTypes = {
    editorState: PropTypes.object.isRequired,
    fetchEditorPayloadRequest: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.fetchEditorPayloadRequest()
  }

  render() {
    const { editorState } = this.props
    const isDataLoaded = editorState.isLoaded

    return (
      <Container>
        <LeftSide>
          <ResumeEditorGuest editorState={editorState} isDataLoaded={isDataLoaded} />
        </LeftSide>
        <PreviewPanelGuest />
      </Container>
    )
  }
}

function mapStateToProps(state) {
  return {
    editorState: state.generalEditor,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchEditorPayloadRequest: () => dispatch(generalEditorActions.fetchEditorPayloadRequest()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResumeEditorViewGuest))
