/* eslint-disable @typescript-eslint/ban-ts-comment */
import { forwardRef, ForwardedRef, useState, useRef } from 'react'
import ReactAutosuggest, {
  AutosuggestPropsBase,
  AutosuggestPropsSingleSection,
  RenderSuggestionsContainerParams,
  SuggestionSelectedEventData,
} from 'react-autosuggest'
import snakeCase from 'lodash/snakeCase'
import { useDebouncedCallback } from 'builder/hooks/useDebouncedCallback'
import { makeCancelable } from './utils'
import { Container, Menu, MenuItem } from './styles'
import {
  AsyncAutosuggestProps,
  CancelableRequest,
  DefaultSuggestionType,
  RenderSuggestionComponentStateType,
  SendEventType,
  SuggestionType,
  ValueType,
} from './types'

const TRACKING_DELAY = 5000
const EMPTY_RESULT_DELAY = 1500

type HighlightType = {
  query: string
  text: string
}

const Highlight = ({ query, text }: HighlightType) => {
  const index = text.toLowerCase().indexOf(query.toLowerCase())

  if (index === -1) {
    return <>{text}</>
  }

  return (
    <>
      <span>{text.substring(0, index)}</span>
      <b>{text.substring(index, index + query.length)}</b>
      <span>{text.substring(index + query.length)}</span>
    </>
  )
}

const emptyResultTimeoutIdArray: NodeJS.Timeout[] = []
const AsyncAutosuggestComponent = <T extends SuggestionType = DefaultSuggestionType>(
  props: AsyncAutosuggestProps<T>,
  ref?: ForwardedRef<ReactAutosuggest<T, T>>,
) => {
  const {
    className,
    name,
    children,
    debounceInterval,
    getSuggestionValue = suggestion => (typeof suggestion.text === 'string' ? suggestion.text : ''),
    onChange,
    defaultSuggestions = [],
    inputProps,
    clearPreviousSuggestions = true,
    emptyStateElement,
    ...rest
  } = props
  const value = props.value || ''

  const [suggestions, setSuggestions] = useState<T[]>([])
  const [lastTrackedValue, setLastTrackedValue] = useState<string>('')
  const [lastRequest, setLastRequest] = useState<CancelableRequest<T[]>>()
  const [isEmptyResult, setIsEmptyResult] = useState(false)

  const setIsEmptyResultToTrue = () => {
    const timeoutId = setTimeout(() => {
      setIsEmptyResult(true)
    }, EMPTY_RESULT_DELAY)

    emptyResultTimeoutIdArray.push(timeoutId)
  }

  const renderDefaultSuggestionsContainer = ({
    containerProps,
    children,
  }: RenderSuggestionsContainerParams) => (
    <>
      <Menu {...containerProps} isEmpty={!children}>
        {children}
      </Menu>
      {value && isEmptyResult && emptyStateElement}
    </>
  )

  const sendEvent = ({ query, suggestions, value }: SendEventType<T>) => {
    const isSupported = 'navigator' in window && typeof navigator.sendBeacon === 'function'
    if (isSupported && name) {
      const params = {
        query,
        proposed_results: suggestions.map(getSuggestionValue),
        correct_response: value,
      }
      const body = JSON.stringify({ params })
      navigator.sendBeacon(`/api/reporting/suggester/event/${snakeCase(name)}`, body)

      if (process.env.NODE_ENV === 'development') {
        console.warn(`Autosuggest: Track ${name}`, params)
      }
    }
  }

  const debouncedSendEvent = useDebouncedCallback(sendEvent, TRACKING_DELAY)

  const track = (data: SendEventType<T>) => {
    // do not override event on blur after click and vice versa
    if (!data.value || lastTrackedValue === data.value) return

    setLastTrackedValue(data.value)
    debouncedSendEvent(data)
  }

  const cachedResponsesRef = useRef<Record<string, Promise<T[]> | undefined>>({})
  const fetchSuggestions = ({ value }: ValueType) => {
    lastRequest?.cancel()
    setIsEmptyResult(false)
    emptyResultTimeoutIdArray.forEach(id => clearTimeout(id))

    // to prevent api call on shouldRenderSuggestions with empty query
    if (value.length === 0) return

    const cachedResponse = cachedResponsesRef.current[value]
    if (cachedResponse) {
      cachedResponse.then(suggestions => {
        if (suggestions.length < 1) setIsEmptyResultToTrue()
        setSuggestions(suggestions)
      })
      return
    }

    const request = makeCancelable<T[]>(props.fetchItems(value))
    setLastRequest(request)
    const result = request.promise
    result.then(suggestions => {
      if (suggestions.length < 1) setIsEmptyResultToTrue()
      setSuggestions(suggestions)
    })
    cachedResponsesRef.current[value] = result
  }

  const debouncedFetchSuggestions = useDebouncedCallback(fetchSuggestions, debounceInterval || 350)

  const onSuggestionsClearRequested = () => {
    lastRequest?.cancel()
    if (clearPreviousSuggestions) {
      setSuggestions([])
    }
  }

  const onSuggestionSelected = (
    e: React.FormEvent<HTMLInputElement>,
    selected: SuggestionSelectedEventData<T>,
  ) => {
    // perform analytics
    track({ query: value, suggestions, value: selected.suggestionValue })

    if (selected.method === 'enter') e.preventDefault()

    // run custom handler if it was passed
    if (typeof props.onSuggestionSelected === 'function') {
      return props.onSuggestionSelected(e, selected)
    }
    // @ts-ignore
    const target: HTMLInputElement = {
      ...e.target,
      name: name || '',
      value: selected.suggestionValue,
    }
    // call `onChange` if it wasn't
    onChange({ ...e, target })
  }

  const onInputBlur = () => {
    // perform analytics
    track({ query: value, suggestions, value })
  }

  const renderDefaultSuggestion = (suggestion: T, state: RenderSuggestionComponentStateType) => {
    return (
      <MenuItem isHighlighted={state.isHighlighted}>
        {props.highlightedQuery ? (
          <Highlight query={state.query} text={getSuggestionValue(suggestion)} />
        ) : (
          getSuggestionValue(suggestion)
        )}
      </MenuItem>
    )
  }

  return (
    <Container className={className}>
      <ReactAutosuggest<T>
        ref={ref}
        renderSuggestionsContainer={renderDefaultSuggestionsContainer}
        renderSuggestion={renderDefaultSuggestion}
        {...rest}
        suggestions={value.length === 0 ? defaultSuggestions : suggestions}
        onSuggestionsFetchRequested={debouncedFetchSuggestions}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        renderInputComponent={children}
        inputProps={{
          name,
          value,
          // @ts-ignore "react-autosuggest" uses FormEvent instead of ChangeEvent for some reason
          onChange,
          onBlur: onInputBlur,
          ...inputProps,
        }}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={onSuggestionSelected}
      />
    </Container>
  )
}

// Type generic component wrapped with forwardRef
// https://fettblog.eu/typescript-react-generic-forward-refs/
export const AsyncAutosuggest = forwardRef(AsyncAutosuggestComponent) as <
  T extends SuggestionType = DefaultSuggestionType,
  S extends AutosuggestPropsBase<T> = AutosuggestPropsSingleSection<T>,
>(
  props: AsyncAutosuggestProps<T, S> & { ref?: ForwardedRef<ReactAutosuggest<T, T>> },
) => JSX.Element
