import styled from 'styled-components'

import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Button from 'builder/components/Button'
import Drawer from 'builder/components/Drawer/Drawer'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

type CloseButtonProps = { position?: number }

export const Container = styled.div`
  background-color: ${Colors.White};
  position: sticky;
  top: 80px;
  z-index: 600;
  display: flex;

  ${Media.Tablet`
    position: fixed;
    align-items: center;
  `}

  ${Media.Phone`
    display: none;
  `};
`

export const CloseIcon = styled(Icon20.ChevronLeft)`
  svg {
    fill: none;
  }
`

export const OpenIcon = styled(Icon20.ChevronRight)`
  svg {
    fill: none;
  }
`

export const LeftDrawer = styled(Drawer)`
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-bottom: 32px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  border-image-slice: 1;
  border-image-source: linear-gradient(
    ${Colors.White} 0%,
    ${Colors.Neutral15} 25%,
    ${Colors.Neutral15} 50%,
    ${Colors.Neutral15} 75%,
    ${Colors.White} 100%
  );

  &:hover {
    border-right: 1px solid ${Colors.Neutral15};
  }

  ${Container}:hover & {
    border-right: 1px solid ${Colors.Neutral15};
  }
`

export const SubMenuIcon = styled(Icon20.ChevronDown)`
  transform: rotate(-90deg);

  path {
    fill: ${Colors.Neutral50};
  }
`

export const Label = styled.div`
  padding: 2px 4px;
  border-radius: 4px;
  background: ${Colors.Blue50};
  color: ${Colors.White};
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
`

export const Badge = styled.div`
  padding: 2px 6px;
  border-radius: 34px;
  background: ${Colors.Blue50};
  color: ${Colors.White};
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
`

export const ExternalIcon = styled(Icon20.ArrowLeft)`
  transform: rotate(135deg);
`

const CloseButtonBase = styled(Button)<CloseButtonProps>``

export const CloseButton = styled(CloseButtonBase).attrs<CloseButtonProps>(props => ({
  style: props.position ? { top: `${props.position}px` } : undefined,
}))`
  position: absolute;
  right: -22px;
  opacity: 0;
  height: 22px;
  border-radius: 0 50% 50% 0;
  padding: 1px;

  ${Media.Tablet`
    opacity: 1;
  `}

  ${Container}:hover & {
    opacity: 1;
  }
`
