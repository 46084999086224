import { configureStore } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import { NavigateProps } from 'react-router-dom'
import { reducer as account } from './account'
import { reducer as invites } from './invites'
import { reducer as users } from './users'

// Add all reducers into the global store
export const store = configureStore({
  reducer: { account, invites, users },
})

// Build interfaces for the whole store automatically
// https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-state-type
export type Store = ReturnType<typeof store.getState>
export type Dispatch = typeof store.dispatch

export const ORG_PATH = '/orgs'

// Create a custom history instance to be able to navigate a user manually
export const history = createBrowserHistory()

export const navigate = (
  to: NavigateProps['to'],
  options?: { replace: NavigateProps['replace'] },
) => {
  const path = `${ORG_PATH}${to}`
  if (options?.replace) {
    return history.replace(path)
  }
  history.push(path)
}
