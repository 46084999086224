import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Wrapper = styled.div`
  background: ${Colors.White};
  width: 100%;
  height: 90vh;
  overflow: hidden;
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
`

export const ChildrenContainer = styled.div`
  display: flex;
  width: 100%;

  ${Media.Tablet`
    display: none;
  `}
`
