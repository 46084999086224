import { useEffect, useState } from 'react'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import axios, { AxiosError } from 'axios'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import Button from 'builder/components/Button'
import {
  JobSuggestionType,
  CompanySuggestionType,
  InterviewBehaviour,
} from 'builder/modules/interview/types'
import { actions } from 'builder/modules/interview/interviewModule'
import {
  ResponseCreateAccount,
  useCreateAccount,
} from 'builder/views/Interview/hooks/useCreateAccount'
import VideoSrc from 'builder/views/Interview/assets/interview-start-page-video.mp4'
import { useCreateMagicLink } from 'builder/views/Interview/hooks/useCreateMagicLink'
import { TextField } from 'builder/components/TextField'
import { generateQueryString } from 'builder/components/FindJob/utils'
import { isValidEmail, isValidName } from 'builder/views/Interview/utils/validation'
import Checkbox from 'builder/components/Checkbox'
import { CompanyName } from 'builder/views/Interview/hooks/useCompanyNames'
import { useUser } from 'builder/hooks/useUser'
import { JobTitleAsyncAutosuggest } from '../../JobTitleAsyncAutosuggest/JobTitleAsyncAutosuggest'
import {
  Title,
  LeftBottom,
  CustomEditor,
  Subtitle,
  InputSection,
  PillLabel,
  ProgressWrapper,
  ActiveProgressDot,
  ProgressDot,
  LeftTop,
  ButtonWrapper,
  BodyText,
  CheckWrapperDesktop,
  CheckWrapperMobile,
  Row,
  Left,
  Right,
  VideoWrapper,
  StyledVideo,
  Content,
} from './styles'
import { CompanyNameAsyncAutosuggest } from './components/CompanyNameAsyncAutosuggest/CompanyNameAsyncAutosuggest'
import { SignUpCopyright } from './components/SignUpCopyright/SignUpCopyright'

const TRANSLATION = 'builder.interview_prep_view.start_screen'

export const InterviewStartView = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { rawBehaviour }: Params = useParams()

  const behaviour = rawBehaviour || InterviewBehaviour.NERVOUS

  const [jobTitle, setJobTitle] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [selectedCompany, setSelectedCompany] = useState<CompanyName>()
  const [showError, setShowError] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const [selectedJobTitle, setSelectedJobTitle] = useState<JobSuggestionType>()
  const [selectedCheck, setSelectedCheck] = useState(true)

  const [showMagicLinkText, setShowMagicLinkText] = useState(false)

  const { mutateAsync: createAccount } = useCreateAccount()
  const { mutateAsync: createMagicLink } = useCreateMagicLink()

  const [step, setStep] = useState('initial')
  const [isLoading, setIsLoading] = useState(false)

  const user = useUser()

  useEffect(() => {
    if (user && user.currentJobTitleId) {
      navigate('/interview-preparation/dashboard')
    }

    trackInternalEvent('visit_test_interview', {
      label: 'interview_prep',
      parameter: behaviour || 'default',
    })
    dispatch(actions.setStartCurrentStep(1))
  }, [])

  const isMissingRequiredFields = () => {
    if (step === 'initial') {
      return !selectedJobTitle || !companyName
    }
    if (step === 'email' || step === 'magic_link') {
      return !isValidName(name) || !isValidEmail(email) || !selectedCheck
    }
    return false
  }

  const onJobSuggestionSelected = (suggestion: JobSuggestionType) => {
    setSelectedJobTitle(suggestion)
    setJobTitle(suggestion.text)
    dispatch(actions.setJobTitleId(suggestion.id))
    dispatch(actions.setJobTitle(suggestion.text))
  }

  const onCompanySuggestionSelected = (suggestion: CompanySuggestionType) => {
    setCompanyName(suggestion.text)
    setSelectedCompany(suggestion)
  }

  const generateRedirectPageLink = (includeStartPrefix = true) => {
    const params = {
      jobTitleId: selectedJobTitle?.id,
      jobTitleText: selectedJobTitle?.text,
      companyName: companyName || undefined,
      isRealCompany: !!selectedCompany,
      typeOfQuestions: behaviour,
      companyLogo: selectedCompany?.logo,
    }
    const queryStrings = generateQueryString(params)

    return `/interview-preparation${includeStartPrefix ? '/start' : ''}/ready?${queryStrings}`
  }

  const redirectToReadyPage = () => {
    return navigate(generateRedirectPageLink(false), { replace: true })
  }

  const createNewAccount = async () => {
    try {
      setIsLoading(true)
      const response = await createAccount({
        email,
        firstName: name,
      })

      if (response.success) {
        navigate(generateRedirectPageLink(), { replace: true })
        return true
      }
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        (error as AxiosError<ResponseCreateAccount>).response?.data?.errorCode === 'email_taken'
      ) {
        return false
      } else {
        throw error
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleMagicLink = async () => {
    const isNewAccount = await createNewAccount()

    trackInternalEvent('click_generate_interview', {
      label: 'interview_prep',
      parameter: 'log in',
    })

    if (!isNewAccount) {
      setIsLoading(true)

      await createMagicLink({
        email: email,
        redirect_to: `/app${generateRedirectPageLink()}`,
      })

      setIsLoading(false)

      navigate(`/interview-preparation/start/check-your-inbox?email=${email}`, {
        replace: true,
      })
    }
  }

  const onConfirmationAction = async () => {
    const hasMissingValue = isMissingRequiredFields()
    setShowError(hasMissingValue)

    if (hasMissingValue) {
      return
    }

    switch (step) {
      case 'initial':
        if (user) redirectToReadyPage()
        else {
          setStep('email')
          setShowError(false)
          trackInternalEvent('click_generate_interview', {
            label: 'interview_prep',
            parameter: 'welcome',
          })
        }
        return
      case 'email':
        setShowMagicLinkText(false)

        const isNewAccount = await createNewAccount()

        if (!isNewAccount) {
          setShowMagicLinkText(true)
        }

        trackInternalEvent('click_generate_interview', {
          label: 'interview_prep',
          parameter: 'email',
        })
    }
  }

  const renderBody = () => {
    switch (step) {
      case 'initial':
        return (
          <>
            <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
            <Subtitle>{i18n.t(`${TRANSLATION}.subtitle`)}</Subtitle>
            <CustomEditor>
              <InputSection>
                <JobTitleAsyncAutosuggest
                  jobTitle={jobTitle}
                  onChange={e => setJobTitle(e.target.value)}
                  onChangeSuggestion={onJobSuggestionSelected}
                  hasError={showError && selectedJobTitle === undefined}
                  trackingParameter="test"
                  placeholder={i18n.t(`${TRANSLATION}.placeholder_job_title`)}
                />
                <CompanyNameAsyncAutosuggest
                  companyName={companyName}
                  onChange={e => {
                    setCompanyName(e.target.value)
                    setSelectedCompany(undefined)
                  }}
                  onChangeSuggestion={onCompanySuggestionSelected}
                  hasError={showError && !selectedCompany}
                  placeholder={i18n.t(`${TRANSLATION}.placeholder_company`)}
                />
              </InputSection>
            </CustomEditor>
          </>
        )
      case 'email':
        return (
          <>
            <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
            <Subtitle>{i18n.t(`${TRANSLATION}.second_step_subtitle`)}</Subtitle>
            <CustomEditor>
              <InputSection>
                <TextField
                  label={i18n.t(`${TRANSLATION}.name`)}
                  onChange={e => setName(e.target.value)}
                  error={showError && !isValidName(name) && i18n.t(`${TRANSLATION}.error_name`)}
                />

                <TextField
                  label={i18n.t(`${TRANSLATION}.email`)}
                  type="email"
                  onChange={e => setEmail(e.target.value)}
                  error={showError && !isValidEmail(email) && i18n.t(`${TRANSLATION}.error_email`)}
                />

                {showMagicLinkText && (
                  <div>
                    <BodyText>{i18n.t(`${TRANSLATION}.email_registered`)}</BodyText>
                    <BodyText>{i18n.t(`${TRANSLATION}.sign_in_link`)}</BodyText>
                  </div>
                )}
              </InputSection>
            </CustomEditor>
          </>
        )
    }
  }

  return (
    <Content>
      <Row>
        <Left>
          <LeftTop>
            <PillLabel>{i18n.t(`${TRANSLATION}.sample_interview`)}</PillLabel>
            {renderBody()}
          </LeftTop>
          <LeftBottom>
            {step === 'email' && (
              <CheckWrapperMobile>
                <Checkbox
                  label={<SignUpCopyright />}
                  checked={selectedCheck}
                  onChange={() => setSelectedCheck(prevState => !prevState)}
                  invalid={showError && !selectedCheck}
                />
              </CheckWrapperMobile>
            )}
            <ButtonWrapper>
              <ProgressWrapper>
                <ActiveProgressDot />
                <ProgressDot />
              </ProgressWrapper>
              <Button
                onClick={showMagicLinkText ? handleMagicLink : onConfirmationAction}
                isLoading={isLoading}
                disabled={isLoading}
                isDisabled={showError && isMissingRequiredFields()}
              >
                {showMagicLinkText
                  ? i18n.t(`${TRANSLATION}.log_in`)
                  : i18n.t(`${TRANSLATION}.create_interview`)}
              </Button>
            </ButtonWrapper>

            {step === 'email' && (
              <CheckWrapperDesktop>
                <Checkbox
                  label={<SignUpCopyright />}
                  checked={selectedCheck}
                  onChange={() => setSelectedCheck(prevState => !prevState)}
                  invalid={showError && !selectedCheck}
                />
              </CheckWrapperDesktop>
            )}
          </LeftBottom>
        </Left>
        <Right>
          <VideoWrapper>
            <StyledVideo src={VideoSrc} key={0} autoPlay muted loop playsInline />
          </VideoWrapper>
        </Right>
      </Row>
    </Content>
  )
}
