import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useI18n } from 'builder/hooks/useI18n'
import { TextField } from 'builder/components/TextField'
import * as SimpleForm from 'builder/components/SimpleForm'
import { Warning } from './components/Warning'
import { suggestEmail } from './utils/suggestEmail'
import { validateEmail } from './utils/validateEmail'
import * as Styled from './styles'

type Payload = { email: string }

type Props = Omit<React.ComponentProps<typeof SimpleForm.Form>, 'defaultValue' | 'onSubmit'> & {
  defaultValue?: string
  allowAvailableOnly?: boolean
  autoFocus?: boolean
  placeholder?: string
  onSubmit: (payload: Payload) => void
}

export const SimpleEmailForm = ({
  defaultValue = '',
  placeholder,
  allowAvailableOnly,
  autoFocus,
  isPending,
  onSubmit,
  ...rest
}: Props) => {
  const { i18n } = useI18n()
  const [isValidating, toggleValidating] = useState(false)
  const [suggestion, setSuggestion] = useState<string | null>(null)
  const [haveSuggested, toggleSuggested] = useState(false)

  const form = useForm({
    defaultValues: { email: defaultValue },
  })

  // Rerender and call effects on field value update
  const email = form.watch('email')

  // Hide suggestion after user changed field value
  useEffect(() => {
    setSuggestion(null)
  }, [email])

  // Don't annoy with suggestions: show it just once
  useEffect(() => {
    if (suggestion) toggleSuggested(true)
  }, [suggestion])

  // Validate and check email before submitting the form
  const handleSubmit = async ({ email }: Payload) => {
    try {
      toggleValidating(true)

      const { message } = await validateEmail(email, { allowAvailableOnly })
      if (message) throw form.setError('email', { message })

      if (!haveSuggested) {
        const suggestion = await suggestEmail(email)
        if (suggestion) throw setSuggestion(suggestion)
      }

      onSubmit({ email })
    } finally {
      toggleValidating(false)
    }
  }

  // A tiny text under the text field
  const warning = useMemo(() => {
    if (!suggestion) return null
    return <Warning email={suggestion} onAgree={() => form.setValue('email', suggestion)} />
  }, [form, suggestion])

  return (
    <SimpleForm.Form
      {...rest}
      onSubmit={form.handleSubmit(handleSubmit)}
      isPending={isPending || isValidating}
      submitButtonText={haveSuggested ? i18n.t('builder.sign_up.confirm') : undefined}
    >
      <SimpleForm.Row>
        <TextField
          {...form.register('email', {
            required: i18n.t('builder.sign_up.field_is_required'),
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: i18n.t('builder.sign_up.invalid_email'),
            },
          })}
          type="email"
          label={i18n.t('builder.sign_up.email')}
          placeholder={placeholder}
          autoFocus={autoFocus}
          error={form.formState.errors.email?.message}
          warning={warning}
        />
      </SimpleForm.Row>

      {suggestion && (
        <Styled.AlertContainer>
          <Styled.AlertIcon />
          <Styled.AlertText>{i18n.t('builder.sign_up.you_made_typo')}</Styled.AlertText>
        </Styled.AlertContainer>
      )}
    </SimpleForm.Form>
  )
}
