import { useState } from 'react'
import { useI18n } from 'builder/hooks/useI18n'

import { Icon20 } from '../Icon'
import {
  CollapseItem,
  CookieCollapseHeader,
  CookieCollapseToggle,
  CookieSettingsModalPlaceholder,
  CheckboxToggle,
  CookieSettingsInput,
  CheckboxCheckmark,
  CookieCollapseBody,
  CookieCollapseBodyInner,
  MarketingLinksContainer,
} from './styles'
import { CookieInformation } from './types'

const facebookUrl = 'https://www.facebook.com/privacy/policy/' as const
const googleUrl = 'https://business.safety.google/privacy/' as const
const microsoftUrl = 'https://www.microsoft.com/en-gb/privacy/' as const

const CookieModalSetting = ({
  cookie,
  handleCookieToggle,
}: {
  cookie: CookieInformation
  handleCookieToggle: () => void
}) => {
  const { i18n } = useI18n()
  const [showBodyInfo, setShowBodyInfo] = useState<boolean>(false)

  return (
    <CollapseItem>
      <CookieCollapseHeader>
        <CookieCollapseToggle onClick={() => setShowBodyInfo(current => !current)}>
          <Icon20.ChevronRight className="collapse__arrow" />
          {i18n.t(`builder.cookie_settings.${cookie.phrase}.title`)}

          {cookie.value === 'essentialCookies' ? (
            <CookieSettingsModalPlaceholder>
              {i18n.t('builder.cookie_settings.always_active')}
            </CookieSettingsModalPlaceholder>
          ) : (
            <CheckboxToggle className="checkbox">
              <CookieSettingsInput
                type="checkbox"
                name="cookie-type"
                value={cookie.value}
                id={cookie.value}
                onClick={handleCookieToggle}
              />
              <CheckboxCheckmark className="checkbox__checkmark" />
            </CheckboxToggle>
          )}
        </CookieCollapseToggle>
      </CookieCollapseHeader>

      <CookieCollapseBody className={showBodyInfo ? 'is-open' : ''}>
        <CookieCollapseBodyInner>
          {cookie.value === 'marketingCookies' ? (
            <>
              <p>{i18n.t(`builder.cookie_settings.${cookie.phrase}.subtitle`)}</p>
              <MarketingLinksContainer>
                <a target="_blank" href={googleUrl} rel="noreferrer">
                  Google
                </a>
                <a target="_blank" href={facebookUrl} rel="noreferrer">
                  Facebook
                </a>
                <a target="_blank" href={microsoftUrl} rel="noreferrer">
                  Microsoft
                </a>
              </MarketingLinksContainer>
              <p>{i18n.t(`builder.cookie_settings.${cookie.phrase}.text`)}</p>
            </>
          ) : (
            <p>{i18n.t(`builder.cookie_settings.${cookie.phrase}.text`)}</p>
          )}
          <a href={`${window.location.host}/cookies`} target="_blank" rel="noreferrer">
            {i18n.t('builder.cookie_settings.learn_more')}
          </a>
        </CookieCollapseBodyInner>
      </CookieCollapseBody>
    </CollapseItem>
  )
}

export default CookieModalSetting
