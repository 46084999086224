import { useEffect, useState, useRef, Fragment } from 'react'

import * as React from 'react'
import { BlankProfilePicture, Resume } from '@rio/types'

import { trackInternalEvent } from '@rio/tracking'
import { AsyncAutosuggest, createSuggestionsApiFetcher } from 'builder/components/AsyncAutosuggest'
import EditorField from 'builder/components/EditorField'
import EditorRow from 'builder/components/EditorRow'
import Foldable from 'builder/components/Foldable'
import PhotoUploadField from 'builder/components/PhotoUploadField'
import SectionTitle from 'builder/components/SectionTitle'
import { SectionHeader } from 'builder/components/Section'
import { TunerSectionTooltips } from 'builder/components/Tuner'
import { TextField } from 'builder/components/TextField'

import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import FocusManager from 'builder/services/FocusManager'

import {
  selectors as editorSelectors,
  SectionNames,
  AvailableAISuggestions,
  UNBLOCKED_EDITOR_URL_PARAM,
} from 'builder/modules/resumeEditor'
import { AISuggestionsToggler } from 'builder/components//AiFeatures/Suggestions'
import { apiClient } from 'builder/modules/apiClient'
import { navigate } from 'builder/modules/navigate'
import { GuestResumeContext } from 'builder/context/GuestResumeContext'
import ErrorLogger from 'builder/services/ErrorLogger'
import {
  Container,
  ShowButton,
  ShowButtonText,
  ShowButtonIcon,
  AdditionalFields,
} from '../PersonalDetailsSection/styles'
import { validateEmail } from '../SimpleEmailForm/utils/validateEmail'
import { ValidateErrorCodes } from '../SimpleEmailForm/types'
import { SignUpModal } from './components/SignUpModal/SignUpModal'
import { StyledEditorField } from './styles'

const avatar = {
  blank: true,
  thumbUrl: null,
  mediumUrl: null,
  originalUrl: null,
  transform: null,
} as BlankProfilePicture

export const PersonalDetailsSectionGuest = () => {
  const { i18n } = useI18n()
  const templates = useTypedSelector(editorSelectors.templates)

  const container = useRef<HTMLDivElement | null>(null)
  const emailFieldRef = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const [areAdditionalFieldsVisible, setAdditionalFieldsVisibility] = useState(false)

  const {
    resume,
    updateResumeFields,
    toggleEmailRequiredError,
    showEmailRequiredError,
    setShowEmailRequiredError,
    resetContext,
  } = React.useContext(GuestResumeContext)
  // SUGGESTIONS NOT WORKING ON LOCALHOST
  const {
    email,
    locale = 'en',
    template,
    firstName,
    lastName,
    phoneNumber,
    city,
    address,
    birthDate,
    birthPlace,
    postalCode,
    position,
    countryName,
    drivingLicense,
    nationality,
    name,
  } = resume

  const currentTemplate = templates.find(t => t.id === template)
  const supportsPhoto = currentTemplate ? currentTemplate.supportsPhoto : false

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isConsentChecked, setIsConsentChecked] = React.useState(false)
  const [backendValidationMessage, setBackendValidationMessage] = React.useState('')
  const [isSignUpModalVisible, setIsSignUpModalVisible] = React.useState(false)

  const emailErrorMessage = showEmailRequiredError
    ? i18n.t('builder.sign_up.field_is_required')
    : backendValidationMessage || ''

  const onSignUpSubmit = async () => {
    if (!email) return

    setIsSubmitting(true)

    try {
      const { message, response } = await validateEmail(email, { allowAvailableOnly: true })

      if (response?.errorCode === ValidateErrorCodes.taken) {
        setIsSignUpModalVisible(false)
      }

      if (message) {
        setBackendValidationMessage(message)
        setIsSubmitting(false)
        return
      }

      const { data } = await apiClient.post(`/sign-up/register`, {
        signUp: {
          template,
          firstName,
          lastName,
          email,
          phone: phoneNumber,
          confirm_email: isConsentChecked,
          jobTitle: position,
          resumeName: name,
          countryName,
          city,
          address,
          postalCode,
          drivingLicense,
          birthPlace,
          birthDate,
          nationality,
          showPrefillResumeModal: false,
        },
      })

      if (data.success) {
        resetContext()
        navigate(`/resumes/${data.firstDocumentId}/edit?${UNBLOCKED_EDITOR_URL_PARAM}`, {
          replace: true,
        })
      } else {
        setIsSubmitting(false)
      }
    } catch (error) {
      ErrorLogger.log(error)
      setIsSubmitting(false)
    }
  }

  const onEmailChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setShowEmailRequiredError(0)
    setBackendValidationMessage('')
    updateResumeFields({ email: event.target.value })
  }

  const onSignupModalShown = () => {
    trackInternalEvent('show_sign_up_banner', { label: 'email' })
  }

  // Connect to FocusManager
  useEffect(() => {
    const focus = (name: string) => {
      const targetField = container.current?.querySelector<HTMLInputElement>(`[name=${name}]`)
      if (targetField) targetField.focus()
    }

    const focusableFieldNames: Array<keyof Resume> = ['position', 'email', 'phoneNumber']
    focusableFieldNames.forEach(name => FocusManager.subscribe(name, () => focus(name)))
    return () => focusableFieldNames.forEach(name => FocusManager.unsubscribe(name))
  }, [])

  useEffect(() => {
    setIsSignUpModalVisible(!!email)
  }, [email])

  useEffect(() => {
    if (showEmailRequiredError) {
      emailFieldRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [showEmailRequiredError])

  useEffect(() => {
    if (isSignUpModalVisible) {
      modalRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [isSignUpModalVisible])

  return (
    <Fragment>
      <Container ref={container}>
        {/* Section header */}
        <SectionHeader>
          <SectionTitle title={i18n.t('builder.resume_editor.personal_details')} />
        </SectionHeader>

        <EditorRow>
          {/* Job title */}
          <EditorField>
            <AsyncAutosuggest
              name="position"
              fetchItems={createSuggestionsApiFetcher('job-title', { limit: 4, locale })}
              value={position || ''}
              onChange={event => updateResumeFields({ position: event.target.value })}
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  placeholder={i18n.t('builder.resume_editor.job_title_placeholder')}
                  label={i18n.t('builder.resume_editor.job_title')}
                  hint={i18n.t('builder.resume_editor.job_title_hint')}
                  badge={<AISuggestionsToggler type={AvailableAISuggestions.jobTitle} />}
                />
              )}
            </AsyncAutosuggest>
          </EditorField>

          {/* Avatar */}
          <EditorField>
            <PhotoUploadField
              imageUrl={avatar.blank ? '' : avatar.thumbUrl}
              isUploading={false}
              isLocked={!supportsPhoto}
              onUpload={() => {}}
              onDelete={() => {}}
              onClick={toggleEmailRequiredError}
            />
          </EditorField>
        </EditorRow>

        <EditorRow>
          {/* First name */}
          <EditorField>
            <TextField
              label={i18n.t('builder.resume_editor.first_name')}
              value={firstName || ''}
              name="firstName"
              onChange={event => updateResumeFields({ firstName: event.target.value })}
            />
          </EditorField>

          {/* Last name */}
          <EditorField>
            <TextField
              label={i18n.t('builder.resume_editor.last_name')}
              value={lastName || ''}
              name="lastName"
              onChange={event => updateResumeFields({ lastName: event.target.value })}
            />
          </EditorField>
        </EditorRow>

        <EditorRow>
          {/* Email */}
          <StyledEditorField elementRef={emailFieldRef}>
            <TextField
              label={`${i18n.t('builder.resume_editor.email')}*`}
              type="email"
              value={email || ''}
              name="email"
              onChange={onEmailChange}
              error={emailErrorMessage}
            />
            <SignUpModal
              isVisible={isSignUpModalVisible}
              onSetIsConsentToggled={setIsConsentChecked}
              isConsentChecked={isConsentChecked}
              onSubmit={onSignUpSubmit}
              isSubmitting={isSubmitting}
              modalRef={modalRef}
              onShow={onSignupModalShown}
            />
          </StyledEditorField>

          {/* Phone */}
          <EditorField>
            <TextField
              label={i18n.t('builder.resume_editor.phone')}
              type="phone"
              value={phoneNumber || ''}
              name="phoneNumber"
              onChange={event => updateResumeFields({ phoneNumber: event.target.value })}
            />
          </EditorField>
        </EditorRow>

        <EditorRow>
          {/* Country */}
          <EditorField>
            <AsyncAutosuggest
              highlightedQuery
              name="countryName"
              fetchItems={createSuggestionsApiFetcher('country', { locale })}
              value={countryName || ''}
              onChange={event => updateResumeFields({ countryName: event.target.value })}
            >
              {inputProps => (
                <TextField {...inputProps} label={i18n.t('builder.resume_editor.country')} />
              )}
            </AsyncAutosuggest>
          </EditorField>

          {/* City */}
          <EditorField>
            <AsyncAutosuggest
              highlightedQuery
              fetchItems={createSuggestionsApiFetcher('city', {
                locale,
                country: countryName || '',
              })}
              name="city"
              value={city || ''}
              onChange={event => updateResumeFields({ city: event.target.value })}
            >
              {inputProps => (
                <TextField {...inputProps} label={i18n.t('builder.resume_editor.city')} />
              )}
            </AsyncAutosuggest>
          </EditorField>
        </EditorRow>

        {/* Additional fields */}
        <Foldable isOpen={areAdditionalFieldsVisible}>
          <AdditionalFields>
            <EditorRow>
              {/* Address */}
              <EditorField>
                <TextField
                  label={i18n.t('builder.resume_editor.address')}
                  value={address || ''}
                  name="address"
                  onChange={event => updateResumeFields({ address: event.target.value })}
                />
              </EditorField>

              {/* ZIP Code */}
              <EditorField>
                <TextField
                  label={i18n.t('builder.resume_editor.postal_code')}
                  value={postalCode || ''}
                  name="postalCode"
                  onChange={event => updateResumeFields({ postalCode: event.target.value })}
                />
              </EditorField>
            </EditorRow>

            <EditorRow>
              {/* Driving License */}
              <EditorField>
                <TextField
                  label={i18n.t('builder.resume_editor.driving_license')}
                  hint={i18n.t('builder.resume_editor.driving_license_hint')}
                  value={drivingLicense || ''}
                  name="drivingLicense"
                  onChange={event => updateResumeFields({ drivingLicense: event.target.value })}
                />
              </EditorField>

              {/* Nationality */}
              <EditorField>
                <AsyncAutosuggest
                  name="nationality"
                  fetchItems={createSuggestionsApiFetcher('nationality', { locale })}
                  value={nationality || ''}
                  onChange={event => updateResumeFields({ nationality: event.target.value })}
                >
                  {inputProps => (
                    <TextField
                      {...inputProps}
                      label={i18n.t('builder.resume_editor.nationality')}
                      hint={i18n.t('builder.resume_editor.nationality_hint')}
                    />
                  )}
                </AsyncAutosuggest>
              </EditorField>
            </EditorRow>

            <EditorRow>
              {/* Birth place */}
              <EditorField>
                <TextField
                  label={i18n.t('builder.resume_editor.birth_place')}
                  value={birthPlace || ''}
                  name="birthPlace"
                  onChange={event => updateResumeFields({ birthPlace: event.target.value })}
                />
              </EditorField>

              {/* Birth date */}
              <EditorField>
                <TextField
                  label={i18n.t('builder.resume_editor.birth_date')}
                  hint={i18n.t('builder.resume_editor.birth_date_hint')}
                  value={birthDate || ''}
                  name="birthDate"
                  onChange={event => updateResumeFields({ birthDate: event.target.value })}
                />
              </EditorField>
            </EditorRow>
          </AdditionalFields>
        </Foldable>

        {/* Additional fields expand button */}
        <ShowButton onClick={() => setAdditionalFieldsVisibility(value => !value)}>
          <ShowButtonText>
            {areAdditionalFieldsVisible
              ? i18n.t('builder.resume_editor.hide_details')
              : i18n.t('builder.resume_editor.show_details')}
          </ShowButtonText>
          <ShowButtonIcon data-open={areAdditionalFieldsVisible} />
        </ShowButton>

        <TunerSectionTooltips sectionId={SectionNames.details} />
      </Container>
    </Fragment>
  )
}
