import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import { ButtonSize, ButtonTheme } from '../../types'
import { BaseButtonV2 } from './BaseButtonV2'

const getThemeCss = (bgColor: string, hoverBgColor: string, activeBgColor: string) => css`
  background-color: ${bgColor};

  &:hover,
  &:focus-visible {
    background-color: ${hoverBgColor};
  }

  &:active {
    background-color: ${activeBgColor};
  }
`

const disabledCss = css`
  background-color: ${Colors.Neutral20};
  color: ${Colors.Neutral50};
  pointer-events: none;
  box-shadow: none;
`

export const ButtonV2 = styled(BaseButtonV2)(props => {
  const { isDisabled, theme, size } = props

  return css`
    ${FontWeights.Medium};
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    outline: none;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    border-width: 0;
    user-select: none;
    border-radius: ${Sizes['3XS']};
    transition: background-color 0.1s, color 0.1s, box-shadow 0.1s;
    pointer-events: ${isDisabled ? 'none' : 'auto'};
    color: ${Colors.White};

    ${() => {
      switch (size) {
        case ButtonSize.small:
          return css`
            ${Typography.ButtonS};
            padding: ${Sizes['2XS']};
          `
        case ButtonSize.default:
        default:
          return css`
            ${Typography.ButtonV2M};
            padding: ${Sizes.XS} ${Sizes.M};
          `
      }
    }};

    ${() => {
      switch (theme) {
        case ButtonTheme.destructive:
          return getThemeCss(Colors.Red50, Colors.Red60, Colors.Red70)
        case ButtonTheme.confirmative:
          return getThemeCss(Colors.Green50, Colors.Green60, Colors.Green70)
        case ButtonTheme.alternative:
          return getThemeCss(Colors.Indigo50, Colors.Indigo60, Colors.Indigo70)
        case ButtonTheme.ghost:
          return css`
            ${getThemeCss('transparent', 'transparent', Colors.Blue10)};
            box-shadow: inset 0 0 0 1px ${Colors.Neutral20};
            color: ${Colors.Neutral90};

            &:hover,
            &:focus-visible,
            &:active {
              box-shadow: inset 0 0 0 1px ${Colors.Blue50};
              color: ${Colors.Blue50};
            }
          `
        case ButtonTheme.secondary:
          return css`
            ${getThemeCss(Colors.Blue10, Colors.Blue20, Colors.Blue30)};
            color: ${Colors.Blue50};
          `
        case ButtonTheme.default:
        default:
          return getThemeCss(Colors.Blue50, Colors.Blue60, Colors.Blue70)
      }
    }};

    &:disabled {
      ${disabledCss};
    }

    ${isDisabled && disabledCss};
  `
})
