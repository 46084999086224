import { ChangeEvent, memo } from 'react'
import { i18n } from 'builder/utils/i18n'
import Toggle from 'builder/components/Toggle'
import { UpdateSimpleFieldFunc } from 'builder/modules/resumeEditor'
import { ExtraContainer } from './styles'

type Props = {
  updateSimpleField?: UpdateSimpleFieldFunc
  hideSkillLevel?: boolean
}

export const SkillsExtra = memo((props: Props) => {
  const { hideSkillLevel, updateSimpleField } = props

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (updateSimpleField) {
      updateSimpleField('hideSkillLevel', event.target.checked, false)
    }
  }

  return (
    <ExtraContainer onClick={event => event.stopPropagation()}>
      <Toggle
        label={i18n.t(`builder.resume_editor.skills_hide_level`)}
        checked={hideSkillLevel}
        onChange={handleChange}
      />
    </ExtraContainer>
  )
})
