import styled, { css } from 'styled-components'
import { TransitionStatus } from 'react-transition-group'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Shadows from 'builder/styles/shadows'
import { Indicators } from './ClusterPicker'

export const Container = styled.div`
  gap: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: var(--size-xs);
`

export const Item = styled.div`
  ${Typography.Tiny};
  height: 24px;
  cursor: pointer;
  padding: 4px 6px;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Neutral50};
  background: ${Colors.Neutral10};

  &:hover,
  &:focus-visible {
    color: ${Colors.Blue60};
  }
`

export const InputWrapper = styled.div<Indicators>`
  position: relative;
  input {
    border-radius: 3px;
    padding: var(--size-xs);
    width: 100%;
    display: block;
    caret-color: ${Colors.Blue50};
    background-color: ${Colors.Neutral10};
    outline: none;
    border: 0;
    color: ${Colors.Neutral90};
    transition: color 0.1s ease;

    &:disabled {
      pointer-events: none;
      color: ${Colors.Neutral50};
    }

    &::placeholder {
      color: ${Colors.Neutral40};
      opacity: 1;
    }
  }

  .select__control {
    border: none;
    outline: none;
    width: 100%;
    min-height: 48px;
    cursor: text;
    border-radius: 4px;
    box-shadow: none;
    background-color: ${Colors.Neutral10};
  }

  .select__placeholder {
    color: ${Colors.Neutral50};
  }

  .select__menu {
    margin: 0;
    width: 100%;
    box-shadow: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: ${Colors.Neutral10};
    border-top: 2px solid ${Colors.Blue50};
  }

  .select__option {
    background-color: transparent;

    &:hover {
      cursor: pointer;
      color: ${Colors.Blue50};
      background-color: ${Colors.Blue20};
    }
  }

  .select__indicators {
    .select__clear-indicator {
      display: ${({ clear }) => (clear ? 'block' : 'none')};
    }

    .select__loading-indicator {
      display: ${({ loading }) => (loading ? 'block' : 'none')};
    }

    .select__indicator-separator {
      display: ${({ separator }) => (separator ? 'block' : 'none')};
    }

    .select__dropdown-indicator {
      display: none;
    }
  }

  .select__multi-value {
    ${Shadows.light.low};
    height: 32px;
    display: flex;
    border-radius: 4px;
    padding: 8px 4px 6px 4px;
    align-items: center;
    background-color: ${Colors.White};
  }

  .select__multi-value__label {
    color: ${Colors.Neutral90};
  }

  .select__multi-value__remove {
    padding: 0;
    color: ${Colors.Neutral40};

    &:hover {
      cursor: pointer;
      color: ${Colors.Blue50};
      background-color: transparent;
    }
  }

  ${({ dropdown }) =>
    dropdown &&
    css`
      &:focus-within {
        .select__indicators {
          align-items: flex-end;
        }

        .select__dropdown-indicator {
          ${Typography.Caption}
          ${FontWeights.Regular}
          color: ${Colors.Neutral50};
          display: flex;

          span {
            margin-top: 4px;
          }
        }
      }
    `}
`

export const Bar = styled.div<{
  $hasError?: boolean
  $hasWarning?: boolean
  $in: TransitionStatus
}>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${({ $hasError, $hasWarning }) => {
    if ($hasError) return Colors.Red50
    if ($hasWarning) return Colors.Amber40
    return Colors.Blue50
  }};
  border-radius: 0 0 2px 2px;
  pointer-events: none;

  ${props =>
    ({
      entering: 'opacity: 1; transform: scaleX(1); transition: transform 0.1s ease;',
      entered: 'opacity: 1; transform: scaleX(1)',
      exiting: 'opacity: 0; transform: scaleX(1); transition: opacity 0.1s ease;',
      exited: 'opacity: 0; transform: scaleX(0.5)',
      unmounted: null,
    }[props.$in])};
`

export const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`

export const Label = styled.label<{ $hasError?: boolean }>`
  display: flex;
  align-items: center;
  ${Typography.Caption};
  color: ${({ $hasError }) => ($hasError ? Colors.Red50 : Colors.Neutral50)};
  justify-content: 'space-between';
`

export const LabelContent = styled.div`
  display: inline-block;
`

export const Required = styled.span`
  color: ${Colors.Red50};
`

export const HintText = styled.div`
  margin-top: 4px;
  color: ${Colors.Neutral50};

  ${Typography.Caption};
  ${FontWeights.Regular};
`
