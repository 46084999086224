import { useI18n } from 'builder/hooks/useI18n'

import { Logo } from '../../../Logo/Logo'
import { AtContainer, Container, Content, LogoContainer, LogoTextContainer } from './styles'

interface Props {
  jobTitle: string | undefined
  logoUrl: string | null | undefined
  companyName: string | null | undefined
}
export const TRANSLATION_PREFIX = 'builder.interview_prep_view.interview_player_v2.job_title'

export const JobTitle = ({ jobTitle, logoUrl, companyName }: Props) => {
  const { i18n } = useI18n()

  return (
    <Container>
      {jobTitle && (
        <Content>
          {jobTitle}&nbsp;
          <LogoTextContainer>
            {companyName && (
              <div>
                <AtContainer>{i18n.t(`${TRANSLATION_PREFIX}.at_preposition`)}</AtContainer>

                <LogoContainer>
                  <Logo src={logoUrl} />
                </LogoContainer>
                {companyName}
              </div>
            )}
          </LogoTextContainer>
        </Content>
      )}
    </Container>
  )
}
