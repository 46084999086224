import { DefaultSuggestionType } from 'builder/components/AsyncAutosuggest/types'
import { SearchSuggestionType } from '../AutoSuggestField/types'

export const getRecentLocationSuggestion = (recentLocations: string[]) => {
  try {
    const latestLocations = recentLocations
      .map(str => JSON.parse(str))
      .filter(i => i.locationType === 'location_id')
      .slice(-3)
      .reverse() as DefaultSuggestionType[]

    if (latestLocations.length === 0) {
      return undefined
    }

    return { title: 'Recent', suggestions: latestLocations }
  } catch {
    return undefined
  }
}

type LocationSuggestion = {
  title: string
  suggestions: DefaultSuggestionType[]
}

export const generateLocationSuggestion = (
  t: (key: string) => string,
  withRemote = true,
  nearMeLocation?: SearchSuggestionType,
  nearMeFragment?: React.ReactFragment,
  recentLocationSuggestion?: LocationSuggestion,
) => {
  const suggestions = [
    nearMeLocation
      ? {
          formatted: nearMeFragment,
          text: nearMeLocation.formatted,
          value: nearMeLocation.value,
          locationType: 'near_me',
          lat: nearMeLocation.lat,
          lng: nearMeLocation.lng,
        }
      : undefined,
    withRemote
      ? {
          formatted: t('builder.job_search.search_bar.location.remote'),
          value: t('builder.job_search.search_bar.location.remote'),
          locationType: 'remote',
        }
      : undefined,
  ].filter(Boolean)

  return [
    {
      suggestions,
    },
    recentLocationSuggestion,
  ].filter(Boolean)
}

export const handleLocationParameterEvent = (
  location?: SearchSuggestionType,
  nearMeLocation?: SearchSuggestionType,
) => {
  if (location?.locationType === 'location_id') {
    return location?.formatted
  }
  if (location?.locationType === 'near_me') {
    return nearMeLocation?.formatted
  }

  return ''
}
