import { useEffect, useLayoutEffect, useState } from 'react'
import Typography from 'builder/styles/typography'

import { Text } from './styles'

interface Props {
  parentNode: HTMLDivElement | null
  text?: string
  html?: string
}

function hasScrollbar(element: HTMLDivElement) {
  // For vertical scrollbar
  if (element.scrollHeight > element.clientHeight) {
    return true
  }

  // For horizontal scrollbar
  if (element.scrollWidth > element.clientWidth) {
    return true
  }

  return false
}

const fontSizesFromTheLargestToSmallest = [
  Typography.L,
  Typography.M,
  Typography.S,
  Typography.Subhead,
  Typography.Body,
]

export const TextWithoutScroll = ({ text, parentNode, html }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isFontSizeReady, setIsFontSizeReady] = useState(false)
  const fontSize = fontSizesFromTheLargestToSmallest[currentIndex]

  useEffect(() => {
    const handleResize = () => {
      setCurrentIndex(0)
      setIsFontSizeReady(false)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useLayoutEffect(() => {
    if (isFontSizeReady || !text || !parentNode) {
      return
    }
    if (hasScrollbar(parentNode) && currentIndex + 1 < fontSizesFromTheLargestToSmallest.length) {
      setCurrentIndex(currentIndex => currentIndex + 1)
    } else {
      setIsFontSizeReady(true)
    }
  }, [fontSize, isFontSizeReady, text, parentNode, currentIndex])

  if (html) {
    return (
      <Text $size={fontSize} dangerouslySetInnerHTML={{ __html: html }}>
        {text}
      </Text>
    )
  }
  return <Text $size={fontSize}>{text}</Text>
}
