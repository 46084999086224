import Colors from 'builder/styles/colors'
import { ProgressContainer, Progress, Container, Title } from './styles'

interface Props {
  left: string
  right: string
  score?: number
  disabled?: boolean
  width: string
}
export const LineProgressBar = ({ left, right, score = 0, disabled = false, width }: Props) => {
  return (
    <Container $width={width}>
      <Title>
        <span>{left}</span>
        <span>{right}</span>
      </Title>

      <ProgressContainer $color={disabled ? Colors.Neutral10 : Colors.Green50}>
        {!disabled && <Progress $score={100 - score} $color={Colors.Orange50} $left={score} />}
      </ProgressContainer>
    </Container>
  )
}
