import { useNavigate } from 'react-router-dom'
import { Button, ButtonSize } from 'builder/components/Button'
import { MouseClickEvent } from 'builder/modules/ui'
import { Container, Header, SubHeader, ButtonContainer } from './styles'

interface EmptyStateProps {
  email?: string
  heading: string
  subHeading?: string | React.ReactElement
  buttonText?: string
}

export const EmptyState = ({ email, heading, subHeading, buttonText }: EmptyStateProps) => {
  const navigate = useNavigate()

  const handleClick = (e: MouseClickEvent) => {
    const target = e.target as HTMLButtonElement

    if (target.innerText === 'Request a Session') {
      window.open(`mailto:${email}`, '_self')
      return
    }

    navigate('/onboarding-form')
  }

  return (
    <Container>
      <Header>{heading}</Header>
      <SubHeader>{subHeading}</SubHeader>
      {buttonText && (
        <ButtonContainer>
          <Button size={ButtonSize.small} onClick={handleClick}>
            {buttonText}
          </Button>
        </ButtonContainer>
      )}
    </Container>
  )
}
