import React from 'react'
import { TargetRoleDialog as TargetLocationDialog } from 'builder/views/CareerProfile/TargetRoleDialog'
import { useI18n } from 'builder/hooks/useI18n'
import NO_LOCATION from '../../../RecommendationsErrors/assets/no_location.png'
import { TargetLocationImage } from '../../styles'

interface SetTargetLocationDialogProps {
  closeHandler: () => void
  afterSetLocationHandler?: () => void
}

const baseI18nKey = 'builder.job_search.recommendations.missing_profile_information.set_location'

export const SetTargetLocationDialog: React.FC<SetTargetLocationDialogProps> = ({
  closeHandler,
  afterSetLocationHandler,
}) => {
  const { i18n } = useI18n()

  return (
    <TargetLocationDialog
      setClose={closeHandler}
      showBackButton={false}
      includeLocation={true}
      translationKey={baseI18nKey}
      handleAfterSave={afterSetLocationHandler}
      eventLabel="recommended_jobs"
      defaultStep="location"
      locationData={{
        buttonText: i18n.t(`${baseI18nKey}.button`),
        subtitle: i18n.t(`${baseI18nKey}.subtitle`),
        Image: <TargetLocationImage src={NO_LOCATION} alt="target-location" />,
      }}
    />
  )
}
