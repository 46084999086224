import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/interview/interviewModule'
import { InterviewPlayerModalType } from 'builder/modules/interview/types'
import { QuestionInterval } from '../QuestionInterval/QuestionInterval'
import { VideoMicModalInitial } from './components/VideoMicModal/VideoMicModalInitial'
import { VideoMicModalHelp } from './components/VideoMicModal/VideoMicModalHelp'
import { CloseModal } from './components/CloseModal/CloseModal'
import { StartLoading } from './components/LoadingScreen/StartLoading'
import { EndLoading } from './components/LoadingScreen/EndLoading'
import { InterruptedLoading } from './components/LoadingScreen/InterruptedLoading'
import { SessionModal } from './components/SessionModal/SessionModal'

export interface InterviewModalsProps {
  handleWelcome: () => void
  handleInterruptedRecordingAnswer: () => void
}

export const InterviewModals = (props: InterviewModalsProps) => {
  const { modal } = useTypedSelector(selectors.interviewPlayer)

  switch (modal) {
    case InterviewPlayerModalType.loading_start:
      return <StartLoading />
    case InterviewPlayerModalType.loading_interrupted_interview:
      return <InterruptedLoading />
    case InterviewPlayerModalType.loading_end:
      return <EndLoading />
    case InterviewPlayerModalType.permission_modal_initial:
      return <VideoMicModalInitial {...props} />
    case InterviewPlayerModalType.permission_modal_help:
      return <VideoMicModalHelp />
    case InterviewPlayerModalType.close_modal:
      return <CloseModal {...props} />
    case InterviewPlayerModalType.session_modal:
      return <SessionModal />
    case InterviewPlayerModalType.question_interval:
      return <QuestionInterval />
    default:
      return null
  }
}
