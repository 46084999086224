import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { baseAppClient } from 'builder/modules/apiClient/apiClient'

type Params = {
  plan?: string
  referrerPage?: string
  fetchPrice?: boolean
}

type Plan = {
  amount: number
  name: string
  recurring_amount: number
}

type Payload = {
  plans: Plan[]
  upsell: Omit<Plan, 'name'>
  currency_symbol: string
}

type ResponsePayload = UseQueryResult<Payload, unknown> & {
  planAmount: () => string
  recurringAmount: () => string
}

export const useGetPlanPrice = (params?: Params): ResponsePayload => {
  const getPlanApi = async () => {
    const res = await baseAppClient.get('/billing/plans.json', {
      params: {
        plan: params?.plan,
        referrerPage: params?.referrerPage,
      },
    })
    return res.data
  }

  const res = useQuery<Payload>({
    queryKey: ['useGetPlanPrice', params],
    queryFn: getPlanApi,
    onError: err => console.error(err),
    useErrorBoundary: false,
    enabled: params?.fetchPrice,
  })

  const normalizeNumber = (amount?: number) => {
    if (!amount) return '0'
    return Number(amount / 100).toFixed(2)
  }

  const planAmount = () => {
    const amount = res.data?.upsell?.amount
    const currency = res.data?.currency_symbol || '$'

    return `${currency}${normalizeNumber(amount)}`
  }

  const recurringAmount = () => {
    const amount = res.data?.upsell?.recurring_amount
    const currency = res.data?.currency_symbol || '$'

    return `${currency}${normalizeNumber(amount)}`
  }

  return { ...res, recurringAmount, planAmount }
}
