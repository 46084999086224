import { useMemo } from 'react'
import { Body, Header, PulsatingCircle, Wrapper } from './styles'
import { useI18n } from 'builder/hooks/useI18n'
import { INTERVAL_MESSAGES_LENGTH } from 'builder/views/Interview/constants'

export const TRANSLATION_PREFIX = 'builder.interview_prep_view.interview_player_v2'

export const QuestionInterval = () => {
  const { i18n } = useI18n()
  const message = useMemo(() => {
    const messageIndex = Math.floor(Math.random() * INTERVAL_MESSAGES_LENGTH)
    return {
      header: i18n.t(
        `${TRANSLATION_PREFIX}.question_interval_messages.message_${messageIndex}.header`,
      ),
      body: i18n.t(`${TRANSLATION_PREFIX}.question_interval_messages.message_${messageIndex}.body`),
    }
  }, [])

  return (
    <Wrapper>
      <PulsatingCircle />
      <Header>{message.header}</Header>
      <Body>{message.body}</Body>
    </Wrapper>
  )
}
