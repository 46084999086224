import { memo } from 'react'
import { BlurredContainer } from '../styles'

interface WithCustomContainerProps {
  CustomContainer: typeof BlurredContainer
}

export const withBlurredErrorContainer = <P extends object>(
  WrappedComponent: React.ComponentType<P & WithCustomContainerProps> | null,
): React.FC<P> => {
  const EnhancedComponent: React.FC<P> = memo(props => {
    if (!WrappedComponent) return null
    return <WrappedComponent {...props} CustomContainer={BlurredContainer} />
  })

  return EnhancedComponent
}
