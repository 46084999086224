import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useI18n } from 'builder/hooks/useI18n'
import { delay } from 'builder/views/Interview/utils/delay'
import { selectors, actions } from 'builder/modules/interview/interviewModule'
import { useInterview } from 'builder/views/Interview/hooks/useInterview'
import { LoadingScreen } from './LoadingScreen'
const TRANSLATION = 'builder.interview_prep_view.interview_player.loading_start_screen'

export const StartLoading = () => {
  const { i18n } = useI18n()
  const [counter, setCounter] = useState(0)
  const dispatch = useDispatch()

  const interviewId = useSelector(selectors.interviewId)
  const { data: interview } = useInterview({ interviewId })
  const { showAllPhrases } = useSelector(selectors.interviewPlayer)

  const showRealCompany = !!(interview?.payload.real_company && interview?.payload.company_name)

  const items = useMemo(() => {
    return [
      {
        title: i18n.t(`${TRANSLATION}.step1.title`),
        content: i18n.t(`${TRANSLATION}.step1.subtitle`, {
          companyName: showRealCompany ? interview?.payload.company_name : '',
        }),
      },
      {
        title: i18n.t(`${TRANSLATION}.step2.title`),
        content: i18n.t(`${TRANSLATION}.step2.subtitle`, {
          companyName: showRealCompany ? interview?.payload.company_name : '',
        }),
      },
      {
        title: i18n.t(`${TRANSLATION}.step3.title`),
        content: i18n.t(`${TRANSLATION}.step3.subtitle`, {
          companyName: showRealCompany ? interview?.payload.company_name : '',
        }),
      },
    ]
  }, [i18n, showRealCompany, interview?.payload.company_name])

  useEffect(() => {
    let isMounted = true

    async function wait() {
      await delay(3000)
      if (isMounted) {
        const newCounter = (counter + 1) % items.length
        if (!showAllPhrases && counter === items.length - 1 && newCounter === 0) {
          dispatch(actions.setInterviewPlayerShowAllPhrases(true))
          return
        }
        setCounter(newCounter)
      }
    }
    wait()

    return () => {
      isMounted = false
    }
  }, [counter, items])

  if (items.length === counter) {
    return null
  }
  return <LoadingScreen content={items[counter]} />
}
