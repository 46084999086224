import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

const size = css`
  flex-shrink: 0;
  max-height: 278px;
  width: auto;

  ${Media.Tablet`
    max-height: 245px;
  `}

  ${Media.Phone`
    max-height: unset;
    max-width: 187px;
  `}
`

export const Container = styled.div`
  position: relative;
  border-radius: ${Sizes.L};
  overflow: hidden;
  left: 0;

  ${size};
`

export const CustomVideo = styled.video`
  border-radius: 10px;

  overflow: hidden;

  ${size};
`

export const EmptyVideo = styled.video`
  background-color: ${Colors.Neutral100};
  width: 100%;
  height: 100%;
`

export const RecContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  gap: 3px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: ${Sizes['3XL']};
  padding: 8px;

  ${Typography.Caption};
  color: ${Colors.White};
  ${FontWeights.Medium};
`

export const ReadDot = styled.div`
  width: 11px;
  height: 11px;
  background-color: ${Colors.Red60};
  border-radius: 50%;
`

export const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  color: ${Colors.White};
  gap: 2px;
  margin-right: 9px;
  margin-bottom: 9px;
`

export const Button = styled.div<{ $disabled: boolean }>`
  height: 53px;
  width: 53px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }

  ${({ $disabled }) => $disabled && `background-color: rgba(0, 0, 0, 0.2)`}
`
