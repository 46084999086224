import styled from 'styled-components'
import { Icon20 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 50px;
`
export const Text = styled.div`
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  color: ${Colors.Neutral80};
  ${Typography.Body};
  ${FontWeights.Medium};
  padding: ${Sizes.XS} ${Sizes.S};
  background-color: ${Colors.White};
  border-radius: ${Sizes['3XL']};
`

export const ChevronRight = styled(Icon20.ChevronDown)`
  transform: rotate(270deg);
`
