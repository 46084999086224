import { Job } from 'builder/components/FindJob/JobSearchResult/types'
import { HeartIconButton } from 'builder/components/FindJob/HeartIconButton/HeartIconButton'
import { useJobTrackingUtilities } from 'builder/components/FindJob/useJobTrackingUtilities'
import { useHover } from 'builder/hooks/useHover'
import { getDefaultLogoForCompany } from 'builder/components/FindJob/utils/getDefaultLogoForCompany'
import {
  Company,
  DesktopContainer,
  Location,
  Logo,
  MobileTabletContainer,
  Title,
  Top,
} from './styles'

interface Props {
  job: Job
  isMobileTable: boolean
  onClick: () => void
}
export const Card = ({ job, isMobileTable, onClick }: Props) => {
  const { isPresent } = useJobTrackingUtilities(job)
  const { hoverRef, isHovering } = useHover<HTMLDivElement>()

  const Container = isMobileTable ? MobileTabletContainer : DesktopContainer

  return (
    <Container onClick={onClick} ref={hoverRef}>
      <Top>
        <Logo src={job.company_logo || getDefaultLogoForCompany()} />
        <Company>{job.company}</Company>

        {(isHovering || isPresent) && <HeartIconButton job={job} />}
      </Top>
      <Title>{job.job_title}</Title>

      <Location>{job.location}</Location>
    </Container>
  )
}
