import { useI18n } from 'builder/hooks/useI18n'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import * as Styles from './styles'
import { useResumeDistributionGotCreditsModal } from './useResumeDistributionGotCreditsModal'

const DEFAULT_MAX_CREDITS = 20
const TRANSLATION = 'builder.resume_distribution.got_credits_modal'

export const ResumeDistributionGotCreditsModal = () => {
  const { i18n } = useI18n()
  const { getAutoApplyCredit } = useAutoApply()
  const { isOpen, handleModalClose } = useResumeDistributionGotCreditsModal()
  const creditCount = getAutoApplyCredit().creditCount || DEFAULT_MAX_CREDITS

  if (!isOpen) return null

  const features = [
    i18n.t(`${TRANSLATION}.features.job_applications`, { credits: creditCount }),
    i18n.t(`${TRANSLATION}.features.weekly_jobs_shortlist`),
    i18n.t(`${TRANSLATION}.features.send_resume`),
    i18n.t(`${TRANSLATION}.features.interview_preparation`),
  ]

  return (
    <Styles.Overlay>
      <Styles.RootModalContainer>
        <Styles.ModalSection>
          <Styles.CloseButtonContainer>
            <Styles.Close onClick={handleModalClose}>
              <Styles.CloseIcon />
            </Styles.Close>
          </Styles.CloseButtonContainer>
          <Styles.HeaderImage />
          <Styles.TextContainer>
            <Styles.HeaderContainer>
              <Styles.Title>
                {i18n.t(`${TRANSLATION}.title`, { credits: creditCount })}
              </Styles.Title>
              <Styles.Description>{i18n.t(`${TRANSLATION}.description`)}</Styles.Description>
            </Styles.HeaderContainer>
            <Styles.FeaturesContainer>
              <Styles.FeatureTitle>{i18n.t(`${TRANSLATION}.features_title`)}</Styles.FeatureTitle>
              <Styles.Features>
                {features.map((feature, index) => (
                  <Styles.Feature key={index}>{feature}</Styles.Feature>
                ))}
              </Styles.Features>
            </Styles.FeaturesContainer>
            <Styles.CTAContainer>
              <Styles.CTAPrimary onClick={handleModalClose}>
                {i18n.t(`${TRANSLATION}.get_started_cta`)}
              </Styles.CTAPrimary>
            </Styles.CTAContainer>
          </Styles.TextContainer>
        </Styles.ModalSection>
      </Styles.RootModalContainer>
    </Styles.Overlay>
  )
}
