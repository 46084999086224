import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
`

export const Animation = styled.div`
  background: rgba(26, 145, 240, 0.3);
  border-radius: 50%;
  position: absolute;
  z-index: 1554;
  height: 122px;
  width: 122px;
  transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1),
    filter 0.3s cubic-bezier(0.77, 0, 0.175, 1);

  ${Media.Tablet`
    height: 144px;
    width: 144px;
  `}

  ${Media.Phone`
    height: 188px;
    width: 188px;
  `}
`
