import { useEffect, useState, lazy, Suspense, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { actions, selectors } from 'builder/modules/uploadResume'
import { selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { UploadResumeStep } from 'builder/modules/uploadResume/constants'
import {
  actions as ResumeCritiqueActions,
  selectors as ResumeCrituqueSelector,
} from 'builder/modules/resumeReview'
import { useQueryParam } from 'builder/hooks/useQueryParam'

import { Icon24 } from 'builder/components/Icon'
import { NameForm } from 'builder/components/CreateAccount/components/NameForm'
import { APP_BASE_PATH } from 'builder/modules/constants'
import CIO_LOGO_URL from 'images/countries/cio-logo.svg'
import * as Styled from './styles'
import StepLayout from './components/StepLayout'
import { UploadResumeLocalStoreKeys, utmParamsList } from './utils'
import EmailSent from './components/EmailSent'
import { emailExist } from './emailExist'

const AlreadyLoggedIn = lazy(() => import('./components/AlreadyLoggedIn'))
const ContentFooter = lazy(() => import('./components/ContentFooter'))
const CreateResumeInstantly = lazy(
  () =>
    import(
      'builder/components/Panel/CIOCritiqueReview/UploadResume/components/CreateResumeInstantly'
    ),
)
const UploadResume = lazy(() => import('builder/components/Panel/CIOCritiqueReview/UploadResume'))
const ProgressBar = lazy(() => import('builder/components/ProgressBar'))

const UploadResumeView = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [urlParams] = useSearchParams()
  const step = useTypedSelector(selectors.step)
  const isParsing = useTypedSelector(selectors.isParsing)
  const hasParsed = useTypedSelector(selectors.hasParsed)
  const parsingProgress = useTypedSelector(selectors.parsingProgress)
  const { firstName, lastName, checksum, email } = useTypedSelector(selectors.parsedResumeDetails)
  const [isEmailExist, setIsEmailExist] = useState(false)
  const isMyStoryURL = location?.pathname?.includes('mystory')
  const uploadProgress = useTypedSelector(selectors.uploadProgress)
  const critiques = useTypedSelector(ResumeCrituqueSelector.critiques)
  const inputEmail = useTypedSelector(selectors.email)
  const selectedFile = useTypedSelector(selectors.selectedFile)

  const [isUserAvailable, setIsUserAvailable] = useState(false)

  const userDetails = useTypedSelector(userSelectors.userData)
  const photoImage = useTypedSelector(userSelectors.photoUrl)

  const [regionPrefix] = useQueryParam('current_region_prefix')

  const handleLeave = useCallback(() => {
    window.location.href = `/${regionPrefix ?? ''}`
  }, [regionPrefix])

  useEffect(() => {
    if (userDetails) {
      dispatch(ResumeCritiqueActions.fetchCritiques())
      setIsUserAvailable(true)
    }
  }, [userDetails, dispatch])

  useEffect(() => {
    trackInternalEvent('visit_upload_resume_page')
    trackInternalEvent('website_visit', {
      label: 'universal_landing_page',
      path: window.location.pathname,
    })

    let utmPayload = {}
    utmParamsList?.map((utmName: string) => {
      utmPayload = { ...utmPayload, [utmName]: urlParams.get(utmName) }
      return utmName
    })

    trackInternalEvent('track_utm', utmPayload)
  }, [urlParams])

  useEffect(() => {
    const myStoryVisit = localStorage.getItem(UploadResumeLocalStoreKeys.MY_STORY_VISIT_FLAG)
    if (!myStoryVisit) {
      trackInternalEvent('first_visit', {
        label: 'universal_landing_page',
        utm_content: urlParams.get('utm_content'),
        utm_source: urlParams.get('utm_source'),
        utm_medium: urlParams.get('utm_medium'),
        utm_campaign: urlParams.get('utm_campaign'),
        utm_term: urlParams.get('utm_term'),
      })
      localStorage.setItem(UploadResumeLocalStoreKeys.MY_STORY_VISIT_FLAG, 'true')
    }
  }, [urlParams])

  useEffect(() => {
    if (isParsing) {
      const interval = setTimeout(() => {
        if (parsingProgress < 100) {
          dispatch(actions.setParsingProgress(parsingProgress + 6.25))
        }
      }, 1000)

      return () => {
        clearTimeout(interval)
      }
    } else if (hasParsed) {
      dispatch(actions.setStep(UploadResumeStep.PARSED_DETAILS))
    }
  }, [dispatch, parsingProgress, isParsing, hasParsed])

  const upsiderPlatformV1 = location.pathname === '/upload-resume'
  const redirectTo = upsiderPlatformV1 ? APP_BASE_PATH : '/upsider-loading'

  const handleCreateNew = () => {
    dispatch(actions.parsedDetails({ upload: selectedFile, email: inputEmail }))
    parsingProgress > 0 && dispatch(actions.setParsingProgress(0))

    dispatch(actions.setStep(UploadResumeStep.SENDING_RESUME))
  }

  const doesEmailExist = async () => {
    // Check Email existence
    const emailAlreadyExistError = await emailExist(inputEmail)
    setIsEmailExist(emailAlreadyExistError)
    return emailAlreadyExistError
  }

  const handleNameFormSubmit = async (isCreatePlusOneUser?: boolean) => {
    if (!isCreatePlusOneUser) {
      if (critiques?.length > 0) {
        navigate(
          // eslint-disable-next-line max-len
          `${APP_BASE_PATH}/resumes/${critiques?.[0]?.resumeId}/critique?lowLeads=1&v2=1&secondReview=true`,
        )
      } else {
        dispatch(actions.parsedDetails({ upload: selectedFile, email: inputEmail }))
        parsingProgress > 0 && dispatch(actions.setParsingProgress(0))
        dispatch(actions.setStep(UploadResumeStep.SENDING_RESUME))
      }
    } else {
      const isCioUser = await doesEmailExist()

      const myStoryUTMs = {
        utm_content: urlParams.get('utm_content'),
        utm_source: urlParams.get('utm_source'),
        utm_medium: urlParams.get('utm_medium'),
        utm_campaign: urlParams.get('utm_campaign'),
        utm_term: urlParams.get('utm_term'),
      }

      const encodedEmail = encodeURIComponent(inputEmail)

      const params = {
        redirectTo,
        checksum,
        critiqueReview: 'critique',
        ...myStoryUTMs,
        isCioUser: isCioUser,
        emailId: encodedEmail,
      }

      localStorage.setItem(UploadResumeLocalStoreKeys.MY_STORY_UTMS, JSON.stringify(myStoryUTMs))
      dispatch(actions.createUpsiderUser(params))
    }
  }

  localStorage.setItem('IS_CRITIQUE_CHECKSUM', checksum)

  const handleNameFormBackClick = () => {
    dispatch(actions.setIsFileSelected(false))
    if (uploadProgress === 100) {
      dispatch(actions.setUploadProgress(-100))
    } else {
      dispatch(actions.setUploadProgress(0))
    }
    dispatch(actions.setStep(UploadResumeStep.UPLOAD_RESUME))
  }

  const getRightContent = () => {
    if (step === UploadResumeStep.PARSED_DETAILS) {
      return (
        <StepLayout title="Add your name" subtitle="Welcome! What should we call you?">
          <NameForm
            firstName={firstName}
            lastName={lastName}
            signUpFlow="RECRUITERDOTCOM"
            onSubmit={() => handleNameFormSubmit(true)}
            onBackClick={handleNameFormBackClick}
            disabledI18n={isMyStoryURL}
          />
        </StepLayout>
      )
    }

    if (step === UploadResumeStep.RESUME_SELECTED) {
      return (
        <Styled.ResumeSelectedContent>
          <Suspense fallback={null}>
            <UploadResume />
          </Suspense>
        </Styled.ResumeSelectedContent>
      )
    }

    if (step === UploadResumeStep.UPLOAD_RESUME) {
      return (
        <>
          <Styled.LeftContent>
            <Styled.UploadTitle>Get a free resume review</Styled.UploadTitle>
            <Suspense fallback={null}>
              <UploadResume />
            </Suspense>
          </Styled.LeftContent>
          <Styled.MiddleContent>
            <Styled.VerticalBreak>
              <Styled.Line />
              <Styled.BreakText>or</Styled.BreakText>
              <Styled.Line />
            </Styled.VerticalBreak>
          </Styled.MiddleContent>
          <Styled.RightContent>
            <Styled.UploadTitle>Don&apos;t have a resume?</Styled.UploadTitle>
            <Suspense fallback={null}>
              <CreateResumeInstantly />
            </Suspense>
            <Suspense fallback={null}>
              <CreateResumeInstantly isTopResumeCard={true} />
            </Suspense>
          </Styled.RightContent>
        </>
      )
    }

    if (step === UploadResumeStep.SENDING_RESUME) {
      return (
        <Styled.Parsing>
          <Suspense fallback={null}>
            <ProgressBar score={parsingProgress} />
          </Suspense>
          <Styled.ParseText>Sending resume...</Styled.ParseText>
        </Styled.Parsing>
      )
    }

    return (
      <Suspense fallback={null}>
        <AlreadyLoggedIn
          name={userDetails?.fullName}
          email={userDetails?.email}
          onContinue={() => handleNameFormSubmit(false)}
          createNew={handleCreateNew}
          photoImage={photoImage}
          inputEmail={inputEmail}
        />
      </Suspense>
    )
  }

  return (
    <Styled.Container>
      <Styled.ContainerBody>
        <Styled.Anchor aria-label="Logo" href="/">
          <Styled.Logo src={CIO_LOGO_URL} />
        </Styled.Anchor>
        <Styled.HeaderContainer>
          <Styled.CloseButton aria-label="Close" onClick={handleLeave}>
            <Icon24.CloseLarge />
          </Styled.CloseButton>
        </Styled.HeaderContainer>
        {isEmailExist ? (
          <EmailSent EmailId={email} />
        ) : (
          <>
            <Styled.Header>
              {step !== UploadResumeStep.USER_AVAILABLE &&
                step !== UploadResumeStep.PARSED_DETAILS && (
                  <>
                    <Styled.Title>Tell your career story with Career.io</Styled.Title>
                    <Styled.SubTitle>Be ready, stand out, and stay in control</Styled.SubTitle>
                  </>
                )}
              {step === UploadResumeStep.USER_AVAILABLE && isUserAvailable && (
                <>
                  <Styled.Title>You’re already logged in</Styled.Title>
                  <Styled.SubTitle>
                    You can create a new account with
                    <Styled.EmailId>{inputEmail} </Styled.EmailId> email or continue as the current
                    user
                  </Styled.SubTitle>
                </>
              )}
            </Styled.Header>
            <Styled.Content>{getRightContent()}</Styled.Content>
          </>
        )}
      </Styled.ContainerBody>
      <Suspense fallback={null}>
        <ContentFooter />
      </Suspense>
    </Styled.Container>
  )
}

export default UploadResumeView
