import { useEffect, useState, useCallback } from 'react'
import { baseClient } from 'builder/modules/apiClient'
import { CareerProfileData } from 'builder/modules/careerProfile'

export const useGetProfile = () => {
  const [loading, setLoading] = useState(true)
  const [response, setResponse] = useState<CareerProfileData>()

  const fetchProfile = useCallback(async (cb?: () => void) => {
    setLoading(true)
    try {
      const response = await baseClient.get<CareerProfileData>(`/career/v1/profile`)
      setResponse(response.data)
      cb?.()
    } catch (error) {
      console.error('Error fetching profile:', error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchProfile()
  }, [fetchProfile])

  const refetch = (cb?: () => void) => {
    fetchProfile(cb)
  }

  return { loading, response, refetch }
}
