import { memo } from 'react'
import Anchor from '../Anchor'
import { WithFoldableSections } from '../Section'
import ProfileSummary from './ProfileSummary'

type Props = WithFoldableSections & {
  sectionId: string
}

const ProfileSection = (props: Props): JSX.Element => {
  const { sectionId, isFoldableSectionsEnabled } = props
  return (
    <Anchor id={sectionId} scrollOffsetTop={70}>
      <ProfileSummary {...{ isFoldableSectionsEnabled }} />
    </Anchor>
  )
}

export default memo(ProfileSection)
