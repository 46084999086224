import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { actions } from 'builder/modules/careerProfile'
import { LOGO_ELEMENT_ID } from 'builder/components/Navigation'
import { PremiumTracker } from 'builder/components/Navigation/components/PremiumTracker'
import { calculateDaysDifference } from 'builder/components/Navigation/components/PremiumTracker/utils'
import { useUser } from 'builder/hooks/useUser'
import { UserBillingStatus, UserType } from 'builder/modules/user'
import CIO_LOGO_URL from 'images/countries/cio-logo.svg'

import { UserProfile } from '../UserProfile/UserProfile'
import { Container, LogoLink, LogoStyled, CustomLogo, Profile, ProfileWrapper } from './styles'

type Props = {
  hideProfile?: boolean
}

export const NavBarMobile = ({ hideProfile }: Props) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const promoBanner = localStorage.getItem('promoBanner')
  const user = useUser()

  const isUpsiderLoaderPage = useMemo(() => {
    return location.pathname?.includes('/upsider-loading')
  }, [location.pathname])

  const openMobileMenu = () => {
    dispatch(actions.setMobileProfileMenu(true))
  }

  const daysLeftPremium = useMemo(
    () =>
      user?.billingInfo?.premiumEndsAt
        ? calculateDaysDifference(user?.billingInfo?.premiumEndsAt) + 1
        : 0,
    [user?.billingInfo.premiumEndsAt],
  )

  const showPremiumTracker = useMemo(() => {
    return (
      daysLeftPremium <= 7 &&
      user?.billingStatus !== UserBillingStatus.subscription &&
      user?.billingInfo.userType &&
      [UserType.premium, UserType.postPremium].includes(user?.billingInfo.userType)
    )
  }, [daysLeftPremium, user])

  return (
    <Container id="navbar-mobile">
      <LogoLink aria-label="Logo" to={promoBanner === '1' ? '/?tr_promo_banner=1' : '/'}>
        {isUpsiderLoaderPage ? (
          <CustomLogo src={CIO_LOGO_URL} />
        ) : (
          <LogoStyled id={LOGO_ELEMENT_ID} variant="default" />
        )}
      </LogoLink>
      {!hideProfile && (
        <ProfileWrapper>
          {showPremiumTracker && <PremiumTracker daysLeftPremium={daysLeftPremium} />}
          <Profile onClick={openMobileMenu}>
            <UserProfile id="mobile-profile-menu-onboarding" showOnlyAvatar={true} />
          </Profile>
        </ProfileWrapper>
      )}
    </Container>
  )
}
