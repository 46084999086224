import { useMemo } from 'react'
import { Optional } from 'packages/types'
import { useI18n } from 'builder/hooks/useI18n'

export const useCardTitle = (part1: Optional<string>, part2: Optional<string>) => {
  const { i18n } = useI18n()

  const title = useMemo(() => {
    const joiningString = ' ' + i18n.t('builder.resume_editor.labels.at') + ' '
    return (
      [part1, part2].filter(x => x).join(joiningString) ||
      i18n.t('builder.resume_editor.not_specified')
    )
  }, [part1, part2, i18n])

  return { title }
}
