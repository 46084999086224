import { useMutation } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
interface Params {
  interviewId: number
  status: string
}

export const useMutateInterview = () => {
  return useMutation({
    mutationFn: async ({ interviewId, status }: Params) => {
      const response = await baseClient.patch(`interview_prep/interviews/${interviewId}`, {
        status,
      })
      return response.data
    },
  })
}
