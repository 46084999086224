import { memo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { AIResumeDraftEvents } from 'builder/modules/constants'
import { i18n as I18n } from 'builder/utils/i18n'
import CreateWithAIImg from '../images/create-with-ai.png'
import { useResumePrefill } from '../../hooks/useResumePrefill'
import {
  CreateWithAICard,
  CardImgContainer,
  CreateWithAICardImg,
  CardDescription,
  CreateWithAICardTitle,
  CardSubTitle,
  AIPoweredBadge,
} from './styles'

type Props = {
  onClose: () => void
}

const CreateWithAI = (props: Props) => {
  const { onClose } = props
  const { handleCreateWithAi, isUSBasedUser } = useResumePrefill()

  const description = isUSBasedUser
    ? I18n.t('builder.prefill_resume.options_view.options.create_with_ai.description')
    : I18n.t('builder.prefill_resume.options_view.options.create_with_ai.description_non_linkedin')

  const handleCardClick = () => {
    trackInternalEvent(AIResumeDraftEvents.clickCreateWithAI)
    handleCreateWithAi()
    onClose()
  }

  return (
    <CreateWithAICard onClick={handleCardClick}>
      <CardImgContainer>
        <CreateWithAICardImg src={CreateWithAIImg} />
      </CardImgContainer>
      <AIPoweredBadge>{I18n.t('builder.ai_resume_draft.ai_powered')}</AIPoweredBadge>
      <CardDescription>
        <CreateWithAICardTitle>
          {I18n.t('builder.prefill_resume.options_view.options.create_with_ai.title')}
        </CreateWithAICardTitle>
        <CardSubTitle>{description}</CardSubTitle>
      </CardDescription>
    </CreateWithAICard>
  )
}

export default memo(CreateWithAI)
