import styled, { css } from 'styled-components'
import Icon24 from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { fromNow } from 'builder/utils/formatDate'
import { Job } from '../JobSearchResult/types'

const Container = styled.span<{ overwriteSVGColor?: boolean }>`
  display: flex;
  align-items: center;
  ${({ overwriteSVGColor: overwriteColor }) =>
    overwriteColor &&
    css`
      & svg path {
        fill: currentColor;
      }
    `}
`

interface Props {
  icon: React.ReactNode
  value: string
  overwriteSVGColor?: boolean
}

const Base = ({ icon, value, overwriteSVGColor }: Props) => (
  <Container overwriteSVGColor={overwriteSVGColor}>
    {icon}
    <span>{value}</span>
  </Container>
)

interface MetaProps {
  job?: Job
}

export const Location = ({ job }: MetaProps) => {
  return <Base overwriteSVGColor icon={<Icon24.NewLocation />} value={job?.location || 'N/A'} />
}

export const Remote = ({ job }: MetaProps) => {
  // TODO: Currently missing this value. Need to investigate its origin
  return job ? null : null

  // const { i18n } = useI18n()
  // if (!job?.isRemote) {
  //   return null
  // }
  // return (
  //   <Base
  //     overwriteSVGColor
  //     icon={<Icon24.Location />}
  //     value={i18n.t('builder.job_search.remote')}
  //   />
  // )
}

export const CreatedAt = ({ job }: MetaProps) => {
  const { i18n } = useI18n()
  if (!job || !job.posted_at) {
    return null
  }

  const translationKey = 'builder.job_search.time_difference'
  const translatedTimeDiffKeys = [
    'day_ago',
    'days_ago',
    'hours_ago',
    'hour_ago',
    'minute_ago',
    'minutes_ago',
    'second_ago',
    'seconds_ago',
    'month_ago',
    'months_ago',
    'year_ago',
    'years_ago',
  ]

  const translatedAdjectives = ['about']

  const splitRelativeTimeString = (
    relativeTimeString: string,
  ): [string, string | null, string | undefined] => {
    const parts = relativeTimeString.split(' ')
    const timeIndex = parts.findIndex(part => !isNaN(Number(part)) && part !== '')

    if (timeIndex === -1) {
      return ['', null, undefined]
    }

    const time = parts[timeIndex]
    const timeAdjectiveKey = parts.slice(0, timeIndex).join('')
    const timeUnitKey = parts.slice(timeIndex + 1).join('_')

    return [time, timeAdjectiveKey || null, timeUnitKey]
  }

  const localizeDate = (date: string | number | Date) => {
    const relativeTimeString = fromNow(date, { addSuffix: true })
    const [timeValue, timeAdjectiveKey, timeUnitKey] = splitRelativeTimeString(relativeTimeString)
    if (!timeUnitKey || !translatedTimeDiffKeys?.includes(timeUnitKey)) {
      return relativeTimeString
    }
    const displayedAdjective =
      timeAdjectiveKey && translatedAdjectives?.includes(timeAdjectiveKey)
        ? i18n.t(`${translationKey}.${timeAdjectiveKey}`)
        : timeAdjectiveKey
    return `${displayedAdjective || ''} ${timeValue} ${i18n.t(`${translationKey}.${timeUnitKey}`)}`
  }

  return <Base icon={<Icon24.ClockIcon />} value={localizeDate(job.posted_at)} />
}

export const Salary = ({ job }: MetaProps) => {
  // TODO: Currently missing this value. Need to investigate its origin
  return job ? null : null

  // if (!job?.salary) {
  //   return null
  // }
  // return <Base icon={<Icon24.MoneyIcon />} value={job?.salary} />
}

export const AutoApplyStatus = () => {
  const { i18n } = useI18n()
  return (
    <Base icon={<Icon24.Simple />} value={i18n.t('builder.job_search.auto_apply.meta_label')} />
  )
}
