import { useNavigate } from 'react-router-dom'

import { CareerProfileCompletenessPendingKey, selectors } from 'builder/modules/careerProfile'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { Menu, MenuItem, MenuItemContent } from 'builder/components/Menu'
import { ISubMenu } from 'builder/features/navigation'

import {
  Divider,
  PendingItemsContainer,
  PendingItem,
  PendingItemText,
  ProfileInfo,
  BadgePercentage,
} from './styles'

const TRANSLATION = 'builder.side_menu.profile'

interface IProps {
  items: ISubMenu[]
}

export const SubMenu = ({ items }: IProps) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const completenessPendingItems = useTypedSelector(selectors.completenessPendingItems)

  const getNavigationLink = (key: CareerProfileCompletenessPendingKey) => {
    if (key === 'target_role') {
      return 'career-profile/edit?section=jobPreferences'
    } else if (key === 'work_experience') {
      return 'career-profile/edit?section=workExperiences'
    } else if (key === 'career_assessment') {
      return '/career-profile/chat'
    }

    return '/career-profile/profile'
  }

  return (
    <>
      {!!completenessPendingItems.length && (
        <PendingItemsContainer>
          <ProfileInfo>{i18n.t(`${TRANSLATION}.complete`)}</ProfileInfo>
          {completenessPendingItems.map(item => (
            <PendingItem onClick={() => navigate(getNavigationLink(item.key))} key={item.key}>
              <BadgePercentage variant="green" size="medium">
                +{item.value}%
              </BadgePercentage>
              <PendingItemText>{i18n.t(`${TRANSLATION}.${item.key}`)}</PendingItemText>
            </PendingItem>
          ))}
        </PendingItemsContainer>
      )}
      {!!completenessPendingItems.length && <Divider />}
      <Menu>
        {items.map(item => (
          <MenuItem key={item.text}>
            <MenuItemContent
              to={item.to}
              open={true}
              active={item.active}
              endDecoration={item.endDecoration}
              startDecoration={item.startDecoration}
              eventParam={item.eventParam}
            >
              {item.text}
            </MenuItemContent>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
