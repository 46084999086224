import { useI18n } from 'builder/hooks/useI18n'
import { getNumberOfQuestionOfInterview } from 'builder/views/Interview/utils/getNumberOfQuestionOfInterview'
import { Interview, Ready } from 'builder/modules/interview/types'
import { Button } from '../Button/Button'
import { ChevronRight, Container, Text } from './styles'
export const TRANSLATION_PREFIX = 'builder.interview_prep_view.interview_player_v2'

interface Props {
  showNextButton: boolean
  currentQuestionIndex: number
  onClickNext: () => void
  interview: Ready<Interview> | undefined
}

export const Pagination = ({
  currentQuestionIndex,
  onClickNext,
  showNextButton,
  interview,
}: Props) => {
  const { i18n } = useI18n()

  const limits = getNumberOfQuestionOfInterview(interview?.payload)

  if (!interview?.isReady) {
    return null
  }
  return (
    <Container>
      <Text>
        {`${currentQuestionIndex + 1} ${i18n.t(`${TRANSLATION_PREFIX}.of_preposition`)} ${
          limits?.totalQuestions
        }`}
      </Text>

      {showNextButton && currentQuestionIndex + 1 !== limits?.totalQuestions && (
        <Button
          text={i18n.t(`${TRANSLATION_PREFIX}.next`)}
          onClick={onClickNext}
          icon={<ChevronRight />}
        />
      )}
    </Container>
  )
}
