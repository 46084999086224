import { useEffect, useMemo, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { findLastIndex } from 'lodash'
import { useI18n } from 'builder/hooks/useI18n'

import { Icon20 } from 'builder/components/Icon'
import { selectors, actions } from 'builder/modules/interview/interviewModule'
import {
  InterviewPlayerModalType,
  Interview,
  Ready,
  InterviewPlayerStatus,
  InterviewPlayerMiddleContent,
  InterviewPlayerBadge,
  InterviewPlayerSnackBar,
} from 'builder/modules/interview/types'

import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { delay } from '../../utils/delay'
import { getUseInterviewKey, useInterview } from '../../hooks/useInterview'

import { DELAY_FOR_FEEDBACK } from '../../constants'
import { invalidateCacheForInterviews } from '../../utils/invalidateCacheForInterviews'
import { addArticleToName } from '../../utils/addArticleToName'
import { getNumberOfQuestionOfInterview } from '../../utils/getNumberOfQuestionOfInterview'
import { useMutateInterview } from '../../hooks/useMutateInterview'
import {
  BottomContainer,
  Container,
  MiddleContainer,
  Note,
  StartInterviewButton,
  TopContainer,
  WebcamContainer,
} from './styles'
import { InterviewModals } from './components/InterviewModals/InterviewModals'
import { TimeDisplay } from './components/TimeDisplay/TimeDisplay'
import { Webcam } from './components/Webcam/Webcam'
import { Snackbar } from './components/Snackbar/Snackbar'

import { Pagination } from './components/Pagination/Pagination'
import { Navbar } from './components/Navbar/Navbar'
import { useGetStream, UserMediaType } from './hooks/useGetStream'
import { JobTitle } from './components/JobTitle/JobTitle'
import { TextWithoutScroll } from './components/TextWithoutScroll/TextWithoutScroll'
import { useAnnouncementUtils } from './hooks/useAnnouncementUtils'
import { Badge } from './components/Badge/Badge'
import { useManageRecordingInterview } from './hooks/useManageRecordingInterview'
import { useRecordingMedia } from './hooks/useRecordingMedia'
import { useProcessFollowUpQuestions } from './hooks/useProcessFollowUpQuestions'
import { Button } from './components/Button/Button'
import { useTimer } from './hooks/useTimer'
import { VoiceAnimation } from './components/VoiceAnimation/VoiceAnimation'

export const TRANSLATION_PREFIX = 'builder.interview_prep_view.interview_player_v2'

export const InterviewPlayerViewV2 = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isPhone, isTablet } = useMediaQueries()

  const interviewId = useSelector(selectors.interviewId)
  const { data: interview } = useInterview({ interviewId })
  const interviewVersion = interview?.payload?.version || null

  const audioStream = useGetStream(UserMediaType.audio)
  const audioVideoStream = useGetStream(UserMediaType.video)
  const middleContainerRef = useRef<HTMLDivElement>(null)
  const { mutateAsync: mutateInterview } = useMutateInterview()
  const [questionIndex, setQuestionIndex] = useState<number | null>(null)
  const [startQuestionIndex, setStartQuestionIndex] = useState<number | null>(null)
  const [showStartButton, setShowStartButton] = useState(false)

  const [interviewPlayerStatus, setInterviewPlayerStatus] = useState<InterviewPlayerStatus>()
  const [prevInterviewPlayerBadge, setPrevInterviewPlayerBadge] = useState<InterviewPlayerBadge>()
  const [interviewPlayerBadge, setInterviewPlayerBadge] = useState<InterviewPlayerBadge>()
  const [interviewPlayerSnackBar, setInterviewPlayerSnackBar] = useState<InterviewPlayerSnackBar>()

  const [middleContent, setMiddleContent] = useState<InterviewPlayerMiddleContent>()
  const question =
    interview?.isReady && typeof questionIndex === 'number'
      ? interview.payload.questions[questionIndex]
      : null

  const mainQuestionId = question?.base_question_id ? question.base_question_id : question?.id

  const interviewPlayer = useTypedSelector(selectors.interviewPlayer)

  const recordingInterviewManager = useManageRecordingInterview()

  const recordingMediaAudio = useRecordingMedia({
    stream: audioStream.stream,
    media: 'audio',
    uploadBlobChunkPart: recordingInterviewManager.uploadBlobChunkPart,
  })

  const recordingMediaVideoAudio = useRecordingMedia({
    stream: audioVideoStream.stream,
    media: 'video',
    uploadBlobChunkPart: recordingInterviewManager.uploadBlobChunkPart,
  })

  const isRecording = interviewPlayerStatus === InterviewPlayerStatus.start_recording_answer

  const announcementUtils = useAnnouncementUtils()
  const queryClient = useQueryClient()

  const { processNextQuestion } = useProcessFollowUpQuestions()

  const maxQuestions = getNumberOfQuestionOfInterview(interview?.payload)?.totalQuestions
  const isLastQuestion = !!(maxQuestions && questionIndex === maxQuestions - 1)

  useEffect(() => {
    dispatch(actions.resetInterviewPlayer())
    // Request the permission for the user
    audioVideoStream.askForPermission()
    audioStream.askForPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [readyFirstQuestionAndWelcome, setReadyFirstQuestionAndWelcome] = useState(false)
  useEffect(() => {
    async function interviewReady() {
      if (!interview?.isReady) {
        return
      }
      const interviewId = interview?.payload.id
      const lastUnansweredQuestion =
        interview?.payload.questions?.findIndex(q => !q.answer?.audio) || -1
      const startIndex = lastUnansweredQuestion === -1 ? 0 : lastUnansweredQuestion
      setStartQuestionIndex(startIndex)

      const startQuestion = interview.payload.questions[startIndex]

      recordingInterviewManager.createNewAnswer({
        questionId: startQuestion.id,
      })

      try {
        // download the welcome announcement
        if (interview.payload.announcement) {
          const welcomeAsset = await announcementUtils.downloadWelcomeAnnouncement(
            interview.payload.announcement,
          )
          announcementUtils.saveWelcomeAnnouncement(welcomeAsset)
        }
        // download first announcement
        const firstQuestionId = interview?.payload.questions[startIndex].id
        const asset = await announcementUtils.downloadQuestionAnnouncement(
          interviewId,
          firstQuestionId,
        )
        announcementUtils.saveQuestionAnnouncement(asset)
      } catch (error) {}

      setReadyFirstQuestionAndWelcome(true)

      if (interview.payload.questions) {
        for (let i = startIndex + 1; i < interview.payload.questions.length; i++) {
          recordingInterviewManager.createNewAnswer({
            questionId: interview.payload.questions[i].id,
          })
        }
      }

      // download missing announcement
      announcementUtils.downloadRestQuestionAnnouncementAssets(
        interviewId,
        interview?.payload.questions.slice(startIndex + 1).map(question => question.id),
      )
    }
    interviewReady()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview?.isReady])

  useEffect(() => {
    // show modal after the loading
    if (readyFirstQuestionAndWelcome && interviewPlayer.showAllPhrases) {
      dispatch(actions.setInterviewPlayerModal(InterviewPlayerModalType.permission_modal_initial))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyFirstQuestionAndWelcome, interviewPlayer.showAllPhrases])

  const handleEndLoading = (interrupted: boolean) => {
    if (interrupted) {
      dispatch(
        actions.setInterviewPlayerModal(InterviewPlayerModalType.loading_interrupted_interview),
      )
    } else {
      dispatch(actions.setInterviewPlayerModal(InterviewPlayerModalType.loading_end))
    }
  }

  const handleStartRecordingAnswer = async () => {
    if (!question) {
      return
    }

    setMiddleContent(InterviewPlayerMiddleContent.question)
    try {
      await announcementUtils.playQuestionAnnouncement(question.id)

      await Promise.all([
        recordingMediaAudio.start(question.id),
        recordingMediaVideoAudio.start(question.id),
      ])

      setInterviewPlayerStatus(InterviewPlayerStatus.start_recording_answer)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (typeof questionIndex === 'number') {
      handleStartRecordingAnswer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionIndex])

  const handleStopRecordingAnswer = async () => {
    if (
      !question ||
      !interviewPlayerStatus ||
      interviewPlayerStatus === InterviewPlayerStatus.stop_recording_answer
    ) {
      return
    }

    const stopAudioPromise = recordingMediaAudio.stop(question.id)
    const stopVideoPromise = recordingMediaVideoAudio.stop(question.id)

    setInterviewPlayerStatus(InterviewPlayerStatus.stop_recording_answer)

    if (interviewVersion === 1) {
      recordingInterviewManager.stopAudioAnswer(question?.id, stopAudioPromise)
      recordingInterviewManager.stopVideoAnswer(question?.id, stopVideoPromise)
    }

    if (interviewVersion === 2) {
      await recordingInterviewManager.stopAudioAnswer(question?.id, stopAudioPromise)
      recordingInterviewManager.stopVideoAnswer(question?.id, stopVideoPromise)
    }

    trackInternalEvent('submit_answer', {
      label: 'interview_prep',
      kind: interview?.payload.kind,
      total_questions: maxQuestions,
      answered_questions: questionIndex,
    })
  }

  const timer = useTimer(
    isRecording,
    question?.id,
    setInterviewPlayerBadge,
    handleStopRecordingAnswer,
  )

  const handleStopInterview = async (interrupted: boolean) => {
    announcementUtils.stopAnnouncement()
    setInterviewPlayerStatus(InterviewPlayerStatus.stop_interview)

    try {
      await Promise.allSettled([
        recordingInterviewManager.stopInterview(),
        delay(interrupted ? 0 : DELAY_FOR_FEEDBACK),
        Promise.allSettled([audioStream.disableStream(), audioVideoStream.disableStream()]),
      ])
    } catch (error) {
      console.error(error)
    }

    if (interrupted) {
      trackInternalEvent('pause_interview', {
        label: 'interview_prep',
        kind: interview?.payload.kind,
        answered_questions: questionIndex,
        total_questions: maxQuestions,
        parameter: 'interrupted_interview',
      })
    } else {
      if (interview?.payload.id) {
        await mutateInterview({ interviewId: interview?.payload.id, status: 'completed' })
      }

      trackInternalEvent('complete_interview', {
        label: 'interview_prep',
        kind: interview?.payload.kind,
        total_questions: maxQuestions,
      })
    }

    await invalidateCacheForInterviews()
    navigate(interrupted ? '/interview-preparation/dashboard' : '/interview-preparation/feedback')
  }

  const generateFollowUpQuestion = async () => {
    if (!question || !interview || !interviewId || !mainQuestionId) {
      return false
    }
    try {
      const followUpQuestion = await processNextQuestion({
        interviewId,
        mainQuestionId,
        questionId: question.id,
        expectedFollowUpQuestionCount: question.base_question_id ? 2 : 1,
      })

      if (followUpQuestion && !followUpQuestion.conversation_end) {
        if (interview.payload.questions.find(question => question.id === followUpQuestion.id)) {
          return false
        } else {
          recordingInterviewManager.createNewAnswer({
            questionId: followUpQuestion.id,
          })

          queryClient.setQueryData<Ready<Interview>>(
            getUseInterviewKey(interview?.payload.id),
            interview => {
              if (interview && followUpQuestion) {
                const followUpLastQuestionIndex = findLastIndex(
                  interview.payload.questions,
                  question => question.base_question_id === mainQuestionId,
                )

                const mainQuestionIndex = interview.payload.questions.findIndex(
                  question => question.id === mainQuestionId,
                )

                const indexToInsert =
                  followUpLastQuestionIndex === -1 ? mainQuestionIndex : followUpLastQuestionIndex

                interview.payload.questions.splice(indexToInsert + 1, 0, followUpQuestion)

                interview.payload.questions = [...interview.payload.questions]
              }

              return interview
            },
          )
        }

        try {
          const asset = await announcementUtils.downloadQuestionAnnouncement(
            interviewId,
            followUpQuestion.id,
          )
          announcementUtils.saveQuestionAnnouncement(asset)
        } catch (error) {
          console.warn(error)
        }

        return true
      }
      return false
    } catch (error) {
      console.warn(error)
      return false
    }
  }

  const handleNextQuestion = async () => {
    if (!question || !interview || !interviewId || !mainQuestionId) {
      return
    }
    if (interviewVersion === 2) {
      const nextQuestionIndex = (questionIndex || 0) + 1

      const followUpQuestions = interview.payload.questions.filter(
        question => question.base_question_id === mainQuestionId,
      )

      const nextMainQuestion = interview.payload.questions[nextQuestionIndex]
      const hasFollowUpQuestion =
        followUpQuestions.length < 2 ? await generateFollowUpQuestion() : false

      if (!hasFollowUpQuestion && !nextMainQuestion) {
        handleEndLoading(false)
        handleStopInterview(false)
        return
      } else {
        setQuestionIndex(nextQuestionIndex)
        dispatch(actions.setInterviewPlayerModal(null))
      }
    }

    if (interviewVersion === 1) {
      const nextQuestionIndex = (questionIndex || 0) + 1
      const isLastQuestion = nextQuestionIndex === (interview?.payload?.questions.length || 0)
      if (!isLastQuestion) {
        setQuestionIndex(nextQuestionIndex)
      }
    }
  }

  const handleInterruptedRecordingAnswer = async () => {
    const questions = interview?.payload?.questions || []
    if (questionIndex == null || questions.length === 0) {
      return
    }

    setInterviewPlayerStatus(InterviewPlayerStatus.interrupted_recording_answer)
    announcementUtils.stopAnnouncement()
    dispatch(actions.setInterviewPlayerModal(InterviewPlayerModalType.close_modal))

    for (let i = questionIndex; i < questions.length; i++) {
      recordingInterviewManager.interruptedAnswer(question?.id)
    }

    handleStopInterview(true)
  }

  const handleWelcome = async () => {
    setMiddleContent(InterviewPlayerMiddleContent.welcome)
    dispatch(actions.setInterviewPlayerModal(null))
    await announcementUtils.playWelcomeAnnouncement()
    setShowStartButton(true)
  }

  const navbarComponent = (
    <Navbar
      onClose={() =>
        dispatch(actions.setInterviewPlayerModal(InterviewPlayerModalType.close_modal))
      }
    />
  )

  const renderMiddleContainer = () => {
    switch (middleContent) {
      case InterviewPlayerMiddleContent.welcome:
        return (
          <VoiceAnimation>
            <TextWithoutScroll
              parentNode={middleContainerRef.current}
              html={interview?.payload?.welcome_msg}
            />
          </VoiceAnimation>
        )
      case InterviewPlayerMiddleContent.question:
        return (
          <VoiceAnimation>
            <>
              <Note
                $display={
                  interview?.payload.real_company && interview?.payload.company_name
                    ? 'block'
                    : 'none'
                }
              >
                {i18n.t(`${TRANSLATION_PREFIX}.real_interview_note`, {
                  companyName: addArticleToName(interview?.payload.company_name || ''),
                })}
              </Note>
              <TextWithoutScroll parentNode={middleContainerRef.current} text={question?.content} />
            </>
          </VoiceAnimation>
        )
    }
  }

  const snackBar = useMemo(() => {
    switch (interviewPlayerSnackBar) {
      case InterviewPlayerSnackBar.are_you_sure_you_want_to_proceed:
        return (
          <Snackbar
            align="center"
            text={i18n.t(`${TRANSLATION_PREFIX}.snackbar.proceed_title`)}
            onNoClick={() => setInterviewPlayerSnackBar(undefined)}
            onYesClick={async () => {
              setInterviewPlayerSnackBar(undefined)

              if (interviewVersion === 2) {
                dispatch(
                  actions.setInterviewPlayerModal(InterviewPlayerModalType.question_interval),
                )
              }
              await handleStopRecordingAnswer()
              handleNextQuestion()
            }}
          />
        )

      case InterviewPlayerSnackBar.are_you_sure_you_want_to_finish:
        return (
          <Snackbar
            align="flex-end"
            text={i18n.t(`${TRANSLATION_PREFIX}.snackbar.finish_title`)}
            onNoClick={() => setInterviewPlayerSnackBar(undefined)}
            onYesClick={async () => {
              handleEndLoading(!isLastQuestion)
              await handleStopRecordingAnswer()
              handleStopInterview(!isLastQuestion)
            }}
          />
        )

      default:
        return null
    }
  }, [interviewPlayerSnackBar, isLastQuestion, questionIndex, i18n])

  return (
    <Container>
      <InterviewModals
        handleWelcome={handleWelcome}
        handleInterruptedRecordingAnswer={handleInterruptedRecordingAnswer}
      />
      {snackBar}
      {isTablet && navbarComponent}
      <TopContainer>
        {!isTablet && navbarComponent}

        <JobTitle
          jobTitle={interview?.payload.job_title}
          logoUrl={interview?.payload.logo_url}
          companyName={interview?.payload.company_name}
        />

        <WebcamContainer>
          <Webcam isRecording={isRecording} />
        </WebcamContainer>
      </TopContainer>

      <MiddleContainer ref={middleContainerRef}>{renderMiddleContainer()}</MiddleContainer>

      <BottomContainer>
        {interviewPlayerBadge && <Badge badge={interviewPlayerBadge} />}
        <TimeDisplay
          disabledButton={interviewPlayerStatus === InterviewPlayerStatus.stop_recording_answer}
          running={isRecording}
          seconds={timer.time}
          onPause={() => {
            if (question) {
              setPrevInterviewPlayerBadge(interviewPlayerBadge)
              setInterviewPlayerBadge(InterviewPlayerBadge.hit_play_to_continue)
              recordingMediaAudio.pause(question.id)
              recordingMediaVideoAudio.pause(question.id)
              setInterviewPlayerStatus(InterviewPlayerStatus.pause_recording_answer)

              trackInternalEvent('pause_interview', {
                label: 'interview_prep',
                kind: interview?.payload.kind,
                answered_questions: questionIndex,
                parameter: 'button',
              })
            }
          }}
          onResume={() => {
            if (question) {
              setPrevInterviewPlayerBadge(undefined)
              setInterviewPlayerBadge(prevInterviewPlayerBadge)
              recordingMediaAudio.resume(question.id)
              recordingMediaVideoAudio.resume(question.id)
              setInterviewPlayerStatus(InterviewPlayerStatus.start_recording_answer)

              trackInternalEvent('resume_interview', {
                label: 'interview_prep',
              })
            }
          }}
        />

        {questionIndex != null && (
          <Pagination
            showNextButton={
              isRecording ||
              interviewPlayerStatus === InterviewPlayerStatus.pause_recording_answer ||
              interviewPlayerStatus === InterviewPlayerStatus.stop_recording_answer
            }
            interview={interview}
            currentQuestionIndex={questionIndex}
            onClickNext={async () => {
              setInterviewPlayerSnackBar(InterviewPlayerSnackBar.are_you_sure_you_want_to_proceed)
            }}
          />
        )}

        {questionIndex == null && showStartButton && (
          <StartInterviewButton
            onClick={() => {
              setShowStartButton(false)
              announcementUtils.stopAnnouncement()
              setQuestionIndex(startQuestionIndex)
            }}
          >
            {i18n.t(`${TRANSLATION_PREFIX}.start_interview_button`)}
          </StartInterviewButton>
        )}

        <Button
          text={isPhone ? undefined : i18n.t(`${TRANSLATION_PREFIX}.complete`)}
          isPrimary={isLastQuestion}
          onClick={async () => {
            setInterviewPlayerSnackBar(InterviewPlayerSnackBar.are_you_sure_you_want_to_finish)
          }}
          icon={<Icon20.Close />}
        />
      </BottomContainer>
    </Container>
  )
}
