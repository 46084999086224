import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { ConfigScopesEnum } from 'builder/modules/init'
import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'
import SocialButton from 'builder/components/SocialButton'

import { Container, Buttons, Note } from './styles'

const SignInMethods = ({ onButtonClick }) => {
  const { i18n } = useI18n()
  const config = useConfig(ConfigScopesEnum.signUp)

  // Copy `location.search` to persist "current_region_prefix"
  const location = useLocation()

  // Super app uses a simplified sign up flow (without document creation)
  const signUpPath = config?.features.superApp ? '/create-account' : '/create-resume'

  const buttons = [
    {
      href: '/auth/facebook?',
      network: 'facebook',
      disabled: config.features?.disableFacebookLogin,
    },
    {
      href: '/auth/google_oauth2?',
      network: 'google',
      disabled: config.features?.disableGoogleLogin,
    },
    {
      href: '/auth/linkedin?',
      network: 'linkedin',
      disabled: config.features?.disableLinkedinLogin,
    },
    {
      disabled: false,
      network: 'email',
    },
  ].filter(button => !button.disabled)

  return (
    <Container $countButtons={buttons.length}>
      <Buttons>
        {buttons.map(button => (
          <SocialButton
            key={button.network}
            onClick={onButtonClick}
            network={button.network}
            href={button.href}
          >
            {i18n.t(`builder.sign_in.methods.${button.network}`)}
          </SocialButton>
        ))}
      </Buttons>

      {!config.features?.disableSignUp && (
        <Note>
          {i18n.t('builder.sign_in.im_not_registered')}
          {' — '}
          <Link to={signUpPath + location.search}>{i18n.t('builder.sign_in.sign_up')}</Link>
        </Note>
      )}
    </Container>
  )
}

SignInMethods.propTypes = {
  onButtonClick: PropTypes.func,
}

SignInMethods.defaultProps = {
  onButtonClick: () => {},
}

export default SignInMethods
