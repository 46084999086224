import { useMemo } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { formatSeconds } from 'builder/views/Interview/utils/formatSeconds'
import { Skeleton } from 'builder/Skeleton/Skeleton'
import { Interview, InterviewAnswer } from 'builder/modules/interview/types'
import {
  parseFillerWords,
  parsePauses,
  parseToneOfVoiceValue,
  parseWordPerMinute,
} from '../../parse'
import { ClassificationEnum } from '../CardSection/components/Card/Card'
import { ShowStyledHTML } from '../ShowStyledHTML/ShowStyledHTML'
import {
  Container,
  Duration,
  LeftContainer,
  LinearProgressContainer,
  PromisingStart,
  RightContainer,
  Title,
} from './styles'
import LineProgressBar from './components/LineProgressBar'

export const TRANSLATION_PREFIX = `builder.interview_prep_view.feedbackV2.summary_question`
export interface Props {
  answer: InterviewAnswer | undefined
  interview: Interview | undefined
}
const NUMBER_OF_STEPS = 5
const WEIGHT_FOR_EACH_STEP = 100 / NUMBER_OF_STEPS

export const SummaryQuestionSection = ({ answer, interview }: Props) => {
  const { i18n } = useI18n()
  const title = [
    i18n.t(`${TRANSLATION_PREFIX}.your`),
    interview?.job_title,
    i18n.t(`${TRANSLATION_PREFIX}.interview_result`),
  ].join(' ')

  const score = useMemo(() => {
    const { feedback } = answer || {}
    const steps = [
      parseToneOfVoiceValue(feedback?.ton_of_voice).classification,
      parseWordPerMinute(feedback?.words_per_minute).classification,
      parseFillerWords(feedback?.filler_words).classification,
      parsePauses((feedback?.pauses || []).length).classification,
    ]

    // We have 5 steps. One step is always 20%, and
    // the weighting of the other four steps depends on STT values.
    return (
      WEIGHT_FOR_EACH_STEP +
      steps.filter(step => step === ClassificationEnum.positive).length * WEIGHT_FOR_EACH_STEP
    )
  }, [answer])

  return (
    <Container>
      <LeftContainer>
        <div>
          <>
            {interview ? <Title>{title}</Title> : <Skeleton width={241} height={20} />}
            {answer?.ai_speech_job?.status == null ||
            answer?.ai_speech_job?.status === 'pending' ? (
              <Skeleton width={119} height={22} style={{ marginTop: '4px' }} />
            ) : (
              <>
                <PromisingStart>{answer?.feedback?.ton_of_voice_interpretation}</PromisingStart>
              </>
            )}
          </>
        </div>

        {typeof answer?.duration === 'number' ? (
          <Duration>{formatSeconds(answer.duration)}</Duration>
        ) : (
          <Skeleton width={120} height={44} />
        )}
      </LeftContainer>
      <RightContainer>
        {answer?.ai_speech_job?.status === 'success' ? (
          <ShowStyledHTML
            dangerouslySetInnerHTML={{
              __html: answer?.feedback?.ai_interpretation || answer?.feedback?.content,
            }}
          />
        ) : (
          <>
            <Skeleton width={410} height={20} />

            <Skeleton width={346} height={20} style={{ marginTop: '4px' }} />
            <Skeleton width={298} height={20} style={{ marginTop: '4px' }} />
            <Skeleton width={400} height={20} style={{ marginTop: '4px' }} />
            <Skeleton width={378} height={20} style={{ marginTop: '4px' }} />
          </>
        )}
        <LinearProgressContainer>
          {answer?.ai_speech_job?.status === 'success' ? (
            <LineProgressBar
              width="100%"
              left={i18n.t(`${TRANSLATION_PREFIX}.strengths`)}
              right={i18n.t(`${TRANSLATION_PREFIX}.areas_for_improvement`)}
              score={score}
            />
          ) : (
            <Skeleton width={404} height={44} />
          )}
        </LinearProgressContainer>
      </RightContainer>
    </Container>
  )
}
