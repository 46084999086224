import axios from 'axios'
import { delay } from './delay'

type Polling<T> = { polling: boolean; data: T }
export const requestPolling = <T>({
  requestFunction,
  delayBetweenRequests = 500,
  timeout,
}: {
  requestFunction: (abortControl: AbortController) => Promise<Polling<T>>
  delayBetweenRequests?: number
  timeout: number | undefined
}) => {
  return new Promise<T>(async (resolve, reject) => {
    let response: Polling<T> | undefined
    const handleResponse = (response: Polling<T> | undefined) => {
      if (response?.data) {
        resolve(response.data)
      } else {
        reject(new Error('Timeout polling'))
      }
    }
    try {
      const abortControl = new AbortController()
      response = await requestFunction(abortControl)

      let loadingStateSentinel

      if (timeout !== undefined) {
        loadingStateSentinel = setTimeout(() => {
          abortControl.abort()
          handleResponse(response)
        }, timeout)
      }

      while (response.polling && !abortControl.signal.aborted) {
        await delay(delayBetweenRequests)
        response = await requestFunction(abortControl)
      }

      clearTimeout(loadingStateSentinel)
      handleResponse(response)
    } catch (error) {
      console.warn(error)
      if (axios.isCancel(error)) {
        handleResponse(response)
      } else {
        throw error
      }
    }
  })
}
