import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  gap: 5px;
  height: 41px;

  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral80};

  & * {
    vertical-align: middle;
  }

  ${Media.AboveTablet`
    justify-content: center;
    align-items: center;
  `}

  ${Media.Tablet`
    flex-direction: column;
  `}
`

export const LogoContainer = styled.div`
  display: inline-flex;
  padding: 0px 5px;

  ${Media.Tablet`
   padding-left: 0px;
   padding-right: 5px;
  `}
`

export const LogoTextContainer = styled.div`
  display: inline-flex;

  ${Media.AboveTablet`
    ${Typography.Body};
    ${FontWeights.Medium};
    color: ${Colors.Neutral80};
  `}

  ${Media.Tablet`
    ${Typography.Caption};
    ${FontWeights.Regular};
    color: ${Colors.Neutral40};
    padding-left: 0px;
    padding-right: 5px;
  `}
`

export const AtContainer = styled.span`
  display: none;
  ${Media.AboveTablet`
    display: unset;
  `}
`
