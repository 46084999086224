import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'

import {
  actions as jobSearchActions,
  selectors as jobSearchSelectors,
} from 'builder/modules/jobSearch'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'
import { AlertTypes, JobSearchNotificationType } from 'builder/modules/jobSearch/types'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { DialogModalV2 } from 'builder/components/DialogModal/DialogModalV2/DialogModalV2'
import { useDeleteCustomAlert } from '../hooks/useDeleteCustomAlert'

import { getLabel } from '../getLabel'
import { WrapperButtons } from './styles'

const TRANSLATION = 'builder.job_search.alert_modal.delete_alert'

export const DeleteAlert = () => {
  const { i18n } = useI18n()
  const alert = useTypedSelector(jobSearchSelectors.alert)
  const deleteCustomAlert = useDeleteCustomAlert()

  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()

  return (
    <DialogModalV2
      onClose={() => dispatch(jobSearchActions.setAlertType({ type: undefined }))}
      title={i18n.t(`${TRANSLATION}.title`)}
      subtitle={i18n.t(`${TRANSLATION}.subtitle`)}
    >
      <WrapperButtons>
        <Button
          size={isPhone ? ButtonSize.small : ButtonSize.default}
          theme={ButtonTheme.ghost}
          onClick={() =>
            dispatch(
              jobSearchActions.setAlertType({
                type: AlertTypes.edit,
                editCustomAlert: alert.editCustomAlert,
              }),
            )
          }
        >
          {i18n.t(`${TRANSLATION}.cancel`)}
        </Button>
        <Button
          size={isPhone ? ButtonSize.small : ButtonSize.default}
          theme={ButtonTheme.destructive}
          isLoading={deleteCustomAlert.isLoading}
          onClick={async () => {
            if (alert.editCustomAlert && typeof alert.editCustomAlert.id === 'number') {
              try {
                await deleteCustomAlert.mutateAsync({ id: alert.editCustomAlert?.id })

                trackInternalEvent('delete_custom_alert', {
                  label: getLabel(),
                })
                dispatch(
                  jobSearchActions.setAlertType({
                    type: AlertTypes.manage,
                  }),
                )
                dispatch(
                  jobSearchActions.setNotification(JobSearchNotificationType.delete_job_alert),
                )
              } catch (e) {
                dispatch(jobSearchActions.setNotification(JobSearchNotificationType.snackbar_fail))
              }
            }
          }}
        >
          {i18n.t(`${TRANSLATION}.delete`)}
        </Button>
      </WrapperButtons>
    </DialogModalV2>
  )
}
