import { useCallback, useEffect, useMemo, useState } from 'react'
import { arrayMove } from 'react-sortable-hoc'
import { Optional } from 'packages/types'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, CareerProfileData, StructuredResumeData } from 'builder/modules/careerProfile'
import { ResumeSectionId } from 'builder/modules/resumeEditor'
import { generateRandomId } from 'builder/utils/generateRandomId'
import { FetchStatuses } from 'builder/modules/constants'
import { ONBOARDING_STRUCTURED_RESUME_DATA_KEY } from 'builder/components/FindJob/types'
import {
  AddCardFunc,
  CardOperationFunc,
  IsCardOpenFunc,
  MoveCardFunc,
  UpdateCardFunc,
} from 'builder/components/Card'
import { resumeToProfileSectionNamesMap } from '../consts'

/**
 * I decided to make full local state management because I didn't want to overload
 * global state with features that not needed globally.
 * I also didn't use useReducer, because I didn't find good examples of it in our code base.
 */
export const useCareerProfileEditorState = () => {
  const existingProfile = useTypedSelector(selectors.careerProfileData)
  const [openedCard, setOpenedCard] = useState<{
    id: Optional<string | number>
    sectionId: ResumeSectionId
  } | null>(null)
  const [localProfile, setLocalProfile] = useState<CareerProfileData | null>(null)
  const profileFetchStatus = useTypedSelector(selectors.fetchCareerProfileStatus)

  const storedStructuredResumeData = useMemo(() => {
    const resumeData = localStorage.getItem(ONBOARDING_STRUCTURED_RESUME_DATA_KEY)
    if (resumeData) {
      return JSON.parse(resumeData) as StructuredResumeData
    }
    return null
  }, [])
  const structuredResumeData =
    useTypedSelector(selectors.onboardingStructuredResumeData) || storedStructuredResumeData
  const [resumeWasPrefilled, setResumeWasPrefilled] = useState(false)

  useEffect(() => {
    if (structuredResumeData && localProfile && !resumeWasPrefilled) {
      setLocalProfile({
        ...localProfile,
        workExperiencesAttributes: [
          ...structuredResumeData.workExperiencesAttributes.map(item => ({
            ...item,
            id: `${item.title}${item.description}${item.employer}`,
          })),
        ],
        educationsAttributes: [
          ...structuredResumeData.educationsAttributes.map(item => ({
            ...item,
            id: `${item.school}${item.description}${item.city}`,
          })),
        ],
        skillsAttributes: [
          ...structuredResumeData.skillsAttributes.map(item => ({
            ...item,
            id: `${item.level}${item.skill}`,
          })),
        ],
      })
      setResumeWasPrefilled(true)
    }
  }, [structuredResumeData, localProfile, resumeWasPrefilled])

  useEffect(() => {
    if (existingProfile && profileFetchStatus === FetchStatuses.loaded) {
      setLocalProfile(state => (!state ? existingProfile : state))
    }
  }, [existingProfile, profileFetchStatus])

  const isCardOpen: IsCardOpenFunc = useCallback(
    (cardId, sectionId) =>
      !!openedCard && openedCard.id === cardId && openedCard.sectionId === sectionId,
    [openedCard],
  )

  // TODO: add type to 'values' if editor will be refactored from JS to TS
  const handleCardUpdate: UpdateCardFunc = useCallback((sectionName, cardId, values) => {
    const profileSectionName = resumeToProfileSectionNamesMap[sectionName]

    if (!profileSectionName) {
      return
    }

    setLocalProfile(state => {
      if (!state) {
        return state
      }

      const index = state[profileSectionName].findIndex(item => item.id === cardId)

      if (index !== -1) {
        const updatedArray = [...state[profileSectionName]]

        updatedArray[index] = {
          ...updatedArray[index],
          ...values,
        }

        state = {
          ...state,
          [profileSectionName]: updatedArray,
        }
      }

      return state
    })
  }, [])

  const handleCardAdd: AddCardFunc = useCallback((sectionName, options = {}) => {
    const { position = 'after', fields = {} } = options
    const profileSectionName = resumeToProfileSectionNamesMap[sectionName]

    if (!profileSectionName) {
      return
    }

    const temporalId = generateRandomId()

    setLocalProfile(state => {
      if (!state) {
        return state
      }

      const newItem =
        profileSectionName === 'skillsAttributes'
          ? { id: temporalId, level: 'expert', ...fields }
          : { id: temporalId, ...fields }

      const updatedArray =
        position === 'after'
          ? [...state[profileSectionName], newItem]
          : [newItem, ...state[profileSectionName]]

      return { ...state, [profileSectionName]: updatedArray }
    })

    setOpenedCard({ id: temporalId, sectionId: sectionName })
  }, [])

  const handleCardDelete: CardOperationFunc = useCallback((cardId, sectionName) => {
    const profileSectionName = resumeToProfileSectionNamesMap[sectionName]

    if (!profileSectionName) {
      return
    }

    setLocalProfile(state => {
      if (!state) {
        return state
      }

      const array = [...state[profileSectionName]]

      return {
        ...state,
        [profileSectionName]: array.filter(item => item.id !== cardId),
      }
    })
  }, [])

  const handleCardMove: MoveCardFunc = useCallback(({ sectionId, oldIndex, newIndex }) => {
    const profileSectionName = resumeToProfileSectionNamesMap[sectionId]

    if (!profileSectionName) {
      return
    }

    setLocalProfile(state => {
      if (!state) {
        return state
      }

      return {
        ...state,
        [profileSectionName]: arrayMove(state[profileSectionName], oldIndex, newIndex),
      }
    })
  }, [])

  const handleCardToggle: CardOperationFunc = useCallback(
    (cardId, sectionId) => {
      const isOpen = isCardOpen(cardId, sectionId)

      setOpenedCard(isOpen ? null : { id: cardId, sectionId })
    },
    [isCardOpen],
  )

  const handleSimpleFieldUpdate = useCallback(
    <T extends keyof CareerProfileData>(fieldName: T, value: CareerProfileData[T]) => {
      setLocalProfile(state => {
        return state && { ...state, [fieldName]: value }
      })
    },
    [],
  )

  return {
    openedCard,
    localProfile,
    handleCardUpdate,
    handleCardAdd,
    handleCardDelete,
    handleCardMove,
    handleCardToggle,
    isCardOpen,
    handleSimpleFieldUpdate,
    isLocationDisabled: localProfile?.targetLocations
      ? localProfile.targetLocations.filter(el => el.formattedName).length >= 5
      : false,
    isTargetRoleDisabled: localProfile?.targetRoles
      ? localProfile.targetRoles.filter(el => el.standardTitle).length >= 5
      : false,
  }
}
