export {
  Icon24 as default,
  Icon24,
  Icon20,
  Icon32,
  Icon16,
  Icon48,
  Icon56,
  Icon36,
  Icon40,
} from './Icon'
