import { ResumeLanguage, ResumeWorkExperience } from 'packages/types'
import { createContext, useContext, Context } from 'react'
import { ResumeSectionId } from 'builder/modules/resumeEditor'

type ExpandableCardContextData<ItemType> = {
  item: ItemType
  sectionId: ResumeSectionId
  onCardValueChange: (values: unknown, debounce: boolean) => void
}

const ExpandableCardContext = createContext<ExpandableCardContextData<
  ResumeWorkExperience | ResumeLanguage
> | null>(null)

const useExpandableCardContext = <ItemType>(): ExpandableCardContextData<ItemType> => {
  const context = useContext<ExpandableCardContextData<ItemType>>(
    ExpandableCardContext as unknown as Context<ExpandableCardContextData<ItemType>>,
  )

  if (!context) {
    throw new Error('No context data found')
  }

  return context
}

const useLanguageCardContext = () => useExpandableCardContext<ResumeLanguage>()
const useWorkExperienceCardContext = () => useExpandableCardContext<ResumeWorkExperience>()

export default ExpandableCardContext
export { useWorkExperienceCardContext, useLanguageCardContext }
