import { forwardRef } from 'react'
import { TooltipV2 } from 'builder/components/Tooltip'
import Icon from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import {
  StyledButton,
  ChevronDownIcon,
  ImproveResumeContainer,
  HintIcon,
  HintIconContainer,
  CompletenessIcon,
} from './styles'

const ImproveResumeButtonGuest = forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement> & { improveButtonOnClick: () => void }
>((props, ref) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()

  return (
    <ImproveResumeContainer ref={ref}>
      <StyledButton onClick={props.improveButtonOnClick}>
        {!isPhone && (
          <CompletenessIcon>
            <Icon.Completeness50Percent />
          </CompletenessIcon>
        )}

        {!isPhone
          ? i18n.t('builder.resume_editor.keywords.improve_resume_panel.toggle_button_text')
          : i18n.t('builder.resume_editor.keywords.improve_resume_panel.toggle_button_mobile_text')}
        <ChevronDownIcon $isActive={false}>
          <Icon.ChevronDown />
        </ChevronDownIcon>
      </StyledButton>
      {!isPhone && (
        <TooltipV2
          content={i18n.t(
            'builder.resume_editor.keywords.improve_resume_panel.hint.closed_panel_hint_text',
          )}
          multiline={false}
        >
          <HintIconContainer>
            <HintIcon />
          </HintIconContainer>
        </TooltipV2>
      )}
    </ImproveResumeContainer>
  )
})

export default ImproveResumeButtonGuest
