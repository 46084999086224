import { Question } from '../Question/Question'
import { ButtonContainer, Container } from './styles'
import { BtnTryAgain } from '../BtnTryAgain/BtnTryAgain'

export const TRANSLATION_PREFIX = `builder.interview_prep_view.feedbackV2`

interface Props {
  questionNumber: number
  question?: string
  questionTotal?: number
  interviewId?: number
}

export const LastSection = ({ questionNumber, question, questionTotal, interviewId }: Props) => {
  return (
    <Container>
      <Question
        enableEllipse={false}
        questionNumber={questionNumber}
        question={question}
        questionTotal={questionTotal || 0}
      />
      {interviewId && (
        <ButtonContainer>
          <BtnTryAgain interviewId={interviewId} />
        </ButtonContainer>
      )}
    </Container>
  )
}
