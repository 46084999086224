import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import Media from 'builder/styles/media'

export const Container = styled.div`
  background: ${Colors.Neutral5};
  width: 100%;
  height: 100vh;
  overflow-x: auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: auto;
  z-index: 1001;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 165px;

  display: flex;
  flex-direction: column;
  align-items: center;
  ${Media.Tablet`
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 21px;
    height: unset;
    position: absolute;
  `}

  ${Media.Phone`
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 8px;
  `}
`

export const NavbarQuestionContainer = styled.div<{ $visibility: boolean; $position: string }>`
  display: flex;
  max-width: 832px;
  gap: 108px;
  background: ${Colors.Neutral5};
  flex: 1;
  visibility: ${({ $visibility }) => ($visibility ? 'visible' : 'hidden')};
  position: ${({ $position }) => $position};
  z-index: 3;
  ${Media.Tablet`
    display: flex;
    flex-direction: column;
    max-width: 832px;
    gap: unset;
    width: 100%;
    padding-left: 8px;
    top: 48px;
    padding: 12px 24px;
    padding-top: 0;
  `}

  ${Media.Phone`
    top: 48px;
    padding: 12px 16px;
    padding-top: 0;
  `}
`
export const NavbarQuestionLine = styled.div`
  margin: 12px 0;
  height: 2px;
  width: 100%;
  background: ${Colors.Neutral15};
`

export const NavBarContentContainer = styled.div`
  display: flex;
  gap: 24px;
  min-width: 0;
  padding: 12px 0;
  justify-content: space-between;
  flex: 1;
`

export const Title = styled.div`
  color: ${Colors.Neutral90};
  ${FontWeights.Medium};
  ${Typography.L};
  margin-top: 16px;
  margin-bottom: 64px;
  max-width: 832px;

  & > * {
    vertical-align: middle;
  }

  ${Media.Tablet`
    padding-top: 56px;
    margin-top: 36px;
    margin-bottom: 48px;
    margin-left: 24px;
    margin-right: 24px;
  `}

  ${Media.Phone`
    padding-top: 48px;
    margin-top: 16px;
    margin-bottom: 49px;
    margin-left: 16px;
    margin-right: 16px;
    ${Typography.M};
  `};
`

export const Logo = styled.span`
  margin: 0 5px;
  width: 41.333px;
  height: 41.333px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${Sizes['3XS']};
  & img {
    width: 32px;
    height: 32px;
  }
`
export const JobTitle = styled.span`
  color: ${Colors.Neutral50};
  ${FontWeights.Medium};
  ${Typography.L};
  align-items: center;
  ${Media.Phone`
    ${Typography.M};
  `}
`

export const CardContainer = styled.div``
export const SectionContainer = styled.div`
  background-color: ${Colors.White};
  padding: 32px;
  border-radius: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 86px;
  ${Media.Tablet`
    padding: 24px;
    gap: 64px;
  `}
  ${Media.Phone`
    padding: 16px;
    gap: 24px;
  `}
`

export const Content = styled.div`
  max-width: 896px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
`

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const SliderArrowContainer = styled.div`
  display: flex;
  gap: 10px;
  height: fit-content;
`

export const InvalidFeedback = styled.div`
  background-color: ${Colors.Blue10};
  padding: 32px;
  border-radius: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 86px;
  ${Media.Tablet`
    padding: 24px;
    gap: 64px;
  `}
  ${Media.Phone`
    padding: 16px;
    gap: 24px;
  `}
`

export const InvalidFeedbackLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
