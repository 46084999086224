import styled from 'styled-components'
import Button from 'builder/components/Button'
import Spinner from 'builder/components/Spinner'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

import spinner from '../assets/spinner.png'

export const BillingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 26px;
`

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2px;
`

export const PlanPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
`

export const PlanName = styled.h3`
  ${Typography.Body}
  ${FontWeights.Medium}

  color: ${Colors.Neutral90};
`

export const PlanPrice = styled.h4`
  ${Typography.Subhead}
  ${FontWeights.Medium}

  color: ${Colors.Neutral90};
`

export const PlanPeriod = styled.h3`
  height: 26px;
  ${Typography.Body}
  ${FontWeights.Regular}

  color: ${Colors.Neutral60};

  ${Media.Phone`
    height: 22px;
  `}
`

export const PriceLoader = styled(Spinner)`
  width: 24px;
  height: 24px;
  background-image: url(${spinner});
`

export const UpgradeButton = styled(Button)`
  width: 100%;
`

export const Disclaimer = styled.span`
  ${Typography.Tiny}
  ${FontWeights.Regular}
  color: ${Colors.Neutral40};

  margin-top: auto;

  ${Media.Phone`
    margin-top: unset;
  `}
`
