import { AxiosResponse } from 'axios'
import { all, put, call, select, takeLatest } from 'redux-saga/effects'
import minBy from 'lodash/minBy'
import { trackInternalEvent } from '@rio/tracking'
import { times } from 'lodash'
import { apiClient, baseClient } from 'builder/modules/apiClient'
import { navigate } from 'builder/modules/navigate'
import { actions as userActions } from 'builder/modules/user'
import { actions as resumeActions } from 'builder/modules/resumeEditor'
import {
  selectors as jobSearchSelectors,
  actions as jobSearchActions,
} from 'builder/modules/jobSearch'
import { FetchStatuses } from '../constants'
import { AutoApplyBubbles } from '../jobSearch/types'
import { actions } from './jobTrackingModule'
import { selectAppliedMaxLimit, selectJobs, selectJobsOrder } from './jobTrackingSelectors'
import { columnize, createDummyJob } from './utils'
import { AutoApplyWidget, ColumnName, Job, JobCreatePayload, ResumeList } from './types'

type BaseCardsResponse = { data: Job[]; notTrackedRecommendationsCount: number | null }
type JobsResponse = AxiosResponse<BaseCardsResponse | AutoApplyWidget>
type JobResponse = AxiosResponse<{ jobCard: Job }>

type ResumeListResponse = AxiosResponse<{ resumes: ResumeList[] }>

function* fetchJobsSaga(action: ReturnType<typeof actions.fetchJobsRequest>) {
  try {
    yield put(actions.setFetchStatus(FetchStatuses.loading))
    const response: JobsResponse = yield call(apiClient.get, '/job-board/cards', {
      params: action.payload,
    })

    if (action.payload?.per_page && action.payload.details && action.payload.auto_applied) {
      yield put(actions.setAutoApplyWidgetData(response.data as AutoApplyWidget))
    } else {
      const baseResponse = response.data as BaseCardsResponse
      yield put(actions.setJobsData(baseResponse.data))
      yield put(
        actions.setNotTrackedRecommendationsCount(baseResponse.notTrackedRecommendationsCount),
      )
    }

    yield put(actions.setFetchStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchStatus(FetchStatuses.failed))
    yield put(actions.setJobsError(error as string))
  }
}

function* deleteJobSaga(action: ReturnType<typeof actions.deleteJobRequest>) {
  try {
    const { id } = action.payload
    yield call(apiClient.delete, `/job-board/cards/${id}`)
    yield put(actions.deleteJob({ id }))
    yield put(jobSearchActions.handleNotificationRemovedJobCard())
  } catch (error) {
    yield put(actions.setJobsError(error as string))
  }
}

function* createJobSaga({
  payload,
}: ReturnType<typeof actions.createJobRequest>): Generator<object, void, JobResponse & Job[]> {
  try {
    const autoApplyBubbles = (yield select(
      jobSearchSelectors.autoApplyBubbles,
    )) as unknown as AutoApplyBubbles
    const { open, ...fields } = payload
    const isDummyCard = autoApplyBubbles.running && payload.dummy
    if (isDummyCard) {
      yield put(actions.setJobsData([createDummyJob(payload)]))
    }

    const status = fields.status || ColumnName.wishlist

    const jobs = yield select(selectJobs)

    const columns = columnize(jobs as Job[])
    const firstItem = minBy(columns[status], 'positionNumber')
    if (!isDummyCard) {
      yield put(jobSearchActions.setNotification(undefined))
    }
    const { data }: JobResponse = yield call(apiClient.post, '/job-board/cards', {
      ...fields,
      status,
      position_number: firstItem ? firstItem?.positionNumber - 1 : 0,
      external_slug_id: fields.external_slug_id,
    })
    if (!isDummyCard) {
      yield put(jobSearchActions.handleNotificationAddedJobCard(data.jobCard))
      yield put(actions.addJob(data.jobCard))
    }

    if (fields.status === 'applied') {
      const appliedMaxLimit = yield select(selectAppliedMaxLimit)
      if (appliedMaxLimit) trackInternalEvent('hit_limit')
    }

    if (payload.status === ColumnName.auto_apply) {
      yield put(userActions.fetchUserRequest({ silent: true }))
    }

    // trackInternalEvent('create_job_tracking_card')

    // TODO: Open modal instead of the board
    if (open) navigate('/job-tracking')
  } catch (error) {
    yield put(actions.setJobsError(error as string))
  }
}

function* patchJobSaga({
  payload,
}: ReturnType<typeof actions.patchJobRequest>): Generator<object, void, JobResponse & Job[]> {
  try {
    const autoApplyBubbles = (yield select(
      jobSearchSelectors.autoApplyBubbles,
    )) as unknown as AutoApplyBubbles
    const { id, ...fields } = payload
    const isDummyCard = autoApplyBubbles.running && payload.dummy
    if (isDummyCard) {
      yield put(actions.setJobsData([createDummyJob(payload as unknown as JobCreatePayload)]))
    }

    if (!isDummyCard) {
      yield put(jobSearchActions.setNotification(undefined))
    }
    const { data }: JobResponse = yield call(apiClient.patch, `/job-board/cards/${id}`, {
      ...fields,
    })

    if (!isDummyCard) {
      yield put(actions.addJob(data.jobCard))
      yield put(jobSearchActions.handleNotificationAddedJobCard(data.jobCard))
    }

    if (fields.status === 'applied') {
      const appliedMaxLimit = yield select(selectAppliedMaxLimit)
      if (appliedMaxLimit) trackInternalEvent('hit_limit')
    }

    if (payload.status === ColumnName.auto_apply) {
      yield put(userActions.fetchUserRequest({ silent: true }))
    }
  } catch (error) {
    yield put(actions.setJobsError(error as string))
  }
}

function* editJobSaga({ payload }: ReturnType<typeof actions.editJobRequest>) {
  try {
    const url = `/job-board/cards/${payload.id}`
    const { data }: JobResponse = yield call(apiClient.put, url, { job_card: payload })
    yield put(actions.updateJob(data.jobCard))
    trackInternalEvent('update_job_tracking_card')
  } catch (error) {
    yield put(actions.setJobsError(error as string))
  }
}

function* moveSaga({
  payload,
}: ReturnType<typeof actions.moveRequest>): Generator<object, void, AxiosResponse> {
  yield put(actions.move(payload))
  const positions = yield select(selectJobsOrder)
  yield call(apiClient.put, '/job-board/sync-cards', {
    job_cards_positions: positions,
  })

  if (payload.destination.droppableId === ColumnName.applied) {
    const appliedMaxLimit = yield select(selectAppliedMaxLimit)
    if (appliedMaxLimit) trackInternalEvent('hit_limit')
  }

  trackInternalEvent('change_job_tracking_card_status', {
    status: payload.destination.droppableId,
  })

  if (
    payload.source.droppableId === ColumnName.auto_apply ||
    payload.destination.droppableId === ColumnName.auto_apply
  ) {
    yield put(userActions.fetchUserRequest({ silent: true }))
  }
}

function* moveManySaga() {
  const positions: {
    id: number
    position_number: number
    status: ColumnName
  }[] = yield select(selectJobsOrder)
  let currAutoApplyIndex = positions.filter(
    position => position.status === ColumnName.auto_apply,
  ).length
  let updated = 0
  const newPositions = positions.map(position => {
    if (position.status !== ColumnName.wishlist) {
      return position
    }
    ++updated
    return { ...position, status: ColumnName.auto_apply, position_number: ++currAutoApplyIndex }
  })

  yield call(apiClient.put, '/job-board/sync-cards', {
    job_cards_positions: newPositions,
  })

  yield put(actions.fetchJobsRequest())

  const appliedMaxLimit: boolean = yield select(selectAppliedMaxLimit)
  if (appliedMaxLimit) trackInternalEvent('hit_limit')

  times(updated).forEach(() => {
    trackInternalEvent('change_job_tracking_card_status', {
      status: ColumnName.auto_apply,
    })
  })
}

function* reorderSaga({
  payload,
}: ReturnType<typeof actions.reorderRequest>): Generator<object, void, AxiosResponse> {
  yield put(actions.reorder(payload))
  const positions = yield select(selectJobsOrder)
  yield call(apiClient.put, '/job-board/sync-cards', {
    job_cards_positions: positions,
  })

  if (payload.destination.droppableId === ColumnName.auto_apply) {
    yield put(userActions.fetchUserRequest({ silent: true }))
  }
}

function* fetchAlreadyAppliedJobSaga(
  action: ReturnType<typeof actions.fetchAlreadyAppliedJobRequest>,
) {
  try {
    const url = `/job-board/cards/applied_status?slug=${action.payload}`
    const { data } = yield call(apiClient.get, url)
    yield put(actions.setAlreadyAppliedJob(data.jobAlreadyApplied))
  } catch (error) {
    yield put(actions.setJobsError(error as string))
  }
}

function* fetchResumeListSaga() {
  try {
    const url = `/resumes`
    const { data }: ResumeListResponse = yield call(apiClient.get, url)
    yield put(actions.setResumeList(data.resumes))
  } catch (error) {
    yield put(actions.setJobsError(error as string))
  }
}

function* updateSelectedResumeSaga({
  payload,
}: ReturnType<typeof actions.UpdateSelectedResumeRequest>) {
  try {
    yield put(
      actions.setUpdateResumeStatus({ id: payload?.job_card_id, status: FetchStatuses.loading }),
    )

    let {
      data: { id: resumeId },
    } = yield call(apiClient.post, `/resumes/${payload.resume_id}/duplicate`)

    if (resumeId) {
      let {
        status,
        data: {
          jobDetails: { id: jobPostingId },
        },
      } = yield call(baseClient.post, `/job-postings/update-job-posting`, null, {
        params: {
          job_url: payload.link,
          resume_id: resumeId,
          is_job_tracker: true,
        },
      })

      if (status === 200) {
        yield put(actions.setResumeSelection(null))
        yield put(
          actions.setUpdateResumeStatus({ id: payload?.job_card_id, status: FetchStatuses.loaded }),
        )

        const editorUrl = `/resumes/${resumeId}/edit`
        navigate(editorUrl)

        const responsePayload = {
          user_id: payload.user_id,
          resume_id: resumeId,
          job_posting_id: jobPostingId,
          job_card_id: payload.job_card_id,
        }

        yield call(baseClient.post, '/job-postings/update_selected_resume', responsePayload)

        // Open optimizer process from second step directly
        yield put(
          resumeActions.checkResumeScore({
            jobPostingLink: payload.link || undefined,
            resumeId,
          }),
        )
      }
    }
  } catch (error) {
    yield put(
      actions.setUpdateResumeStatus({ id: payload?.job_card_id, status: FetchStatuses.failed }),
    )
  }
}

function* fetchJTShowBannerSaga() {
  try {
    const url = `/job-board/jt_banner_show`
    const { data } = yield call(apiClient.get, url)
    yield put(actions.setShowJTChromeBanner(data.jtBannerShow))
  } catch (error) {
    yield put(actions.setJobsError(error as string))
  }
}

function* removeAutoApplyBubbleDummyCardSaga() {
  try {
    let { data: jobs } = yield call(apiClient.get, `/job-board/cards?dummy=true`)

    for (const job of jobs) {
      yield put(actions.deleteJobRequest({ id: job.id }))
    }
  } catch (error) {
    yield put(actions.setJobsError(error as string))
  }
}

export const sagas = function* saga() {
  yield all([
    takeLatest(actions.fetchJobsRequest, fetchJobsSaga),
    takeLatest(actions.deleteJobRequest, deleteJobSaga),
    takeLatest(actions.createJobRequest, createJobSaga),
    takeLatest(actions.patchJobRequest, patchJobSaga),
    takeLatest(actions.editJobRequest, editJobSaga),
    takeLatest(actions.moveRequest, moveSaga),
    takeLatest(actions.moveManyRequest, moveManySaga),
    takeLatest(actions.reorderRequest, reorderSaga),
    takeLatest(actions.fetchAlreadyAppliedJobRequest, fetchAlreadyAppliedJobSaga),
    takeLatest(actions.fetchResumeListRequest, fetchResumeListSaga),
    takeLatest(actions.UpdateSelectedResumeRequest, updateSelectedResumeSaga),
    takeLatest(actions.fetchJTShowBannerRequest, fetchJTShowBannerSaga),
    takeLatest(actions.removeAutoApplyBubbleDummyCard, removeAutoApplyBubbleDummyCardSaga),
  ])
}
