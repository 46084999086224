import { FC, useState } from 'react'
import { i18n } from 'builder/utils/i18n'
import DialogModal from 'builder/components/DialogModal'
import Foldable from 'builder/components/Foldable'
import { getCardHighlightId } from 'builder/modules/resumeEditor'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { MouseClickEvent } from 'builder/modules/ui'
import { CardModal } from './CardModal'
import { CardTitle } from './CardTitle'
import { Wrapper, Container } from './styles'
import { CardComponentProps } from './types'

export const Base: FC<CardComponentProps> = props => {
  const {
    children,
    onToggle,
    item,
    sectionId,
    onDelete,
    title,
    disableMove,
    foldableDuration,
    isCustom,
    isDragging,
    subtitle,
    sectionTitle,
    isOpen,
    isFoldableSectionsEnabled,
  } = props

  const [isModalOpen, setIsModalOpen] = useState(false)
  const mediaQueries = useMediaQueries()

  const handleToggle = () => {
    onToggle(item.cid || item.id, sectionId)
  }

  const showModal = (event?: MouseClickEvent) => {
    if (event) event.stopPropagation()
    setIsModalOpen(true)
  }

  const handleModalConfirm = () => {
    setIsModalOpen(false)
    onDelete(item.id, sectionId, isCustom)
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Wrapper>
      <Container
        isDragging={isDragging}
        data-highlight-id={getCardHighlightId(sectionId, item.cid || item.id)}
      >
        <DialogModal
          title={i18n.t('builder.resume_editor.delete_entry')}
          text={i18n.t('builder.resume_editor.sure_to_delete')}
          primaryButtonText={i18n.t('builder.resume_editor.delete')}
          secondaryButtonText={i18n.t('builder.resume_editor.cancel')}
          dontShowAgainId="deleteCardDialog2" // optional
          isOpen={isModalOpen}
          primaryAction={handleModalConfirm}
          secondaryAction={handleModalCancel}
          closeAction={handleModalCancel}
        />

        <CardTitle
          title={title}
          subtitle={subtitle}
          isOpen={isOpen}
          isDragging={isDragging}
          onToggle={handleToggle}
          onDelete={showModal}
          disableMove={disableMove}
          isFoldableSectionsEnabled={isFoldableSectionsEnabled}
        />

        {!mediaQueries.isPhone && !isDragging && (
          <Foldable isOpen={isOpen} duration={foldableDuration}>
            {children}
          </Foldable>
        )}

        {mediaQueries.isPhone && (
          <CardModal isOpen={isOpen} title={sectionTitle} caption={title} onClose={handleToggle}>
            {children}
          </CardModal>
        )}
      </Container>
    </Wrapper>
  )
}
