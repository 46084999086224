import { memo, useRef } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { PrefillResumeEvents } from 'builder/modules/constants'
import { i18n as I18n } from 'builder/utils/i18n'
import FileSelector from '../../UploadResume/FileSelector/FileSelector'
import UploadResumeImg from '../images/upload-resume.png'
import { useResumePrefill } from '../../hooks/useResumePrefill'
import {
  Card,
  CardImgContainer,
  UploadResumeCardImg,
  CardDescription,
  UploadResumeCardTitle,
  CardSubTitle,
} from './styles'

const UploadResume = () => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { handleResumeUpload } = useResumePrefill()

  const handleCardClick = () => {
    trackInternalEvent(PrefillResumeEvents.clickUploadResume)
    handleResumeUpload(fileInputRef)
  }

  return (
    <Card onClick={handleCardClick}>
      <CardImgContainer>
        <UploadResumeCardImg src={UploadResumeImg} />
      </CardImgContainer>
      <CardDescription>
        <UploadResumeCardTitle>
          {I18n.t('builder.prefill_resume.options_view.options.upload_resume.title')}
        </UploadResumeCardTitle>
        <CardSubTitle>
          {I18n.t('builder.prefill_resume.options_view.options.upload_resume.description')}
        </CardSubTitle>
      </CardDescription>
      <FileSelector ref={fileInputRef} />
    </Card>
  )
}

export default memo(UploadResume)
