import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import { WithFoldableSections } from 'builder/components/Section'
import Colors from 'builder/styles/colors'

export const Container = styled.div<WithFoldableSections>`
  display: flex;
  justify-content: center;
  width: 50%;
  min-height: 100vh;

  ${Media.Laptop`
    width: 100%;
    margin: 0 auto;
  `};

  ${Media.Desktop`
    max-width: 960px;
  `};

  ${props =>
    props.isFoldableSectionsEnabled &&
    css`
      background-color: ${Colors.Neutral10};

      ${Media.Desktop`
        max-width: 860px;
      `};
    `};
`

export const LeftSide = styled.div`
  width: 100%;
  max-width: 100%;
  flex: 1;
`
