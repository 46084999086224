import { FC, useCallback, useState } from 'react'
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
  SortStartHandler,
} from 'react-sortable-hoc'
import nanoid from 'nanoid'
import Anchor from 'builder/components/Anchor'
import SectionComponent, { SectionComponentProps } from 'builder/components/Section'
import { GeneralEditorLevels } from 'builder/modules/generalEditor'
import {
  CardOperationFunc,
  IsCardOpenFunc,
  MoveCardFunc,
  UpdateCardFunc,
} from 'builder/components/Card'

const DroppableFC: FC = ({ children }) => <div>{children}</div>
const DraggableFC: FC<{ sortId?: string | number }> = ({ sortId, children }) => (
  <div data-id={sortId}>{children}</div>
)

const Droppable = SortableContainer(DroppableFC)
const Draggable = SortableElement(DraggableFC)

type Props = {
  levels?: GeneralEditorLevels
  foldableDuration?: number
  isCardOpen: IsCardOpenFunc
  onCardMove: MoveCardFunc
  onCardDelete: CardOperationFunc
  onCardChange: UpdateCardFunc
  onCardToggle: CardOperationFunc
  isRecommendedLengthHidden?: boolean
} & SectionComponentProps

type SortableProps = Props & { index: number }

export const Section = (props: Props) => {
  const {
    item: section,
    levels,
    locale,
    hideSkillLevel,
    isCardOpen,
    onCardDelete,
    onCardChange,
    onCardToggle,
    foldableDuration,
    template,
    updateSimpleField,
    position,
    isRecommendedLengthHidden,
    onCardMove,
    isFoldableSectionsEnabled,
  } = props
  const { hidden, CardComponent } = section

  const [draggableIndex, setDraggableIndex] = useState(-1)
  const [customId] = useState(() => nanoid())

  const preventSelection: EventListener = useCallback(event => {
    event.preventDefault()
    return false
  }, [])

  const handleSortStart: SortStartHandler = ({ index }) => {
    // Fix cursor
    document.addEventListener('selectstart', preventSelection)

    return setDraggableIndex(index)
  }

  const handleCardMove: SortEndHandler = ({ oldIndex, newIndex }) => {
    document.removeEventListener('selectstart', preventSelection)

    if (oldIndex !== newIndex) {
      onCardMove({ oldIndex, newIndex, sectionId: section.id })
    }

    setDraggableIndex(-1)
  }

  if (hidden) return null

  return (
    <Anchor id={section.id}>
      <SectionComponent {...props} item={section}>
        <Droppable
          updateBeforeSortStart={handleSortStart}
          onSortEnd={handleCardMove}
          useWindowAsScrollContainer
          useDragHandle
        >
          {section.items?.map((item, index) => {
            const cardId = item.cid || item.id || customId
            return (
              <Draggable key={cardId} index={index}>
                <Anchor id={cardId}>
                  <CardComponent
                    item={item}
                    sectionId={section.id}
                    sectionTitle={section.title}
                    levels={levels}
                    locale={locale}
                    hideSkillLevel={hideSkillLevel}
                    foldableDuration={foldableDuration}
                    isOpen={isCardOpen(cardId, section.id)}
                    isDragging={draggableIndex === index}
                    onChange={onCardChange}
                    onResumeSimpleFieldChange={updateSimpleField}
                    onDelete={onCardDelete}
                    onToggle={onCardToggle}
                    template={template}
                    jobTitle={position}
                    isRecommendedLengthHidden={isRecommendedLengthHidden}
                    isFoldableSectionsEnabled={isFoldableSectionsEnabled}
                  />
                </Anchor>
              </Draggable>
            )
          })}
        </Droppable>
      </SectionComponent>
    </Anchor>
  )
}

export const SortableSection = (props: SortableProps) => {
  const { id, sortId, sortable } = props.item

  if (sortable) {
    return (
      <Draggable sortId={sortId || id} index={props.index}>
        <Section {...props} />
      </Draggable>
    )
  }

  return <Section {...props} />
}
