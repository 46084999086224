import { useCallback, useState } from 'react'
import { Transition } from 'react-transition-group'
import { components, DropdownIndicatorProps, MultiValueRemoveProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import { Icon20 } from 'builder/components/Icon'
import { FieldNote, FieldNoteProps } from '../FieldNote'
import {
  Bar,
  Container,
  HintText,
  InputWrapper,
  Item,
  Label,
  LabelContainer,
  LabelContent,
  Required,
} from './styles'

export type SelectItem = {
  value: string
  label: string
}

export type Indicators = {
  clear?: boolean
  loading?: boolean
  separator?: boolean
  dropdown?: boolean
}

type Props = {
  list?: SelectItem[]
  selectedList: SelectItem[]
  className?: string
  label?: string
  hint?: string
  active?: boolean
  showLimit?: number
  placeholder?: string
  showIndicators?: Indicators
  onSelect: (item: SelectItem) => void
  onUnselect: (newValue: SelectItem[]) => void
  loadOptions?: (value: string) => Promise<SelectItem[]>
} & FieldNoteProps

export const ClusterPicker = (props: Props) => {
  const {
    list,
    selectedList,
    onSelect,
    onUnselect,
    description,
    error,
    errorNoMsg,
    required,
    warning,
    active: isActive,
    className,
    label,
    hint,
    showLimit,
    placeholder = '',
    showIndicators,
    loadOptions,
    ...rest
  } = props
  const [isFocused, toggleFocus] = useState(false)

  const handleFocus = useCallback(() => {
    toggleFocus(true)
  }, [])

  const handleBlur = useCallback(() => {
    toggleFocus(false)
  }, [])

  const hasError = !!error || errorNoMsg
  const hasWarning = !!warning
  const isBarVisible = isFocused || isActive || hasError || hasWarning

  const DropdownIndicator = (props: DropdownIndicatorProps<SelectItem>) => (
    <components.DropdownIndicator {...props}>
      <span>↵</span> Enter
    </components.DropdownIndicator>
  )

  const MultiValueRemove = (props: MultiValueRemoveProps<SelectItem>) => {
    return (
      <div>
        <components.MultiValueRemove {...props}>
          <Icon20.Close />
        </components.MultiValueRemove>
      </div>
    )
  }

  return (
    <div className={className}>
      {label && (
        <LabelContainer>
          <Label $hasError={hasError}>
            <span>
              <LabelContent>{label}</LabelContent>
              {required && <Required> *</Required>}
            </span>
          </Label>
        </LabelContainer>
      )}
      <InputWrapper {...showIndicators}>
        <AsyncSelect<SelectItem, boolean>
          {...rest}
          isMulti
          autoFocus
          isSearchable
          value={selectedList}
          placeholder={placeholder}
          openMenuOnClick={false}
          classNamePrefix="select"
          onBlur={handleBlur}
          onFocus={handleFocus}
          loadOptions={loadOptions}
          components={{ DropdownIndicator, MultiValueRemove }}
          onChange={newValue => onUnselect(Array.isArray(newValue) ? newValue : [newValue])}
        />
        <Transition in={isBarVisible} timeout={100}>
          {state => <Bar $in={state} $hasError={hasError} $hasWarning={hasWarning} />}
        </Transition>
      </InputWrapper>
      {!hasError && hint && <HintText>{hint}</HintText>}
      <FieldNote error={error} warning={warning} description={description} />
      {list && (
        <Container>
          {list.slice(0, showLimit).map(item => (
            <Item key={item.value} onClick={() => onSelect(item)}>
              {item.label}
            </Item>
          ))}
        </Container>
      )}
    </div>
  )
}
