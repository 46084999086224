import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { useI18n } from 'builder/hooks/useI18n'
import { ButtonTheme } from 'builder/components/Button'
import Icon24 from 'builder/components/Icon'
import { TooltipV2 } from 'builder/components/Tooltip'
import Colors from 'builder/styles/colors'
import { actions } from 'builder/modules/interview/interviewModule'
import { InterviewPlayerModalType } from 'builder/modules/interview/types'

import { Modal } from 'builder/views/Interview/components/Modal/Modal'
import { useGetStream, UserMediaType } from '../../../../hooks/useGetStream'
import { InterviewModalsProps } from '../../InterviewModals'
import { usePermission } from '../../../../hooks/usePermissions'
import {
  Video,
  Text,
  LargeButton,
  Hint,
  IconContainer,
  Line,
  AllGoodButton,
  LineContainer,
  TestSoundText,
  VideoContainer,
  VideoBlank,
  VideoWrapper,
  Title,
  WebCamContainer,
} from './styles'
import TestSound from './assets/TestSound.webm'
import WebCamOffPath from './assets/webcam_off.svg'
import WebCamOnPath from './assets/webcam_on.svg'

const TRANSLATION_PREFIX = 'builder.interview_prep_view.video_mic_modal'

export const VideoMicModalInitial = ({ handleWelcome }: Partial<InterviewModalsProps>) => {
  const navigate = useNavigate()
  const videoRef = useRef<HTMLVideoElement>(null)
  const testSoundRef = useRef<HTMLAudioElement>(null)
  const { i18n } = useI18n()
  const dispatch = useDispatch()

  const audioStream = useGetStream(UserMediaType.audio)
  const audioVideoStream = useGetStream(UserMediaType.video)

  const isMicOn = audioStream?.stream?.active

  function regainPermission(p: PermissionState) {
    if (p === 'granted') {
      audioVideoStream.askForPermission()
      audioStream.askForPermission()
    }
  }

  usePermission('camera', regainPermission)
  usePermission('microphone', regainPermission)

  useEffect(() => {
    if (audioVideoStream.stream === null) {
      audioStream.askForPermission()
    }
  }, [audioVideoStream?.stream])

  useEffect(() => {
    if (videoRef.current && audioVideoStream.stream) {
      videoRef.current.srcObject = audioVideoStream.stream
    }
  }, [audioVideoStream.stream])

  const onHelpClick = () => {
    dispatch(actions.setInterviewPlayerModal(InterviewPlayerModalType.permission_modal_help))
  }

  const onTestSound = () => {
    testSoundRef.current?.play()
  }

  const onContinue = () => {
    if (handleWelcome) {
      handleWelcome()
    }
  }

  const onClose = () => {
    if (isMicOn) {
      onContinue()
    } else {
      return navigate('/interview-preparation/dashboard')
    }
  }

  return (
    <Modal
      onClose={onClose}
      width={352}
      title={<Title>{i18n.t(`${TRANSLATION_PREFIX}.title`)}</Title>}
      disableAnimation
    >
      <TooltipV2 content={i18n.t(`${TRANSLATION_PREFIX}.hint`)} multiline={true}>
        <VideoWrapper>
          <VideoContainer>
            {audioVideoStream.stream ? (
              <>
                <WebCamContainer>
                  <img src={WebCamOnPath} />
                </WebCamContainer>
                <Video ref={videoRef} autoPlay muted playsInline />
              </>
            ) : (
              <>
                <WebCamContainer>
                  <img src={WebCamOffPath} />
                </WebCamContainer>
                <VideoBlank />
              </>
            )}
          </VideoContainer>
        </VideoWrapper>
      </TooltipV2>
      <LineContainer>
        <Line>
          <IconContainer>
            <Icon24.Sound />
          </IconContainer>
          <div>
            <Text>{i18n.t(`${TRANSLATION_PREFIX}.sound`)}</Text>
            <TestSoundText onClick={onTestSound}>
              {i18n.t(`${TRANSLATION_PREFIX}.sound_test`)}
            </TestSoundText>
          </div>
        </Line>
        <Line>
          {isMicOn ? (
            <>
              <IconContainer>
                <Icon24.Mic />
              </IconContainer>
              <Text>{i18n.t(`${TRANSLATION_PREFIX}.mic`)}</Text>
            </>
          ) : (
            <>
              <IconContainer>
                <Icon24.MicClosed color={Colors.Red50} />
              </IconContainer>
              <Text>{i18n.t(`${TRANSLATION_PREFIX}.micFail`)}</Text>
            </>
          )}
        </Line>
      </LineContainer>
      <LargeButton theme={ButtonTheme.ghost} onClick={onHelpClick}>
        {i18n.t(`${TRANSLATION_PREFIX}.need_help`)}
      </LargeButton>
      <AllGoodButton isDisabled={!isMicOn} onClick={onContinue}>
        {i18n.t(`${TRANSLATION_PREFIX}.all_good`)}
      </AllGoodButton>
      <Hint>{i18n.t(`${TRANSLATION_PREFIX}.hint`)}</Hint>
      <audio src={TestSound} ref={testSoundRef} />
    </Modal>
  )
}
