import { config, useTransition } from '@react-spring/web'
import { useI18n } from 'builder/hooks/useI18n'
import TargetImage from 'images/resume_optimizer/target_image.png'
import { BannerTransitionProps, ViewProps } from '../../types'
import {
  ArrowIcon,
  Banner,
  BannerImage,
  BannerTitle,
  ActionWrapper,
  ActionText,
  NotNowButton,
  BannerContainer,
  MainBannerContent,
} from './styles'

const TRANSLATION = 'builder.resume_editor.keywords.improve_resume_panel.tailor_resume_banner'

const defaultTransitionFrom: BannerTransitionProps = {
  maxHeight: 0,
  opacity: 0,
  marginTop: 0,
}

const defaultTransitionTo: BannerTransitionProps = {
  maxHeight: 200,
  opacity: 1,
  marginTop: 12,
}

export const TailorResumeBannerView1 = (props: ViewProps) => {
  const { isVisible, onBannerClick, onNotNowClick, transitionProps } = props
  const { i18n } = useI18n()
  const transition = useTransition(isVisible, {
    config: config.default,
    from: defaultTransitionFrom,
    enter: { ...defaultTransitionTo, ...transitionProps },
    leave: defaultTransitionFrom,
  })
  const wrapOnMobile = !!onNotNowClick

  return transition((style, item) =>
    item ? (
      <BannerContainer style={style}>
        <Banner onClick={onBannerClick}>
          <BannerImage src={TargetImage} alt="Target Image" />
          <MainBannerContent $wrapOnMobile={wrapOnMobile}>
            <BannerTitle>{i18n.t(`${TRANSLATION}.title`)}</BannerTitle>
            <ActionWrapper>
              {onNotNowClick && (
                <NotNowButton
                  onClick={event => {
                    event.stopPropagation()
                    onNotNowClick()
                  }}
                >
                  {i18n.t(`${TRANSLATION}.not_now_button_text`)}
                </NotNowButton>
              )}
              <ActionText>
                {i18n.t(`${TRANSLATION}.button_text`)} <ArrowIcon />
              </ActionText>
            </ActionWrapper>
          </MainBannerContent>
        </Banner>
      </BannerContainer>
    ) : null,
  )
}
