import { useState } from 'react'
import { navigate } from 'organizations/store'
import { useI18n } from 'builder/hooks/useI18n'

import { DashboardBuilder } from 'builder/components/Icon/24x24'
import { Icon20 } from 'builder/components/Icon'
import { Menu } from '../Menu/Menu'
import { CloseContainer, Container, DashboardButton, MenuButtonContainer } from './styles'

type Props = {
  onClose: () => void
}
export const TRANSLATION_PREFIX = 'builder.interview_prep_view.interview_player_v2.navbar'

export const Navbar = (props: Props) => {
  const { onClose } = props
  const { i18n } = useI18n()
  const [showSideMenu, setShowSideMenu] = useState(false)

  return (
    <>
      <Container>
        <MenuButtonContainer onClick={() => setShowSideMenu(true)}>
          <DashboardBuilder />
        </MenuButtonContainer>
        <DashboardButton onClick={onClose}>
          {i18n.t(`${TRANSLATION_PREFIX}.dashboard`)}
        </DashboardButton>
        <CloseContainer onClick={onClose}>
          <Icon20.Close />
        </CloseContainer>
      </Container>
      {showSideMenu && (
        <Menu
          onClose={() => setShowSideMenu(false)}
          onClick={(path: string) => {
            navigate(path)
          }}
        />
      )}
    </>
  )
}
