import styled, { keyframes } from 'styled-components'
import { Icon24 } from 'builder/components/Icon'
import Sizes from 'builder/styles/sizes'
import { ButtonV2Props } from '../../types'

const rotation = keyframes`
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
`

const ButtonContent = styled.span`
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  gap: inherit;
  min-height: 24px;
`

const ButtonText = styled.span`
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  gap: inherit;
  padding: 0 ${Sizes['3XS']};
`

const Spinner = styled(Icon24.Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: ${rotation} 0.5s infinite linear;
`

export const BaseButtonV2 = (props: ButtonV2Props) => {
  const { children, isLoading, as, leadingIcon, trailingIcon, ...otherProps } = props
  const Component = as || 'button'

  return (
    <Component {...otherProps}>
      <ButtonContent style={{ opacity: isLoading ? 0 : 1 }}>
        {leadingIcon}
        {children && <ButtonText>{children}</ButtonText>}
        {trailingIcon}
      </ButtonContent>
      {isLoading && <Spinner />}
    </Component>
  )
}
