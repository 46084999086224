import { memo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { Icon24 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import { i18n as I18n } from 'builder/utils/i18n'
import { useConfig } from 'builder/hooks/useConfig'
import { Spinner } from 'builder/components/Spinner'
import { UseExampleEvents } from 'builder/modules/constants'
import useExampleImg from '../../../../../../app/assets/images/builder/fill-resume/use-example.svg'
import { useResumePrefill } from '../../hooks/useResumePrefill'
import {
  UseExampleCard,
  CardDescription,
  CardSubtitle,
  CardTitle,
  UseExampleImgWrapper,
  UseExampleImg,
  SpinnerWrapper,
  IconWrapper,
} from './styles'

type Props = {
  onClose: () => void
}

const UseExample = (props: Props) => {
  const { onClose } = props
  const arrowStyles = {
    fill: Colors.Neutral40,
  }

  const config = useConfig()
  const { handleUseExample, hasResumeExamples, fetchingResumeExamples } = useResumePrefill()
  const useNewPhrases = config?.features.newStartScreen === 'old_design_new_text'

  const title = useNewPhrases
    ? I18n.t('builder.prefill_resume.options_view.options.use_example.title')
    : I18n.t('builder.use_example.option.heading')

  const description = useNewPhrases
    ? I18n.t('builder.prefill_resume.options_view.options.use_example.description')
    : I18n.t('builder.use_example.option.description')

  const handleCardClick = () => {
    trackInternalEvent(UseExampleEvents.clickUseAnExampleStartScreen)
    handleUseExample()
    if (hasResumeExamples) {
      onClose()
    }
  }

  return (
    <UseExampleCard onClick={handleCardClick}>
      <UseExampleImgWrapper>
        <UseExampleImg src={useExampleImg} alt="Use resume example" />
      </UseExampleImgWrapper>
      <CardDescription>
        <CardTitle>{title}</CardTitle>
        <CardSubtitle>{description}</CardSubtitle>
      </CardDescription>
      {fetchingResumeExamples ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <IconWrapper>
          <Icon24.ToggleArrow style={arrowStyles} />
        </IconWrapper>
      )}
    </UseExampleCard>
  )
}

export default memo(UseExample)
