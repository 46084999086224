import { useState } from 'react'
import { Button, ButtonSize, ButtonTheme, ButtonV2 } from 'builder/components/Button'
import { ActionMenu } from 'builder/components/ActionMenu'
import { Icon24 } from 'builder/components/Icon'
import * as ActionGroup from 'builder/components/ActionGroup'
import Tabs, { Tab } from 'builder/components/Tabs'
import { Section } from '../../Section'
import { ComponentPreview, Group, Label } from '../../styles'
import { MENU_ACTIONS } from './constants'
import { ButtonsList } from './styles'

export const ButtonsSection = () => {
  const [isMenuOpen, toggleMenu] = useState(false)
  const [activeTab, setActiveTab] = useState('New')

  return (
    <Section title="Buttons">
      <Group>
        <Label>Button</Label>

        <Tabs>
          {['Old', 'New'].map(item => (
            <Tab key={item} isActive={activeTab === item} onClick={() => setActiveTab(item)}>
              {item}
            </Tab>
          ))}
        </Tabs>

        {activeTab === 'Old' && (
          <ButtonsList>
            {Object.values(ButtonSize).map(size =>
              Object.values(ButtonTheme).map(theme => (
                <Button key={theme} theme={theme} size={size}>
                  {theme} {size !== ButtonSize.default ? size : ''}
                </Button>
              )),
            )}
          </ButtonsList>
        )}

        {activeTab === 'New' && (
          <>
            <ButtonsList>
              {Object.values(ButtonSize).map(size =>
                Object.values(ButtonTheme).map(theme => (
                  <ButtonV2 key={theme} theme={theme} size={size}>
                    {theme} {size !== ButtonSize.default ? size : ''}
                  </ButtonV2>
                )),
              )}
            </ButtonsList>
            <ButtonsList>
              {Object.values(ButtonSize).map(size => (
                <ButtonV2 key={size} size={size} leadingIcon={<Icon24.Success />}>
                  With leading icon
                </ButtonV2>
              ))}
            </ButtonsList>
            <ButtonsList>
              {Object.values(ButtonSize).map(size => (
                <ButtonV2
                  key={size}
                  theme={ButtonTheme.alternative}
                  size={size}
                  trailingIcon={<Icon24.Success />}
                >
                  With trailing icon
                </ButtonV2>
              ))}
            </ButtonsList>
            <ButtonsList>
              {Object.values(ButtonSize).map(size => (
                <ButtonV2
                  key={size}
                  theme={ButtonTheme.confirmative}
                  size={size}
                  leadingIcon={<Icon24.Success />}
                  trailingIcon={<Icon24.Success />}
                >
                  With both icons
                </ButtonV2>
              ))}
            </ButtonsList>
            <ButtonsList>
              {Object.values(ButtonSize).map(size => (
                <ButtonV2 key={size} size={size} isDisabled>
                  Disabled
                </ButtonV2>
              ))}
            </ButtonsList>
            <ButtonsList>
              {Object.values(ButtonSize).map(size => (
                <ButtonV2 key={size} size={size} isLoading>
                  Loading state
                </ButtonV2>
              ))}
            </ButtonsList>
          </>
        )}
      </Group>

      <Group>
        <Label>ActionGroup</Label>
        <ComponentPreview>
          <ActionGroup.Container>
            <ActionGroup.Button icon={<Icon24.Google />}>Google Something</ActionGroup.Button>
            <ActionGroup.Button icon={<Icon24.Linkedin />}>Open LinkedIn</ActionGroup.Button>
            <ActionGroup.Button icon={<Icon24.Mail />}>Sign up with Email</ActionGroup.Button>
          </ActionGroup.Container>
        </ComponentPreview>
      </Group>

      <Group>
        <Label>ActionMenu</Label>
        <ComponentPreview style={{ position: 'relative' }}>
          <Button onClick={() => toggleMenu(true)}>Open Menu</Button>
          {isMenuOpen && (
            <ActionMenu
              position="bottom"
              align="left"
              actions={MENU_ACTIONS}
              onClose={() => toggleMenu(false)}
            />
          )}
        </ComponentPreview>
      </Group>
    </Section>
  )
}
