import { createInternalEventHandler } from '@rio/tracking'
import { stringify } from 'query-string'
import snakeCase from 'lodash/snakeCase'
import { useI18n } from 'builder/hooks/useI18n'
import Icon from 'builder/components/Icon'
import { Buttons, SocialButton, SocialNetwork, ChevronIcon, NetworkIcon } from './styles'

type SocialProfileButtonsProps = {
  templateId: string
  documentType: string
  className?: string
}

const SocialProfileButtons = ({
  documentType,
  templateId,
  className,
}: SocialProfileButtonsProps) => {
  const { i18n } = useI18n()

  // "cover-letter" → "cover_letter"
  const type = snakeCase(documentType)

  // Pass some of the persisted values to the social auth controller
  const query = stringify({ type, template: templateId })

  return (
    <Buttons className={className}>
      <SocialButton
        href={`/auth/facebook?${query}`}
        onClick={createInternalEventHandler('choose_social_sign_up_method', {
          network: 'facebook',
        })}
      >
        <SocialNetwork>
          <NetworkIcon>
            <Icon.Facebook />
          </NetworkIcon>
          {i18n.t('builder.sign_in.methods.facebook')}
        </SocialNetwork>

        <ChevronIcon />
      </SocialButton>

      <SocialButton
        href={`/auth/google_oauth2?${query}`}
        onClick={createInternalEventHandler('choose_social_sign_up_method', {
          network: 'google',
        })}
      >
        <SocialNetwork>
          <NetworkIcon>
            <Icon.Google />
          </NetworkIcon>
          {i18n.t('builder.sign_in.methods.google')}
        </SocialNetwork>

        <ChevronIcon />
      </SocialButton>

      <SocialButton
        href={`/auth/linkedin?${query}`}
        onClick={createInternalEventHandler('choose_social_sign_up_method', {
          network: 'linkedin',
        })}
      >
        <SocialNetwork>
          <NetworkIcon>
            <Icon.Linkedin />
          </NetworkIcon>
          {i18n.t('builder.sign_in.methods.linkedin')}
        </SocialNetwork>

        <ChevronIcon />
      </SocialButton>
    </Buttons>
  )
}

export default SocialProfileButtons
