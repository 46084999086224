import { useRef, useCallback } from 'react'
import { AxiosResponse } from 'axios'
import { MIN_BLOB_SIZE, RECORD_DATA_READY_INTERVAL } from 'builder/views/Interview/constants'
import { getMediaRecorder } from 'builder/views/Interview/utils/getMediaRecorder'

interface BlobMetadata {
  mediaRecorder: MediaRecorder
  blobParts: Blob[]
  totalBytes: number
  partSize: number
  durations: { start: number; end?: number }[]
  status?: RecordingState
}

export const useRecordingMedia = ({
  uploadBlobChunkPart,
  stream,
  media,
}: {
  uploadBlobChunkPart: (item: {
    questionId: number
    media: 'video' | 'audio'
    blob: Blob
  }) => Promise<AxiosResponse<Response> | undefined>
  stream: MediaStream | undefined | null
  media: 'audio' | 'video'
}) => {
  const allMetadata = useRef<Record<number, BlobMetadata>>({})

  const addStartTime = (questionId: number) => {
    const currentMetadata = allMetadata.current[questionId]
    currentMetadata.durations.push({ start: Date.now() })
  }

  const addEndTime = (questionId: number) => {
    const currentMetadata = allMetadata.current[questionId]

    const lastDuration = currentMetadata.durations[currentMetadata.durations.length - 1]
    if (lastDuration) {
      lastDuration.end = Date.now()
    } else {
      console.error('Invalid state')
    }
  }

  const start = useCallback(
    questionId => {
      return new Promise(resolve => {
        if (!stream) {
          resolve(undefined)
          return
        }

        if (allMetadata.current[questionId]) {
          throw new Error('Must be executed one time only')
        }

        if (!media) {
          throw new Error('Must have stream and media')
        }

        const mediaRecorder = getMediaRecorder(stream, media)
        const currentMetadata: BlobMetadata = {
          mediaRecorder,
          blobParts: [],
          totalBytes: 0,
          durations: [],
          partSize: 0,
        }

        const handleDataAvailable = (event: BlobEvent) => {
          const blobSize = event.data.size
          currentMetadata.totalBytes += blobSize
          currentMetadata.partSize += blobSize
          currentMetadata.blobParts.push(event.data)

          if (currentMetadata.partSize >= MIN_BLOB_SIZE) {
            const mergedBlobs = new Blob(currentMetadata.blobParts, {
              type: currentMetadata.blobParts[0].type,
            })
            currentMetadata.blobParts = []
            currentMetadata.partSize = 0

            uploadBlobChunkPart({
              questionId,
              media,
              blob: mergedBlobs,
            })
          }
        }

        const updateRecorderState = () => {
          const newState = currentMetadata.mediaRecorder.state
          currentMetadata.status = newState
        }

        mediaRecorder.ondataavailable = handleDataAvailable
        mediaRecorder.onerror = error => {
          throw error
        }

        mediaRecorder.onstart = () => {
          addStartTime(questionId)
          updateRecorderState()
          resolve(undefined)
        }
        try {
          mediaRecorder.start(RECORD_DATA_READY_INTERVAL)
          allMetadata.current[questionId] = currentMetadata
        } catch (error) {
          console.error(error)
          resolve(undefined)
        }
      })
    },
    [media, uploadBlobChunkPart, stream],
  )

  const stop = useCallback(
    async (questionId: number) => {
      return new Promise(resolve => {
        const currentMetadata = allMetadata.current[questionId]

        if (!stream) {
          resolve(undefined)
          return
        }

        if (!currentMetadata) {
          throw new Error('Must exist metadata')
        }

        const handleStop = async () => {
          addEndTime(questionId)
          if (currentMetadata.mediaRecorder && currentMetadata.partSize > 0) {
            const mergedBlobs = new Blob(currentMetadata.blobParts, {
              type: currentMetadata.blobParts[0].type,
            })

            await uploadBlobChunkPart({
              questionId,
              media,
              blob: mergedBlobs,
            })
          }
          resolve({
            duration:
              currentMetadata.durations.reduce(
                (acc, { start, end }) => acc + (end || 0) - start,
                0,
              ) / 1000,
            totalBytes: currentMetadata.totalBytes,
          })
        }
        if (currentMetadata.mediaRecorder) {
          currentMetadata.mediaRecorder.onstop = handleStop
          currentMetadata.mediaRecorder.stop()
        }
      })
    },
    [media, uploadBlobChunkPart, stream],
  )

  const pause = useCallback(
    async (questionId: number) => {
      const currentMetadata = allMetadata.current[questionId]

      if (!stream) {
        return
      }

      if (!currentMetadata) {
        throw new Error('Must exist metadata')
      }

      if (currentMetadata.mediaRecorder) {
        addEndTime(questionId)
        currentMetadata.mediaRecorder.pause()
      }
    },
    [stream],
  )

  const resume = useCallback(
    async (questionId: number) => {
      const currentMetadata = allMetadata.current[questionId]

      if (!stream) {
        return
      }

      if (!currentMetadata) {
        throw new Error('Must exist metadata')
      }

      if (currentMetadata.mediaRecorder) {
        addStartTime(questionId)
        currentMetadata.mediaRecorder.resume()
      }
    },
    [stream],
  )

  return {
    start,
    stop,
    pause,
    resume,
  }
}
