import { Optional } from 'packages/types'
import scrollParent from 'scrollparent'
import { Step } from 'react-joyride'
import { SectionFieldNames } from './constants'
import { ResumeSectionId } from './types'

type SectionFieldNamesType = keyof typeof SectionFieldNames

export function getCardHighlightId<T extends ResumeSectionId>(
  sectionName: T,
  cardId: Optional<number> | string,
): string {
  return `${sectionName}.${cardId}`
}

export function getCardFieldHighlightId<T extends ResumeSectionId>(
  sectionName: T extends SectionFieldNamesType ? T : never,
  cardId: Optional<number> | string,
  fieldName: T extends SectionFieldNamesType ? SectionFieldNamesType[T] : never,
): string {
  return `${getCardHighlightId(sectionName, cardId)}.${SectionFieldNames[sectionName][fieldName]}`
}

/**
 * This fix may help if you open modal with scrollable content over scrollable page.
 * In this case react-joyride will add extra value to top position of spotlight,
 * and there is no other way to avoid this
 * @see https://github.com/gilbarbara/react-joyride/blob/c6c7bd7b4a6f68e8ba009083d825673b47196cb2/src/modules/dom.ts#L209
 */
export const joyRideSpotlightPositionInModalCssFix = (targetElement: Optional<HTMLElement>) => {
  let mobilePositionTopFix: Step['styles'] | undefined
  if (targetElement && document.scrollingElement) {
    const parent = scrollParent(targetElement)
    if (parent && parent.scrollHeight > parent.offsetHeight) {
      mobilePositionTopFix = {
        spotlight: {
          marginTop: -document.scrollingElement.scrollTop,
        },
      }
    }
  }

  return mobilePositionTopFix
}
