import { Fragment } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import { TRANSLATION } from '../constants'
import {
  BillingContainer,
  Disclaimer,
  PlanContainer,
  PlanName,
  PlanPeriod,
  PlanPrice,
  PlanPriceContainer,
  PriceLoader,
  UpgradeButton,
} from './styles'

type Props = {
  amount: string
  isPriceLoading: boolean
  onClickUpgrade: () => void
}

export const BillingAction = ({ amount, isPriceLoading, onClickUpgrade }: Props) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()

  return (
    <BillingContainer>
      <PlanContainer>
        <PlanName>{i18n.t(`${TRANSLATION}.plan.title`)}</PlanName>
        <PlanPriceContainer>
          {isPriceLoading && <PriceLoader />}
          {!isPriceLoading && (
            <Fragment>
              <PlanPrice>{amount}</PlanPrice>
              <PlanPeriod>/{i18n.t(`${TRANSLATION}.plan.period`)}</PlanPeriod>
            </Fragment>
          )}
        </PlanPriceContainer>
      </PlanContainer>

      {!isPhone && (
        <UpgradeButton onClick={onClickUpgrade}>{i18n.t(`${TRANSLATION}.cta_label`)}</UpgradeButton>
      )}
      <Disclaimer>*{i18n.t(`${TRANSLATION}.disclaimer`)}</Disclaimer>
    </BillingContainer>
  )
}
