import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useEffect, useRef, useState, Fragment, useCallback } from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  Label,
  actions,
  selectors,
  CareerPathStep,
  SKILLS_WIDTH_PERCENTAGE,
  SearchQuery,
  SalaryParams,
} from 'builder/modules/careerPath2'
import { MouseClickEvent } from 'builder/modules/ui'
import {
  Container,
  AvgTime,
  AvgTimeText,
  AvgTimeValue,
  SkillMatch,
  SkillMatchText,
  SkillMatchValue,
  SkillsContainer,
  SkillsTitle,
  SkillsList,
  Skill,
  StyledButton,
  Square,
  Wrapper,
} from './styles'

type Props = {
  id: number
  level: number
  title: string
  skills: string[]
  skillMatch: number
  averageTransitionTime: string
  isLaptop?: boolean
}

type LevelType = null | number | undefined

const CardDetails = ({
  id,
  level,
  title,
  skills,
  skillMatch,
  averageTransitionTime,
  isLaptop,
}: Props) => {
  const dispatch = useDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const [visibleCount, setVisibleCount] = useState<number>(0)

  const allSkills = useTypedSelector(selectors.skills)
  const location = useTypedSelector(selectors.location)

  const allSkillsLabels = allSkills.map(skill => skill.label)

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    let visibleCount = 0
    const container = containerRef.current
    const containerRect = container.getBoundingClientRect()
    const childDivs = Array.from(container.children) as HTMLDivElement[]

    childDivs.forEach(childDiv => {
      const childRect = childDiv.getBoundingClientRect()

      if (childRect.bottom >= containerRect.top && childRect.top <= containerRect.bottom) {
        const visibleHeight =
          Math.min(childRect.bottom, containerRect.bottom) -
          Math.max(childRect.top, containerRect.top)
        const visiblePercentage = (visibleHeight / childDiv.offsetHeight) * 100

        if (visiblePercentage >= SKILLS_WIDTH_PERCENTAGE) {
          // Only count elements that are at least 50% visible
          visibleCount++
        }
      }
    })

    setVisibleCount(visibleCount)
  }, [containerRef.current?.scrollTop])

  const clearLevelJobTitleIndex = useCallback(
    (level: LevelType) => {
      switch (level) {
        case 1:
          dispatch(actions.setLevel1JobTitleIndex(null))
          break
        case 2:
          dispatch(actions.setLevel2JobTitleIndex(null))
          break
        case 3:
          dispatch(actions.setLevel3JobTitleIndex(null))
          break
      }
    },
    [dispatch],
  )

  const handleClick = useCallback(
    (e: MouseClickEvent) => {
      e.stopPropagation()

      const queryParams: SearchQuery = {
        query: title,
        location,
      }

      const salaryParams: SalaryParams = {
        title,
        location: location || null,
      }

      dispatch(actions.fetchJobsRequest())
      dispatch(actions.fetchRecommendedJobCard(queryParams))
      dispatch(actions.fetchSalaryDetails(salaryParams))
      dispatch(actions.setAddToPathJobTitle(title))
      dispatch(actions.fetchCareerPagesJobDetails(title))
      dispatch(actions.fetchCourses({ title, limit: 12 }))
      dispatch(actions.fetchJobDemand({ job_title: title, location: location || '' }))

      clearLevelJobTitleIndex(level)
      dispatch(actions.setAddToPathLevel(level))
      dispatch(actions.setAddToPathJobTitle(title))
      dispatch(actions.setSelectedJobTitle(title))
      dispatch(actions.setStep(CareerPathStep.ADD_TO_PATH))

      trackInternalEvent('click_job_title_show_more', { ...Label, title })
    },
    [dispatch, level, title, location, clearLevelJobTitleIndex],
  )

  return (
    <Wrapper titleId={id} level={level} onClick={e => e.stopPropagation()}>
      <Container>
        <Square titleId={id} level={level} />
        {!isLaptop ? (
          <Fragment>
            <AvgTime>
              <AvgTimeText>Transition time</AvgTimeText>
              <AvgTimeValue>≈ {averageTransitionTime}</AvgTimeValue>
            </AvgTime>
            <SkillMatch>
              <SkillMatchText>Your skill match</SkillMatchText>
              <SkillMatchValue>{skillMatch}%</SkillMatchValue>
            </SkillMatch>
          </Fragment>
        ) : null}
        <SkillsContainer>
          <SkillsTitle>Required skills</SkillsTitle>
          <SkillsList ref={containerRef}>
            {skills.map(skill => (
              <Skill key={skill} isKnown={allSkillsLabels.includes(skill)}>
                {skill}
              </Skill>
            ))}
          </SkillsList>
          <Skill key="count-skills" isKnown={false} isCountSkills={true}>
            +{Math.abs(skills.length - visibleCount)} Skills
          </Skill>
        </SkillsContainer>
        <StyledButton theme="ghost" size="small" onClick={handleClick}>
          Show More
        </StyledButton>
      </Container>
    </Wrapper>
  )
}

export default CardDetails
