import { useRef, useCallback } from 'react'
import axios, { AxiosResponse } from 'axios'
import { trackInternalEvent } from '@rio/tracking'
import { InterviewAnswer } from 'builder/modules/interview/types'
import { PerformanceLoggerWithMainDsn as PerformanceLogger } from 'builder/services/PerformanceLogger'

import ErrorLogger from 'builder/services/ErrorLogger'
import { useCompleteInterview } from 'builder/views/Interview/hooks/useCompleteInterview'
import { useCreateInterviewAnswerQuestion } from 'builder/views/Interview/hooks/useCreateInterviewAnswerQuestion'
import { useInterviewPresignedUrl } from 'builder/views/Interview/hooks/useInterviewPresignedUrl'
import { useMutationDeleteInterviewAnswerQuestion } from 'builder/views/Interview/hooks/useMutationDeleteInterviewAnswerQuestion'
import { useUploadInterviewVideo } from 'builder/views/Interview/hooks/useUploadInterviewVideo'

interface MediaRequests {
  currentPart: number
  partRequests: Record<number, Promise<AxiosResponse<Response>>>
  completedRequest: Promise<Response> | undefined
  finishedRecordingPromise: Promise<void> | undefined
}
interface QuestionRequest {
  questionId: number
  isInterrupted: boolean
  interruptedPromise: Promise<void> | undefined
  answerRequest: Promise<InterviewAnswer>
  presignedRequest: Record<number, Promise<InterviewAnswer>>
  abortController: AbortController
  requests: {
    video: MediaRequests
    audio: MediaRequests
  }
}

async function hasRejectedPromise<T>(obj: T): Promise<boolean> {
  // Helper function to check if a value is a Promise
  const isPromise = (value: any): value is Promise<any> => typeof value?.then === 'function'

  // For arrays, check if any element fulfills the condition
  if (Array.isArray(obj)) {
    const results = await Promise.all(obj.map(item => hasRejectedPromise(item)))
    return results.some(result => result)
  }

  // For objects, check if any value fulfills the condition
  if (typeof obj === 'object' && obj !== null) {
    const entries = Object.entries(obj)
    const results = await Promise.all(
      entries.map(async ([, value]) => {
        if (isPromise(value)) {
          try {
            await value // Wait for the promise to resolve
            return false // Promise resolved successfully
          } catch (error) {
            return true // Promise rejected
          }
        } else {
          return hasRejectedPromise(value) // Recursive call
        }
      }),
    )
    return results.some(result => result)
  }

  // For other types (including Promises), return false (base case)
  return false
}

export const useManageRecordingInterview = () => {
  const questionsRef = useRef<Record<number, QuestionRequest>>({})
  const errorRef = useRef<Error[]>([])
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.questionsRef = questionsRef.current
  const { mutateAsync: getPresignedUrl } = useInterviewPresignedUrl()
  const { mutateAsync: uploadChunk } = useUploadInterviewVideo()
  const { mutateAsync: completeFile } = useCompleteInterview()

  const { mutateAsync: newAnswerRequest } = useCreateInterviewAnswerQuestion()
  const { mutateAsync: deleteAnswer } = useMutationDeleteInterviewAnswerQuestion()

  const errorHandler = useCallback(async (where: string, questionId: number, error: unknown) => {
    const question = questionsRef.current[questionId]

    if (question?.isInterrupted) {
      return
    }

    if (error instanceof Error) {
      if (errorRef.current.includes(error)) {
        return
      }
      errorRef.current.push(error)

      if (axios.isAxiosError(error)) {
        trackInternalEvent('error_interview', {
          label: 'interview_prep',
          code: error.code,
          url: error.config.url,
          name: error.name,
          questionId,
        })
      }
    }
    const requestWasAborted = axios.isAxiosError(error) && error.code === 'ERR_CANCELED'
    if (!requestWasAborted) {
      ErrorLogger.log(error, { tag: 'interview-player-v2' })
    }
  }, [])

  interface PerformanceLoggerHandler<T> {
    identify: string
    tags?: QuestionRequest
    operation: string
    promise: Promise<T>
  }
  const performanceLoggerHandler = useCallback(
    async <T>({ identify, tags, promise }: PerformanceLoggerHandler<T>): Promise<T> => {
      PerformanceLogger.operationFull({
        key: identify,
        tags,
      })
      const value = await promise

      PerformanceLogger.operationEnd(identify)

      return value
    },
    [],
  )

  const makePreSignRequest = useCallback(async (questionId, answerId, partNumber) => {
    const question = questionsRef.current[questionId]

    const presignedRequest = getPresignedUrl({
      questionId,
      answerId,
      partNumber,
      signal: question.abortController.signal,
    })

    question.presignedRequest[partNumber] = performanceLoggerHandler({
      identify: `presignedUrl-request-${questionId}`,
      operation: 'presignedUrl-request',
      tags: question,
      promise: presignedRequest,
    })
    return question.presignedRequest[partNumber]
  }, [])

  const makeUploadChuckRequest = useCallback(
    async ({
      questionId,
      media,
      preSignResponse,
      blob,
    }: {
      questionId: number
      media: 'video' | 'audio'
      blob: Blob
      preSignResponse: InterviewAnswer
    }) => {
      try {
        const question = questionsRef.current[questionId]
        const requests = question.requests[media]

        const uploadChunkPromise = uploadChunk({
          url:
            media === 'video' ? preSignResponse.video_upload_url : preSignResponse.audio_upload_url,
          blob,
          signal: question.abortController.signal,
        })

        const addedPerformanceLoggerPromise = performanceLoggerHandler({
          identify: `chunk-request-${media}-${questionId}-${requests.currentPart}`,
          operation: 'chunk-request',
          tags: question,
          promise: uploadChunkPromise,
        })
        requests.partRequests[requests.currentPart] = addedPerformanceLoggerPromise
        requests.currentPart++
        return addedPerformanceLoggerPromise
      } catch (error) {
        errorHandler('MakeUploadChuckRequest', questionId, error)
        return Promise.reject(error)
      }
    },
    [],
  )

  const makeCompleteAnswerRequest = useCallback(
    async (questionId, assetMetadata, type: 'audio' | 'video') => {
      try {
        const question = questionsRef.current[questionId]

        const answerId = await question.answerRequest

        const etagsPromises = Object.entries(question.requests[type].partRequests)
          .sort((a, b) => Number(a[0]) - Number(b[0]))
          .map(([, value]) => value)

        const etagsResolved = await Promise.all(etagsPromises)

        const etags = etagsResolved.map((r, i) => ({
          part_number: i + 1,
          etag: r.headers.etag || '',
        }))

        if (!assetMetadata) {
          throw Error('Must exist metadata')
        }

        if (question.requests[type].completedRequest) {
          throw Error('This function must be called one time per question')
        }

        const completeRequestPromise = completeFile({
          answerId: answerId.id,
          questionId,
          answerDuration: assetMetadata.duration,
          totalBytes: assetMetadata.totalBytes,
          type,
          etags,
          signal: question.abortController.signal,
        })

        question.requests[type].completedRequest = completeRequestPromise
        return question.requests[type].completedRequest
      } catch (error) {
        errorHandler('makeCompleteAnswerRequest', questionId, error)
        return Promise.reject(error)
      }
    },
    [],
  )

  const createNewAnswer = useCallback(({ questionId }: { questionId: number }) => {
    const question = questionsRef.current[questionId]
    if (question) {
      throw new Error('createNewAnswer already has data ')
    }

    try {
      const question: QuestionRequest = {
        questionId,
        answerRequest: newAnswerRequest({ questionId }),
        interruptedPromise: undefined,
        presignedRequest: {},
        isInterrupted: false,
        abortController: new AbortController(),
        requests: {
          video: {
            currentPart: 1,
            partRequests: {},
            completedRequest: undefined,
            finishedRecordingPromise: undefined,
          },
          audio: {
            currentPart: 1,
            partRequests: {},
            completedRequest: undefined,
            finishedRecordingPromise: undefined,
          },
        },
      }

      questionsRef.current[questionId] = question

      question.answerRequest.then(answerRequest => {
        makePreSignRequest(questionId, answerRequest.id, 1)
        return answerRequest
      })
    } catch (error) {
      errorHandler('createNewAnswer', questionId, error)
      return Promise.reject(error)
    }
  }, [])

  const uploadBlobChunkPart = useCallback(
    async ({
      questionId,
      media,
      blob,
    }: {
      questionId: number
      media: 'video' | 'audio'
      blob: Blob
    }) => {
      try {
        const question = questionsRef.current[questionId]
        if (!question) {
          throw new Error('Question must exist')
        }

        const answerRequest = await question.answerRequest

        if (!answerRequest) {
          throw new Error('question.answerRequest must exist')
        }

        const requests = question.requests[media]
        let preSignResponse: InterviewAnswer = await question.presignedRequest[requests.currentPart]

        if (!preSignResponse) {
          preSignResponse = await makePreSignRequest(
            questionId,
            answerRequest.id,
            requests.currentPart,
          )
        }

        const uploadChuckRequest = await makeUploadChuckRequest({
          questionId,
          media,
          blob,
          preSignResponse,
        })

        return uploadChuckRequest
      } catch (error) {
        errorHandler('uploadBlobChunkPart', questionId, error)
        return Promise.reject(error)
      }
    },
    [],
  )

  const stopAudioAnswer = useCallback(async (questionId, audioStopPromise) => {
    const question = questionsRef.current[questionId]

    if (
      question.requests.audio.completedRequest ||
      question.requests.audio.finishedRecordingPromise
    ) {
      throw new Error('This method only can be called one time')
    }

    question.requests.audio.finishedRecordingPromise = new Promise(async (resolve, reject) => {
      try {
        const audioMetadata = await audioStopPromise
        await makeCompleteAnswerRequest(questionId, audioMetadata, 'audio')
        resolve()
      } catch (error) {
        reject(error)
      }
    })

    return Promise.allSettled([question.requests.audio.finishedRecordingPromise])
  }, [])

  const stopVideoAnswer = useCallback(async (questionId, videoStopRecordingPromise) => {
    const question = questionsRef.current[questionId]
    if (
      question.requests.video.completedRequest ||
      question.requests.video.finishedRecordingPromise
    ) {
      throw new Error('This method only can be called one time')
    }

    question.requests.video.finishedRecordingPromise = new Promise(async (resolve, reject) => {
      try {
        if (Object.values(question.requests.video.partRequests).length === 0) {
          resolve(undefined)
        } else {
          const assetMetadata = await videoStopRecordingPromise
          await makeCompleteAnswerRequest(questionId, assetMetadata, 'video')
        }

        resolve()
      } catch (error) {
        reject(error)
      }
    })

    return question.requests.video.finishedRecordingPromise
  }, [])

  const stopInterview = useCallback(async () => {
    try {
      const questions = Object.values(questionsRef.current)
      const notInterruptedQuestions = questions.filter(question => !question.isInterrupted)

      const finishedAudioRecordingPromises = await Promise.allSettled(
        notInterruptedQuestions.map(question => question.requests.audio.finishedRecordingPromise),
      )

      const rejectAudioRecordingPromises = finishedAudioRecordingPromises.find(
        promise => promise.status === 'rejected',
      )

      if (rejectAudioRecordingPromises) {
        // TODO: An error occurred during audio upload. This needs to be reported to the backend.
        // {error: ""}
      }

      const finishedVideoRecordingPromises = await Promise.allSettled(
        notInterruptedQuestions.map(question => question.requests.video.finishedRecordingPromise),
      )

      const rejectVideoRecordingPromises = finishedVideoRecordingPromises.find(
        promise => promise.status === 'rejected',
      )

      if (rejectVideoRecordingPromises) {
        // TODO: An error occurred during audio upload. This needs to be reported to the backend.
      }

      await Promise.allSettled(notInterruptedQuestions.map(question => question.interruptedPromise))

      console.log(
        '####stopInterview end' +
          new Date() +
          'hasRejectedPromise:' +
          (await hasRejectedPromise(questionsRef.current)),
      )
    } catch (error) {
      errorHandler('stopInterview', -1, error)
    }
  }, [])

  const interruptedAnswer = useCallback(async questionId => {
    const question = questionsRef.current[questionId]

    question.interruptedPromise = new Promise(async (resolve, reject) => {
      try {
        if (question) {
          question.isInterrupted = true
          question.abortController.abort('Interrupted answer')
          await deleteAnswer(questionId)
          resolve()
        }
      } catch (error) {
        reject(error)
      }
    })
  }, [])

  return {
    interruptedAnswer,
    createNewAnswer,
    uploadBlobChunkPart,
    stopAudioAnswer,
    stopVideoAnswer,
    stopInterview,
  }
}
