import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import Steps from 'builder/components/Steps'
import { useI18n } from 'builder/hooks/useI18n'
import { NavbarWithoutMenu } from '../../Navbar/NavbarWithoutMenu/NavbarWithoutMenu'
import { ConfirmationLeaving } from '../ConfirmationLeaving/ConfirmationLeaving'
import { ChildrenContainer } from '../styles'
import { selectors } from 'builder/modules/interview/interviewModule'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

const TRANSLATION = 'builder.interview_prep_view.navbar'

export const Navbar = () => {
  const navigate = useNavigate()
  const { i18n } = useI18n()

  // check if current url has /page in it
  const location = useLocation()
  const showCloseIcon = !location.pathname.includes('page')
  const currentStep = useTypedSelector(selectors.startCurrentStep)
  const [displayLeavingModal, setDisplayLeavingModal] = useState(false)

  const handleLeavingChoice = (choice: boolean) => {
    if (choice) {
      navigate('/interview-preparation/dashboard')
    } else {
      setDisplayLeavingModal(false)
    }
  }
  return (
    <>
      <NavbarWithoutMenu
        onCloseClick={() => setDisplayLeavingModal(true)}
        showCloseIcon={showCloseIcon}
      >
        <ChildrenContainer>
          <Steps
            steps={[
              i18n.t(`${TRANSLATION}.step_0`),
              i18n.t(`${TRANSLATION}.step_1`),
              i18n.t(`${TRANSLATION}.step_2`),
            ]}
            currentStep={currentStep}
          />
        </ChildrenContainer>
      </NavbarWithoutMenu>
      {displayLeavingModal && <ConfirmationLeaving onChoiceClick={handleLeavingChoice} />}
    </>
  )
}
