import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import * as React from 'react'
import i18n from 'i18n-js' // TODO: Migrate to v4 once it's released
import uniq from 'lodash/uniq'
import setByPath from 'lodash/set'
import { matchPath, useLocation } from 'react-router-dom'
import { apiClient } from 'builder/modules/apiClient'
import { CountryConfig, selectors } from 'builder/modules/init'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import ErrorLogger from 'builder/services/ErrorLogger'
import { GuestResumeContext } from './GuestResumeContext'

// Fallback to English if there is no translation found
i18n.defaultLocale = 'en'
i18n.fallbacks = true

/** "en", "nl-NL", etc */
type Locale = CountryConfig['locale']

/** Translation key without locale prefix (e.g. "builder.account") */
export type Scope = string

/** Path inside of `i18n.translations` object including locale prefix (e.g. "en.builder.account") */
type Path = `${Locale}.${Scope}`

/** Part of the translations object. We just use the same type as 'i18n-js' does */
type Translations = object

/** Joins locale and scope to format a path in `i18n.translations` object ("en.builder.account") */
const formatPath = (locale: Locale, scope: Scope): Path => `${locale}.${scope}`

/** Special error type to filter these errors in Sentry */
class TranslationsFetchingError extends Error {
  constructor(locale: string, scope: string) {
    super(`Failed to load "${formatPath(locale, scope)}" translations`)
    this.name = 'TranslationsFetchingError'
  }
}

/** Special error type to detect broken translations in Sentry */
class MissingTranslationError extends Error {
  constructor(path: Path) {
    super(`Missing "${path}" translation`)
    this.name = 'MissingTranslationError'
  }
}

/** Record reported paths to avoid extra error recordings because of component rerenders */
const reportedTranslationPaths: Path[] = []

/** Return a placeholder for the missing translation and report it to Sentry **/
i18n.missingTranslation = (scope: Scope) => {
  const path = formatPath(i18n.locale, scope)

  if (process.env.NODE_ENV === 'development' && !reportedTranslationPaths.includes(path)) {
    ErrorLogger.log(new MissingTranslationError(path))
    reportedTranslationPaths.push(path)
  }

  if (process.env.NODE_ENV === 'development') {
    return `[missing "${path}" translation]`
  }

  return ''
}

/** Fetches translations for multiple locales in parallel */
const fetchTranslations = (locales: Locale[], scope: Scope): Promise<[Locale, Translations][]> => {
  const requests = locales.map(locale => {
    return apiClient
      .get<Translations>(`/translations/${locale}/${scope}`)
      .then<[Locale, Translations]>(response => [locale, response.data])
  })
  return Promise.all(requests)
}

/** Public i18n context API */
interface I18nContextValue {
  i18n: typeof i18n
  loadScope: (scope: Scope) => Promise<void>
  isScopeLoaded: (scope: Scope) => boolean
  isEditorScopeLoaded: (scope: Scope) => boolean
}

/** Context to get i18n client */
export const I18nContext = React.createContext<I18nContextValue>({ i18n } as I18nContextValue)

/** Provider to load translations */
export const I18nProvider = ({ children }: { children?: React.ReactNode }) => {
  const appLocale = useTypedSelector(selectors.locale)
  const { pathname } = useLocation()
  const isResumeEditor = matchPath('resumes/:id/edit', pathname)
  const isCoverLetterEditor = matchPath('cover-letters/:id/edit', pathname)
  const isGuestResumeEditor = matchPath('guest-builder', pathname)

  const isEditPage = !!isResumeEditor || !!isCoverLetterEditor || !!isGuestResumeEditor

  const resumeLocale = useTypedSelector(selectors.resumeLocale)
  const coverLetterLocale = useTypedSelector(selectors.coverLetterLocale)
  const guestResumeEditorLocale = useContext(GuestResumeContext).resume?.locale || 'en'

  const editorLocale = isCoverLetterEditor
    ? coverLetterLocale
    : isGuestResumeEditor
    ? guestResumeEditorLocale
    : resumeLocale

  // "en", "nl-NL", ..., or undefined
  const [locale, setLocale] = useState(appLocale)

  // For example ["en.builder", "en.organizations"]
  const [loadedPaths, setLoadedPaths] = useState<Path[]>([])

  // Listen application config changes
  useEffect(() => {
    if (!isEditPage) {
      if (!appLocale) return
      i18n.locale = appLocale
      setLocale(appLocale)
    }
  }, [appLocale, isEditPage])

  useEffect(() => {
    if (isEditPage) {
      if (!editorLocale) return
      i18n.locale = editorLocale
      setLocale(editorLocale)
    }
  }, [editorLocale, isEditPage])

  // Checks that the scope (or the parent one) is loaded for the current locale.
  // Example: Returns true if "builder.account" is passed, but whole "builder" is already loaded
  const isScopeLoaded = useCallback(
    (scope: Scope) => {
      if (!locale) return false
      return loadedPaths.some(loadedPath => formatPath(locale, scope).startsWith(loadedPath))
    },
    [locale, loadedPaths],
  )

  const isEditorScopeLoaded = useCallback(
    (scope: Scope) => {
      if (!editorLocale) return false
      return loadedPaths.some(loadedPath => formatPath(editorLocale, scope).startsWith(loadedPath))
    },
    [editorLocale, loadedPaths],
  )

  // Public method to start loading a specific scope for current and fallback locales
  const loadScope = useCallback(
    async (scope: Scope) => {
      if (!locale) return

      try {
        // Avoid fetching "en" locale twice if the website is international
        const localesToFetch = uniq([
          locale,
          i18n.defaultLocale,
          ...(editorLocale ? [editorLocale] : []),
        ])

        // Start loading translations for the current and fallback locales
        const entries = await fetchTranslations(localesToFetch, scope)

        // Save translations to `i18n.translations` object
        entries.forEach(([fetchedLocale, fetchedObject]) => {
          setByPath(i18n.translations, formatPath(fetchedLocale, scope), fetchedObject)
          setLoadedPaths(list => uniq([...list, formatPath(fetchedLocale, scope)]))
        })
      } catch {
        throw new TranslationsFetchingError(locale, scope)
      }
    },
    [locale, editorLocale],
  )

  // Format the context value (public APIs)
  const value = useMemo(() => {
    return { i18n, isScopeLoaded, loadScope, isEditorScopeLoaded }
  }, [isScopeLoaded, loadScope, isEditorScopeLoaded])

  // Render the app and pass the i18n client
  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>
}

/**
 * Requests a translations and waits until they're loaded.
 * @todo Make it Suspense-compatible
 */
export const I18nScope = ({ scope, children }: { scope: Scope; children: JSX.Element }) => {
  const { isScopeLoaded, loadScope } = useContext(I18nContext)
  // Catch the error by closest error boundary
  const [error, setError] = useState<Error | null>(null)
  if (error) throw error

  useEffect(() => {
    if (!isScopeLoaded(scope)) loadScope(scope).catch(err => setError(err))
  }, [isScopeLoaded, loadScope, scope])
  // Do not render the app until the translations are loaded
  return isScopeLoaded(scope) ? children : null
}

// Export client to provide backwards compatibility
export { i18n }
