import { useResumeTailoringBanner } from 'builder/components/DocumentEditor/ResumeTailoringBanner/useResumeTailoringBanner'
import { TailorResumeBannerView1 } from './components/TailorResumeBannerView1/TailorResumeBannerView1'
import { TailorResumeBannerView2 } from './components/TailorResumeBannerView2/TailorResumeBannerView2'
import { BannerTransitionProps } from './types'

type Props = {
  isVisible?: boolean
  transitionProps?: Partial<BannerTransitionProps>
}

const TailorResumeBanner = (props: Props) => {
  const { isVisible = true, transitionProps } = props
  const { onBannerClick, onNotNowClick, type } = useResumeTailoringBanner('builder', isVisible)

  return type === 'new' ? (
    <TailorResumeBannerView2
      isVisible={isVisible}
      onBannerClick={onBannerClick}
      onNotNowClick={onNotNowClick}
      transitionProps={transitionProps}
    />
  ) : (
    <TailorResumeBannerView1
      isVisible={isVisible}
      onBannerClick={onBannerClick}
      onNotNowClick={onNotNowClick}
      transitionProps={transitionProps}
    />
  )
}

export default TailorResumeBanner
