import { connect } from 'react-redux'
import { Component, createRef } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import find from 'lodash/find'
import { compose } from 'redux'
import { filter } from 'lodash'
import { i18n as I18n } from 'builder/utils/i18n'
import { matchMediaQueries } from 'builder/utils/matchMediaQueries'
import DialogModal from 'builder/components/DialogModal'
// import { Icon20 } from 'builder/components/Icon'
import { withMediaQueries } from 'builder/components/MediaQueries'
import { withConfig } from 'builder/components/Config'
import { AppConfig } from 'builder/modules/init'
import { Store } from 'builder/modules/store'
import { UserData } from 'builder/modules/user'
import {
  ColumnName,
  FetchJobsPayload,
  Job,
  JobCreatePayload,
  JobDeletePayload,
  JobMovePayload,
  ResumeSelection,
  actions,
} from 'builder/modules/jobTracking'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import { AutoApplyBubbles } from 'builder/modules/jobSearch/types'
import tunerURL from 'images/modals/resume.jpg'
import { CioLimitModal } from '../CioLimitModal/CioLimitModal'
import JobTrackerExtensionBanner from '../Panel/CareerGoal/components/JobTrackerExtensionBanner'
import Board from './Board'
import BoardPlaceholder from './BoardPlaceholder'
import { Sidebar } from './Sidebar'
import {
  Container,
  Header,
  // HeaderTitle,
  // RunOnboarding,
  RightSide,
  BodyScrollBlocker,
} from './styles'
import ChromeExtensionNotification from './ChromeExtensionNotification'
import CardDrawer from './CardDrawer'
import { CardStatusDrawer } from './CardStatusDrawer/CardStatusDrawer'

type Props = {
  jobs: Job[]
  isJobsLoading: boolean
  columnNames: ColumnName[]
  fetchJobs: (payload: FetchJobsPayload) => void
  createJob: (payload: JobCreatePayload) => void
  deleteJob: (payload: JobDeletePayload) => void
  editJob: (job: Job) => void
  move: (payload: JobMovePayload) => void
  reorder: (payload: JobMovePayload) => void
  setResumeSelection: (payload: ResumeSelection | null) => void
  setCioLimitModal: (payload: boolean) => void
  config: AppConfig | null
  mediaQueries: ReturnType<typeof matchMediaQueries>
  user: UserData
  isCioLimitModal: boolean
  fromDashboard?: boolean
  autoApplyBubbles: AutoApplyBubbles
  openCardDrawer: boolean
  openStatusChangeDrawer: boolean
  setOpenCardDrawer: (value: boolean) => void
  setOpenStatusChangeDrawer: (value: boolean) => void
}

type State = {
  editId: number | null
  tempEditId: number | null
  isOnboardingOpen: boolean
  canInstallExtension: boolean
  interviewJob: Partial<Job> | null
  deleteId: number | null
  BoardComponent?: typeof Board
  lastUpdatedId: number | null
}

// TODO: Rewrite from class component to functional one
class JobTracking extends Component<Props, State> {
  static defaultProps = {
    jobs: [],
  }

  statusDrawerRef: React.RefObject<HTMLDivElement>

  closedOnboardingKey = 'JOB_TRACKER_ONBOARDING_CLOSED'

  constructor(props: Props) {
    super(props)
    this.state = {
      editId: null,
      tempEditId: null,
      isOnboardingOpen: !localStorage.getItem(this.closedOnboardingKey),
      canInstallExtension: false,
      lastUpdatedId: null,
      interviewJob: null,
      deleteId: null,
      BoardComponent: undefined,
    }
    this.statusDrawerRef = createRef<HTMLDivElement>()
  }

  openCardDetailsFromWidgetDashboard(isJobsLoading: boolean) {
    const params = new URLSearchParams(window.location.search)
    const cardId = params.get('widget_card_details_id')

    if (cardId && !isJobsLoading) {
      this.handleCardClick(Number(cardId))
      window.history.pushState({}, '', `${window.location.origin}${window.location.pathname}`)
    }
  }

  async componentDidMount() {
    if (!this.props.autoApplyBubbles.running) {
      this.props.fetchJobs({
        location: 'Remote',
        dummy: this.props.autoApplyBubbles.running,
      })
    }

    import(/* webpackChunkName: "job-tracking-board" */ './Board').then(module => {
      this.setState({
        BoardComponent: module.default,
      })
    })

    setTimeout(this.checkAvailabilityToInstallExtension, 300)
  }

  componentWillUnmount() {
    this.props.setResumeSelection(null)
  }

  checkAvailabilityToInstallExtension = () => {
    const { mediaQueries } = this.props

    const isDesktopChrome = /Chrome/i.test(navigator.userAgent) && !mediaQueries.isPhone
    const isExtensionInstalled = document.getElementById('jtrack-root') !== null
    this.setState({ canInstallExtension: isDesktopChrome && !isExtensionInstalled })
  }

  isSuperApp() {
    return this.props.config?.features.superApp
  }

  createJob = (fields: JobCreatePayload) => {
    this.props.createJob({ ...fields, dummy: this.props.autoApplyBubbles.running })
    trackInternalEvent('create_job_tracking_card')

    // User just created a new card in "interview" column
    // if (this.isSuperApp() && fields.status === ColumnName.interview) {
    //   this.setState({ interviewJob: fields })
    // }
  }

  deleteJob = () => {
    if (this.state.deleteId) this.props.deleteJob({ id: this.state.deleteId })

    this.setState({
      deleteId: null,
      editId: null,
    })
    this.clearOpenDrawers()
  }

  clearOpenDrawers = () => {
    if (this.props.mediaQueries.isPhone) {
      this.props.setOpenStatusChangeDrawer(false)
      this.props.setOpenCardDrawer(false)
    }
  }

  handleCardClick = (editId: number, openCardDrawer = true) => {
    this.setState({ editId })
    this.setState({ tempEditId: editId })
    if (openCardDrawer) {
      this.props.setOpenCardDrawer(true)
    }
  }

  handleDeleteClick = (deleteId: number) => {
    this.setState({ deleteId })
  }

  closeOnboarding = () => {
    this.setState({ isOnboardingOpen: false })
    localStorage.setItem(this.closedOnboardingKey, 'true')
  }

  openOnboarding = () => {
    this.setState({ isOnboardingOpen: true })
  }

  handleEditModalClose = () => {
    this.setState({ editId: null })
    this.clearOpenDrawers()
  }

  handleDeleteModalClose = () => {
    this.setState({ deleteId: null })
  }

  handleEditJob = (patch: Job) => {
    this.props.editJob(patch)
    const currentJob = this.props.jobs.find(job => job.id === patch.id)

    this.setState({ lastUpdatedId: patch.id })
    // Interview Prep is supported by CIO only
    if (!this.isSuperApp()) return
    // Card already was in interview column
    if (currentJob?.status === ColumnName.interview) return
    // User moved it to some another column
    if (patch.status !== ColumnName.interview) return

    this.setState({ interviewJob: patch })
  }

  handleDontShowAgaianClicked = () => {
    this.setState({ editId: this.state.tempEditId })
  }

  handleMove = ({ source, destination }: JobMovePayload) => {
    this.props.move({ source, destination })
    const job = filter(this.props.jobs, { status: source.droppableId })[source.index]
    this.setState({ lastUpdatedId: job.id })
  }

  handleSetInterviewJob = (selectedJob: Partial<Job> | null) => {
    this.setState({ interviewJob: selectedJob })
  }

  render() {
    const { columnNames, jobs, reorder, isJobsLoading, user, isCioLimitModal, fromDashboard } =
      this.props

    const {
      editId,
      lastUpdatedId,
      deleteId,
      BoardComponent,
      // isOnboardingOpen,
      // canInstallExtension,
    } = this.state

    const isFreePlan = !user.hasPremiumFeatures
    this.openCardDetailsFromWidgetDashboard(isJobsLoading)

    const editableJob = editId ? find(jobs, { id: editId }) : null
    const lastUpdatedJob = lastUpdatedId ? find(jobs, { id: lastUpdatedId }) : null
    const isBoardLoaded = BoardComponent && !isJobsLoading

    const handleClose = () => {
      this.props.setCioLimitModal(false)
      trackInternalEvent('skip_paywall')
    }

    // const isOnboardingVisible = isFreePlan || isOnboardingOpen

    const { isPhone } = this.props.mediaQueries
    const openCardDrawer = editId && isPhone && this.props.openCardDrawer
    const openChangeStatusDrawer =
      editId && editableJob && isPhone && this.props.openStatusChangeDrawer

    return (
      <>
        <Container>
          {fromDashboard && (
            <Header fromDashboard={fromDashboard}>
              {fromDashboard && <JobTrackerExtensionBanner componentType="job_tracker_top" />}
              {/* <HeaderTitle>{I18n.t('builder.job_tracking.title')}</HeaderTitle> */}
              <RightSide>
                {/* <RunOnboarding onClick={this.openOnboarding}>
              <Icon20.Info />
              {I18n.t('builder.job_tracking.how_to_use')}
            </RunOnboarding> */}
              </RightSide>
            </Header>
          )}

          {isBoardLoaded ? (
            <BoardComponent
              columnNames={columnNames}
              jobs={jobs}
              move={this.handleMove}
              reorder={reorder}
              lastUpdatedJob={lastUpdatedJob}
              onCardCreate={this.createJob}
              onCardClick={this.handleCardClick}
              isFreePlan={isFreePlan}
              deleteJob={this.props.deleteJob}
              handleSetInterviewJob={this.handleSetInterviewJob}
              interviewJob={this.state.interviewJob}
              fromDashboard={fromDashboard}
            />
          ) : (
            <BoardPlaceholder />
          )}

          {/* {isOnboardingVisible && (
          <Onboarding isFreePlan={isFreePlan} onClose={this.closeOnboarding} />
        )} */}

          {/* Uncomment below lines to show upgrade banner on moving any job to interview column

         {this.isSuperApp() && (
            <InterviewMock
              selectedJob={interviewJob}
              onClose={() => this.setState({ interviewJob: null })}
            />
          )} */}

          <DialogModal
            dontShowAgainId={`${user.id}_deleteItem_jobTracking`}
            title={I18n.t('builder.job_tracking.delete_job')}
            text={I18n.t('builder.job_tracking.delete_modal.sure_to_delete_job')}
            primaryButtonText={I18n.t('builder.job_tracking.delete')}
            secondaryButtonText={I18n.t('builder.job_tracking.cancel')}
            isOpen={!!deleteId}
            primaryAction={this.deleteJob}
            secondaryAction={this.handleDeleteModalClose}
            closeAction={this.handleDeleteModalClose}
            dontShowAgaianClicked={this.handleDontShowAgaianClicked}
          />

          {!fromDashboard && <BodyScrollBlocker />}
        </Container>
        {editId && !isPhone && (
          <Sidebar
            job={editableJob}
            columnNames={columnNames}
            onSubmit={this.handleEditJob}
            onClose={this.handleEditModalClose}
            onDelete={this.handleDeleteClick}
            handleSetInterviewJob={this.handleSetInterviewJob}
            isDashboard={fromDashboard}
          />
        )}
        {openCardDrawer && (
          <CardDrawer fromDashboard={fromDashboard}>
            <Sidebar
              stateChangeRef={this.statusDrawerRef}
              job={editableJob}
              columnNames={columnNames}
              onSubmit={this.handleEditJob}
              onClose={this.handleEditModalClose}
              onDelete={this.handleDeleteClick}
              handleSetInterviewJob={this.handleSetInterviewJob}
              isDashboard={fromDashboard}
            />
          </CardDrawer>
        )}
        {openChangeStatusDrawer && (
          <CardStatusDrawer
            ref={this.statusDrawerRef}
            selectedJob={editableJob}
            columnNames={columnNames}
            editJob={this.handleEditJob}
            onClose={() => this.props.setOpenStatusChangeDrawer(false)}
            onDelete={this.handleDeleteClick}
          />
        )}
        <ChromeExtensionNotification />
        {isCioLimitModal && (
          <CioLimitModal
            documentType="resume"
            cancelButtom={I18n.t('builder.job_tracking.limit_modal.cancel')}
            upgradebutton={I18n.t('builder.job_tracking.limit_modal.upgrade')}
            tunerImg={tunerURL}
            onClose={() => handleClose()}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state: Store) => {
  return {
    user: state.user.data,
    isCioLimitModal: state.jobTracking.isCioLimitModal,
    autoApplyBubbles: jobSearchSelectors.autoApplyBubbles(state),
    openCardDrawer: state.jobTracking.openCardDrawer,
    openStatusChangeDrawer: state.jobTracking.openStatusChangeDrawer,
  }
}

const mapDispatchToProps = {
  setResumeSelection: actions.setResumeSelection,
  setCioLimitModal: actions.setCioLimitModal,
  setOpenCardDrawer: actions.setOpenCardDrawer,
  setOpenStatusChangeDrawer: actions.setOpenStatusChangeDrawer,
}

export default compose(
  withConfig,
  withMediaQueries,
  connect(mapStateToProps, mapDispatchToProps),
)(JobTracking)
