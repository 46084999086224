import { useConfig } from 'builder/hooks/useConfig'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { useState } from 'react'
import SubscriptionCancellationViewOld from '../SubscriptionCancellationViewOld'
import { SubscriptionCancellationAutoApplyWarning } from './AutoApplyWarning'
import { SubscriptionCancellationViewStepOne } from './StepOne'
import { SubscriptionCancellationViewStepThree } from './StepThree'

export const SubscriptionCancellationView = () => {
  const [step, setStep] = useState(1)
  const config = useConfig()
  const isSubscriptionV4Enabled = config?.features.subCancellationV4 || false
  const autoApplyInSubCancellation = config?.features.autoApplyInSubCancellation || false
  const { host } = window.location
  const isHostRioCio =
    host === 'staging.resume.io' ||
    host === 'resume.io' ||
    host === 'career.io' ||
    host === 'staging.career.io' ||
    host.includes('localhost')
  const { isUserOnAutoApplyPlan, getAutoApplyCredit } = useAutoApply()
  const { creditCount, creditCountUsed } = getAutoApplyCredit()
  const hasCreditLeft = creditCountUsed < creditCount

  if (!isHostRioCio && !isSubscriptionV4Enabled) {
    return <SubscriptionCancellationViewOld />
  }

  return (
    <>
      {isSubscriptionV4Enabled && step === 1 && (
        <SubscriptionCancellationViewStepOne
          onContinueCancellation={() =>
            isUserOnAutoApplyPlan && autoApplyInSubCancellation && hasCreditLeft
              ? setStep(2.5)
              : setStep(3)
          }
        />
      )}
      {step === 2 && (
        <SubscriptionCancellationViewStepOne
          onContinueCancellation={() =>
            isUserOnAutoApplyPlan && autoApplyInSubCancellation && hasCreditLeft
              ? setStep(2.5)
              : setStep(3)
          }
        />
      )}
      {step === 2.5 && (
        <SubscriptionCancellationAutoApplyWarning onContinueCancellation={() => setStep(3)} />
      )}
      {(!isSubscriptionV4Enabled || step === 3) && <SubscriptionCancellationViewStepThree />}
    </>
  )
}
