import { apiClient, ApiResponse } from 'organizations/utils/apiClient'
import { Dispatch } from 'organizations/store'
import { RequestStatuses } from 'organizations/constants'
import { User } from './types'
import { actions } from './slice'

type FetchResponse = ApiResponse<{ users: User[]; page: number; total: number; perPage: number }>
type ImpersonateResponse = ApiResponse<{ link: string }>

/**
 * Gets a paginated list of users from the API
 */
export const fetchPage =
  (page = 1) =>
  async (dispatch: Dispatch) => {
    dispatch(actions.setFetchStatus(RequestStatuses.loading))

    const params = { page, perPage: 10 }
    const { data } = await apiClient.get<FetchResponse>('/users/', { params })
    const { users, total, perPage } = data
    const pageCount = Math.ceil(total / perPage)

    dispatch(actions.setList(users))
    dispatch(actions.setPagination({ page, pageCount }))
    dispatch(actions.setFetchStatus(RequestStatuses.complete))
  }

/**
 * Get a temporary impersonation URL and open it in a new tab
 */
export const impersonate = (userId: number) => async () => {
  const { data } = await apiClient.post<ImpersonateResponse>(`/users/${userId}/impersonate`)
  window.open(data.link)
}
