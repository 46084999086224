import React from 'react'
import { Container } from './styles'

interface Props {
  text: string | undefined
  onClick: () => void
  icon: React.ReactNode
  isPrimary?: boolean
}

export const Button = ({ onClick, text, icon, isPrimary = false }: Props) => {
  return (
    <Container $isPrimary={isPrimary} onClick={onClick}>
      {text} {icon}
    </Container>
  )
}
