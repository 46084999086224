import { CompanyName } from 'builder/views/Interview/hooks/useCompanyNames'

export interface CareerPathwaysJobTitleResponse {
  jobTitle: string
}
export interface JobTitle {
  standardTitle: string
  id: number
}

export interface NewInterviewResponse {
  id: number
  data_api_job_title_id: number
}

export interface InterviewQuestion {
  id: number
  announcement?: string
  question_template_id: number
  base_question_id: number | null
  interview_id: number
  content: string
  hint: string
  question_type: string
  data_api_job_title_id: number
  status: Status
  answer?: InterviewAnswer
  created_at: string
  updated_at: string
  conversation_end: boolean
}

export interface InterviewQuestionTemplate extends InterviewQuestion {
  used?: boolean
}

type Grade = 'average' | 'perfect' | 'good'

export interface Pagination<T> {
  items: T[]
  page: number
  per_page: number
  total: number
}

export interface Interview {
  id: number
  data_api_job_title_id: number
  questions_count: number
  answered_questions: number
  questions: InterviewQuestion[]
  job_title: string
  created_at: string
  updated_at: string
  status: Status
  company_name?: string
  user_id: string
  grade: Grade
  total_duration: number
  logo_url: string
  kind: 'custom' | 'one_question' | 'test_interview'
  base_interview_id?: number
  type_of_questions: 'nervous' | 'confident' | 'ambitious' | null
  real_company?: boolean
  version: number
  main_questions_count: number
  announcement: string | null
  welcome_msg: string
}

export interface InterviewAnswerFeedback {
  id: number
  content: string
  ideal_answer: string
  ton_of_voice: string
  filler_words: number
  pauses: string[]
  words_per_minute: number
  grade: Grade
  ai_interpretation: string
  answer_id: number
  classification: string
  // new fields for version two
  is_structured: boolean
  ton_of_voice_summary_headline_strengths: string | null
  ai_summary_strengths: string | null
  strengths_headline: string | null
  strengths: string | null
  ton_of_voice_summary_headline_areas_for_improvement: string | null
  ai_summary_areas_for_improvement: string | null
  areas_for_improvement_headline: string | null
  areas_for_improvement: string | null
  ton_of_voice_interpretation: string | null
}

export interface InterviewAiJobStatus {
  job_id?: string
  status?: 'pending' | 'success' | 'failed'
}

export interface InterviewAnswer {
  id: number
  question_id: string
  audio: string
  video: string
  content: string
  proficient: boolean
  feedback: InterviewAnswerFeedback
  audio_upload_url: string
  video_upload_url: string
  created_at: string
  updated_at: string
  duration: number
  ai_job?: InterviewAiJobStatus
  ai_speech_job?: InterviewAiJobStatus
}

export type JobSuggestionType = {
  text: string
  id: number
}

export type CompanySuggestionType = {
  text: string
  id: string
} & CompanyName &
  Record<string, unknown>

export type MyInterviewsResponseType = {
  interview_templates: InterviewTemplate[]
  question_templates: QuestionTemplate[]
}
export type Status = 'completed' | 'started' | 'initiated' | 'cancelled'

export type InterviewTemplate = {
  id: number
  data_api_job_title_id: number
  job_title: string
  company_name?: string
  logo_url?: string
  status: Status
  completed_question?: number
  proficient?: boolean
  questions_count: number
  interview_id: number
}
export type QuestionTemplate = {
  id: number
  data_api_job_title_id: number
  content: string
  hint: string
  status: Status
  used?: boolean
}

export interface DailySuggestionsResponse {
  question_templates: QuestionTemplate[]
  interview_template: InterviewTemplate
  company_interview: InterviewTemplate
}

export interface DailySuggestionsError {
  error: string
}

export type LoadingScreenItem = { title: string; content: string; customContentWidth?: number }

export enum InterviewPlayerModalType {
  loading_start = 'loading_start',
  loading_interrupted_interview = 'loading_interrupted_interview',
  loading_end = 'loading_end',
  permission_modal_initial = 'permission_modal_initial',
  permission_modal_help = 'permission_modal_help',
  close_modal = 'close_modal',
  session_modal = 'session_modal',
  question_interval = 'question_interval',
}

export enum InterviewPlayerSnackBar {
  are_you_sure_you_want_to_proceed = 'are_you_sure_you_want_to_proceed',
  are_you_sure_you_want_to_finish = 'are_you_sure_you_want_to_finish',
}

export enum InterviewPlayerStatus {
  start_recording_answer = 'start_recording_answer',
  stop_recording_answer = 'stop_recording_answer',
  interrupted_recording_answer = 'interrupted_recording_answer',
  pause_recording_answer = 'pause_recording_answer',
  stop_interview = 'stop_interview',
}

export enum InterviewPlayerMiddleContent {
  welcome = 'welcome',
  question = 'question',
  waiting_question = 'waiting_question',
}

export enum InterviewPlayerBadge {
  hit_play_to_continue = 'hit_play_to_continue',
  great_timing = 'great_timing',
  hurry_up = 'hurry_up',
}

export type InterviewStore = {
  jobTitleId: number
  jobTitle: string
  interviewId: number
  activeInterviewTab: ActiveInterviewTab
  startCurrentStep: number
  interviewPlayer: {
    showAllPhrases: boolean
    modal: InterviewPlayerModalType | null
  }
}

export interface InterviewLocationParams {
  company?: string
  jobTitle?: {
    id?: number
    text?: string
  }
  description?: string
}

export type ActiveInterviewTab = 'in_progress' | 'complete'

export type CompanyInterview = {
  company_name: string
  job_title: string
  logo_url: string
  questions_count: number
}

export enum InterviewBehaviour {
  NERVOUS = 'nervous',
  CONFIDENT = 'confident',
  AMBITIOUS = 'ambitious',
}

export enum FeedbackVersionEnum {
  v1 = 'v1',
  v2 = 'v2',
}
export type behaviourType = `${InterviewBehaviour}`

export interface Ready<T> {
  isReady: boolean
  payload: T
}

export enum VoiceAnimationEnum {
  playing = 'playing',
  reset = 'reset',
}

export type VoiceAnimationEvent =
  | { type: VoiceAnimationEnum.reset }
  | { type: VoiceAnimationEnum.playing; waveform: Uint8Array; analyser: AnalyserNode }
