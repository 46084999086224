import styled, { css } from 'styled-components'
import Button from 'builder/components/Button'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import Icon24 from 'builder/components/Icon'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${Sizes['2XS']};
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`
export const FilterButton = styled(Button)<{ isDisabled?: boolean; selected?: boolean }>`
  border: 1px solid ${Colors.Neutral20};
  background-color: ${Colors.White};
  border-radius: ${Sizes['3XS']};
  color: ${Colors.Neutral90};
  text-align: center;
  height: 36px;
  padding: ${Sizes['2XS']};
  ${Typography.ButtonS}
  ${FontWeights.Medium}
  &:hover,
  &:focus-visible {
    border-color: ${Colors.Blue50};
    color: ${Colors.Blue50};
    background-color: ${Colors.White};
    svg path {
      stroke: ${Colors.Blue50};
    }
  }

  &:active {
    border-color: ${Colors.Blue50};
    color: ${Colors.Blue50};
    background-color: ${Colors.Blue10};

    svg path {
      stroke: ${Colors.Blue50};
    }
  }

  ${props =>
    props.isDisabled &&
    css`
      color: ${Colors.Neutral50};
      background-color: ${Colors.Neutral20};
    `};
  ${props =>
    props.selected &&
    css`
      border-color: ${Colors.Blue50};
      color: ${Colors.White};
      background-color: ${Colors.Blue50};
    `};

  div {
    ${Typography.Caption};
    ${FontWeights.Medium};
  }
`

export const ClearButton = styled(FilterButton)`
  width: 36px;
  height: 36px;
  color: ${Colors.Neutral50};
`

export const DropDownIcon = styled(Icon24.ChevronDown)<{ isDisabled?: boolean; selected: boolean }>`
  svg {
    fill: none;
    path {
      stroke: ${Colors.Neutral90};
      stroke-width: 1.4;

      ${props =>
        props.isDisabled &&
        css`
          stroke: ${Colors.Neutral50};
        `};
      ${props =>
        props.selected &&
        css`
          stroke: ${Colors.White};
        `};
    }
  }
`

export const ChildrenContainer = styled.div`
  display: flex;

  ${Media.AbovePhone`
    width: 100%;
  flex: 1;
  justify-content: flex-end;
    `}
`
