import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
import { WithFoldableSections } from './types'

type SectionContainerProps = {
  isDragging?: boolean
  value: string
} & WithFoldableSections

type SectionHeaderContainerProps = {
  compact?: boolean
  $isOpened?: boolean
} & WithFoldableSections

export const foldableSectionMixin = css`
  background-color: ${Colors.White};
  border-radius: ${Sizes.XS};
  margin-bottom: 4px;
`

export const SectionContainer = styled.div<SectionContainerProps>(props => {
  const { isDragging, isFoldableSectionsEnabled } = props

  return css`
    position: relative;
    opacity: ${isDragging ? 0.6 : 1};

    ${isFoldableSectionsEnabled
      ? foldableSectionMixin
      : css`
          margin-bottom: 40px;
        `}

    /* fix cursor */
    pointer-events: auto !important;
    -webkit-touch-callout: none;
    user-select: none;

    &:before {
      content: '';
      display: ${isDragging ? 'block' : 'none'};
      position: absolute;
      background-color: ${Colors.White};
      box-shadow: 0px 3px 12px rgba(112, 131, 151, 0.3);
      pointer-events: none;

      ${isFoldableSectionsEnabled
        ? css`
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: ${Sizes.XS};
          `
        : css`
            left: -24px;
            right: -20px;
            bottom: -16px;
            top: -16px;
            border-radius: 4px;

            ${Media.Laptop`
              left: -20px;
            `};
          `}
    }

    ${!isFoldableSectionsEnabled &&
    css`
      &:after {
        content: '';
        display: ${isDragging ? 'block' : 'none'};
        position: absolute;
        height: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to bottom, ${hexToRgba(Colors.White, 0)}, ${Colors.White});
        pointer-events: none;
      }
    `}
  `
})

export const SectionHeaderContainer = styled.div<SectionHeaderContainerProps>`
  position: relative;
  margin-bottom: 16px;

  ${props =>
    !props.compact &&
    Media.AbovePhone`
      margin-bottom: 24px;
    `}

  ${props =>
    props.isFoldableSectionsEnabled &&
    css`
      cursor: pointer;
      margin-bottom: 0;
      padding: 20px 36px;

      ${Media.Phone`
        padding: 21px 20px;
      `};
    `}
`

export const SectionContent = styled.div<{ isDragging?: boolean }>`
  position: relative;

  ${props =>
    props.isDragging &&
    css`
      overflow: hidden;
      max-height: 140px;
    `}
`

export const ExtraContainer = styled.div`
  margin-top: 12px;
`

export const SectionHintContainer = styled.div`
  margin-top: 2px;
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const SectionSubtitleContainer = styled.div`
  margin-top: 8px;

  ${Media.Phone`
    margin-top: 2px;
    ${Typography.Caption};
  `}
`

export const SectionHeaderFoldableContent = styled.div``

export const SectionBodyFoldableContent = styled.div`
  padding: 0 36px 20px;

  ${Media.Phone`
    padding: 0 20px 21px;
  `};
`
