import styled from 'styled-components'

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Image = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
`
