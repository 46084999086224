import { memo, useCallback, useEffect, useRef } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useLocation } from 'react-router-dom'
import Icon from 'builder/components/Icon'
import { MouseClickEvent } from 'builder/modules/ui'
import { i18n as I18n } from 'builder/utils/i18n'
import { DocumentTypes } from 'builder/modules/constants'
import SpacingControl from 'builder/components/DocumentCustomizationModal/SpacingControl'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { useUpdateSimpleField } from 'builder/hooks/useUpdateSimpleField'
import { navigate } from 'builder/modules/navigate'
import ChangeTemplateTooltip from 'builder/components/FillResumeModal/UseExample/ChangeTemplateTooltip'
import DownloadMenuLayout from '../DownloadMenuLayout'
import { CurrentDocument } from '../../types'
import {
  Topbar,
  SelectTemplateButtonContainer,
  SelectTemplateButton,
  SelectTemplateIcon,
  SelectTemplateText,
  ButtonContainerRight,
  Divider,
} from './styles'

type Props = {
  type: DocumentTypes
  currentDocument: CurrentDocument
  documentId: number
  width: number
  isDownloading: boolean
  handleFullSize: (event: MouseClickEvent) => void
  customAction?: () => void
  customDownloadAction?: () => void
}

export const TopbarLayout = memo((props: Props) => {
  const {
    width,
    type,
    documentId,
    currentDocument,
    isDownloading,
    handleFullSize,
    customAction,
    customDownloadAction,
  } = props
  const { updateSimpleField } = useUpdateSimpleField()
  const location = useLocation()
  const exploreTemplateButtonRef: React.MutableRefObject<null | HTMLSpanElement> = useRef(null)

  const templates = useAppSelector(store => store.generalEditor.resumeTemplates)
  const showChangeTemplateTooltip = useAppSelector(
    store => store.resumeEditor.useExample.showChangeTemplateTooltip,
  )
  const currentTemplate = templates.find(template => template.id === currentDocument?.template)
  const supportsSpacing = currentTemplate?.supportsSpacing || !!customAction

  const isResume = type === 'resume'

  const handleSelectTemplate = useCallback(
    (event: MouseClickEvent) => {
      trackInternalEvent(`click_select_${type}_template_button`)
      handleFullSize(event)
    },
    [handleFullSize, type],
  )
  const handleSpacingChange = (spacing: number) => {
    updateSimpleField({ name: 'spacing', value: spacing, debounce: true })
  }

  const queryParams = new URLSearchParams(location.search)
  const exploreTemplates = queryParams.get('show_templates')

  useEffect(() => {
    if (exploreTemplates === '1') {
      const newUrlWithoutExploreParam = `${location.pathname}${location?.search?.replaceAll(
        '?show_templates=1',
        '',
      )}`
      navigate(newUrlWithoutExploreParam)
      if (exploreTemplateButtonRef.current?.click) {
        exploreTemplateButtonRef.current?.click()
      }
    }
  }, [exploreTemplates, handleSelectTemplate, location.pathname, location?.search])

  return (
    <Topbar previewWidth={width}>
      <ButtonContainerRight>
        <SelectTemplateButtonContainer>
          <SelectTemplateButton
            onClick={customAction || handleSelectTemplate}
            ref={exploreTemplateButtonRef}
          >
            <SelectTemplateIcon>
              <Icon.Grid />
            </SelectTemplateIcon>
            <SelectTemplateText>
              {I18n.t('builder.resume_editor.select_template')}
            </SelectTemplateText>
          </SelectTemplateButton>
          {showChangeTemplateTooltip && <ChangeTemplateTooltip />}
        </SelectTemplateButtonContainer>
        {isResume && (
          <>
            <Divider />
            <SpacingControl
              isSupported={supportsSpacing}
              value={currentDocument?.spacing}
              onChange={customAction || handleSpacingChange}
              isTopbarLayout
            />
          </>
        )}
      </ButtonContainerRight>
      <DownloadMenuLayout
        {...{
          type,
          documentId,
          isDownloading,
          handleMenuClick: customAction,
          handleDownloadPdfClick: customDownloadAction,
          currentDocument,
          handleFullSize,
        }}
      />
    </Topbar>
  )
})
