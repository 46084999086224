import { UseFormReturn } from 'react-hook-form'
import paypalSupportedCurrencies from 'builderStatic/js/components/config/paypalSupportedCurrency'
import * as SimpleForm from 'builder/components/SimpleForm'
import { TextField } from 'builder/components/TextField'
import { useI18n } from 'builder/hooks/useI18n'
import { AutoApplyForm, SalaryPlan as SalaryPlanType } from 'builder/modules/autoApply/types'
import { ClusterPicker, SelectItem } from 'builder/components/ClusterPicker/ClusterPicker'
import { useJobTitles } from 'builder/hooks/useJobTitles'
import { CurrencySelect, Salary, SalaryPlanSelect, SmallTitle } from './styles'

const MAX_ROLES = 5
const TRANSLATION = `builder.auto_apply.form.job_preferences_1`

const numberCorrector = (str: string) => {
  return str
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*?)\..*/g, '$1')
    .replace(/^0[^.]/, '0')
}

type Props = {
  form: UseFormReturn<Pick<AutoApplyForm, 'targetRoles' | 'jobPreferenceAttributes'>>
}

export const JobPreferencesForm1 = (props: Props) => {
  const {
    watch,
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = props.form
  const { i18n } = useI18n()
  const { mutateAsync: getJobTitle } = useJobTitles()

  const required = i18n.t(`${TRANSLATION}.field_is_required`)
  const maxRoles = i18n.t(`${TRANSLATION}.target_role.max_error`)
  const currencies = paypalSupportedCurrencies.map(c => ({ id: c.id, name: c.id }))

  const SalaryPlan = [
    {
      id: 'annually',
      name: i18n.t(`${TRANSLATION}.salary_plan.annually`),
    },
    {
      id: 'monthly',
      name: i18n.t(`${TRANSLATION}.salary_plan.monthly`),
    },
  ]

  const setLocationError = (hasError: boolean) => {
    if (hasError) {
      setError('targetRoles', { message: maxRoles })
    } else {
      clearErrors('targetRoles')
    }
  }

  const parseLocation = (roles: AutoApplyForm['targetRoles']) => {
    return roles?.map(role => ({
      value: role.id ? role?.id.toString() : '',
      label: role?.standardTitle || '',
    }))
  }

  const onSelectLocation = (role: SelectItem) => {
    const parsedRole = { id: parseInt(role.value), standardTitle: role.label }
    const roles = [parsedRole, ...getValues('targetRoles')]
    const hasError = roles.length > MAX_ROLES

    setValue('targetRoles', roles)
    setLocationError(hasError)
  }

  const onUnselectLocation = (roles: SelectItem[]) => {
    const hasError = roles.length > MAX_ROLES
    const parsedRoles = roles.map(role => ({
      id: parseInt(role.value),
      standardTitle: role.label,
    }))

    setValue('targetRoles', parsedRoles)
    setLocationError(hasError)
  }

  const searchRoles = async (val: string) => {
    const jobs = await getJobTitle(val)
    return jobs.map(job => ({
      value: job?.id?.toString() || '',
      label: job.standardTitle || '',
    }))
  }

  return (
    <>
      <SimpleForm.Row position="relative">
        <ClusterPicker
          {...register('targetRoles', {
            required,
            validate: val => (val.length > MAX_ROLES ? maxRoles : true),
          })}
          showLimit={5}
          showIndicators={{ dropdown: true }}
          selectedList={parseLocation(getValues('targetRoles'))}
          onSelect={onSelectLocation}
          loadOptions={searchRoles}
          onUnselect={onUnselectLocation}
          label={i18n.t(`${TRANSLATION}.target_role.label`)}
          hint={i18n.t(`${TRANSLATION}.target_role.info`)}
          placeholder={i18n.t(`${TRANSLATION}.target_role.placeholder`)}
          // @ts-expect-error custom message error
          error={errors.targetRoles?.message}
        />
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <SmallTitle>{i18n.t(`${TRANSLATION}.current_salary.label`)}</SmallTitle>
        <Salary>
          <CurrencySelect
            {...register('jobPreferenceAttributes.currentCurrency', { required })}
            error={errors.jobPreferenceAttributes?.currentCurrency?.message}
            selected={watch('jobPreferenceAttributes.currentCurrency')}
            onSelect={val =>
              setValue('jobPreferenceAttributes.currentCurrency', val?.toString() || '')
            }
            options={currencies}
          />
          <TextField
            {...register('jobPreferenceAttributes.currentSalary', { required })}
            type="number"
            min="0"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (+e.target.value < 0) {
                e.target.value = numberCorrector(e.target.value)
              }
            }}
            error={errors.jobPreferenceAttributes?.currentSalary?.message}
          />
          <SalaryPlanSelect
            {...register('jobPreferenceAttributes.currentSalaryPlan', { required })}
            error={errors.jobPreferenceAttributes?.currentSalaryPlan?.message}
            selected={watch('jobPreferenceAttributes.currentSalaryPlan')}
            onSelect={val =>
              setValue(
                'jobPreferenceAttributes.currentSalaryPlan',
                (val?.toString() as SalaryPlanType) || 'annually',
              )
            }
            options={SalaryPlan}
          />
        </Salary>
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <SmallTitle>{i18n.t(`${TRANSLATION}.expected_salary.label`)}</SmallTitle>
        <Salary>
          <CurrencySelect
            {...register('jobPreferenceAttributes.expectedCurrency', { required })}
            error={errors.jobPreferenceAttributes?.expectedCurrency?.message}
            selected={watch('jobPreferenceAttributes.expectedCurrency')}
            onSelect={val =>
              setValue('jobPreferenceAttributes.expectedCurrency', val?.toString() || '')
            }
            options={currencies}
          />
          <TextField
            {...register('jobPreferenceAttributes.expectedSalary', { required })}
            type="number"
            min="0"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (+e.target.value < 0) {
                e.target.value = numberCorrector(e.target.value)
              }
            }}
            error={errors.jobPreferenceAttributes?.expectedSalary?.message}
          />
          <SalaryPlanSelect
            {...register('jobPreferenceAttributes.expectedSalaryPlan', { required })}
            error={errors.jobPreferenceAttributes?.expectedSalaryPlan?.message}
            selected={watch('jobPreferenceAttributes.expectedSalaryPlan')}
            onSelect={val =>
              setValue(
                'jobPreferenceAttributes.expectedSalaryPlan',
                (val?.toString() as SalaryPlanType) || 'annually',
              )
            }
            options={SalaryPlan}
          />
        </Salary>
      </SimpleForm.Row>
    </>
  )
}
