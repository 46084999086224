import { memo } from 'react'
import Anchor from '../Anchor'
import { WithFoldableSections } from '../Section'
import PowerStatement from './PowerStatement'

type Props = WithFoldableSections & {
  sectionId: string
}

const PowerStatementSection = (props: Props): JSX.Element => {
  const { sectionId, isFoldableSectionsEnabled } = props
  return (
    <Anchor id={sectionId} scrollOffsetTop={70}>
      <PowerStatement {...{ isFoldableSectionsEnabled }} />
    </Anchor>
  )
}

export default memo(PowerStatementSection)
