import { useContext, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { SectionNames } from 'builder/modules/resumeEditor'
import { Icon40 } from 'builder/components/Icon'
import PersonalDetailsSectionGuest from 'builder/components/PersonalDetailsSectionGuest'
import SectionsListGuest from 'builder/components/SectionsList/SectionsListGuest'
import EditorTitle from 'builder/components/EditorTitle'
import LanguageSelect from 'builder/components/LanguageSelect'
import Anchor from 'builder/components/Anchor'
import { GuestResumeContext } from 'builder/context/GuestResumeContext'
import { GeneralEditorStore } from 'builder/modules/generalEditor'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useI18n } from 'builder/hooks/useI18n'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import TailorResumeBannerGuest from '../ImproveResumePanel/components/TailorResumeBanner/TailorResumeBannerGuest'
import { ResumeScoreGuest } from '../ResumeScore/ResumeScoreGuest'
import { FillResumeModalGuest } from '../FillResumeModal'
import { SignUpBuilderGuestModal } from '../SignUpBuilderGuestModal'
import { FillByUrlParamValues } from '../FillResumeModal/constants'
import { EditorPanel, EditorScroll, EditorContent, EditorSubtitle, PreviewButton } from './styles'
import { ResumeSideMenuGuest } from './ResumeSideMenuGuest/ResumeSideMenuGuest'
import { ElementHighlight } from './ElementHighlight'
import { BuilderType } from './ResumeSideMenu/types'

type Props = {
  editorState: GeneralEditorStore
}

const ResumeEditorGuest = (props: Props) => {
  const { editorState } = props
  const isOnline = useTypedSelector(state => state.application.isOnline)
  const { i18n } = useI18n()
  const { locales } = editorState
  const { resume, updateResumeFields, toggleEmailRequiredError } = useContext(GuestResumeContext)
  const { name, locale = 'en', template, showPrefillResumeModal } = resume
  const [registerModalPrefillOption, setRegisterModalPrefillOption] =
    useState<FillByUrlParamValues | null>(null)
  const [isPrefillModalOpened, setIsPrefillModalOpened] = useState(showPrefillResumeModal ?? true)

  const onPrefillModalClose = () => {
    setIsPrefillModalOpened(false)
    updateResumeFields({ showPrefillResumeModal: false })
  }

  const onPrefillModalDisabledItemClick = (type: FillByUrlParamValues) => {
    setIsPrefillModalOpened(false)
    setRegisterModalPrefillOption(type)
  }

  const onSignUpModalClose = () => {
    setIsPrefillModalOpened(true)
    setRegisterModalPrefillOption(null)
  }

  useEffectOnMount(() => {
    trackInternalEvent('visit_resume_editor', { editor: 'builder' })
  })

  return (
    <>
      <ResumeSideMenuGuest
        isOnline={isOnline}
        builderType={BuilderType.resumeBuilder}
        onClick={toggleEmailRequiredError}
      />

      <EditorPanel data-testid="editor-panel">
        <EditorScroll>
          <EditorContent>
            <EditorTitle
              onChange={value => updateResumeFields({ name: value })}
              value={name || i18n.t('builder.resume_editor.untitled')}
            />

            <EditorSubtitle>
              <LanguageSelect
                selected={locale}
                onSelect={(value: string) => updateResumeFields({ locale: value })}
                options={locales}
              />
            </EditorSubtitle>

            <ResumeScoreGuest improveButtonOnClick={toggleEmailRequiredError} />
            <TailorResumeBannerGuest onBannerClick={toggleEmailRequiredError} />
            <Anchor id={SectionNames.details} scrollOffsetTop={70}>
              <PersonalDetailsSectionGuest />
            </Anchor>

            <SectionsListGuest />
          </EditorContent>
        </EditorScroll>
        <ElementHighlight />
        {isPrefillModalOpened && (
          <FillResumeModalGuest
            onClose={onPrefillModalClose}
            onDisabledItemClick={onPrefillModalDisabledItemClick}
          />
        )}
        {registerModalPrefillOption && template && (
          <SignUpBuilderGuestModal
            templateId={template}
            prefillOption={registerModalPrefillOption}
            onClose={onSignUpModalClose}
          />
        )}
      </EditorPanel>

      <PreviewButton onClick={toggleEmailRequiredError}>
        {i18n.t('builder.sign_up.preview')}
        <Icon40.Document />
      </PreviewButton>
    </>
  )
}

export default ResumeEditorGuest
