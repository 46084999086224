import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequestStatuses } from 'organizations/constants'
import { UsersStore, User } from './types'

const initialState: UsersStore = {
  list: [],
  fetchStatus: RequestStatuses.notAsked,
  page: 1,
  pageCount: 0,
}

const { reducer, actions } = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<User[]>) => {
      state.list = action.payload
    },
    setFetchStatus: (state, action: PayloadAction<RequestStatuses>) => {
      state.fetchStatus = action.payload
    },
    setPagination: (state, action: PayloadAction<{ page: number; pageCount: number }>) => {
      state.page = action.payload.page
      state.pageCount = action.payload.pageCount
    },
  },
})

export { reducer, actions }
