import { config, useTransition } from '@react-spring/web'
import { RefObject, useEffect } from 'react'
import Button from 'builder/components/Button'
import Checkbox from 'builder/components/Checkbox'
import { useI18n } from 'builder/hooks/useI18n'
import { useSignUpCopyright } from 'builder/components/SignUpCopyright'
import {
  Wrapper,
  Container,
  Arrow,
  Header,
  Title,
  Description,
  ReceiveAdsContainer,
  Footer,
  ButtonContainer,
} from './styles'

type Props = {
  isVisible: boolean
  isSubmitting: boolean
  onSubmit: () => void
  isConsentChecked: boolean
  onSetIsConsentToggled: (value: boolean) => void
  modalRef?: RefObject<HTMLDivElement>
  onShow?: () => void
}

export const SignUpModal = (props: Props) => {
  const {
    isVisible,
    isSubmitting,
    onSubmit,
    onSetIsConsentToggled,
    isConsentChecked,
    modalRef,
    onShow,
  } = props
  const { i18n } = useI18n()
  const copyrightMarkup = useSignUpCopyright({ isEmailOnly: true })
  const transition = useTransition(isVisible, {
    config: config.default,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  useEffect(() => {
    if (isVisible) {
      onShow?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return transition((style, item) =>
    item ? (
      <Wrapper style={style} ref={modalRef}>
        <Arrow />
        <Container>
          <Header>
            <Title>{i18n.t('builder.sign_up.sign_up_with_email_modal.title')}</Title>
            <Description>
              {i18n.t('builder.sign_up.sign_up_with_email_modal.description')}
            </Description>
          </Header>

          <ButtonContainer>
            <Button onClick={onSubmit} isLoading={isSubmitting} isDisabled={isSubmitting}>
              {i18n.t('builder.sign_up.sign_up_with_email_modal.button_text')}
            </Button>
          </ButtonContainer>

          <ReceiveAdsContainer>
            <Checkbox
              label={i18n.t('builder.sign_up.consent_checkbox_label')}
              checked={isConsentChecked}
              onChange={event => onSetIsConsentToggled(event.target.checked)}
              name="confirm_email"
            />
          </ReceiveAdsContainer>

          <Footer dangerouslySetInnerHTML={{ __html: copyrightMarkup }} />
        </Container>
      </Wrapper>
    ) : null,
  )
}
