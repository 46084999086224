import { useDispatch } from 'react-redux'
import { useI18n } from 'builder/hooks/useI18n'
import Button from 'builder/components/Button'

import { actions } from 'builder/modules/interview/interviewModule'
import { DialogModalV2 } from 'builder/components/DialogModal'
import { InterviewModalsProps } from '../../InterviewModals'
import { Bottom, Content } from './styles'

export const TRANSLATION = 'builder.interview_prep_view.interview_player_v2.close_modal'

export const CloseModal = ({ handleInterruptedRecordingAnswer }: Partial<InterviewModalsProps>) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()

  const handleClose = () => dispatch(actions.setInterviewPlayerModal(null))

  return (
    <DialogModalV2 onClose={handleClose} title={i18n.t(`${TRANSLATION}.title`)}>
      <Content>{i18n.t(`${TRANSLATION}.content`)}</Content>
      <Bottom>
        <Button theme="ghost" onClick={handleInterruptedRecordingAnswer}>
          {i18n.t(`${TRANSLATION}.leave`)}
        </Button>

        <Button onClick={handleClose}>{i18n.t(`${TRANSLATION}.keep_preparing`)}</Button>
      </Bottom>
    </DialogModalV2>
  )
}
