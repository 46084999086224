import { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Icon24 } from 'builder/components/Icon'

import { actions as uiActions } from 'builder/modules/ui'
import { selectors, actions } from 'builder/modules/careerProfile'
import { selectors as userSelectors, UserType } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useI18n } from 'builder/hooks/useI18n'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { useMenuItems } from '../../hooks/useMenuItems'
import { isSafari } from '../../utils'

import { MobileDrawerMenu } from './MobileMoreMenu/MobileMoreMenu'
import { Container, CrownIcon, IconWrapper, Menu, MenuItem, Text } from './styles'

export const MobileMenu = () => {
  const { i18n } = useI18n()
  const location = useLocation()
  const dispatch = useDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const { isActiveNestedMenu } = useMenuItems(true)

  const [drawerMenu, setDrawerMenu] = useState(false)
  const isMobileProfileMenuOpen = useTypedSelector(selectors.isMobileProfileMenuOpen)
  const userType = useTypedSelector(userSelectors.postPremium)
  const isPostPremiumActive = userType === UserType.postPremium

  const handleMobileMenu = (state: boolean) => {
    setDrawerMenu(state)
    if (isMobileProfileMenuOpen !== state) {
      dispatch(actions.setMobileProfileMenu(state))
    }
  }

  const handlePostPremium = useCallback(() => {
    if (isPostPremiumActive) dispatch(uiActions.setTrackJobPaywall(true))
  }, [dispatch, isPostPremiumActive])

  useClickOutside(containerRef, () => handleMobileMenu(false))

  const isMenuActive = (to: string) => {
    return location.pathname === to || isActiveNestedMenu(to)
  }

  const menu = useMemo(
    () => [
      {
        to: '/',
        text: i18n.t(`builder.side_menu.mobile.dashboard`),
        icon: <Icon24.Dashboard />,
        activeIcon: <Icon24.DashboardActive />,
      },
      {
        to: '/resumes',
        text: i18n.t(`builder.side_menu.mobile.documents`),
        icon: <Icon24.Documents />,
        activeIcon: <Icon24.DocumentsActive />,
      },
      {
        id: 'job-search-menu-mobile',
        to: '/job-search?view=recommendation',
        text: i18n.t(`builder.side_menu.mobile.jobs`),
        icon: <Icon24.Jobs />,
        activeIcon: <Icon24.JobsActive />,
      },
      {
        id: 'job-tracking-menu-mobile',
        to: '/job-tracking',
        text: i18n.t(`builder.side_menu.mobile.tracker`),
        isPostPremium: isPostPremiumActive,
        action: () => handlePostPremium(),
        icon: <Icon24.Tracker />,
        activeIcon: <Icon24.TrackerActive />,
      },
      {
        id: 'mobile-menu-more',
        to: '#',
        text: i18n.t(`builder.side_menu.mobile.more`),
        icon: <Icon24.MoreMenu />,
        action: () => setDrawerMenu(true),
      },
    ],
    [handlePostPremium, i18n, isPostPremiumActive],
  )

  return (
    <>
      {!drawerMenu && (
        <Container $isSafari={isSafari()} id="bottom-menu-mobile">
          <Menu>
            {menu.map((item, index) => (
              <MenuItem
                id={item.id}
                key={index}
                to={item.isPostPremium ? location : item.to}
                active={isMenuActive(item.to)}
                onClick={item.action}
              >
                {item.isPostPremium && <CrownIcon />}
                <IconWrapper>{isMenuActive(item.to) ? item.activeIcon : item.icon}</IconWrapper>
                <Text active={isMenuActive(item.to)}>{item.text}</Text>
              </MenuItem>
            ))}
          </Menu>
        </Container>
      )}
      <MobileDrawerMenu
        ref={containerRef}
        open={drawerMenu || isMobileProfileMenuOpen}
        setOpen={handleMobileMenu}
      />
    </>
  )
}
