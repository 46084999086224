import { Job } from 'builder/components/FindJob/JobSearchResult/types'
import { getDefaultLogoForCompany } from 'builder/components/FindJob/utils/getDefaultLogoForCompany'
import { useJobTracker } from '../../hooks/useJobTracker'
import Body from './components/Body'
import { Location } from './components/Meta/Meta'
import { MainContent, Wrapper } from './styles'
import TopContent from './components/TopContent'

interface JobCardProps {
  card: Job
  isSliderActive: boolean
  handleClick: (jobTitle?: string) => void
  fromDashboard?: boolean
}

const RecommendedJobCard = ({
  card,
  isSliderActive,
  handleClick,
  fromDashboard = false,
}: JobCardProps) => {
  const { isPresent, handleAddJobBoard } = useJobTracker(card, fromDashboard)

  const prevent = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleAddCard = (event: React.MouseEvent<HTMLDivElement>) => {
    prevent(event)
    handleAddJobBoard()
  }

  return (
    <Wrapper onClick={() => handleClick(card.job_title)} {...{ isSliderActive }}>
      <TopContent
        logo={card.company_logo || getDefaultLogoForCompany()}
        handleAddCard={event => handleAddCard(event)}
        isPresent={isPresent}
      />
      <MainContent>
        <Body company={card.company} title={card.job_title} />
        <Location value={card.location} />
        {/* Will come in next iteration */}
        {/* {jobType && <Type value={jobType} />}
        {salary && <Salary value={salary} />} */}
      </MainContent>
    </Wrapper>
  )
}

export default RecommendedJobCard
