import { DataContainer, Image } from './styles'
import imageUrl from './assets/tailoring.png'

const FeaturingAutoTailorOnDownloadTopView = () => {
  return (
    <DataContainer>
      <Image src={imageUrl} />
    </DataContainer>
  )
}

export default FeaturingAutoTailorOnDownloadTopView
