import { EnumerableResumeSectionType, Optional } from '@rio/types'
import { actions } from 'builder/modules/resumeEditor'
import { store } from 'builder/modules/store'

/** Runs the saga creating a new card inside of the given section */
export const openCard = (
  card: { id: Optional<string | number>; cid?: Optional<string> },
  sectionId: number | EnumerableResumeSectionType,
) => {
  store.dispatch(actions.openCard({ id: card.cid || card.id, sectionId }))
}
