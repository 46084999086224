import { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'
import { UNBLOCKED_EDITOR_URL_PARAM } from 'builder/modules/resumeEditor'
import { SnackBar, SnackBarContext, SnackBarTypes } from '../SnackBarV2'

/**
 * I created this feature as component because in ResumeEditor is not possible to use hooks
 * and not comfortable to operate with context and useSearchParams.
 * Once ResumeEditor is refactored to TS/functional style, logic from this component can be moved
 * to the ResumeEditor component.
 */
export const EditorUnblockedSnackbar = () => {
  const [params, setParams] = useSearchParams()
  const snackbarContext = useContext(SnackBarContext)
  const { i18n } = useI18n()

  useEffect(() => {
    const isEditorUnblocked = params.has(UNBLOCKED_EDITOR_URL_PARAM)

    if (isEditorUnblocked) {
      params.delete(UNBLOCKED_EDITOR_URL_PARAM)
      setParams(params, { replace: true })

      snackbarContext.openSnackBar({
        text: i18n.t('builder.resume_editor.editor_unblocked'),
        type: SnackBarTypes.success,
        isDismissible: true,
      })
    }
  }, [params, setParams, snackbarContext, i18n])

  return <SnackBar />
}
