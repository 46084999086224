import { useEffect, useRef, useState } from 'react'
import { Icon20 } from 'builder/components/Icon'
import { WebCamOff, WebCamOn } from 'builder/components/Icon/24x24'
import { useI18n } from 'builder/hooks/useI18n'
import { MicOff } from 'builder/components/Icon/20x20'
import { useGetStream, UserMediaType } from '../../hooks/useGetStream'
import {
  Button,
  ButtonContainer,
  Container,
  CustomVideo,
  EmptyVideo,
  ReadDot,
  RecContainer,
} from './styles'

interface Props {
  isRecording: boolean
}

export const TRANSLATION_PREFIX = 'builder.interview_prep_view.interview_player_v2.webcam'

export const Webcam = ({ isRecording }: Props) => {
  const audioStream = useGetStream(UserMediaType.audio)
  const audioVideoStream = useGetStream(UserMediaType.video)
  const [enabledMic, setEnabledMic] = useState(true)
  const [enabledVideo, setEnabledVideo] = useState(true)
  const playerRef = useRef<HTMLVideoElement>(null)
  const { i18n } = useI18n()

  const onMicClick = () => {
    setEnabledMic(!enabledMic)
    audioStream.stream?.getAudioTracks().forEach(track => {
      track.enabled = !enabledMic
    })
    audioVideoStream.stream?.getAudioTracks().forEach(track => {
      track.enabled = !enabledMic
    })
  }

  const onVideoClick = () => {
    setEnabledVideo(!enabledVideo)
    audioVideoStream.stream?.getVideoTracks().forEach(track => {
      track.enabled = !enabledVideo
    })
  }

  useEffect(() => {
    if (playerRef.current && audioVideoStream.stream) {
      playerRef.current.srcObject = audioVideoStream.stream
    }
  }, [audioVideoStream.stream])

  return (
    <Container>
      {audioVideoStream.stream?.active ? (
        <CustomVideo ref={playerRef} autoPlay muted playsInline />
      ) : (
        <EmptyVideo />
      )}

      {isRecording && (
        <RecContainer>
          <ReadDot /> {i18n.t(`${TRANSLATION_PREFIX}.rec`)}
        </RecContainer>
      )}
      <ButtonContainer>
        <Button $disabled={!audioStream.stream?.active} onClick={onMicClick}>
          {enabledMic ? <Icon20.MicOn /> : <MicOff />}
        </Button>
        <Button $disabled={!audioVideoStream.stream?.active} onClick={onVideoClick}>
          {enabledVideo ? <WebCamOn /> : <WebCamOff />}
        </Button>
      </ButtonContainer>
    </Container>
  )
}
