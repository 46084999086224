import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Shadows from 'builder/styles/shadows'

export const Wrapper = styled.div<{ isSliderActive?: boolean }>`
  background-color: ${Colors.White};
  cursor: pointer;
  transition: all 0.1s ease;
  border-radius: 16px;
  border: 1px solid #e7eaf4;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 357px;
  width: 268px;
  height: ${({ isSliderActive }) => isSliderActive && '100%'};

  &:hover {
    ${Shadows.lightWithBorder.mid};
  }

  ${Media.Tablet`
    min-height: 288px;
    max-width: 218px;
    padding: 24px;
    height: unset;
  `}

  ${Media.Phone`
    padding: 20px;
  `}

  ${({ isSliderActive }) => {
    return Media.Phone`
    width: ${() => !isSliderActive && '100%'};
    flex-shrink: ${() => !isSliderActive && 0};
    `
  }}
`

export const MainContent = styled.div``
