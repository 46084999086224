import { Fragment, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import queryString from 'query-string'
import throttle from 'lodash/throttle'
import { TunerEditorProvider } from 'builder/components/Tuner'
import { SectionNames, actions, selectors, ResumeEditorStore } from 'builder/modules/resumeEditor'
import PersonalDetailsSection from 'builder/components/PersonalDetailsSection'
import SectionsList from 'builder/components/SectionsList'
import EditorTitle from 'builder/components/EditorTitle'
import LanguageSelect from 'builder/components/LanguageSelect'
import ResumeScore from 'builder/components/ResumeScore'
import Anchor from 'builder/components/Anchor'
import {
  FetchStatuses,
  ResumeUploadStatus,
  ResumeValidationStatus,
} from 'builder/modules/constants'
import CreateResumeWithAI from 'builder/components/FillResumeModal/CreateResumeWithAI'
import { useConfig } from 'builder/hooks/useConfig'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { GeneralEditorStore } from 'builder/modules/generalEditor'
import loaderImage from 'images/builder/resume-editor/editor-loader.svg'
import FillResumeModal, { FillResumeByUrl } from '../FillResumeModal'
import { ATS_TEMPLATES, TemplateName } from '../Helper/constants'
import UseExample from '../FillResumeModal/UseExample'
import JobSearchButton from '../FloatingCIOHelperButton/JobSearchButton/JobSearchButton'
import TailorResumeBanner from '../ImproveResumePanel/components/TailorResumeBanner/TailorResumeBanner'
import { OverviewModal } from '../AutoTailoring/OverviewModal'
import { BeforeAutoTailoringKeywordsModal } from '../AutoTailoring/BeforeAutoTailoring'
import { AutoTailoringModal } from '../AutoTailoring/AutoTailoringModal'
import { PreventOriginalResumeEditModal } from '../AutoTailoring/PreventOriginalResumeEditModal'
import { BuilderType } from './ResumeSideMenu/types'
import { ElementHighlight } from './ElementHighlight'
import { ResumeSideMenu } from './ResumeSideMenu/ResumeSideMenu'
import ATSPersonalDetailsSection from './ATSResumeEditor/PersonalDetailsSection'
import {
  EditorPanel,
  EditorScroll,
  EditorContent,
  EditorSubtitle,
  LoaderContainer,
  Loader,
  UserNavigation,
  JobSearch,
  JobSearchContainer,
  VerticalDivider,
  EditorTitleWrapperForFoldableSections,
  ResumeScoreWrapperForFoldableSections,
  OptimizerBannerWrapperForFoldableSections,
} from './styles'
import { EditorUnblockedSnackbar } from './EditorUnblockedSnackbar'

type Props = {
  resumeState: ResumeEditorStore
  editorState: GeneralEditorStore
}

export const ResumeEditor = (props: Props) => {
  const { resumeState, editorState } = props
  const dispatch = useDispatch()
  const config = useConfig()
  const { i18n } = useI18n()
  const useExample = useTypedSelector(state => state.resumeEditor.useExample)
  const isPremiumUser = useTypedSelector(state => state.user.data?.hasPremiumFeatures ?? false)
  const autoTailoringOpenedModal = useTypedSelector(selectors.autoTailoringOpenedModal)
  const aiResume = useTypedSelector(state => state.resumeEditor.aiResume)
  const showPrefillResumeModalProp = useTypedSelector(
    state => state.resumeEditor.resume?.showPrefillResumeModal,
  )
  const optimizerSection = useTypedSelector(state => state.resumeEditor.optimizerSection)
  const isLinkedToJobPosting = !!optimizerSection.jobPostingAPIData.jobPostingId
  const isJobPostingDataFetched =
    optimizerSection.fetchJobPostingDataStatus === FetchStatuses.loaded
  const isOptimizerModalOpen = useTypedSelector(state => state.resumeEditor.isOptimizerModalOpen)
  const isOnline = useTypedSelector(state => state.application.isOnline)
  const isPageScrolled = useTypedSelector(selectors.isPageScrolled)

  const {
    resume,
    improveResumePanel: { isOpen: isImproveResumePanelOpen },
  } = resumeState
  const { resumeTemplates, levels, locales } = editorState
  const { showAIResumeModal } = aiResume
  const { showUseExampleModal } = useExample
  const isResumeOptimizerFeatureOn = config?.features.resumeOptimizer
  const isPrefillResumeFeatureOn = config?.features.newStartScreen !== 'no_screen'
  const {
    aiResumeDraft,
    startWithExamples,
    uploadResume,
    superApp,
    international,
    dashboardV3,
    dashboardV2,
    roBannerTrigger,
    foldSections,
  } = config?.features || {}

  const isDashboardV3 = superApp || international || (dashboardV3 && !dashboardV2)
  const hostname = window.location.hostname

  const isCareerIo = hostname === 'career.io' || hostname === 'staging.career.io'
  const isOptimizerBannerTriggerEnabled = !!roBannerTrigger && roBannerTrigger !== 'control'
  const isFoldableSectionsEnabled = !!foldSections

  const showResumeOptimizerBanner =
    isResumeOptimizerFeatureOn &&
    isJobPostingDataFetched &&
    !isLinkedToJobPosting &&
    !isImproveResumePanelOpen &&
    !isOptimizerBannerTriggerEnabled

  const showPrefillResumeModal =
    !isOptimizerModalOpen &&
    showPrefillResumeModalProp &&
    (aiResumeDraft || startWithExamples || uploadResume) &&
    isPrefillResumeFeatureOn

  useEffectOnMount(() => {
    const urlParams = queryString.parse(window.location.search)
    const preserveAIResumeDraft = urlParams && urlParams.showAIResumeDraft
    if (!preserveAIResumeDraft) dispatch(actions.resetAIResumeData())
  })

  const handleSimpleFieldUpdate = useCallback(
    (name: any, value: any, debounce?: boolean) => {
      dispatch(actions.updateSimpleField({ name, value, debounce }))

      switch (name) {
        case 'name':
          trackInternalEvent('rename_resume')
          break
        case 'color':
          trackInternalEvent('change_resume_color')
          break
        case 'locale':
          trackInternalEvent('change_resume_locale', { locale: value })
          break
        default:
          break
      }
    },
    [dispatch],
  )

  const handleResumeNameChange = useCallback(
    (value: string) => {
      dispatch(actions.updateSimpleField({ name: 'name', value, debounce: true }))
    },
    [dispatch],
  )

  const handleLocaleSelect = useCallback(
    (value: string) => {
      dispatch(actions.updateSimpleField({ name: 'locale', value, debounce: true }))
    },
    [dispatch],
  )

  const handlePrefillModalClose = useCallback(() => {
    dispatch(actions.setResumeValidationStatus(ResumeValidationStatus.notStarted))
    dispatch(actions.setResumeUploadStatus(ResumeUploadStatus.notStarted))
    dispatch(
      actions.updateSimpleField({
        name: 'showPrefillResumeModal',
        value: false,
        debounce: false,
      }),
    )
  }, [dispatch])

  const updateCard = useCallback(
    (sectionId, cardId, values, debounce, isCustom) =>
      dispatch(actions.updateCard({ sectionId, cardId, values, debounce, isCustom })),
    [dispatch],
  )

  const addCard = useCallback(
    (cardId, sectionName, options) => dispatch(actions.addCard({ sectionName, cardId, options })),
    [dispatch],
  )

  const deleteCard = useCallback(
    (cardId, sectionName, isCustom = false) =>
      dispatch(actions.deleteCard({ sectionName, cardId, isCustom })),
    [dispatch],
  )

  const addCustomSection = useCallback(
    (externalId, options) => dispatch(actions.addCustomSection({ externalId, options })),
    [dispatch],
  )

  const moveCard = useCallback(payload => dispatch(actions.moveCard(payload)), [dispatch])

  const moveSection = useCallback(payload => dispatch(actions.moveSection(payload)), [dispatch])

  const deleteSection = useCallback(
    (id, options) => dispatch(actions.deleteSection({ id, options })),
    [dispatch],
  )

  const renameSection = useCallback(
    payload => dispatch(actions.renameSection({ ...payload, debounce: true })),
    [dispatch],
  )

  useEffect(() => {
    /** If the user has selected Tailor to Job Posting flow from dashboard
     * and if the prefill modal has been set to open, call the handler function
     */
    if (isOptimizerModalOpen && resume?.showPrefillResumeModal) {
      handlePrefillModalClose()
    }
  }, [isOptimizerModalOpen, resume?.showPrefillResumeModal, handlePrefillModalClose])

  useEffect(() => {
    const handleScroll = throttle(() => {
      dispatch(actions.setIsPageScrolled(window.scrollY > 100))
    }, 100)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [dispatch])

  if (!resume || resumeTemplates.length === 0) {
    return (
      <EditorPanel>
        <LoaderContainer>
          <Loader src={loaderImage} />
        </LoaderContainer>
      </EditorPanel>
    )
  }

  const isATSTemplate = ATS_TEMPLATES.includes(resume.template as TemplateName)

  const TitleWrapper = isFoldableSectionsEnabled ? EditorTitleWrapperForFoldableSections : Fragment

  const ResumeScoreWrapper = isFoldableSectionsEnabled
    ? ResumeScoreWrapperForFoldableSections
    : Fragment

  const OptimizerBannerWrapper = isFoldableSectionsEnabled
    ? OptimizerBannerWrapperForFoldableSections
    : Fragment

  const personalDetailsProps = { resume, isFoldableSectionsEnabled }

  return resume.autoTailored && resume.draft ? (
    <OverviewModal />
  ) : (
    <>
      {isDashboardV3 && (
        <ResumeSideMenu isOnline={isOnline} builderType={BuilderType.resumeBuilder} />
      )}
      <JobSearchContainer>
        <JobSearch hideDivider showBubbles id="job-search-solution" />
        <VerticalDivider />
      </JobSearchContainer>
      <EditorPanel data-testid="editor-panel" {...{ isFoldableSectionsEnabled }}>
        {!isDashboardV3 && isOnline && <UserNavigation hasFeatures />}
        <EditorScroll>
          <EditorContent {...{ isFoldableSectionsEnabled }}>
            <TitleWrapper>
              <EditorTitle
                onChange={handleResumeNameChange}
                value={resume.name ? resume.name : i18n.t('builder.resume_editor.untitled')}
                {...{ isFoldableSectionsEnabled }}
              />

              <EditorSubtitle>
                <LanguageSelect
                  selected={resume.locale}
                  onSelect={handleLocaleSelect}
                  options={locales}
                />
              </EditorSubtitle>
            </TitleWrapper>

            <ResumeScoreWrapper
              $showResumeOptimizerBanner={showResumeOptimizerBanner}
              $isPageScrolled={isPageScrolled}
            >
              <ResumeScore />
            </ResumeScoreWrapper>

            {showResumeOptimizerBanner && (
              <OptimizerBannerWrapper>
                <TailorResumeBanner
                  {...(isFoldableSectionsEnabled ? { transitionProps: { marginTop: 0 } } : {})}
                />
              </OptimizerBannerWrapper>
            )}

            <TunerEditorProvider resume={resume}>
              <Anchor id={SectionNames.details} scrollOffsetTop={70}>
                {isATSTemplate ? (
                  <ATSPersonalDetailsSection {...personalDetailsProps} />
                ) : (
                  <PersonalDetailsSection {...personalDetailsProps} />
                )}
              </Anchor>

              <SectionsList
                resume={resume}
                updateCard={updateCard}
                addCard={addCard}
                addCustomSection={addCustomSection}
                deleteCard={deleteCard}
                moveCard={moveCard}
                moveSection={moveSection}
                levels={levels}
                updateSimpleField={handleSimpleFieldUpdate}
                renameSection={renameSection}
                deleteSection={deleteSection}
                isFoldableSectionsEnabled={isFoldableSectionsEnabled}
              />
            </TunerEditorProvider>
          </EditorContent>
        </EditorScroll>
        {showPrefillResumeModal && <FillResumeModal onClose={handlePrefillModalClose} />}
        {showAIResumeModal && <CreateResumeWithAI />}
        {showUseExampleModal && <UseExample />}
        {isPremiumUser && isCareerIo && <JobSearchButton resumeEditor />}
        {autoTailoringOpenedModal === 'keywords_selection' && <BeforeAutoTailoringKeywordsModal />}
        {autoTailoringOpenedModal === 'auto_tailoring' && <AutoTailoringModal />}
        <PreventOriginalResumeEditModal />
        <ElementHighlight />
        <EditorUnblockedSnackbar />
        <FillResumeByUrl />
      </EditorPanel>
    </>
  )
}
