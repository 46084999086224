import styled, { FlattenSimpleInterpolation } from 'styled-components'
import Colors from 'builder/styles/colors'
import { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Text = styled.div<{ $size: FlattenSimpleInterpolation }>`
  ${FontWeights.Medium};
  ${({ $size }) => $size}
  color: ${Colors.Neutral90};
  text-align: center;
  max-width: 580px;

  ${Media.Tablet`
    max-width: 461px;
 `}

  ${Media.Phone`
    max-width: 363px;
  `}
`
