import React from 'react'
import { CardType } from '../index'

import LEVEL_ICON_URL from '../assets/card-level.svg'
import MESSAGE_ICON_URL from '../assets/card-message.svg'
import TIME_ICON_URL from '../assets/card-time.svg'

import { InfoContainer, InfoContainerFull, InfoItem, InfoItemLoading } from './styles'

export const CardInfo: React.FC<{ type: CardType }> = ({ type }) => {
  const isPlaceholderVisible = ['empty', 'loading', 'error'].includes(type)

  if (isPlaceholderVisible) {
    return (
      <InfoContainerFull>
        <InfoItemLoading animated={type === 'loading'} />
        <InfoItemLoading animated={type === 'loading'} />
        <InfoItemLoading animated={type === 'loading'} />
      </InfoContainerFull>
    )
  }

  return (
    <InfoContainer>
      <InfoItem>
        <img src={LEVEL_ICON_URL} />
        <>Easy</>
      </InfoItem>

      <InfoItem>
        <img src={TIME_ICON_URL} />
        <>45 Min</>
      </InfoItem>

      <InfoItem>
        <img src={MESSAGE_ICON_URL} />
        <>9 Questions</>
      </InfoItem>
    </InfoContainer>
  )
}
