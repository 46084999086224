import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useAutoApply } from '../../views/AutoApply/hooks/useAutoApply'
import {
  CTAContainer,
  CTAPrimary,
  CTAPrimaryContainer,
  Close,
  CloseButtonContainer,
  CloseIcon,
  GetStartedDescription,
  GetStartedFeature,
  GetStartedFeatureTitle,
  GetStartedFeatures,
  GetStartedFeaturesContainer,
  GetStartedHeaderContainer,
  GetStartedTextContainer,
  GetStartedTitle,
  ModalSection,
  Overlay,
  RootModalContainer,
  GetStartedSubTitle,
  Image,
} from './styles'
import { useResumeDistributionGetStartedModal } from './useResumeDistributionGetStartedModal'
import IMAGE from './assets/AutoJobApplyBanner.png'

const TRANSALTION = 'builder.resume_distribution.get_started_modal'

export const ResumeDistributionGetStartedModal = () => {
  const { i18n } = useI18n()
  const { getAutoApplyCredit } = useAutoApply()
  const { isOpen, handleModalClose, redirectToAutoApply } = useResumeDistributionGetStartedModal()
  const { creditCount } = getAutoApplyCredit()

  useEffect(() => {
    if (isOpen && creditCount > 0) {
      trackInternalEvent('show_promo_modal', { type: 'total_job_solution' })
    }
  }, [isOpen, creditCount])

  if (!isOpen || creditCount === 0) return null

  return (
    <Overlay>
      <RootModalContainer>
        <CloseButtonContainer>
          <Close onClick={handleModalClose}>
            <CloseIcon />
          </Close>
        </CloseButtonContainer>
        <div>
          <Image src={IMAGE} />
        </div>
        <ModalSection>
          <GetStartedTextContainer>
            <GetStartedHeaderContainer>
              <GetStartedTitle>
                {i18n.t(`${TRANSALTION}.title`)}
                <GetStartedSubTitle>
                  {i18n.t(`${TRANSALTION}.sub_title`, { credits: creditCount })}
                </GetStartedSubTitle>
              </GetStartedTitle>
              <GetStartedDescription>{i18n.t(`${TRANSALTION}.description`)}</GetStartedDescription>
            </GetStartedHeaderContainer>
            <GetStartedFeaturesContainer>
              <GetStartedFeatureTitle>
                {i18n.t(`${TRANSALTION}.features_title`)}
              </GetStartedFeatureTitle>

              <GetStartedFeatures>
                <GetStartedFeature>{i18n.t(`${TRANSALTION}.features.one`)}</GetStartedFeature>
                <GetStartedFeature>{i18n.t(`${TRANSALTION}.features.two`)}</GetStartedFeature>
                <GetStartedFeature>{i18n.t(`${TRANSALTION}.features.three`)}</GetStartedFeature>
              </GetStartedFeatures>
            </GetStartedFeaturesContainer>
          </GetStartedTextContainer>
          <CTAContainer>
            <CTAPrimaryContainer>
              <CTAPrimary onClick={redirectToAutoApply}>
                {i18n.t(`${TRANSALTION}.get_started_cta`)}
              </CTAPrimary>
            </CTAPrimaryContainer>
          </CTAContainer>
        </ModalSection>
      </RootModalContainer>
    </Overlay>
  )
}
