import { memo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useI18n } from 'builder/hooks/useI18n'
import Colors from 'builder/styles/colors'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import {
  AIResumeDraftEvents,
  PrefillResumeEvents,
  UseExampleEvents,
} from 'builder/modules/constants'
import CreateResumeImg from 'images/builder/fill-resume/layout-two/create-resume.png'
import useExampleImg from 'images/builder/fill-resume/use-example.svg'
import uploadResumeImg from 'images/builder/fill-resume/layout-two/upload-resume.png'
import ModalOverlay from '../ModalOverlay'
import Icon24, { Icon20 } from '../Icon'
import Tooltip from '../Tooltip'
import {
  AIPoweredBadge,
  AIStarImg,
  Card,
  CardContainer,
  CardDescription,
  CardSubtitle,
  CardTitle,
  CardTitleContainer,
  CreateNewResumeCard,
  CreateResumeImage,
  CreateWithAIArrowIcon,
  CreateWithAICard,
  CreateWithAICardTitle,
  CreateWithAIImage,
  IconWrapper,
  ImageWrapper,
  UploadResumeImage,
  UseExampleCard,
  UseExampleImg,
  UseExampleImgWrapper,
} from './OptionsViewOld/OptionCards/styles'
import aiImg from './OptionsViewOld/OptionCards/aiImg.svg'
import starImg from './OptionsViewOld/OptionCards/starImg.svg'
import { DesktopClose as Close, OptionsContainer, SubTitle, Title } from './styles'
import { FillByUrlParamValues } from './constants'

type Props = {
  onClose: () => void
  onDisabledItemClick: (type: FillByUrlParamValues) => void
}

/**
 * Version of FillResumeModal for unauthorized builder experiment
 */

export const FillResumeModalGuest = memo((props: Props) => {
  const { onClose, onDisabledItemClick } = props
  const { isPhone } = useMediaQueries()
  const { i18n } = useI18n()

  const title = i18n.t('builder.prefill_resume.start_title')

  const arrowStyles = {
    fill: Colors.Neutral40,
  }

  useEffectOnMount(() => {
    trackInternalEvent(PrefillResumeEvents.seeStartScreen)
  })

  const handleCreteNewResume = () => {
    trackInternalEvent(PrefillResumeEvents.clickCreateResume)
    onClose()
  }

  const handleDisabledItemClick = (type: FillByUrlParamValues) => {
    onDisabledItemClick(type)
  }

  const renderCreateNewResume = () => {
    const title = i18n.t('builder.prefill_resume.create_resume')
    const description = i18n.t('builder.prefill_resume.choose_blank_template')

    return (
      <CreateNewResumeCard onClick={handleCreteNewResume}>
        <ImageWrapper>
          <CreateResumeImage src={CreateResumeImg} alt="Create New Resume" />
        </ImageWrapper>
        <CardDescription>
          <CardTitle>{title}</CardTitle>
          <CardSubtitle>{description}</CardSubtitle>
        </CardDescription>
        <IconWrapper>
          <Icon24.ToggleArrow style={arrowStyles} />
        </IconWrapper>
      </CreateNewResumeCard>
    )
  }

  const renderCreateWithAI = () => {
    const title = i18n.t('builder.ai_resume_draft.create_with_ai')
    const description = i18n.t('builder.ai_resume_draft.description_non_linkedin')

    return (
      <CreateWithAICard
        onClick={() => {
          trackInternalEvent(AIResumeDraftEvents.clickCreateWithAI)
          handleDisabledItemClick(FillByUrlParamValues.createWithAi)
        }}
      >
        <ImageWrapper>
          <CreateWithAIImage src={aiImg} alt="Create with AI" />
        </ImageWrapper>
        <CardDescription>
          <CardTitleContainer>
            <CreateWithAICardTitle>{title}</CreateWithAICardTitle>
            <Tooltip value={i18n.t('builder.ai_resume_draft.ai_powered')}>
              <AIPoweredBadge>
                <AIStarImg src={starImg} alt="AI star" />
              </AIPoweredBadge>
            </Tooltip>
          </CardTitleContainer>
          <CardSubtitle>{description}</CardSubtitle>
        </CardDescription>
        <CreateWithAIArrowIcon>
          <Icon24.ToggleArrow style={arrowStyles} />
        </CreateWithAIArrowIcon>
      </CreateWithAICard>
    )
  }

  const renderUseExample = () => {
    const title = i18n.t('builder.use_example.option.heading')
    const description = i18n.t('builder.use_example.option.description')

    return (
      <UseExampleCard
        onClick={() => {
          trackInternalEvent(UseExampleEvents.clickUseAnExampleStartScreen)
          handleDisabledItemClick(FillByUrlParamValues.useExample)
        }}
      >
        <UseExampleImgWrapper>
          <UseExampleImg src={useExampleImg} alt="Use resume example" />
        </UseExampleImgWrapper>
        <CardDescription>
          <CardTitle>{title}</CardTitle>
          <CardSubtitle>{description}</CardSubtitle>
        </CardDescription>
        <IconWrapper>
          <Icon24.ToggleArrow style={arrowStyles} />
        </IconWrapper>
      </UseExampleCard>
    )
  }

  const renderUploadResume = () => {
    const title = i18n.t('builder.prefill_resume.upload_resume')
    const description = i18n.t('builder.prefill_resume.upload_resume_instructions')

    return (
      <Card
        onClick={() => {
          trackInternalEvent(PrefillResumeEvents.clickUploadResume)
          handleDisabledItemClick(FillByUrlParamValues.uploadResume)
        }}
      >
        <ImageWrapper>
          <UploadResumeImage src={uploadResumeImg} alt="Upload Resume" />
        </ImageWrapper>
        <CardDescription>
          <CardTitle>{title}</CardTitle>
          <CardSubtitle>{description}</CardSubtitle>
        </CardDescription>
        <IconWrapper>
          <Icon24.ToggleArrow style={arrowStyles} />
        </IconWrapper>
      </Card>
    )
  }

  return (
    <ModalOverlay
      onClick={onClose}
      overlayFadeDuration={150}
      contentSlideDuration={150}
      fullScreen={isPhone}
    >
      <OptionsContainer>
        <Close
          onClick={() => {
            trackInternalEvent(PrefillResumeEvents.clickCrossButtonStartScreen)
            onClose()
          }}
        >
          <Icon20.Close />
        </Close>
        <Title>{title}</Title>
        <SubTitle>{i18n.t('builder.prefill_resume.start_subtitle')}</SubTitle>
        <CardContainer>
          {renderCreateNewResume()}
          {renderCreateWithAI()}
          {renderUseExample()}
          {renderUploadResume()}
        </CardContainer>
      </OptionsContainer>
    </ModalOverlay>
  )
})
