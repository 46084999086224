import styled, { css } from 'styled-components'
import { animated } from '@react-spring/web'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Icon24 } from 'builder/components/Icon'

type Props = {
  $wrapOnMobile: boolean
}

export const BannerContainer = styled(animated.div)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const Banner = styled.div`
  width: 100%;
  height: 100%;
  background: ${Colors.Indigo10};
  display: flex;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  padding: 8px 16px 8px 8px;

  &:hover {
    background: ${Colors.Indigo110};
  }

  ${Media.Phone`
      padding: 10px 8px;
  `}
`

export const MainBannerContent = styled.div<Props>(props => {
  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 6px;
    justify-content: space-between;
    flex: auto;

    ${Media.Phone`
      ${
        props.$wrapOnMobile &&
        css`
          flex-wrap: wrap;
        `
      }
    `}
  `
})

export const ArrowIcon = styled(Icon24.ChevronRight)`
  fill: ${Colors.Indigo60};
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;

  ${Banner}:hover & {
    fill: ${Colors.Indigo70};
  }
`

export const BannerImage = styled.img`
  flex: 0 0 66px;
  width: 66px;
  margin: 7px 9px 3px 6px;

  ${Media.Phone`
    flex: 0 0 60px;
    width: 60px;
    margin: unset;
  `}
`

export const BannerTitle = styled.p`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Indigo90};

  ${Banner}:hover & {
    color: ${Colors.Indigo70};
  }
`
export const ActionWrapper = styled.div<{ $isGuest?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  ${props =>
    props.$isGuest &&
    css`
      gap: 0;
      margin-left: auto;
    `}

  ${Media.Phone`
    margin-right: 0;
  `}
`

export const ActionText = styled.p`
  font-size: 16px;
  ${FontWeights.Regular};
  color: ${Colors.Indigo60};
  white-space: nowrap;
  display: flex;
  align-items: center;

  ${Banner}:hover & {
    color: ${Colors.Indigo70};
  }
`

export const NotNowButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  font-size: 16px;
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  cursor: pointer;
  transition: color 0.2s ease-out;
  white-space: nowrap;

  &:hover {
    color: ${Colors.Neutral70};
  }
`
