import { UseFormReturn } from 'react-hook-form'
import countriesArray from 'builderStatic/js/components/config/countriesArray'
import { useI18n } from 'builder/hooks/useI18n'
import { AutoApplyForm, ExpectedStartWork } from 'builder/modules/autoApply/types'

import Select from 'builder/components/Select'
import Toggle from 'builder/components/Toggle'
import * as SimpleForm from 'builder/components/SimpleForm'
import { Divider } from 'builder/components/DocumentSelect/styles'
import { ClusterPicker, SelectItem } from 'builder/components/ClusterPicker/ClusterPicker'
import { createNewLocationSuggestionsApiFetcher } from 'builder/components/FindJob/utils/createNewSuggestionsApiFetcher'

const MAX_LOCATIONS = 5
const countries = countriesArray
  .sort((a, b) => (b.priority || 0) - (a.priority || 0))
  .map(c => ({ value: c.id, label: c.name }))

type Props = {
  form: UseFormReturn<
    Pick<
      AutoApplyForm,
      'countriesAuthorizedToWorkIn' | 'targetLocations' | 'jobPreferenceAttributes'
    >
  >
}

export const JobPreferencesForm2 = (props: Props) => {
  const { form } = props
  const { formState, setError, clearErrors, register, watch, setValue } = form
  const { i18n } = useI18n()
  const TRANSLATION = `builder.auto_apply.form.job_preferences_2`

  const START_DATE_OPTIONS = [
    { id: 'immediate', name: i18n.t(`${TRANSLATION}.start_date.options.immediate`) },
    { id: 'one_month', name: i18n.t(`${TRANSLATION}.start_date.options.one_month`) },
    { id: 'three_months', name: i18n.t(`${TRANSLATION}.start_date.options.three_months`) },
    { id: 'six_months', name: i18n.t(`${TRANSLATION}.start_date.options.six_months`) },
  ]

  const authorizedCountries = watch('countriesAuthorizedToWorkIn') || []
  const targetLocations = watch('targetLocations') || []
  const maxLocations = i18n.t(`${TRANSLATION}.location.max_error`)

  const setLocationError = (hasError: boolean) => {
    if (hasError) {
      setError('targetLocations', { message: maxLocations })
    } else {
      clearErrors('targetLocations')
    }
  }

  const parseLocation = (locations: AutoApplyForm['targetLocations']) => {
    return locations.map(location => ({
      value: location.id ? location.id.toString() : '',
      label: location.formattedName || '',
    }))
  }

  const onSelectLocation = (location: SelectItem) => {
    const parsedLocation = { id: parseInt(location.value), formattedName: location.label }
    const locations = [parsedLocation, ...targetLocations]
    const hasError = locations.length > MAX_LOCATIONS

    setValue('targetLocations', locations)
    setLocationError(hasError)
  }

  const onUnselectLocation = (locations: SelectItem[]) => {
    const hasError = locations.length > MAX_LOCATIONS
    const parsedLocation = locations.map(location => ({
      id: parseInt(location.value),
      formattedName: location.label,
    }))

    setValue('targetLocations', parsedLocation)
    setLocationError(hasError)
  }

  const searchLocation = async (val: string) => {
    const locations = await createNewLocationSuggestionsApiFetcher(val)
    return locations.map(location => ({
      value: location.value || '',
      label: location.formatted || '',
    }))
  }

  const getUnselectedCountries = () => {
    return countries.filter(x => !authorizedCountries.map(item => item.value).includes(x.value))
  }

  const searchCountries = async (val: string) => {
    return getUnselectedCountries().filter(item =>
      item.label.toLowerCase().includes(val.toLowerCase()),
    )
  }

  return (
    <>
      <SimpleForm.Row position="relative">
        <ClusterPicker
          {...register('targetLocations', {
            required: i18n.t(`${TRANSLATION}.location.error`),
            validate: val => (val.length > MAX_LOCATIONS ? maxLocations : true),
          })}
          showLimit={5}
          showIndicators={{ dropdown: true }}
          selectedList={parseLocation(targetLocations)}
          onSelect={onSelectLocation}
          loadOptions={searchLocation}
          onUnselect={onUnselectLocation}
          label={i18n.t(`${TRANSLATION}.location.label`)}
          hint={i18n.t(`${TRANSLATION}.location.label2`)}
          placeholder={i18n.t(`${TRANSLATION}.location.placeholder`)}
          // @ts-expect-error custom message error
          error={formState.errors.targetLocations?.message}
        />
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <Toggle
          {...register('jobPreferenceAttributes.willingToRelocate')}
          checked={watch('jobPreferenceAttributes.willingToRelocate')}
          onChange={e =>
            setValue(
              'jobPreferenceAttributes.willingToRelocate',
              (e.target as HTMLInputElement).checked,
            )
          }
          label={i18n.t(`${TRANSLATION}.relocation`)}
        />
      </SimpleForm.Row>

      {watch('jobPreferenceAttributes.willingToRelocate') && (
        <SimpleForm.Row position="relative">
          <Toggle
            {...register('jobPreferenceAttributes.willingToRelocateAtYourOwnCost')}
            checked={watch('jobPreferenceAttributes.willingToRelocateAtYourOwnCost')}
            onChange={e =>
              setValue(
                'jobPreferenceAttributes.willingToRelocateAtYourOwnCost',
                (e.target as HTMLInputElement).checked,
              )
            }
            label={i18n.t(`${TRANSLATION}.relocation_expenses`)}
          />
        </SimpleForm.Row>
      )}

      <SimpleForm.Row position="relative">
        <ClusterPicker
          list={getUnselectedCountries()}
          selectedList={authorizedCountries}
          onSelect={country =>
            setValue('countriesAuthorizedToWorkIn', [country, ...authorizedCountries])
          }
          loadOptions={searchCountries}
          onUnselect={newVal => setValue('countriesAuthorizedToWorkIn', newVal)}
          label={i18n.t(`${TRANSLATION}.countries_permission.label`)}
          showLimit={7}
          placeholder={i18n.t(`${TRANSLATION}.countries_permission.placeholder`)}
        />
      </SimpleForm.Row>

      <SimpleForm.Row position="relative">
        <Divider />
      </SimpleForm.Row>

      <SimpleForm.Row position="relative">
        <Select
          {...register('jobPreferenceAttributes.expectedStartWork')}
          label={i18n.t(`${TRANSLATION}.start_date.label`)}
          selected={watch('jobPreferenceAttributes.expectedStartWork')}
          onSelect={val =>
            setValue('jobPreferenceAttributes.expectedStartWork', val as ExpectedStartWork)
          }
          {...{ options: START_DATE_OPTIONS }}
        />
      </SimpleForm.Row>
    </>
  )
}
