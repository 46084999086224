import { useState, useRef, useEffect, useCallback } from 'react'
import { InterviewQuestion } from 'builder/modules/interview/types'
import ErrorLogger from 'builder/services/ErrorLogger'
import { AudioPlayerUtils } from 'builder/views/Interview/utils/AudioPlayerUtils'
import { baseClient } from 'builder/modules/apiClient'
import { requestPolling } from 'builder/views/Interview/utils/requestPolling'

const requestQuestion = async (
  interviewId: number,
  questionId: number,
  abortControl: AbortController,
) => {
  const response = await baseClient.get<InterviewQuestion>(
    // eslint-disable-next-line max-len
    `/interview_prep/interviews/${interviewId}/questions/${questionId}`,
    { signal: abortControl.signal },
  )
  return response.data
}

const requestDownloadBlob = async (url: string) => {
  const response = await fetch(url || '')
  const blob = await response.blob()
  return blob
}

type AudioAsset = { blob: Blob; questionId: number }

export function useAnnouncementUtils() {
  const audioUtilsRef = useRef(new AudioPlayerUtils())

  const [speaking, setSpeaking] = useState(false)

  const questionAnnouncements = useRef<Record<number, Blob>>({})

  const welcomeAnnouncement = useRef<Blob>()

  const stopAnnouncement = useCallback(() => audioUtilsRef.current?.stopSound(), [])

  useEffect(() => {
    let audioInstance = audioUtilsRef.current
    return () => audioInstance?.stopSound()
  }, [])

  const downloadQuestionAnnouncement = useCallback(
    async (interviewId: number, questionId: number) => {
      const question = await requestPolling({
        timeout: 16000,
        requestFunction: async (abortControl: AbortController) => {
          const response = await requestQuestion(interviewId, questionId, abortControl)

          return {
            polling: !response?.announcement,
            data: response,
          }
        },
      })

      if (question?.announcement) {
        const blob = await requestDownloadBlob(question?.announcement)
        return {
          questionId,
          blob,
        }
      }

      throw new Error(
        `There is no announcement for the interviewId:${interviewId} question:${questionId} `,
      )
    },
    [],
  )

  const saveQuestionAnnouncement = useCallback(async (asset: AudioAsset) => {
    questionAnnouncements.current[asset.questionId] = asset.blob
  }, [])

  const downloadRestQuestionAnnouncementAssets = useCallback(
    async (interviewId: number, questionIds: number[]) => {
      const missingQuestionAnnouncementsIds = questionIds.filter(
        questionId => !questionAnnouncements.current[questionId],
      )

      const downloadPromises = []
      for (let i = 0; i < missingQuestionAnnouncementsIds.length; i++) {
        const questionId = missingQuestionAnnouncementsIds[i]
        downloadPromises.push(downloadQuestionAnnouncement(interviewId, questionId))
      }

      const assetPromises = await Promise.allSettled(downloadPromises)

      for (let i = 0; i < assetPromises.length; i++) {
        const assetPromise = assetPromises[i]
        if (assetPromise.status === 'fulfilled') {
          const asset = assetPromise.value
          saveQuestionAnnouncement(asset)
        }
      }
    },
    [],
  )

  const playAnnouncement = async (blob: Blob) => {
    try {
      if (!blob) {
        return
      }
      setSpeaking(true)
      await audioUtilsRef.current.playAudioFromBlob(blob)
    } catch (error) {
      ErrorLogger.log(error)
    }

    setSpeaking(false)
  }

  const playQuestionAnnouncement = useCallback(async (id?: number) => {
    if (id === undefined) {
      return
    }
    const announcement = questionAnnouncements.current[id]
    if (!announcement) {
      return
    }

    return await playAnnouncement(announcement)
  }, [])

  const downloadWelcomeAnnouncement = useCallback(async (url: string) => {
    const blob = await requestDownloadBlob(url)

    return blob
  }, [])

  const saveWelcomeAnnouncement = useCallback(async (blob: Blob) => {
    welcomeAnnouncement.current = blob
  }, [])

  const playWelcomeAnnouncement = useCallback(async () => {
    if (welcomeAnnouncement.current) {
      await playAnnouncement(welcomeAnnouncement.current)
    }
  }, [])

  return {
    welcomeAnnouncement: welcomeAnnouncement.current,
    questionAnnouncements: questionAnnouncements.current,
    playQuestionAnnouncement,
    speaking,
    stopAnnouncement,
    downloadRestQuestionAnnouncementAssets,
    downloadQuestionAnnouncement,
    saveQuestionAnnouncement,
    downloadWelcomeAnnouncement,
    saveWelcomeAnnouncement,
    playWelcomeAnnouncement,
  }
}
