import React, { Context, createContext, useContext, useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import axios, { AxiosError } from 'axios'
import { throttle } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions } from 'builder/modules/jobTracking'
import { Options, useQueryParam } from 'builder/hooks/useQueryParam'
import {
  actions as dashboardActions,
  selectors as dashboardSelectors,
} from 'builder/modules/dashboard'
import {
  actions as careerProfileActions,
  selectors as careerProfileSelectors,
} from 'builder/modules/careerProfile'

import { actions as jobSearchActions } from 'builder/modules/jobSearch'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { AlertTypes } from 'builder/modules/jobSearch/types'
import { Job, ResultStats, SearchQuery } from './JobSearchResult/types'
import {
  PARAM_EXTERNAL_SLUG_ID,
  PARAM_ONLY_AUTO_APPLY_JOBS,
  PARAM_ONLY_REMOTE_JOBS,
  PARAM_OPEN_JOB_ALERT,
  PARAM_PAGE,
  PARAM_QUERY,
  PARAM_RADIUS,
  PARAM_TERM,
  PARAM_TIME,
  PARAM_VIEW,
} from './SearchBar/constants'
import { Filters, RecommendationsRequiredAttributesType, TabType } from './types'
import {
  fetchRecommendations,
  performApiSearch,
  performSimilarJobsApi,
  getLocationFromServer as _getLocationFromServer,
} from './utils'

import { SEARCH_BAR_HEIGHT, SearchBarType } from './SearchBar/SearchBar'
import { SearchSuggestionType } from './AutoSuggestField/types'
import { handleLocationParameterEvent } from './SearchBar/utils'
import { DEFAULT_FILTERS } from './JobFilters/constants'

export enum SearchStatus {
  default = 'default',
  searching = 'searching',
  loaded = 'loaded',
  loadingPage = 'loadingPage',
}

export enum RecommendationsSearchStatus {
  loaded = 'loaded',
  searching = 'searching',
  loadingPage = 'loadingPage',
}

export interface UpdateParamsType {
  term?: string
  time?: string
  location?: SearchSuggestionType
  page?: string
  correct_term?: boolean
  ignoreSelectedTabId?: boolean
}

type JobSearchContextType = {
  searchStatus: SearchStatus
  activeJob: Job | null
  recommendActiveJob: Job | null
  jobSearchResults: Job[]
  similarResults: Job[]
  correctedTerm: string | null
  recommendations: Job[]
  jobSearchStats: ResultStats | null
  similarJobsStats: ResultStats | null
  recommendationStats: ResultStats | null
  recommendationStatus: RecommendationsSearchStatus
  processedResponseAddress?: string
  selectedTabId: TabType
  similarJobsPageNumber: number
  scrollableSearchBarType: SearchBarType
  filters: Filters
  term: string
  lastSearchedTerm: string
  timeInterval: string
  page: string | null
  selectedLocation?: SearchSuggestionType
  locationText: string
  nearMeLocation?: SearchSuggestionType
  recommendationPage?: number
  setActiveJob: React.Dispatch<React.SetStateAction<Job | null>>
  setRecommendActiveJob: React.Dispatch<React.SetStateAction<Job | null>>
  setSimilarJobsPageNumber: React.Dispatch<React.SetStateAction<number>>
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
  locationPrefillCompleted?: boolean
  setLocationPrefillCompleted: React.Dispatch<React.SetStateAction<boolean>>
  selectRecommendedJob: (job: Job) => void
  beforeSwitchAction: () => void
  getLocationFromServer: () => Promise<SearchSuggestionType | undefined>
  handleChangeSelectedTabId: (tab: TabType) => void
  setScrollableSearchBarType: (type: SearchBarType) => void
  updateSearchParams: (obj: UpdateParamsType) => void
  setTerm: (a: string) => void
  setTimeInterval: (a: string) => void
  setPage: (a: string) => void
  setSelectedLocation: (location?: SearchSuggestionType) => void
  setLocationText: (location: string) => void
  setRecommendationPage: React.Dispatch<React.SetStateAction<number | undefined>>
  setExternalSlugId: (newValue: string | null, options?: Options) => void
  performRecommendations: (filters: Filters, page?: number) => Promise<void>
}

const JobSearchContext = createContext<JobSearchContextType | null>(null)

export const JobSearchProvider: React.FC = ({ children }) => {
  const [nearMeLocation, setNearMeLocation] = useState<SearchSuggestionType>()
  const [openJobAlert, setOpenJobAlert] = useQueryParam(PARAM_OPEN_JOB_ALERT)
  const [externalSlugId, setExternalSlugId] = useQueryParam(PARAM_EXTERNAL_SLUG_ID)

  const dispatch = useDispatch()
  const [query, setQueryString] = useSearchParams()

  const { isUserOnAutoApplyPlan, hasUserCompletedQuestionnaire } = useAutoApply()
  const [term, setTerm] = useState(query.get(PARAM_TERM) || query.get(PARAM_QUERY) || '')
  const [lastSearchedTerm, setLastSearchedTerm] = useState<string>('')

  const [selectedLocation, setSelectedLocation] = useState<SearchSuggestionType>()
  const [locationText, setLocationText] = useState('')
  const [locationPrefillCompleted, setLocationPrefillCompleted] = useState(false)
  const [timeInterval, setTimeInterval] = useState(query.get(PARAM_TIME) ?? '0')

  const [page, setPage] = useQueryParam(PARAM_PAGE)
  const [view, setView] = useQueryParam(PARAM_VIEW)

  const [recommendationPage, setRecommendationPage] = useState<number>()

  const [selectedTabId, setSelectedTabId] = useState<TabType>(
    view === 'recommendation' ? TabType.recommendation : TabType.search,
  )
  const [params, setParams] = useState<SearchQuery>({})
  const [similarJobsPageNumber, setSimilarJobsPageNumber] = useState(1)
  const stepsData = useTypedSelector(dashboardSelectors.stepsData)

  const [firstRequestForSearch, setFirstRequestForSearch] = useState(true)

  const [filters, setFilters] = useState<Filters>({
    onlyRemoteJobs: query.get(PARAM_ONLY_REMOTE_JOBS) === 'true' || DEFAULT_FILTERS.onlyRemoteJobs,
    onlyAutoApplyJobs:
      query.get(PARAM_ONLY_AUTO_APPLY_JOBS) === 'true' ||
      (isUserOnAutoApplyPlan && !!hasUserCompletedQuestionnaire),
    postedWithinDays:
      query.get(PARAM_TIME) === null || query.get(PARAM_TIME) === ''
        ? DEFAULT_FILTERS.postedWithinDays
        : parseInt(query.get(PARAM_TIME) as string),
    radius:
      query.get(PARAM_RADIUS) === null || query.get(PARAM_RADIUS) === ''
        ? DEFAULT_FILTERS.radius
        : parseInt(query.get(PARAM_RADIUS) as string),
  })
  const careerProfile = useTypedSelector(careerProfileSelectors.careerProfileData)

  const processingQueryLocation = (
    query: SearchQuery & Record<string, string>,
    location?: SearchSuggestionType,
  ): SearchQuery & Record<string, string> => {
    if (location?.locationType === 'near_me') {
      delete query.location
      query.location_latitude = location.lat
      query.location_longitude = location.lng
    }

    if (location?.locationType === 'location_id') {
      delete query.lng
      delete query.lat
      delete query.location
      query.location_id = location.value
    }

    if (location?.locationType === 'string_location') {
      delete query.location_latitude
      delete query.location_longitude
      query.location = location.value
    }

    if (!selectedLocation && !query.only_remote_jobs) {
      query.sort_by_coordinates = true
      if (nearMeLocation) {
        query.location_latitude = nearMeLocation?.lat
        query.location_longitude = nearMeLocation?.lng
      }
    } else {
      delete query.sort_by_coordinates
    }

    return query
  }

  const handleChangeSelectedTabId = useCallback(
    (tab: TabType) => {
      trackInternalEvent('click_job_search_tab', { tab })
      setView(tab)
      setSelectedTabId(tab)
    },
    [setView, setSelectedTabId],
  )

  const updateSearchParams = useCallback(
    (obj: UpdateParamsType) => {
      if (!locationPrefillCompleted) return
      let query: SearchQuery & Record<string, string> = {
        [PARAM_QUERY]: typeof obj.term === 'string' ? obj.term : term || '',
        [PARAM_TIME]: obj.time || filters.postedWithinDays.toString() || '',
        [PARAM_PAGE]: typeof obj.page === 'string' ? obj.page : '1',
        [PARAM_VIEW]: selectedTabId,
        [PARAM_RADIUS]: filters.radius.toString(),
        [PARAM_EXTERNAL_SLUG_ID]: externalSlugId || '',
      }

      if (openJobAlert === 'true') {
        setOpenJobAlert(null)
        dispatch(jobSearchActions.setAlertType({ type: AlertTypes.manage }))
      }

      query.only_remote_jobs = filters.onlyRemoteJobs || undefined
      query.only_auto_apply_jobs = filters.onlyAutoApplyJobs || undefined

      // Temporary disable feature until autocorrect results are more acceptable
      query.correct_term = false
      // query.correct_term = typeof obj.correct_term === 'boolean' ? obj.correct_term : true
      const location = selectedLocation || obj.location
      query = processingQueryLocation(query, location)

      if (query.page === '1') {
        // If you're on the first page, scroll to the top.
        window.scrollTo({
          top: 0,
          left: 0,
        })
      }
      setQueryString(query)
      setParams(query)
    },
    [
      term,
      filters,
      locationPrefillCompleted,
      timeInterval,
      selectedTabId,
      selectedLocation,
      setQueryString,
      openJobAlert,
    ],
  )

  const [searchStatus, setSearchStatus] = useState<SearchStatus>(SearchStatus.default)
  const [activeJob, setActiveJob] = useState<Job | null>(null)

  const [jobSearchResults, setJobSearchResults] = useState<Job[]>([])
  const [similarResults, setSimilarResults] = useState<Job[]>([])
  const [similarJobsStats, setSimilarJobsStats] = useState<ResultStats | null>(null)
  const [jobSearchStats, setJobSearchStats] = useState<ResultStats | null>(null)
  const [correctedTerm, setCorrectedTerm] = useState<string | null>(null)

  const [recommendations, setRecommendations] = useState<Job[]>([])
  const [recommendActiveJob, setRecommendActiveJob] = useState<Job | null>(null)
  const [recommendationStats, setRecommendationStats] = useState<ResultStats | null>(null)
  const [recommendationStatus, setRecommendationStatus] = useState<RecommendationsSearchStatus>(
    RecommendationsSearchStatus.loadingPage,
  )

  useEffect(() => {
    dispatch(
      jobSearchActions.setSearchForm({
        term,
        selectedLocation,
        filters,
      }),
    )
  }, [dispatch, term, selectedLocation, filters])

  const [scrollableSearchBarType, setScrollableSearchBarType] = useState<SearchBarType>(undefined)
  useEffect(() => {
    const scrollHappened = throttle(() => {
      if (window.scrollY >= SEARCH_BAR_HEIGHT && !scrollableSearchBarType) {
        setScrollableSearchBarType('scrollable_initial')
      }

      if (window.scrollY < SEARCH_BAR_HEIGHT) {
        setScrollableSearchBarType(undefined)
      }
    }, 100)

    window.addEventListener('scroll', scrollHappened, { passive: true })
    return () => window.removeEventListener('scroll', scrollHappened)
  }, [scrollableSearchBarType])

  useEffect(() => {
    dispatch(actions.fetchJobsRequest())
  }, [])

  useEffect(() => {
    updateSearchParams({})
  }, [filters])

  const getLocationFromServer = async () => {
    const suggestionObj = await _getLocationFromServer()
    setNearMeLocation(suggestionObj)
    return suggestionObj
  }

  const triggerNoSearchEvent = (queryParams: SearchQuery) => {
    const event = firstRequestForSearch
      ? 'view_no_search_results_default'
      : 'view_no_search_results'
    trackInternalEvent(event, {
      label: 'search',
      term: queryParams.query,
      location: handleLocationParameterEvent(selectedLocation, nearMeLocation),
      period: queryParams.within_n_days,
    })
  }

  useEffect(() => {
    const performSearch = async (query: SearchQuery) => {
      const isLoadingPage = query.page && parseInt(query.page) > 1

      setSearchStatus(isLoadingPage ? SearchStatus.loadingPage : SearchStatus.searching)

      // don't clear if they are just loading new page
      if (!isLoadingPage) {
        setJobSearchResults([])
        setJobSearchStats(null)
        setActiveJob(null)
      }

      const queryParams: SearchQuery = {
        ...query,
        add_groups: true,
        page: query.page || '1',
      }
      delete queryParams.external_slug_id

      // we will trigger only if query is added
      const willSimilarJobsBeTriggered = queryParams.query !== ''
      try {
        const { jobs, stats, correctedTerm } = await performApiSearch(queryParams)

        if (stats.page === 1) {
          trackInternalEvent('job_results', {
            label: 'search',
            term: queryParams.query,
            location: handleLocationParameterEvent(selectedLocation, nearMeLocation),
            period: queryParams.within_n_days,
            total_jobs: stats.total_jobs,
          })
        }

        if (stats.page > 1) {
          trackInternalEvent('scroll_search_results', {
            label: 'search',
            term: queryParams.query,
            location: handleLocationParameterEvent(selectedLocation, nearMeLocation),
            period: queryParams.within_n_days,
            page: stats.page,
            totalPages: stats.total_pages,
          })
        }
        if (isLoadingPage) {
          setJobSearchResults([
            ...jobSearchResults,
            ...jobs.map((job, index) => ({ ...job, position: jobSearchResults.length + index })),
          ])

          return
        }

        setLastSearchedTerm(queryParams.query || '')
        setCorrectedTerm(correctedTerm)
        setJobSearchStats(stats)
        setJobSearchResults((jobs || []).map((job, index) => ({ ...job, position: index })))
        setSimilarResults([])
        setSimilarJobsStats(null)
        setSimilarJobsPageNumber(1)

        if (jobs.length > 0 && window.innerWidth >= 1024) setActiveJob(jobs[0])
        if (!willSimilarJobsBeTriggered && jobs.length === 0 && stats.page === 1) {
          triggerNoSearchEvent(queryParams)
        }
      } finally {
        setSearchStatus(SearchStatus.loaded)
        if (!willSimilarJobsBeTriggered) {
          setFirstRequestForSearch(false)
        }
      }
    }
    if (locationPrefillCompleted && Object.keys(params).length > 0) {
      performSearch(params)
    }
  }, [
    params.within_n_days,
    params.location_id,
    params.location_latitude,
    params.location_longitude,
    params.query,
    params.page,
    params.only_remote_jobs,
    params.only_auto_apply_jobs,
    params.radius,
    params.correct_term,
    params.sort_by_coordinates,
    locationPrefillCompleted,
  ])

  useEffect(() => {
    if (careerProfile && recommendationStats?.page === 1) {
      trackInternalEvent('job_results', {
        label: 'recommendation',
        term: careerProfile.targetRoles.map(el => el.standardTitle).join(';'),
        location: careerProfile.targetLocations.map(el => el.formattedName).join(';'),
        total_jobs: recommendationStats.total_jobs,
        location_from_resume: recommendationStats.location_from_resume,
        target_role_from_resume: recommendationStats.target_role_from_resume,
      })
    }
  }, [careerProfile, recommendationStats])

  const performRecommendations = async (filters: Filters, page?: number) => {
    if (!page) {
      return
    }

    const isLoadingPage = page > 1

    setRecommendationStatus(
      isLoadingPage
        ? RecommendationsSearchStatus.loadingPage
        : RecommendationsSearchStatus.searching,
    )

    try {
      const { jobs, stats } = await fetchRecommendations(page, filters)

      if (jobs.length && !stepsData.personalized_jobs.completed) {
        dispatch(dashboardActions.updatePersonalizedJobsStateRequest())
      }

      if (stats.page > 1) {
        trackInternalEvent('scroll_search_results', {
          label: 'recommendation',
          page: stats.page,
          totalPages: stats.total_pages,
        })
      }
      if (isLoadingPage) {
        setRecommendations(prevJobs => [
          ...prevJobs,
          ...jobs.map((job, index) => ({ ...job, position: recommendations.length + index })),
        ])
        return
      }
      setRecommendationStats(stats)
      setRecommendations(jobs.map((job, index) => ({ ...job, position: index })))
      dispatch(careerProfileActions.setRecommendationsMissingAttributes([]))
      if (jobs.length > 0 && window.innerWidth >= 1024) {
        if (externalSlugId && stats.page === 1) {
          setRecommendActiveJob(jobs.find(el => el.external_slug_id === externalSlugId) || null)
        } else {
          setRecommendActiveJob(jobs[0])
        }
      }
      if (jobs.length === 0 && stats.page === 1) {
        trackInternalEvent('view_no_search_results_default', {
          label: 'recommendation',
        })
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 422) {
        const axiosError = error as AxiosError
        const missingAttributes = (axiosError.response?.data as Record<string, any>)
          .missing_attributes as RecommendationsRequiredAttributesType[]

        dispatch(careerProfileActions.setRecommendationsMissingAttributes(missingAttributes))

        trackInternalEvent('job_results', {
          label: 'recommendation',
          term: '',
          location: '',
          locations: [],
          titles: [],
          total_jobs: 0,
        })
      }
    } finally {
      setRecommendationStatus(RecommendationsSearchStatus.loaded)
    }
  }

  useEffect(() => {
    performRecommendations(filters, recommendationPage)
  }, [recommendationPage, filters.onlyAutoApplyJobs, filters.postedWithinDays])

  useEffect(() => {
    const performSimilarJobs = async (query: SearchQuery) => {
      const isLoadingPage = query.page && parseInt(query.page) > 1
      if (!isLoadingPage) {
        setSimilarResults([])
        setSimilarJobsStats(null)
      }
      const queryParams: Omit<SearchQuery, 'correct_term'> = {
        ...query,
        page: query.page || '1',
      }
      delete queryParams.external_slug_id

      try {
        const { jobs, stats } = await performSimilarJobsApi(queryParams)
        if (isLoadingPage) {
          setSimilarResults([
            ...similarResults,
            ...jobs.map((job, index) => ({ ...job, position: jobSearchResults.length + index })),
          ])
          return
        }
        if (stats.page === 1) {
          trackInternalEvent('job_results', {
            label: 'similar-jobs',
            ...query,
            total_jobs: stats.total_jobs,
          })
        }
        setSimilarJobsStats(stats)
        setSimilarResults(jobs.map((job, index) => ({ ...job, position: index })))
        if (
          jobSearchResults.length === 0 &&
          jobs.length &&
          stats.page === 1 &&
          window.innerWidth >= 1024
        ) {
          setActiveJob(jobs[0])
        }
        if (jobSearchResults.length === 0 && jobs.length === 0 && stats.page === 1) {
          triggerNoSearchEvent(queryParams)
        }
      } finally {
        setFirstRequestForSearch(false)
      }
    }

    const canPerformSimilarJobs =
      params.query !== '' &&
      searchStatus === SearchStatus.loaded &&
      jobSearchStats &&
      (jobSearchResults.length === 0 || jobSearchResults.length >= jobSearchStats.total_jobs)

    if (canPerformSimilarJobs) {
      performSimilarJobs({ ...params, page: similarJobsPageNumber.toString() })
    }
  }, [jobSearchResults, jobSearchStats, similarJobsPageNumber, searchStatus])
  const selectRecommendedJob = (job: Job) => {
    setActiveJob(job)
  }

  const beforeSwitchAction = () => {
    if (!jobSearchResults?.length) {
      setJobSearchResults(recommendations)
      setJobSearchStats(recommendationStats)
      setSearchStatus(SearchStatus.loaded)
    }
  }

  const value: JobSearchContextType = {
    jobSearchStats,
    recommendActiveJob,
    correctedTerm,
    jobSearchResults,

    similarResults,
    similarJobsStats,
    similarJobsPageNumber,
    setSimilarJobsPageNumber,

    activeJob,
    searchStatus,
    recommendations,
    recommendationStatus,
    selectedLocation,
    locationText,
    nearMeLocation,
    selectedTabId,
    scrollableSearchBarType,
    term,
    lastSearchedTerm,
    timeInterval,
    page,
    recommendationStats,
    recommendationPage,
    setRecommendationPage,
    setRecommendActiveJob,
    setActiveJob,
    beforeSwitchAction,
    selectRecommendedJob,
    getLocationFromServer,
    handleChangeSelectedTabId,
    setScrollableSearchBarType,
    updateSearchParams,
    setTerm,
    setTimeInterval,
    setPage,
    setSelectedLocation,
    setLocationText,
    filters,
    setFilters,
    setLocationPrefillCompleted,
    locationPrefillCompleted,
    setExternalSlugId,
    performRecommendations,
  }

  return <JobSearchContext.Provider value={value}>{children}</JobSearchContext.Provider>
}

export const useJobSearch = (): JobSearchContextType =>
  useContext(JobSearchContext as Context<JobSearchContextType>)
