import { SectionNames } from './sectionNames'

export const SectionFieldNames = {
  [SectionNames.workExperiences]: {
    title: 'title' as const,
    employer: 'employer' as const,
    dateFrom: 'dateFrom' as const,
    dateUntil: 'dateUntil' as const,
    city: 'city' as const,
    description: 'description' as const,
  },
  // can be extended in future
  [SectionNames.details]: {},
  [SectionNames.profile]: {},
  [SectionNames.educations]: {},
  [SectionNames.courses]: {},
  [SectionNames.internships]: {},
  [SectionNames.activities]: {},
  [SectionNames.skills]: {},
  [SectionNames.languages]: {},
  [SectionNames.hobbies]: {},
  [SectionNames.references]: {},
  [SectionNames.custom]: {},
  [SectionNames.socialProfiles]: {},
  [SectionNames.accomplishments]: {},
  [SectionNames.powerStatement]: {},
}

export const AI_PROFILE_SUMMARY_ANALYTICS_LABEL = 'ai_profile_summary'

// strings are from AI team documentation, do not change them without discussion
export const SECTIONS_FOR_AUTO_TAILORING = [
  'wanted_job_title',
  'profile',
  'skills',
  'work_experience',
] as const

export const UNBLOCKED_EDITOR_URL_PARAM = 'unblockedEditor'
