import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { selectors, actions } from 'builder/modules/careerProfile'
import { selectors as userSelectors } from 'builder/modules/user'

import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import Colors from 'builder/styles/colors'
import { useIsCareerProfileAvailable } from 'builder/components/CareerProfile'
import {
  AvatarIcon,
  Container,
  Header,
  Score,
  ScoreWrapper,
  SubHeader,
  TextContainer,
} from './styles'

export type ColorsProp = {
  start: string
  end: string
}
interface IProps {
  active?: boolean
  showOnlyAvatar?: boolean
  [key: string]: unknown
}

export const UserProfile = ({ active, showOnlyAvatar, ...rest }: IProps) => {
  const user = useUser()
  const { i18n } = useI18n()
  const dispatch = useDispatch()

  const photoUrl = useTypedSelector(userSelectors.photoUrl)
  const careerProfileData = useTypedSelector(selectors.careerProfileData)
  const completenessPercent = useTypedSelector(selectors.completenessPercent)
  const isCompleted = completenessPercent === 100
  const isCareerProfileAvailable = useIsCareerProfileAvailable()

  const getProgressColor = (completenessPercent: number): ColorsProp => {
    if (completenessPercent < 10) {
      return { start: Colors.Orange50, end: Colors.Orange30 }
    } else if (completenessPercent < 70) {
      return { start: Colors.Amber50, end: Colors.Amber30 }
    } else {
      return { start: Colors.Green50, end: Colors.Green30 }
    }
  }

  useEffect(() => {
    if (isCareerProfileAvailable) {
      dispatch(actions.fetchCareerProfile())
      dispatch(actions.fetchCompleteness())
    }
  }, [dispatch, isCareerProfileAvailable])

  return (
    <Container {...rest}>
      <ScoreWrapper percentageColor={getProgressColor(completenessPercent || 0)}>
        <AvatarIcon src={photoUrl} />
        {!isCompleted && (
          <Score percentageColor={getProgressColor(completenessPercent || 0)}>
            {completenessPercent}%
          </Score>
        )}
      </ScoreWrapper>
      {!showOnlyAvatar && (
        <TextContainer>
          <Header active={active}>{user?.fullName}</Header>
          <SubHeader>
            {careerProfileData?.targetRoles?.length
              ? careerProfileData?.targetRoles[0].standardTitle
              : i18n.t(`builder.side_menu.profile.blank_target_role`)}
          </SubHeader>
        </TextContainer>
      )}
    </Container>
  )
}
