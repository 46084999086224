import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import FindJob from 'builder/components/FindJob'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as careerProfileSelectors, actions } from 'builder/modules/careerProfile'
import { TargetRoleDialog } from 'builder/views/CareerProfile/TargetRoleDialog'
import TARGET_LOCATION from 'builder/views/CareerProfile/TargetRoleDialog/assets/target_location.png'
import { TargetLocationImage } from 'builder/components/FindJob/styles'
import { useI18n } from 'builder/hooks/useI18n'
import {
  RecommendationsRequiredAttributes,
  SHOW_CHECK_PROFILE_BANNER_STORAGE_KEY,
  TabType,
} from 'builder/components/FindJob/types'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import { CheckProfileModal } from 'builder/components/FindJob/CheckProfileModal'
import { UploadResumeModal } from 'builder/components/FindJob/UploadResumeModal/UploadResumeModal'
import { FetchStatuses } from 'builder/modules/constants'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import { useAutoApply } from '../AutoApply/hooks/useAutoApply'

export const JobSearchView = () => {
  const [showCompleteProfileModal, setShowCompleteProfileModal] = useState(false)
  const [showCareerProfileModal, setShowCareerProfileModal] = useState(false)
  const [showUploadResumeModal, setShowUploadResumeModal] = useState(false)
  const careerProfileData = useTypedSelector(careerProfileSelectors.careerProfileData)
  const isNewProfile = useTypedSelector(careerProfileSelectors.isNewProfile)
  const fetchIsNewProfileStatus = useTypedSelector(careerProfileSelectors.fetchIsNewProfileStatus)
  const { i18n } = useI18n()
  const [view] = useQueryParam('view')
  const dispatch = useDispatch()
  const [uploadModalWasClosed, setUploadModalWasClosed] = useState(false)
  const recommendationsMissingAttributes = useTypedSelector(
    careerProfileSelectors.recommendationsMissingAttributes,
  )
  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)
  const { hasUserCompletedQuestionnaire } = useAutoApply()

  // Track the page opening
  useEffect(() => {
    trackInternalEvent('visit_job_search')
  }, [])

  useEffect(() => {
    if (fetchIsNewProfileStatus === FetchStatuses.notAsked) {
      dispatch(actions.prefillProfile())
    }
  }, [dispatch, fetchIsNewProfileStatus])

  useEffect(() => {
    if (
      autoApplyBubbles.initialized &&
      autoApplyBubbles.selectedJob?.done &&
      !autoApplyBubbles.running &&
      view === TabType.recommendation &&
      isNewProfile &&
      fetchIsNewProfileStatus === FetchStatuses.loaded &&
      !showUploadResumeModal &&
      !uploadModalWasClosed &&
      !hasUserCompletedQuestionnaire &&
      (recommendationsMissingAttributes.includes(
        RecommendationsRequiredAttributes.TargetLocation,
      ) ||
        recommendationsMissingAttributes.includes(RecommendationsRequiredAttributes.TargetRole))
    ) {
      setShowCompleteProfileModal(true)
    }
  }, [
    autoApplyBubbles.initialized,
    autoApplyBubbles.selectedJob?.done,
    autoApplyBubbles.running,
    view,
    showUploadResumeModal,
    fetchIsNewProfileStatus,
    isNewProfile,
    uploadModalWasClosed,
    recommendationsMissingAttributes,
    hasUserCompletedQuestionnaire,
  ])

  const hasWorkExperience = !!careerProfileData?.workExperiencesAttributes.length

  return (
    <>
      <FindJob />

      {showCompleteProfileModal && (
        <TargetRoleDialog
          includeLocation
          setClose={() => setShowCompleteProfileModal(false)}
          showSkip
          locationData={{
            buttonText: i18n.t('builder.job_search.recommendations.target_location.button'),
            subtitle: i18n.t('builder.job_search.recommendations.target_location.subtitle'),
            title: i18n.t('builder.job_search.recommendations.target_location.title'),
            Image: <TargetLocationImage src={TARGET_LOCATION} alt="target-location" />,
          }}
          roleData={{
            buttonText: i18n.t('builder.job_search.recommendations.target_role.button'),
            subtitle: i18n.t('builder.job_search.recommendations.target_role.subtitle'),
            title: i18n.t('builder.job_search.recommendations.target_role.title'),
          }}
          handleAfterSave={() => {
            if (hasWorkExperience) {
              dispatch(actions.setIsNewProfile(false))
              setShowCareerProfileModal(true)
            } else {
              setShowUploadResumeModal(true)
            }
          }}
          eventLabel="recommended_jobs"
        />
      )}

      {showUploadResumeModal && (
        <UploadResumeModal
          onClose={() => {
            setShowUploadResumeModal(false)
            setUploadModalWasClosed(true)

            if (
              (recommendationsMissingAttributes.includes(
                RecommendationsRequiredAttributes.TargetLocation,
              ) ||
                recommendationsMissingAttributes.includes(
                  RecommendationsRequiredAttributes.TargetRole,
                )) &&
              !recommendationsMissingAttributes.includes(
                RecommendationsRequiredAttributes.WorkExperience,
              ) &&
              !recommendationsMissingAttributes.includes(
                RecommendationsRequiredAttributes.WorkExperienceWithDetails,
              )
            ) {
              window.location.reload()
            }
          }}
          goBack={() => {
            setShowUploadResumeModal(false)
            setShowCompleteProfileModal(true)
          }}
          eventLabel="recommended_jobs"
          redirectUrl="/career-profile/edit?returnUrl=/job-search?view=recommendation"
        />
      )}

      {showCareerProfileModal && (
        <CheckProfileModal
          onClose={() => {
            localStorage.setItem(SHOW_CHECK_PROFILE_BANNER_STORAGE_KEY, 'true')
            setShowCareerProfileModal(false)
            window.location.reload()
          }}
          onClick={() => {
            localStorage.setItem(SHOW_CHECK_PROFILE_BANNER_STORAGE_KEY, 'true')
            window.location.reload()
          }}
          eventLabel="recommended_jobs"
        />
      )}
    </>
  )
}
