import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import {
  actions as jobSearchActions,
  selectors as jobSearchSelectors,
} from 'builder/modules/jobSearch'
import { AlertTypes, CustomAlertType } from 'builder/modules/jobSearch/types'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { ConfigScopesEnum } from 'builder/modules/init'
import { useConfig } from 'builder/hooks/useConfig'
import {
  actions as careerProfileActions,
  selectors as careerProfileSelectors,
} from 'builder/modules/careerProfile'
import Spinner from 'builder/components/Spinner'
import { FetchStatuses } from 'builder/modules/constants'
import { ScrollabelDialogModalV2 } from 'builder/components/FindJob/AlertModal/ManageYourJobAlerts/ScrollabelDialogModalV2/ScrollabelDialogModalV2'
import { useGetCustomAlerts } from '../hooks/useGetCustomAlerts'
import { TabType } from '../../types'
import { useGetRecommendationAlert } from '../hooks/useGetRecommendationAlert'
import { CreateCustomAlertButton } from './CreateCustomAlertButton/CreateCustomAlertButton'
import { CustomAlert } from './CustomAlert/CustomAlert'
import { Content, SpinnerContainer } from './styles'
import { RecommendedAlert } from './RecommendedAlert/RecommendedAlert'
import { BlockedRecommendedAlert } from './BlockedRecommendedAlert/BlockedRecommendedAlert'
const TRANSLATION = 'builder.job_search.alert_modal.manage_your_job_alerts'
interface Props {
  selectedTab?: TabType
}
export const ManageYourJobAlerts = ({ selectedTab }: Props) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const enableJobAlertFeature = useTypedSelector(jobSearchSelectors.enableJobAlertFeature)
  const countryConfig = useConfig(ConfigScopesEnum.country)
  const customAlerts = useGetCustomAlerts(enableJobAlertFeature)
  const recommendationAlert = useGetRecommendationAlert()

  const careerProfile = useTypedSelector(careerProfileSelectors.careerProfileData)
  const searchForm = useTypedSelector(jobSearchSelectors.searchForm)
  const fetchCareerProfileStatus = useTypedSelector(careerProfileSelectors.fetchCareerProfileStatus)

  useEffect(() => {
    if (fetchCareerProfileStatus === FetchStatuses.notAsked) {
      dispatch(careerProfileActions.fetchCareerProfile())
    }
  }, [fetchCareerProfileStatus])

  const missingTargetLocation =
    !careerProfile?.targetLocations ||
    careerProfile?.targetLocations?.length === 0 ||
    careerProfile?.targetLocations[0].formattedName === ''

  const missingTargetRole =
    !careerProfile?.targetRoles ||
    careerProfile?.targetRoles.length === 0 ||
    careerProfile?.targetRoles[0].standardTitle === ''

  const blockedRecommendedAlert = missingTargetLocation || missingTargetRole

  const renderBody = () => {
    if (customAlerts.isLoading || recommendationAlert.isLoading) {
      return (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )
    }

    return (
      <>
        <Content>
          {blockedRecommendedAlert ? (
            <BlockedRecommendedAlert />
          ) : (
            <RecommendedAlert isEnabled={!!recommendationAlert.data?.active} />
          )}
          {(customAlerts.data || []).map(alert => (
            <CustomAlert
              key={alert.id}
              {...alert}
              onClick={() => {
                dispatch(
                  jobSearchActions.setAlertType({
                    type: AlertTypes.edit,
                    editCustomAlert: alert,
                  }),
                )
              }}
            />
          ))}
        </Content>
        {countryConfig &&
          customAlerts.data &&
          countryConfig.jobAlertsLimit > customAlerts.data.length && (
            <CreateCustomAlertButton
              onClick={() => {
                let editCustomAlert: CustomAlertType | undefined

                if (selectedTab && selectedTab === TabType.search) {
                  editCustomAlert = {
                    targetRole: searchForm.term,
                    onlyRemoteJobs: searchForm.filters.onlyRemoteJobs,
                    location:
                      searchForm.selectedLocation?.locationType === 'near_me'
                        ? searchForm.selectedLocation.text
                        : searchForm.selectedLocation?.formatted,
                    locationId: searchForm.selectedLocation?.value
                      ? parseInt(searchForm.selectedLocation.value)
                      : undefined,
                  }
                }
                dispatch(
                  jobSearchActions.setAlertType({
                    type: AlertTypes.create,
                    editCustomAlert,
                  }),
                )
              }}
            />
          )}
      </>
    )
  }

  return (
    <ScrollabelDialogModalV2
      onClose={() => dispatch(jobSearchActions.setAlertType({ type: undefined }))}
      title={i18n.t(`${TRANSLATION}.title`)}
    >
      {renderBody()}
    </ScrollabelDialogModalV2>
  )
}
