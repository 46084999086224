import { useState } from 'react'
import Icon, { Icon20 } from 'builder/components/Icon'
import ActionMenu from 'builder/components/ActionMenu'

import { useI18n } from 'builder/hooks/useI18n'
import { MouseClickEvent } from 'builder/modules/ui'
import {
  Tooltip,
  Header,
  HeaderInner,
  HeaderContent,
  HeaderTitle,
  HeaderSubtitle,
  ToggleIcon,
  ToggleIconInner,
  DeleteIcon,
  DragIcon,
  MobileIcon,
} from './styles'

type Props = {
  title?: string
  subtitle?: string
  onToggle: (event?: MouseClickEvent) => void
  onDelete: (event?: MouseClickEvent) => void
  isOpen?: boolean
  isDragging?: boolean
  disableMove?: boolean
  isFoldableSectionsEnabled?: boolean
}

export const CardTitle = (props: Props) => {
  const {
    title,
    subtitle = '',
    isOpen,
    isDragging,
    onToggle,
    onDelete,
    disableMove = false,
    isFoldableSectionsEnabled,
  } = props
  const { i18n } = useI18n()
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  const actionSheetOptions = [
    {
      icon: <Icon.Edit />,
      text: i18n.t('builder.resume_editor.edit'),
      onClick: onToggle,
    },
    {
      icon: <Icon.Trash />,
      text: i18n.t('builder.resume_editor.delete'),
      onClick: onDelete,
    },
  ]

  const handleActionSheetOpen = (event: MouseClickEvent) => {
    setIsSheetOpen(true)
    event.stopPropagation()
  }

  const handleActionSheetClose = () => {
    setIsSheetOpen(false)
  }

  return (
    <Header>
      <HeaderInner onClick={onToggle}>
        <HeaderContent>
          <HeaderTitle active={!!isOpen}>{title}</HeaderTitle>
          {subtitle && <HeaderSubtitle>{subtitle}</HeaderSubtitle>}
        </HeaderContent>

        <MobileIcon onClick={handleActionSheetOpen}>
          <Icon.More />
        </MobileIcon>

        <ToggleIcon>
          <Tooltip>
            {isOpen
              ? i18n.t('builder.resume_editor.collapse')
              : i18n.t('builder.resume_editor.expand')}
          </Tooltip>
          <ToggleIconInner isOpen={isOpen}>
            <Icon.ToggleArrow />
          </ToggleIconInner>
        </ToggleIcon>
      </HeaderInner>

      {!disableMove && (
        <DragIcon isDragging={isDragging} $isFoldableSectionsEnabled={isFoldableSectionsEnabled} />
      )}

      <DeleteIcon onClick={onDelete}>
        <Tooltip>{i18n.t('builder.resume_editor.delete')}</Tooltip>
        <Icon20.Trash />
      </DeleteIcon>

      {isSheetOpen && <ActionMenu onClose={handleActionSheetClose} actions={actionSheetOptions} />}
    </Header>
  )
}
