import { FC } from 'react'
import Foldable from '../Foldable'
import { SectionHeaderFoldableContent } from './styles'
import { WithFoldableSections } from './types'

type Props = {
  isOpened?: boolean
  isDragging?: boolean
} & WithFoldableSections

export const SectionHeaderFoldableContainer: FC<Props> = props => {
  const { isOpened, children, isFoldableSectionsEnabled, isDragging } = props

  if (!isFoldableSectionsEnabled) {
    return <>{children}</>
  }

  if (isDragging) {
    return null
  }

  return (
    <Foldable isOpen={isOpened}>
      <SectionHeaderFoldableContent>{children}</SectionHeaderFoldableContent>
    </Foldable>
  )
}
