import { apiClient, baseClient } from 'builder/modules/apiClient'
import { CancelableRequest, DefaultSuggestionType, SuggestionType } from './types'

export enum ApiSection {
  Root = '',
  CareerPath = '/career-pathways',
  CareerPages = 'career-data/v1',
}

// Loosely based on makeCancellable implementation by @istarkov
// https://github.com/facebook/react/issues/5465#issuecomment-157888325
export const makeCancelable = <V>(promise: Promise<V>): CancelableRequest<V> => {
  let hasCanceled = false

  const wrappedPromise = new Promise<V>((resolve, reject) => {
    promise.then(val => !hasCanceled && resolve(val))
    promise.catch(error => !hasCanceled && reject(error))
  })

  return {
    promise: wrappedPromise,
    cancel: () => {
      hasCanceled = true
    },
  }
}

export interface FetcherOptions {
  limit?: number
  locale?: string
  country?: string
  section?: ApiSection
}

/**
 * Creates a handler that could be used as `fetchItems` property.
 */
export const createSuggestionsApiFetcher =
  <T extends SuggestionType = DefaultSuggestionType>(
    namespace: string,
    options: FetcherOptions = {},
  ) =>
  async (query: string) => {
    try {
      const { limit = 5, locale, section = ApiSection.Root, ...rest } = options

      const params = { query, limit, locale, ...rest }

      const response = await apiClient.get<{ suggestions: T[] }>(
        `${section}/suggest/${namespace}`,
        { params },
      )

      return response.data ? response.data.suggestions : []
    } catch (error) {
      return []
    }
  }

export const createNewSuggestionsApiFetcher =
  <T extends SuggestionType = DefaultSuggestionType>(
    namespace: string,
    options: FetcherOptions = {},
  ) =>
  async (query: string) => {
    try {
      const { limit = 5, locale, section = ApiSection.Root, ...rest } = options

      const params = { startswith: query, limit, locale, ...rest }

      if (namespace === 'job-titles') {
        const response = await baseClient.get<T[]>(`${section}/${namespace}`, {
          params,
        })

        return response.data
          ? response.data.map(
              title =>
                ({
                  text: title.standardTitle,
                } as unknown as T),
            )
          : []
      }

      const response = await baseClient.get<T[]>(`${section}/${namespace}`, {
        params,
      })

      return response.data ? response.data.map(text => ({ text } as unknown as T)) : []
    } catch (error) {
      return []
    }
  }
