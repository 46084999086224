import { useEffect } from 'react'
import { Outlet, matchPath, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { actions, ConfigScopesEnum } from 'builder/modules/init'
import { Scope as i18nScopeType } from 'builder/context/I18nContext'
import UserScope from 'builder/components/UserScope'
import { useCareerProfileFeaturesInit } from 'builder/components/CareerProfile'
import { actions as userActions, selectors as userSelectors } from 'builder/modules/user'
import { useConfig } from 'builder/hooks/useConfig'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useI18n } from 'builder/hooks/useI18n'
import CookieV4 from '../CookieV4'

type SmartRouteProps = {
  configScope?: ConfigScopesEnum
  i18nScope?: i18nScopeType
  privateRoute?: boolean
  premiumRoute?: boolean
}

const SmartRoute = (props: SmartRouteProps) => {
  const {
    configScope = ConfigScopesEnum.app,
    i18nScope = 'builder',
    privateRoute,
    premiumRoute,
  } = props
  const config = useConfig(configScope)
  const { isScopeLoaded, loadScope, isEditorScopeLoaded } = useI18n()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const isResumeEditor = matchPath('resumes/:id/edit', pathname)
  const isCoverLetterEditor = matchPath('cover-letters/:id/edit', pathname)
  const isEditPage = isResumeEditor ? true : !!isCoverLetterEditor

  const isUserFetched = useTypedSelector(userSelectors.isFetched)
  const isI18nScopeReady = isScopeLoaded(i18nScope)
  const isI18nEditorScopeReady = isEditorScopeLoaded(i18nScope)

  useEffect(() => {
    if (!isUserFetched && privateRoute) {
      dispatch(userActions.fetchUserRequest())
    }
  }, [dispatch, isUserFetched, privateRoute])

  useEffect(() => {
    if (!config) {
      dispatch(actions.fetchConfig({ scope: configScope }))
    }
  }, [config, configScope, dispatch])

  useEffect(() => {
    if (!isI18nScopeReady) {
      loadScope(i18nScope)
    }
  }, [i18nScope, isI18nScopeReady, loadScope])

  useEffect(() => {
    if (!isI18nEditorScopeReady && isEditPage) {
      loadScope(i18nScope)
    }
  }, [i18nScope, isI18nEditorScopeReady, loadScope, isEditPage])

  useCareerProfileFeaturesInit(isEditPage)

  if (!config || (!isUserFetched && privateRoute) || (!isI18nScopeReady && !isEditPage)) return null

  return privateRoute ? (
    <UserScope premium={premiumRoute}>
      <Outlet />
    </UserScope>
  ) : (
    <>
      <Outlet />
      <CookieV4 />
    </>
  )
}

export default SmartRoute
