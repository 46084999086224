import { useI18n } from 'builder/hooks/useI18n'
import TargetImage from 'images/resume_optimizer/target_image.png'
import {
  ArrowIcon,
  Banner,
  BannerImage,
  BannerTitle,
  ActionWrapper,
  ActionText,
} from './components/TailorResumeBannerView1/styles'

type Props = {
  onBannerClick: () => void
}

const TailorResumeBannerGuest = ({ onBannerClick }: Props) => {
  const { i18n } = useI18n()

  return (
    <Banner onClick={onBannerClick}>
      <BannerImage src={TargetImage} alt="Target Image" />
      <BannerTitle>
        {i18n.t('builder.resume_editor.keywords.improve_resume_panel.tailor_resume_banner.title')}
      </BannerTitle>
      <ActionWrapper $isGuest>
        <ActionText>
          {i18n.t(
            'builder.resume_editor.keywords.improve_resume_panel.tailor_resume_banner.button_text',
          )}
        </ActionText>
        <ArrowIcon />
      </ActionWrapper>
    </Banner>
  )
}

export default TailorResumeBannerGuest
