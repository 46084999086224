import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { Interview, Ready } from 'builder/modules/interview/types'
import { POLLING_REFRESH_INTERVAL_IN_MILLIS } from '../constants'
import { getNumberOfQuestionOfInterview } from '../utils/getNumberOfQuestionOfInterview'
interface Props {
  interviewId?: number | string
}

export const getUseInterviewKey = (interviewId?: number | string) => ['useInterview', interviewId]

export const useInterview = ({ interviewId }: Props) => {
  return useQuery<Ready<Interview>>(
    getUseInterviewKey(interviewId),
    async () => {
      const response = await baseClient.get<Interview>(`/interview_prep/interviews/${interviewId}`)
      const limits = getNumberOfQuestionOfInterview(response.data)

      return {
        isReady:
          response.data.questions.filter(question => question.base_question_id === null).length ===
          limits?.maxMainQuestions,
        payload: response.data,
      }
    },
    {
      enabled: !!interviewId,
      refetchInterval: (data?: Ready<Interview>) => {
        if (!data) {
          return POLLING_REFRESH_INTERVAL_IN_MILLIS
        }
        if (data.isReady) {
          return false
        }
        return POLLING_REFRESH_INTERVAL_IN_MILLIS
      },
      useErrorBoundary: false,
    },
  )
}
