import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequestStatuses } from 'organizations/constants'
import type { Optional } from '@rio/types'
import { AccountStore, SerializedAccount } from './types'

const initialState: AccountStore = {
  user: null,
  organization: null,
  loginStatus: RequestStatuses.notAsked,
  loginError: null,
}

const { reducer, actions } = createSlice({
  name: 'account',
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<SerializedAccount>) => {
      const { id, email, organization } = action.payload
      state.user = { id, email }
      state.organization = organization
      state.loginStatus = RequestStatuses.complete
    },
    setLoginStatus: (state, action: PayloadAction<RequestStatuses>) => {
      state.loginStatus = action.payload
    },
    setLoginError: (state, action: PayloadAction<Optional<string>>) => {
      state.loginError = action.payload
    },
  },
})

export { reducer, actions }
