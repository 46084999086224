import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Store } from 'builder/modules/store'
import { ActiveInterviewTab, InterviewPlayerModalType, InterviewStore } from './types'

export const selectors = {
  jobTitleId: (state: Store) => state.interview.jobTitleId || 0,
  jobTitle: (state: Store) => state.interview.jobTitle || '',
  startCurrentStep: (state: Store) => state.interview.startCurrentStep || 0,
  interviewId: (state: Store) =>
    state.interview.interviewId || localStorage.interviewId
      ? Number(localStorage.interviewId)
      : undefined,
  activeInterviewTab: (state: Store) => state.interview.activeInterviewTab,
  getInterviewUrl: (state: Store) =>
    `/interview-preparation/${state.interview.jobTitleId ? 'dashboard' : 'start'}`,
  getInterviewDomainUrl: (state: Store) =>
    `https://career.io/app/interview-preparation/interview-preparation/${
      state.interview.jobTitleId ? 'dashboard' : 'start'
    }`,

  interviewPlayer: (state: Store) => state.interview.interviewPlayer,
}

const interviewEmptyState = (): InterviewStore['interviewPlayer'] => ({
  modal: InterviewPlayerModalType.loading_start,
  showAllPhrases: false,
})

const initialState: InterviewStore = {
  jobTitleId: 0,
  jobTitle: '',
  interviewId: 0,
  activeInterviewTab: 'in_progress',
  startCurrentStep: 1,
  interviewPlayer: interviewEmptyState(),
}

const { reducer, actions } = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    setJobTitleId: (state, action: PayloadAction<number>) => {
      state.jobTitleId = action.payload
      localStorage.setItem('jobTitleId', String(action.payload))
    },
    setJobTitle: (state, action: PayloadAction<string>) => {
      state.jobTitle = action.payload
      localStorage.setItem('jobTitle', String(action.payload))
    },
    setInterviewId: (state, action: PayloadAction<number>) => {
      state.interviewId = action.payload
      localStorage.setItem('interviewId', String(action.payload))
    },
    setActiveInterviewTab: (state, action: PayloadAction<ActiveInterviewTab>) => {
      state.activeInterviewTab = action.payload
    },
    setStartCurrentStep: (state, action: PayloadAction<number>) => {
      state.startCurrentStep = action.payload
    },
    resetInterviewPlayer: state => {
      state.interviewPlayer = interviewEmptyState()
    },
    setInterviewPlayerModal: (state, action: PayloadAction<InterviewPlayerModalType | null>) => {
      state.interviewPlayer.modal = action.payload
    },

    setInterviewPlayerShowAllPhrases: (state, action: PayloadAction<boolean>) => {
      state.interviewPlayer.showAllPhrases = action.payload
    },
  },
})

export { reducer, actions }
