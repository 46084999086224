import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useI18n } from 'builder/hooks/useI18n'

import { CustomElement } from 'builder/hooks/useFields'
import { validateUrl } from 'builder/utils/regexValidations'
import { JobCreatePayload } from 'builder/modules/jobTracking'

import { LinkField } from './Fields/LinkField'
import { CompanyField } from './Fields/CompanyField'
import { Container, Actions, SaveButton, CancelButton } from './styles'

const TRANSLATION = 'builder.job_tracking.quick_add'

type Step = 'company' | 'link'
type Card = Omit<JobCreatePayload, 'open' | 'dummy' | 'status' | 'external_slug_id'>
type Props = {
  onCancel: () => void
  onSubmit: (fields: JobCreatePayload) => void
}

const QuickAdd = ({ onSubmit, onCancel }: Props) => {
  const { i18n } = useI18n()
  const form = useForm<Card>({ mode: 'onChange' })
  const [step, setStep] = useState<Step>('company')
  const isFirstStep = step === 'company'

  const onChangeStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const data = await form.trigger('company')
    if (data) setStep('link')
  }

  const validateURL = (val?: string | null) => {
    return validateUrl(val || '') || i18n.t(`${TRANSLATION}.invalid_url`)
  }

  const onCompanyChange = (e: React.ChangeEvent<HTMLInputElement> | CustomElement) => {
    if (e.target.name) {
      const name = e.target.name as keyof Card
      form.setValue(name, e.target.value)
    }
    form.setValue('link', undefined)
  }

  const onChangeLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setValue('link', e.target.value)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') e.preventDefault()
  }

  const handleSubmit = (data: Card) => {
    onSubmit(data as JobCreatePayload)
  }

  return (
    <Container onSubmit={form.handleSubmit(handleSubmit)} onKeyDown={onKeyDown}>
      {step === 'company' && (
        <CompanyField
          {...form.register('company', {
            required: i18n.t(`${TRANSLATION}.company_error`),
          })}
          value={form.watch('company')}
          handleUpdate={onCompanyChange}
          errorMessage={form.formState.errors.company?.message}
          placeholder={i18n.t(`${TRANSLATION}.company_placeholder`)}
        />
      )}

      {step === 'link' && (
        <LinkField
          {...form.register('link', {
            required: i18n.t(`${TRANSLATION}.required_url`),
            validate: validateURL,
            onChange: onChangeLink,
          })}
          value={form.watch('link')}
          company={form.watch('company')}
          errorMessage={form.formState.errors.link?.message}
          placeholder={i18n.t(`${TRANSLATION}.url_placeholder`)}
        />
      )}

      <Actions>
        <SaveButton
          type={isFirstStep ? 'button' : 'submit'}
          onClick={isFirstStep ? onChangeStep : undefined}
        >
          {i18n.t(`${TRANSLATION}.${isFirstStep ? 'button' : 'submit'}`)}
        </SaveButton>
        <CancelButton type="button" onClick={onCancel}>
          {i18n.t(`${TRANSLATION}.cancel`)}
        </CancelButton>
      </Actions>
    </Container>
  )
}

export default QuickAdd
