import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import { Icon20 } from 'builder/components/Icon'
import { Typography, FontWeights } from 'builder/styles/typography'

const ContainerActiveState = css`
  ${Shadows.lightWithBorder.mid};

  z-index: 1;
  height: 100%;
  transition: 0.2s all;
`

const ContainerHoverState = css`
  &:hover {
    ${ContainerActiveState};
  }
`

export const SearchField = styled.input`
  ${Typography.Body};
  ${FontWeights.Regular};

  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;

  &::placeholder {
    color: ${Colors.Neutral30};
  }
  &:disabled {
    color: ${Colors.Neutral10};
  }
`

export const FieldContainer = styled.div<{
  isExpanded?: boolean
  isHoverStateDisabled?: boolean
}>`
  display: block;
  gap: 8px;
  flex-grow: 1;
  align-items: center;
  border-radius: ${props => (props.isExpanded ? '12px 12px 0px 0px' : '12px')};

  ${Media.Tablet`
    width: 100%;
    align-items: center;
  `}

  ${props => !props.isHoverStateDisabled && ContainerHoverState}

  ${props => props.isExpanded && ContainerActiveState}
`

export const Menu = styled.div<{ isEmpty: boolean }>`
  ${Shadows.lightWithBorder.mid};

  width: 100%;
  display: ${props => (props.isEmpty ? 'none' : 'block')};
  background-color: ${Colors.White};
  border-radius: 0 0 12px 12px;
  position: absolute;
  padding: 12px 8px;

  & .react-autosuggest__suggestions-list {
    width: 100%;
  }
`

export const InputWrapper = styled.div<{ isExpanded?: boolean }>`
  height: 64px;
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  padding-inline: 16px 8px;
  border-radius: ${props => (props.isExpanded ? '12px 12px 0px 0px' : '12px')};

  ${Media.Tablet`
    height: 48px;

    &:hover {
      background-color: ${Colors.Blue10};
    }
  `}
`

export const Item = styled.div<{ isHighlighted: boolean }>`
  padding: 12px 8px;
  display: flex;
  gap: 8px;
  align-items: center;

  ${props =>
    props.isHighlighted &&
    css`
      background-color: ${Colors.Blue10};
    `}
`

export const SectionHeader = styled.div`
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral50};
  text-transform: uppercase;
  letter-spacing: 1.8px;
  padding-left: 20px;
  margin-top: 16px;
  margin-bottom: 4px;
  background-color: ${Colors.White};
`

export const Clear = styled(Icon20.Close)`
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: color 0.2s ease-out;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
`
