import { FormEvent, useEffect, useState } from 'react'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import * as Cookies from 'js-cookie'

import { useI18n } from 'builder/hooks/useI18n'
import { ConfigScopesEnum } from 'builder/modules/init'
import { useConfig } from 'builder/hooks/useConfig'
import ModalOverlay from '../ModalOverlay'
import Button from '../Button'

import Icon24 from '../Icon'

import useWebsiteHost, { rioDomainList } from '../CIO-Dasboard-2.0/hooks/useWebsiteHost'
import {
  Collapse,
  CookiePanel,
  CookiePanelContainer,
  CookiePanelText,
  CookiePanelTitle,
  CookieSettingsModalButtonPanel,
  CookieSettingsModalContainer,
  CookieSettingsModalText,
  CookieSettingsModalTitle,
  DescriptionLink,
  ModalContainer,
  ModalContent,
} from './styles'
import { acceptCookies, addConsentStateUpdateToDatalayer, allAdditionalTypes } from './utils'
import CookieModalSetting from './CookieModalSetting'
import { CookieInformation } from './types'

const cookieTypes = [
  { value: 'essentialCookies', phrase: 'essential_cookies' },
  { value: 'analyticalCookies', phrase: 'analytical_cookies' },
  { value: 'marketingCookies', phrase: 'marketing_cookies' },
] as CookieInformation[]

type ModalSettingsOptions = {
  essentialCookies: true // has to be always true
  analyticalCookies: boolean
  marketingCookies: boolean
}

type CookieSettings = {
  showBanner: boolean
  showModal: boolean
}

const CookieV4 = () => {
  const { i18n } = useI18n()
  const [strictMode] = useState<boolean>(true)
  const config = useConfig(ConfigScopesEnum.country)
  const showCookieBanner = config?.showCookieBanner

  const [cookieSettings, setCookieSettings] = useState<CookieSettings>({
    showBanner: false,
    showModal: false,
  })
  const [modalSettings, setModalSettings] = useState<ModalSettingsOptions>({
    essentialCookies: true,
    analyticalCookies: false,
    marketingCookies: false,
  })

  const { getHost } = useWebsiteHost()
  const isRioDomain = rioDomainList.includes(getHost)

  // considering true for now as Sophie says v4 is fine
  // when the ab test is over lets remove the code that is not necessary

  const trackCookiePreferences = () => {
    trackInternalEvent('click_cookie_banner', {
      cta_text: 'Save',
      cookie_settings: 'Essential only',
    })

    if (modalSettings.analyticalCookies) {
      trackInternalEvent('click_cookie_banner', {
        cta_text: 'Save',
        cookie_settings: 'Analytical cookies',
      })
    }

    if (modalSettings.marketingCookies) {
      trackInternalEvent('click_cookie_banner', {
        cta_text: 'Save',
        cookie_settings: 'Marketing cookies',
      })
    }
  }

  const acceptAllCookies = () => {
    acceptCookies(allAdditionalTypes, strictMode)
    trackMarketingEvent('Cookie Consent', 'Accept All Cookies')
  }

  const handleClickAcceptAllButton = () => {
    acceptAllCookies()

    trackInternalEvent('click_cookie_banner', {
      cta_text: 'Accept all',
      cookie_settings: 'Accept all',
    })

    setCookieSettings({ showBanner: false, showModal: false })
  }

  const rejectAllCookies = () => {
    acceptCookies([], strictMode)
    trackMarketingEvent('Cookie Consent', 'Reject Cookies')
  }

  /* Modal */
  const handleClose = () =>
    setCookieSettings(currentStatus => ({ ...currentStatus, showModal: false }))

  const handleRejectAllCookies = () => {
    rejectAllCookies()

    trackInternalEvent('click_cookie_banner', {
      cta_text: 'Reject all',
      cookie_settings: 'Reject',
    })

    setCookieSettings({ showBanner: false, showModal: false })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const events = Object.entries(modalSettings)
      .filter(setting => setting[1])
      .map(setting => setting[0])

    acceptCookies(events, strictMode)

    trackCookiePreferences()
    setCookieSettings({ showBanner: false, showModal: false })
  }

  useEffect(() => {
    if (!isRioDomain) return
    const events = Cookies.getJSON('cookie_consent')
    if (events) {
      // Process the legacy cookie value ("true")
      if (events === true) return acceptCookies(allAdditionalTypes, false)

      // others
      acceptCookies(events, false)
    } else {
      addConsentStateUpdateToDatalayer(['essentialCookies'], true)
      setCookieSettings(currentCookieSettings => ({ ...currentCookieSettings, showBanner: true }))
    }
  }, [])

  if (!isRioDomain || !showCookieBanner) return <></>

  return (
    <>
      {cookieSettings.showBanner && (
        <CookiePanel>
          <CookiePanelContainer>
            <CookiePanelTitle>{i18n.t('builder.cookie_panel_v4.title')}</CookiePanelTitle>
            <CookiePanelText>
              {i18n.t('builder.cookie_panel_v4.text_1')}
              {i18n.t('builder.cookie_panel_v4.text_2')}{' '}
              <DescriptionLink onClick={handleRejectAllCookies}>
                {` ${i18n.t('builder.cookie_panel_v4.reject_link')} `}
              </DescriptionLink>{' '}
              {i18n.t('builder.cookie_panel_v4.text_3')}{' '}
              <a
                href={`mailto:privacy@${window.location.hostname}`}
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  trackInternalEvent('click_cookie_banner', { cta_text: 'Send to email' })
                }
              >{`privacy@${window.location.hostname}`}</a>
              {'. '}
              {i18n.t('builder.cookie_panel_v4.text_4')}
              <a
                href="/cookies"
                target="_blank"
                onClick={() =>
                  trackInternalEvent('click_cookie_banner', { cta_text: 'Cookie Policy' })
                }
              >
                {` ${i18n.t('builder.cookie_panel_v4.cookie_information')} `}
              </a>
              {i18n.t('builder.cookie_panel_v4.text_5')}
            </CookiePanelText>
            <CookieSettingsModalButtonPanel>
              <Button
                className="button button.button--small cookie-panel__accept"
                onClick={handleClickAcceptAllButton}
                theme="confirmative"
              >
                {i18n.t('builder.cookie_panel_v4.accept_all_cookies')}
              </Button>
              <Button
                className="button button--small cookie-panel__settings"
                theme="confirmative"
                onClick={() => {
                  trackInternalEvent('open_cookie_banner_settings')
                  setCookieSettings(currentStatus => ({ ...currentStatus, showModal: true }))
                }}
              >
                {i18n.t('builder.cookie_panel_v4.preferences')}
              </Button>
            </CookieSettingsModalButtonPanel>
          </CookiePanelContainer>
        </CookiePanel>
      )}
      {cookieSettings.showModal && (
        <ModalOverlay
          onClose={() =>
            setCookieSettings(currentStatus => ({ ...currentStatus, showModal: false }))
          }
          onClick={handleClose}
        >
          <ModalContainer>
            <ModalContent>
              <Icon24.Close onClick={handleClose} />
            </ModalContent>
            <CookieSettingsModalContainer onSubmit={handleSubmit}>
              <CookieSettingsModalTitle>
                {i18n.t('builder.cookie_panel_v4.title')}
              </CookieSettingsModalTitle>

              <CookieSettingsModalText>
                <p>
                  {i18n.t('builder.cookie_settings.description')}{' '}
                  <a href={`${window.location.host}/cookies`} target="_blank" rel="noreferrer">
                    {i18n.t('builder.cookie_settings.cookie_policy')}
                  </a>
                </p>
              </CookieSettingsModalText>
              <CookieSettingsModalButtonPanel>
                <Button type="button" size="small">
                  {i18n.t('builder.cookie_panel_v4.accept_all_cookies')}
                </Button>
              </CookieSettingsModalButtonPanel>
              <CookieSettingsModalText>
                {i18n.t('builder.cookie_settings.settings_teaser')}
              </CookieSettingsModalText>
              <Collapse>
                {cookieTypes.map((cookie, index) => {
                  return (
                    <CookieModalSetting
                      key={index}
                      handleCookieToggle={() => {
                        const currentCookieValue = modalSettings[cookie.value]
                        setModalSettings(current => ({
                          ...current,
                          [cookie.value]: !currentCookieValue,
                        }))
                      }}
                      cookie={cookie}
                    />
                  )
                })}
              </Collapse>
              <CookieSettingsModalButtonPanel>
                <Button size="small" type="submit">
                  {i18n.t('builder.cookie_settings.save')}
                </Button>
                <Button onClick={handleRejectAllCookies} size="small" type="button">
                  {i18n.t('builder.cookie_settings.reject_all')}
                </Button>
              </CookieSettingsModalButtonPanel>
            </CookieSettingsModalContainer>
          </ModalContainer>
        </ModalOverlay>
      )}
    </>
  )
}

export default CookieV4
