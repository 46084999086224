import { ReactNode } from 'react'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientConfig, QueryClientProvider } from '@tanstack/react-query'
import ErrorBoundary from 'builder/components/ErrorBoundary'
import { RichTextAreaGlobalStyle } from 'builder/components/RichTextArea'
import { store } from 'builder/modules/store'
import { _history } from 'builder/modules/navigate'
import { ConfigScopesEnum } from 'builder/modules/init'
import { MagicContainer } from 'builder/components/MagicModal'
import { I18nProvider } from 'builder/context/I18nContext'
import { ConfigScope } from 'builder/components/ConfigScope'
import { APP_BASE_PATH } from 'builder/modules/constants'
import { ScrollToTop } from 'builder/components/ScrollToTop'
import { GuestResumeContextProvider } from 'builder/context/GuestResumeContext'
import { SnackBarContextProvider } from '../SnackBarV2/SnackBarContext'

export const DEFAULT_QUERY_CLIENT_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnReconnect: false,
      refetchOnWindowFocus: true,
      retry: false,
      useErrorBoundary: true,
    },
  },
}

export const queryClient = new QueryClient(DEFAULT_QUERY_CLIENT_CONFIG)

type ProvidersProps = {
  children: ReactNode
}

/** Creates all of the application wrappers (error boundaries, context providers, etc) */
const Providers = ({ children }: ProvidersProps) => (
  <ErrorBoundary>
    <Provider store={store}>
      <HistoryRouter history={_history} basename={APP_BASE_PATH}>
        <QueryClientProvider client={queryClient}>
          <ConfigScope scope={ConfigScopesEnum.country}>
            <GuestResumeContextProvider>
              <I18nProvider>
                <SnackBarContextProvider>
                  <MagicContainer>
                    <RichTextAreaGlobalStyle />
                    <ScrollToTop>{children}</ScrollToTop>
                  </MagicContainer>
                </SnackBarContextProvider>
              </I18nProvider>
            </GuestResumeContextProvider>
          </ConfigScope>
        </QueryClientProvider>
      </HistoryRouter>
    </Provider>
  </ErrorBoundary>
)

export default Providers
