import { Navigate, Route, Routes } from 'react-router-dom'
import { useConfig } from 'builder/hooks/useConfig'
import { ConfigScopesEnum } from 'builder/modules/init'
import { CustomInterviewView } from './components/CustomInterviewView/CustomInterviewView'

import { QuestionsLibraryView } from './components/QuestionsLibraryView/QuestionsLibraryView'
import { FeedbackViewV2 } from './components/FeedbackViewV2/FeedbackViewV2'
import { InterviewStartView } from './components/Start/InterviewStartView/InterviewStartView'
import { Start } from './components/Start/Start'
import { CheckYourInboxView } from './components/Start/CheckYourInboxView/CheckYourInboxView'
import { ReadyToStartView } from './components/Start/ReadyToStartView/ReadyToStartView'
import { InterviewDashboardView } from './components/DashboardView/DashboardView'
import { InterviewPlayerViewV2 } from './components/InterviewPlayerView/InterviewPlayerView'

export const InterviewStartRoutesView = () => {
  const config = useConfig(ConfigScopesEnum.signUp)
  if (!config?.features.interviewSchool) {
    return <Navigate to="/" />
  }
  return (
    <Routes>
      <Route element={<Start />}>
        {/* start pages routes */}
        <Route path="" element={<InterviewStartView />} />
        <Route path="check-your-inbox" element={<CheckYourInboxView />} />
        <Route path="ready" element={<ReadyToStartView />} />
        <Route path=":behaviour" element={<InterviewStartView />} />
      </Route>
    </Routes>
  )
}

export const InterviewPreparationRoutesView = () => {
  return (
    <Routes>
      <Route path="" element={<InterviewStartView />} />
      <Route path="ready" element={<ReadyToStartView />} />
      <Route path="interview-player" element={<InterviewPlayerViewV2 />} />
      <Route path="dashboard" element={<InterviewDashboardView />} />
      <Route path="questions-library" element={<QuestionsLibraryView />} />
      <Route path="feedback" element={<FeedbackViewV2 />} />
      <Route path="custom-interview" element={<CustomInterviewView />} />
      <Route path=":behaviour" element={<InterviewStartView />} />
    </Routes>
  )
}
