import styled from 'styled-components'
import Sizes from 'builder/styles/sizes'
import Media from 'builder/styles/media'
import Shadows from 'builder/styles/shadows'
import Colors from 'builder/styles/colors'
import { TOP_POSITION_SEARCH_BAR, Z_INDEX_SEARCH_BAR } from '../styles'
import { AUTOSUGGEST_FIELD_HEIGHT } from '../../AutoSuggestField/styles'

export const Container = styled.div`
  top: ${TOP_POSITION_SEARCH_BAR}px;
  z-index: ${Z_INDEX_SEARCH_BAR};
  position: fixed;
  display: flex;
  align-items: center;
  right: 0;
  left: 0;
  background: ${Colors.White};
  border-radius: ${Sizes['2XS']};
  padding: ${Sizes['2XS']} ${Sizes.S};
  margin: 0 20px;
  ${Media.AboveTablet`
    display: none;
  `}

  ${Media.Tablet`
    height: 64px;
    left: 80px;
  `} 

  ${Media.Phone`
    height: 48px;
    left: -5px;
    right: -5px;
  `} 

  ${Shadows.lightWithBorder.low};
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${Sizes['2XS']};
`
export const FakeInput = styled.div`
  border: none;
  height: ${AUTOSUGGEST_FIELD_HEIGHT}px;
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
`

export const RejectIconWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  right: 0;
  height: 100%;
  align-items: center;

  & svg {
    cursor: pointer;
    fill: ${Colors.Neutral30};
  }
`
