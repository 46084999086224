import { config, useTransition } from '@react-spring/web'
import { ButtonSize, ButtonTheme, ButtonV2 } from 'builder/components/Button'
import { useI18n } from 'builder/hooks/useI18n'
import { BannerTransitionProps, ViewProps } from '../../types'
import {
  BannerContainer,
  Banner,
  Content,
  Header,
  Title,
  Description,
  Footer,
  ImageContainer,
  Image,
  RightFade,
  BottomFade,
  TailoringIcon,
} from './styles'
import sitePreviewSrc from './images/site-preview.png'

const TRANSLATION = 'builder.resume_editor.keywords.improve_resume_panel.tailor_resume_banner_new'

const defaultTransitionFrom: BannerTransitionProps = {
  maxHeight: 0,
  opacity: 0,
  marginTop: 0,
}

const defaultTransitionTo: BannerTransitionProps = {
  maxHeight: 300,
  opacity: 1,
  marginTop: 12,
}

export const TailorResumeBannerView2 = (props: ViewProps) => {
  const { isVisible, onBannerClick, onNotNowClick, transitionProps } = props
  const { i18n } = useI18n()
  const transition = useTransition(isVisible, {
    config: config.default,
    from: defaultTransitionFrom,
    enter: { ...defaultTransitionTo, ...transitionProps },
    leave: defaultTransitionFrom,
  })

  return transition((style, item) =>
    item ? (
      <BannerContainer style={style}>
        <Banner>
          <Content>
            <Header>
              <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
              <Description>{i18n.t(`${TRANSLATION}.description`)}</Description>
            </Header>
            <Footer>
              <ButtonV2
                onClick={onBannerClick}
                theme={ButtonTheme.alternative}
                size={ButtonSize.small}
                leadingIcon={<TailoringIcon />}
              >
                {i18n.t(`${TRANSLATION}.button_text`)}
              </ButtonV2>
              {onNotNowClick && (
                <ButtonV2 onClick={onNotNowClick} theme={ButtonTheme.ghost} size={ButtonSize.small}>
                  {i18n.t(`${TRANSLATION}.not_now_button_text`)}
                </ButtonV2>
              )}
            </Footer>
          </Content>
          <ImageContainer>
            <Image src={sitePreviewSrc} alt="Image preview of job-search site" />
            <BottomFade />
            <RightFade />
          </ImageContainer>
        </Banner>
      </BannerContainer>
    ) : null,
  )
}
