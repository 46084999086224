import { Link, useNavigate } from 'react-router-dom'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import Spinner from 'builder/components/Spinner'
import {
  CareerInsightsProcessingStatus,
  CareerInsightsPageType,
  actions,
} from 'builder/modules/careerProfile'
import Button, { ButtonTheme } from 'builder/components/Button'
import {
  ModalTemplate,
  careerInsightsRequiredImageUrl,
  careerInsightsPendingImageUrl,
  coverLetterPreviewImageUrl,
  professionalPitchPreviewImageUrl,
  careerInsightsReadyImageUrl,
  careerInsightsFailedImageUrl,
} from 'builder/components/CareerProfile'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { ETrailModalLocalKeys } from 'builder/components/PremiumRemindModal/types'
import { useCareerInsightsStatus } from '../../hooks/useCareerInsightsStatus'
import { Image, Subtitle } from './styles'

export const CareerInsightsStatusModal: FC<{
  type: CareerInsightsPageType
}> = ({ type }) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const careerInsightsStatus = useCareerInsightsStatus()
  const isProcessing = careerInsightsStatus === CareerInsightsProcessingStatus.pending
  const isSuccess = careerInsightsStatus === CareerInsightsProcessingStatus.success
  const isFailure =
    careerInsightsStatus === CareerInsightsProcessingStatus.failure ||
    careerInsightsStatus === CareerInsightsProcessingStatus.retry

  useEffectOnMount(() => {
    let status = 'promo'
    if (isProcessing) {
      status = 'in progress'
    } else if (isFailure) {
      status = 'error'
    } else if (isSuccess) {
      status = 'ready'
    }
    trackInternalEvent('assessment_modal_view', { status })
  })

  const onClose = () => {
    if (isSuccess || isFailure) {
      dispatch(actions.setSeenTemporalModal('insights-result'))
      localStorage.removeItem(ETrailModalLocalKeys.lastShownTrialModal)
    }

    dispatch(actions.setCareerInsightsStatusModalType(null))
  }

  const onRetakeAssessment = () => {
    onClose()
    dispatch(actions.setInitialChatState())
    navigate('/career-profile/chat')
  }

  const onRetry = () => {
    dispatch(actions.postChatbotAnswers())
    onClose()
  }

  // default: insights
  let header = careerInsightsRequiredImageUrl
  let title: JSX.Element | string = i18n.t('builder.career_profile.status_modal.insights.title')
  let subtitle = i18n.t('builder.career_profile.status_modal.insights.subtitle')
  let buttons: JSX.Element | undefined = (
    <Link to="/career-profile/chat">
      <Button theme={ButtonTheme.alternative} onClick={onClose}>
        {i18n.t('builder.career_profile.status_modal.start_now')}{' '}
      </Button>
    </Link>
  )

  if (type === 'cover-letter') {
    header = coverLetterPreviewImageUrl

    title = i18n.t('builder.career_profile.status_modal.cover_letter.title')
    subtitle = i18n.t('builder.career_profile.status_modal.cover_letter.subtitle')
  }

  if (type === 'pitch') {
    header = professionalPitchPreviewImageUrl
    title = i18n.t('builder.career_profile.status_modal.pitch.title')
    subtitle = i18n.t('builder.career_profile.status_modal.pitch.subtitle')
  }

  if (isProcessing) {
    if (type === 'insights') {
      header = careerInsightsPendingImageUrl
    }

    title = (
      <>
        <Spinner />
        {i18n.t('builder.career_profile.status_modal.processing.title')}
      </>
    )
    subtitle = i18n.t('builder.career_profile.status_modal.processing.subtitle')
    buttons = undefined
  }

  if (isSuccess) {
    header = careerInsightsReadyImageUrl
    title = i18n.t('builder.career_profile.status_modal.success.title')
    subtitle = i18n.t('builder.career_profile.status_modal.success.subtitle')
    buttons = (
      <Link to="/career-profile/insights">
        <Button theme={ButtonTheme.alternative} onClick={onClose}>
          {i18n.t('builder.career_profile.status_modal.view_results')}
        </Button>
      </Link>
    )
  }

  if (isFailure) {
    header = careerInsightsFailedImageUrl
    title = i18n.t('builder.career_profile.status_modal.failure.title')
    subtitle = i18n.t('builder.career_profile.status_modal.failure.subtitle')
    buttons = (
      <>
        <Button theme={ButtonTheme.ghost} onClick={onRetakeAssessment}>
          {i18n.t('builder.career_profile.status_modal.retake_assessment')}
        </Button>
        <Button theme={ButtonTheme.alternative} onClick={onRetry}>
          {i18n.t('builder.career_profile.status_modal.try_again')}
        </Button>
      </>
    )
  }

  return (
    <ModalTemplate
      onClose={onClose}
      header={<Image src={header} />}
      title={title}
      description={<Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />}
      buttons={buttons}
    />
  )
}
