import { useContext, HTMLAttributes } from 'react'
import * as React from 'react'
import { WizardContext } from 'builder/components/Wizard'
import { SectionHeaderContainer } from './styles'
import { WithFoldableSections } from './types'

type Props = HTMLAttributes<HTMLDivElement> & {
  isOpened?: boolean
} & WithFoldableSections

export const SectionHeader: React.FC<Props> = props => {
  const { children, isOpened, ...rest } = props
  const wizard = useContext(WizardContext)
  const compact = typeof wizard === 'undefined'

  return (
    <SectionHeaderContainer compact={compact} $isOpened={isOpened} {...rest}>
      {children}
    </SectionHeaderContainer>
  )
}
