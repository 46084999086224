import { useMemo, useState } from 'react'
import { stringifyUrl } from 'query-string'
import { useLocation } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import { useGetPlanPrice } from 'builder/hooks/useGetPlanPrice'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import { NavBarMobile } from 'builder/features/navigation/components/NavBarMobile'
import { calculateDaysDifference } from '../Navigation/components/PremiumTracker/utils'
import { useAutoApplyCreditsModal } from './hooks/useAutoApplyCreditsModal'
import { CreditsList } from './CreditsList/CreditsList'
import { BillingAction } from './BillingAction/BillingAction'
import * as Styles from './styles'
import { Plans, TRANSLATION } from './constants'

export const AutoApplyCreditsModal = () => {
  const user = useUser()
  const { i18n } = useI18n()
  const location = useLocation()
  const { isPhone } = useMediaQueries()
  const { isOpen, handleModalClose } = useAutoApplyCreditsModal()
  const [selectedPlan, setSelectedPlan] = useState(Plans.CREDITS_20)

  const daysLeftPremium = useMemo(() => {
    return user?.billingInfo?.premiumEndsAt
      ? calculateDaysDifference(user?.billingInfo?.premiumEndsAt) + 1
      : 0
  }, [user?.billingInfo.premiumEndsAt])

  const getPlanName = (selectedPlan: Plans) => {
    const isPaidUser = !user?.isTrialUser && user?.isPaidUser && !user?.isTopResumeUser
    const plans = {
      [Plans.CREDITS_10]: isPaidUser ? '10_credits_autoapply_promo' : '10_credits_autoapply',
      [Plans.CREDITS_20]: isPaidUser ? '20_credits_autoapply_promo' : '20_credits_autoapply',
      [Plans.CREDITS_50]: isPaidUser ? '50_credits_autoapply_promo' : '50_credits_autoapply',
    }
    return plans[selectedPlan]
  }

  const { recurringAmount, planAmount, isLoading } = useGetPlanPrice({
    plan: getPlanName(selectedPlan),
    fetchPrice: isOpen,
  })

  const redirectToPayments = () => {
    if (isLoading) return
    trackInternalEvent('auto_apply_plan_credits_exceeded')
    trackInternalEvent('show_upsell_payment_page')

    const isFromDashboard = location.pathname === '/'
    const path = isFromDashboard
      ? '/app'
      : '/app/job-search?view=recommendation&show_credit_modal=true'

    const plansPageUrl = stringifyUrl({
      url: '/app/billing/payment',
      query: { plan: getPlanName(selectedPlan), on_success_redirect: path },
    })

    window.location.assign(plansPageUrl)
  }

  const onSelectPlan = (plan: Plans) => {
    setSelectedPlan(plan)
    trackInternalEvent('aa_upsell_plans_page_pick_plan', { addon_name: plan })
  }

  const onCloseModal = () => {
    setSelectedPlan(Plans.CREDITS_20)
    handleModalClose()
  }

  const features = [
    i18n.t(`${TRANSLATION}.features.job_applications`, { credits: selectedPlan.split('_')[0] }),
    i18n.t(`${TRANSLATION}.features.weekly_jobs_shortlist`),
    i18n.t(`${TRANSLATION}.features.send_resume`),
    i18n.t(`${TRANSLATION}.features.interview_preparation`),
    daysLeftPremium > 0
      ? i18n.t(`${TRANSLATION}.features.do_not_lose_access`, { count: daysLeftPremium })
      : undefined,
  ].filter(feat => feat)

  if (!isOpen) return null

  return (
    <Styles.Overlay>
      <Styles.RootModalContainer>
        {isPhone && (
          <Styles.NavBarContainer>
            <NavBarMobile hideProfile />
            <Styles.CloseButtonContainer>
              <Styles.Close onClick={onCloseModal}>
                <Styles.CloseIcon />
              </Styles.Close>
            </Styles.CloseButtonContainer>
          </Styles.NavBarContainer>
        )}

        <Styles.ModalSection>
          {!isPhone && (
            <Styles.CloseButtonContainer>
              <Styles.Close onClick={onCloseModal}>
                <Styles.CloseIcon />
              </Styles.Close>
            </Styles.CloseButtonContainer>
          )}

          <Styles.UpsellHeaderContainer>
            <Styles.UpsellTitle>{i18n.t(`${TRANSLATION}.title`)}</Styles.UpsellTitle>
            <Styles.UpsellSubTitle>{i18n.t(`${TRANSLATION}.sub_title`)}</Styles.UpsellSubTitle>
          </Styles.UpsellHeaderContainer>

          <CreditsList selectedPlan={selectedPlan} onSelectPlan={onSelectPlan} />

          <Styles.UpsellFeaturesContainer>
            <Styles.FeaturesHeader>
              {i18n.t(`${TRANSLATION}.features_header`)}
            </Styles.FeaturesHeader>
            <Styles.UpsellFeatures>
              {features.map(feature => (
                <Styles.UpsellFeature key={feature}>
                  <Styles.IconContainer>
                    <Styles.TickIcon />
                  </Styles.IconContainer>
                  {feature}
                </Styles.UpsellFeature>
              ))}
            </Styles.UpsellFeatures>
          </Styles.UpsellFeaturesContainer>

          <BillingAction
            amount={
              user?.scopes.chargebeeCustomer && user.scopes.kind !== 'TR'
                ? planAmount()
                : recurringAmount()
            }
            isPriceLoading={isLoading}
            onClickUpgrade={redirectToPayments}
          />
        </Styles.ModalSection>

        {isPhone && (
          <Styles.UpgradeContainer>
            <Styles.UpgradeButton onClick={redirectToPayments}>
              {i18n.t(`${TRANSLATION}.cta_label`)}
            </Styles.UpgradeButton>
          </Styles.UpgradeContainer>
        )}
      </Styles.RootModalContainer>
    </Styles.Overlay>
  )
}
