import { useTransition } from '@react-spring/web'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Icon20 } from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, actions } from 'builder/modules/resumeEditor'
import { TooltipV2 } from 'builder/components/Tooltip'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Container, Text } from './styles'

const TRANSLATION = 'builder.resume_editor.keywords.improve_resume_panel.banner_badge'

export const TailorResumeBadge = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const isAnimating = useTypedSelector(selectors.isImproveResumeBadgeAnimating)
  const transition = useTransition(isAnimating, {
    from: { opacity: 0, transform: 'translateX(100%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: 'translateX(100%)' },
  })
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const { isPhone } = useMediaQueries()

  useEffect(() => {
    if (isAnimating) {
      const timeouts = [
        setTimeout(() => {
          setIsTooltipVisible(true)
        }, 600),

        setTimeout(() => {
          setIsTooltipVisible(false)
        }, 2600),

        setTimeout(() => {
          dispatch(actions.setIsImproveResumeBadgeAnimating({ status: false }))
        }, 3200),
      ]
      return () => timeouts.forEach(item => clearTimeout(item))
    }
  }, [isAnimating, dispatch])

  return transition((style, item) =>
    item ? (
      <TooltipV2
        content={i18n.t(`${TRANSLATION}.tooltip`)}
        visible={isTooltipVisible}
        placement="bottom"
      >
        <Container style={style}>
          {!isPhone && <Icon20.ArrowTarget2 />}
          <Text>{i18n.t(isPhone ? `${TRANSLATION}.text_mobile` : `${TRANSLATION}.text`)}</Text>
        </Container>
      </TooltipV2>
    ) : null,
  )
}
