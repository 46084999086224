import { InterviewPlayerBadge } from 'builder/modules/interview/types'
import { useI18n } from 'builder/hooks/useI18n'
import Colors from 'builder/styles/colors'
import { CustomBadge, Emoji } from './styles'

import PlaySrc from './asset/play-emoji.png'
import LeafFlutteringInWindSrc from './asset/leaf-fluttering-in-wind-emoji.png'
import Fire from './asset/fire-emoji.png'

export const TRANSLATION_PREFIX = 'builder.interview_prep_view.interview_player_v2.badge'

const HitPlayToContinue = () => {
  const { i18n } = useI18n()

  return (
    <CustomBadge variant="blue" size="medium" $color={Colors.Neutral80}>
      <Emoji src={PlaySrc} /> {i18n.t(`${TRANSLATION_PREFIX}.hit_play_to_continue`)}
    </CustomBadge>
  )
}

const GreatTimingBadge = () => {
  const { i18n } = useI18n()

  return (
    <CustomBadge variant="green" size="medium">
      <Emoji src={LeafFlutteringInWindSrc} /> {i18n.t(`${TRANSLATION_PREFIX}.great_timing`)}
    </CustomBadge>
  )
}

const HurryUpBadge = () => {
  const { i18n } = useI18n()
  return (
    <CustomBadge variant="orange" size="medium">
      <Emoji src={Fire} /> {i18n.t(`${TRANSLATION_PREFIX}.hurry_up`)}
    </CustomBadge>
  )
}

interface Props {
  badge: InterviewPlayerBadge
}

export const Badge = ({ badge }: Props) => {
  switch (badge) {
    case InterviewPlayerBadge.hit_play_to_continue:
      return <HitPlayToContinue />
    case InterviewPlayerBadge.great_timing:
      return <GreatTimingBadge />

    case InterviewPlayerBadge.hurry_up:
      return <HurryUpBadge />

    default:
      return null
  }
}
