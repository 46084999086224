import styled, { css } from 'styled-components'
import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Icon from 'builder/components/Icon'
import { foldableSectionMixin, WithFoldableSections } from 'builder/components/Section'

export const Container = styled.div<WithFoldableSections>(props => {
  return css`
    position: relative;

    ${props.isFoldableSectionsEnabled
      ? foldableSectionMixin
      : css`
          margin-top: 32px;
          margin-bottom: 40px;
        `}
  `
})

export const ShowButton = styled.div`
  ${Typography.Caption};
  font-weight: 600;
  color: ${Colors.Blue50};
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: ${Colors.Blue70};
  }
`

export const ShowButtonText = styled.span`
  display: inline-block;
  vertical-align: middle;
`

export const ShowButtonIcon = styled(Icon.ToggleArrow)`
  display: inline-block;
  vertical-align: middle;
  transform: rotate(90deg);

  &[data-open='true'] {
    transform: translateY(2px) rotate(-90deg);
  }
`

export const AdditionalFields = styled.div`
  padding-bottom: 24px;
`
