import { trackInternalEvent } from '@rio/tracking'
import { useCallback, useEffect, useState } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { selectors } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { Job } from 'builder/components/FindJob/JobSearchResult/types'
import Loader from '../Loader'
import JobSlider from './components/JobSlider'
import RecommendedJobCard from './components/RecommendedJobCard'
import { Container, Content, ExploreIcon, ExploreLink, Header, Heading, Link } from './styles'

const RecommendedJobs = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [isSliderActive, setIsSliderActive] = useState(true)
  const { isDesktop, isLaptop, isTablet, isPhone } = useMediaQueries()

  const location = useTypedSelector(selectors.location)
  const selectedJobTitle = useTypedSelector(selectors.selectedJobTitle)
  const isJobCardLoading = useTypedSelector(selectors.isJobCardLoading)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const addToPathJobTitle = useTypedSelector(selectors.addToPathJobTitle)
  const recommendedJobCard = useTypedSelector(selectors.recommendedJobCard)
  const exploreCareersLocation = useTypedSelector(selectors.exploreCareersLocation)

  const jobCard =
    recommendedJobCard?.length > 12 ? recommendedJobCard.slice(0, 12) : recommendedJobCard

  const handleWindowSize = () => {
    setWidth(window.innerWidth)
  }

  const getSliderStatus = useCallback(() => {
    let isActive = false
    if ((isDesktop && jobCard.length > 4) || (isLaptop && jobCard.length > 4)) {
      isActive = true
    } else if (!isPhone && isTablet && jobCard.length > 3) {
      isActive = true
    } else if (isPhone && jobCard.length > 1) {
      isActive = true
    } else {
      isActive = false
    }

    return isActive
  }, [isDesktop, isLaptop, isPhone, isTablet, jobCard])

  useEffect(() => {
    // Event listener to track window size
    window.addEventListener('resize', handleWindowSize)

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleWindowSize)
    }
  }, [])

  useEffect(() => {
    const isActive = getSliderStatus()

    setIsSliderActive(isActive)
  }, [getSliderStatus, width])

  const handleClick = (jobTitle?: string, isExploreMore?: boolean) => {
    const title = isExploreCareers ? addToPathJobTitle : selectedJobTitle
    const locationParam = isExploreCareers ? exploreCareersLocation : location
    const jobRole = jobTitle || title
    let url
    if (locationParam) {
      url = `/app/job-search?query=${jobRole}&location=${locationParam}&view=search`
    } else {
      url = `/app/job-search?query=${jobRole}&view=search`
    }
    window.open(url, '_blank')
    const eventName = isExploreMore ? 'click_explore_more_jobs' : 'click_open_job_posting'

    trackInternalEvent(eventName, {
      job_title: jobRole,
      label: isExploreCareers ? 'explore_careers' : 'career_path2',
    })
  }

  const handleClickOnCard = (card: Job) => {
    // eslint-disable-next-line max-len
    const url = `/app/job-search?query=${card.job_title}&location_id=${card.location_id}&location=${card.location}&view=search`
    window.open(url, '_blank')
  }

  const renderJobCards = () => {
    if (isSliderActive) {
      return (
        <JobSlider
          cards={jobCard}
          isSliderActive={isSliderActive}
          handleClick={handleClickOnCard}
        />
      )
    }
    return jobCard?.map((card, index: number) => {
      return (
        <RecommendedJobCard
          key={index}
          {...{ card, isSliderActive, handleClick: () => handleClickOnCard(card) }}
        />
      )
    })
  }

  if (isJobCardLoading) {
    return (
      <Container>
        <Header>
          <Heading>Recommended Jobs</Heading>
        </Header>
        <Loader />
      </Container>
    )
  }

  if (!jobCard.length) {
    return null
  }

  return (
    <Container>
      <Header>
        <Heading>Recommended Jobs</Heading>
        <ExploreLink onClick={() => handleClick(undefined, true)}>
          <Link>Explore More</Link> <ExploreIcon />
        </ExploreLink>
      </Header>
      {isJobCardLoading ? <Loader /> : <Content>{renderJobCards()}</Content>}
    </Container>
  )
}

export default RecommendedJobs
