import { FC, memo, useMemo } from 'react'
import {
  ResumeActivity,
  ResumeContentCard,
  ResumeCourse,
  ResumeCustomSectionCard,
  ResumeEducation,
  ResumeHobby,
  ResumeLanguage,
  ResumeReference,
  ResumeSkill,
  ResumeSocialProfile,
} from 'packages/types'
import { TextField, TextAreaField } from 'builder/components/TextField'
import RichTextArea from 'builder/components/RichTextArea'
import Select from 'builder/components/Select'
import { SectionNames } from 'builder/modules/resumeEditor'
import { LevelSelect } from 'builder/components/LevelSelect'
import DateRangePicker from 'builder/components/DateRangePicker'
import { AsyncAutosuggest, createSuggestionsApiFetcher } from 'builder/components/AsyncAutosuggest'
import { formatDateRange } from 'builder/utils/formatDateRange'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { displayFieldPerTemplateConfig } from '../DocumentEditor/ATSResumeEditor/builderSectionsConfig'
import { TemplateName } from '../Helper/constants'
import { Base } from './Base'
import { WorkExperienceCard as WorkExperience } from './WorkExperienceCard'
import { CardContentWrapper, Row, Field } from './styles'
import { CardComponentProps } from './types'
import { useCardHandlers } from './hooks/useCardHandlers'
import { useCardTitle } from './hooks/useCardTitle'

const Education = memo((props: CardComponentProps<ResumeEducation>) => {
  const { item, autoFocus, locale, template } = props
  const { i18n } = useI18n()
  const { title } = useCardTitle(item.degree, item.school)
  const { updateSimpleField, updateDescription, updateDateRange } = useCardHandlers(props)
  const titleDates = useMemo(() => formatDateRange(item), [item])
  const isAthensTemplate = template === TemplateName.Athens
  const isPragueTemplate = template === TemplateName.Prague
  const showDescriptionField = displayFieldPerTemplateConfig(
    template,
    SectionNames.educations,
    'description',
  )

  return (
    <Base {...props} title={title} subtitle={titleDates}>
      <CardContentWrapper>
        <Row>
          <Field>
            <AsyncAutosuggest
              name="school"
              fetchItems={createSuggestionsApiFetcher('university', { limit: 4, locale })}
              value={item.school || ''}
              onChange={updateSimpleField}
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  autoFocus={autoFocus}
                  label={i18n.t('builder.resume_editor.educations_card.school')}
                />
              )}
            </AsyncAutosuggest>
          </Field>

          <Field>
            <TextField
              label={i18n.t('builder.resume_editor.educations_card.degree')}
              name="degree"
              onChange={updateSimpleField}
              value={item.degree || ''}
            />
          </Field>
        </Row>

        <Row>
          <Field>
            <DateRangePicker
              value={item}
              onChange={updateDateRange}
              currentlyLabel={i18n.t('builder.date_range_picker.currently.study')}
            />
          </Field>
          <Field>
            <AsyncAutosuggest
              highlightedQuery
              name="city"
              value={item.city || ''}
              onChange={updateSimpleField}
              fetchItems={createSuggestionsApiFetcher('city', { locale })}
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  label={
                    isAthensTemplate || isPragueTemplate
                      ? i18n.t('builder.resume_editor.educations_card.location')
                      : i18n.t('builder.resume_editor.educations_card.city')
                  }
                />
              )}
            </AsyncAutosuggest>
          </Field>
        </Row>

        {showDescriptionField && (
          <Row>
            <Field fullwidth>
              <RichTextArea
                label={i18n.t('builder.resume_editor.educations_card.description')}
                placeholder={i18n.t(
                  'builder.resume_editor.educations_card.description_placeholder',
                )}
                value={item.description || ''}
                onChange={updateDescription}
                locale={locale}
              />
            </Field>
          </Row>
        )}
      </CardContentWrapper>
    </Base>
  )
})

const Activity = memo((props: CardComponentProps<ResumeActivity>) => {
  const { item, autoFocus, locale, template } = props
  const { i18n } = useI18n()
  const { updateSimpleField, updateDescription, updateDateRange } = useCardHandlers(props)
  const { title } = useCardTitle(item.title, item.employer)
  const titleDates = useMemo(() => formatDateRange(item), [item])
  const isAthensTemplate = template === TemplateName.Athens
  const isPragueTemplate = template === TemplateName.Prague
  const showDescriptionField = displayFieldPerTemplateConfig(
    template,
    SectionNames.activities,
    'description',
  )
  const showCityField = displayFieldPerTemplateConfig(template, SectionNames.activities, 'city')

  return (
    <Base {...props} title={title} subtitle={titleDates}>
      <CardContentWrapper>
        <Row>
          <Field>
            <TextField
              autoFocus={autoFocus}
              label={
                isAthensTemplate || isPragueTemplate
                  ? i18n.t('builder.resume_editor.activities_card.license_name')
                  : i18n.t('builder.resume_editor.activities_card.title')
              }
              name="title"
              onChange={updateSimpleField}
              value={item.title || ''}
            />
          </Field>

          <Field>
            <TextField
              label={
                isAthensTemplate || isPragueTemplate
                  ? i18n.t('builder.resume_editor.activities_card.issuer')
                  : i18n.t('builder.resume_editor.activities_card.employer')
              }
              name="employer"
              onChange={updateSimpleField}
              value={item.employer || ''}
            />
          </Field>
        </Row>

        <Row>
          <Field>
            <DateRangePicker value={item} onChange={updateDateRange} />
          </Field>
          {showCityField && (
            <Field>
              <AsyncAutosuggest
                highlightedQuery
                name="city"
                value={item.city || ''}
                onChange={updateSimpleField}
                fetchItems={createSuggestionsApiFetcher('city', { locale })}
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    label={
                      isAthensTemplate || isPragueTemplate
                        ? i18n.t('builder.resume_editor.activities_card.location')
                        : i18n.t('builder.resume_editor.activities_card.city')
                    }
                  />
                )}
              </AsyncAutosuggest>
            </Field>
          )}
        </Row>

        {showDescriptionField && (
          <Row>
            <Field fullwidth>
              <RichTextArea
                label={i18n.t('builder.resume_editor.activities_card.description')}
                value={item.description || ''}
                onChange={updateDescription}
                locale={locale}
              />
            </Field>
          </Row>
        )}
      </CardContentWrapper>
    </Base>
  )
})

const Course = memo((props: CardComponentProps<ResumeCourse>) => {
  const { item, autoFocus, template } = props
  const { i18n } = useI18n()
  const { title } = useCardTitle(item.course, item.institution)
  const { updateSimpleField, updateDateRange } = useCardHandlers(props)
  const titleDates = formatDateRange(item)
  const showDatesField = displayFieldPerTemplateConfig(template, SectionNames.courses, 'dates')
  const showInstitutionField = displayFieldPerTemplateConfig(
    template,
    SectionNames.courses,
    'institution',
  )

  return (
    <Base {...props} title={title} subtitle={titleDates}>
      <CardContentWrapper>
        <Row>
          <Field fullwidth={!showInstitutionField}>
            <TextField
              autoFocus={autoFocus}
              label={i18n.t('builder.resume_editor.courses_card.course')}
              name="course"
              onChange={updateSimpleField}
              value={item.course || ''}
            />
          </Field>

          {showInstitutionField && (
            <Field>
              <TextField
                label={i18n.t('builder.resume_editor.courses_card.institution')}
                name="institution"
                onChange={updateSimpleField}
                value={item.institution || ''}
              />
            </Field>
          )}
        </Row>

        {showDatesField && (
          <Row>
            <Field>
              <DateRangePicker value={item} onChange={updateDateRange} />
            </Field>
          </Row>
        )}
      </CardContentWrapper>
    </Base>
  )
})

const Hobby = memo((props: CardComponentProps<ResumeHobby>) => {
  const { item } = props
  const { i18n } = useI18n()
  const { updateSimpleField } = useCardHandlers(props)

  return (
    <TextAreaField
      rows={3}
      label={i18n.t('builder.resume_editor.hobbies_card.hobby')}
      placeholder={i18n.t('builder.resume_editor.hobbies_card.hobby_placeholder')}
      name="hobby"
      onChange={updateSimpleField}
      value={item.hobby || ''}
    />
  )
})

const Skill = memo((props: CardComponentProps<ResumeSkill>) => {
  const { item, levels, hideSkillLevel, autoFocus, locale, template } = props
  const { i18n } = useI18n()
  const { updateSimpleField, handleLevelSelect } = useCardHandlers(props)
  const title = item.skill || i18n.t('builder.resume_editor.not_specified')
  const showLevelField = displayFieldPerTemplateConfig(template, SectionNames.skills, 'level')

  const subtitle = useMemo(() => {
    const isLevelVisible = !hideSkillLevel && item.level && item.level !== 'hidden'
    return isLevelVisible ? i18n.t(`builder.resume_editor.skill_level.${item.level}`) : ''
  }, [hideSkillLevel, item.level, i18n])

  const levelOptions = [
    {
      translation: i18n.t('builder.resume_editor.skills_card.select_level'),
      name: '',
      rating: -1,
    },
    ...(levels?.skill || []),
  ].map(level => ({
    id: level.name,
    name: level.translation,
    rating: level.rating,
  }))

  return (
    <Base {...props} title={title} subtitle={subtitle}>
      <CardContentWrapper>
        <Row>
          <Field fullwidth={!showLevelField}>
            <AsyncAutosuggest
              name="skill"
              fetchItems={createSuggestionsApiFetcher('skill', { locale })}
              value={item.skill || ''}
              onChange={updateSimpleField}
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  autoFocus={autoFocus && !item.skill}
                  label={i18n.t('builder.resume_editor.skills_card.skill')}
                />
              )}
            </AsyncAutosuggest>
          </Field>

          {showLevelField && (
            <Field>
              <LevelSelect
                label={i18n.t('builder.resume_editor.skills_card.level')}
                selected={item.level || undefined}
                options={levelOptions}
                onChange={handleLevelSelect}
                disabled={hideSkillLevel}
              />
            </Field>
          )}
        </Row>
      </CardContentWrapper>
    </Base>
  )
})

const Language = memo((props: CardComponentProps<ResumeLanguage>) => {
  const { item, levels, autoFocus, locale, template } = props
  const { i18n } = useI18n()
  const { updateSimpleField, handleLevelSelect } = useCardHandlers(props)
  const title = item.language || i18n.t('builder.resume_editor.not_specified')
  const subtitle = item.level ? i18n.t(`builder.resume_editor.language_level.${item.level}`) : ''
  const showLevelField = displayFieldPerTemplateConfig(template, SectionNames.skills, 'level')

  const levelOptions = useMemo(() => {
    return [
      { translation: i18n.t('builder.resume_editor.languages_card.select_level'), name: '' },
      ...(levels?.language || []),
    ].map(level => ({ id: level.name, name: level.translation }))
  }, [levels?.language, i18n])

  return (
    <Base {...props} title={title} subtitle={subtitle} item={item}>
      <CardContentWrapper>
        <Row>
          <Field fullwidth={!showLevelField}>
            <AsyncAutosuggest
              name="language"
              fetchItems={createSuggestionsApiFetcher('language', { locale })}
              value={item.language || ''}
              onChange={updateSimpleField}
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  autoFocus={autoFocus}
                  label={i18n.t('builder.resume_editor.languages_card.language')}
                />
              )}
            </AsyncAutosuggest>
          </Field>

          {showLevelField && (
            <Field>
              <Select
                label={i18n.t('builder.resume_editor.languages_card.level')}
                selected={item.level || ''}
                onSelect={handleLevelSelect}
                options={levelOptions}
              />
            </Field>
          )}
        </Row>
      </CardContentWrapper>
    </Base>
  )
})

const Social = memo((props: CardComponentProps<ResumeSocialProfile>) => {
  const { item, autoFocus } = props
  const { i18n } = useI18n()
  const { updateSimpleField } = useCardHandlers(props)

  return (
    <Base
      {...props}
      title={item.label || i18n.t('builder.resume_editor.not_specified')}
      subtitle={item.link || ''}
    >
      <CardContentWrapper>
        <Row>
          <Field>
            <TextField
              autoFocus={autoFocus}
              label={i18n.t('builder.resume_editor.links_card.label')}
              name="label"
              onChange={updateSimpleField}
              value={item.label || ''}
            />
          </Field>

          <Field>
            <TextField
              label={i18n.t('builder.resume_editor.links_card.link')}
              name="link"
              onChange={updateSimpleField}
              value={item.link || ''}
            />
          </Field>
        </Row>
      </CardContentWrapper>
    </Base>
  )
})

const Reference = memo((props: CardComponentProps<ResumeReference>) => {
  const { item, autoFocus } = props
  const { i18n } = useI18n()
  const { updateSimpleField } = useCardHandlers(props)

  return (
    <Base
      {...props}
      title={item.name || i18n.t('builder.resume_editor.not_specified')}
      subtitle={item.company || ''}
    >
      <CardContentWrapper>
        <Row>
          <Field>
            <TextField
              autoFocus={autoFocus}
              label={i18n.t('builder.resume_editor.references_card.name')}
              name="name"
              onChange={updateSimpleField}
              value={item.name || ''}
            />
          </Field>

          <Field>
            <TextField
              label={i18n.t('builder.resume_editor.references_card.company')}
              name="company"
              onChange={updateSimpleField}
              value={item.company || ''}
            />
          </Field>
        </Row>

        <Row>
          <Field>
            <TextField
              label={i18n.t('builder.resume_editor.references_card.phone')}
              name="phone"
              onChange={updateSimpleField}
              value={item.phone || ''}
            />
          </Field>

          <Field>
            <TextField
              label={i18n.t('builder.resume_editor.references_card.email')}
              name="email"
              onChange={updateSimpleField}
              value={item.email || ''}
            />
          </Field>
        </Row>
      </CardContentWrapper>
    </Base>
  )
})

const Custom = memo((props: CardComponentProps<ResumeCustomSectionCard>) => {
  const { item, autoFocus, locale } = props
  const { i18n } = useI18n()
  const { updateSimpleField, updateDescription, updateDateRange } = useCardHandlers(props)
  const titleDates = formatDateRange(item)

  const title = useMemo(() => {
    return (
      [item.title, item.city].filter(x => x).join(', ') ||
      i18n.t('builder.resume_editor.not_specified')
    )
  }, [item.title, item.city, i18n])

  return (
    <Base {...props} title={title} subtitle={titleDates} isCustom={true}>
      <CardContentWrapper>
        <Row>
          <Field>
            <TextField
              autoFocus={autoFocus}
              label={i18n.t('builder.resume_editor.custom_card.title')}
              name="title"
              onChange={updateSimpleField}
              value={item.title || ''}
            />
          </Field>

          <Field>
            <AsyncAutosuggest
              highlightedQuery
              name="city"
              value={item.city || ''}
              onChange={updateSimpleField}
              fetchItems={createSuggestionsApiFetcher('city', { locale })}
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  label={i18n.t('builder.resume_editor.custom_card.city')}
                />
              )}
            </AsyncAutosuggest>
          </Field>
        </Row>

        <Row>
          <Field>
            <DateRangePicker value={item} onChange={updateDateRange} />
          </Field>
        </Row>

        <Row>
          <Field fullwidth>
            <RichTextArea
              label={i18n.t('builder.resume_editor.custom_card.description')}
              value={item.description || ''}
              onChange={updateDescription}
              locale={locale}
            />
          </Field>
        </Row>
      </CardContentWrapper>
    </Base>
  )
})

const withAutoFocus = (Component: FC<CardComponentProps>): FC<CardComponentProps> => {
  return props => {
    const { isPhone } = useMediaQueries()
    return <Component {...props} autoFocus={!isPhone} />
  }
}

const CategorizedSkill = memo((props: CardComponentProps<ResumeSkill>) => {
  const { item, autoFocus } = props
  const { i18n } = useI18n()
  const title = item.skill || i18n.t('builder.resume_editor.not_specified')
  const { updateSimpleField } = useCardHandlers(props)

  return (
    <Base {...props} title={title}>
      <CardContentWrapper>
        <Row>
          <Field>
            <TextField
              autoFocus={autoFocus}
              label={i18n.t('builder.resume_editor.skills_card.title')}
              name="skill"
              onChange={updateSimpleField}
              value={item.skill || ''}
            />
          </Field>
        </Row>
        <Row>
          <Field fullwidth>
            <TextAreaField
              rows={3}
              label={i18n.t('builder.resume_editor.skills_card.skills')}
              placeholder={i18n.t('builder.resume_editor.skills_card.skills')}
              name="details"
              onChange={updateSimpleField}
              value={item.details || ''}
            />
          </Field>
        </Row>
      </CardContentWrapper>
    </Base>
  )
})

const Accomplishments = memo((props: CardComponentProps<ResumeContentCard>) => {
  const { item, locale, onResumeSimpleFieldChange } = props

  const updateSimpleField = (value: string) => {
    onResumeSimpleFieldChange?.(SectionNames.accomplishments, value, true)
  }

  return (
    <RichTextArea value={item.description || ''} onChange={updateSimpleField} locale={locale} />
  )
})

export default {
  WorkExperience: withAutoFocus(WorkExperience),
  Education: withAutoFocus(Education),
  Skill: withAutoFocus(Skill),
  CategorizedSkill: withAutoFocus(CategorizedSkill),
  Language: withAutoFocus(Language),
  Activity: withAutoFocus(Activity),
  Course: withAutoFocus(Course),
  Social: withAutoFocus(Social),
  Reference: withAutoFocus(Reference),
  Custom: withAutoFocus(Custom),
  Hobby,
  Accomplishments,
}
