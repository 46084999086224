import {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import trim from 'lodash/trim'
import { trackInternalEvent } from '@rio/tracking'
import { Transition } from 'react-transition-group'
import { Optional } from 'packages/types'
import { useI18n } from 'builder/hooks/useI18n'
import { useHover } from 'builder/hooks/useHover'
import SectionActions from './SectionActions'
import {
  Container,
  TitleWrapper,
  Title,
  Input,
  Bar,
  DragIcon,
  ToggleIcon,
  BadgesWrapper,
} from './styles'
import { SectionTitleBadgeProps } from './types'

type Props = {
  id?: string | number
  title: string
  customTitle?: Optional<string>
  sortable?: boolean
  renamable?: boolean
  removable?: boolean
  onRename?: (value: string | null) => void
  onDelete?: () => void
  isDragging?: boolean
  isCustom?: boolean
  badgeComponents?: FC<SectionTitleBadgeProps>[]
  isFoldableSectionsEnabled?: boolean
  isOpened?: boolean
}

const SectionTitle = (props: Props) => {
  const {
    sortable,
    renamable,
    removable,
    isDragging,
    isCustom,
    onDelete,
    onRename,
    badgeComponents,
    isFoldableSectionsEnabled,
    isOpened,
  } = props
  const hasCustomTitle = !!props.customTitle && trim(props.customTitle).length > 0
  const title = hasCustomTitle ? props.customTitle : props.title
  const [value, setValue] = useState(title)
  const [isEditing, toggleEditing] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const { i18n } = useI18n()
  const { hoverRef } = useHover<HTMLDivElement>()
  const [activeBadgeStates, setActiveBadgeStates] = useState<boolean[]>([])
  const revertable = !isCustom && hasCustomTitle
  const isSomeBadgeActive = activeBadgeStates.some(Boolean)

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    setValue(e.target.value)
    onRename?.(e.target.value)
  }

  const handleBlur = () => {
    toggleEditing(false)
  }

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = e => {
    const target = e.target as HTMLInputElement
    if (e.key === 'Enter') target.blur()
  }

  const handleRevert = () => {
    onRename?.(null)
  }

  const handleEditClick = () => {
    toggleEditing(true)
    trackInternalEvent('rename_resume_section')
  }

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [isEditing])

  useEffect(() => {
    if (!isEditing) setValue(title)
  }, [isEditing, title])

  const activeBadgeStateHandlers = useMemo(() => {
    return (badgeComponents || []).map((item, index) => {
      return (isActive: boolean) =>
        setActiveBadgeStates(states => {
          states = [...states]
          states[index] = isActive
          return states
        })
    })
  }, [badgeComponents])

  const handleClickPropagation: MouseEventHandler = event => {
    event.stopPropagation()
  }

  return (
    <Container ref={hoverRef}>
      <TitleWrapper onClick={handleClickPropagation}>
        <Title $isEditing={isEditing}>{isEditing ? value : title}</Title>

        {isEditing && (
          <Input
            value={value || ''}
            placeholder={i18n.t('builder.resume_editor.untitled')}
            onKeyUp={handleKeyUp}
            onChange={handleChange}
            onBlur={handleBlur}
            ref={inputRef}
            maxLength={80}
          />
        )}

        <Transition in={isEditing} timeout={100} appear>
          {state => <Bar $in={state} />}
        </Transition>
      </TitleWrapper>

      {(renamable || removable) && (
        <SectionActions
          isForcedVisible={isEditing || isSomeBadgeActive}
          onEdit={renamable ? handleEditClick : null}
          onRevert={revertable ? handleRevert : null}
          onDelete={removable ? onDelete : null}
        />
      )}

      {sortable && (
        <DragIcon
          isDragging={isDragging}
          $isFoldableSectionsEnabled={isFoldableSectionsEnabled}
          onClick={handleClickPropagation}
        />
      )}

      {badgeComponents?.length ? (
        <BadgesWrapper onClick={handleClickPropagation}>
          {badgeComponents.map((BadgeComponent, index) => {
            return (
              <BadgeComponent key={index} onActiveStateChange={activeBadgeStateHandlers[index]} />
            )
          })}
        </BadgesWrapper>
      ) : null}

      {isFoldableSectionsEnabled && <ToggleIcon $isDragging={isDragging} $isOpened={isOpened} />}
    </Container>
  )
}

export default SectionTitle
