import { useQuery } from '@tanstack/react-query'
import { performSimilarJobsApi } from '../../utils'
import { SearchQuery } from '../../JobSearchResult/types'

export const useGetSimilarJobs = (
  params: Omit<SearchQuery, 'correct_term' | 'view'> | undefined,
) => {
  return useQuery(
    ['useGetSimilarJobs', params?.query, params?.location],
    async () => {
      if (!params) {
        return
      }
      const { jobs, stats } = await performSimilarJobsApi(params)

      return { jobs, stats }
    },
    {
      enabled: !!params?.query,
    },
  )
}
