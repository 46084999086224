import { useI18n } from 'builder/hooks/useI18n'

import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import * as Styles from './styles'
import { useResumeDistributionNotEnoughCreditsModal } from './useResumeDistributionNotEnoughCreditsModal'

const DEFAULT_MAX_CREDITS = 20
const TRANSLATION = 'builder.resume_distribution.not_enough_credits_modal'

export const ResumeDistributionNotEnoughCreditsModal = () => {
  const { i18n } = useI18n()
  const { getAutoApplyCredit } = useAutoApply()
  const { isOpen, handleModalClose } = useResumeDistributionNotEnoughCreditsModal()
  const creditCountMax = getAutoApplyCredit().creditCountMax || DEFAULT_MAX_CREDITS

  if (!isOpen) return null

  const features = [
    i18n.t(`${TRANSLATION}.features.job_applications`, { count: creditCountMax }),
    i18n.t(`${TRANSLATION}.features.team_of_experts`),
    i18n.t(`${TRANSLATION}.features.job_application_screenshots`),
    i18n.t(`${TRANSLATION}.features.weekly_jobs_shortlist`),
  ]

  return (
    <Styles.Overlay>
      <Styles.RootModalContainer>
        <Styles.ModalSection>
          <Styles.CloseButtonContainer>
            <Styles.Close onClick={handleModalClose}>
              <Styles.CloseIcon />
            </Styles.Close>
          </Styles.CloseButtonContainer>
          <Styles.HeaderImage />
          <Styles.TextContainer>
            <Styles.HeaderContainer>
              <Styles.Title>{i18n.t(`${TRANSLATION}.title`)}</Styles.Title>
              <Styles.Description>{i18n.t(`${TRANSLATION}.description`)}</Styles.Description>
            </Styles.HeaderContainer>
            <Styles.FeaturesContainer>
              <Styles.FeatureTitle>{i18n.t(`${TRANSLATION}.features_title`)}</Styles.FeatureTitle>
              <Styles.Features>
                {features.map((features, index) => (
                  <Styles.Feature key={index}>{features}</Styles.Feature>
                ))}
              </Styles.Features>
            </Styles.FeaturesContainer>
          </Styles.TextContainer>
        </Styles.ModalSection>
      </Styles.RootModalContainer>
    </Styles.Overlay>
  )
}
