import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { ModalClose } from '../ModalClose'
import SocialProfileButtons from '../SignUp/SocialProfile/SocialProfileButtons'

export const Modal = styled.div`
  display: flex;
  position: relative;

  ${Media.Phone`
    margin-top: auto;
  `}
`

export const RightModalContainer = styled.div`
  border-radius: 0 ${Sizes.S} ${Sizes.S} 0;
  width: 485px;
  background-color: ${Colors.Blue10};
  padding-top: 89px;

  ${Media.Tablet`
    display: none;
  `}
`

export const RioCioImage = styled.img`
  height: 485px;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  background-color: ${Colors.White};
  border-radius: ${Sizes.S} 0 0 ${Sizes.S};
  width: 544px;
  max-width: calc(100vw - 40px);
  overflow: hidden;
  position: relative;

  ${Media.Tablet`
    border-radius: ${Sizes.S};
 `}

  ${Media.Phone`
    display: block;
    width: 100%;
    max-width: none;
    max-height: calc(100vh - 68px);
    border-radius: ${Sizes.S} ${Sizes.S} 0 0;
    overflow: auto;
  `}
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  flex: 0 0 100%; // TODO: add video on left side when ready and decrease to 0 0 50%
  gap: ${Sizes.L};

  ${Media.Tablet`
    padding: 32px;
    flex: 0 0 100%;
  `}

  ${Media.Phone`
    padding: 20px;
  `}
`

export const ManualSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ManualSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes['2XS']};

  ${Media.Phone`
    padding-right: 32px;
  `}
`

export const Title = styled.div`
  ${Typography.L};
  ${FontWeights.DemiBold};
`

export const Description = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};

  a {
    color: ${Colors.Blue50};
  }

  a:hover {
    color: ${Colors.Blue70};
  }
`

export const ManualSectionEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.XS};
`

export const ConsentCheckboxWrapper = styled.div`
  color: ${Colors.Neutral50};
`

export const ManualSectionFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.S};
`

export const Separator = styled.div`
  ${Typography.Caption};
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${Colors.Neutral50};

  &::before,
  &::after {
    content: '';
    flex: 1;
    height: 1px;
    background-color: ${Colors.Neutral30};
  }
`

export const SocialProfilesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.S};
`

export const SocialButtons = styled(SocialProfileButtons)`
  margin-bottom: 0 !important;
`

export const Copyright = styled(Description)`
  ${Typography.Tiny};
`

export const CloseButton = styled(ModalClose)`
  position: absolute;
  top: 32px;
  right: 32px;

  ${Media.Phone`
    top: 20px;
    right: 20px;
  `}
`
