import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div<{ $align: string }>`
  position: fixed;
  bottom: 82px;
  z-index: 1555;

  left: 0;
  right: 0;
  display: flex;
  justify-content: ${({ $align }) => $align};
  padding: 0 20px;
`

export const Content = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};

  display: inline-flex;
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: 0px 8px 20px -4px rgba(15, 20, 30, 0.24), 0px 2px 4px -1px rgba(15, 20, 30, 0.16);
  background-color: ${Colors.Neutral90};
  color: ${Colors.White};
  height: 48px;
  display: inline-flex;
  overflow: hidden;
  z-index: 2;
`

export const Text = styled.div<{ $enabledHover?: boolean }>`
  padding: ${Sizes.XS} ${Sizes.S};
  height: 100%;
  ${({ $enabledHover }) =>
    $enabledHover &&
    Media.Hover`
    &:hover  {
      background: ${Colors.Neutral80};
      cursor: pointer;
    }
  `}
`

export const Divider = styled.div`
  width: 1px;
  height: ${Sizes['4XL']};
  background: ${Colors.Neutral70};
`
