import React from 'react'
import { SuggestionSelectedEventData } from 'react-autosuggest'

import { TooltipV2 } from 'builder/components/Tooltip'
import { CustomElement } from 'builder/hooks/useFields'
import { CompanyAutosuggest, CompanySuggestion } from 'builder/components/CompanyAutosuggest'
import { ErrorIcon, IconContainer, Input, InputContainer } from '../styles'

type Ref = React.ForwardedRef<HTMLInputElement>
type Props = {
  value?: string
  placeholder: string
  errorMessage?: string
  handleUpdate: (e: React.ChangeEvent<HTMLInputElement> | CustomElement) => void
} & React.HTMLAttributes<HTMLInputElement>

export const CompanyField = React.forwardRef(
  ({ value, placeholder, errorMessage, handleUpdate, ...rest }: Props, ref: Ref) => {
    const handleSuggestionSelect = (
      _e: React.FormEvent,
      { suggestion }: SuggestionSelectedEventData<CompanySuggestion>,
    ) => {
      for (const name in suggestion) {
        const key = name as keyof CompanySuggestion
        const target = { name, value: suggestion[key] || '' }
        handleUpdate({ target })
      }
    }

    return (
      <CompanyAutosuggest
        {...rest}
        value={value}
        onChange={handleUpdate}
        fetchItemOptions={{ limit: 3 }}
        onSuggestionSelected={handleSuggestionSelect}
      >
        {inputProps => (
          <InputContainer>
            <Input ref={ref} {...inputProps} placeholder={placeholder} autoFocus />
            {errorMessage && (
              <TooltipV2 content={errorMessage} multiline={false}>
                <IconContainer>
                  <ErrorIcon />
                </IconContainer>
              </TooltipV2>
            )}
          </InputContainer>
        )}
      </CompanyAutosuggest>
    )
  },
)
