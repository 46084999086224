import { ChangeEvent, useCallback } from 'react'
import { DateRangePickerChangeValue } from 'builder/components/DateRangePicker/types'
import { CardComponentProps } from '../types'

export const useCardHandlers = (props: CardComponentProps) => {
  const { item, sectionId, onChange } = props

  const updateSimpleField = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(sectionId, item.id, { [event.target.name]: event.target.value }, true)
    },
    [sectionId, item.id, onChange],
  )

  const handleLevelSelect = useCallback(
    (value: string) => {
      onChange(sectionId, item.id, { level: value }, false)
    },
    [sectionId, item.id, onChange],
  )

  const updateDateRange = useCallback(
    (value: DateRangePickerChangeValue) => {
      const isChanged = Object.entries(value).some(
        ([key, value]) => value !== item[key as keyof DateRangePickerChangeValue],
      )

      if (isChanged) {
        onChange(sectionId, item.id, value, true)
      }
    },
    [onChange, sectionId, item],
  )

  const updateDescription = useCallback(
    (val: string) => {
      onChange(sectionId, item.id, { description: val }, true)
    },
    [sectionId, item.id, onChange],
  )

  return {
    updateSimpleField,
    handleLevelSelect,
    updateDateRange,
    updateDescription,
  }
}
