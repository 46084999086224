import { useNavigate, Link } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { SwiperSlide } from 'swiper/react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { actions } from 'builder/modules/jobTracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as careerProfileSelectors } from 'builder/modules/careerProfile'
import {
  Container,
  Content,
  ExploreIcon,
  ExploreLink,
  Header,
  Heading,
} from '../../CareerPathJobDetails/components/JobDetails/components/RecommendedJobs/styles'
import RecommendedJobCard from '../../CareerPathJobDetails/components/JobDetails/components/RecommendedJobs/components/RecommendedJobCard'
import JobSlider from '../../CareerPathJobDetails/components/JobDetails/components/RecommendedJobs/components/JobSlider'
import Loader from '../../CareerPathJobDetails/components/JobDetails/components/Loader'
import { Job } from '../JobSearchResult/types'
import {
  useGetRecommendationJob,
  useInvalidateGetRecommendationJobQuery,
} from '../JobAlertPage/hooks/useGetRecommendationJob'
import { getRecommendationsError } from '../utils/getRecommendationsError'
import { NoResultsRecommendation } from '../Banners/NoResultsSearch/NoResultsRecommendation'
import { withBlurredErrorContainer } from '../RecommendationsErrors/hoc/withBlurredErrorContainer'
import { useGetProfile } from '../hooks/useGetProfile'
import { NoLocation, SetTargetLocationDialog } from '../RecommendationsMissingInfoComponents'
import { mockJobRecommendations } from './mockJobRecommendations'
import { Description, ContentWrapper, NoResultContainer, MissingInfoWrapper } from './styles'

const RecommendedJobs = () => {
  const [displaySetLocationDialog, setDisplaySetLocationDialog] = useState<boolean>(false)
  const navigate = useNavigate()

  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { isDesktop, isLaptop, isTablet, isPhone } = useMediaQueries()
  const recommendationsResponse = useGetRecommendationJob(true)
  const { invalidate } = useInvalidateGetRecommendationJobQuery()
  const { response: userProfile, refetch: refetchProfile } = useGetProfile()
  const careerProfile = useTypedSelector(careerProfileSelectors.careerProfileData)

  const navigateToJobRecommendation = () => {
    trackInternalEvent('recommended_jobs_click', {
      label: 'see_all',
      term: careerProfile?.targetRoles.map(el => el.standardTitle).join(';'),
      location: careerProfile?.targetLocations.map(el => el.formattedName).join(';'),
    })
    navigate('/job-search?view=recommendation')
  }
  const navigateToJobSearch = () => navigate('/job-search?view=search')

  const handleCardClick = (card: Job) => {
    trackInternalEvent('recommended_jobs_click', {
      label: 'job_post_click',
      term: careerProfile?.targetRoles.map(el => el.standardTitle).join(';'),
      location: careerProfile?.targetLocations.map(el => el.formattedName).join(';'),
    })
    navigate(`/job-search?view=recommendation&external_slug_id=${card.external_slug_id}`)
  }
  useEffect(() => {
    if (
      !recommendationsResponse.isLoading &&
      recommendationsResponse.data &&
      !recommendationsResponse.isError &&
      careerProfile
    ) {
      trackInternalEvent('job_results', {
        label: 'dashboard',
        term: careerProfile?.targetRoles.map(el => el.standardTitle).join(';'),
        location: careerProfile?.targetLocations.map(el => el.formattedName).join(';'),
        location_from_resume: recommendationsResponse.data.stats.location_from_resume,
        target_role_from_resume: recommendationsResponse.data.stats.target_role_from_resume,
        total_jobs: recommendationsResponse.data.stats.total_jobs,
      })
    }
  }, [recommendationsResponse, careerProfile])

  const afterProfileUpdateHandler = () => {
    refetchProfile(invalidate)
  }

  useEffect(() => {
    dispatch(actions.fetchJobsRequest())
  }, [dispatch])

  const isSliderActive = useMemo(() => {
    const { isLoading, isError, data } = recommendationsResponse
    const recommendations = data?.jobs || []

    if (isError || isLoading || !recommendations.length) {
      return false
    }

    const recommendationsCount = recommendations.length
    if (
      ((isDesktop || isLaptop) && recommendationsCount > 4) ||
      (!isPhone && isTablet && recommendationsCount > 3) ||
      (isPhone && recommendationsCount > 1)
    ) {
      return true
    }

    return false
  }, [isDesktop, isLaptop, isPhone, isTablet, recommendationsResponse])

  const error = useMemo(() => {
    if (!recommendationsResponse.isError || !recommendationsResponse.error) return null
    const error = recommendationsResponse.error as AxiosError<{ missing_attributes: string[] }>
    const missingAttributes = error.response?.data?.missing_attributes || []
    return getRecommendationsError(missingAttributes)
  }, [recommendationsResponse.isError, recommendationsResponse.error])

  const ErrorComponent = withBlurredErrorContainer(error)

  const cards = useMemo(() => {
    if (recommendationsResponse.error) {
      return error ? mockJobRecommendations : []
    }
    return recommendationsResponse.data?.jobs || []
  }, [recommendationsResponse?.error, recommendationsResponse?.data, error])

  const isProfileInfoMissing = useMemo(() => {
    const { targetLocations = [] } = userProfile || {}
    return !targetLocations?.length && !recommendationsResponse.data?.stats.location_from_resume
  }, [userProfile, recommendationsResponse.data?.stats])

  const renderMissingInfoComponent = () => {
    if (!isProfileInfoMissing || !!error) return null

    const MissingInfoContent = (
      <>
        <MissingInfoWrapper isSliderActive={isSliderActive}>
          <NoLocation onClick={() => setDisplaySetLocationDialog(true)} layout="vertical" />
        </MissingInfoWrapper>
      </>
    )

    return isSliderActive ? <SwiperSlide>{MissingInfoContent}</SwiperSlide> : MissingInfoContent
  }

  const renderJobCards = () => {
    if (isSliderActive) {
      return (
        <JobSlider
          appendSlides={renderMissingInfoComponent}
          cards={cards}
          isSliderActive={isSliderActive}
          handleClick={handleCardClick}
          fromDashboard={true}
        />
      )
    }
    return (
      <>
        {renderMissingInfoComponent?.()}
        {cards.map((card: Job, index: number) => {
          return (
            <RecommendedJobCard
              key={index}
              fromDashboard={true}
              {...{ card, isSliderActive, handleClick: () => handleCardClick(card) }}
            />
          )
        })}
      </>
    )
  }

  return (
    <>
      <Container>
        <Header>
          <Heading>{i18n.t('builder.dashboard.recommended_jobs')}</Heading>
          <ExploreLink onClick={navigateToJobRecommendation}>
            <Link to="/job-search?view=recommendation">{i18n.t('builder.dashboard.see_all')}</Link>
            <ExploreIcon />
          </ExploreLink>
        </Header>
        <ContentWrapper>
          {recommendationsResponse.isLoading && <Loader />}
          {!recommendationsResponse.isLoading && !!cards?.length && (
            <>
              <Content data-iserror={recommendationsResponse.isError}>{renderJobCards()}</Content>
              {!(recommendationsResponse.isError && error) && (
                <Description>
                  Based on your&nbsp;
                  <Link to="/career-profile/edit?section=jobPreferences&returnUrl=/">
                    {i18n.t('builder.dashboard.career_profile')}
                  </Link>
                </Description>
              )}
            </>
          )}
          {recommendationsResponse.isError && !!error && <ErrorComponent />}
          {!recommendationsResponse.isLoading && !cards?.length && !error && (
            <NoResultsRecommendation
              CustomContainer={NoResultContainer}
              handleSearch={navigateToJobSearch}
              showExtension={false}
            />
          )}
        </ContentWrapper>
      </Container>
      {displaySetLocationDialog && (
        <SetTargetLocationDialog
          afterSetLocationHandler={afterProfileUpdateHandler}
          closeHandler={() => setDisplaySetLocationDialog(false)}
        />
      )}
    </>
  )
}

export default RecommendedJobs
