import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import Icon from 'builder/components/Icon'
import { ActionMenu, Action, ActionMenuPosition } from 'builder/components/ActionMenu'
import { actions, selectors } from 'builder/modules/coRegistration'
import { selectors as userSelectors } from 'builder/modules/user'
import { MouseClickEvent, actions as uiActions } from 'builder/modules/ui'
import { DocumentTypes, FORMATS } from 'builder/modules/constants'
import { useConfig } from 'builder/hooks/useConfig'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { ConfigScopesEnum } from 'builder/modules/init'

interface Props {
  documentId: number
  documentType: DocumentTypes
  position?: ActionMenuPosition
  onShare: () => void
  onClose: () => void
  onDownload: (args: {
    id: number
    type: DocumentTypes
    format: FORMATS
    source: string
    event: MouseClickEvent
  }) => void
}

export const DocumentActionMenu = ({
  documentId,
  documentType,
  onClose,
  onDownload,
  position,
  onShare,
}: Props) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const config = useConfig()
  const countryConfig = useConfig(ConfigScopesEnum.country)
  const isPremiumUser = useTypedSelector(userSelectors.premiumAccess)
  const isSharingEnabled = (config?.features.shareResume ?? false) && isPremiumUser
  const showOneDownloadButton = !!(config?.features.downloadButtonLayout === 'one_button')
  const isCoRegSupported = useSelector(selectors.isSupported)
  const isReadyToCoReg = useSelector(selectors.isResumeReady)
  const isResume = documentType === DocumentTypes.resume
  // Show the download resume option only for resume and the one button layout
  const showUpdatedMenuLayout = isResume && showOneDownloadButton

  const handleOpenCoReg = useCallback(
    () =>
      dispatch(actions.open({ resumeId: documentId, source: 'additional_functionality_button' })),
    [dispatch, documentId],
  )

  const handleDownloadDocument = useCallback(
    ({ event, format = FORMATS.pdf }) => {
      onDownload({
        id: documentId,
        type: documentType,
        format,
        source: 'additional_functionality_button',
        event: event,
      })
    },
    [onDownload, documentId, documentType],
  )

  const handleShareClick = useCallback(() => {
    onShare()
    dispatch(uiActions.openSharingModal(documentId))
  }, [dispatch, documentId, onShare])

  const handleCombineClick = useCallback(() => {
    const resumeId = documentType === DocumentTypes.resume ? documentId : null
    const coverLetterId = documentType === DocumentTypes.coverLetter ? documentId : null

    dispatch(uiActions.openMergeModal({ resumeId, coverLetterId }))
    trackInternalEvent('open_documents_merging_modal')
  }, [dispatch, documentType, documentId])

  const menuActions: Array<Action | false> = useMemo(() => {
    const pdfDownloadAction = {
      text: 'PDF',
      icon: <Icon.FilePdfGray />,
      onClick: (event: MouseClickEvent) => handleDownloadDocument({ event, format: FORMATS.pdf }),
    }

    const docxDownLoadAction = {
      text: showUpdatedMenuLayout ? 'DOCX' : i18n.t('builder.dashboard.download_docx'),
      icon: <Icon.FileWord />,
      onClick: (event: MouseClickEvent) => handleDownloadDocument({ event, format: FORMATS.docx }),
    }

    const resumeActions = [
      {
        text: i18n.t(
          showUpdatedMenuLayout
            ? 'builder.resume_editor.download_document_menu.free_option'
            : 'builder.dashboard.download_txt',
        ),
        icon: <Icon.FileTxt />,
        onClick: (event: MouseClickEvent) => handleDownloadDocument({ event, format: FORMATS.txt }),
        // Show the 'FREE' badge only for the one button layout and if the user is not premium
        badge:
          showUpdatedMenuLayout && !isPremiumUser ? countryConfig?.freeBadgeSlug || 'FREE' : '',
      },
      isSharingEnabled && {
        text: i18n.t('builder.resume_editor.share'),
        icon: <Icon.Link />,
        onClick: handleShareClick,
      },
      isCoRegSupported && {
        divider: true,
        text: i18n.t('builder.coreg.dropdown_action'),
        icon: <Icon.Share />,
        marked: isReadyToCoReg,
        onClick: handleOpenCoReg,
      },
    ]

    const coverLetterActions: Action[] = [
      {
        text: i18n.t('builder.resume_editor.combine_with'),
        icon: <Icon.Combine />,
        onClick: handleCombineClick,
      },
    ]

    return [
      // New pdf download action only for the one button layout
      ...(showUpdatedMenuLayout ? [pdfDownloadAction] : []),
      docxDownLoadAction,
      ...(isResume ? resumeActions : coverLetterActions),
    ]
  }, [
    i18n,
    handleShareClick,
    isCoRegSupported,
    isReadyToCoReg,
    handleOpenCoReg,
    handleCombineClick,
    isResume,
    handleDownloadDocument,
    isSharingEnabled,
    isPremiumUser,
    countryConfig,
    showUpdatedMenuLayout,
  ])

  return (
    <ActionMenu
      {...{
        position,
        onClose,
      }}
      actions={menuActions}
    />
  )
}
