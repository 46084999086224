import { useI18n } from 'builder/hooks/useI18n'
import { Text, Container, Divider, Content } from './styles'

export const TRANSLATION_PREFIX = 'builder.interview_prep_view.interview_player_v2.snackbar'

interface Props {
  align: string
  text: string
  onYesClick: () => void | undefined | Promise<void>
  onNoClick: () => void | undefined | Promise<void>
}
export const Snackbar = ({ text, onNoClick, onYesClick, align }: Props) => {
  const { i18n } = useI18n()
  return (
    <Container $align={align}>
      <Content>
        <Text>{text}</Text>
        <Divider />
        <Text $enabledHover onClick={onNoClick}>
          {i18n.t(`${TRANSLATION_PREFIX}.no_word`)}
        </Text>
        <Text $enabledHover onClick={onYesClick}>
          {i18n.t(`${TRANSLATION_PREFIX}.yes_word`)}
        </Text>
      </Content>
    </Container>
  )
}
