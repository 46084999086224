import { useDispatch } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import Button from 'builder/components/Button'

import { actions } from 'builder/modules/interview/interviewModule'
import { DialogModalV2 } from 'builder/components/DialogModal'
import { formatSecondsToMMSS } from 'builder/utils/formatTime'
import { Bottom, Content } from './styles'

export const TRANSLATION = 'builder.interview_prep_view.interview_player_v2.session_modal'

export const SessionModal = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const [seconds, setSeconds] = useState(2)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setSeconds(prevSeconds => Math.max(0, prevSeconds - 1))
    }, 1000)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (seconds === 0) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [seconds])

  const handleClose = () => dispatch(actions.setInterviewPlayerModal(null))

  return (
    <DialogModalV2 onClose={handleClose} title={i18n.t(`${TRANSLATION}.title`)}>
      <Content>{i18n.t(`${TRANSLATION}.content`, { time: formatSecondsToMMSS(seconds) })}</Content>
      <Bottom>
        <Button theme="ghost" onClick={handleClose}>
          {i18n.t(`${TRANSLATION}.leave`)}
        </Button>

        <Button onClick={handleClose}>{i18n.t(`${TRANSLATION}.stay_connected`)}</Button>
      </Bottom>
    </DialogModalV2>
  )
}
