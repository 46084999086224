import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'

export const Content = styled.div`
  width: 1120px;
  margin: 0 auto;
  padding-top: 58px;

  height: 100%;

  ${Media.AboveTablet`
    height: 585px;
  `};

  ${Media.Tablet`
    width: 100%;
    padding-top: 40px;
  `};

  ${Media.Phone`
    width: 100%;
    padding-top: 32px;
  `};
`

export const Row = styled.div`
  ${Media.AboveTablet`
    display: grid;
    grid-template-columns: 1fr 1fr;

    align-items: center;
    justify-content: space-between;
    margin: 0 32px;
  `};

  ${Media.Tablet`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-bottom: 56px;
    height: 100%;

  `};

  ${Media.Phone`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    height: 100%;
  `};
`

export const Left = styled.div`
  width: 448px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${Media.Tablet`
    width: 504px;
    padding: 32px 0;
    height: calc(100% - 100px);
    height: calc(100vh - 100px);
    height: calc(100svh - 100px);
  `};

  ${Media.Phone`
    width: 335px;
  `};
`

export const Right = styled.div`
  width: 544px;
  height: 560px;
  border: none;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  display: none;

  ${Media.AboveTablet`
    width: 504px;
    height: auto;
    display: unset;
  `};
`

export const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const StyledVideo = styled.video`
  width: 100%;
  height: 504px;
  border-radius: 16px;
`

export const LeftTop = styled.div``

export const LeftBottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
`

export const ProgressWrapper = styled.div`
  display: flex;
`

export const ProgressDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${Colors.Neutral20};
`

export const ActiveProgressDot = styled(ProgressDot)`
  background: ${Colors.Blue50};
  width: 16px;
  border-radius: 8px;
  margin-right: 8px;
`

export const CustomEditor = styled.div`
  margin: 0;
`
export const PillLabel = styled.span`
  color: ${Colors.Green50};
  background-color: ${Colors.Green10};
  padding: 4px 10px;
  border-radius: 12px;
  ${FontWeights.Medium};
  ${Typography.Body};
`
export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  margin-top: 20px;
`
export const Title2 = styled.div`
  color: ${Colors.Neutral50};
  ${FontWeights.DemiBold};
  letter-spacing: 1.8px;
  text-transform: uppercase;
`

export const Subtitle = styled.p`
  color: ${Colors.Neutral50};
  ${FontWeights.Regular};
`

export const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
`

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Item = styled.div`
  display: flex;
  gap: 4px;
`

export const Tick = styled(Icon20.Tick)`
  color: ${Colors.Blue50};
`
export const Buttons = styled.div`
  display: flex;
  gap: 10px;

  ${Media.Phone`
    width: 335px;
    position: fixed;
    bottom: 70px;
    z-index: 10;
    background: ${Colors.White};
    flex-direction: column-reverse;
    padding-bottom: 24px;
  `};
`

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
`
export const CheckWrapperDesktop = styled.div`
  display: none;

  ${Media.AboveTablet`
    display: flex;
    width: 100%;
    padding-top: 16px;

    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;

    z-index: 3;
  `}
`

export const CheckWrapperMobile = styled.div`
  display: none;
  ${Media.Tablet`
    display: flex;

  `}
`

export const BodyText = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Body};
  ${FontWeights.Regular};
`
