import type { UserDocumentType, Attachment } from '@rio/types'
import { FormatType } from 'builder/modules/constants'
import { FORMATS } from '../constants'

export interface RenderingStore {
  isDocumentDownloading: boolean
  isPreviewGenerating: boolean
  rdUpsellPromoModalVisible: boolean
  rdUpsellGetStartedModalVisible: boolean
  showUpsellRdPromoModal: boolean
  rdUpsellModalVisible: boolean
  rdGotCreditsModalVisible: boolean
  rdNotEnoughCreditsModalVisible: boolean
  showAutoApplyUpsellModal: boolean
}

export enum DownloadTRDocumentStorageKeys {
  DOWNLOAD_WHEN_PREMIUM = 'downloadWhenPremium',
  TR_DOC_ID = 'trDocId',
}

export type DownloadWhenPremium = FORMATS.tr_pdf | FORMATS.tr_doc

export const PREMIUM_TRIAL_ACTIVATED_KEY = 'premiumTrialActivated'

export interface DownloadTypes {
  id: number
  type?: UserDocumentType
  format?: FormatType
  showSnackBar?: boolean
  source?: string
  trDocFiles?: Attachment[]
  template?: string
  aiTaskId?: string | null
}
