import React from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { Field } from 'builder/components/Card/styles'
import RichTextArea from 'builder/components/RichTextArea/RichTextArea'

type WorkExperienceAdditionalInformationProps = {
  item: { description?: string }
  updateDescription: (value: string) => void
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function WorkExperienceAdditionalInformation(
  props: WorkExperienceAdditionalInformationProps,
): JSX.Element {
  const { i18n } = useI18n()

  const { item, updateDescription } = props

  return (
    <React.Fragment>
      <Field
        fullwidth
        // z-index decresed to 0 due to clash with dropdown
        // This results in the dropdown being shown as transparent
        style={{ zIndex: 0 }}
      >
        <RichTextArea
          placeholder={i18n.t(
            'builder.resume_editor.work_experiences_card.description_placeholder',
          )}
          label={i18n.t('builder.resume_editor.work_experiences_card.additional_information')}
          value={item.description || ''}
          onChange={updateDescription}
          // This extra props is to hide the bold,italic and link button
          boldButtonDisable
          italicButtonDisable
          linkButtonDisable
        />
      </Field>
    </React.Fragment>
  )
}
