import { EVENT_VOICE_ANIMATION_FOR_INTERVIEW_PREP } from '../constants'
import { sendCustomEvent } from './sendCustomEvent'

export class AudioPlayerUtils {
  bufferSource: AudioBufferSourceNode
  startedAudio = false
  stopSound() {
    if (this.startedAudio) {
      this.bufferSource.stop()
      this.startedAudio = false
      sendCustomEvent(EVENT_VOICE_ANIMATION_FOR_INTERVIEW_PREP, { type: 'reset' })
    }
  }

  private async playAudioFromAudioBuffer(audioCtx: AudioContext, audioData: AudioBuffer) {
    return new Promise(async resolve => {
      sendCustomEvent(EVENT_VOICE_ANIMATION_FOR_INTERVIEW_PREP, { type: 'reset' })

      this.bufferSource = audioCtx.createBufferSource()
      this.bufferSource.buffer = audioData
      const gainNode = audioCtx.createGain()
      this.bufferSource.connect(gainNode)
      gainNode.connect(audioCtx.destination)

      const analyser = audioCtx.createAnalyser()
      analyser.fftSize = 2048

      this.bufferSource.connect(analyser)
      this.bufferSource.connect(audioCtx.destination)

      this.bufferSource.start()
      this.startedAudio = true

      const waveform = new Uint8Array(analyser.frequencyBinCount)

      sendCustomEvent(EVENT_VOICE_ANIMATION_FOR_INTERVIEW_PREP, {
        type: 'playing',
        waveform,
        analyser,
      })

      this.bufferSource.onended = () => {
        sendCustomEvent(EVENT_VOICE_ANIMATION_FOR_INTERVIEW_PREP, {
          type: 'playing',
          waveform,
          analyser,
        })

        resolve(undefined)
      }
    })
  }

  async playAudioFromBlob(blob: Blob) {
    if (blob.type !== 'audio/mpeg') {
      return
    }
    const audioCtx = new AudioContext()
    return new Promise<void>(resolve => {
      const reader = new FileReader()

      reader.readAsArrayBuffer(blob)
      reader.onload = async event => {
        const arrayBuffer = event.target?.result
        if (arrayBuffer instanceof ArrayBuffer) {
          const audioData = await audioCtx.decodeAudioData(arrayBuffer)
          await this.playAudioFromAudioBuffer(audioCtx, audioData)
        }
        resolve()
      }
    })
  }
}
