import styled from 'styled-components'
import { animated } from '@react-spring/web'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const Container = styled(animated.div)`
  ${Typography.Tiny};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px 2px 6px;
  background-color: ${Colors.Indigo10};
  color: ${Colors.Indigo50};
  position: relative;
`

export const Text = styled.span``
