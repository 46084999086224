import { RefObject, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'builder/modules/resumeEditor'
import { AIResumeFlows } from 'builder/modules/constants'
import { useConfig } from 'builder/hooks/useConfig'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

export const useResumePrefill = () => {
  const config = useConfig()
  const isUSBasedUser = config?.features.geoipCode === 'US'
  const isAIResumeDraftEnabled = config?.features.aiResumeDraft
  const isUseExamplesEnabled = config?.features.startWithExamples
  const isUploadResumeEnabled = config?.features.uploadResume
  const dispatch = useDispatch()
  const { resumeExamples, fetchingResumeExamples } = useTypedSelector(
    store => store.resumeEditor.useExample,
  )
  const hasResumeExamples = !!resumeExamples.length

  const handleCreateWithAi = useCallback(() => {
    if (!isAIResumeDraftEnabled) {
      return
    }
    dispatch(actions.resetAIResumeData())
    if (!isUSBasedUser) {
      dispatch(actions.setAIResumeFlow(AIResumeFlows.Questionnaire))
      dispatch(actions.setHideLinkedInFlow(true))
    }
    dispatch(actions.setShowAIResumeModal(true))
  }, [dispatch, isUSBasedUser, isAIResumeDraftEnabled])

  const handleUseExample = useCallback(() => {
    if (!isUseExamplesEnabled) {
      return
    }
    if (hasResumeExamples) {
      dispatch(actions.setShowUseExamplePopup(true))
    } else if (!fetchingResumeExamples) {
      dispatch(actions.fetchResumeExamples())
    }
  }, [dispatch, fetchingResumeExamples, hasResumeExamples, isUseExamplesEnabled])

  const handleResumeUpload = useCallback(
    (fileInputRef: RefObject<HTMLInputElement>) => {
      if (!isUploadResumeEnabled) {
        return
      }
      fileInputRef.current?.click()
    },
    [isUploadResumeEnabled],
  )

  return {
    isUSBasedUser,
    hasResumeExamples,
    fetchingResumeExamples,
    handleCreateWithAi,
    handleUseExample,
    handleResumeUpload,
  }
}
