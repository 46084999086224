import { memo } from 'react'
import PropTypes from 'prop-types'
import { useI18n } from 'builder/hooks/useI18n'
import { SectionNames } from 'builder/modules/resumeEditor'
import { Container, Title, List, Item } from './styles'
import { useSelector } from 'react-redux'
import { TemplateName } from '../Helper/constants'
import { translationKeyAliases } from '../SectionsList/constants'

const sectionTypes = [
  SectionNames.courses,
  SectionNames.activities,
  SectionNames.internships,
  SectionNames.hobbies,
  SectionNames.languages,
  SectionNames.references,
]

const getRandomId = () => Math.floor(Math.random() * 0xffffffff).toString()
const isEnabled = (name, sections) => sections.find(section => section.id === name).hidden
const isPresent = (name, sections) => !!sections.find(section => section.id === name)

const AdditionalSections = ({ sections, onAdd, onAddCustom }) => {
  const { i18n } = useI18n()
  const isAtsTemplate = useSelector(state =>
    [TemplateName.Athens, TemplateName.Prague]?.includes(state.resumeEditor.resume?.template),
  )

  return (
    <Container>
      <Title>{i18n.t('builder.resume_editor.add_section')}</Title>
      <List>
        <Item type="custom" onClick={() => onAddCustom(getRandomId())}>
          {i18n.t('builder.resume_editor.custom_section.title')}
        </Item>

        {sectionTypes
          .filter(type => isPresent(type, sections))
          .map(type => {
            const section = sections.find(item => item.id === type)
            return (
              <Item
                key={type}
                type={type}
                disabled={!isEnabled(type, sections)}
                onClick={() => isEnabled(type, sections) && onAdd(type)}
              >
                {section?.title
                  ? section.title
                  : isAtsTemplate && translationKeyAliases[type]
                  ? i18n.t(translationKeyAliases[type])
                  : i18n.t(`builder.resume_editor.${type}`)}
              </Item>
            )
          })}
      </List>
    </Container>
  )
}

AdditionalSections.propTypes = {
  sections: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onAddCustom: PropTypes.func.isRequired,
}

export default memo(AdditionalSections)
