import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import useDebouncedEffect from 'use-debounced-effect'
import { trackInternalEvent } from '@rio/tracking'
import { actions, isEmptyField, SectionNames, selectors } from 'builder/modules/resumeEditor'
import ModalOverlay from 'builder/components/ModalOverlay'
import { ButtonSize } from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FetchStatuses } from 'builder/modules/constants'
import { i18n } from 'builder/utils/i18n'
import { useAutoTailoring } from 'builder/components/AutoTailoring/hooks/useAutoTailoring'
import { isEmptySection } from 'builder/modules/resumeEditor/autoTailoringRules'
import { useResume } from 'builder/hooks/useResume'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { useTailoredDesignation } from 'builder/hooks/useTailoredDesignation'
import { actions as renderingActions } from 'builder/modules/rendering'
import {
  MainContainer,
  TopContainer,
  BottomContainer,
  ValidationSuccessIcon,
  ValidationFailIcon,
  ValidationLoadingIcon,
  CloseIcon,
  GradientContainer,
  CloseIconWrapper,
} from './styles'
import BottomContainerInitialView from './BottomContainer/Initial'
import BottomContainerLoadingView from './BottomContainer/Loading'
import BottomContainerResultView from './BottomContainer/Result'
import DataContainerInitialView from './DataContainer/Views/Initial'
import DataContainerLoadingView from './DataContainer/Views/Loading'
import DataContainerResultView from './DataContainer/Views/Result'
import ManualInput from './ManualInput'
import { DEBOUNCE_DELAY, urlRegex } from './constants'
import { ErrorState } from './ErrorState/ErrorState'
import { BottomContainerNotEnoughDataView } from './BottomContainer/NotEnoughData'
import { NotEnoughDataView } from './DataContainer/Views/NotEnoughData'
import DownloadGeneric from './BottomContainer/DownloadGeneric'
import FeaturingAutoTailorOnDownloadTopView from './DataContainer/Views/FeaturingAutoTailorOnDownloadTopView'
import FeaturingAutoTailorOnDownloadBottomView from './BottomContainer/FeaturingAutoTailorOnDownloadBottomView'

const statusIcons = {
  [FetchStatuses.notAsked]: <></>,
  [FetchStatuses.loading]: <ValidationLoadingIcon />,
  [FetchStatuses.loaded]: <ValidationSuccessIcon />,
  [FetchStatuses.failed]: <ValidationFailIcon />,
}

const getStatusText = (status: FetchStatuses | null): string => {
  switch (status) {
    case FetchStatuses.failed:
      return i18n.t('builder.resume_optimizer.job_posting.link_error')
    default:
      return i18n.t('builder.resume_optimizer.job_posting.example')
  }
}

interface Props {
  resumeId: number
}

export const JobPosting = ({ resumeId }: Props) => {
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const { tryToStartAutoTailoring, isAutoTailoringEnabled } = useAutoTailoring()
  const buttonSize = !isPhone ? ButtonSize.default : ButtonSize.small

  const optimizerSection = useTypedSelector(state => state.resumeEditor.optimizerSection)
  const resumeDownloadData = useTypedSelector(selectors.resumeDownloadData)
  const resume = useResume(resumeId)
  const isWorkExperienceEmpty = isEmptySection(resume, SectionNames.workExperiences)
  const isProfileEmpty = isEmptyField(resume, SectionNames.profile)
  const isFeaturingAutoTailorModalOpenOnDownload = useTypedSelector(
    selectors.isFeaturingAutoTailorModalOpenOnDownload,
  )
  const {
    fetchScoreStatus: fetchTailoredScoreStatus,
    saveJobPostingDataStatus,
    jobPostingLinkForModal: jobPostingLink,
    resumeScoreAPIData: { averageScore, suggestedJobTitle, employerName },
    showManualInputScreen,
  } = optimizerSection

  const initialValidationResult = urlRegex.test(jobPostingLink)

  const [linkValidationStatus, setLinkValidationStatus] = useState<FetchStatuses | null>(
    jobPostingLink ? (initialValidationResult ? FetchStatuses.loaded : FetchStatuses.failed) : null,
  )

  const tailoredDesignation = useTailoredDesignation(suggestedJobTitle, employerName)

  const isDataMissed = isAutoTailoringEnabled && isWorkExperienceEmpty && isProfileEmpty

  useEffectOnMount(() => {
    dispatch(actions.resetResumeOptimizerProcess())
  })

  useDebouncedEffect(
    () => {
      if (jobPostingLink) {
        const validationResult = urlRegex.test(jobPostingLink)
        setLinkValidationStatus(validationResult ? FetchStatuses.loaded : FetchStatuses.failed)
      }
    },
    DEBOUNCE_DELAY,
    [jobPostingLink],
  )

  const handleLinkChange = useCallback(
    event => {
      const inputValue = event.target.value.replaceAll('https://', '')
      const linkValue = inputValue ? 'https://' + inputValue : ''
      dispatch(actions.setJobPostingLinkForModal(linkValue))
      setLinkValidationStatus(FetchStatuses.loading)
    },
    [dispatch],
  )

  const isValidLink = linkValidationStatus === FetchStatuses.loaded
  const isInvalidLink = linkValidationStatus === FetchStatuses.failed

  const handleClose = useCallback(() => {
    if (saveJobPostingDataStatus !== FetchStatuses.loading) {
      dispatch(actions.setOpenOptimizerModal(false))
      dispatch(actions.setIsClickedOnAiCVBanner(false))
    }
    dispatch(actions.resetResumeDownloadData())
    dispatch(actions.setJobPostingLinkForModal(''))
    dispatch(actions.setFeaturingAutoTailorModalOpenOnDownload(false))
  }, [dispatch, saveJobPostingDataStatus])

  const handleContinue = useCallback(() => {
    dispatch(
      actions.checkResumeScore({
        jobPostingLink,
        resumeId,
      }),
    )
    if (resumeDownloadData.id) {
      trackInternalEvent('click_auto_tailor', { label: 'auto_tailoring_download_button_screen' })
    }
    trackInternalEvent('click_continue_job_posting_link')
    dispatch(actions.resetResumeDownloadData())
  }, [dispatch, jobPostingLink, resumeDownloadData.id, resumeId])

  const showErrorModal = fetchTailoredScoreStatus === FetchStatuses.failed
  const showResultScreen = fetchTailoredScoreStatus === FetchStatuses.loaded

  useEffect(() => {
    if (showErrorModal) {
      trackInternalEvent('see_resume_optimizer_error_screen', { label: 'no_manual_input' })
    }
  }, [showErrorModal])

  const handleTryAgain = useCallback(() => {
    dispatch(actions.resetResumeOptimizerProcess())
    if (showErrorModal) {
      trackInternalEvent('click_try_again_resume_optimizer_error_screen', {
        label: 'no_manual_input',
      })
      dispatch(actions.setShowManualInputScreen(true))
    } else if (showResultScreen) {
      trackInternalEvent('click_try_again_resume_optimizer_result_screen')
    }
  }, [dispatch, showErrorModal, showResultScreen])

  const handleStartManualTailoring = useCallback(() => {
    dispatch(
      actions.startImprovingResume({
        jobPostingLink,
        resumeId,
      }),
    )
    if (isAutoTailoringEnabled) {
      trackInternalEvent('click_edit_manually_resume_optimizer_result_screen')
    } else {
      trackInternalEvent('click_start_improving_resume_optimizer_result_screen')
    }
  }, [dispatch, isAutoTailoringEnabled, jobPostingLink, resumeId])

  const handleStartAutoTailoring = useCallback(() => {
    trackInternalEvent('click_auto_tailor_resume_optimizer_result_screen')
    tryToStartAutoTailoring()
    handleClose()
  }, [tryToStartAutoTailoring, handleClose])

  const handleHighlightMissedData = useCallback(() => {
    dispatch(
      actions.highlightEmptyWorkExperience({
        title: i18n.t('builder.auto_tailoring.work_experience.title'),
        content: i18n.t('builder.auto_tailoring.work_experience.description'),
        buttonLabel: i18n.t('builder.auto_tailoring.work_experience.button_text'),
      }),
    )
    trackInternalEvent('click_continue', {
      label: 'auto_tailoring_missing_info_screen',
    })
    handleClose()
  }, [dispatch, handleClose])

  const handleDownload = useCallback(() => {
    dispatch(renderingActions.download(resumeDownloadData))
    dispatch(actions.resetResumeDownloadData())
    dispatch(actions.setOpenOptimizerModal(false))
    dispatch(actions.setFeaturingAutoTailorModalOpenOnDownload(false))
    trackInternalEvent('click_download_generic', { label: 'auto_tailoring_download_button_screen' })
  }, [dispatch, resumeDownloadData])

  const handleOpenJobPosting = useCallback(() => {
    dispatch(actions.resetResumeDownloadData())
    dispatch(actions.setFeaturingAutoTailorModalOpenOnDownload(false))
    trackInternalEvent('click_auto_tailor', { label: 'auto_tailoring_download_button_screen' })
  }, [dispatch])

  useEffect(() => {
    if (isValidLink) {
      trackInternalEvent('send_job_posting_link', { status: 'ok', link: jobPostingLink })
    } else if (isInvalidLink) {
      trackInternalEvent('send_job_posting_link', { status: 'failed', link: jobPostingLink })
    }
  }, [isValidLink, isInvalidLink, jobPostingLink])

  useEffect(() => {
    if (
      fetchTailoredScoreStatus === FetchStatuses.notAsked &&
      !isDataMissed &&
      !isFeaturingAutoTailorModalOpenOnDownload
    ) {
      trackInternalEvent('see_resume_optimizer_link_screen')
    } else if (
      fetchTailoredScoreStatus === FetchStatuses.loaded &&
      !isFeaturingAutoTailorModalOpenOnDownload
    ) {
      trackInternalEvent('see_resume_optimizer_result_screen', { score: averageScore })
    }
  }, [
    fetchTailoredScoreStatus,
    averageScore,
    isDataMissed,
    isFeaturingAutoTailorModalOpenOnDownload,
  ])

  const topContainerView = useMemo(() => {
    if (isDataMissed) {
      return <NotEnoughDataView resumeId={resumeId} />
    }

    if (isFeaturingAutoTailorModalOpenOnDownload) {
      return <FeaturingAutoTailorOnDownloadTopView />
    }

    switch (fetchTailoredScoreStatus) {
      case FetchStatuses.notAsked:
        return <DataContainerInitialView {...{ jobPostingLink, isValidLink }} />
      case FetchStatuses.loading:
        return <DataContainerLoadingView />
      case FetchStatuses.loaded:
        return (
          <DataContainerResultView
            {...{ resumeId, resumeScore: averageScore, tailoredDesignation }}
          />
        )
    }
  }, [
    fetchTailoredScoreStatus,
    jobPostingLink,
    isValidLink,
    resumeId,
    tailoredDesignation,
    averageScore,
    isDataMissed,
    isFeaturingAutoTailorModalOpenOnDownload,
  ])

  useEffect(() => {
    if (isFeaturingAutoTailorModalOpenOnDownload && resumeDownloadData.id) {
      trackInternalEvent('see_auto_tailoring_download_button_screen', { label: 'without_link' })
    } else if (!isFeaturingAutoTailorModalOpenOnDownload && resumeDownloadData.id) {
      trackInternalEvent('see_auto_tailoring_download_button_screen', { label: 'with_link' })
    }
  }, [isFeaturingAutoTailorModalOpenOnDownload, resumeDownloadData.id])

  const bottomContainerView = useMemo(() => {
    if (isFeaturingAutoTailorModalOpenOnDownload) {
      return (
        <FeaturingAutoTailorOnDownloadBottomView
          onDownload={handleDownload}
          onStart={handleOpenJobPosting}
        />
      )
    }

    if (resumeDownloadData.id) {
      return (
        <DownloadGeneric
          jobPostingLink={jobPostingLink}
          onChange={handleLinkChange}
          Icon={linkValidationStatus && jobPostingLink ? statusIcons[linkValidationStatus] : null}
          error={isInvalidLink ? getStatusText(linkValidationStatus) : ''}
          onDownload={handleDownload}
          size={buttonSize}
          isDisabled={!isValidLink}
          onContinue={handleContinue}
        />
      )
    }

    if (isDataMissed) {
      return (
        <BottomContainerNotEnoughDataView
          onContinueClick={handleHighlightMissedData}
          onCancelClick={handleClose}
          buttonSize={buttonSize}
        />
      )
    }

    switch (fetchTailoredScoreStatus) {
      case FetchStatuses.notAsked:
        return (
          <BottomContainerInitialView
            jobPostingLink={jobPostingLink}
            onChange={handleLinkChange}
            Icon={linkValidationStatus && jobPostingLink ? statusIcons[linkValidationStatus] : null}
            error={isInvalidLink ? getStatusText(linkValidationStatus) : ''}
            status={!isInvalidLink ? getStatusText(linkValidationStatus) : ''}
            onClose={handleClose}
            size={buttonSize}
            isDisabled={!isValidLink}
            onContinue={handleContinue}
          />
        )

      case FetchStatuses.loading:
        return <BottomContainerLoadingView buttonSize={buttonSize} />

      case FetchStatuses.loaded:
        return (
          <BottomContainerResultView
            resumeScore={averageScore}
            onStartManualTailoring={handleStartManualTailoring}
            onStartAutoTailoring={handleStartAutoTailoring}
            onTryAgainClick={handleTryAgain}
            buttonSize={buttonSize}
            isSavingJobPostingData={saveJobPostingDataStatus === FetchStatuses.loading}
          />
        )
    }
  }, [
    isFeaturingAutoTailorModalOpenOnDownload,
    resumeDownloadData.id,
    isDataMissed,
    fetchTailoredScoreStatus,
    handleDownload,
    handleOpenJobPosting,
    jobPostingLink,
    handleLinkChange,
    linkValidationStatus,
    isInvalidLink,
    buttonSize,
    isValidLink,
    handleContinue,
    handleHighlightMissedData,
    handleClose,
    averageScore,
    handleStartManualTailoring,
    handleStartAutoTailoring,
    handleTryAgain,
    saveJobPostingDataStatus,
  ])

  return (
    <ModalOverlay overlayFadeDuration={300} contentSlideDuration={300}>
      {!showErrorModal ? (
        showManualInputScreen ? (
          <ManualInput onClose={handleClose} />
        ) : (
          <MainContainer>
            <TopContainer>
              <CloseIconWrapper
                showHoverState={saveJobPostingDataStatus !== FetchStatuses.loading}
                onClick={handleClose}
              >
                <CloseIcon absolutePosition={isFeaturingAutoTailorModalOpenOnDownload} />
              </CloseIconWrapper>
              {topContainerView}
              {!isFeaturingAutoTailorModalOpenOnDownload && <GradientContainer />}
            </TopContainer>

            <BottomContainer>{bottomContainerView}</BottomContainer>
          </MainContainer>
        )
      ) : (
        <ErrorState onClose={handleClose} onTryAgain={handleTryAgain} ButtonSize={buttonSize} />
      )}
    </ModalOverlay>
  )
}
