import { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import find from 'lodash/find'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import ModalOverlay from 'builder/components/ModalOverlay'
import Icon from 'builder/components/Icon'
import DocumentSelect from 'builder/components/DocumentSelect'
import Button from 'builder/components/Button'
import SelectPlaceholder from './SelectPlaceholder'
import { trackMarketingEvent } from '@rio/tracking'
import { DocumentTypes } from 'builder/modules/constants'
import { actions as renderingActions } from 'builder/modules/rendering'
import { actions as uiActions } from 'builder/modules/ui'
import { actions as panelActions } from 'builder/modules/panel'

import { useDocuments } from './hooks'

import {
  Container,
  MobileClose,
  DesktopClose,
  Title,
  Text,
  SelectContainer,
  SelectWrapper,
  Buttons,
  DownloadButton,
} from './styles'

const MergeDocumentsModal = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const modalResumeId = useSelector(state => state.ui.mergeModalResumeId)
  const modalCoverLetterId = useSelector(state => state.ui.mergeModalCoverLetterId)

  const { allResumes: resumes, coverLetters } = useDocuments()
  const [resumeId, setResumeId] = useState(modalResumeId)
  const [coverLetterId, setCoverLetterId] = useState(modalCoverLetterId)

  // Auto select the first resume
  useEffect(() => {
    if (!modalResumeId && resumes.length) setResumeId(resumes[0].id)
  }, [resumes, modalResumeId])

  // Auto select the first cover letter
  useEffect(() => {
    if (!modalCoverLetterId && coverLetters.length) setCoverLetterId(coverLetters[0].id)
  }, [coverLetters, modalCoverLetterId])

  // Trigger saga to download merged file
  const handleDownload = useCallback(() => {
    const resume = find(resumes, { id: resumeId })
    const coverLetter = find(coverLetters, { id: coverLetterId })
    dispatch(renderingActions.downloadMergedDocuments({ resume, coverLetter }))
    trackMarketingEvent('Dashboard', 'Download Merged Documents')
    dispatch(uiActions.closeMergeModal())
  }, [dispatch, coverLetters, resumes, coverLetterId, resumeId])

  const handleClose = useCallback(() => dispatch(uiActions.closeMergeModal()), [dispatch])

  const handleCreateResume = useCallback(() => {
    dispatch(panelActions.createDocument({ type: DocumentTypes.resume }))
    dispatch(uiActions.closeMergeModal())
  }, [dispatch])

  const handleCreateCoverLetter = useCallback(() => {
    dispatch(panelActions.createDocument({ type: DocumentTypes.coverLetter }))
    dispatch(uiActions.closeMergeModal())
  }, [dispatch])

  const { isPhone } = useMediaQueries()

  const Close = isPhone ? MobileClose : DesktopClose
  const CloseIcon = isPhone ? Icon.Close : Icon.CloseLarge

  const documentsToOptions = documents =>
    documents.map(document => ({
      ...document,
      name: document.name || i18n.t('builder.dashboard.untitled'),
    }))

  const resumesOptions = documentsToOptions(resumes)
  const coverLettersOptions = documentsToOptions(coverLetters)
  const ableToMerge = resumeId && coverLetterId

  return (
    <ModalOverlay
      onClick={handleClose}
      overlayFadeDuration={isPhone ? 0 : 150}
      contentSlideDuration={isPhone ? 0 : 150}
      fullScreen={isPhone}
    >
      <Container>
        <Close onClick={handleClose}>
          <CloseIcon />
        </Close>

        <Title>{i18n.t('builder.merge_modal.title')}</Title>
        <Text>{i18n.t('builder.merge_modal.text')}</Text>

        <SelectContainer>
          <SelectWrapper>
            {coverLettersOptions.length ? (
              <DocumentSelect
                options={coverLettersOptions}
                documentId={coverLetterId}
                label={i18n.t('builder.merge_modal.cover_letter')}
                onChange={setCoverLetterId}
              />
            ) : (
              <SelectPlaceholder
                label={i18n.t('builder.merge_modal.cover_letter')}
                text={i18n.t('builder.merge_modal.cover_letter_placeholder_text')}
                buttonText={i18n.t('builder.merge_modal.create_cover_letter')}
                onButtonClick={handleCreateCoverLetter}
              />
            )}
          </SelectWrapper>

          <SelectWrapper>
            {resumesOptions.length ? (
              <DocumentSelect
                options={resumesOptions}
                documentId={resumeId}
                label="Resume"
                onChange={setResumeId}
              />
            ) : (
              <SelectPlaceholder
                label={i18n.t('builder.merge_modal.resume')}
                text={i18n.t('builder.merge_modal.resume_placeholder_text')}
                buttonText={i18n.t('builder.merge_modal.create_new_resume')}
                onButtonClick={handleCreateResume}
              />
            )}
          </SelectWrapper>
        </SelectContainer>

        <Buttons>
          <DownloadButton isDisabled={!ableToMerge} onClick={handleDownload}>
            {i18n.t('builder.merge_modal.download_pdf')}
          </DownloadButton>
          <Button theme="ghost" onClick={handleClose}>
            {i18n.t('builder.merge_modal.cancel')}
          </Button>
        </Buttons>
      </Container>
    </ModalOverlay>
  )
}

export default MergeDocumentsModal
