import { uniqBy, differenceWith, isEqual, intersectionWith, minBy } from 'lodash'
import { all, put, call, takeLatest, select } from 'redux-saga/effects'
import { apiClient, baseClient } from 'builder/modules/apiClient'
import { FetchStatuses } from 'builder/modules/constants'
import { actions as uiActions, SnackbarTypes } from 'builder/modules/ui'
import ErrorLogger from 'builder/services/ErrorLogger'
import { actions, selectors } from './careerPathModule'
import {
  CareerCardType,
  CompanySummary,
  ColumnName,
  JobCard,
  JobProgressionsPath,
  Skill,
} from './types'
import { columnize, fetchSkillsAndMatchScore } from './utils'
import { SUCCESS_MESSAGE } from './constants'

const delay = (time: number) => new Promise(resolve => setTimeout(resolve, time))

export function* fetchSuggestedSkillsSaga({
  payload,
}: ReturnType<typeof actions.fetchSuggestedSkills>) {
  try {
    const selectedSkills: Skill[] = yield select(selectors.skills)
    const params = { title: payload }

    const { data } = yield call(baseClient.get, '/career-data/v1/title-skills', {
      params,
    })

    const suggestedSkills = data.hardSkills.map((skill: string) => ({
      value: skill.toLowerCase(),
      label: skill,
    }))

    // set unique suggested skills
    const uniqueSuggestedSkills = differenceWith(suggestedSkills, selectedSkills, isEqual)

    yield put(actions.setSuggestedSkills(uniqueSuggestedSkills))
    yield put(actions.setSuggestedSkillsCopy(uniqueSuggestedSkills))
  } catch (error) {
    console.log(error)
  }
}

export function* fetchUserDocumentsSaga() {
  try {
    const { data } = yield call(apiClient.get, `/resumes`)

    yield put(actions.setUserDocuments(data.resumes))
  } catch (error) {
    console.log(error)
  }
}

export function* fetchResumeSkillsSaga({ payload }: ReturnType<typeof actions.fetchResumeSkills>) {
  try {
    const skills: Skill[] = yield select(selectors.skills)
    const suggestedSkills: Skill[] = yield select(selectors.suggestedSkills)

    const params = { resume_id: payload }

    const { data } = yield call(baseClient.get, `/career-data/v1/resume-skills`, { params })

    if (!data.length) {
      yield put(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.failure,
          text: 'Failed to load skills from resume',
        }),
      )

      yield call(delay, 3000)

      yield put(
        uiActions.setSnackBarOpen({
          status: false,
          type: SnackbarTypes.failure,
        }),
      )
    } else {
      const resumeSkills = data.map((skill: string) => ({
        value: skill.toLowerCase(),
        label: skill,
      }))

      // set unique skills
      const uniqueSkills = uniqBy([...skills, ...resumeSkills], skill => skill.value)
      // set unique suggested skills
      const uniqueSuggestedSkills = differenceWith(suggestedSkills, resumeSkills, isEqual)

      yield put(actions.setSkills([...uniqueSkills]))
      yield put(actions.setSuggestedSkills([...uniqueSuggestedSkills]))

      // close skills prefill modal
      yield put(actions.setResumeID(null))
      yield put(actions.setOpenPreFillModal(false))
    }
  } catch (error) {
    console.log(error)
  }
}

export function* fetchJobTitlesSaga({ payload }: ReturnType<typeof actions.fetchJobTitles>) {
  const { query, level, selectedValue } = payload

  try {
    yield put(actions.setFetchJobProgressionStatus(FetchStatuses.loading))

    const params = { title: query }

    const skills: Skill[] = yield select(selectors.skills)
    const skillsLabel = skills.map(skill => skill.label)

    const { data } = yield call(baseClient.get, `/career-data/v1/job-progression`, { params })

    let jobProgressionsPath = data.filter((_: JobProgressionsPath, index: number) => index < 9)

    const res: ReturnType<typeof fetchSkillsAndMatchScore> = yield call(
      fetchSkillsAndMatchScore,
      jobProgressionsPath,
      skillsLabel,
    )
    jobProgressionsPath = res

    switch (level) {
      case 1: {
        yield put(actions.setLevel2JobTitles(jobProgressionsPath))
        break
      }
      case 2: {
        yield put(actions.setLevel3JobTitles(jobProgressionsPath))
        break
      }
      default: {
        if (selectedValue) {
          let levelJobTitleMap: JobProgressionsPath[]
          const index = jobProgressionsPath.findIndex(
            (jobTitle: JobProgressionsPath) => jobTitle?.target === selectedValue,
          )
          if (index > -1) {
            levelJobTitleMap = [jobProgressionsPath[index]]
            jobProgressionsPath.forEach((val: JobProgressionsPath, mapIndex: number) => {
              if (mapIndex !== index) {
                levelJobTitleMap.push(val)
              }
              return val
            })

            yield put(actions.setLevel1JobTitles(levelJobTitleMap))
          }
        } else {
          yield put(actions.setLevel1JobTitles(jobProgressionsPath))
        }
      }
    }

    yield put(actions.setFetchJobProgressionStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchJobProgressionStatus(FetchStatuses.failed))
    console.log(error)
  }
}

export function* saveCareerPathsSaga({ payload }: ReturnType<typeof actions.saveCareerPaths>) {
  const { title, skills, pathways } = payload

  try {
    const params = {
      title,
      skills,
      pathways,
    }

    yield call(apiClient.post, `/career-pathways/v2/cards`, { ...params })
  } catch (error) {
    console.log(error)
  }
}

export function* fetchCareerCardsSaga() {
  try {
    yield put(actions.setFetchGoalsStatus(FetchStatuses.loading))

    const { data } = yield call(apiClient.get, `/career-pathways/v2/cards`)

    yield put(actions.setAllCareerCards(data.careerCards))
    yield put(actions.setFetchGoalsStatus(FetchStatuses.loaded))
  } catch (error) {
    console.log(error)
    yield put(actions.setFetchGoalsStatus(FetchStatuses.failed))
  }
}

export function* fetchRequiredSkillsSaga({
  payload,
}: ReturnType<typeof actions.fetchRequiredSkills>) {
  try {
    const params = { title: payload }
    const skills: Skill[] = yield select(selectors.skills)
    const skillsLabel = skills.map(skill => skill.label)

    const { data } = yield call(baseClient.get, '/career-data/v1/title-skills', {
      params,
    })

    const { hardSkills, softSkills } = data
    const requiredSkills = [...hardSkills, ...softSkills]

    const uniqueSkills = differenceWith(requiredSkills, skillsLabel, isEqual)
    const commonSkills = intersectionWith(requiredSkills, skillsLabel, isEqual)

    const matchScore = (commonSkills.length / requiredSkills.length) * 100

    yield put(actions.setRequiredSkills([...commonSkills, ...uniqueSkills]))
    yield put(actions.setSkillsMatchScore(matchScore))
  } catch (error) {
    console.log(error)
  }
}

export function* updateCareerPathsSaga({ payload }: ReturnType<typeof actions.updateCareerPath>) {
  const { title, skills, pathways } = payload
  const editCareerCardData: CareerCardType = yield select(selectors.editCareerCardData)
  try {
    const params = {
      title,
      skills,
      pathways,
    }

    yield call(apiClient.put, `/career-pathways/v2/cards/${editCareerCardData.id}`, { ...params })
    yield put(
      actions.setEditCareerCardData({
        id: 0,
        title: '',
        skills: [],
        pathways: { path1: [] },
      }),
    )
  } catch (error) {
    console.log(error)
  }
}

export function* fetchJobTitlesLevelTwoSaga({
  payload,
}: ReturnType<typeof actions.fetchJobTitlesLevelTwo>) {
  const { query, selectedValue } = payload

  try {
    yield put(actions.setFetchJobProgressionStatus(FetchStatuses.loading))

    const params = { title: query }
    const skills: Skill[] = yield select(selectors.skills)
    const skillsLabel = skills.map(skill => skill.label)

    const { data } = yield call(baseClient.get, `/career-data/v1/job-progression`, { params })

    let jobProgressionsPath = data.filter((_: JobProgressionsPath, index: number) => index < 9)

    const res: ReturnType<typeof fetchSkillsAndMatchScore> = yield call(
      fetchSkillsAndMatchScore,
      jobProgressionsPath,
      skillsLabel,
    )
    jobProgressionsPath = res

    let levelJobTitleMap: JobProgressionsPath[]
    const index = jobProgressionsPath.findIndex(
      (jobTitle: JobProgressionsPath) => jobTitle?.target === selectedValue,
    )
    if (index > -1) {
      levelJobTitleMap = [jobProgressionsPath[index]]
      jobProgressionsPath.forEach((val: JobProgressionsPath, mapIndex: number) => {
        if (mapIndex !== index) {
          levelJobTitleMap.push(val)
        }
        return val
      })
      yield put(actions.setLevel2JobTitles(levelJobTitleMap))
    }

    yield put(actions.setFetchJobProgressionStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchJobProgressionStatus(FetchStatuses.failed))
    console.log(error)
  }
}

export function* fetchJobTitlesLevelThreeSaga({
  payload,
}: ReturnType<typeof actions.fetchJobTitlesLevelThree>) {
  const { query, selectedValue } = payload

  try {
    yield put(actions.setFetchJobProgressionStatus(FetchStatuses.loading))

    const params = { title: query }
    const skills: Skill[] = yield select(selectors.skills)
    const skillsLabel = skills.map(skill => skill.label)

    const { data } = yield call(baseClient.get, `/career-data/v1/job-progression`, { params })

    let jobProgressionsPath = data.filter((_: JobProgressionsPath, index: number) => index < 9)

    const res: ReturnType<typeof fetchSkillsAndMatchScore> = yield call(
      fetchSkillsAndMatchScore,
      jobProgressionsPath,
      skillsLabel,
    )
    jobProgressionsPath = res

    let levelJobTitleMap: JobProgressionsPath[]
    const index = jobProgressionsPath.findIndex(
      (jobTitle: JobProgressionsPath) => jobTitle?.target === selectedValue,
    )
    if (index > -1) {
      levelJobTitleMap = [jobProgressionsPath[index]]
      jobProgressionsPath.forEach((val: JobProgressionsPath, mapIndex: number) => {
        if (mapIndex !== index) {
          levelJobTitleMap.push(val)
        }
        return val
      })
      yield put(actions.setLevel3JobTitles(levelJobTitleMap))
    }

    yield put(actions.setFetchJobProgressionStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchJobProgressionStatus(FetchStatuses.failed))
    console.log(error)
  }
}

export function* deletePathsCardSaga({ payload }: ReturnType<typeof actions.deletePathsCard>) {
  const { id } = payload

  const params = {
    id,
  }

  try {
    yield call(apiClient.delete, `/career-pathways/v2/cards/${id}`, { params })
    yield put(actions.deletePaths({ id }))
  } catch (error) {
    console.log(error)
  }
}

export function* fetchCoursesSaga({ payload }: ReturnType<typeof actions.fetchCourses>) {
  try {
    const { title } = payload

    const params = {
      title,
    }

    yield put(actions.setFetchCoursesStatus(FetchStatuses.loading))

    const { data } = yield call(baseClient.get, `/career-data/v1/courses`, { params })
    yield put(actions.setCourses(data))

    yield put(actions.setFetchCoursesStatus(FetchStatuses.loaded))
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.setFetchCoursesStatus(FetchStatuses.failed))
  }
}

// fetchSalaryDetails
export function* fetchSalaryDetailsSaga({
  payload,
}: ReturnType<typeof actions.fetchSalaryDetails>) {
  try {
    const { title, location, education, experience } = payload

    const params = {
      title,
      location,
      education: education || '',
      experience: experience || '',
    }
    yield put(actions.setFetchSalaryStatus(FetchStatuses.loading))

    const { data } = yield call(baseClient.get, '/career-data/v1/salary', { params })

    yield put(actions.setSalaryDetails(data))
    yield put(actions.setFetchSalaryStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchSalaryStatus(FetchStatuses.failed))
  }
}

export function* fetchCareerPagesJobDetailsSaga({
  payload,
}: ReturnType<typeof actions.fetchCareerPagesJobDetails>) {
  try {
    yield put(actions.setFetchCareerPagesJobDetailsStatus(FetchStatuses.loading))

    const params = { title: payload }

    const { data } = yield call(baseClient.get, `/career-data/v1/attributes`, { params })

    yield put(actions.setCareerPagesJobDetails(data))
    yield put(actions.setFetchCareerPagesJobDetailsStatus(FetchStatuses.loaded))
  } catch (error) {
    console.log(error)
  }
}

export function* fetchRecommendedJobSaga({
  payload,
}: ReturnType<typeof actions.fetchRecommendedJobCard>) {
  try {
    yield put(actions.setJobCardStatus(FetchStatuses.loading))

    const { query, location, page, perPage } = payload

    const params = {
      query,
      location: location === '' ? undefined : location,
      page,
      perPage,
    }

    const { data } = yield call(baseClient.get, `/jobs/v1/search`, { params })

    yield put(actions.setFetchRecommendedJobCard(data.jobs))
    yield put(actions.setJobCardStatus(FetchStatuses.loaded))
  } catch (error) {
    console.log(error)
  }
}

function* fetchJobCardSaga() {
  try {
    const { data } = yield call(apiClient.get, '/job-board/cards')

    yield put(actions.setJobsData(data))
  } catch (error) {
    console.log(error)
  }
}

function* deleteJobCardSaga(action: ReturnType<typeof actions.deleteJobCardRequest>) {
  try {
    const { id } = action.payload

    yield call(apiClient.delete, `/job-board/cards/${id}`)
    yield put(actions.deleteJob({ id }))
  } catch (error) {
    console.log(error)
  }
}

function* createJobCardSaga({ payload }: ReturnType<typeof actions.createJobCardRequest>) {
  try {
    const { slug, ...fields } = payload
    const status = fields.status || ColumnName.wishlist

    const jobs: JobCard[] = yield select(selectors.selectJobs)
    const columns = columnize(jobs as JobCard[])
    const firstItem = minBy(columns[status], 'positionNumber')

    const {
      data: { jobCard, success },
    } = yield call(apiClient.post, '/job-board/cards', {
      ...fields,
      status,
      position_number: firstItem ? firstItem?.positionNumber - 1 : 0,
      external_slug_id: slug,
    })

    yield put(actions.addJob(jobCard))

    // Trigger snackbar
    if (success) {
      yield put(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.openable,
          url: '/app/job-tracking',
          text: SUCCESS_MESSAGE,
        }),
      )

      yield call(delay, 3000)

      yield put(
        uiActions.setSnackBarOpen({
          status: false,
          type: SnackbarTypes.openable,
        }),
      )
    }
  } catch (error) {
    console.log(error)
  }
}

export function* fetchJobDemandSaga({ payload }: ReturnType<typeof actions.fetchJobDemand>) {
  try {
    yield put(actions.setJobDemandStatus(FetchStatuses.loading))

    const params = {
      job_title: payload.job_title,
      location: payload.location === '' ? undefined : payload.location,
    }

    const { data } = yield call(baseClient.get, `/jobs/v1/search/companies/jobs-count`, { params })

    const { total_jobs: jobsCount, companies, total_companies: companiesCount } = data

    const companiesArray: CompanySummary[] = Object.entries<number>(companies).map(
      ([name, jobPostings]) => ({
        name,
        jobPostings,
      }),
    )

    yield put(actions.setJobDemand({ jobsCount, companiesCount, companies: companiesArray }))
    yield put(actions.setJobDemandStatus(FetchStatuses.loaded))
  } catch (error) {
    console.log(error)
  }
}

// save user's salary feedback
export function* saveSalaryFeedbackSaga({
  payload,
}: ReturnType<typeof actions.saveSalaryFeedback>) {
  try {
    const params = { ...payload }

    yield call(baseClient.post, '/career-data/v1/salary-feedback', { ...params })
  } catch (error) {
    console.log(error)
  }
}

/** Bind side-effect handlers */
export const sagas = function* saga() {
  yield all([takeLatest(actions.fetchSuggestedSkills, fetchSuggestedSkillsSaga)])
  yield all([takeLatest(actions.fetchUserDocuments, fetchUserDocumentsSaga)])
  yield all([takeLatest(actions.fetchResumeSkills, fetchResumeSkillsSaga)])
  yield all([takeLatest(actions.fetchJobTitles, fetchJobTitlesSaga)])
  yield all([takeLatest(actions.fetchCareerCards, fetchCareerCardsSaga)])
  yield all([takeLatest(actions.saveCareerPaths, saveCareerPathsSaga)])
  yield all([takeLatest(actions.fetchRequiredSkills, fetchRequiredSkillsSaga)])
  yield all([takeLatest(actions.updateCareerPath, updateCareerPathsSaga)])
  yield all([takeLatest(actions.fetchJobTitlesLevelTwo, fetchJobTitlesLevelTwoSaga)])
  yield all([takeLatest(actions.fetchJobTitlesLevelThree, fetchJobTitlesLevelThreeSaga)])
  yield all([takeLatest(actions.deletePathsCard, deletePathsCardSaga)])
  yield all([takeLatest(actions.fetchCourses, fetchCoursesSaga)])
  yield all([takeLatest(actions.fetchSalaryDetails, fetchSalaryDetailsSaga)])
  yield all([takeLatest(actions.saveSalaryFeedback, saveSalaryFeedbackSaga)])
  yield all([takeLatest(actions.fetchCareerPagesJobDetails, fetchCareerPagesJobDetailsSaga)])
  yield all([takeLatest(actions.fetchRecommendedJobCard, fetchRecommendedJobSaga)])
  yield all([takeLatest(actions.fetchJobsRequest, fetchJobCardSaga)])
  yield all([takeLatest(actions.deleteJobCardRequest, deleteJobCardSaga)])
  yield all([takeLatest(actions.createJobCardRequest, createJobCardSaga)])
  yield all([takeLatest(actions.fetchJobDemand, fetchJobDemandSaga)])
}
