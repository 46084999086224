import { useMutation } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'

type EtagBody = {
  part_number: number
  etag: string
}
export type CompleteInterviewVideoParams = {
  questionId: number
  answerId: number
  answerDuration: number
  totalBytes: number
  signal: AbortSignal
  type: 'audio' | 'video'
  etags: EtagBody[]
}

export const useCompleteInterview = () => {
  return useMutation({
    mutationFn: (params: CompleteInterviewVideoParams): Promise<Response> => {
      return baseClient.post(
        // eslint-disable-next-line max-len
        `/interview_prep/questions/${params.questionId}/answer/complete_${params.type}`,
        {
          answer_duration: params.answerDuration,
          total_bytes: params.totalBytes,
          body: JSON.stringify({
            [params.type]: params.etags,
          }),
        },
        { signal: params.signal },
      )
    },
  })
}
