import { useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { FillByUrlParamValues, FILL_BY_URL_PARAM } from '../constants'
import { useResumePrefill } from '../hooks/useResumePrefill'
import FileSelector from '../UploadResume/FileSelector/FileSelector'

export const FillResumeByUrl = () => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const { handleCreateWithAi, handleUseExample, handleResumeUpload } = useResumePrefill()

  useEffectOnMount(() => {
    if (!searchParams.has(FILL_BY_URL_PARAM)) {
      return
    }

    const fillBy = searchParams.get(FILL_BY_URL_PARAM)

    switch (fillBy) {
      case FillByUrlParamValues.createWithAi:
        handleCreateWithAi()
        break
      case FillByUrlParamValues.useExample:
        handleUseExample()
        break
      case FillByUrlParamValues.uploadResume:
        handleResumeUpload(fileInputRef)
        break
    }

    searchParams.delete(FILL_BY_URL_PARAM)

    setSearchParams(searchParams, { replace: true })
  })

  return <FileSelector ref={fileInputRef} />
}
