import { createAction, createReducer } from '@reduxjs/toolkit'
import { trackInternalEvent } from '@rio/tracking'
import { Store } from 'builder/modules/store'
import { FetchStatuses } from 'builder/modules/constants'
import { BriefResume } from 'builder/modules/panel'
import { AvatarUploadPayload } from 'builder/modules/resumeEditor'
import { createProfilePictureSelector } from 'builder/modules/user'
import { RecommendationsRequiredAttributesType } from 'builder/components/FindJob/types'
import {
  CareerProfileStore,
  Question,
  Answer,
  QuestionFromApiResponse,
  ChatPersona,
  CareerInsightsData,
  PaywallModalType,
  CareerProfileCompletenessPendingItem,
  CareerInsightsPageType,
  CareerProfileData,
  CareerInsightsProcessingStatus,
  LocalStorageCareerProfileState,
  TemporalModalType,
  StructuredResumeData,
} from './types'
import { getTodaysDateString } from './utils'
import { MAX_FLOATING_HELPER_PROMOTION_DAYS } from './consts'

// ---
// Action creators
// ---
export const actions = {
  fetchQuestions: createAction('builder/CAREER_PROFILE_CHAT_FETCH_QUESTIONS'),
  setFetchQuestionsStatus: createAction<FetchStatuses>(
    'builder/CAREER_PROFILE_CHAT_SET_FETCH_QUESTIONS_STATUS',
  ),
  setInitialQuestions: createAction<QuestionFromApiResponse[]>(
    'builder/CAREER_PROFILE_CHAT_SET_INITIAL_QUESTIONS',
  ),
  setQuestions: createAction<Question[]>('builder/CAREER_PROFILE_CHAT_SET_QUESTIONS'),
  setAsked: createAction<Question[]>('builder/CAREER_PROFILE_CHAT_SET_ASKED'),
  setAnswers: createAction<Answer[]>('builder/CAREER_PROFILE_CHAT_SET_ANSWERS'),
  saveAnswer: createAction<Answer>('builder/CAREER_PROFILE_CHAT_SAVE_ANSWER'),
  setSelectedResume: createAction<BriefResume | null>('builder/CAREER_PROFILE_CHAT_SET_RESUME'),
  setLatestResume: createAction<BriefResume | null>(
    'builder/CAREER_PROFILE_CHAT_SET_COMMIT_RESUME',
  ),
  setSelectedResumeText: createAction<string | null>(
    'builder/CAREER_PROFILE_CHAT_SET_SELECTED_RESUME_TEXT',
  ),
  setStructuredResumeData: createAction<StructuredResumeData | null>(
    'builder/CAREER_PROFILE_SET_STRUCTURED_RESUME_DATA',
  ),
  setFetchSelectedResumeStatus: createAction<FetchStatuses>(
    'builder/CAREER_PROFILE_CHAT_SET_SELECTED_RESUME_FETCH_STATUS',
  ),
  postChatbotAnswers: createAction<void>('builder/CAREER_PROFILE_CHAT_POST_ANSWERS'),
  setPersona: createAction<ChatPersona | null>('builder/CAREER_PROFILE_CHAT_SET_PERSONA'),
  parseResume: createAction<File>('builder/CAREER_PROFILE_CHAT_PARSE_RESUME'),
  setUploadedFilename: createAction<string | null>('builder/CAREER_PROFILE_CHAT_SET_FILENAME'),
  setIsDataSentDuringCurrentSession: createAction<boolean>(
    'builder/CAREER_PROFILE_CHAT_SET_SET_IS_DATA_SENT_DURING_CURRENT_SESSION',
  ),
  setFetchCareerInsightsStatus: createAction<FetchStatuses>(
    'builder/CAREER_PROFILE_SET_FETCH_CAREER_INSIGHTS_STATUS',
  ),
  startCareerInsightsPolling: createAction<void>(
    'builder/CAREER_PROFILE_START_CAREER_INSIGHTS_POLLING',
  ),
  setCareerInsightsData: createAction<CareerInsightsData | null>(
    'builder/CAREER_PROFILE_SET_CAREER_INSIGHTS_DATA',
  ),
  setOpenedPaywallModalType: createAction<PaywallModalType | null>(
    'builder/CAREER_PROFILE_SET_OPENED_PAYWALL_MODAL_TYPE',
  ),
  setIsOptimizeResumeModalOpened: createAction<boolean>(
    'builder/CAREER_PROFILE_SET_IS_OPTIMIZE_RESUME_MODAL_OPENED',
  ),
  fetchCompleteness: createAction<void>('builder/CAREER_PROFILE_FETCH_COMPLETENESS'),
  setFetchCompletenessStatus: createAction<FetchStatuses>(
    'builder/CAREER_PROFILE_SET_FETCH_COMPLETENESS_STATUS',
  ),
  setCompletenessPercent: createAction<number>('builder/CAREER_PROFILE_SET_COMPLETENESS_PERCENT'),
  setCompletenessPendingItems: createAction<CareerProfileCompletenessPendingItem[]>(
    'builder/CAREER_PROFILE_SET_COMPLETENESS_PENDING_ITEMS',
  ),
  setSeenPage: createAction<CareerInsightsPageType>('builder/CAREER_PROFILE_SET_SET_SEEN_PAGE'),
  updateProfileData: createAction<Partial<CareerProfileData>>(
    'builder/CAREER_PROFILE_UPDATE_PROFILE_DATA',
  ),
  updateProfileImage: createAction<AvatarUploadPayload>(
    'builder/CAREER_PROFILE_UPDATE_PROFILE_IMAGE',
  ),
  deleteProfileImage: createAction<void>('builder/CAREER_PROFILE_DELETE_PROFILE_IMAGE'),
  fetchCareerProfile: createAction<void>('builder/CAREER_PROFILE_FETCH_CAREER_PROFILE'),
  setFetchCareerProfileStatus: createAction<FetchStatuses>(
    'builder/CAREER_PROFILE_SET_FETCH_CAREER_PROFILE_STATUS',
  ),
  setCareerProfileData: createAction<CareerProfileData>(
    'builder/CAREER_PROFILE_SET_CAREER_PROFILE_DATA',
  ),
  setCareerProfileStateFromLocalStorage: createAction<Partial<LocalStorageCareerProfileState>>(
    'builder/CAREER_PROFILE_CHAT_DATA_SYNCHRONIZED_WITH_LOCAL_STORAGE',
  ),
  setIsSyncedWithLocalStorage: createAction<boolean>(
    'builder/CAREER_PROFILE_SET_IS_SYNCED_WITH_LOCAL_STORAGE',
  ),
  setCareerInsightsStatusModalType: createAction<CareerInsightsPageType | null>(
    'builder/CAREER_PROFILE_SET_CAREER_INSIGHTS_STATUS_MODAL_TYPE',
  ),
  setSeenTemporalModal: createAction<TemporalModalType>(
    'builder/CAREER_PROFILE_SET_SEEN_TEMPORAL_MODAL',
  ),
  setInitialChatState: createAction<void>('builder/CAREER_PROFILE_SET_INITIAL_CHAT_STATE'),
  setIsProfilePhotoUploading: createAction<boolean>(
    'builder/CAREER_PROFILE_SET_IS_PROFILE_PHOTO_UPLOADING',
  ),
  fetchIsAutoApplyComplete: createAction<void>(
    'builder/CAREER_PROFILE_FETCH_IS_AUTO_APPLY_COMPLETE',
  ),
  setFetchIsAutoApplyCompleteStatus: createAction<FetchStatuses>(
    'builder/CAREER_PROFILE_SET_FETCH_IS_AUTO_APPLY_COMPLETE_STATUS',
  ),
  setIsAutoApplyComplete: createAction<boolean>(
    'builder/CAREER_PROFILE_SET_IS_AUTO_APPLY_COMPLETE',
  ),
  addFloatingHelperPromotionDay: createAction<void>(
    'builder/CAREER_PROFILE_ADD_FLOATING_HELPER_PROMOTION_DAY',
  ),
  setIsCompletenessResultClosed: createAction<boolean>(
    'builder/CAREER_PROFILE_SET_IS_COMPLETENESS_RESULT_CLOSED',
  ),
  setMobileProfileMenu: createAction<boolean>('builder/CAREER_PROFILE_OPEN_PROFILE_MENU'),
  prefillProfile: createAction<void>('builder/CAREER_PROFILE_PREFILL_PROFILE'),
  prefillProfileAndFetchProfile: createAction<void>(
    'builder/CAREER_PROFILE_PREFILL_PROFILE_AND_FETCH_PROFILE',
  ),
  setFetchIsNewProfileStatus: createAction<FetchStatuses>(
    'builder/CAREER_PROFILE_SET_FETCH_IS_NEW_PROFILE_STATUS',
  ),
  setIsNewProfile: createAction<boolean>('builder/CAREER_PROFILE_SET_IS_NEW_PROFILE'),
  setOnboardingStructuredResumeData: createAction<StructuredResumeData | null>(
    'builder/CAREER_PROFILE_SET_ONBOARDING_STRUCTURED_RESUME_DATA',
  ),
  parseResumeOnboarding: createAction<{ file: File; redirectUrl: string }>(
    'builder/CAREER_PROFILE_PARSE_RESUME_ONBOARDING',
  ),
  setWasProfilePreffiled: createAction<boolean>('builder/CAREER_PROFILE_SET_WAS_PROFILE_PREFILLED'),
  setRecommendationsMissingAttributes: createAction<RecommendationsRequiredAttributesType[]>(
    'builder/CAREER_PROFILE_SET_RECOMMENDATIONS_MISSING_ATTRIBUTES',
  ),
}

// ---
// Selectors
// ---
export const selectors = {
  fetchQuestionsStatus: (state: Store): FetchStatuses =>
    state.careerProfile.chat.fetchQuestionsStatus,
  questions: (state: Store): Question[] => state.careerProfile.chat.questions,
  asked: (state: Store): Question[] => state.careerProfile.chat.asked,
  answers: (state: Store): Answer[] => state.careerProfile.chat.answers,
  selectedResume: (state: Store) => state.careerProfile.chat.selectedResume,
  latestResume: (state: Store) => state.careerProfile.chat.latestResume,
  selectedResumeText: (state: Store) => state.careerProfile.chat.selectedResumeText,
  persona: (state: Store): ChatPersona | null => state.careerProfile.chat.persona,
  uploadedFilename: (state: Store): string | null => state.careerProfile.chat.uploadedFilename,
  isDataSentDuringCurrentSession: (state: Store) =>
    state.careerProfile.chat.isDataSentDuringCurrentSession,
  careerInsightsData: (state: Store) => state.careerProfile.careerInsights.data,
  careerAssessmentResults: (state: Store) =>
    state.careerProfile.careerInsights.data?.careerAssessmentResults,
  fetchCareerInsightsStatus: (state: Store) =>
    state.careerProfile.careerInsights.fetchCareerInsightsStatus,
  openedPaywallModalType: (state: Store) => state.careerProfile.openedPaywallModalType,
  isOptimizeResumeModalOpened: (state: Store) => state.careerProfile.isOptimizeResumeModalOpened,
  isCompletenessFetchNeeded: (state: Store) =>
    state.careerProfile.completeness.fetchStatus === FetchStatuses.notAsked,
  completenessPercent: (state: Store) => state.careerProfile.completeness.percent,
  completenessPendingItems: (state: Store) => state.careerProfile.completeness.pendingItems,
  isCompletenessResultClosed: (state: Store) => state.careerProfile.completeness.isResultClosed,
  seenCareerProfilePages: (state: Store) => state.careerProfile.seenCareerProfilePages,
  isCareerAssessmentRequired: (state: Store) => {
    const { fetchCareerInsightsStatus: status, data } = state.careerProfile.careerInsights
    const isChatbotCompleted = state.careerProfile.chat.isDataSentDuringCurrentSession
    const isInsightsLoaded = status === FetchStatuses.loaded || status === FetchStatuses.failed
    return !isChatbotCompleted || (isInsightsLoaded && !data)
  },
  isCareerInsightsProcessed: (state: Store) =>
    state.careerProfile.careerInsights.data?.status === CareerInsightsProcessingStatus.success,
  isPageMarkedAsNew: (state: Store, page: CareerInsightsPageType) => {
    const isPageSeen = state.careerProfile.seenCareerProfilePages[page]
    const isDataProcessed = selectors.isCareerInsightsProcessed(state)
    return isDataProcessed && !isPageSeen
  },
  profileTargetRoles: (state: Store) => state.careerProfile.profile.data?.targetRoles,
  profileTargetLocations: (state: Store) =>
    state.careerProfile.profile.data?.targetLocations
      ? state.careerProfile.profile.data?.targetLocations.filter(el => el.formattedName !== '')
      : [],
  fetchCareerProfileStatus: (state: Store) => state.careerProfile.profile.fetchStatus,
  careerProfileData: (state: Store) => state.careerProfile.profile.data,
  careerInsightsStatusModalType: (state: Store) =>
    state.careerProfile.careerInsightsStatusModalType,
  dataToSyncWithLocalStorage: (state: Store): LocalStorageCareerProfileState => ({
    chat: state.careerProfile.chat,
    seenCareerProfilePages: state.careerProfile.seenCareerProfilePages,
    seenTemporalModals: state.careerProfile.seenTemporalModals,
    floatingHelperPromotionDays: state.careerProfile.floatingHelperPromotionDays,
    completeness: {
      isResultClosed: state.careerProfile.completeness.isResultClosed,
    },
  }),
  isSyncedWithLocalStorage: (state: Store) => state.careerProfile.isSyncedWithLocalStorage,
  isTemporalModalSeen: (state: Store, type: TemporalModalType) =>
    state.careerProfile.seenTemporalModals[type],
  structuredResumeData: (state: Store) => state.careerProfile.chat.structuredResumeData,
  isProfilePhotoUploading: (state: Store) => state.careerProfile.profile.isPhotoUploading,
  profilePictureUrl: createProfilePictureSelector(
    (state: Store) => state.careerProfile.profile.data?.profilePicture,
  ),
  isFloatingHelperPromotionNeeded: (state: Store) => {
    const isSyncedWithLocalStorage = selectors.isSyncedWithLocalStorage(state)
    const { floatingHelperPromotionDays } = state.careerProfile
    const isDaysExceeded = floatingHelperPromotionDays.length > MAX_FLOATING_HELPER_PROMOTION_DAYS
    const isAlreadySeenToday = floatingHelperPromotionDays.includes(getTodaysDateString())
    return isSyncedWithLocalStorage && !isDaysExceeded && !isAlreadySeenToday
  },
  isAutoApplyComplete: (state: Store) => state.careerProfile.autoApply.isComplete,
  fetchIsAutoApplyCompleteStatus: (state: Store) => state.careerProfile.autoApply.fetchStatus,
  isMobileProfileMenuOpen: (state: Store) => state.careerProfile.mobileProfileMenu,
  isNewProfile: (state: Store) => state.careerProfile.onboarding.isNewProfile,
  fetchIsNewProfileStatus: (state: Store) => state.careerProfile.onboarding.fetchIsNewProfileStatus,
  onboardingStructuredResumeData: (state: Store) =>
    state.careerProfile.onboarding.structuredResumeData,
  wasProfilePrefilled: (state: Store) => state.careerProfile.onboarding.wasProfilePrefilled,
  recommendationsMissingAttributes: (state: Store) =>
    state.careerProfile.recommendations.missingAttributes,
}

// ---
// Reducer
// ---
const initialState: CareerProfileStore = {
  chat: {
    fetchQuestionsStatus: FetchStatuses.notAsked,
    fetchSelectedResumeStatus: FetchStatuses.notAsked,
    questions: [],
    asked: [],
    answers: [],
    selectedResume: null,
    latestResume: null,
    selectedResumeText: null,
    persona: null,
    isDataSentDuringCurrentSession: false,
    uploadedFilename: null,
    structuredResumeData: null,
  },
  careerInsights: {
    fetchCareerInsightsStatus: FetchStatuses.notAsked,
    data: null,
  },
  openedPaywallModalType: null,
  isOptimizeResumeModalOpened: false,
  completeness: {
    fetchStatus: FetchStatuses.notAsked,
    percent: null,
    pendingItems: [],
    isResultClosed: false,
  },
  seenCareerProfilePages: {
    insights: false,
    pitch: false,
    'cover-letter': false,
  },
  profile: {
    fetchStatus: FetchStatuses.notAsked,
    data: null,
    isPhotoUploading: false,
  },
  isSyncedWithLocalStorage: false,
  careerInsightsStatusModalType: null,
  seenTemporalModals: {
    'insights-result': false,
    promotion: false,
  },
  floatingHelperPromotionDays: [],
  autoApply: {
    isComplete: false,
    fetchStatus: FetchStatuses.notAsked,
  },
  mobileProfileMenu: false,
  onboarding: {
    fetchIsNewProfileStatus: FetchStatuses.notAsked,
    isNewProfile: false,
    structuredResumeData: null,
    wasProfilePrefilled: false,
  },
  recommendations: {
    missingAttributes: [],
  },
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setFetchQuestionsStatus, (state, action) => {
    state.chat.fetchQuestionsStatus = action.payload
  })
  reducer.addCase(actions.setInitialQuestions, (state, action) => {
    let id = 1
    state.chat.questions = action.payload.map(question => {
      return {
        id: String(id++),
        text: question.question,
        hint: question.hint,
        response: {
          id: String(id++),
          // fixes incorrect localstorage sync when refreshing quickly in the middle of answering
          text: typeof question?.response === 'string' ? question?.response : question?.question,
        },
      }
    })
  })
  reducer.addCase(actions.setQuestions, (state, action) => {
    state.chat.questions = action.payload
  })
  reducer.addCase(actions.setAsked, (state: CareerProfileStore, action) => {
    state.chat.asked = action.payload
  })
  reducer.addCase(actions.setAnswers, (state: CareerProfileStore, action) => {
    state.chat.answers = action.payload
  })
  reducer.addCase(actions.saveAnswer, (state: CareerProfileStore, action) => {
    const existingAnswerIndex = state.chat.answers.findIndex(
      answer => answer.id === action.payload.id,
    )
    if (existingAnswerIndex !== -1) {
      state.chat.answers[existingAnswerIndex] = action.payload
    } else {
      const answersCount = state.chat.answers.length + 1
      state.chat.answers.push({ ...action.payload, id: String(answersCount) })
      trackInternalEvent('submit_qa_question', {
        question_number: answersCount,
        label: 'career_assessment',
      })
    }
  })
  reducer.addCase(actions.setSelectedResume, (state: CareerProfileStore, action) => {
    state.chat.selectedResume = action.payload
  })
  reducer.addCase(actions.setLatestResume, (state: CareerProfileStore, action) => {
    state.chat.latestResume = action.payload
  })
  reducer.addCase(actions.setPersona, (state: CareerProfileStore, action) => {
    state.chat.persona = action.payload
  })
  reducer.addCase(actions.setFetchSelectedResumeStatus, (state: CareerProfileStore, action) => {
    state.chat.fetchSelectedResumeStatus = action.payload
  })
  reducer.addCase(actions.setSelectedResumeText, (state: CareerProfileStore, action) => {
    state.chat.selectedResumeText = action.payload
  })
  reducer.addCase(actions.setStructuredResumeData, (state, action) => {
    state.chat.structuredResumeData = action.payload
  })
  reducer.addCase(actions.setIsDataSentDuringCurrentSession, (draft, action) => {
    draft.chat.isDataSentDuringCurrentSession = action.payload
  })
  reducer.addCase(actions.setFetchCareerInsightsStatus, (draft, action) => {
    draft.careerInsights.fetchCareerInsightsStatus = action.payload
  })
  reducer.addCase(actions.setCareerInsightsData, (draft, action) => {
    draft.careerInsights.data = action.payload
  })
  reducer.addCase(actions.setOpenedPaywallModalType, (draft, action) => {
    draft.openedPaywallModalType = action.payload
  })
  reducer.addCase(actions.setIsOptimizeResumeModalOpened, (draft, action) => {
    draft.isOptimizeResumeModalOpened = action.payload
  })
  reducer.addCase(actions.setUploadedFilename, (state, action) => {
    state.chat.uploadedFilename = action.payload
  })
  reducer.addCase(actions.setFetchCompletenessStatus, (state, action) => {
    state.completeness.fetchStatus = action.payload
  })
  reducer.addCase(actions.setCompletenessPercent, (state, action) => {
    state.completeness.percent = action.payload
  })
  reducer.addCase(actions.setCompletenessPendingItems, (state, action) => {
    state.completeness.pendingItems = action.payload
  })
  reducer.addCase(actions.setSeenPage, (state, action) => {
    state.seenCareerProfilePages[action.payload] = true
  })
  reducer.addCase(actions.setSeenTemporalModal, (state, action) => {
    state.seenTemporalModals[action.payload] = true
  })
  reducer.addCase(actions.updateProfileData, (state, action) => {
    if (!state.profile.data) {
      return
    }
    state.profile.data = {
      ...state.profile.data,
      ...action.payload,
    }
  })
  reducer.addCase(actions.setFetchCareerProfileStatus, (state, action) => {
    state.profile.fetchStatus = action.payload
  })
  reducer.addCase(actions.setCareerProfileData, (state, action) => {
    state.profile.data = action.payload
  })
  reducer.addCase(actions.setCareerProfileStateFromLocalStorage, (state, action) => {
    state.chat = action.payload.chat || initialState.chat
    state.seenCareerProfilePages =
      action.payload.seenCareerProfilePages || initialState.seenCareerProfilePages
    state.seenTemporalModals = action.payload.seenTemporalModals || initialState.seenTemporalModals
    state.floatingHelperPromotionDays = action.payload.floatingHelperPromotionDays || []
    state.completeness.isResultClosed = action.payload.completeness?.isResultClosed || false
  })
  reducer.addCase(actions.setIsSyncedWithLocalStorage, (state, action) => {
    state.isSyncedWithLocalStorage = action.payload
  })
  reducer.addCase(actions.setCareerInsightsStatusModalType, (draft, action) => {
    draft.careerInsightsStatusModalType = action.payload
  })
  reducer.addCase(actions.setInitialChatState, draft => {
    draft.chat = {
      ...initialState.chat,
    }
  })
  reducer.addCase(actions.setIsProfilePhotoUploading, (draft, action) => {
    draft.profile.isPhotoUploading = action.payload
  })
  reducer.addCase(actions.addFloatingHelperPromotionDay, draft => {
    const today = getTodaysDateString()
    if (!draft.floatingHelperPromotionDays.includes(today)) {
      draft.floatingHelperPromotionDays.push(getTodaysDateString())
    }
  })
  reducer.addCase(actions.setIsCompletenessResultClosed, (draft, action) => {
    draft.completeness.isResultClosed = action.payload
  })
  reducer.addCase(actions.setFetchIsAutoApplyCompleteStatus, (state, action) => {
    state.autoApply.fetchStatus = action.payload
  })
  reducer.addCase(actions.setIsAutoApplyComplete, (state, action) => {
    state.autoApply.isComplete = action.payload
  })
  reducer.addCase(actions.setMobileProfileMenu, (draft, action) => {
    draft.mobileProfileMenu = action.payload
  })
  reducer.addCase(actions.setIsNewProfile, (state, action) => {
    state.onboarding.isNewProfile = action.payload
  })
  reducer.addCase(actions.setFetchIsNewProfileStatus, (state, action) => {
    state.onboarding.fetchIsNewProfileStatus = action.payload
  })
  reducer.addCase(actions.setOnboardingStructuredResumeData, (state, action) => {
    state.onboarding.structuredResumeData = action.payload
  })
  reducer.addCase(actions.setWasProfilePreffiled, (state, action) => {
    state.onboarding.wasProfilePrefilled = action.payload
  })
  reducer.addCase(actions.setRecommendationsMissingAttributes, (state, action) => {
    state.recommendations.missingAttributes = action.payload
  })
})
