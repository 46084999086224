import styled, { css } from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

type CreditBoxProps = {
  $color?: 'green' | 'indigo'
  $boxPosition?: 'left' | 'center' | 'right'
  $isSelected?: boolean
}

type BadgeProps = {
  $color?: 'green' | 'indigo'
}

export const Container = styled.div`
  margin-top: 9px;
`

export const CreditsContainer = styled.div`
  display: flex;
  border-radius: 8px;
`

export const CreditBadge = styled.div<BadgeProps>`
  position: absolute;
  top: -14px;
  padding: 2px 8px;
  border-radius: 4px;

  ${Typography.Caption}
  ${FontWeights.DemiBold}

  color: ${({ $color }) => ($color === 'green' ? Colors.Green50 : Colors.Indigo50)};
  background: ${({ $color }) => ($color === 'green' ? Colors.Green10 : Colors.Indigo10)};
`

export const CreditCount = styled.div`
  ${Typography.M}
  ${FontWeights.Medium}
  
  color: ${Colors.Neutral90};
`

export const CreditText = styled.div`
  ${Typography.Body}
  ${FontWeights.Regular}
  
  color: ${Colors.Neutral90};
`

export const CreditBox = styled.div<CreditBoxProps>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  cursor: pointer;
  border: 1px solid ${Colors.Neutral10};

  &:hover {
    background: ${Colors.Neutral5};

    ${CreditBadge} {
      ${({ $color, $isSelected }) =>
        !$isSelected &&
        css`
          color: ${$color === 'green' ? Colors.Green60 : Colors.Indigo60};
          background: ${$color === 'green' ? Colors.Green20 : Colors.Indigo20};
        `}
    }
  }

  ${({ $boxPosition }) => {
    if ($boxPosition === 'center') {
      return css`
        border-right: unset;
        border-left: unset;
      `
    }
    if ($boxPosition === 'left') {
      return css`
        border-radius: 8px 0 0 8px;
      `
    }

    if ($boxPosition === 'right') {
      return css`
        border-radius: 0 8px 8px 0;
      `
    }
  }}

  ${({ $isSelected, $color }) => {
    if ($isSelected) {
      const backgroundColor = $color === 'green' ? Colors.Green50 : Colors.Indigo50

      return css`
        padding: 23px 15px;
        background: ${Colors.Neutral5};
        border: 2px solid ${backgroundColor};

        ${CreditBadge} {
          color: ${Colors.White};
          background: ${backgroundColor};
        }
      `
    }
  }}
`
