import { useState, useEffect, useCallback, useMemo, useRef, createRef } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult, DragUpdate } from 'react-beautiful-dnd'
import type { Optional } from '@rio/types'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import ReactDOM from 'react-dom'
import { reject } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'
import Card from 'builder/components/JobTracking/Card'
import StatusIcon from 'builder/components/JobTrackingStatusIcon'
import {
  ColumnName,
  Job,
  JobCreatePayload,
  JobMovePayload,
  JobDeletePayload,
  actions,
  selectors,
  AutoApplyStatus,
  AutoApplyNotificationState,
} from 'builder/modules/jobTracking'
import { selectors as uiSelectors } from 'builder/modules/ui'
import { useUser } from 'builder/hooks/useUser'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { usePortalNode } from 'builder/hooks/usePortalNode'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'

import { billingPlanPageURL } from 'builder/utils/goToBillingPlanPage'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { isSafari } from 'builder/features/navigation/utils'
import Tooltip from 'builder/components/Tooltip'
import LimitPaywallModal from '../LimitPaywallModal'
import { JoyRidesJobSearchTrackingSteps } from '../FindJob/JoyRidesJobSearch/JoyRidesJobSearchTrackingSteps'
import { Icon20 } from '../Icon'
import QuickAdd from './QuickAdd'
import CardMovedNotification from './CardMovedNotification/CardMovedNotification'
import {
  Content,
  ContentWrapper,
  ContentInner,
  ColumnParent,
  ColumnContainer,
  ColumnHeader,
  ColumnTitle,
  // ColumnCount,
  ColumnContent,
  // AddButton,
  // AddButtonIcon,
  CardNotifiy,
  CardNotifiyContent,
  CardNotifiyTitle,
  CardNotifiyText,
  UpgradeButtonContainer,
  UpgradeButtonIcon,
  // DeleteContainer,
  // DeleteText,
  // DeleteIconContainer,
  // RecycleIcon,
  PlusIcon,
  AddButtonBottom,
  ColumnOuterContainer,
  ColumnTitleJobsCount,
  AutoApplyTitleBtn,
  AutoApplyTitleIcon,
  DashboardScroll,
  InfoIcon,
} from './styles'
import ResumeSelection from './ResumeSelection'
import ApplyDialog from './ApplyDialog'
import InterviewMock from './InterviewMock/InterviewMock'
import Clock from './assets/Clock.svg'
import { CardAutoApplyNotification } from './CardMovedNotification/CardAutoApplyNotification'

import { JobTrackerHeader } from './JobTrackerHeader/JobTrackerHeader'
import { useIsStatusChangeAllowed } from './hooks/useIsStatusChangeAllowed'

export const DEMO_CHECK_KEY = 'JOB_BOARD_DEMO_SHOWN'
interface ColumnRefs {
  [key: string]: React.RefObject<HTMLDivElement>
}

type BoardProps = {
  jobs: Job[]
  columnNames: ColumnName[]
  onCardCreate: (payload: JobCreatePayload) => void
  onCardClick: (id: number, openCardDrawer?: boolean) => void
  move: (payload: JobMovePayload) => void
  reorder: (payload: JobMovePayload) => void
  isFreePlan: Optional<boolean>
  deleteJob: (payload: JobDeletePayload) => void
  handleSetInterviewJob: (selectedJob: Partial<Job> | null) => void
  interviewJob: Partial<Job> | null
  fromDashboard?: boolean
  lastUpdatedJob: Job | null | undefined
}

const Board = ({
  columnNames: columnNamesProp,
  jobs,
  onCardClick,
  onCardCreate,
  reorder,
  deleteJob,
  move,
  isFreePlan,
  handleSetInterviewJob,
  interviewJob,
  fromDashboard,
  lastUpdatedJob,
}: BoardProps) => {
  const user = useUser()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const columnRefs = useRef<ColumnRefs>({})

  const [addingTo, setAddingTo] = useState<ColumnName | null>(null)
  const [lastUpdateTime, setLastUpdate] = useState(() => Date.now())
  const [movingDragId, setMovingDragId] = useState()
  const [isDragging, setDragging] = useState(false)
  const [draggingJob, setDraggingJob] = useState<Job | undefined>(undefined)
  const [hoveredDroppableId, setHoveredDroppableId] = useState<ColumnName | null>(null)
  const [limitPaywallOpen, setLimitPaywallOpen] = useState<boolean>(false)
  const [isNotificationVisible, setIsNotificationVisible] = useState<boolean>(false)
  const [autoApplyNotification, setAutoApplyNotification] =
    useState<AutoApplyNotificationState | null>(null)
  const portalNode = usePortalNode()

  const { isStatusChangeAllowed } = useIsStatusChangeAllowed(draggingJob)

  const { isPhone, isTablet } = useMediaQueries()
  const { i18n } = useI18n()
  const {
    isAutoApplyLimitReached,
    redirectToBillingPlans,
    redirectToAutoApplyQuestionnaire,
    isAutoApplyFeatureEnabled,
  } = useAutoApply()

  const resumeSelection = useTypedSelector(selectors.resumeSelection)
  const notTrackedRecommendationsCount = useTypedSelector(selectors.notTrackedRecommendationsCount)
  const applyTrackingDialog = useTypedSelector(selectors.selectApplyTrackingDialog)
  const canInstallExtension = useTypedSelector(selectors.canInstallExtension)
  const isSideMenuOpen = useTypedSelector(uiSelectors.isSideMenuOpen)

  const FOUR_SECOND = 4000
  const columnNames = isAutoApplyFeatureEnabled
    ? columnNamesProp
    : reject(columnNamesProp, item => item === ColumnName.auto_apply)
  // const scrollRef = useHorizontalScroll()
  // const dragScrollRef = useDragScroll()
  const handleNotification = () => {
    setIsNotificationVisible(true)
    setTimeout(() => {
      setIsNotificationVisible(false)
    }, FOUR_SECOND)
  }

  const handleAutoApplyNotification = ({
    source,
    destination,
    job,
  }: {
    source: ColumnName
    destination: ColumnName
    job: Job
  }) => {
    if (source !== ColumnName.auto_apply && destination !== ColumnName.auto_apply) {
      return
    }
    if (source !== ColumnName.auto_apply && destination === ColumnName.auto_apply) {
      if (!job.autoApplyStatus || job.autoApplyStatus === 'processing') {
        setAutoApplyNotification(AutoApplyNotificationState.processing)
      } else {
        setAutoApplyNotification(AutoApplyNotificationState.added)
      }
    } else if (source === ColumnName.auto_apply && destination !== ColumnName.auto_apply) {
      if (job.isAgentAssigned) {
        setAutoApplyNotification(AutoApplyNotificationState.processing_by_agent)
      } else {
        setAutoApplyNotification(AutoApplyNotificationState.removed)
      }
    }

    setTimeout(() => {
      setAutoApplyNotification(null)
    }, FOUR_SECOND)
  }

  const smoothScrollIntoColumn = (columnName: ColumnName) => {
    const destinationColumnRef = columnRefs.current[columnName]

    if (destinationColumnRef?.current) {
      destinationColumnRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      })
    }
  }

  useEffect(() => {
    const id = setInterval(() => setLastUpdate(Date.now()), 30000)
    return () => clearInterval(id)
  }, [])

  useEffect(() => {
    dispatch(actions.fetchResumeListRequest())
  }, [dispatch])

  useEffect(() => {
    columnNames.forEach(columnId => {
      columnRefs.current[columnId] = createRef<HTMLDivElement>()
    })
  }, [])

  useEffect(() => {
    if ((isPhone || isTablet) && lastUpdatedJob) {
      smoothScrollIntoColumn(lastUpdatedJob.status)
    }
  }, [lastUpdatedJob, isPhone, isTablet])

  const columns = useMemo(() => {
    return columnNames.reduce((result, name) => {
      result[name] = jobs.filter(job => job.status === name)
      return result
    }, {} as Record<ColumnName, Job[]>)
  }, [columnNames, jobs])

  const handleUpgrade = () => {
    trackInternalEvent('click_upgrade_notification')
  }

  const jobsCount = useMemo(
    () =>
      Object.assign(
        {},
        ...Object.values(ColumnName).map(status => ({
          [status]: jobs.filter(job => job.status === status).length,
        })),
      ),
    [jobs],
  )

  const maxLimitCondition = useCallback(
    (currentColumn: string | undefined, isNotification: boolean) => {
      const appliedLength = columns.applied.length + 1
      if (currentColumn === 'applied' && isFreePlan && appliedLength > 3) {
        if (isNotification) {
          return (
            <CardNotifiy>
              <CardNotifiyContent>
                <CardNotifiyTitle>
                  {i18n.t(`builder.job_tracking.board.max_limit.title`)}
                </CardNotifiyTitle>
                <CardNotifiyText>
                  {i18n.t(`builder.job_tracking.board.max_limit.subtitle`)}
                </CardNotifiyText>
              </CardNotifiyContent>
              <UpgradeButtonContainer
                onClick={() => handleUpgrade()}
                href={billingPlanPageURL({ upgradeURL: user?.billingInfo?.upgradeUrl })}
              >
                <UpgradeButtonIcon />
                {i18n.t(`builder.job_tracking.board.max_limit.upgrade`)}
              </UpgradeButtonContainer>
            </CardNotifiy>
          )
        } else {
          setLimitPaywallOpen(true)
          return true
        }
      }
    },
    [user, columns, isFreePlan],
  )

  const onDragStart = useCallback(
    ({ draggableId }) => {
      setMovingDragId(draggableId)
      setDragging(true)
      setDraggingJob(jobs.find(({ id }) => id === Number(draggableId)))
    },
    [jobs, movingDragId],
  )

  const onDragEnd = useCallback(
    ({ source, destination, draggableId }: DropResult) => {
      setDragging(false)
      if (destination?.droppableId === 'deleted') {
        deleteJob({ id: parseInt(draggableId) })
        setHoveredDroppableId(null)
        return
      }

      // Redirect to billing plans if user has auto-apply A/B test enabled and it's on free plan
      if (destination?.droppableId === ColumnName.auto_apply && redirectToBillingPlans()) {
        return
      }

      if (
        destination?.droppableId === ColumnName.auto_apply &&
        redirectToAutoApplyQuestionnaire()
      ) {
        return
      }

      // Check for the limit condition on Dragging to Applied Column
      if (maxLimitCondition(destination?.droppableId, false)) return

      // Check for auto-apply credit on dragging to auto apply column
      if (destination?.droppableId === ColumnName.auto_apply && isAutoApplyLimitReached()) {
        return
      }

      // dropped outside the list
      if (!destination) return

      if (source.droppableId === destination.droppableId) {
        return reorder({ source, destination } as JobMovePayload)
      }

      const job = jobs.find(job => Number(job.id) === Number(draggableId))
      const blockAutoApplyCardFromMoving =
        source.droppableId === ColumnName.auto_apply &&
        destination.droppableId === ColumnName.wishlist &&
        job?.isAgentAssigned

      if (!blockAutoApplyCardFromMoving) {
        move({ source, destination, jobId: Number(draggableId) } as JobMovePayload)
        setHoveredDroppableId(null)
        handleNotification()
      }

      if (job) {
        handleAutoApplyNotification({
          source: source.droppableId as ColumnName,
          destination: destination.droppableId as ColumnName,
          job,
        })
      }
      if (job && source.droppableId === ColumnName.recommended) {
        trackInternalEvent('add_to_job_tracker', {
          title: job.title,
          label: 'recommended_jobs_column',
          position: job.positionNumber,
        })
        dispatch(actions.deductFromRecommendationsCount(1))
      }
    },
    [move, reorder, maxLimitCondition, deleteJob, dispatch],
  )

  const onDragUpdate = ({ destination }: DragUpdate) => {
    if (isDragging && destination) {
      setHoveredDroppableId(destination.droppableId as ColumnName)
    }
  }

  const handleApplyToAll = useCallback(() => {
    const wishlistJobs = jobs.filter(job => job.status === ColumnName.wishlist)
    if (!wishlistJobs.length) {
      return
    }

    // Redirect to billing plans if user has auto-apply A/B test enabled and it's on free plan
    if (redirectToBillingPlans()) {
      return
    }

    if (redirectToAutoApplyQuestionnaire()) {
      return
    }

    if (isAutoApplyLimitReached(wishlistJobs.length)) {
      return
    }

    const moved = jobs.map(job =>
      job.status === ColumnName.wishlist
        ? {
            ...job,
            status: ColumnName.auto_apply,
            autoApplyStatus: 'processing' as AutoApplyStatus,
          }
        : job,
    )
    dispatch(actions.setJobsData(moved))
    reorder({
      source: {
        index: 0,
        droppableId: ColumnName.wishlist,
      },
      destination: {
        droppableId: ColumnName.auto_apply,
        index: 0,
      },
    })
    handleAutoApplyNotification({
      source: ColumnName.wishlist,
      destination: ColumnName.auto_apply,
      job: wishlistJobs[0],
    })
    wishlistJobs.forEach(() => {
      trackInternalEvent('auto_apply_for_job', {
        label: 'job_tracker',
        parameter: 'job_card',
      })
      trackInternalEvent('change_job_tracking_card_status', {
        status: ColumnName.auto_apply,
      })
    })
  }, [jobs, dispatch, reorder])

  const handleAutoApplyCard = useCallback(
    (job: Job) => {
      // Redirect to billing plans if user has auto-apply A/B test enabled and it's on free plan
      if (redirectToBillingPlans()) {
        return
      }

      if (redirectToAutoApplyQuestionnaire()) {
        return
      }

      if (isAutoApplyLimitReached()) {
        return
      }

      const payload = {
        source: {
          droppableId: job.status,
          index: job.positionNumber,
        },
        destination: {
          droppableId: ColumnName.auto_apply,
          index: 0,
        },
        jobId: Number(job.id),
      }
      dispatch(actions.moveRequest(payload))

      handleAutoApplyNotification({
        source: ColumnName.wishlist,
        destination: ColumnName.auto_apply,
        job,
      })
    },
    [jobs, dispatch],
  )

  const onQuickAdd = (fields: JobCreatePayload) => {
    // Check for the limit condition on Adding Job to Applied Column
    if (maxLimitCondition(addingTo?.toString(), false)) return

    if (addingTo) onCardCreate({ ...fields, status: addingTo })
    setAddingTo(null)
  }

  const handleApplyOk = () => {
    setHoveredDroppableId(null)
    dispatch(actions.setApplyTrackingDialog(null))

    // Check for the limit condition on Adding Job to Applied Column
    if (maxLimitCondition('applied', false)) return

    move(applyTrackingDialog?.moveData as JobMovePayload)
  }

  const handleApplyCancel = () => {
    setHoveredDroppableId(null)
    dispatch(actions.setApplyTrackingDialog(null))
    trackInternalEvent('close_apply_banner', { label: 'tracker' })
  }

  const addToShortlist = (job: Job) => {
    const source = {
      droppableId: ColumnName.recommended,
      index: job.positionNumber,
    }
    const destination = {
      droppableId: ColumnName.wishlist,
      index: 0,
    }
    const payload = {
      source,
      destination,
      jobId: job.id,
    }
    trackInternalEvent('add_to_job_tracker', {
      title: job.title,
      label: 'recommended_jobs_column',
      position: job.positionNumber,
    })
    dispatch(actions.moveRequest(payload))
    dispatch(actions.deductFromRecommendationsCount(1))
  }

  return (
    <>
      {!fromDashboard && (
        <>
          <JoyRidesJobSearchTrackingSteps />
          <Content fromDashboard={fromDashboard}>
            <JobTrackerHeader />
          </Content>
        </>
      )}
      <DragDropContext onDragUpdate={onDragUpdate} onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Content cardsContent fromDashboard={fromDashboard}>
          <ContentWrapper>
            <ContentInner fromDashboard={fromDashboard}>
              {columnNames
                .filter(column =>
                  column === ColumnName.recommended ? notTrackedRecommendationsCount > 0 : true,
                )
                .map(name => {
                  if (name !== ColumnName.deleted) {
                    return (
                      <ColumnOuterContainer key={name}>
                        <div ref={columnRefs.current[name]} id={name}>
                          <ColumnHeader status={name}>
                            <StatusIcon status={name} />
                            <ColumnTitle status={name}>
                              {i18n.t(`builder.job_tracking.columns.${name}`)}
                              <ColumnTitleJobsCount>
                                {name === ColumnName.recommended
                                  ? notTrackedRecommendationsCount
                                  : jobsCount[name]}
                              </ColumnTitleJobsCount>
                              {isAutoApplyFeatureEnabled && name === ColumnName.wishlist && (
                                <>
                                  <AutoApplyTitleBtn onClick={() => handleApplyToAll()}>
                                    {i18n.t(`builder.job_tracking.auto_apply.disclaimer.button`)}
                                  </AutoApplyTitleBtn>
                                  <AutoApplyTitleIcon src={Clock} />
                                </>
                              )}
                            </ColumnTitle>
                            <span>
                              {!(
                                isFreePlan &&
                                name === ColumnName.applied &&
                                columns[name].length >= 3
                              ) &&
                                name !== ColumnName.recommended && (
                                  <PlusIcon status={name} onClick={() => setAddingTo(name)} />
                                )}
                              {name === ColumnName.recommended && (
                                <Tooltip
                                  position="bottom"
                                  value={i18n.t('builder.job_tracking.info_tip')}
                                >
                                  <InfoIcon />
                                </Tooltip>
                              )}
                            </span>
                          </ColumnHeader>
                          <Droppable
                            key={name}
                            droppableId={name}
                            // ignoreContainerClipping={true}
                            isDropDisabled={!isStatusChangeAllowed(name)}
                          >
                            {provided => (
                              <ColumnParent
                                isDraggedOver={hoveredDroppableId === name}
                                isDragging={isDragging}
                                status={name}
                                ref={provided.innerRef}
                              >
                                <ColumnContainer
                                  isDraggedOver={hoveredDroppableId === name}
                                  isDragging={isDragging}
                                  status={name}
                                >
                                  {/* <AddButton tabIndex={index + 1} onClick={() => setAddingTo(name)}>
                            <AddButtonIcon />
                            <span>{i18n.t('builder.job_tracking.add_job')}</span>
                          </AddButton> */}

                                  <ColumnContent
                                    data-testid={name}
                                    isSafari={isSafari()}
                                    canInstallExtension={canInstallExtension}
                                    fromDashboard={fromDashboard}
                                  >
                                    {columns[name].map((item, index) => (
                                      <Draggable
                                        // isDragDisabled={window?.innerWidth <= 780}
                                        key={item.id}
                                        draggableId={item.id.toString()}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <Card
                                            {...{
                                              item,
                                              lastUpdateTime,
                                              provided,
                                              snapshot,
                                              isDragging,
                                            }}
                                            addToShortlist={() => addToShortlist(item)}
                                            triggerCardClick={onCardClick}
                                            onClick={onCardClick}
                                            handleSetInterviewJob={handleSetInterviewJob}
                                            handleAutoApplyCard={
                                              (name === ColumnName.wishlist ||
                                                name === ColumnName.recommended) &&
                                              isAutoApplyFeatureEnabled
                                                ? handleAutoApplyCard
                                                : undefined
                                            }
                                          />
                                        )}
                                      </Draggable>
                                    ))}
                                    {addingTo === name && (
                                      <QuickAdd
                                        onSubmit={onQuickAdd}
                                        onCancel={() => setAddingTo(null)}
                                      />
                                    )}
                                    {!(
                                      isFreePlan &&
                                      name === 'applied' &&
                                      columns[name].length >= 3
                                    ) &&
                                      name !== 'recommended' && (
                                        <AddButtonBottom
                                          status={name}
                                          onClick={() => setAddingTo(name)}
                                        >
                                          <PlusIcon status={name} /> <>Add Job</>
                                        </AddButtonBottom>
                                      )}
                                    {name === ColumnName.recommended && (
                                      <AddButtonBottom
                                        status={name}
                                        onClick={() => navigate('/job-search?view=recommendation')}
                                      >
                                        <Icon20.Tile />
                                        <div>{i18n.t(`builder.job_tracking.see_all_jobs`)}</div>
                                      </AddButtonBottom>
                                    )}
                                    {provided.placeholder}
                                    {maxLimitCondition(name, true)}
                                  </ColumnContent>
                                </ColumnContainer>
                              </ColumnParent>
                            )}
                          </Droppable>
                        </div>
                      </ColumnOuterContainer>
                    )
                  } else return null
                  // } else {
                  //   return (
                  //     <Droppable key={name} droppableId={name} ignoreContainerClipping={true}>
                  //       {provided => (
                  //         <DeleteContainer ref={provided.innerRef} isDragging={isDragging}>
                  //           <DeleteText
                  //             isDraggedOver={hoveredDroppableId === name}
                  //             isDragging={isDragging}drop
                  //           >
                  //             Drop it here to delete
                  //           </DeleteText>
                  //           <DeleteIconContainer
                  //             isDraggedOver={hoveredDroppableId === name}
                  //             isDragging={isDragging}
                  //           >
                  //             <RecycleIcon />
                  //           </DeleteIconContainer>
                  //         </DeleteContainer>
                  //       )}
                  //     </Droppable>
                  //   )
                  // }
                })}
              {fromDashboard && <DashboardScroll isSideMenuOpen={isSideMenuOpen} />}
            </ContentInner>
          </ContentWrapper>
        </Content>
      </DragDropContext>
      {limitPaywallOpen && (
        <LimitPaywallModal
          limitPaywallOpen={limitPaywallOpen}
          setLimitPaywallOpen={setLimitPaywallOpen}
          from="tracker"
        />
      )}
      {applyTrackingDialog && (
        <ApplyDialog handleApplyOk={handleApplyOk} handleApplyCancel={handleApplyCancel} />
      )}
      {isNotificationVisible && <CardMovedNotification />}
      {autoApplyNotification && <CardAutoApplyNotification message={autoApplyNotification} />}
      {resumeSelection && <ResumeSelection onClick={onCardClick} />}
      {ReactDOM.createPortal(
        <InterviewMock
          visible={!!interviewJob}
          selectedJob={interviewJob}
          onClose={() => handleSetInterviewJob(null)}
          trackingLabel="job_tracker"
        />,
        portalNode,
      )}
    </>
  )
}

export default Board
