import styled from 'styled-components'
import { animated } from '@react-spring/web'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Wrapper = styled(animated.div)`
  ${Shadows.lightWithBorder.high};
  position: absolute;
  z-index: 1;
  border-radius: 16px;
  top: calc(100% + 13px);
`

export const Arrow = styled.div`
  ${Shadows.lightWithBorder.high};
  background: ${Colors.White};
  position: absolute;
  width: 16px;
  height: 16px;
  transform: rotate(45deg) translateX(-50%);
  top: 0;
  left: 50%;
`

export const Container = styled.div`
  width: 100%;
  background: ${Colors.White};
  border-radius: 16px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  overflow: hidden;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 0 16px;
`

export const Title = styled.div`
  ${FontWeights.Medium};
`

export const Description = styled.div`
  ${Typography.Caption};
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`

export const ReceiveAdsContainer = styled.div`
  padding: 0 16px 8px;
  color: ${Colors.Neutral50};
`

export const Footer = styled.div`
  background: ${Colors.Neutral5};
  border-top: 1px solid ${Colors.Neutral15};
  padding: 16px;
  color: ${Colors.Neutral50};
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.3px;

  a {
    color: ${Colors.Blue50};
  }

  a:hover {
    color: ${Colors.Blue70};
  }
`
