import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  padding: 32px;

  background: ${Colors.White};
  border-radius: 26px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Media.Tablet`
    padding: 24px;
  `}

  ${Media.Phone`
    flex-direction: column;
    padding: 16px;
    gap: 24px;
  `}
`
export const ButtonContainer = styled.div`
  ${Media.Phone`
    width: 95%;
    flex: 1;
    display: flex;
    flex-direction: column;
  `}
`
