import { Resume } from 'packages/types'
import { createContext, FC, useState } from 'react'
import { useLocalStorage } from 'builder/hooks/useLocalStorage'

type ContextValue = {
  resume: Partial<Resume>
  showEmailRequiredError: number
  setShowEmailRequiredError: (show: number) => void
  updateResumeFields: (update: Partial<Resume>) => void
  toggleEmailRequiredError: () => void
  resetContext: () => void
}

const defaultResume: Partial<Resume> = {}
const lsKey = 'GUEST_EDITOR_RESUME'

export const GuestResumeContext = createContext<ContextValue>({
  resume: defaultResume,
  updateResumeFields: () => {},
  showEmailRequiredError: 0,
  setShowEmailRequiredError: () => {},
  toggleEmailRequiredError: () => {},
  resetContext: () => {},
})

export const GuestResumeContextProvider: FC = props => {
  const { children } = props
  const [resume, setResume] = useLocalStorage(lsKey, defaultResume)
  const [showEmailRequiredError, setShowEmailRequiredError] = useState(0)

  const updateResumeFields = (update: Partial<Resume>) => {
    setResume({ ...resume, ...update })
  }

  const toggleEmailRequiredError = () => {
    if (!resume.email) {
      setShowEmailRequiredError(Math.random() + 1)
    }
  }

  const resetContext = () => {
    setResume({})
  }

  const value = {
    resume,
    updateResumeFields,
    showEmailRequiredError,
    setShowEmailRequiredError,
    toggleEmailRequiredError,
    resetContext,
  }

  return <GuestResumeContext.Provider value={value}>{children}</GuestResumeContext.Provider>
}
