import { FC, Fragment, useMemo, useState } from 'react'
import { SortableContainer, SortEndHandler, SortStartHandler } from 'react-sortable-hoc'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { Optional, Resume } from 'packages/types'
import {
  SectionNames,
  selectors as editorSelectors,
  actions as editorActions,
  ResumeSectionId,
  UpdateSimpleFieldFunc,
  MoveSectionPayload,
  AddCustomSectionPayload,
} from 'builder/modules/resumeEditor'
import { actions as coverLetterActions } from 'builder/modules/coverLetterEditor'
import { actions as uiActions } from 'builder/modules/ui'
import AdditionalSections from 'builder/components/AdditionalSections'
import { generateRandomId } from 'builder/utils/generateRandomId'
import { camelToSnake } from 'builder/utils/camelToSnake'
import { useConfig } from 'builder/hooks/useConfig'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { GeneralEditorLevels } from 'builder/modules/generalEditor'
import ProfileSection from '../ProfileSummary/ProfileSection'
import GenerateAICVBannerNew from '../GenerateAICVBannerNew'
import GenerateAICVBanner from '../GenerateAICVBanner'
import { displaySectionPerTemplateConfig } from '../DocumentEditor/ATSResumeEditor/builderSectionsConfig'
import PowerStatementSection from '../PowerStatement/PowerStatementSection'
import { AddCardFunc, CardOperationFunc, MoveCardFunc, UpdateCardFunc } from '../Card'
import { DeleteSectionFunc, RenameSectionFunc } from '../Section/types'
import { AcvBanner, SectionsListContainer, SectionsListFooter } from './styles'
import { groupSectionsBy, constructSectionsData } from './constructSectionsData'
import { SortableSection } from './SortableSection'

type Props = {
  resume: Resume
  updateCard: UpdateCardFunc
  deleteCard: CardOperationFunc
  addCard: (cardId: string, sectionId: ResumeSectionId, options?: { shouldOpen: boolean }) => void
  addCustomSection: (customSectionId: string, options: AddCustomSectionPayload['options']) => void
  moveCard: MoveCardFunc
  moveSection: (payload: MoveSectionPayload) => void
  levels: GeneralEditorLevels
  updateSimpleField: UpdateSimpleFieldFunc
  renameSection: RenameSectionFunc
  deleteSection: DeleteSectionFunc
  appLocale?: string
  isFoldableSectionsEnabled?: boolean
}

export const DroppableSectionFC: FC = ({ children }) => <div>{children}</div>

export const DroppableSectionContainer = SortableContainer(DroppableSectionFC)

export const NewSectionsList = (props: Props) => {
  const {
    resume,
    levels,
    addCustomSection,
    renameSection,
    deleteSection,
    moveCard,
    deleteCard,
    updateCard,
    updateSimpleField,
    moveSection,
    isFoldableSectionsEnabled,
  } = props
  const {
    hideSkillLevel,
    locale,
    template,
    skills,
    position,
    firstName,
    lastName,
    email,
    workExperiences,
  } = resume
  const { addCard } = props
  const config = useConfig()
  const dispatch = useDispatch()
  const openedCard = useTypedSelector(editorSelectors.openedCard)
  const openedSection = useTypedSelector(editorSelectors.openedSection)
  const aiClFreeAttempts = useTypedSelector(state => state.user.data?.aiClFreeAttempts) || 0
  const isAPremiumUser = useTypedSelector(state => state.user.data?.hasPremiumFeatures)
  const currentUserDocuments = useTypedSelector(state => state.panel.documents)
  const [draggableId, setDraggableId] = useState<ResumeSectionId | null>(null)

  const handleCardAdd: AddCardFunc = (sectionId, options) => {
    const cardId = generateRandomId()
    addCard(cardId, sectionId, { ...(options || {}), shouldOpen: true })
  }

  const handleGenerateClick = () => {
    dispatch(editorActions.setIsClickedOnAiCVBanner(true))
  }

  const isCardOpen = (cardId: Optional<number | string>, sectionId: ResumeSectionId) => {
    if (!openedCard) return false

    return openedCard.id === cardId && openedCard.sectionId === sectionId
  }

  const handleCardToggle = (cardId: Optional<number | string>, sectionId: ResumeSectionId) => {
    const isOpen = isCardOpen(cardId, sectionId)

    dispatch(editorActions.openCard(isOpen ? null : { id: cardId, sectionId }))
  }

  const handleSectionToggle = (sectionId: ResumeSectionId) => {
    dispatch(editorActions.openSection(openedSection === sectionId ? null : sectionId))
  }

  const preventSelection = (event: Event) => {
    event.preventDefault()
    return false
  }

  const handleSortStart: SortStartHandler = ({ node }) => {
    // Fix cursor
    document.addEventListener('selectstart', preventSelection)
    const { id } = (node as any).dataset
    return setDraggableId(id)
  }

  const handleSectionMove: SortEndHandler = ({ oldIndex, newIndex }) => {
    document.removeEventListener('selectstart', preventSelection)

    if (!draggableId) return

    moveSection({
      offset: newIndex - oldIndex,
      sectionId: draggableId,
    })
    if (oldIndex !== newIndex) {
      trackInternalEvent('change_sections_order', {
        section: typeof draggableId === 'string' ? camelToSnake(draggableId) : draggableId,
        old_position: oldIndex + 1,
        new_position: newIndex + 1,
      })
    }

    setDraggableId(null)
  }

  const showGenerateAICVButton = useMemo(() => {
    if (!config?.features.aiCoverLetters) {
      return false
    }
    if (!firstName || !lastName || !email) {
      return false
    }

    const hasWorkExperience =
      workExperiences.findIndex(
        ({ title, employer, dateFrom }) => title && employer && dateFrom,
      ) !== -1

    return hasWorkExperience
  }, [config?.features.aiCoverLetters, firstName, lastName, email, workExperiences])

  const sections = constructSectionsData(resume)
  const filteredSections = sections.filter(section =>
    displaySectionPerTemplateConfig(resume.template, section.id),
  )
  const groups = groupSectionsBy(filteredSections, 'sortable')
  const showAIUsagePaywall = !isAPremiumUser && aiClFreeAttempts <= 0
  const currentUserCoverLetters = currentUserDocuments.filter(
    document => document.type === 'cover_letter',
  )
  const hasCoverLettersLimitReached = !isAPremiumUser && currentUserCoverLetters.length >= 1
  const showPowerStatementSection = template === 'shanghai'

  let generateCoverLetterHandler = handleGenerateClick

  if (hasCoverLettersLimitReached) {
    generateCoverLetterHandler = () => dispatch(uiActions.openLimitModal('cover_letter'))
  } else if (showAIUsagePaywall) {
    generateCoverLetterHandler = () => dispatch(coverLetterActions.setShowAICoverLetterPaywall())
  }

  const FooterContainer = isFoldableSectionsEnabled ? SectionsListFooter : Fragment
  const AcvBannerContainer = isFoldableSectionsEnabled ? AcvBanner : Fragment

  return (
    <SectionsListContainer>
      {showPowerStatementSection && (
        <PowerStatementSection
          {...{ sectionId: SectionNames.powerStatement, isFoldableSectionsEnabled }}
        />
      )}
      <ProfileSection {...{ sectionId: SectionNames.profile, isFoldableSectionsEnabled }} />
      {groups.map((group, groupIndex) => {
        const content = group.sections.map((section, sectionIndex) => (
          <SortableSection
            key={section.id}
            index={sectionIndex}
            isDragging={draggableId === section.sortId || draggableId === section.id}
            isOpened={isFoldableSectionsEnabled ? openedSection === section.id : true}
            item={section}
            levels={levels}
            skills={skills}
            position={position}
            hideSkillLevel={hideSkillLevel}
            locale={locale}
            onRename={renameSection}
            onDelete={deleteSection}
            onToggle={handleSectionToggle}
            onAdd={handleCardAdd}
            isCardOpen={isCardOpen}
            onCardChange={updateCard}
            onCardDelete={deleteCard}
            onCardMove={moveCard}
            onCardToggle={handleCardToggle}
            template={resume.template}
            updateSimpleField={updateSimpleField}
            isFoldableSectionsEnabled={isFoldableSectionsEnabled}
          />
        ))

        if (!group.sortable) return <div key={groupIndex}>{content}</div>

        return (
          <DroppableSectionContainer
            key={groupIndex}
            updateBeforeSortStart={handleSortStart}
            onSortEnd={handleSectionMove}
            useWindowAsScrollContainer
            useDragHandle
          >
            {content}
          </DroppableSectionContainer>
        )
      })}

      <FooterContainer>
        <AcvBannerContainer>
          {showGenerateAICVButton &&
            (config?.features.resumeOptimizer ? (
              <GenerateAICVBannerNew onClickGenerate={generateCoverLetterHandler} />
            ) : (
              <GenerateAICVBanner onClickGenerate={generateCoverLetterHandler} />
            ))}
        </AcvBannerContainer>
        <AdditionalSections
          sections={filteredSections}
          onAdd={sectionId =>
            handleCardAdd(sectionId, { scrollIntoViewport: true, shouldOpen: true })
          }
          onAddCustom={externalId => addCustomSection(externalId, { scrollIntoViewport: true })}
        />
      </FooterContainer>
    </SectionsListContainer>
  )
}
