import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { actions, selectors } from 'builder/modules/resumeEditor'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useTailoringBannerTriggerConfig } from 'builder/hooks/useTailoringBannerTriggerConfig'

export const useResumeTailoringBanner = (location: 'builder' | 'header', isVisible = true) => {
  const dispatch = useDispatch()
  const seeBannerEventTracked = useTypedSelector(
    state => state.resumeEditor.viewTailoringBannerEventTracked,
  )
  const score = useTypedSelector(selectors.resumeScore)
  const isBannerSkipped = !!useTypedSelector(selectors.resume)?.skipOptimizerBanner
  const { isBannerTriggerAvailable, availableToTriggerBannerType: type } =
    useTailoringBannerTriggerConfig()

  const onBannerClick = useCallback(() => {
    trackInternalEvent('click_resume_optimizer_banner', { score })
    dispatch(actions.setOpenOptimizerModal(true))
  }, [dispatch, score])

  const onNotNowClick = useMemo(() => {
    if (!isBannerTriggerAvailable || isBannerSkipped) {
      return null
    }

    return () => {
      trackInternalEvent('click_not_now_ro_banner')

      dispatch(actions.updateSimpleField({ name: 'skipOptimizerBanner', value: true }))
      dispatch(actions.setOptimizerBannerTriggeredFrom(null))
      dispatch(actions.setIsImproveResumeBadgeAnimating({ status: true }))
    }
  }, [dispatch, isBannerTriggerAvailable, isBannerSkipped])

  useEffect(() => {
    /** Check to prevent event from being called twice */
    if (!seeBannerEventTracked && isVisible) {
      dispatch(actions.setTailorBannerEventTracked(true))
      trackInternalEvent('see_resume_optimizer_banner')
    }
  }, [dispatch, location, seeBannerEventTracked, isVisible])

  return { onBannerClick, onNotNowClick, type }
}
