import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { uniqBy } from 'lodash'
import { useI18n } from 'builder/hooks/useI18n'
import { PreFillModalClose, PreFillModalCloseIcon } from 'builder/components/CareerPath2Skills'
import Colors from 'builder/styles/colors'
import { ButtonTheme } from 'builder/components/Button'
import { actions, selectors } from 'builder/modules/careerProfile'
import { Icon24 } from 'builder/components/Icon'
import { FormInput } from 'builder/components/CareerPath2Questionnaire'
import {
  getRecentLocationSuggestion,
  generateLocationSuggestion as _generateLocationSuggestion,
} from 'builder/components/FindJob/SearchBar/utils'
import { createNewLocationSuggestionsApiFetcher } from 'builder/components/FindJob/utils/createNewSuggestionsApiFetcher'
import { getLocationFromServer } from 'builder/components/FindJob/utils'
import { LOCAL_STORAGE_JS_HISTORY_LOCATIONS_KEY } from 'builder/components/FindJob/SearchBar/constants'
import { useLocalStorage } from 'builder/hooks/useLocalStorage'
import { NearMe } from 'builder/components/FindJob/styles'
import { SearchSuggestionType } from 'builder/components/FindJob/AutoSuggestField/types'
import {
  DisabledMessage,
  RemoveIcon,
  SelectedList,
  SelectedListItem,
  TargetRoleTitle,
} from '../components/CareerProfileEditorView/styles'
import { useCareerProfileEditorState } from '../components/CareerProfileEditorView/hooks/useCareerProfileEditorState'
import TargetLocationHeader from './assets/target_role_location_header.png'
import TargetRoleHeader from './assets/target_role_header.png'
import {
  ModalBody,
  ModalContainer,
  ModalTitle,
  ModalHeaderContainer,
  StepButton,
  ModalFooter,
  AutoSuggestContainer,
  RoleHeader,
  LocationHeader,
  StyledModalOverlay,
  Subtitle,
  ErrorDescriptionContainer,
  CustomAutoSuggestField,
} from './styles'

export type Step = 'job' | 'location'

type StepData = {
  title?: string
  subtitle?: string
  buttonText?: string
  Image?: React.ReactNode
}

export type Props = {
  setClose: () => void
  includeLocation?: boolean
  roleData?: StepData
  locationData?: StepData
  useAlternativeButton?: boolean
  showSkip?: boolean
  handleAfterSave?: () => void
  eventLabel: 'recommended_jobs' | 'career_profile'
  translationKey?: string
  defaultStep?: 'job' | 'location'
  showBackButton?: boolean
}

const defaultTranslationKey = 'builder.career_profile.profile.target_role_modal'

export const TargetRoleDialog: React.FC<Props> = ({
  setClose,
  includeLocation = false,
  locationData,
  roleData,
  useAlternativeButton = false,
  showSkip = false,
  handleAfterSave,
  eventLabel,
  showBackButton = true,
  translationKey = defaultTranslationKey,
  defaultStep = 'job',
}) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()

  const [step, setStep] = useState<Step>(defaultStep)
  const [targetRoleError, setTargetRoleError] = useState(false)
  const [locationError, setLocationError] = useState(false)
  const currentTargetLocations = useSelector(selectors.profileTargetLocations)

  const { isLocationDisabled, handleSimpleFieldUpdate, isTargetRoleDisabled, localProfile } =
    useCareerProfileEditorState()
  const [targetRoleText, setTargetRoleText] = useState('')
  const [locationText, setLocationText] = useState('')
  const [isExpanded, setIsExpanded] = useState(false)

  const isSaveButtonVisible = !includeLocation || step === 'location'

  const [recentLocations] = useLocalStorage<string[]>(LOCAL_STORAGE_JS_HISTORY_LOCATIONS_KEY, [])
  const [nearMeLocation, setNearMeLocation] = useState<SearchSuggestionType>()
  const recentLocationSuggestion = useMemo(() => getRecentLocationSuggestion(recentLocations), [])
  const generateLocationSuggestion = useMemo(() => {
    const nearMeLocationFormatted = (
      <>
        {nearMeLocation?.formatted}&nbsp;
        <NearMe>({i18n.t('builder.job_search.search_bar.location.near_me')})</NearMe>
      </>
    )
    return _generateLocationSuggestion(
      i18n.t,
      true,
      nearMeLocation,
      nearMeLocationFormatted,
      recentLocationSuggestion,
    )
  }, [i18n, nearMeLocation, recentLocationSuggestion])

  useEffect(() => {
    trackInternalEvent('show_target_role_modal', { label: eventLabel })
  }, [eventLabel])

  useEffect(() => {
    getLocationFromServer().then(suggestionObj => setNearMeLocation(suggestionObj))
  }, [])

  const isRoleSelectionValid = () => {
    if (!showBackButton && defaultStep === 'location') {
      return true
    }
    const hasSelectedRoles = localProfile?.targetRoles?.length

    const hasTypedText = targetRoleText !== ''

    const hasCompletedSelection = hasSelectedRoles && !hasTypedText

    const isValid =
      hasCompletedSelection ||
      (hasSelectedRoles &&
        hasTypedText &&
        !!localProfile?.targetRoles.find(el => el.standardTitle.trim() === targetRoleText.trim()))

    return isValid
  }

  const isLocationSelectionValid = () => {
    const skipLocation = !includeLocation
    const fieldEmpty = locationText === ''
    const fieldMatchesSelection = localProfile?.targetLocations.find(
      el => el.formattedName === locationText,
    )
    return skipLocation || fieldEmpty || fieldMatchesSelection
  }

  const handleSave = (withSkip = false) => {
    const isRoleInvalid = !isRoleSelectionValid()
    const isLocationInvalid = withSkip ? false : !isLocationSelectionValid()

    if (isRoleInvalid || isLocationInvalid) {
      setTargetRoleError(isRoleInvalid)
      setLocationError(isLocationInvalid)
      return
    }

    const skipUpdatingLocation = withSkip && currentTargetLocations.length

    dispatch(
      actions.updateProfileData({
        targetRoles: localProfile?.targetRoles,
        ...(includeLocation &&
          !skipUpdatingLocation && {
            targetLocations:
              withSkip && !localProfile?.targetLocations.length
                ? [{ id: null, formattedName: 'Remote' }]
                : localProfile?.targetLocations,
          }),
      }),
    )

    trackInternalEvent('update_career_profile_data', {
      source: 'target_role_modal',
      targetRoles: localProfile?.targetRoles.map(el => el.standardTitle).join(';'),
      targetLocations: localProfile?.targetLocations.map(el => el.formattedName).join(';'),
    })
    setClose()
    handleAfterSave && handleAfterSave()
  }

  return (
    <StyledModalOverlay>
      <ModalContainer>
        <ModalHeaderContainer>
          <PreFillModalClose
            onClick={() => {
              if (step === 'job') {
                trackInternalEvent('click_close_target_role_modal', { label: eventLabel })
              } else {
                trackInternalEvent('click_close_desired_location', { label: eventLabel })
              }
              setClose()
            }}
            style={{ background: Colors.Neutral5 }}
          >
            <PreFillModalCloseIcon />
          </PreFillModalClose>
          {step === 'job'
            ? roleData?.Image || <RoleHeader src={TargetRoleHeader} />
            : locationData?.Image || <LocationHeader src={TargetLocationHeader} />}
          <ModalTitle>
            {step === 'job'
              ? roleData?.title || i18n.t(`${translationKey}.title`)
              : locationData?.title || i18n.t(`${translationKey}.location_title`)}
          </ModalTitle>
        </ModalHeaderContainer>
        <ModalBody>
          <SelectedList withMargin>
            {step === 'job' &&
              localProfile?.targetRoles.map(targetRole => (
                <SelectedListItem key={targetRole.standardTitle}>
                  <TargetRoleTitle>{targetRole.standardTitle}</TargetRoleTitle>
                  <RemoveIcon
                    onClick={() =>
                      handleSimpleFieldUpdate(
                        'targetRoles',
                        localProfile.targetRoles.filter(
                          el => el.standardTitle !== targetRole.standardTitle,
                        ),
                      )
                    }
                  />
                </SelectedListItem>
              ))}
            {step === 'location' &&
              localProfile?.targetLocations.map(location => (
                <SelectedListItem key={location.formattedName}>
                  <TargetRoleTitle>{location.formattedName}</TargetRoleTitle>
                  <RemoveIcon
                    onClick={() =>
                      handleSimpleFieldUpdate(
                        'targetLocations',
                        localProfile.targetLocations.filter(
                          el => el.formattedName !== location.formattedName,
                        ),
                      )
                    }
                  />
                </SelectedListItem>
              ))}
          </SelectedList>
          {step === 'job' && (
            <AutoSuggestContainer $isExpanded={isExpanded}>
              <FormInput
                onClear={() => setTargetRoleText('')}
                icon={<Icon24.Search />}
                placeholder={i18n.t(`${translationKey}.search_placeholder.job`)}
                name="occupation"
                value={targetRoleText}
                suggestionParam="job-titles"
                storageKey="JOB_TITLE_HISTORY"
                isJobExpanded={isExpanded}
                disabled={isTargetRoleDisabled}
                multiSection={!targetRoleText.length}
                suggestionOptions={{ limit: 10 }}
                onExpand={setIsExpanded}
                onValueChange={value => {
                  setTargetRoleError(false)
                  setTargetRoleText(value)
                }}
                onSelectAutosuggestion={value => {
                  setTargetRoleError(false)
                  setTargetRoleText('')
                  handleSimpleFieldUpdate(
                    'targetRoles',
                    uniqBy(
                      [...(localProfile?.targetRoles || []), { id: null, standardTitle: value }],
                      'standardTitle',
                    ),
                  )
                  trackInternalEvent('select_career_job_title', {
                    label: eventLabel,
                    job_title: value,
                  })
                }}
              />
            </AutoSuggestContainer>
          )}
          {step === 'location' && (
            <CustomAutoSuggestField
              clearPreviousSuggestions={false}
              withHover={false}
              disabled={isLocationDisabled}
              defaultSuggestions={generateLocationSuggestion}
              getSuggestionValue={suggestion => suggestion.formatted || suggestion.text}
              fetchItems={createNewLocationSuggestionsApiFetcher}
              value={locationText || ''}
              icon={<Icon24.LocationOutlined />}
              placeholder={i18n.t(`${translationKey}.search_placeholder.location`)}
              onSuggestionSelected={suggestion => {
                const selectedItem =
                  suggestion.locationType === 'near_me' && nearMeLocation
                    ? nearMeLocation
                    : suggestion
                const locationText = selectedItem.formatted || selectedItem.text
                setLocationText('')
                setLocationError(false)
                const newSelectedLocations = uniqBy(
                  [
                    {
                      id:
                        selectedItem.value && selectedItem.value !== 'Remote'
                          ? parseInt(selectedItem.value)
                          : null,
                      formattedName: locationText,
                    },
                    ...(localProfile?.targetLocations || []),
                  ],
                  'formattedName',
                )
                handleSimpleFieldUpdate('targetLocations', newSelectedLocations)
                trackInternalEvent('select_desired_location', {
                  label: eventLabel,
                  location: selectedItem.formatted || selectedItem.text,
                })
              }}
              onChange={value => {
                setLocationError(false)
                setLocationText(value)
              }}
            />
          )}

          {step === 'job' && isTargetRoleDisabled && (
            <DisabledMessage>
              {i18n.t(`builder.career_profile.profile.target_role_modal.title_disabled`)}
            </DisabledMessage>
          )}
          {step === 'location' && isLocationDisabled && (
            <DisabledMessage>
              {i18n.t(`builder.career_profile.profile.target_role_modal.title_disabled`)}
            </DisabledMessage>
          )}
          {step === 'job' && targetRoleError && (
            <ErrorDescriptionContainer>
              {i18n.t(`${translationKey}.job_error`)}
            </ErrorDescriptionContainer>
          )}

          {step === 'location' && locationError && (
            <ErrorDescriptionContainer>
              {i18n.t(`${translationKey}.location_error`)}
            </ErrorDescriptionContainer>
          )}

          {step === 'job' && roleData?.subtitle && <Subtitle>{roleData.subtitle}</Subtitle>}
          {step === 'location' && locationData?.subtitle && (
            <Subtitle>{locationData.subtitle}</Subtitle>
          )}
        </ModalBody>
        <ModalFooter>
          {step !== 'job' && (
            <>
              {showBackButton && (
                <StepButton
                  onClick={() => {
                    trackInternalEvent('click_back_desired_location', { label: eventLabel })
                    setStep('job')
                  }}
                  theme={ButtonTheme.ghost}
                  $back={true}
                >
                  {i18n.t(`${translationKey}.back`)}{' '}
                </StepButton>
              )}
              {showSkip && (
                <StepButton
                  onClick={() => {
                    trackInternalEvent('click_skip_desired_location', { label: eventLabel })
                    handleSave(true)
                  }}
                  theme={ButtonTheme.ghost}
                  $skip={true}
                >
                  {i18n.t(`${translationKey}.skip`)}{' '}
                </StepButton>
              )}
            </>
          )}
          {!isSaveButtonVisible && (
            <StepButton
              onClick={() => {
                const isRoleInvalid = !isRoleSelectionValid()
                if (includeLocation && isRoleInvalid) {
                  setTargetRoleError(true)
                  return
                }

                if (step === 'job') {
                  if (isRoleInvalid) {
                    setTargetRoleError(isRoleInvalid)
                  } else {
                    trackInternalEvent('click_continue_target_role', { label: eventLabel })
                    setStep('location')
                  }
                } else {
                  setClose()
                }
              }}
              theme={useAlternativeButton ? ButtonTheme.ghost : undefined}
              $useAlternativeButton={useAlternativeButton}
            >
              {roleData?.buttonText ||
                (includeLocation
                  ? i18n.t(`${translationKey}.next`)
                  : i18n.t(`${translationKey}.save`))}{' '}
            </StepButton>
          )}
          {isSaveButtonVisible && (
            <StepButton
              onClick={() => handleSave()}
              theme={useAlternativeButton ? ButtonTheme.ghost : undefined}
              isDisabled={locationError}
              $useAlternativeButton={useAlternativeButton}
              $fixedMarginLeft={showSkip}
            >
              {locationData?.buttonText || i18n.t(`${translationKey}.save`)}{' '}
            </StepButton>
          )}
        </ModalFooter>
      </ModalContainer>
    </StyledModalOverlay>
  )
}
