import { useEffect, useMemo, useRef, useState } from 'react'
import { useSpring, config } from '@react-spring/web'
import throttle from 'lodash/throttle'
import { useI18n } from 'builder/hooks/useI18n'
import type { ToneName } from 'builder/styles/colors'
import ImproveResumeButtonGuest from '../ImproveResumePanel/components/ImproveResumeButton/ImproveResumeButtonGuest'
import {
  Container,
  Header,
  HeaderMain,
  Percent,
  Progress,
  ProgressInner,
  HeaderText,
  HeaderAside,
} from './styles'

const points = 10

export const ResumeScoreGuest = ({
  improveButtonOnClick,
}: {
  improveButtonOnClick: () => void
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { i18n } = useI18n()
  const improveResumeButtonRef = useRef<HTMLDivElement>(null)
  const [isPageScrolled, setIsPageScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = throttle(() => {
      setIsPageScrolled(window.scrollY > 100)
    }, 100)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const [barColor] = useMemo(() => {
    const getColorByPoints = (points: number): ToneName => {
      if (points > 70) return 'green'
      if (points > 30) return 'amber'
      return 'red'
    }

    const color = getColorByPoints(points)
    return [`var(--${color}-40)`, `var(--${color}-50)`]
  }, [points])

  const props = useSpring({
    config: config.slow,
    from: { points: 0 },
    to: { points: Math.min(points, 100) },
  })

  return (
    <Container ref={containerRef} isPageScrolled={isPageScrolled}>
      <Header>
        <HeaderMain>
          <Percent points={points}>{props.points.to(value => value.toFixed(0))}</Percent>
          <HeaderText>{i18n.t('builder.resume_tuner.banner.dashboard_score')}</HeaderText>
        </HeaderMain>
        <HeaderAside>
          <ImproveResumeButtonGuest
            ref={improveResumeButtonRef}
            improveButtonOnClick={improveButtonOnClick}
          />
        </HeaderAside>
      </Header>

      <Progress>
        <ProgressInner
          style={{
            transform: props.points.to(value => `scaleX(${value / 100})`),
            backgroundColor: barColor,
          }}
        />
      </Progress>
    </Container>
  )
}
