export const Unicorn = () => (
  <svg fill="none" height="40" width="40" xmlns="http://www.w3.org/2000/svg">
    <g stroke="white" strokeLinejoin="round" strokeWidth="2">
      <path d="m8 32h-5s-.5-14 5.2-20 8.3-6.5 13.8-7l2 5" />
      <path
        d="m25 9.5 8.521-7.1a.2.2 0 0 1 .322.203l-2.843 10.897m0 0 6 9.5c-1 2.5-4 5-6 5-3.572 0-5.672-3-9-3-2 0-3.5-2-4-4"
        strokeLinecap="round"
      />
      <path d="m12.5 15.5c-4.5 5.5-4.5 19.5-4.5 19.5h18s-1.5-3.5-2-9" strokeLinecap="round" />
    </g>
    <path
      clipRule="evenodd"
      d="m25 9.5c-1.5 1-3 .5-3 .5s-3.5-3-6-6c-2 1-5 6 0 9"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="m25 9.5c-1.5 1-3 .5-3 .5s-3.5-3-6-6c-2 1-5 6 0 9m11 6.082c0-1-.923-1.666-1.5-2-.577-.333-1.5-.5-2.5 0"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export const Document = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99988 5H23.9999L31.9999 13V34C31.9999 34.5523 31.5522 35 30.9999 35H8.99988C8.44759 35 7.99988 34.5523 7.99988 34V6C7.99988 5.44772 8.44759 5 8.99988 5Z"
      fill="white"
    />
    <path d="M26.9999 17H12.9999V19H26.9999V17Z" fill="#CFD6E6" />
    <path d="M26.9999 21.0001H12.9999V23.0001H26.9999V21.0001Z" fill="#CFD6E6" />
    <path d="M20.9999 25H12.9999V27H20.9999V25Z" fill="#CFD6E6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 5L32 13H25C24.4477 13 24 12.5523 24 12V5Z"
      fill="#CFD6E6"
    />
  </svg>
)
