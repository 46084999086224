import { trackMarketingEvent, trackInternalEvent, EventCode } from '@rio/tracking'
import startCase from 'lodash/startCase'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo, useState } from 'react'
import { Resume } from 'packages/types'
import { useI18n } from 'builder/hooks/useI18n'
import DocumentActionMenu from 'builder/components/DocumentActionMenu'
import CoRegPopup, { CoRegPopupPositions } from 'builder/components/PreviewPanel/CoRegPopup'
import { ActionsButtonBadge } from 'builder/components/PreviewPanel/components/DownloadMenuLayout/styles'
import Icon from 'builder/components/Icon'
import { getUpdatedTemplateAndFormat } from 'builder/utils/getTemplates'
import { DocumentTypes, FORMATS } from 'builder/modules/constants'
import { shouldOpenPlansPageAbandonment } from 'builder/components/AbandonmentModal/utils'
import { actions as uiActions } from 'builder/modules/ui'
import { useConfig } from 'builder/hooks/useConfig'
import {
  actions,
  isEmptyField,
  SectionNames,
  selectors as resumeSelectors,
} from 'builder/modules/resumeEditor'
import { useAutoTailoringAppConfig } from 'builder/hooks/useAutoTailoringAppConfig'
import { isEmptySection } from 'builder/modules/resumeEditor/autoTailoringRules'
import {
  selectors as renderingSelectors,
  actions as renderingActions,
} from 'builder/modules/rendering'
import {
  actions as coRegActions,
  selectors as coRegSelectors,
} from 'builder/modules/coRegistration'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { Store } from 'builder/modules/store'
import { IS_BUILDER_DOWNLOAD_AUTO_TAILOR_MODAL_SHOWN } from 'builder/components/PreviewPanel/components/DownloadMenuLayout/DownloadMenuLayout'
import {
  ToolbarRight as Container,
  Updating,
  UpdatingIcon,
  UpdatingText,
  Actions,
  ActionsButton,
  DownloadButton,
} from '../../styles'

interface ToolbarRightProps {
  onClose: () => void
  type: DocumentTypes
}

const ToolbarRight = ({ type, onClose }: ToolbarRightProps) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const config = useConfig()
  const [isActionMenuOpen, setIsActionMenuOpen] = useState<boolean>(false)
  const { isAutoTailoringEnabled } = useAutoTailoringAppConfig()
  const isResume = type === DocumentTypes.resume
  const isCoRegPopupOpen = useSelector(coRegSelectors.isPopupOpen)
  const isReadyToCoReg = useSelector(coRegSelectors.isResumeReady)
  const showOneDownloadButton = !!(config?.features.downloadButtonLayout === 'one_button')
  const showUpdatedMenuLayout = isResume && showOneDownloadButton
  const userData = useTypedSelector(userSelectors.userData)
  const isDownloading = useSelector(renderingSelectors.isDocumentDownloading)
  const isPreviewGenerating = useSelector(renderingSelectors.isPreviewGenerating)
  const isSyncing = useSelector(resumeSelectors.isSyncing)
  const isUpdating = isSyncing || isPreviewGenerating

  const coverLetter = useSelector((state: Store) => state.coverLetterEditor.coverLetter)
  const resume = useSelector(resumeSelectors.resume)
  const currentDocument = isResume ? resume : coverLetter

  const isDataMissed = useMemo(() => {
    if (isResume) {
      const isWorkExperienceEmpty = isEmptySection(
        currentDocument as Resume,
        SectionNames.workExperiences,
      )
      const isProfileEmpty = isEmptyField(currentDocument as Resume, SectionNames.profile)
      return isWorkExperienceEmpty && isProfileEmpty
    } else {
      return false
    }
  }, [currentDocument, isResume])

  const toggleActionMenu = useCallback(() => {
    if (!isActionMenuOpen) {
      trackInternalEvent('open_document_action_menu', {
        source: `full_screen_${type}_preview`,
      })
    }
    setIsActionMenuOpen(!isActionMenuOpen)
  }, [isActionMenuOpen, type])

  const handleShareClick = useCallback(() => {
    trackInternalEvent('click_share_button', { source: `full_screen_${type}_preview` })
  }, [type])

  const handleDownloadDocument = ({ format = FORMATS.pdf }) => {
    const showOneDownloadButton = !!(config?.features.downloadButtonLayout === 'one_button')
    const { updatedTemplate, updatedFormat } = getUpdatedTemplateAndFormat(
      showOneDownloadButton,
      type,
      currentDocument?.template as string,
      format,
    )

    // Track in Google Analytics
    trackMarketingEvent('Preview Modal', `Download ${startCase(type)}`, {
      eventLabel: updatedFormat,
    })
    // Track in ClickHouse
    trackInternalEvent(`click_download_${type}_button` as EventCode, {
      format: updatedFormat,
      source: `full_screen_${type}_preview`,
      template: updatedTemplate,
    })

    const downloadButtonActions = config?.features.downloadButtonActions
    const wasAutoTailorDownloadModalShown = localStorage.getItem(
      IS_BUILDER_DOWNLOAD_AUTO_TAILOR_MODAL_SHOWN,
    )
    if (
      (downloadButtonActions === 'at_banner_link' || downloadButtonActions === 'at_banner') &&
      isAutoTailoringEnabled &&
      !isDataMissed &&
      isResume &&
      !wasAutoTailorDownloadModalShown
    ) {
      onClose()
      if (downloadButtonActions === 'at_banner') {
        dispatch(actions.setFeaturingAutoTailorModalOpenOnDownload(true))
      }
      dispatch(actions.setOpenOptimizerModal(true))
      dispatch(
        actions.setResumeDownloadData({
          id: currentDocument?.id as number,
          type,
          format: updatedFormat,
          source: 'builder',
          template: updatedTemplate,
        }),
      )
      localStorage.setItem(IS_BUILDER_DOWNLOAD_AUTO_TAILOR_MODAL_SHOWN, 'true')
      return
    }
    if (
      window.location.pathname.includes('/resumes') &&
      userData?.registeredAt &&
      shouldOpenPlansPageAbandonment(
        userData?.registeredAt,
        config?.features.plansPageAbandonment || false,
        userData.showAbandonmentFlows,
      )
    ) {
      trackInternalEvent('show_abandonment_popup', { source: 'dashboard', type: 'plans' })
      dispatch(uiActions.openPlansPageAbandonmentModal())
      return
    }
    dispatch(
      renderingActions.download({
        id: currentDocument?.id as number,
        type,
        format: updatedFormat,
        source: 'builder_full_screen_preview',
        template: updatedTemplate,
      }),
    )
  }

  const onDownloadButtonClick = () => {
    showUpdatedMenuLayout ? toggleActionMenu() : handleDownloadDocument({})
  }

  return (
    <Container>
      <Updating isVisible={isUpdating}>
        <UpdatingIcon />
        <UpdatingText>{i18n.t('builder.resume_editor.updating')}</UpdatingText>
      </Updating>
      <>
        <DownloadButton onClick={onDownloadButtonClick} isDisabled={isDownloading}>
          {i18n.t(
            `builder.resume_editor.${
              showUpdatedMenuLayout ? 'download_document_menu.button_text' : 'download'
            }`,
          )}
        </DownloadButton>
        <Actions>
          {!showUpdatedMenuLayout && (
            <ActionsButton onClick={toggleActionMenu} isDisabled={isDownloading}>
              <Icon.More />
            </ActionsButton>
          )}

          <ActionsButtonBadge
            isVisible={isReadyToCoReg && !isActionMenuOpen && !isCoRegPopupOpen}
          />

          {isActionMenuOpen && (
            <DocumentActionMenu
              position="bottom"
              documentType={type}
              documentId={currentDocument?.id as number}
              onClose={toggleActionMenu}
              onDownload={handleDownloadDocument}
              onShare={handleShareClick}
            />
          )}

          {isCoRegPopupOpen && (
            <CoRegPopup
              position={CoRegPopupPositions.bottom}
              onClick={() =>
                dispatch(coRegActions.open({ resumeId: currentDocument?.id, source: 'pop_up' }))
              }
              onClose={() => dispatch(coRegActions.togglePopup(false))}
            />
          )}
        </Actions>
      </>
    </Container>
  )
}

export default ToolbarRight
