import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'
import DefaultButton from 'builder/components/Button'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'

export const TitleContainer = styled.p`
  display: flex;
  flex-direction: column;
  gap: ${Sizes['2XS']};
  margin-bottom: ${Sizes.M};
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold}
  width: 480px;
  color: ${Colors.Neutral90};

  ${Media.Phone`
    width: 303px;
    height: 48px;
  `}
`

export const SubTitle = styled.p`
  ${Typography.Body};
  ${FontWeights.Regular}
  width: 100%;
  color: ${Colors.Neutral50};

  ${Media.Phone`
    width: 303px;
    height: 48px;
  `}
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;

  ${Media.Phone`
    margin-top: 20px;
  `}
`

export const DownloadButton = styled(DefaultButton)`
  margin-right: 8px;

  ${Media.Phone`
    padding: ${Sizes.XS} ${Sizes.S};
  `}
`

export const StartButton = styled(DefaultButton)`
  ${Media.Phone`
    padding: ${Sizes.XS} ${Sizes.S};
  `}
`

export const ArrowTargetIconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 4px;
  color: ${Colors.White};
`

export const ArrowTargetIcon = styled(Icon20.ArrowTarget2)`
  width: 24px;
  height: 24px;
`

export const Button = styled(DefaultButton)`
  ${Typography.Body}
  ${FontWeights.Medium};
`
