import { i18n } from 'builder/utils/i18n'
import { TextField } from 'builder/components/TextField'
import { ButtonSize, ButtonTheme } from 'builder/components/Button/types'
import { Button } from '../styles'
import {
  TitleContainer,
  JobPostingTitle,
  JobPostingSubTitle,
  DownloadButton,
  ButtonContainer,
  ArrowTargetIconWrapper,
  ArrowTargetIcon,
} from './styles'

interface BottomContainerProps {
  jobPostingLink: string
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined
  Icon: JSX.Element | null
  error: string
  size: ButtonSize
  onDownload: () => void
  onContinue: () => void
  isDisabled: boolean
  isSavingJobPostingData?: boolean
}

const DownloadGeneric = ({
  jobPostingLink,
  onChange,
  Icon,
  error,
  size,
  onDownload,
  isDisabled,
  isSavingJobPostingData,
  onContinue,
}: BottomContainerProps) => {
  return (
    <>
      <TitleContainer>
        <JobPostingTitle>
          {i18n.t('builder.resume_optimizer.job_posting.download_generic.title')}
        </JobPostingTitle>
        <JobPostingSubTitle>
          {i18n.t('builder.resume_optimizer.job_posting.download_generic.sub_title')}
        </JobPostingSubTitle>
      </TitleContainer>
      <TextField
        value={jobPostingLink}
        name="link"
        placeholder={i18n.t('builder.resume_optimizer.job_posting.download_generic.placeholder')}
        onChange={onChange}
        inputIcon={Icon}
        inputIconPosition="right"
        error={error}
      />
      <ButtonContainer>
        <DownloadButton theme="ghost" onClick={onDownload} size={size}>
          {i18n.t('builder.resume_optimizer.job_posting.download_generic.download_button_text')}
        </DownloadButton>
        <Button
          theme={ButtonTheme.alternative}
          onClick={onContinue}
          isLoading={isSavingJobPostingData}
          isDisabled={isDisabled}
          size={size}
        >
          <ArrowTargetIconWrapper>
            <ArrowTargetIcon />
          </ArrowTargetIconWrapper>
          {i18n.t('builder.resume_optimizer.job_posting.download_generic.continue_button_text')}
        </Button>
      </ButtonContainer>
    </>
  )
}

export default DownloadGeneric
