import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { baseClient } from 'builder/modules/apiClient'
import { actions } from 'builder/modules/interview/interviewModule'
import { behaviourType, NewInterviewResponse } from 'builder/modules/interview/types'

type Params = {
  jobTitleCode: number
  company?: string
  jobDescription?: string
  levelOfConfidence?: behaviourType
  typeOfQuestions?: string
  numberOfQuestions?: number
}
export const useNewInterview = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  return useMutation(
    ['newInterview'],
    async (params: Params): Promise<NewInterviewResponse> => {
      const res = await baseClient.post('interview_prep/interviews', {
        data_api_job_title_id: params.jobTitleCode,
        company_name: params.company,
        job_description: params.jobDescription,
        level_of_confidence: params.levelOfConfidence,
        type_of_questions: params.typeOfQuestions,
        number_of_questions: params.numberOfQuestions,
        version: 2,
      })
      dispatch(actions.setInterviewId(res.data.id))
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['useInterviews'])
        queryClient.invalidateQueries(['useDailySuggestions'])
        queryClient.invalidateQueries(['useCompanyInterviews'])
      },
    },
  )
}
