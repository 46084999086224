import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useChromeExtension } from 'builder/hooks/useChromeExtension'
import Button, { ButtonTheme } from 'builder/components/Button'
import { ChromeExtensionEmptyState } from '../../ChromeExtension/EmptyState/EmptyState'
import { useChromeExtensionBanner } from '../../ChromeExtension/hooks/useChromeExtensionBanner'

import NO_RESULTS from './assets/no_results_recommandations.png'
import {
  EmptyResultsContainer,
  Title,
  Subtitle,
  NoResultsImage,
  ButtonsContainer,
  EmptyRecommendationContainer,
  ChromeExtensionBanner,
} from './styles'

interface Props {
  handleSearch: () => void
  showExtension?: boolean
  CustomContainer?: React.FunctionComponent
}

export const NoResultsRecommendation = ({
  handleSearch,
  showExtension = true,
  CustomContainer,
}: Props) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { isInstalled, canInstallExtension } = useChromeExtension()
  const { showBanner, hideBanner, getExtension } = useChromeExtensionBanner({ type: 'empty_state' })
  const showChromeExtensionBanner =
    canInstallExtension && !isInstalled && showBanner && showExtension

  const Container = CustomContainer || EmptyRecommendationContainer

  return (
    <Container>
      <EmptyResultsContainer>
        <NoResultsImage src={NO_RESULTS} />
        <Title>{i18n.t(`builder.job_search.no_results_recommendations.title`)}</Title>
        <Subtitle>
          {i18n.t(`builder.job_search.no_results_recommendations.subtitle_line_one`)}
        </Subtitle>
        <Subtitle>
          {i18n.t(`builder.job_search.no_results_recommendations.subtitle_line_two`)}
        </Subtitle>
        <ButtonsContainer>
          <Button theme={ButtonTheme.ghost} onClick={handleSearch}>
            {i18n.t(`builder.job_search.no_results_recommendations.go_search_button`)}
          </Button>
          <Button
            onClick={() => {
              trackInternalEvent('view_profile_editor', { label: 'recommended_jobs' })
              navigate(
                '/career-profile/edit?section=workExperiences&returnUrl=/job-search?view=recommendation',
              )
            }}
          >
            {i18n.t(`builder.job_search.no_results_recommendations.change_work_button`)}
          </Button>
        </ButtonsContainer>
      </EmptyResultsContainer>
      {showChromeExtensionBanner && (
        <ChromeExtensionBanner>
          <ChromeExtensionEmptyState hideBanner={hideBanner} getExtension={getExtension} />
        </ChromeExtensionBanner>
      )}
    </Container>
  )
}
