import { useMemo } from 'react'
import EditorField from 'builder/components/EditorField'
import { Select } from 'builder/components/Select'
import { useI18n } from 'builder/hooks/useI18n'
import { GeneralEditorLevels } from 'builder/modules/generalEditor'
import { useLanguageCardContext } from '../../ExpandableCardContext'

interface Props {
  levels?: GeneralEditorLevels
}

const SelectLevel = (props: Props): JSX.Element => {
  const { item, onCardValueChange } = useLanguageCardContext()
  const { i18n } = useI18n()
  const { levels } = props
  const levelOptions = useMemo(() => {
    return [
      { translation: i18n.t('builder.resume_editor.languages_card.select_level'), name: null },
      ...(levels?.language ?? []),
    ].map(level => ({ id: level.name, name: level.translation }))
  }, [i18n, levels?.language])
  const label = i18n.t('builder.resume_editor.shokumukeirekisho.languages.level')

  const handleLevelSelect = (value: unknown) => {
    onCardValueChange({ level: value }, false)
  }

  return (
    <EditorField>
      <Select
        label={label}
        selected={item.level || null}
        onSelect={handleLevelSelect}
        options={levelOptions}
      />
    </EditorField>
  )
}
export default SelectLevel
