import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import Button, { ButtonTheme } from 'builder/components/Button'
import Icon24 from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { useCreateInterviewFromInterview } from 'builder/views/Interview/hooks/useCreateInterviewFromInterview'

export const TRANSLATION_PREFIX = `builder.interview_prep_view.feedbackV2`

interface Props {
  interviewId: number
}

export const BtnTryAgain = ({ interviewId }: Props) => {
  const { i18n } = useI18n()
  const createInterviewFromSameQuestions = useCreateInterviewFromInterview()
  const navigate = useNavigate()

  const newInterviewWithSameQuestion = async () => {
    if (interviewId === undefined) {
      return
    }
    await createInterviewFromSameQuestions.mutateAsync(interviewId)

    trackInternalEvent('try_again', {
      label: 'interview_prep',
    })
    navigate('/interview-preparation/interview-player')
  }
  return (
    <Button theme={ButtonTheme.ghost} onClick={newInterviewWithSameQuestion}>
      <Icon24.UpdateIcon /> {i18n.t(`${TRANSLATION_PREFIX}.try_again_btn`)}
    </Button>
  )
}
