import { useDispatch } from 'react-redux'
import { useI18n } from 'builder/hooks/useI18n'

import { actions as jobSearchActions } from 'builder/modules/jobSearch'
import { useUser } from 'builder/hooks/useUser'
import { JobSearchNotificationType } from 'builder/modules/jobSearch/types'
import { DialogModalV2 } from 'builder/components/DialogModal/DialogModalV2/DialogModalV2'
import ConfirmationImgSrc from '../assets/confirmation.png'
import { Container, Img, Subtitle, Title } from './styles'

const TRANSLATION = 'builder.job_search.alert_modal.confirmation_alert'

export const ConfirmationCreateAlert = () => {
  const { i18n } = useI18n()

  const dispatch = useDispatch()
  const user = useUser()

  return (
    <DialogModalV2
      onClose={() => {
        dispatch(jobSearchActions.setAlertType({ type: undefined }))
        dispatch(jobSearchActions.setNotification(JobSearchNotificationType.create_job_alert))
      }}
    >
      <Container>
        <Img src={ConfirmationImgSrc} />
        <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
        <Subtitle
          dangerouslySetInnerHTML={{
            __html: i18n.t(`${TRANSLATION}.subtitle`, {
              email: user?.email,
            }),
          }}
        />
      </Container>
    </DialogModalV2>
  )
}
