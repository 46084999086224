import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useEffect, useState } from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { stripHtml } from 'builder/utils/stripHTML'
import { selectors, ColumnName } from 'builder/modules/careerPath2'
import { Job } from 'builder/components/FindJob/JobSearchResult/types'
import { actions, selectors as jtSelectors } from 'builder/modules/jobTracking'

type JobTrackingUtilities = {
  isPresent: boolean
  handleAddJobBoard: () => void
}

export const useJobTracker = (job: Job, fromDashboard = false): JobTrackingUtilities => {
  const dispatch = useDispatch()

  const [saveJobId, setSaveJobId] = useState<number | null>(null)

  const cards = useTypedSelector(jtSelectors.savedJobs)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)

  useEffect(() => {
    const jobTrackingCard =
      cards.find(card => {
        const cardSlugNumber = card.externalSlugId

        return cardSlugNumber === job.external_slug_id
      }) || null

    setSaveJobId(jobTrackingCard?.id || null)
  }, [cards, job.external_slug_id])

  const handleAddJobBoard = () => {
    if (saveJobId) {
      dispatch(actions.deleteJobRequest({ id: saveJobId }))
    } else {
      const careerPathFeature = isExploreCareers ? 'explore_careers' : 'career_path2'
      trackInternalEvent('add_to_job_tracker', {
        title: job.job_title,
        label: fromDashboard ? 'icon_dashboard' : careerPathFeature,
      })

      dispatch(
        actions.createJobRequest({
          status: ColumnName.wishlist,
          title: job.job_title,
          company: job.company,
          link: job.url,
          description: stripHtml(job.description),
          external_slug_id: job.external_slug_id,
          location: job.location,
          logo: job.company_logo,
        }),
      )
    }
  }

  return {
    isPresent: !!saveJobId,
    handleAddJobBoard,
  }
}
