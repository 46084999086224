import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import {
  selectors as renderingSelectors,
  actions as renderingActions,
} from 'builder/modules/rendering'
import { Plans } from '../constants'

export const useAutoApplyCreditsModal = () => {
  const dispatch = useDispatch()
  const rdUpsellModalVisible = useTypedSelector(renderingSelectors.rdUpsellModalVisible)
  const [isOpen, setIsOpen] = useState(rdUpsellModalVisible)

  const handleModalClose = () => {
    dispatch(renderingActions.closeRDUpsellModal())
  }

  useEffect(() => {
    if (rdUpsellModalVisible) {
      trackInternalEvent('show_upsell_plans_page')
      trackInternalEvent('aa_upsell_plans_page_pick_plan', { addon_name: Plans.CREDITS_20 })
    }
    setIsOpen(rdUpsellModalVisible)
  }, [rdUpsellModalVisible])

  return {
    isOpen,
    handleModalClose,
  }
}
