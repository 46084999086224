import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { selectors, actions } from 'builder/modules/panel'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { DocumentTypes, FetchStatuses } from 'builder/modules/constants'

export const useDocuments = () => {
  const dispatch = useDispatch()
  const status = useTypedSelector(selectors.documentsStatus)

  const resumes = useTypedSelector(store => selectors.documentsByType(store, DocumentTypes.resume))
  const coverLetters = useTypedSelector(store =>
    selectors.documentsByType(store, DocumentTypes.coverLetter),
  )
  const autoTailoredResumes = useTypedSelector(store =>
    selectors.documentsByType(store, DocumentTypes.resume, true),
  )

  const allResumes = useMemo(
    () => [...resumes, ...autoTailoredResumes],
    [resumes, autoTailoredResumes],
  )

  useEffect(() => {
    if (status === FetchStatuses.notAsked) {
      dispatch(actions.fetchDocuments())
    }
  }, [status, dispatch])

  return {
    resumes,
    coverLetters,
    autoTailoredResumes,
    allResumes,
  }
}
