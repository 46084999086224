import { useDispatch } from 'react-redux'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { trackInternalEvent } from '@rio/tracking'
import { uniqBy } from 'lodash'
import { selectors, actions, CareerProfileData } from 'builder/modules/careerProfile'
import {
  actions as generalEditorActions,
  selectors as generalEditorSelectors,
} from 'builder/modules/generalEditor'
import { actions as userActions } from 'builder/modules/user'
import { SnackbarTypes, actions as uiActions } from 'builder/modules/ui'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FetchStatuses } from 'builder/modules/constants'
import { SortableSection, DroppableSectionContainer } from 'builder/components/SectionsList'
import { useI18n } from 'builder/hooks/useI18n'
import { FormInput } from 'builder/components/CareerPath2Questionnaire'
import Icon24 from 'builder/components/Icon'
import Button, { ButtonTheme } from 'builder/components/Button'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import { CareerProfileEditorUrlParams } from 'builder/components/CareerProfile'
import { SectionHeader } from 'builder/components/Section'
import SectionTitle from 'builder/components/SectionTitle'
import EditorRow from 'builder/components/EditorRow'
import EditorField from 'builder/components/EditorField'
import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import { TextField } from 'builder/components/TextField'
import { useUser } from 'builder/hooks/useUser'
import PhotoUploadField from 'builder/components/PhotoUploadField'
import AvatarEditorModal from 'builder/components/AvatarEditorModal'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { AvatarUploadPayload } from 'builder/modules/resumeEditor'
import { createNewLocationSuggestionsApiFetcher } from 'builder/components/FindJob/utils/createNewSuggestionsApiFetcher'
import {
  getRecentLocationSuggestion,
  generateLocationSuggestion as _generateLocationSuggestion,
} from 'builder/components/FindJob/SearchBar/utils'
import { LOCAL_STORAGE_JS_HISTORY_LOCATIONS_KEY } from 'builder/components/FindJob/SearchBar/constants'
import { useLocalStorage } from 'builder/hooks/useLocalStorage'
import { NearMe } from 'builder/components/FindJob/styles'
import { SearchSuggestionType } from 'builder/components/FindJob/AutoSuggestField/types'
import { getLocationFromServer } from 'builder/components/FindJob/utils'
import { ONBOARDING_STRUCTURED_RESUME_DATA_KEY } from 'builder/components/FindJob/types'
import { SectionHintContainer } from 'builder/components/Section/styles'
import { PageLoader } from '../PageLoader'
import {
  Animation,
  AutoSuggestContainer,
  Body,
  ButtonsContainer,
  Container,
  Content,
  EditAccountLink,
  Footer,
  HeadersContainer,
  ItemContainer,
  ItemError,
  ItemTitle,
  ItemTitleContainer,
  JobPreferenceContainer,
  LocationIcon,
  LocationInputIcon,
  OptionText,
  PageHeader,
  PersonalDetailsContainer,
  RoleIcon,
  SearchInputIcon,
  Screen,
  CustomAutoSuggestField,
  SelectedList,
  SelectedListItem,
  TargetRoleTitle,
  ItemHeader,
  DisabledMessage,
  RemoveIcon,
} from './styles'
import { clearTemporalIds, constructSectionsData } from './helpers'
import { translationKey } from './consts'
import { useCareerProfileEditorState } from './hooks/useCareerProfileEditorState'

const scroll = (offsetTop: number) => {
  window.scrollTo({
    top: offsetTop,
    behavior: 'auto',
  })
}

const PERSONAL_DETAILS_SECTION_ID = 'personalDetails' as const
const JOB_PREFERENCES_SECTION_ID = 'jobPreferences' as const

export const CareerProfileEditorView = () => {
  const { i18n } = useI18n()
  const user = useUser()
  const dispatch = useDispatch()
  const { isPhone, isTablet } = useMediaQueries()
  const [returnUrl] = useQueryParam(CareerProfileEditorUrlParams.returnUrl)
  const [section] = useQueryParam(CareerProfileEditorUrlParams.section)
  const profileFetchStatus = useTypedSelector(selectors.fetchCareerProfileStatus)
  const actualProfile = useTypedSelector(selectors.careerProfileData)
  const levels = useTypedSelector(generalEditorSelectors.levels)
  const isGeneralEditorLoaded = useTypedSelector(generalEditorSelectors.isLoaded)
  const [firstName, setFirstName] = useState(user?.firstName || '')
  const [lastName, setLastName] = useState(user?.lastName || '')
  const {
    localProfile,
    handleCardAdd,
    handleCardDelete,
    handleCardMove,
    handleCardToggle,
    handleCardUpdate,
    isCardOpen,
    handleSimpleFieldUpdate,
    isTargetRoleDisabled,
    isLocationDisabled,
  } = useCareerProfileEditorState()
  const [locationText, setLocationText] = useState(
    localProfile?.targetLocations.length ? localProfile.targetLocations[0].formattedName : '',
  )
  const sections = useMemo(
    () => (localProfile ? constructSectionsData(localProfile, i18n) : []),
    [localProfile, i18n],
  )
  const locale = i18n.currentLocale()
  const isLoading =
    [FetchStatuses.notAsked, FetchStatuses.loading].includes(profileFetchStatus) ||
    !isGeneralEditorLoaded
  const [isAvatarEditorVisible, setAvatarEditorVisibility] = useState(false)
  const isOnline = useTypedSelector(state => state.application.isOnline)
  const isProfilePhotoUploading = useTypedSelector(selectors.isProfilePhotoUploading)
  const [isExpanded, setIsExpanded] = useState(false)

  const [targetRole, setTargetRole] = useState('')
  const [hasTargetRoleError, setHasTargetRoleError] = useState(false)
  const [displayTargetRoleError, setDisplayTargetRoleError] = useState(false)

  const personalDetailsContentRef = useRef<HTMLDivElement>(null)
  const { current: personalDetailsRef } = personalDetailsContentRef
  const jobPreferencesContentRef = useRef<HTMLDivElement>(null)
  const { current: jobPreferencesRef } = jobPreferencesContentRef

  const [selectedSectionId, setSelectedSectionId] = useState('')
  const [contentOffset, setContentOffset] = useState(0)
  const [isScrollToView, setIsScrollToView] = useState(true)

  const [recentLocations] = useLocalStorage<string[]>(LOCAL_STORAGE_JS_HISTORY_LOCATIONS_KEY, [])
  const [nearMeLocation, setNearMeLocation] = useState<SearchSuggestionType>()

  const [initialScrollPending, setInitialScrollPending] = useState(true)

  useEffect(() => {
    const timeout = setTimeout(
      () => setSelectedSectionId(section || PERSONAL_DETAILS_SECTION_ID),
      500,
    )
    return () => {
      clearTimeout(timeout)
    }
  }, [section])

  useEffect(() => {
    getLocationFromServer().then(suggestionObj => setNearMeLocation(suggestionObj))
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
      const lastSection = sections[sections.length - 1]

      if (bottom) {
        setSelectedSectionId(lastSection.id)
      }

      const contentsRef = document.querySelectorAll('.content')
      const lastSectionRef = contentsRef[contentsRef.length - 1]
      if (
        selectedSectionId === lastSection.id &&
        document.documentElement.scrollHeight - (window.innerHeight + window.scrollY) >
          lastSectionRef.getBoundingClientRect().height
      ) {
        setSelectedSectionId(sections[sections.length - 2].id)
      }
    }

    window.addEventListener('scroll', handleScroll, {
      passive: true,
    })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [sections, selectedSectionId])

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && entry.target.attributes[1].nodeValue) {
            const headersIds = [
              PERSONAL_DETAILS_SECTION_ID,
              JOB_PREFERENCES_SECTION_ID,
              ...sections.map(section => section.id),
            ]
            const selectedId = headersIds.find(
              headerId => headerId === entry.target.attributes[1].nodeValue,
            )

            if (
              selectedId &&
              selectedSectionId !== selectedId &&
              !(
                Math.ceil(window.innerHeight + window.scrollY) >=
                document.documentElement.scrollHeight
              ) &&
              !initialScrollPending
            ) {
              setSelectedSectionId(selectedId)
            }

            setIsScrollToView(false)
          }
        })
      },
      { rootMargin: '-30% 0px -70% 0px', threshold: 0 },
    )

    const contentsRef = document.querySelectorAll('.content')
    contentsRef.forEach(content => observer.observe(content))
    return () => {
      observer.disconnect()
    }
  }, [sections, selectedSectionId, initialScrollPending])

  useEffect(() => {
    if (personalDetailsRef && selectedSectionId === PERSONAL_DETAILS_SECTION_ID) {
      setContentOffset(personalDetailsRef.offsetTop)
    }
  }, [personalDetailsRef, selectedSectionId])

  useEffect(() => {
    if (jobPreferencesRef && selectedSectionId === JOB_PREFERENCES_SECTION_ID) {
      setContentOffset(jobPreferencesRef.offsetTop)
    }
  }, [jobPreferencesRef, selectedSectionId])

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    if (isScrollToView || initialScrollPending) {
      scroll(contentOffset - 150)
      if (initialScrollPending) {
        timeout = setTimeout(() => setInitialScrollPending(false), 1000)
      }
    }

    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [contentOffset, isScrollToView, initialScrollPending])

  useEffectOnMount(() => {
    if (profileFetchStatus === FetchStatuses.notAsked) {
      dispatch(actions.fetchCareerProfile())
    }

    if (!levels || !levels.skill) {
      dispatch(generalEditorActions.fetchEditorPayloadRequest())
    }
  })

  const isSomethingChanged = () => {
    if (!localProfile || !actualProfile || !user) {
      return false
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { profilePicture: a, ...changeableLocalProfile } = localProfile
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { profilePicture: b, ...changeableActualProfile } = actualProfile

    const profileChanged = !isEqual(changeableLocalProfile, changeableActualProfile)
    const accountChanged = firstName !== user.firstName || lastName !== user.lastName

    return profileChanged || accountChanged
  }

  const showSnackbar = useCallback(() => {
    dispatch(
      uiActions.setSnackBarOpen({
        status: true,
        type: SnackbarTypes.success,
        text: i18n.t('builder.career_profile.editor.profile_saved'),
      }),
    )
    setTimeout(() => dispatch(uiActions.closeSnackBar()), 3000)
  }, [dispatch, i18n])

  const goBack = () => {
    window.location.href = `/app${returnUrl || '/career-profile/profile'}`
  }

  const handleBackClick = () => {
    trackInternalEvent('click_cancel_editing_profile')
    if (
      isSomethingChanged() &&
      !window.confirm(i18n.t('builder.career_profile.editor.exit_warning'))
    ) {
      return
    }

    localStorage.removeItem(ONBOARDING_STRUCTURED_RESUME_DATA_KEY)
    goBack()
  }

  const handleSave = () => {
    setDisplayTargetRoleError(false)

    if (returnUrl?.includes('recommendation')) {
      trackInternalEvent('click_save_profile', { label: 'recommended_jobs' })
    } else {
      trackInternalEvent('click_save_profile', { label: 'career_profile' })
    }

    if (!localProfile) {
      return
    }

    if (hasTargetRoleError) {
      setDisplayTargetRoleError(true)
      return
    }

    dispatch(actions.setIsNewProfile(false))

    const normalizedProfile: CareerProfileData = {
      ...localProfile,
      targetLocations: localProfile.targetLocations.filter(el => el.formattedName !== ''),
      skillsAttributes: clearTemporalIds(localProfile.skillsAttributes),
      coursesAttributes: clearTemporalIds(localProfile.coursesAttributes),
      educationsAttributes: clearTemporalIds(localProfile.educationsAttributes),
      workExperiencesAttributes: clearTemporalIds(localProfile.workExperiencesAttributes),
    }

    localStorage.removeItem(ONBOARDING_STRUCTURED_RESUME_DATA_KEY)

    dispatch(actions.updateProfileData(normalizedProfile))
    trackInternalEvent('update_career_profile_data', {
      source: 'career_profile_view',
      targetRoles: normalizedProfile.targetRoles.map(el => el.standardTitle).join(';'),
      targetLocations: normalizedProfile.targetLocations.map(el => el.formattedName).join(';'),
    })
    // email should present in payload for successful account update
    dispatch(userActions.updateUserRequest({ firstName, lastName, email: user?.email }))
    goBack()
    showSnackbar()
  }

  const handleTogglePhotoUploader = useCallback(() => {
    if (!isOnline) return dispatch(uiActions.setConnectionLostSnackbarOpen(true))
    setAvatarEditorVisibility(value => !value)
  }, [dispatch, isOnline])

  const handleUploadPhoto = useCallback(
    (payload: AvatarUploadPayload) => {
      dispatch(actions.updateProfileImage(payload))
      setAvatarEditorVisibility(false)
      showSnackbar()
    },
    [dispatch, showSnackbar],
  )

  const handleDeletePhoto = useCallback(() => {
    if (!window.confirm(i18n.t('builder.resume_editor.delete_photo_confirm'))) return
    dispatch(actions.deleteProfileImage())
    showSnackbar()
  }, [i18n, dispatch, showSnackbar])

  const recentLocationSuggestion = useMemo(() => getRecentLocationSuggestion(recentLocations), [])
  const generateLocationSuggestion = useMemo(() => {
    const nearMeLocationFormatted = (
      <>
        {nearMeLocation?.formatted}&nbsp;
        <NearMe>({i18n.t('builder.job_search.search_bar.location.near_me')})</NearMe>
      </>
    )
    return _generateLocationSuggestion(
      i18n.t,
      true,
      nearMeLocation,
      nearMeLocationFormatted,
      recentLocationSuggestion,
    )
  }, [i18n, nearMeLocation, recentLocationSuggestion])

  return isLoading ? (
    <Container>
      <PageLoader />
    </Container>
  ) : (
    <Screen isTablet={isTablet}>
      {!isTablet && (
        <HeadersContainer>
          <OptionText
            isSelected={selectedSectionId === PERSONAL_DETAILS_SECTION_ID}
            onClick={() => {
              setSelectedSectionId(PERSONAL_DETAILS_SECTION_ID)
              setIsScrollToView(true)
            }}
          >
            {i18n.t('builder.resume_editor.personal_details')}
          </OptionText>
          <OptionText
            isSelected={selectedSectionId === JOB_PREFERENCES_SECTION_ID}
            onClick={() => {
              setSelectedSectionId(JOB_PREFERENCES_SECTION_ID)
              setIsScrollToView(true)
            }}
          >
            {i18n.t('builder.career_profile.editor.job_preference_section.title')}
          </OptionText>
          {sections.map(({ id, title }) => (
            <OptionText
              isSelected={selectedSectionId === id}
              onClick={() => {
                setSelectedSectionId(id)
                setIsScrollToView(true)
              }}
              key={id}
            >
              {title}
            </OptionText>
          ))}
        </HeadersContainer>
      )}
      <Container>
        <Animation>
          <Content>
            <PageHeader>
              {i18n.t(`${translationKey}.title`)}
              <EditAccountLink to="/account" target="_blank">
                <Icon24.Settings2 />
                {i18n.t(`${translationKey}.account_settings_link`)}
              </EditAccountLink>
            </PageHeader>

            <Body>
              <PersonalDetailsContainer
                ref={personalDetailsContentRef}
                className="content"
                value={PERSONAL_DETAILS_SECTION_ID}
              >
                <SectionHeader>
                  <SectionTitle title={i18n.t('builder.resume_editor.personal_details')} />
                </SectionHeader>

                <EditorRow>
                  <EditorField>
                    <PhotoUploadField
                      imageUrl={
                        actualProfile?.profilePicture.blank
                          ? ''
                          : actualProfile?.profilePicture.thumbUrl
                      }
                      isUploading={isProfilePhotoUploading}
                      onUpload={handleUploadPhoto}
                      onDelete={handleDeletePhoto}
                      onClick={isPhone ? null : handleTogglePhotoUploader}
                    />
                  </EditorField>
                </EditorRow>

                <EditorRow>
                  <EditorField>
                    <TextField
                      label={i18n.t('builder.resume_editor.first_name')}
                      value={firstName}
                      name="firstName"
                      onChange={event => setFirstName(event.target.value)}
                    />
                  </EditorField>

                  <EditorField>
                    <TextField
                      label={i18n.t('builder.resume_editor.last_name')}
                      value={lastName}
                      name="lastName"
                      onChange={event => setLastName(event.target.value)}
                    />
                  </EditorField>
                </EditorRow>

                <EditorRow>
                  <EditorField>
                    <AsyncAutosuggest
                      highlightedQuery
                      name="location"
                      fetchItems={createNewLocationSuggestionsApiFetcher}
                      value={localProfile?.location || ''}
                      onChange={event => handleSimpleFieldUpdate('location', event.target.value)}
                    >
                      {inputProps => (
                        <TextField
                          {...inputProps}
                          label={i18n.t('builder.career_profile.editor.location')}
                          placeholder={i18n.t('builder.career_profile.editor.location_placeholder')}
                        />
                      )}
                    </AsyncAutosuggest>
                  </EditorField>

                  <EditorField>
                    <TextField
                      label={i18n.t('builder.resume_editor.email')}
                      type="email"
                      value={localProfile?.email || ''}
                      name="email"
                      onChange={event => handleSimpleFieldUpdate('email', event.target.value)}
                    />
                  </EditorField>
                </EditorRow>
              </PersonalDetailsContainer>
              <JobPreferenceContainer
                ref={jobPreferencesContentRef}
                className="content"
                value={JOB_PREFERENCES_SECTION_ID}
              >
                <SectionHeader>
                  <SectionTitle
                    title={i18n.t('builder.career_profile.editor.job_preference_section.title')}
                  />
                  <SectionHintContainer>
                    {i18n.t('builder.career_profile.editor.job_preference_section.hint')}
                  </SectionHintContainer>
                </SectionHeader>

                <ItemContainer>
                  <ItemHeader>
                    <ItemTitleContainer>
                      <RoleIcon />
                      <ItemTitle>
                        {i18n.t(
                          'builder.career_profile.editor.job_preference_section.target_role.title',
                        )}
                      </ItemTitle>
                    </ItemTitleContainer>
                    <SelectedList>
                      {localProfile?.targetRoles.map(targetRole => (
                        <SelectedListItem key={targetRole.standardTitle}>
                          <TargetRoleTitle>{targetRole.standardTitle}</TargetRoleTitle>
                          <RemoveIcon
                            onClick={() =>
                              handleSimpleFieldUpdate(
                                'targetRoles',
                                localProfile.targetRoles.filter(
                                  el => el.standardTitle !== targetRole.standardTitle,
                                ),
                              )
                            }
                          />
                        </SelectedListItem>
                      ))}
                    </SelectedList>
                  </ItemHeader>
                  <AutoSuggestContainer $isExpanded={isExpanded}>
                    <FormInput
                      onClear={() => {
                        setTargetRole('')
                        setHasTargetRoleError(false)
                        setDisplayTargetRoleError(false)
                      }}
                      icon={<SearchInputIcon />}
                      placeholder={i18n.t(
                        `builder.career_profile.profile.target_role_modal.search_placeholder.job`,
                      )}
                      name="occupation"
                      value={targetRole}
                      suggestionParam="job-titles"
                      suggestionOptions={{ limit: 10 }}
                      storageKey="JOB_TITLE_HISTORY"
                      disabled={isTargetRoleDisabled}
                      isJobExpanded={isExpanded}
                      multiSection={!targetRole.length}
                      onExpand={setIsExpanded}
                      onValueChange={value => {
                        setTargetRole(value)
                        if (value?.length) {
                          setHasTargetRoleError(true)
                        } else {
                          setHasTargetRoleError(false)
                          setDisplayTargetRoleError(false)
                        }
                      }}
                      onSelectAutosuggestion={value => {
                        setTargetRole('')
                        handleSimpleFieldUpdate(
                          'targetRoles',
                          uniqBy(
                            [
                              ...(localProfile?.targetRoles || []),
                              { id: null, standardTitle: value },
                            ],
                            'standardTitle',
                          ),
                        )
                        setHasTargetRoleError(false)
                        setDisplayTargetRoleError(false)
                      }}
                    />
                  </AutoSuggestContainer>
                  {!displayTargetRoleError ? (
                    <DisabledMessage>
                      {isTargetRoleDisabled
                        ? i18n.t(`builder.career_profile.profile.target_role_modal.title_disabled`)
                        : i18n.t(`builder.career_profile.profile.target_role_message`)}
                    </DisabledMessage>
                  ) : (
                    <ItemError>
                      {i18n.t(`builder.career_profile.profile.target_role_modal.job_error`)}
                    </ItemError>
                  )}
                </ItemContainer>

                <ItemContainer>
                  <ItemHeader>
                    <ItemTitleContainer>
                      <LocationIcon />
                      <ItemTitle>
                        {i18n.t(
                          'builder.career_profile.editor.job_preference_section.selected_location.title',
                        )}
                      </ItemTitle>
                    </ItemTitleContainer>
                    <SelectedList>
                      {localProfile?.targetLocations
                        .filter(el => el.formattedName)
                        .map(location => (
                          <SelectedListItem key={location.formattedName}>
                            <TargetRoleTitle>{location.formattedName}</TargetRoleTitle>
                            <RemoveIcon
                              onClick={() =>
                                handleSimpleFieldUpdate(
                                  'targetLocations',
                                  localProfile.targetLocations.filter(
                                    el => el.formattedName !== location.formattedName,
                                  ),
                                )
                              }
                            />
                          </SelectedListItem>
                        ))}
                    </SelectedList>
                  </ItemHeader>
                  <CustomAutoSuggestField
                    value={locationText || ''}
                    placeholder={i18n.t(
                      'builder.career_profile.editor.job_preference_section.selected_location.placeholder',
                    )}
                    icon={<LocationInputIcon />}
                    defaultSuggestions={generateLocationSuggestion}
                    getSuggestionValue={suggestion => suggestion.formatted || suggestion.text}
                    fetchItems={createNewLocationSuggestionsApiFetcher}
                    clearPreviousSuggestions={false}
                    onChange={value => {
                      setLocationText(value)
                    }}
                    disabled={isLocationDisabled}
                    onSuggestionSelected={suggestion => {
                      const selectedItem =
                        suggestion.locationType === 'near_me' && nearMeLocation
                          ? nearMeLocation
                          : suggestion
                      const locationText = selectedItem.formatted || selectedItem.text
                      const selectedLocation = {
                        id:
                          selectedItem.value && selectedItem.value !== 'Remote'
                            ? parseInt(selectedItem.value)
                            : null,
                        formattedName: locationText,
                      }
                      const newSelectedLocations = uniqBy(
                        [selectedLocation, ...(localProfile?.targetLocations || [])],
                        'formattedName',
                      )
                      handleSimpleFieldUpdate('targetLocations', newSelectedLocations)
                      setLocationText('')
                    }}
                    withHover={false}
                  />
                  <DisabledMessage>
                    {isLocationDisabled
                      ? i18n.t(`builder.career_profile.profile.target_role_modal.location_disabled`)
                      : i18n.t(`builder.career_profile.profile.target_location_message`)}
                  </DisabledMessage>
                </ItemContainer>
              </JobPreferenceContainer>

              <DroppableSectionContainer useWindowAsScrollContainer useDragHandle>
                {sections.map((section, sectionIndex) => (
                  <SortableSection
                    key={section.id}
                    index={sectionIndex}
                    item={section}
                    levels={levels}
                    hideSkillLevel={localProfile?.hideSkillLevel}
                    locale={locale}
                    onAdd={handleCardAdd}
                    isCardOpen={isCardOpen}
                    onCardChange={handleCardUpdate}
                    onCardDelete={handleCardDelete}
                    onCardMove={handleCardMove}
                    onCardToggle={handleCardToggle}
                    skills={localProfile?.skillsAttributes || []}
                    updateSimpleField={handleSimpleFieldUpdate}
                    isRecommendedLengthHidden={true}
                    setContentOffset={setContentOffset}
                    triggerScroll={section.id === selectedSectionId}
                  />
                ))}
              </DroppableSectionContainer>
            </Body>
          </Content>
          <Footer>
            <ButtonsContainer>
              <Button theme={ButtonTheme.ghost} onClick={handleBackClick}>
                {i18n.t(`${translationKey}.cancel`)}
              </Button>
              <Button onClick={handleSave}>{i18n.t(`${translationKey}.save`)}</Button>
            </ButtonsContainer>
          </Footer>
        </Animation>
      </Container>

      {isAvatarEditorVisible && !isPhone && actualProfile && (
        <AvatarEditorModal
          avatar={actualProfile.profilePicture}
          onSubmit={handleUploadPhoto}
          onClose={handleTogglePhotoUploader}
        />
      )}
    </Screen>
  )
}
