import { LocationPin } from '../styles'
import { LayoutType } from '../types'
import LOCATION_PIN from '../assets/location_pin.png'
import { MissingInfoLayout } from './MissingInfoLayout'

export const NoLocation: React.FC<{ layout?: LayoutType; onClick: () => void }> = ({
  layout,
  onClick,
}) => {
  return (
    <MissingInfoLayout
      Image={<LocationPin src={LOCATION_PIN} alt="no location" />}
      layout={layout}
      translationKey="no_location"
      onClick={onClick}
    />
  )
}
