import type { Optional } from '@rio/types'
import { FetchStatuses } from 'builder/modules/constants'

export enum ColumnName {
  recommended = 'recommended',
  wishlist = 'wishlist',
  auto_apply = 'auto_apply',
  applied = 'applied',
  interview = 'interview',
  offer = 'offer',
  rejected = 'rejected',
  deleted = 'deleted',
}

export type AutoApplyStatus = 'processing' | 'auto_applied' | 'error'

export enum AutoApplyNotificationState {
  added = 'added',
  removed = 'removed',
  processing = 'processing',
  processing_by_agent = 'processing_by_agent',
}

export interface Job {
  // service fields
  id: number
  jobBoardId: number
  status: ColumnName
  autoApplyStatus: AutoApplyStatus | null
  positionNumber: number
  // user fields
  title: Optional<string>
  description: Optional<string>
  link: Optional<string>
  location: Optional<string>
  logo: Optional<string>
  notes: Optional<string>
  salary: Optional<string>
  company: Optional<string>
  companyDomain: Optional<string>
  externalSlugId: string
  chromeJob?: boolean
  tags: string[]
  isIrrelevant: Optional<boolean>
  isAgentAssigned?: boolean
  stillRelevant: Optional<boolean>
  tailorResumeStatus: Optional<boolean>
  showBanner: Optional<boolean>
  resumeId: number
  resumeScore: number
  dummy?: boolean
  // timestamps
  createdAt: string | number
  movedAt: Optional<string | number>
  updatedAt: Optional<string | number>
}

export interface ResumeSelection {
  id: number
  link: Optional<string>
}

export interface TailorResumeStatus {
  id: number | undefined
  status: FetchStatuses
}

export interface AutoApplyWidget {
  data: Job[]
  total: number
  autoApplyStatus: {
    processing: number
    autoApplied: number
    error: number
  }
}

export interface JobTrackingStore {
  columnNames: ColumnName[]
  fetchStatus?: FetchStatuses
  lastUpdatedCard: Job | null
  jobs: Job[]
  error: Optional<string>
  alreadyApplied: boolean
  applyTrackingDialog: ApplyTrackingDialog | null
  resumeList: ResumeList[]
  resumeSelection: ResumeSelection | null
  fetchUpdateResumeStatus: TailorResumeStatus | null
  isCioLimitModal: boolean
  jtShowBanner: boolean
  hasInstalledExtension: boolean
  canInstallExtension: boolean
  autoApplyWidget: AutoApplyWidget
  openCardDrawer: boolean
  openStatusChangeDrawer: boolean
  notTrackedRecommendationsCount: null | number
}

export type Columns = Record<ColumnName, Job[]>

export type FetchJobsPayload = Optional<{
  location?: string
  dummy?: boolean
  per_page?: number
  details?: boolean
  status?: ColumnName
  auto_applied?: boolean
}>

export type JobDeletePayload = { id: number }

export interface JobCreatePayload {
  status?: ColumnName
  title?: string
  company?: string
  link?: string
  description?: string
  open?: boolean
  external_slug_id: string
  location?: string
  logo?: string
  dummy?: boolean
}

export interface JobMovePayload {
  source: {
    droppableId: ColumnName
    index: number
  }
  destination: {
    droppableId: ColumnName
    index: number
  }
  jobId?: number
}

export interface ResumeList {
  // service fields
  id: number
  // user fields
  displayName: Optional<string>
  jobTitle: Optional<string>
  // timestamps
  updatedAt: Optional<string>
}

export interface UpdateSelectedResumePayload {
  user_id?: number
  resume_id?: number
  link?: Optional<string>
  job_card_id?: number
}

export interface ApplyTrackingDialog {
  show: boolean
  moveData?: JobMovePayload
}
