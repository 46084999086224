import styled, { css } from 'styled-components'
import Sizes from 'builder/styles/sizes'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: inline-flex;
  height: 50px;
  border-radius: ${Sizes['3XL']};
  background-color: ${Colors.Neutral10};
  padding: 1px;
  align-items: center;
  width: fit-content;
`
export const Timer = styled.div<{ $isPaused: boolean }>`
  padding-left: 16px;
  padding-right: 11px;
  min-width: 72px;
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral80};

  ${({ $isPaused }) =>
    $isPaused &&
    css`
      color: ${Colors.Neutral50};
    `}
`

export const Button = styled.div<{ $isPaused: boolean }>`
  cursor: pointer;
  background-color: ${Colors.Neutral15};
  border-radius: 50%;

  color: ${Colors.Neutral90};
  margin: 1px 0;
  padding: 13px 14px;

  &:hover {
    background-color: ${Colors.Neutral20};
  }

  ${({ $isPaused }) =>
    $isPaused &&
    css`
      background-color: ${Colors.White};
    `}
`
