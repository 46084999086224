import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'
import Button from 'builder/components/Button'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'

export const SuccessIcon = styled(Icon20.Tick)`
  fill: ${Colors.White};
  background: ${Colors.Green50};
  border-radius: 50%;
  height: 16px;
  width: 16px;
  position: relative;
  top: 10px;
  right: 10px;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes['2XS']};
  margin-bottom: ${Sizes.M};
`

export const JobPostingTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold}
  width: 100%;
  color: ${Colors.Neutral90};

  ${Media.Phone`
    ${Typography.S};
  `}
`

export const JobPostingSubTitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular}
  width: 100%;
  color: ${Colors.Neutral50};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;

  ${Media.Phone`
    margin-top:20px;
  `}
`

export const DownloadButton = styled(Button)`
  margin-right: 8px;
`

export const StartButton = styled(Button)``

export const ArrowTargetIconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 4px;
  color: inherit;
`

export const ArrowTargetIcon = styled(Icon20.ArrowTarget2)`
  width: 24px;
  height: 24px;
`
