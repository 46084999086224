import { Base } from 'builder/components/Card'
import DateRangePicker from 'builder/components/DateRangePicker'
import { TextField } from 'builder/components/TextField'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { formatDateRange } from 'builder/utils/formatDateRange'
import { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { CardContentWrapper, Field, Row } from 'builder/components/Card/styles'
import Select from 'builder/components/Select'
import { withAutoFocus } from '../../../../../hocs/AutoFocusHoc'
import { JobTitle } from './JobTitle'
import { PlannedResignationDate } from './PlannedResignationDate'
import { WorkExperienceAdditionalInformation } from './WorkExperienceAdditionalInformation'
import { trackInternalEvent } from '@rio/tracking'

const EmploymentHistoryCard = ({ item, autoFocus, locale, sectionId, onChange, ...rest }) => {
  const { i18n } = useI18n()

  const { id: cardId, title, employer, isDateUntilPresent } = item

  const cardTitle = useMemo(() => {
    const joiningString = ' ' + i18n.t('builder.resume_editor.labels.at') + ' '

    return (
      [title, employer].filter(Boolean).join(joiningString) ||
      i18n.t('builder.resume_editor.not_specified')
    )
  }, [i18n, employer, title])

  const cardSubtitle = formatDateRange(item)

  // Workaround to perform focusing on the description field from the tooltip
  // TODO: Find a better way

  const suggestionLocales = useTypedSelector(state => state.generalEditor.suggestionLocales)
  const suggestionEnabled = suggestionLocales.workExperience?.includes(locale)
  const suggestionConfig = useMemo(
    () =>
      suggestionEnabled
        ? {
            mode: 'phrases',
            scope: 'workExperience',
            onOpenSuggestionVariants: () => {
              trackInternalEvent('click_writing_help', {
                section: 'work_experience',
              })
            },
          }
        : {},
    [suggestionEnabled],
  )

  const updateSimpleField = useCallback(
    e => {
      onChange(sectionId, cardId, { [e.target.name]: e.target.value }, true)
    },
    [onChange, sectionId, cardId],
  )

  const updateDescription = useCallback(
    description => onChange(sectionId, cardId, { description }, true),
    [onChange, sectionId, cardId],
  )

  const updateDateRange = useCallback(
    value => onChange(sectionId, cardId, value, true),
    [onChange, sectionId, cardId],
  )

  const updateSelect = useCallback(
    (name, value) => onChange(sectionId, cardId, { [name]: value }, true),
    [onChange, sectionId, cardId],
  )

  return (
    <Base title={cardTitle} subtitle={cardSubtitle} item={item} sectionId={sectionId} {...rest}>
      <CardContentWrapper>
        <Row>
          <StartAndEndDate {...{ item, updateDateRange }} />

          <Employer {...{ item, updateSimpleField }} />
        </Row>

        <Row>
          {isDateUntilPresent ? (
            <PlannedResignationDate {...{ item, updateSimpleField }} />
          ) : (
            <ReasonForResignation {...{ item, updateSimpleField, updateSelect }} />
          )}
          <JobTitle {...{ item, updateSimpleField, autoFocus }} />
        </Row>

        <Row>
          <WorkExperienceAdditionalInformation
            {...{ item, updateDescription, suggestionConfig, locale, autoFocus }}
          />
        </Row>
      </CardContentWrapper>
    </Base>
  )
}

EmploymentHistoryCard.propTypes = {
  item: PropTypes.object,
  autoFocus: PropTypes.bool,
  locale: PropTypes.string,
  sectionId: PropTypes.any,
  onChange: PropTypes.func,
}

function StartAndEndDate(props) {
  const { i18n } = useI18n()
  const { item, updateDateRange } = props
  return (
    <>
      <Field>
        <DateRangePicker
          value={item}
          onChange={updateDateRange}
          currentlyLabel={i18n.t('builder.date_range_picker.currently.work')}
        />
      </Field>
    </>
  )
}

StartAndEndDate.propTypes = {
  item: PropTypes.object,
  updateDateRange: PropTypes.func,
}

function Employer(props) {
  const { i18n } = useI18n()
  const { item, updateSimpleField } = props

  return (
    <>
      <Field>
        <TextField
          label={i18n.t('builder.resume_editor.work_experiences_card.employer')}
          name="employer"
          onChange={updateSimpleField}
          value={item.employer || ''}
        />
      </Field>
    </>
  )
}

Employer.propTypes = {
  item: PropTypes.object,
  updateSimpleField: PropTypes.func,
}

function ReasonForResignation(props) {
  const { i18n } = useI18n()
  const { item, updateSelect } = props
  const Reasons = useRef({
    RETIREMENT: 'involuntary_retirement',
    PERSONAL_REASON: 'personal_reasons',
    NONE: null,
  })
  const options = useRef([
    { id: Reasons.current.NONE, name: '\0' },
    {
      id: Reasons.current.RETIREMENT,
      name: i18n.t(
        'builder.resume_editor.work_experience_section.resignation_options.involuntary_retirement',
      ),
    },
    {
      id: Reasons.current.PERSONAL_REASON,
      name: i18n.t(
        'builder.resume_editor.work_experience_section.resignation_options.personal_reasons',
      ),
    },
  ])

  const reason = item.reasonForResignation
  const handleSelect = useCallback(
    value => {
      updateSelect('reasonForResignation', value)
    },
    [updateSelect],
  )

  return (
    <>
      <Field>
        <Select
          label={i18n.t('builder.resume_editor.work_experience_section.reason_for_resignation')}
          selected={reason}
          onSelect={handleSelect}
          options={options.current}
        />
      </Field>
    </>
  )
}
ReasonForResignation.propTypes = {
  item: PropTypes.object,
  updateSelect: PropTypes.func,
}

export const EmploymentCard = withAutoFocus(EmploymentHistoryCard)
