import styled, { css } from 'styled-components'
import { TransitionStatus } from 'react-transition-group'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import DragHandle from 'builder/components/DragHandle'
import Icon24 from '../Icon'

type DragIconProps = {
  $isFoldableSectionsEnabled?: boolean
}

type ToggleIconProps = {
  $isDragging?: boolean
  $isOpened?: boolean
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
`

export const TitleWrapper = styled.div`
  ${Typography.Subhead};
  position: relative;
  display: inline-flex;
  align-items: center;
  min-width: 0;
  height: 28px;
  font-weight: 600;
`

export const Title = styled.div<{ $isEditing: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;

  ${props =>
    props.$isEditing &&
    css`
      visibility: hidden;
      min-width: 74px;
    `};
`

export const Input = styled.input`
  font: inherit;
  position: absolute;
  border: none;
  padding: 0;
  caret-color: ${Colors.Blue50};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  &::placeholder {
    color: ${Colors.Neutral50};
  }
`

export const Bar = styled.div<{ $in: TransitionStatus }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${Colors.Blue50};
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  transform: scale(0.5, 0);
  transition: transform 0.1s ease;

  ${props =>
    ({
      entering: 'opacity: 1; transform: scale(1, 1);',
      entered: 'opacity: 1; transform: scale(1, 1);',
      exiting: 'opacity: 0; transform: scale(1, 1); transition: opacity 0.1s ease;',
      exited: null,
      unmounted: null,
    }[props.$in])};
`

export const SectionIconList = styled.div<{ $isForcedVisible?: boolean }>(props => {
  return css`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    user-select: none;

    ${Media.Hover`
      display: ${props.$isForcedVisible ? 'flex' : 'none'};

      ${Container}:hover & {
        display: flex;
      }
    `};

    ${Media.Laptop`
      display: flex;
    `};
  `
})

export const SectionIcon = styled.div`
  color: ${Colors.Neutral30};
  cursor: pointer;
  user-select: none;
  position: relative;

  ${Media.Hover`
    &:hover {
      color: ${Colors.Blue50};
    }
  `};

  & svg {
    display: block;
  }
`

export const StripIcon = styled.div`
  cursor: pointer;
  margin-right: 4px;
  display: none;
  position: relative;

  &:last-child {
    display: block;
    margin-right: 0;
  }

  ${Media.Hover`
    &:hover {
      color: ${Colors.Blue50};
    }
  `};
`

export const DragIcon = styled(DragHandle)<DragIconProps>`
  position: absolute;

  ${Media.Phone`
    margin-right: 0;
  `};

  &:before {
    right: 0;
  }

  ${props =>
    props.$isFoldableSectionsEnabled
      ? css`
          opacity: 1;
          right: 100%;
          top: 2px;
          margin-right: 8px;

          ${Media.Hover`
            ${Container}:hover & {
              color: ${Colors.Blue50};
            }
          `};
        `
      : css`
          right: 100%;
          top: 50%;
          margin-right: 2px;
          margin-top: -10px;

          ${Media.Hover`
            ${Container}:hover & {
              opacity: 1;
            }
          `};
        `}
`

export const BadgesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
`

export const ToggleIcon = styled(Icon24.ToggleArrow)<ToggleIconProps>`
  display: ${props => props.$isDragging && 'none'};
  position: absolute;
  transform: rotate(${props => (props.$isOpened ? '-90deg' : '90deg')});
  right: -16px;
  top: 0;
  color: ${Colors.Neutral40};

  &:before {
    content: '';
    position: absolute;
    left: -8px;
    right: -8px;
    top: -8px;
    bottom: -8px;
  }

  ${Media.Phone`
    right: 0;
  `}
`
