import { useState, memo, useCallback, useMemo } from 'react'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { useDispatch, useSelector } from 'react-redux'
import startCase from 'lodash/startCase'
import { Resume } from 'packages/types'
import Icon from 'builder/components/Icon'
import { DocumentActionMenu } from 'builder/components/DocumentActionMenu/DocumentActionMenu'
import { ButtonSize } from 'builder/components/Button'
import { i18n as I18n } from 'builder/utils/i18n'
import { actions as renderingActions } from 'builder/modules/rendering'
import {
  actions as coRegActions,
  selectors as coRegSelectors,
} from 'builder/modules/coRegistration'
import { useConfig } from 'builder/hooks/useConfig'
import { MouseClickEvent, actions as uiActions } from 'builder/modules/ui'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { shouldOpenPlansPageAbandonment } from 'builder/components/AbandonmentModal/utils'
import { useAutoTailoringAppConfig } from 'builder/hooks/useAutoTailoringAppConfig'
import { DocumentTypes, FORMATS } from 'builder/modules/constants'
import { actions, selectors, isEmptyField, SectionNames } from 'builder/modules/resumeEditor'
import { getUpdatedTemplateAndFormat } from 'builder/utils/getTemplates'
import { isEmptySection } from 'builder/modules/resumeEditor/autoTailoringRules'
import CoRegPopup from '../../CoRegPopup'
import { CurrentDocument } from '../../types'
import { Actions, ActionsButton, ActionsButtonBadge, DownloadButton, DownloadMenu } from './styles'

export const IS_BUILDER_DOWNLOAD_AUTO_TAILOR_MODAL_SHOWN =
  'IS_BUILDER_DOWNLOAD_AUTO_TAILOR_MODAL_SHOWN'

type Props = {
  type: DocumentTypes
  documentId: number
  isDownloading: boolean
  currentDocument: CurrentDocument
  handleDownloadPdfClick?: () => void
  handleMenuClick?: () => void
  handleFullSize: (event: MouseClickEvent) => void
}

export const DownloadMenuLayout = memo((props: Props) => {
  const dispatch = useDispatch()
  const toggleCoRegPopup = coRegActions.togglePopup
  const openCoReg = coRegActions.open
  const isCoRegPopupOpen = useSelector(coRegSelectors.isPopupOpen)
  const isReadyToCoReg = useSelector(coRegSelectors.isResumeReady)
  const isFromJobTracker = useSelector(selectors.isFromJobTracker)
  const {
    type,
    documentId,
    isDownloading,
    handleDownloadPdfClick,
    currentDocument,
    handleMenuClick,
  } = props
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)
  const buttonsSize = ButtonSize.small
  const { isAutoTailoringEnabled } = useAutoTailoringAppConfig()
  const config = useConfig()
  const plansPageAbandonment = config?.features.plansPageAbandonment || false
  const showOneDownloadButton = !!(config?.features.downloadButtonLayout === 'one_button')
  const isResume = type === DocumentTypes.resume
  // Show the download resume option only for resume and the one button layout
  const showUpdatedMenuLayout = isResume && showOneDownloadButton
  const userData = useTypedSelector(userSelectors.userData)
  const downloadButtonActions = config?.features.downloadButtonActions

  const handleActionMenuOpen = useCallback(() => {
    setIsActionMenuOpen(true)
    if (isCoRegPopupOpen) dispatch(toggleCoRegPopup(false))
    trackInternalEvent('open_document_action_menu', { source: `${type}_editor` })
  }, [dispatch, isCoRegPopupOpen, toggleCoRegPopup, type])

  const handleActionMenuClose = useCallback(() => {
    setIsActionMenuOpen(false)
  }, [])

  const handleShareClick = useCallback(() => {
    trackInternalEvent('click_share_button', { source: `${type}_editor` })
  }, [type])

  const onCoRegPopupClick = useCallback(
    () => dispatch(openCoReg({ resumeId: documentId, source: 'pop_up' })),
    [dispatch, documentId, openCoReg],
  )
  const onCoRegPopupClose = useCallback(() => toggleCoRegPopup(false), [toggleCoRegPopup])

  const isDataMissed = useMemo(() => {
    if (isResume) {
      const isWorkExperienceEmpty = isEmptySection(
        currentDocument as Resume,
        SectionNames.workExperiences,
      )
      const isProfileEmpty = isEmptyField(currentDocument as Resume, SectionNames.profile)
      return isWorkExperienceEmpty && isProfileEmpty
    } else {
      return false
    }
  }, [currentDocument, isResume])

  const handleDownloadDocument = useCallback(
    ({ event, format = FORMATS.pdf }) => {
      const { documentId: id, type, currentDocument, handleFullSize } = props
      const { updatedTemplate, updatedFormat } = getUpdatedTemplateAndFormat(
        showOneDownloadButton,
        type,
        currentDocument.template as string,
        format,
      )
      // Track in ClickHouse
      trackInternalEvent(`click_download_${type}_button`, {
        format: updatedFormat,
        source: `${type}_editor`,
        template: updatedTemplate,
      })

      // Track in Google Analytics
      trackMarketingEvent('Preview Modal', `Download ${startCase(type)}`, {
        eventLabel: updatedFormat,
      })

      if (downloadButtonActions === 'cust_page' && isResume) {
        handleFullSize(event)
        return
      }
      const wasAutoTailorDownloadModalShown = localStorage.getItem(
        IS_BUILDER_DOWNLOAD_AUTO_TAILOR_MODAL_SHOWN,
      )
      if (
        (downloadButtonActions === 'at_banner_link' || downloadButtonActions === 'at_banner') &&
        isAutoTailoringEnabled &&
        !isDataMissed &&
        isResume &&
        !wasAutoTailorDownloadModalShown
      ) {
        if (downloadButtonActions === 'at_banner') {
          dispatch(actions.setFeaturingAutoTailorModalOpenOnDownload(true))
        }
        dispatch(actions.setOpenOptimizerModal(true))
        dispatch(
          actions.setResumeDownloadData({
            id,
            type,
            format: updatedFormat,
            source: 'builder',
            template: updatedTemplate,
          }),
        )
        localStorage.setItem(IS_BUILDER_DOWNLOAD_AUTO_TAILOR_MODAL_SHOWN, 'true')
        return
      }

      if (
        window.location.pathname.includes('/resumes') &&
        userData?.registeredAt &&
        shouldOpenPlansPageAbandonment(
          userData.registeredAt,
          plansPageAbandonment || false,
          userData.showAbandonmentFlows,
        ) &&
        (downloadButtonActions === 'control' || downloadButtonActions === 'default')
      ) {
        trackInternalEvent('show_abandonment_popup', { source: 'dashboard', type: 'plans' })
        dispatch(uiActions.openPlansPageAbandonmentModal())
        return
      }

      dispatch(
        renderingActions.download({
          id,
          type,
          format: updatedFormat,
          source: 'builder',
          template: updatedTemplate,
        }),
      )
      if (isFromJobTracker) {
        dispatch(actions.setOpenSavingInfoBanner(true))
        trackInternalEvent('view_save_banner', {
          label: 'optimizer',
        })
      }
    },
    [
      userData?.registeredAt,
      userData?.showAbandonmentFlows,
      plansPageAbandonment,
      downloadButtonActions,
      props,
      showOneDownloadButton,
      isAutoTailoringEnabled,
      isDataMissed,
      dispatch,
      isFromJobTracker,
      isResume,
    ],
  )

  const handleClick = useCallback(
    (event: MouseClickEvent) => {
      if (handleDownloadPdfClick) {
        handleDownloadPdfClick()
        return
      }
      if (showUpdatedMenuLayout) {
        handleActionMenuOpen()
      } else {
        handleDownloadDocument({ event })
      }
    },
    [handleActionMenuOpen, handleDownloadDocument, showUpdatedMenuLayout, handleDownloadPdfClick],
  )

  return (
    <DownloadMenu>
      <DownloadButton onClick={handleClick} isDisabled={isDownloading} size={buttonsSize}>
        {I18n.t(
          `builder.resume_editor.${
            showUpdatedMenuLayout ? 'download_document_menu.button_text' : 'download'
          }`,
        )}
      </DownloadButton>
      <Actions>
        {!showUpdatedMenuLayout && (
          <ActionsButton
            onClick={handleMenuClick || handleActionMenuOpen}
            isDisabled={isDownloading}
            size={buttonsSize}
          >
            <Icon.More />
          </ActionsButton>
        )}
        <ActionsButtonBadge isVisible={!isCoRegPopupOpen && isReadyToCoReg && !isActionMenuOpen} />
        {isActionMenuOpen && (
          <DocumentActionMenu
            {...{
              position: 'bottom',
              documentId,
              onDownload: handleDownloadDocument,
              documentType: type,
              onClose: handleActionMenuClose,
              onShare: handleShareClick,
            }}
          />
        )}
        {isCoRegPopupOpen && <CoRegPopup onClick={onCoRegPopupClick} onClose={onCoRegPopupClose} />}
      </Actions>
    </DownloadMenu>
  )
})
