import React from 'react'
import { Button } from 'builder/components/Button'
import { Job } from 'builder/modules/jobTracking'
import { GenerateStatus } from '../GenerateStatus'
import ModalList from '../ModalList'
import { existingDataList } from '../types'
import ModalWrapper from './ModalWrapper'
import { ButtonsWrapper } from './styles'

type Props = {
  job: Partial<Job>
  onClose: () => void
  onStart: () => void
  isLoading: boolean
}

const ExistingDataModal: React.FC<Props> = ({ job, onClose, onStart, isLoading }) => {
  return (
    <ModalWrapper
      cardType="default"
      title="Start practicing for this interview"
      job={job}
      onClose={onClose}
      action={
        <ButtonsWrapper>
          <Button isLoading={isLoading} onClick={onStart}>
            Start Interview
          </Button>

          {isLoading && <GenerateStatus type="loading" error="" />}
        </ButtonsWrapper>
      }
    >
      <ModalList list={existingDataList} />
    </ModalWrapper>
  )
}

export default ExistingDataModal
