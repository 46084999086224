import { createAction } from '@reduxjs/toolkit'
import {
  EnumerableResumeSectionType,
  Optional,
  Resume,
  ResumeCustomSectionCard,
  UserDocumentTemplateId,
} from 'packages/types'
import { CallBackProps } from 'react-joyride'
import { TABS } from 'builder/components/FillResumeModal/constants'
import { ResumeExample } from 'builder/components/FillResumeModal/UseExample/types'
import {
  AIProfileGenerateType,
  AIResumeFlows,
  AIResumeLinkedInSteps,
  AIResumeWarningModalActions,
  AIResumeWarningModalTypes,
  FetchStatuses,
  GenerateAiProfileStatus,
  GenerateResumeStatus,
  ResumeUploadStatus,
  ResumeUploadStep,
  ResumeValidationStatus,
  SpeechToTextStatus,
  VoiceInputErrors,
} from '../constants'
import { DownloadTypes } from '../rendering'
import { SectionNames } from './sectionNames'
import {
  AIResumeWorkExpCardDataError,
  HighlightedElementData,
  JobPostingAPIData,
  JobPostingManualInputValues,
  LinkedInProfileData,
  PrefillStatuses,
  QuestionnaireData,
  ResumeEditorStore,
  ResumeScoreAPIData,
  Step,
  AvailableAISuggestions,
  AutoTailoringOpenedModalType,
  ResumeEventTrackedSectionsType,
  ResumeOpenedCard,
  ResumeSectionId,
  OptimizerBannerTriggerSource,
} from './types'

export type UpdateCardPayload = {
  sectionId: ResumeSectionId
  cardId: Optional<number> | string
  debounce?: boolean
  isCustom?: boolean
  values?: object
}

export type UpdateSimpleFieldPayload = Exclude<
  {
    [K in keyof Resume]: {
      name: K
      value: Resume[K]
      debounce?: boolean
    }
  }[keyof Resume],
  undefined
>

export type AddCardPayload<K extends EnumerableResumeSectionType = EnumerableResumeSectionType> =
  | {
      sectionName: number | string
      cardId?: string
      options?: {
        shouldOpen?: boolean
        scrollIntoViewport?: boolean
        position?: 'before' | 'after'
        fields?: Partial<ResumeCustomSectionCard>
      }
    }
  | {
      sectionName: K
      cardId?: string
      options?: {
        shouldOpen?: boolean
        scrollIntoViewport?: boolean
        position?: 'before' | 'after'
        fields?: Partial<Resume[K][number]>
      }
    }

export type PrefillResumeErrorPayload = {
  error: string | null
  errorSource: ResumeUploadStep | null
}

export type QuestionaryCardType = 'workExperience' | 'education'

export type QuestionaryCardData<T extends QuestionaryCardType> =
  QuestionnaireData['userInput'][T]['cards'][number]

export type QuestionnaireCardDataPayload<T extends QuestionaryCardType = QuestionaryCardType> = {
  cardType: T
  data: QuestionaryCardData<T>
}

export type ResumeOptimizerManualInputPayload<
  K extends keyof JobPostingManualInputValues = keyof JobPostingManualInputValues,
> = {
  name: K
  value: JobPostingManualInputValues[K]
}

export type HighlightEmptySectionPayload = Pick<
  HighlightedElementData,
  'content' | 'title' | 'buttonLabel'
>

export type StartResumeUpdatePayload = (object & { debounce?: boolean; name?: string }) | undefined

export type GenerateAICVUntailoredPayload = {
  jobPostingLink?: string
  resumeId: number
  jobTitle?: string
  companyName?: string
}

export type StartImprovingResumePayload = {
  jobPostingLink?: string
  resumeId?: number
  jobTitle?: string
  companyName?: string
}

export type EditJobDetailsPayload = {
  resumeId: number
  successMessage: string
  jobPostingLink: string
  jobTitle?: string
  companyName?: string
}

export type CheckResumeScorePayload = {
  jobPostingLink?: string
  resumeId?: number
  jobPostingManualInput?: {
    companyName: string
    jobTitle: string
    description: string
  }
}

export type AutoTailorResumePayload = {
  resumeId: number
  jobPostingId: number
  selectedKeywords: string[]
}

export type MoveSectionPayload = {
  sectionId: ResumeSectionId
  offset: number
}

export type AddCustomSectionPayload = {
  externalId: string
  options?: { scrollIntoViewport?: boolean }
}

export const RESUME_REMOTE_UPDATE_SUCCESS = 'builder/RESUME_REMOTE_UPDATE_SUCCESS'

export const actions = {
  fetchResumeRequest: createAction<{ id: number; isResumeOptimizerFeatureOn?: boolean }>(
    'builder/RESUME_FETCH_REQUEST',
  ),
  fetchResumeSuccess: createAction<Resume | null>('builder/RESUME_FETCH_SUCCESS'),
  fetchResumeFail: createAction<Error>('builder/RESUME_FETCH_FAIL'),
  updateSimpleField: createAction<UpdateSimpleFieldPayload>('builder/RESUME_UPDATE_SIMPLE_FIELD'),
  updateTemplate: createAction<{ id: UserDocumentTemplateId }>('builder/RESUME_UPDATE_TEMPLATE'),
  updateCard: createAction<UpdateCardPayload>('builder/RESUME_UPDATE_CARD'),
  addCustomSection: createAction<AddCustomSectionPayload>('builder/RESUME_ADD_CUSTOM_SECTION'),
  setCustomSection: createAction<{ externalId: string }>('builder/SET_CUSTOM_SECTION'),
  renameSection: createAction<{
    id: ResumeSectionId | SectionNames
    value: string | null
    debounce?: boolean
  }>('builder/RESUME_RENAME_SECTION'),
  deleteSection: createAction<{
    id: ResumeSectionId
    options: { isCustom?: boolean; sortId: string }
  }>('builder/RESUME_DELETE_SECTION'),
  addCard: createAction<AddCardPayload>('builder/RESUME_ADD_CARD'),
  deleteCard: createAction<{
    sectionName: ResumeSectionId
    cardId: Optional<string | number>
    isCustom?: boolean
  }>('builder/RESUME_DELETE_CARD'),
  moveCard: createAction<{
    sectionId: ResumeSectionId
    oldIndex: number
    newIndex: number
  }>('builder/RESUME_MOVE_CARD'),
  moveSection: createAction<MoveSectionPayload>('builder/RESUME_MOVE_SECTION'),
  updateResumeSuccess: createAction<Resume>(RESUME_REMOTE_UPDATE_SUCCESS),
  updateResumeFail: createAction<Error | undefined>('builder/RESUME_REMOTE_UPDATE_FAIL'),
  uploadPhoto: createAction<{
    file?: string | Blob | null
    transform?: object
    processedFile?: string | Blob
  }>('builder/RESUME_UPLOAD_PHOTO'),
  deletePhoto: createAction('builder/RESUME_DELETE_PHOTO'),
  changeEditorState: createAction<Partial<ResumeEditorStore>>('builder/RESUME_CHANGE_EDITOR_STATE'),
  startResumeUpdate: createAction<StartResumeUpdatePayload>('builder/RESUME_START_UPDATE'),
  prefillResume: createAction<{ documentId?: number; exampleId?: number }>(
    'builder/PREFILL_RESUME',
  ),
  setCurrentWizardStep: createAction<string>('builder/SET_CURRENT_WIZARD_STEP'),
  updateWizardStep: createAction<Partial<Step>>('builder/UPDATE_WIZARD_STEP'),
  openCard: createAction<ResumeOpenedCard | null>('builder/SET_OPEN_CARD_ID'),
  openSection: createAction<SectionNames | ResumeSectionId | null>('builder/SET_OPEN_SECTION'),
  // Prefilling
  setPrefillStatus: createAction<PrefillStatuses>('builder/SET_PREFILL_STATUS'),
  setOpenOptimizerModal: createAction<boolean>('builder/SET_OPEN_OPTIMIZER_MODAL'),
  setIsEditJobPosting: createAction<boolean>('builder/SET_IS_EDIT_JOB_POSTING'),
  setJobPostingLinkForModal: createAction<string>('builder/SET_JOB_POSTING_LINK_FOR_MODAL'),
  startResumeOptimizerProcess: createAction('builder/START_RESUME_OPTIMIZER_PROCESS'),
  resetResumeOptimizerProcess: createAction('builder/RESET_RESUME_OPTIMIZER_PROCESS'),
  checkResumeScore: createAction<CheckResumeScorePayload>('builder/CHECK_RESUME_SCORE'),
  setResumeScoreAPIResponse: createAction<ResumeScoreAPIData>(
    'builder/SET_RESUME_SCORE_API_RESPONSE',
  ),
  resetResumeScoreAPIResponse: createAction('builder/RESET_RESUME_SCORE_API_RESPONSE'),
  setFetchResumeScoreStatus: createAction<FetchStatuses>('builder/SET_FETCH_RESUME_SCORE_STATUS'),
  setFetchJobPostingDataStatus: createAction<FetchStatuses>(
    'builder/SET_FETCH_JOB_POSTING_DATA_STATUS',
  ),
  setisClickedonDeleteJobPosting: createAction<boolean>(
    'builder/SET_IS_CLICKED_ON_DELETE_JOB_POSTING',
  ),
  setisClickedonEditJobDetails: createAction<boolean>('builder/SET_IS_CLICKED_ON_EDIT_JOB_DETAILS'),
  setDeleteJobPostingStatus: createAction<FetchStatuses>('builder/SET_DELETE_JOB_POSTING'),
  deleteJobPosting: createAction<{ jobPostingId: number; resumeId: number }>(
    'builder/DELETE_JOB_POSTING',
  ),
  editJobDetails: createAction<EditJobDetailsPayload>('builder/EDIT_JOB_DETAILS'),
  editJobDetailsStatus: createAction<FetchStatuses>('builder/EDIT_JOB_DETAILS_STATUS'),
  setSaveJobPostingDataStatus: createAction<FetchStatuses>(
    'builder/SET_SAVE_JOB_POSTING_DATA_STATUS',
  ),
  setShowManualInputScreen: createAction<boolean>('builder/SET_SHOW_MANUAL_INPUT_SCREEN'),
  setTemporaryJobPostingAPIData: createAction<JobPostingAPIData>(
    'builder/SET_TEMPORARY_JOB_POSTING_API_DATA',
  ),
  saveJobPostingAPIData: createAction('builder/SAVE_JOB_POSTING_API_DATA'),
  resetJobPostingAPIData: createAction('builder/RESET_JOB_POSTING_API_DATA'),
  setOpenedAISuggestionsPopup: createAction<Optional<AvailableAISuggestions>>(
    'builder/SET_OPENED_AI_SUGGESTIONS_POPUP',
  ),
  refreshAISuggestions: createAction('builder/REFRESH_AI_SUGGESTIONS'),
  startImprovingResume: createAction<StartImprovingResumePayload>('builder/START_IMPROVING_RESUME'),
  setIsEditJobDetails: createAction<boolean>('builder/SET_IS_EDIT_JOB_DETAILS'),
  setEditJobTitle: createAction<string>('builder/SET_EDIT_JOB_TITLE'),
  setEditCompanyName: createAction<string>('builder/SET_EDIT_COMPANY_NAME'),
  ShowToolTip: createAction<boolean>('builder/ShHOW_TOOL_TIP'),
  setFromJobTracker: createAction<boolean>('builder/SET_FROM_JOB_TRACKER'),
  setOpenSavingInfoBanner: createAction<boolean>('builder/SET_OPEN_SAVING_INFO_BANNER'),
  setChangeJobPostingLink: createAction<boolean>('builder/SET_CHANGE_JOB_POSTING_LINK'),
  setResumeValidationStatus: createAction<ResumeValidationStatus>(
    'builder/SET_RESUME_VALIDATION_STATUS',
  ),
  setResumeForPrefill: createAction<File | null>('builder/SET_RESUME_FOR_PREFILL'),
  setResumeValidationError: createAction<PrefillResumeErrorPayload>(
    'builder/SET_RESUME_VALIDATION_FAIL',
  ),
  parseAndSavePrefilledResume: createAction<{ selectedResume: File; resumeId: number }>(
    'builder/PARSE_AND_SAVE_PREFILLED_RESUME',
  ),
  setIsSavingPrefilledResume: createAction<boolean>('builder/ SET_IS_UPDATED_RESUME_LOADING'),
  setShowPrefillResumeModal: createAction<boolean>('builder/SET_SHOW_PREFILL_RESUME_MODAL'),
  setResumeUploadStatus: createAction<ResumeUploadStatus>('builder/SET_RESUME_UPLOAD_STATUS'),
  setPrefillResumeGenericError: createAction<PrefillResumeErrorPayload>(
    'builder/SET_PREFILL_RESUME_GENERIC_ERROR',
  ),
  setIsClickedOnAiCVBanner: createAction<boolean>('builder/SET_S_CLICKED_ON_AI_CV_BANNER'),
  setIsClickedOnAiCVEditJobDetails: createAction<boolean>(
    'builder/SET_IS_CLICKED_ON_AI_CV_EDIT_JOB_DETAILS',
  ),
  setIsClickedOnAiCVEditModalIcon: createAction<boolean>(
    'builder/SET_IS_CLICKED_ON_AI_CV_EDIT_MODAL_ICON',
  ),
  setIsEditJobDetailsFocused: createAction<boolean>('builder/SET_IS_EDIT_JOB_DETAILS_focused'),
  setOpenAICVOptimizerModal: createAction<boolean>('builder/SET_OPEN_AICV_OPTIMIZER_MODAL'),
  generateTailoredAICoverLetter: createAction<{ resumeId: number }>(
    'builder/GENERATE_TAILORED_AI_COVER_LETTER',
  ),
  generateAICVUntailored: createAction<GenerateAICVUntailoredPayload>(
    'builder/GENERATE_TAILORED_AICV_UNTAILORED',
  ),
  setGenerateAICoverLetterStatus: createAction<FetchStatuses>(
    'builder/SET_GENERATE_AI_COVER_LETTER_STATUS',
  ),
  setAICVEditedJobTitle: createAction<string>('builder/SET_AICV_EDITED_JOB_TITLE'),
  setAICVEditedCompanyName: createAction<string>('builder/SET_AICV_EDITED_COMPANY_NAME'),
  setResumeJobDetails: createAction<{
    jobTitle: Optional<string>
    employerName: Optional<string>
  }>('builder/SET_RESUME_JOB_DETAILS'),
  setShowUntailoreView: createAction<boolean>('builder/SET_SHOW_UNTAILORED_VIEW'),
  setGenerateCLBannerEventTracked: createAction<boolean>(
    'builder/SET_GENERATE_CL_BANNER_EVENT_TRACKED',
  ),
  setShowAIResumeModal: createAction<boolean>('builder/SET_SHOW_AI_RESUME_MODAL'),
  setAIResumeFlow: createAction<AIResumeFlows>('builder/SET_AI_RESUME_FLOW'),
  setHideLinkedInFlow: createAction<boolean>('builder/SET_HIDE_LINKED_IN_FLOW'),
  setAIResumeLinkedInStep: createAction<AIResumeLinkedInSteps>(
    'builder/SET_AI_RESUME_LINKEDIN_STEP',
  ),
  setLinkedInProfileURL: createAction<string>('builder/SET_LINKEDIN_PROFILE_URL'),
  fetchLinkedInProfile: createAction<{ linkedInProfileURL: string }>(
    'builder/FETCH_LINKEDIN_PROFILE',
  ),
  setLinkedInProfileFetchStatus: createAction<boolean>('builder/SET_LINKEDIN_PROFILE_FETCH_STATUS'),
  setLinkedInProfileData: createAction<LinkedInProfileData>('builder/SET_LINKEDIN_PROFILE_DATA'),
  setQuestionnaireStep: createAction<number>('builder/SET_QUESTIONNAIRE_STEP'),
  updateQuestionnaireProgress: createAction<number>('builder/UPDATE_QUESTIONNAIRE_PROGRESS'),
  setQuestionnaireCardData: createAction<QuestionnaireCardDataPayload>(
    'builder/SET_QUESTIONNAIRE_CARD_DATA',
  ),
  // setQuestionnaireCardData2: createAction<QuestionnaireCardDataPayload>(
  //   'builder/SET_QUESTIONNAIRE_CARD_DATA',
  //   <T extends QuestionaryCardType>(cardType: T, data: QuestionaryCardData<T>) => {
  //     return { cardType, data }
  //   },
  // ),
  setConfirmDeleteQuestionnaireCardId: createAction<{ deleteCardId: string }>(
    'builder/SET_CONFIRM_DELETE_QUESTIONNAIRE_CARD_ID',
  ),
  deleteQuestionnaireCard: createAction<{
    cardType: QuestionaryCardType
    deleteCardId: string
  }>('builder/DELETE_QUESTIONNAIRE_CARD'),
  collapseQuestionnaireCards: createAction<{
    cardType: QuestionaryCardType
    excludeCardsIds?: string[]
  }>('builder/COLLAPSE_QUESTIONNAIRE_CARDS'),
  pushEmptyEducationInQuestionnaire: createAction('builder/PUSH_EMPTY_EDUCATION_IN_QUESTIONNAIRE'),
  pushEmptyWorkExpInQuestionnaire: createAction('builder/PUSH_EMPTY_WORK_EXP_IN_QUESTIONNAIRE'),
  setQuestionnaireWorkExpCardErrors: createAction<AIResumeWorkExpCardDataError>(
    'builder/SET_QUESTIONNAIRE_WORK_EXP_CARD_ERRORS',
  ),
  setAIResumeDesiredPostion: createAction<string>('builder/SET_AI_RESUME_DESIRED_POSITION'),
  resetQuestionnaireData: createAction('builder/RESET_QUESTIONNAIRE_DATA'),
  updateCurrentSkills: createAction<string>('builder/UPDATE_CURRENT_SKILLS'),
  removeFromCurrentSkills: createAction<string>('builder/REMOVE_FROM_CURRENT_SKILLS'),
  resetAIResumeData: createAction('builder/RESET_AI_RESUME_DATA'),
  setGenerateResumeStatus: createAction<GenerateResumeStatus>('builder/SET_RESUME_GENERATE_STATUS'),
  generateResumeFromLinkedIn: createAction('builder/GENERATE_RESUME_FROM_LINKEDIN'),
  generateResumeFromQuestionnaire: createAction('builder/GENERATE_RESUME_FROM_QUESIONNAIRE'),
  setSuggestedSkills: createAction<string[]>('builder/SET_SUGGESTED_SKILLS'),
  removeFromSuggestedSkills: createAction<string>('builder/UPDATE_SUGGESTED_SKILLS'),
  extractTextFromVoiceInput: createAction<{ audioBlob: Blob[] }>(
    'builder/EXTRACT_TEXT_FROM_VOICE_INPUT',
  ),
  setAccomplishmentsVoiceProcessingStatus: createAction<SpeechToTextStatus>(
    'builder/SET_ACCOMPLISHMENTS_VOICE_PROCESSING_STATUS',
  ),
  setPastAccomplishmentsText: createAction<string>('builder/SET_PAST_ACCOMPLISHMENTS_TEXT'),
  setShowAIResumeWarningModal: createAction<{
    showModal: boolean
    type?: AIResumeWarningModalTypes | null
  }>('builder/SET_SHOW_AI_RESUME_WARNING_MODAL'),
  setAIResumeWarningModalAction: createAction<AIResumeWarningModalActions | null>(
    'builder/SET_AI_RESUME_WARNING_MODAL_ACTION',
  ),
  setActiveAccomplishmentsTab: createAction<TABS>('builder/SET_PAST_ACCOMPLISHMENTS_TAB'),
  setAccomplishmentsAudio: createAction<Blob[]>('builder/SET_ACCOMPLISHMENTS_AUDIO'),
  setAccomplishmentsAudioDuration: createAction<number>(
    'builder/SET_ACCOMPLISHMENTS_AUDIO_DURATION',
  ),
  setGoalsVoiceProcessingStatus: createAction<SpeechToTextStatus>(
    'builder/SET_GOALS_VOICE_PROCESSING_STATUS',
  ),
  setFutureGoalsText: createAction<string>('builder/SET_FUTURE_GOALS_TEXT'),
  setActiveGoalsTab: createAction<TABS>('builder/SET_FUTURE_GOALS_TAB'),
  setGoalsAudio: createAction<Blob[]>('builder/SET_GOALS_AUDIO'),
  setGoalsAudioDuration: createAction<number>('builder/SET_GOALS_AUDIO_DURATION'),
  setAccomplishmentsVoiceInputError: createAction<VoiceInputErrors | null>(
    'SET_ACCOMPLISHMENTS_VOICE_INPUT_ERROR',
  ),
  setGoalsVoiceInputError: createAction<VoiceInputErrors | null>('SET_GOALS_VOICE_INPUT_ERROR'),
  setShowUseExamplePopup: createAction<boolean>('builder/SET_SHOW_USE_EXAMPLE_POPUP'),
  fetchResumeExamples: createAction('builder/FETCH_RESUME_EXAMPLES'),
  fetchingResumeExamples: createAction<boolean>('builder/FETCHING_RESUME_EXAMPLES'),
  setResumeExamples: createAction<ResumeExample[]>('builder/SET_RESUME_EXAMPLES'),
  setShowChangeTemplateTooltip: createAction<boolean>('builder/SET_SHOW_CHANGE_TEMPLATE_TOOLTIP'),
  generateResumeFromExample: createAction<{ exampleId: number; jobTitle: string }>(
    'builder/GENERATE_RESUME_FROM_EXAMPLE',
  ),
  setTailorBannerEventTracked: createAction<boolean>('builder/SET_TAILOR_BANNER_EVENT_TRACKED'),
  setResumeOptimizerManualInput: createAction<ResumeOptimizerManualInputPayload>(
    'builder/SET_RESUME_OPTIMIZER_MANUAL_INPUT',
  ),
  resetResumeOptimizerManualInput: createAction('builder/CLEAR_RESUME_OPTIMIZER_MANUAL_INPUT'),
  updateResumeOptimizerData: createAction<{ jobPostingId?: number; resumeId?: number }>(
    'builder/UPDATE_RESUME_OPTIMIZER_DATA',
  ),
  generateResumeAiSummary: createAction('builder/GENERATE_AI_PROFILE_SUMMARY'),
  setSummaryStatus: createAction<GenerateAiProfileStatus>('builder/GENERATE_SUMMARY_STATUS'),
  resetSummaryStatus: createAction('builder/RESET_SUMMARY_STATUS'),
  toggleAIProfileSummaryPaywall: createAction('builder/SET_SHOW_AI_PROFILE_SUMMARY_PAYWALL'),
  setAiProfileSummaryText: createAction<string | null>('builder/SET_AI_PROFILE_SUMMARY_TEXT'),
  highlightEmptyWorkExperience: createAction<HighlightEmptySectionPayload>(
    'builder/HIGHLIGHT_EMPTY_WORK_EXPERIENCE',
  ),
  resetAIProfileSummaryFlow: createAction('builder/RESET_AI_PROFILE_SUMMARY_FLOW'),
  setAIProfileActionType: createAction<AIProfileGenerateType>('builder/SET_AI_PROFILE_ACTION_TYPE'),
  setAIProfileResultContentAnimation: createAction<boolean>(
    'builder/SET_AI_PROFILE_RESULT_CONTENT_ANIMATION',
  ),
  setAIProfileEditorFieldAnimation: createAction<boolean>(
    'builder/SET_AI_PROFILE_EDITOR_FIELD_ANIMATION',
  ),
  stopAIProfileSummaryEditorAnimation: createAction(
    'builder/STOP_AI_PROFILE_SUMMARY_EDITOR_ANIMATION',
  ),
  startAIProfileSummaryEditorAnimation: createAction(
    'builder/START_AI_PROFILE_SUMMARY_EDITOR_ANIMATION',
  ),
  setHighlightedElement: createAction<HighlightedElementData | null>(
    'builder/SET_HIGHLIGHTED_ELEMENT',
  ),
  closeHighlightedElement: createAction<CallBackProps>('builder/CLOSE_HIGHLIGHTED_ELEMENT'),
  setOpenedKeywordsModalType: createAction<SectionNames | null>(
    'builder/SET_OPENED_KEYWORDS_MODAL_TYPE',
  ),
  setIsImproveResumePanelOpen: createAction<{ status: boolean }>(
    'builder/SET_IMPROVE_RESUME_PANEL_OPEN',
  ),
  setIsImproveResumeBadgeAnimating: createAction<{ status: boolean }>(
    'builder/SET_IMPROVE_RESUME_BADGE_ANIMATING',
  ),
  setIsHelpWithWritingOpen: createAction<{ status: boolean }>(
    'builder/SET_HELP_WITH_RESUME_WRITING_OPEN',
  ),
  setIsAIProfileSummaryResultOpen: createAction<{ status: boolean }>(
    'builder/SET_AI_PROFILE_SUMMARY_RESULT_OPEN',
  ),
  setAutoTailoringModalStep: createAction<AutoTailoringOpenedModalType | null>(
    'builder/SET_AUTO_TAILORING_MODAL_STEP',
  ),
  setAutoTailoringApproveModalStatus: createAction<boolean>(
    'builder/SET_AUTO_TAILORING_APPROVE_MODAL_STATUS',
  ),
  autoTailorResume: createAction<AutoTailorResumePayload>('builder/AUTO_TAILOR_RESUME'),
  autoTailorAfterPayment: createAction<AutoTailorResumePayload>(
    'builder/AUTO_TAILOR_AFTER_PAYMENT',
  ),
  setAutoTailoringFetchStatus: createAction<FetchStatuses>(
    'builder/SET_AUTO_TAILORING_FETCH_STATUS',
  ),
  setAutoTailoredResume: createAction<Resume | null>('builder/SET_AUTO_TAILORED_RESUME'),
  setOriginalResume: createAction<Resume | null>('builder/SET_ORIGINAL_RESUME'),
  approveResume: createAction('builder/APPROVE_RESUME'),
  approveResumeSuccess: createAction('builder/APPROVE_RESUME_SUCCESS'),
  setJobPostingDataForAutoTailoring: createAction<ResumeScoreAPIData>(
    'builder/SET_JOB_POSTING_DATA_FOR_AUTO_TAILORING',
  ),
  toggleKeywordForAutoTailoring: createAction<string>('builder/TOGGLE_KEYWORD_FOR_AUTO_TAILORING'),
  setAllKeywordForAutoTailoring: createAction<boolean>(
    'builder/SET_ALL_KEYWORD_FOR_AUTO_TAILORING',
  ),
  setApproveAutoTailoredResumeStatus: createAction<FetchStatuses>(
    'builder/SET_APPROVED_AUTO_TAILORED_RESUME_STATUS',
  ),
  setIsProfileSummaryWithTailoredResumeModalOpen: createAction<boolean>(
    'builder/SET_IS_PROFILE_SUMMARY_WITH_TAILORED_RESUME_MODAL_OPEN',
  ),
  setIsIncludeJobPostingToProfileSummary: createAction<boolean>(
    'builder/SET_IS_INCLUDE_JOB_POSTING_TO_PROFILE_SUMMARY',
  ),
  setResumeEventTrackedSections: createAction<ResumeEventTrackedSectionsType>(
    'builder/SET_RESUME_EVENT_TRACKED_SECTIONS',
  ),
  setResumeDownloadData: createAction<DownloadTypes>('builder/SET_RESUME_DOWNLOAD_DATA'),
  resetResumeDownloadData: createAction('builder/RESET_RESUME_DOWNLOAD_DATA'),
  setFeaturingAutoTailorModalOpenOnDownload: createAction<boolean>(
    'builder/SET_FEATURING_AUTO_TAILOR_MODAL_ON_DOWNLOAD',
  ),
  setOptimizerBannerTriggeredFrom: createAction<OptimizerBannerTriggerSource | null>(
    'builder/SET_OPTIMIZER_BANNER_TRIGGERED_FROM',
  ),
  setIsPageScrolled: createAction<boolean>('builder/SET_IS_PAGE_SCROLLED'),
}
