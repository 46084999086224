import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Badge } from 'builder/components/Badge'

export const Divider = styled.div`
  margin: 20px;
  height: 1px;
  border-radius: 12px;
  background: ${Colors.Neutral15};
`

export const ProfileInfo = styled.div`
  font-size: 15px;
  line-height: 18px;

  ${FontWeights.Regular}
`

export const PendingItemsContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 12px;
  margin: 8px 20px 0 20px;
`

export const PendingItemText = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption}
  ${FontWeights.Medium}
`

export const BadgePercentage = styled(Badge)`
  border-radius: 4px;
  padding: 4px;
`

export const PendingItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  &:hover {
    ${PendingItemText} {
      color: ${Colors.Neutral90};
    }

    ${BadgePercentage} {
      color: ${Colors.White};
      background-color: ${Colors.Green50};
    }
  }
`
