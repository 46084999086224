import { MultipleErrors } from '../RecommendationsErrors/MultipleErrors'
import { NoTargetLocation } from '../RecommendationsErrors/NoTargetLocation'
import { NoTargetLocationAndNoWorkExperience } from '../RecommendationsErrors/NoTargetLocationAndNoWorkExperience'
import { NoTargetRole } from '../RecommendationsErrors/NoTargetRole'
import { NoTargetRoleAndNoLocation } from '../RecommendationsErrors/NoTargetRoleAndNoLocation'
import { NoTargetRoleAndNoWorkExperience } from '../RecommendationsErrors/NoTargetRoleAndNoWorkExperience'
import { NoWorkExperience } from '../RecommendationsErrors/NoWorkExperience'
import { RecommendationsRequiredAttributes } from '../types'

export const getRecommendationsError = (missingAttributes: string[]) => {
  const { TargetRole, TargetLocation, WorkExperience, WorkExperienceWithDetails } =
    RecommendationsRequiredAttributes

  const hasTargetRole = !missingAttributes.includes(TargetRole)
  const hasTargetLocation = !missingAttributes.includes(TargetLocation)
  const hasWorkExperience =
    !missingAttributes.includes(WorkExperience) &&
    !missingAttributes.includes(WorkExperienceWithDetails)

  if (!hasTargetRole && hasTargetLocation && hasWorkExperience) return NoTargetRole
  if (hasTargetRole && !hasTargetLocation && hasWorkExperience) return NoTargetLocation
  if (hasTargetRole && hasTargetLocation && !hasWorkExperience) return NoWorkExperience
  if (!hasTargetRole && !hasTargetLocation && hasWorkExperience) return NoTargetRoleAndNoLocation
  if (!hasTargetRole && hasTargetLocation && !hasWorkExperience)
    return NoTargetRoleAndNoWorkExperience
  if (hasTargetRole && !hasTargetLocation && !hasWorkExperience)
    return NoTargetLocationAndNoWorkExperience
  if (!hasTargetLocation || !hasTargetRole || !hasWorkExperience) return MultipleErrors

  return null
}
