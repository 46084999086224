import styled from 'styled-components'
import Media from 'builder/styles/media'
import { foldableSectionMixin } from '../Section/styles'

export const SectionsListContainer = styled.div``

export const SectionsListFooter = styled.div`
  ${foldableSectionMixin}
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 24px 36px 36px;

  ${Media.Phone`
    padding: 16px 20px 36px;
  `};
`

export const AcvBanner = styled.div`
  margin-top: 8px;
`
