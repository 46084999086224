import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import {
  SliderArrowButton,
  SliderArrowButtonThemeEnum,
  SliderArrowButtonType,
} from 'builder/views/Interview/components/FeedbackViewV2/components/SliderArrowButton'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useIntersectionObserver } from 'builder/hooks/useIntersectionObserver'
import { getDefaultLogoForCompany } from 'builder/components/FindJob/utils/getDefaultLogoForCompany'
import { Skeleton } from 'builder/Skeleton/Skeleton'
import { selectors } from 'builder/modules/interview/interviewModule'
import PerformanceLogger from 'builder/services/PerformanceLogger'
import { useInterviewAnswer } from '../../hooks/useInterviewAnswer'
import { NavbarWithMenu } from '../Navbar/NavbarWithMenu/NavbarWithMenu'
import { useInterview } from '../../hooks/useInterview'
import {
  Container,
  Title,
  Logo,
  JobTitle,
  SectionContainer,
  QuestionContainer,
  SliderArrowContainer,
  Content,
  NavbarQuestionLine,
  NavBarContentContainer,
  NavbarQuestionContainer,
  InvalidFeedback,
  InvalidFeedbackLeft,
} from './styles'
import { SummaryQuestionSection } from './components/SummaryQuestionSection/SummaryQuestionSection'
import { FeedbackSection } from './components/FeedbackSection/FeedbackSection'
import { IdealAnswerSection } from './components/IdealAnswerSection/IdealAnswerSection'
import { LastSection } from './components/LastSection/LastSection'
import { Question } from './components/Question/Question'
import { CardSection } from './components/CardSection/CardSection'
import NoFeedbackImg from './no_feedback.png'
import { BtnTryAgain } from './components/BtnTryAgain/BtnTryAgain'

export const TRANSLATION_PREFIX = `builder.interview_prep_view.feedbackV2`

export const FeedbackViewV2 = () => {
  const user = useUser()
  const { i18n } = useI18n()

  const interviewId = useSelector(selectors.interviewId)
  const { data: interviewReadyData } = useInterview({ interviewId })
  const interview = interviewReadyData?.payload

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0)
  const question = interview?.questions[selectedQuestionIndex]
  const { data: answer } = useInterviewAnswer(question ? question.id : undefined)

  const navigate = useNavigate()
  const [showNavbarEllipse, setShowNavbarEllipse] = useState(true)
  const [showNavbarFixed, setShowNavbarFixed] = useState(false)
  const { isPhone, isTablet } = useMediaQueries()
  const isNonDesktop = isPhone || isTablet
  const [showQuestionInNavbar, setShowQuestionInNavbar] = useState(false)
  const rootRef = useRef<HTMLDivElement | null>(null)

  const desktopObserver = useIntersectionObserver({
    enable: !isNonDesktop && !!interview,
    rootRef,
    callback: entries => {
      entries.forEach(entry => {
        setShowQuestionInNavbar(!entry.isIntersecting)
      })
    },
  })

  const mobileObserver = useIntersectionObserver({
    enable: isNonDesktop && !!interview,
    callback: entries => {
      entries.forEach(entry => {
        setShowNavbarFixed(!entry.isIntersecting)
      })
    },
  })
  const stopPerformance = useCallback(() => {
    PerformanceLogger.finish()
  }, [])

  useEffect(() => {
    if (!interviewId) {
      stopPerformance()
      navigate('/interview-preparation/dashboard')
      return
    }

    trackInternalEvent('view_interview_report', { label: 'interview_prep' })
    return () => {
      stopPerformance()
    }
  }, [])

  const renderSideButtons = (disabledBgcolor: boolean) => {
    const renderSideButtonContent = (disabledBgcolor: boolean): React.ReactNode => {
      if (!interview) {
        return <Skeleton width={106} height={48} />
      }
      if (interview?.questions.length === 1) {
        return null
      }
      return (
        <>
          <SliderArrowButton
            theme={SliderArrowButtonThemeEnum.gray}
            type={SliderArrowButtonType.left}
            size={48}
            onClick={() => setSelectedQuestionIndex(prevIndex => prevIndex - 1)}
            disable={selectedQuestionIndex === 0}
            disableBgColor={disabledBgcolor}
          />
          <SliderArrowButton
            theme={SliderArrowButtonThemeEnum.gray}
            type={SliderArrowButtonType.right}
            size={48}
            onClick={() => setSelectedQuestionIndex(prevIndex => prevIndex + 1)}
            disable={interview.questions.length - 1 <= selectedQuestionIndex}
            disableBgColor={disabledBgcolor}
          />
        </>
      )
    }

    return <SliderArrowContainer>{renderSideButtonContent(disabledBgcolor)}</SliderArrowContainer>
  }

  const renderNavbarQuestion = ({
    position,
    visible,
    showLine,
    disabledBgcolor,
  }: {
    position: string
    visible: boolean
    showLine: boolean
    disabledBgcolor: boolean
  }) => {
    if (!question) {
      return null
    }
    return (
      <NavbarQuestionContainer $visibility={visible} $position={position}>
        {showLine && <NavbarQuestionLine />}

        <NavBarContentContainer>
          <Question
            question={question.content}
            questionNumber={selectedQuestionIndex + 1}
            questionTotal={interview.questions.length}
            enableEllipse={showNavbarEllipse}
            onClick={() => setShowNavbarEllipse(prevState => !prevState)}
            onClickOutside={() => setShowNavbarEllipse(true)}
          />

          {renderSideButtons(disabledBgcolor)}
        </NavBarContentContainer>
      </NavbarQuestionContainer>
    )
  }

  return (
    <Container ref={rootRef}>
      <NavbarWithMenu
        onCloseClick={() => navigate('/interview-preparation/dashboard')}
        onDashboardClick={() => navigate('/interview-preparation/dashboard')}
        onHover={hover => showQuestionInNavbar && setShowNavbarEllipse(!hover)}
      >
        {showQuestionInNavbar &&
          renderNavbarQuestion({
            position: 'sticky',
            visible: true,
            showLine: false,
            disabledBgcolor: true,
          })}
      </NavbarWithMenu>
      {isNonDesktop &&
        renderNavbarQuestion({
          position: 'fixed',
          visible: showNavbarFixed,
          showLine: true,
          disabledBgcolor: true,
        })}

      {interview ? (
        <Title>
          {i18n.t(`${TRANSLATION_PREFIX}.phrase`, { name: user?.firstName || '' })}&nbsp;
          <Logo>
            <img src={interview.logo_url || getDefaultLogoForCompany()} alt="logo" />
          </Logo>
          <JobTitle>
            {interview?.job_title}&nbsp;
            {interview?.real_company &&
              interview?.company_name &&
              `${i18n.t(`${TRANSLATION_PREFIX}.at_preposition`)} ${interview?.company_name} `}
            {i18n.t(`${TRANSLATION_PREFIX}.interview`)}
          </JobTitle>
        </Title>
      ) : (
        <Title>
          <Skeleton width={674} height={34} />
          <Skeleton width={779} height={34} style={{ marginTop: '5px', marginBottom: '66px' }} />
        </Title>
      )}
      <Content>
        <SectionContainer ref={desktopObserver.targetRef}>
          <div ref={mobileObserver.targetRef}>
            <QuestionContainer>
              <Question
                question={question?.content}
                questionNumber={selectedQuestionIndex + 1}
                questionTotal={interview?.questions.length}
                enableEllipse={false}
              />
            </QuestionContainer>
            {renderSideButtons(false)}
          </div>
        </SectionContainer>

        {answer?.ai_speech_job?.status === 'failed' || answer?.audio === null ? (
          <>
            <InvalidFeedback>
              <InvalidFeedbackLeft>
                <p
                  dangerouslySetInnerHTML={{
                    __html: i18n.t(`${TRANSLATION_PREFIX}.no_feedback`),
                  }}
                />
                {interviewId && (
                  <div>
                    <BtnTryAgain interviewId={interviewId} />
                  </div>
                )}
              </InvalidFeedbackLeft>
              <img src={NoFeedbackImg} alt="no feedback" />
            </InvalidFeedback>
          </>
        ) : (
          <>
            <CardSection feedback={answer?.feedback} />

            <SummaryQuestionSection answer={answer} interview={interview} />

            <FeedbackSection
              answer={answer}
              interview={interview}
              question={question}
              selectedQuestionIndex={selectedQuestionIndex}
            />
            <IdealAnswerSection feedback={answer?.feedback} interview={interview} enablePaywall />
            {false && (
              <LastSection
                interviewId={interviewId}
                question={question?.content}
                questionNumber={selectedQuestionIndex + 1}
                questionTotal={interview?.questions.length}
              />
            )}
          </>
        )}
      </Content>
    </Container>
  )
}
