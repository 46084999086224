import * as Cookies from 'js-cookie'

const allAdditionalTypes = ['analyticalCookies', 'marketingCookies']

const pushEventToDataLayer = (data: unknown) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

const consentModeConfig = (
  acceptedTypes: string[],
  consentTypeToCookieMapping: Record<string, string>,
) => {
  function gtmConsentStateFor(cookieType: string) {
    const COOKIE_CONSENT_GRANTED = 'granted'
    const COOKIE_CONSENT_DENIED = 'denied'

    return acceptedTypes.indexOf(cookieType) >= 0 ? COOKIE_CONSENT_GRANTED : COOKIE_CONSENT_DENIED
  }

  const updatedConsent = { ...consentTypeToCookieMapping }

  for (const cookieType in consentTypeToCookieMapping) {
    updatedConsent[cookieType] = gtmConsentStateFor(consentTypeToCookieMapping[cookieType])
  }

  return updatedConsent
}

const addConsentStateUpdateToDatalayer = (acceptedTypes: string[], strictMode: boolean) => {
  if (strictMode) {
    pushEventToDataLayer([
      'consent',
      'update',
      consentModeConfig(acceptedTypes, {
        ad_storage: 'essentialCookies',
        ad_user_data: 'essentialCookies',
        ad_personalization: 'marketingCookies',
        analytics_storage: 'analyticalCookies',
      }),
    ])
  } else {
    pushEventToDataLayer([
      'consent',
      'update',
      consentModeConfig(acceptedTypes, {
        ad_storage: 'essentialCookies',
        ad_user_data: 'essentialCookies',
        ad_personalization: 'essentialCookies',
        analytics_storage: 'essentialCookies',
      }),
    ])
  }
}

const updateUETConsent = (acceptedTypes: string[]) => {
  const uetConsentMapping = {
    ad_storage: 'marketingCookies',
    analytics_storage: 'analyticalCookies',
    personalization_storage: 'marketingCookies',
    functional_storage: 'essentialCookies',
    security_storage: 'essentialCookies',
  }

  const uetConsentConfig = {} as { [x: string]: string }
  for (const [uetKey, cookieType] of Object.entries(uetConsentMapping)) {
    uetConsentConfig[uetKey] = acceptedTypes.includes(cookieType) ? 'granted' : 'denied'
  }

  window.uetq = window.uetq || []
  window.uetq.push('consent', 'update', uetConsentConfig)
}

const acceptCookies = (types: string[], strictMode: boolean) => {
  const acceptedTypes = ['essentialCookies', ...types]
  if (!Cookies.get('cookie_consent')) {
    Cookies.set('cookie_consent', acceptedTypes, { expires: 365 })
  }
  if (types && types.length) {
    if (strictMode) {
      types.forEach(type => {
        if (allAdditionalTypes.indexOf(type) >= 0) {
          pushEventToDataLayer({ event: type + 'Accepted' })
        }
      })
    } else {
      pushEventToDataLayer({ event: 'AcceptCookies' })
    }
  }
  addConsentStateUpdateToDatalayer(acceptedTypes, strictMode)
  updateUETConsent(acceptedTypes)
}

export {
  consentModeConfig,
  addConsentStateUpdateToDatalayer,
  updateUETConsent,
  pushEventToDataLayer,
  acceptCookies,
  allAdditionalTypes,
}
