import styled, { css, keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

const fadeIn = keyframes`
  from {
    transform: translateY(-12px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

export const Container = styled.div<{
  isPageScrolled: boolean
  isImproveResumePanelOpen: boolean
  isBannerVisible: boolean
}>`
  transition: all 300ms ease-in-out;
  padding: 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${props =>
    props.isBannerVisible &&
    css`
      padding-top: 0;
    `}

  ${props =>
    props.isImproveResumePanelOpen &&
    css`
      animation: ${fadeIn} 0.3s ease-in;
    `}

  ${props =>
    props.isPageScrolled &&
    css`
      padding: 20px 24px;
      box-shadow: 0px 2px 4px -1px rgba(15, 56, 113, 0.08),
        0px 8px 20px -4px rgba(15, 56, 113, 0.12);
    `}
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Header = styled.p`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: ${Colors.Neutral50};
`
