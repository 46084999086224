import { CareerProfileCardsType } from 'builder/modules/careerProfile'
import { ResumeSectionId, SectionNames } from 'builder/modules/resumeEditor'

export const translationKey = 'builder.career_profile.editor'

export const resumeToProfileSectionNamesMap: Partial<
  Record<ResumeSectionId, CareerProfileCardsType>
> = {
  [SectionNames.workExperiences]: 'workExperiencesAttributes',
  [SectionNames.educations]: 'educationsAttributes',
  [SectionNames.skills]: 'skillsAttributes',
  [SectionNames.courses]: 'coursesAttributes',
}
