import { Optional, UserDocumentTemplateId } from 'packages/types'
import { TemplateName } from 'builder/components/Helper/constants'
import { ResumeSectionId, SectionNames } from 'builder/modules/resumeEditor'

type ConfigurableSection = ResumeSectionId | 'details'

type SectionConfig = {
  [sectionKey in ConfigurableSection]?: {
    hiddenFields?: string[]
    isHidden?: boolean
  }
}

type TemplateConfig = {
  [template in UserDocumentTemplateId]?: SectionConfig | null
}

const customTemplatesConfig: TemplateConfig = {
  [TemplateName.Prague]: {
    [SectionNames.details]: {
      hiddenFields: ['drivingLicense', 'nationality', 'birthPlace', 'birthDate'],
    },
    [SectionNames.languages]: {
      isHidden: true,
      hiddenFields: ['level'],
    },
    [SectionNames.socialProfiles]: {
      isHidden: true,
    },
    [SectionNames.references]: {
      isHidden: true,
    },
    [SectionNames.hobbies]: {
      isHidden: true,
    },
    [SectionNames.activities]: {
      hiddenFields: ['description', 'city'],
    },
    [SectionNames.skills]: {
      hiddenFields: ['level'],
    },
    [SectionNames.educations]: {
      hiddenFields: ['description'],
    },
    [SectionNames.internships]: {
      hiddenFields: ['description', 'city'],
    },
  },
  [TemplateName.Athens]: {
    [SectionNames.details]: {
      hiddenFields: ['drivingLicense', 'nationality', 'birthPlace', 'birthDate'],
    },
    [SectionNames.languages]: {
      isHidden: true,
      hiddenFields: ['level'],
    },
    [SectionNames.socialProfiles]: {
      isHidden: true,
    },
    [SectionNames.references]: {
      isHidden: true,
    },
    [SectionNames.hobbies]: {
      isHidden: true,
    },
    [SectionNames.activities]: {
      hiddenFields: ['description'],
    },
    [SectionNames.skills]: {
      hiddenFields: ['level'],
    },
    [SectionNames.courses]: {
      hiddenFields: ['dates', 'institution'],
    },
    [SectionNames.educations]: {
      hiddenFields: ['description'],
    },
    [SectionNames.internships]: {
      hiddenFields: ['description'],
    },
  },
  [TemplateName.Brussels]: {
    [SectionNames.languages]: {
      hiddenFields: ['level'],
    },
    [SectionNames.socialProfiles]: {
      isHidden: true,
    },
    [SectionNames.skills]: {
      hiddenFields: ['level'],
    },
  },
  [TemplateName.Shanghai]: {
    [SectionNames.details]: {
      hiddenFields: ['drivingLicense', 'nationality', 'birthPlace', 'birthDate'],
    },
    [SectionNames.languages]: {
      isHidden: true,
      hiddenFields: ['level'],
    },
    [SectionNames.socialProfiles]: {
      isHidden: true,
    },
    [SectionNames.references]: {
      isHidden: true,
    },
    [SectionNames.hobbies]: {
      isHidden: true,
    },
    [SectionNames.activities]: {
      hiddenFields: ['description', 'city'],
    },
    [SectionNames.skills]: {
      hiddenFields: ['level'],
    },
    [SectionNames.educations]: {
      hiddenFields: ['description'],
    },
  },
}

export const displayFieldPerTemplateConfig = (
  template: Optional<UserDocumentTemplateId>,
  section: ConfigurableSection,
  field: string,
) => {
  const templateConfig = template && customTemplatesConfig[template]
  if (templateConfig && typeof section === 'string') {
    const sectionConfig = templateConfig[section]
    if (sectionConfig) {
      const hiddenFields = sectionConfig.hiddenFields
      return hiddenFields ? !hiddenFields.includes(field) : true
    }
  }
  return true
}

export const displaySectionPerTemplateConfig = (
  template: UserDocumentTemplateId,
  section: ConfigurableSection,
) => {
  const templateConfig = customTemplatesConfig[template]
  if (templateConfig && typeof section === 'string') {
    const sectionConfig = templateConfig[section]
    return sectionConfig ? !sectionConfig.isHidden : true
  }
  return true
}

const sectionTitleMapping: Record<string, Record<string, string>> = {
  [TemplateName.Prague]: {
    [SectionNames.skills]: 'builder.resume_editor.technical_proficiencies',
    [SectionNames.workExperiences]: 'builder.resume_editor.career_experience',
    [SectionNames.internships]: 'builder.resume_editor.awards',
    [SectionNames.courses]: 'builder.resume_editor.professional_training',
    [SectionNames.activities]: 'builder.resume_editor.licenses_and_certifications',
  },
  [TemplateName.Athens]: {
    [SectionNames.skills]: 'builder.resume_editor.area_of_expertise',
    [SectionNames.workExperiences]: 'builder.resume_editor.professional_experience',
    [SectionNames.internships]: 'builder.resume_editor.additional_experience',
    [SectionNames.courses]: 'builder.resume_editor.professional_training',
    [SectionNames.activities]: 'builder.resume_editor.licenses_and_certifications',
  },
  [TemplateName.Shanghai]: {
    [SectionNames.skills]: 'builder.resume_editor.area_of_expertise',
    [SectionNames.workExperiences]: 'builder.resume_editor.career_experience',
    [SectionNames.internships]: 'builder.resume_editor.internships_section.title',
    [SectionNames.courses]: 'builder.resume_editor.affiliations',
    [SectionNames.activities]: 'builder.resume_editor.licenses_and_certifications',
    [SectionNames.accomplishments]: 'builder.resume_editor.accomplishments_section.title',
  },
  default: {
    [SectionNames.skills]: 'builder.resume_editor.skills_section.title',
    [SectionNames.workExperiences]: 'builder.resume_editor.work_experience_section.title',
    [SectionNames.socialProfiles]: 'builder.resume_editor.links_section.title',
    [SectionNames.internships]: 'builder.resume_editor.internships_section.title',
    [SectionNames.courses]: 'builder.resume_editor.courses_section.title',
    [SectionNames.activities]: 'builder.resume_editor.activities_section.title',
    [SectionNames.educations]: 'builder.resume_editor.educations_section.title',
    [SectionNames.hobbies]: 'builder.resume_editor.hobbies_section.title',
    [SectionNames.languages]: 'builder.resume_editor.languages_section.title',
    [SectionNames.references]: 'builder.resume_editor.references_section.title',
    [SectionNames.accomplishments]: 'builder.resume_editor.accomplishments_section.title',
  },
}

export const sectionTitleTranslation = (
  template: UserDocumentTemplateId | 'default',
  translate: (translationKey: string) => string,
) => {
  return (SectionName: SectionNames) => {
    const translationKey =
      (sectionTitleMapping[template] && sectionTitleMapping[template][SectionName]) ||
      (sectionTitleMapping.default && sectionTitleMapping.default[SectionName])

    return translate(translationKey)
  }
}
