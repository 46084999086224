import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Step, actions, selectors } from 'builder/modules/dashboard'
import {
  selectors as profileSelectors,
  actions as profileActions,
} from 'builder/modules/careerProfile'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import Spinner from 'builder/components/Spinner'

import { FetchStatuses } from 'builder/modules/constants'
import { Onboarding } from '../Onboarding/Onboarding'

import { StepsProgress } from './StepsProgress/StepsProgress'
import { SuccessState } from './SuccessState/SuccessState'

import { Container, StepsContainer } from './styles'
import { MergedStepData, stepperDefaultState } from './types'
import { useOnboardingSteps } from './useOnboardingSteps'

interface IProps {
  showStepper?: boolean
}

export const Stepper = ({ showStepper }: IProps) => {
  const [activeStep, setActiveStep] = useState(stepperDefaultState)
  const [mappedSteps, setMappedSteps] = useState<MergedStepData[]>([])
  const dispatch = useDispatch()
  const stepsData = useTypedSelector(selectors.stepsData)
  const stepsAreLoaded = useTypedSelector(selectors.isLoaded)
  const stepperSuccessStateSeen = useTypedSelector(selectors.stepperSuccessStateSeen)
  const features = useFeaturesConfig()
  const [runOnboarding, setRunOnboarding] = useState(false)
  const [showCompletedSteps, setShowCompletedSteps] = useState(false)
  const fetchIsAutoApplyCompleteStatus = useTypedSelector(
    profileSelectors.fetchIsAutoApplyCompleteStatus,
  )

  const handleActiveStep = (step: Step) => {
    setActiveStep({ ...stepperDefaultState, [step]: true })
  }

  const { steps, getCompletedStepsCount } = useOnboardingSteps({
    stepsData,
    activeStep,
    handleActiveStep,
    showCompletedSteps,
    features,
    setMappedSteps,
    stepsAreLoaded,
    fetchIsAutoApplyCompleteStatus,
  })

  useEffect(() => {
    dispatch(actions.fetchStepsDataRequest())
    dispatch(actions.fetchStepperSuccessStateRequest())
    dispatch(profileActions.fetchIsAutoApplyComplete())

    const timer = setTimeout(() => {
      setRunOnboarding(true)
    }, 900)

    return () => {
      clearTimeout(timer)
      setMappedSteps([])
      dispatch(actions.setStepsLoadedStatus(false))
    }
  }, [dispatch])

  const allStepsAreCompleted =
    !!mappedSteps.length && getCompletedStepsCount(mappedSteps) === mappedSteps.length

  return (
    <Container>
      {!stepsAreLoaded || fetchIsAutoApplyCompleteStatus === FetchStatuses.loading ? (
        <Spinner />
      ) : (
        <>
          <StepsProgress
            showSuccessState={allStepsAreCompleted && !stepperSuccessStateSeen}
            completedSteps={getCompletedStepsCount(mappedSteps)}
            totalSteps={mappedSteps.length}
            showProgress={!!showStepper}
            showCompletedSteps={showCompletedSteps}
            setShowCompletedSteps={setShowCompletedSteps}
          />
          {allStepsAreCompleted && !stepperSuccessStateSeen && <SuccessState />}
          {showStepper && !allStepsAreCompleted && (
            <>
              {runOnboarding && <Onboarding />}
              <StepsContainer id="first-step-onboarding">{steps}</StepsContainer>
            </>
          )}
        </>
      )}
    </Container>
  )
}
