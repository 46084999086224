import React from 'react'
import { TooltipV2 } from 'builder/components/Tooltip'
import * as Styles from '../styles'

type Ref = React.ForwardedRef<HTMLInputElement>
type Props = {
  value?: string
  company?: string
  errorMessage?: string
} & React.HTMLAttributes<HTMLInputElement>

export const LinkField = React.forwardRef(
  ({ value, company, errorMessage, ...rest }: Props, ref: Ref) => {
    return (
      <Styles.LinkFieldContainer>
        <Styles.CompanyName>{company}</Styles.CompanyName>
        <Styles.InputContainer>
          <Styles.Input ref={ref} {...rest} />
          {errorMessage && (
            <TooltipV2 content={errorMessage} multiline={false}>
              <Styles.IconContainer>
                <Styles.ErrorIcon />
              </Styles.IconContainer>
            </TooltipV2>
          )}
          {value && !errorMessage && (
            <Styles.IconContainer>
              <Styles.SuccessIcon />
            </Styles.IconContainer>
          )}
        </Styles.InputContainer>
      </Styles.LinkFieldContainer>
    )
  },
)
