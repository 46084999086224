import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'
import Sizes from 'builder/styles/sizes'
import Media from 'builder/styles/media'

export const Container = styled.div`
  position: relative;
  display: inline-flex;
  gap: 4px;
  ${Media.Tablet`
    margin-bottom: ${Sizes.L};
  `}
  ${Media.Phone`
    display: none;
  `}
`

export const MenuButtonContainer = styled.div`
  gap: 4px;

  width: fit-content;
  height: fit-content;
  border-radius: 32px;
  color: ${Colors.Neutral80};
  padding: 8px;

  cursor: pointer;

  &:hover {
    background-color: ${Colors.Blue10};
    color: ${Colors.Blue50};
  }

  &:active {
    background-color: ${Colors.Blue20};
    color: ${Colors.Blue60};
  }
`

export const DashboardButton = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 8px ${Sizes.S};
  cursor: pointer;
  border-radius: 32px;

  &:hover {
    background-color: ${Colors.Blue10};
    color: ${Colors.Blue50};
  }

  &:active {
    background-color: ${Colors.Blue20};
    color: ${Colors.Blue60};
  }
`

export const CloseContainer = styled.div`
  position: absolute;
  right: 0;
  display: none;
  border-radius: 100%;
  cursor: pointer;
  width: 40px;
  height: 40px;

  ${Media.Tablet`
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  &:hover {
    background-color: ${Colors.Blue10};
    color: ${Colors.Blue50};
  }
`

export const CloseIcon = styled(Icon24.Close)`
  min-width: 40px;
  min-height: 40px;
  padding: 8px;
  border-radius: 50%;
  background-color: ${Colors.Neutral15};
  cursor: pointer;
`
