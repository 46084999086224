import { SyntheticEvent, useEffect, useState } from 'react'
import JobTracker from 'builder/components/FeatureIcon/icons/jobTracker/enabled.svg'
import { Wrapper } from './styles'

interface Props {
  src: string | null | undefined
}
export const Logo = ({ src }: Props) => {
  const [showDefault, setShowDefault] = useState(true)

  useEffect(() => {
    setShowDefault(true)
  }, [src])

  const onLoadImage = (e: SyntheticEvent<HTMLImageElement>) => {
    if (Math.abs(e.currentTarget.naturalWidth / e.currentTarget.naturalHeight) > 1.5) {
      setShowDefault(true)
    } else {
      setShowDefault(false)
    }
  }

  const onErrorImage = () => setShowDefault(true)

  return (
    <Wrapper>
      {src && (
        <img
          src={src}
          onLoad={onLoadImage}
          onError={onErrorImage}
          style={{ display: showDefault ? 'none' : 'block' }}
        />
      )}

      {showDefault && <img src={JobTracker} style={{ height: '40px', width: '40px' }} />}
    </Wrapper>
  )
}
