import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${Colors.Neutral5};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1551;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
`
export const WebcamContainer = styled.div`
  position: fixed;
  right: 20px;
  width: 278px;
  height: 278px;
  z-index: 1555;
  ${Media.Tablet`
    width: 245px;
    height: 245px;
  `}

  ${Media.Phone`
    width: 187px;
    height: 187px;
  `}
`

export const TopContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${Media.Tablet`
    grid-template-columns: 1fr 1fr;
  `}
`

export const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const MiddleContainer = styled.div`
  height: 100%;
  display: flex;
  overflow: auto;
  justify-content: center;
  align-items: center;
`
export const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`
export const Text = styled.div`
  ${Typography.L};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  text-align: center;
  margin-bottom: 20px;
`

export const Note = styled.div<{ $display: string }>`
  padding-bottom: 16px;
  text-align: center;
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};

  display: ${({ $display }) => $display};
`

export const StartInterviewButton = styled.div`
  display: flex;
  width: 151px;
  height: 50px;
  padding: ${Sizes.XS} ${Sizes.S};
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  color: ${Colors.White};
  border-radius: ${Sizes['3XL']};
  background: ${Colors.Blue50};
  cursor: pointer;
`
