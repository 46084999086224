import Button, { ButtonSize } from 'builder/components/Button'
import { useI18n } from 'builder/hooks/useI18n'
import { Container, Title, SubTitle, ImageWrapper, DetailsWrapper, ButtonWrapper } from '../styles'
import { LayoutType } from '../types'

interface MissingInfoLayoutProps {
  layout?: LayoutType
  translationKey: string
  Image: React.ReactNode
  onClick: () => void
}

export const MissingInfoLayout: React.FC<MissingInfoLayoutProps> = ({
  layout,
  translationKey,
  Image,
  onClick,
}) => {
  const { i18n } = useI18n()
  const i18nBaseTranslationKey = 'builder.job_search.recommendations.missing_profile_information'

  return (
    <Container layout={layout}>
      <ImageWrapper layout={layout}>{Image}</ImageWrapper>
      <DetailsWrapper layout={layout}>
        <Title>{i18n.t(`${i18nBaseTranslationKey}.${translationKey}.title`)}</Title>
        <SubTitle>{i18n.t(`${i18nBaseTranslationKey}.${translationKey}.subtitle`)}</SubTitle>
        <ButtonWrapper layout={layout}>
          <Button size={ButtonSize.small} onClick={onClick}>
            {i18n.t(`${i18nBaseTranslationKey}.${translationKey}.button`)}
          </Button>
        </ButtonWrapper>
      </DetailsWrapper>
    </Container>
  )
}
