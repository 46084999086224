import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: #000000ba;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`

export const RootModalContainer = styled.div`
  background-color: ${Colors.White};
  border-radius: 8px;
  display: flex;
  width: fit-content;
  position: relative;
  flex-direction: column;
  max-height: 100vh;
  max-width: 552px;
  overflow: auto;

  ${Media.Tablet`
    width: 100%;
  `};

  ${Media.Phone`
    width: 95%;
  `};
`

export const ModalSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 498px;
  height: 100%;
  padding: 32px 12px;
  margin: auto;

  ${Media.Phone`
    width: 100%;
    padding: 24px;
  `};
`

export const CloseIcon = styled(Icon24.Close)`
  display: block;
  color: ${Colors.Neutral50};
  transition: color 0.3s;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const Close = styled.div`
  display: flex;
  justify-self: flex-end;
  right: 0;
  height: fit-content;
  width: fit-content;
  margin: 32px 32px 0 32px;
  background-color: ${Colors.Neutral5};
  border-radius: 32px;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${Colors.Blue10};
  }

  ${Media.Tablet`
    margin: 20px;
  `};
`

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

export const GetStartedHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`

export const GetStartedTitle = styled.h2`
  ${Typography.S};
  ${FontWeights.DemiBold}
  color: ${Colors.Neutral90};
  display: flex;
  flex-direction: column;

  ${Media.Phone` display: block; width: 80%; `};
`

export const GetStartedSubTitle = styled.span`
  ${Typography.S};
  ${FontWeights.DemiBold}
  color: ${Colors.Indigo50};
  ${Media.Phone` margin-left: 4px; `}
`

export const GetStartedDescription = styled.p`
  color: ${Colors.Neutral50};
  ${Typography.Body}
  margin-top: ${Sizes['3XS']};
`

export const GetStartedFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;

  ${Media.Tablet`
    margin-top: 16px;
  `};
`

export const GetStartedFeatureTitle = styled.h3`
  color: ${Colors.Neutral50};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1.8px;
  text-transform: uppercase;

  ${Media.Tablet`
    font-size: 16px;
  `};

  ${Media.Phone`
    font-size: 12px;
    line-height: 14px;
  `};
`

export const GetStartedFeatures = styled.ol`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 0;
  counter-reset: li;
`

export const GetStartedFeature = styled.li`
  color: ${Colors.Neutral80};
  ${Typography.Body}
  align-items: center;
  display: flex;

  &::before {
    content: counter(li);
    counter-increment: li;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: ${Colors.Indigo10};
    color: ${Colors.Indigo50};
    ${Typography.Body}
  }
`

export const GetStartedTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CTAContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1.5em;
  gap: 0.5em;

  ${Media.Tablet`
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
    text-align: end;
    gap: 1em;
  `};

  ${Media.Phone` margin-top: 16px;`};
`

export const CTAPrimaryContainer = styled.div`
  display: flex;
  gap: ${Sizes.S};
  align-items: center;

  ${Media.Tablet`
    flex-direction: row-reverse;
  `}
`

export const CTAPrimary = styled.button`
  border-radius: 4px;
  border: none;
  background: ${Colors.Blue50};
  color: ${Colors.White};
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  display: inline-flex;
  padding: ${Sizes.XS} ${Sizes.M};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: ${Colors.Blue60};
  }

  &:active {
    background: ${Colors.Blue70};
  }

  &:disabled {
    color: ${Colors.Neutral50};
    background-color: transparent;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }

  ${Media.Phone`
    font-size: 16px;
    ${FontWeights.Medium}
  `}
`
