import styled, { css } from 'styled-components'
import { WithFoldableSections, foldableSectionMixin } from 'builder/components/Section'

export const SectionContainer = styled.div<WithFoldableSections>(props => {
  return css`
    position: relative;

    ${props.isFoldableSectionsEnabled
      ? foldableSectionMixin
      : css`
          margin-bottom: 40px;
        `}
  `
})

export const SectionContent = styled.div``
