import { memo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import Icon from 'builder/components/Icon'
import { Job } from 'builder/components/FindJob/JobSearchResult/types'
import RecommendedJobCard from '../RecommendedJobCard'
import {
  Container,
  SwiperContainer,
  SliderContent,
  NavigationButtons,
  ToggleRightButton,
  ToggleLeftButton,
} from './styles'
import {
  handleOnInit,
  handleOnReachEnd,
  handleOnSlideChange,
  handleOnReachBeginning,
} from './utils'

interface Props {
  cards: Job[]
  isSliderActive: boolean
  handleClick: (job: Job) => void
  appendSlides?: () => React.ReactNode
  fromDashboard?: boolean
}

SwiperCore.use([Navigation])

const JobSlider = ({
  cards,
  isSliderActive,
  handleClick,
  appendSlides,
  fromDashboard = false,
}: Props) => {
  return (
    <Container data-testid="job-swiper">
      {cards.length && (
        <>
          <NavigationButtons>
            <ToggleRightButton data-testid="leftButton" className="job-prev-button">
              <Icon.Chevron />
            </ToggleRightButton>
            <ToggleLeftButton data-testid="rightButton" className="job-next-button">
              <Icon.Chevron />
            </ToggleLeftButton>
          </NavigationButtons>

          <SwiperContainer>
            <SliderContent>
              <Swiper
                grabCursor
                mousewheel
                slidesPerView="auto"
                initialSlide={0}
                spaceBetween={16}
                navigation={{
                  prevEl: '.job-prev-button',
                  nextEl: '.job-next-button',
                }}
                onInit={handleOnInit}
                onReachEnd={() => handleOnReachEnd}
                onReachBeginning={handleOnReachBeginning}
                onSlideChange={swiper => handleOnSlideChange(swiper)}
              >
                {appendSlides?.()}
                {cards?.map((item, index) => (
                  <SwiperSlide key={`template-${index}`} style={{ width: '100%' }}>
                    <RecommendedJobCard
                      fromDashboard={fromDashboard}
                      key={index}
                      card={item}
                      handleClick={() => handleClick(item)}
                      isSliderActive={isSliderActive}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </SliderContent>
          </SwiperContainer>
        </>
      )}
    </Container>
  )
}

export default memo(JobSlider)
