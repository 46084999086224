import { baseClient } from 'builder/modules/apiClient'
import {
  Job,
  ResultStats,
  SearchApiResponse,
  SearchQuery,
  RecommendJobsApiResponse,
} from './JobSearchResult/types'
import { LocationResponse, PARAM_ONLY_AUTO_APPLY_JOBS, PARAM_TIME } from './SearchBar/constants'
import { SearchSuggestionType } from './AutoSuggestField/types'
import { Filters } from './types'

type PerformSearch = {
  jobs: Job[]
  stats: ResultStats
  correctedTerm: string | null
}

export const performApiSearch = async (params: SearchQuery): Promise<PerformSearch> => {
  const result = await baseClient.get<SearchApiResponse>('jobs/v1/search', { params })

  const { jobs, corrected_term: correctedTerm, ...stats } = result.data

  return {
    jobs,
    stats,
    correctedTerm,
  }
}

export const performSimilarJobsApi = async (
  params: Omit<SearchQuery, 'correct_term' | 'view'>,
): Promise<Omit<PerformSearch, 'correctedTerm'>> => {
  const result = await baseClient.get<Omit<SearchApiResponse, 'corrected_term'>>(
    'jobs/v1/search/similar-jobs',
    { params },
  )

  const { jobs, ...stats } = result.data

  return {
    jobs,
    stats,
  }
}

export const fetchRecommendations = async (
  page: number,
  params?: Filters,
): Promise<Omit<PerformSearch, 'correctedTerm'>> => {
  const url = `career/v1/recommended-jobs`

  const filters = {
    [PARAM_TIME]: params?.postedWithinDays,
    [PARAM_ONLY_AUTO_APPLY_JOBS]: params?.onlyAutoApplyJobs || undefined,
  }
  const result = await baseClient.get<RecommendJobsApiResponse>(url, {
    params: { ...filters, page },
  })

  const { jobs, ...stats } = result.data

  return {
    jobs,
    stats,
  }
}

export const getCurrentPosition = (): Promise<GeolocationPosition> =>
  new Promise((resolve, reject) => {
    const rejectionCallBackId = setTimeout(
      () => reject(new Error('Timeout on getCurrentPosition')),
      5000,
    )
    navigator.geolocation.getCurrentPosition(
      r => {
        clearInterval(rejectionCallBackId)
        resolve(r)
      },
      reject,
      { timeout: 5000 },
    )
  })

export const getLocationFromServer = async () => {
  const geoLocation = await getCurrentPosition()
  let response
  if (geoLocation?.coords) {
    response = await baseClient.get<LocationResponse>(`/jobs/v1/search/location`, {
      params: { lat: geoLocation?.coords?.latitude, lng: geoLocation?.coords?.longitude },
    })
    const state = response.data
    const suggestionObj: SearchSuggestionType = {
      value: String(state.id),
      text: state.name,
      formatted: state.formatted_name,
      locationCategory: state.type,
      locationType: 'near_me',
      lat: String(geoLocation?.coords?.latitude),
      lng: String(geoLocation?.coords?.longitude),
    }
    return suggestionObj
  }
}

export function generateQueryString(params: Record<string, any>) {
  const urlParams = new URLSearchParams()
  for (const key in params) {
    if (params[key] !== undefined && params[key] !== null) {
      urlParams.append(key, params[key].toString())
    }
  }

  return urlParams.toString()
}

export function hasHTMLContent(body: string): boolean {
  const containsHTML = /<\/?[a-z][\s\S]*>/i.test(body)

  return containsHTML
}
