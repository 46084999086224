import { useCallback } from 'react'
import { GetSuggestionValue, RenderSuggestion, RenderSuggestionsContainer } from 'react-autosuggest'
import {
  AsyncAutosuggest,
  AsyncAutosuggestProps,
  createSuggestionsApiFetcher,
} from 'builder/components/AsyncAutosuggest'
import { FetcherOptions } from '../AsyncAutosuggest/utils'
import { Menu, MenuItem, MenuItemLogo } from './styles'

export type CompanySuggestion = {
  name: string
  company: string
  logo?: string
}

// Make "fetchItems" optional (CompanyAutosuggest has its own default implementation)
type Props = Omit<AsyncAutosuggestProps<CompanySuggestion>, 'fetchItems'> & {
  fetchItemOptions?: FetcherOptions
  fetchItems?: (value: string) => Promise<CompanySuggestion[]>
}

export const CompanyAutosuggest = (props: Props) => {
  const renderSuggestion: RenderSuggestion<CompanySuggestion> = useCallback(
    (suggestion, state) => (
      <MenuItem isHighlighted={state.isHighlighted}>
        <MenuItemLogo size={28} src={suggestion.logo} alt={suggestion.company} />
        {suggestion.company}
      </MenuItem>
    ),
    [],
  )

  const renderSuggestionsContainer: RenderSuggestionsContainer = useCallback(
    ({ containerProps, children }) => (
      <Menu {...containerProps} isEmpty={!children}>
        {children}
      </Menu>
    ),
    [],
  )

  const getSuggestionValue: GetSuggestionValue<CompanySuggestion> = useCallback(
    suggestion => suggestion.company,
    [],
  )

  return (
    <AsyncAutosuggest<CompanySuggestion>
      name="company"
      fetchItems={createSuggestionsApiFetcher('company', props.fetchItemOptions)}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      getSuggestionValue={getSuggestionValue}
      {...props}
    />
  )
}
