import { useCallback } from 'react'
import { baseClient } from 'builder/modules/apiClient'
import { InterviewAnswer, InterviewQuestion } from 'builder/modules/interview/types'
import { requestPolling } from 'builder/views/Interview/utils/requestPolling'

// http://localhost:8080/api/interview_prep/interviews/102/questions/179
const requestFollowUpQuestions = async (
  interviewId: number,
  mainQuestionId: number,
  abortControl: AbortController,
) => {
  const response = await baseClient.get<{
    main_question: InterviewQuestion
    follow_up_questions: InterviewQuestion[]
  }>(
    // eslint-disable-next-line max-len
    `/interview_prep/interviews/${interviewId}/questions/${mainQuestionId}/follow_up_questions`,
    { signal: abortControl.signal },
  )
  return response.data
}

const requestAnswer = async (questionId: number, abortControl: AbortController) => {
  const response = await baseClient.get<InterviewAnswer>(
    `/interview_prep/questions/${questionId}/answer`,
    { signal: abortControl.signal },
  )
  return response.data
}

export const useProcessFollowUpQuestions = () => {
  const processNextQuestion = useCallback(
    async ({
      interviewId,
      mainQuestionId,
      questionId,
      expectedFollowUpQuestionCount,
    }: {
      interviewId: number
      mainQuestionId: number
      questionId: number
      expectedFollowUpQuestionCount: number
    }): Promise<InterviewQuestion | undefined> => {
      // wait the answer to have the ai_speech_job resolved
      const answer = await requestPolling({
        timeout: 60000,
        requestFunction: async (abortControl: AbortController) => {
          const response = await requestAnswer(questionId, abortControl)

          return {
            polling: response.ai_speech_job?.status === 'pending',
            data: response,
          }
        },
      })

      if (answer.ai_speech_job?.status === 'success') {
        const followUpQuestion = await requestPolling({
          timeout: 30000,
          requestFunction: async (abortControl: AbortController) => {
            const response = await requestFollowUpQuestions(
              interviewId,
              mainQuestionId,
              abortControl,
            )

            const followUpQuestion = response.follow_up_questions.sort((a, b) => a.id - b.id)

            return {
              polling:
                !response.main_question.conversation_end &&
                expectedFollowUpQuestionCount !== response.follow_up_questions.length,
              data: followUpQuestion[response.follow_up_questions.length - 1],
            }
          },
        })
        return followUpQuestion
      }

      throw new Error('There was an error in the STT service for this answer')
    },
    [],
  )

  return {
    processNextQuestion,
  }
}
