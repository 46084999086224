import { UserDocumentTemplateId } from 'packages/types'
import { i18n as I18n } from 'builder/utils/i18n'
import { SectionNames } from 'builder/modules/resumeEditor'
import Card from 'builder/components/Card'
import { sectionTitleTranslation } from '../DocumentEditor/ATSResumeEditor/builderSectionsConfig'
import { TemplateName } from '../Helper/constants'
import { SectionData } from '../Section/types'
import { WorkExperienceKeywordsToggler } from '../AiFeatures/Keywords'
import { SkillsSuggestionsToggler } from './components/SkillsSuggestionsToggler/SkillsSuggestionsToggler'

export default function (template: UserDocumentTemplateId): SectionData[] {
  const sectionTitle = sectionTitleTranslation(template, I18n.t)
  const isTemplatePrague = template === TemplateName.Prague
  const isTemplateShanghai = template === TemplateName.Shanghai

  const sections: SectionData[] = [
    {
      id: SectionNames.accomplishments,
      single: true,
      title: sectionTitle(SectionNames.accomplishments),
      customTitle: '',
      CardComponent: Card.Accomplishments,
      items: [{ id: null, cid: null, description: '' }],
      sortable: true,
      renamable: true,
      hidden: !isTemplateShanghai,
    },
    {
      id: SectionNames.workExperiences,
      title: sectionTitle(SectionNames.workExperiences),
      customTitle: '',
      addButtonText: I18n.t('builder.resume_editor.work_experience_section.add'),
      CardComponent: Card.WorkExperience,
      items: [],
      hintText: I18n.t('builder.resume_editor.work_experience_section.hint'),
      sortable: true,
      renamable: true,
      badgeComponents: [WorkExperienceKeywordsToggler],
    },
    {
      id: SectionNames.educations,
      title: sectionTitle(SectionNames.educations),
      customTitle: '',
      addButtonText: I18n.t('builder.resume_editor.educations_section.add'),
      CardComponent: Card.Education,
      items: [],
      hintText: I18n.t('builder.resume_editor.educations_section.hint'),
      sortable: true,
      renamable: true,
    },
    {
      id: SectionNames.skills,
      title: sectionTitle(SectionNames.skills),
      customTitle: '',
      addButtonText: I18n.t('builder.resume_editor.skills_section.add'),
      CardComponent: isTemplatePrague ? Card.CategorizedSkill : Card.Skill,
      items: [],
      hintText: I18n.t('builder.resume_editor.skills_section.hint'),
      sortable: true,
      renamable: true,
      badgeComponents: [SkillsSuggestionsToggler],
    },
    {
      id: SectionNames.courses,
      title: sectionTitle(SectionNames.courses),
      customTitle: '',
      addButtonText: I18n.t('builder.resume_editor.courses_section.add'),
      CardComponent: Card.Course,
      items: [],
      sortable: true,
      renamable: true,
      removable: true,
      hidden: true,
    },
    {
      id: SectionNames.internships,
      title: sectionTitle(SectionNames.internships),
      customTitle: '',
      addButtonText: I18n.t('builder.resume_editor.internships_section.add'),
      CardComponent: Card.WorkExperience,
      items: [],
      sortable: true,
      renamable: true,
      removable: true,
      hidden: true,
    },
    {
      id: SectionNames.activities,
      title: sectionTitle(SectionNames.activities),
      customTitle: '',
      addButtonText: I18n.t('builder.resume_editor.activities_section.add'),
      CardComponent: Card.Activity,
      items: [],
      sortable: true,
      renamable: true,
      removable: true,
      hidden: true,
    },
    {
      id: SectionNames.hobbies,
      title: sectionTitle(SectionNames.hobbies),
      customTitle: '',
      single: true,
      CardComponent: Card.Hobby,
      items: [],
      sortable: true,
      renamable: true,
      removable: true,
      hidden: true,
    },
    {
      id: SectionNames.languages,
      title: sectionTitle(SectionNames.languages),
      customTitle: '',
      addButtonText: I18n.t('builder.resume_editor.languages_section.add'),
      CardComponent: Card.Language,
      items: [],
      sortable: true,
      renamable: true,
      removable: true,
      hidden: true,
    },
    {
      id: SectionNames.socialProfiles,
      title: sectionTitle(SectionNames.socialProfiles),
      customTitle: '',
      addButtonText: I18n.t('builder.resume_editor.links_section.add'),
      CardComponent: Card.Social,
      items: [],
      sortable: true,
      renamable: true,
      hintText: I18n.t('builder.resume_editor.links_section.hint'),
    },
    {
      id: SectionNames.references,
      title: sectionTitle(SectionNames.references),
      customTitle: '',
      addButtonText: I18n.t('builder.resume_editor.references_section.add'),
      CardComponent: Card.Reference,
      items: [],
      sortable: true,
      renamable: true,
      removable: true,
      hidden: true,
    },
  ]

  return sections
}
