import { ComponentPropsWithoutRef, ElementType } from 'react'

export enum ButtonSize {
  default = 'default',
  small = 'small',
}

export enum ButtonTheme {
  default = 'default',
  alternative = 'alternative',
  confirmative = 'confirmative',
  destructive = 'destructive',
  ghost = 'ghost',
  secondary = 'secondary',
}

export type ButtonComponentProps<T extends ElementType> = {
  size?: ButtonSize | keyof typeof ButtonSize
  theme?: ButtonTheme | keyof typeof ButtonTheme
  isDisabled?: boolean
  isLoading?: boolean
  as?: T
  /** @deprecated use component children to pass icons instead */
  isTryAgain?: boolean
}

export type ButtonProps<T extends ElementType = 'button'> = ButtonComponentProps<T> &
  Omit<ComponentPropsWithoutRef<T>, keyof ButtonComponentProps<T>>

export type ButtonV2Props<T extends ElementType = 'button'> = Omit<ButtonProps<T>, 'isTryAgain'> & {
  leadingIcon?: JSX.Element
  trailingIcon?: JSX.Element
}
