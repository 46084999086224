import { ReactNode, RefObject } from 'react'
import { Container } from './Styles'

interface Props {
  children: ReactNode
  fullWidth?: boolean
  quarterWidth?: boolean
  elementRef?: RefObject<HTMLDivElement>
}

const EditorField = (props: Props) => {
  const { fullWidth, quarterWidth, children, elementRef, ...rest } = props
  return (
    <Container ref={elementRef} {...{ fullWidth, quarterWidth }} {...rest}>
      {children}
    </Container>
  )
}

export default EditorField
