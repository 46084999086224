import styled from 'styled-components'
import { Badge } from 'builder/components/Badge'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

const height = '48'

export const CustomBadge = styled(Badge)<{ $color?: string }>`
  position: absolute;
  height: ${height}px;
  display: inline-flex;
  padding: ${Sizes.XS};
  border-radius: ${Sizes['3XL']};

  ${Typography.Body};
  ${FontWeights.Medium}

  ${({ $color }) => $color && `color: ${$color};`}
  top: calc(-1 * (${height}px + 8px));

  ${Media.Phone`
    top: calc(-1 * (${height}px + 4px));
  `}
`

export const Emoji = styled.img`
  height: 19px;
  margin: 0 5px;
`
