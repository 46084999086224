import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'

import { behaviourType } from 'builder/modules/interview/types'
import { LoadingScreen } from './LoadingScreen'

export const TRANSLATION_PREFIX = 'builder.interview_prep_view.interview_player.loading_end_screen'

export const EndLoading = () => {
  const { i18n } = useI18n()

  const location = useLocation() as unknown as {
    state?: {
      forceStartLoading?: boolean
      startPageType?: behaviourType
      companyName?: string
      isRealCompany?: boolean
    }
  }

  const texts = useMemo(() => {
    if (location.state?.startPageType) {
      return {
        title: 'generating_your_results',
        subtitle: 'generating_your_results_subtitle',
      }
    }

    return {
      title: 'congratulations',
      subtitle: 'congratulations_subtitle',
    }
  }, [location.state?.startPageType])

  return (
    <div>
      <LoadingScreen
        content={{
          title: i18n.t(`${TRANSLATION_PREFIX}.${texts.title}`),
          content: i18n.t(`${TRANSLATION_PREFIX}.${texts.subtitle}`),
          customContentWidth: 500,
        }}
      />
    </div>
  )
}
