import styled from 'styled-components'

import Media from 'builder/styles/media'
import Typography from 'builder/styles/typography'

import Button from '../Button'

export const SettingsButton = styled(Button)``

export const CookiePanel = styled.div`
  display: flex;
  position: fixed;
  bottom: 112px;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  width: 100%;
  justify-content: center;
  z-index: 10001;

  ${Media.Phone` 
      padding: 0;
      bottom: 0;
      padding-bottom: env(safe-area-inset-bottom);
    `}
`

export const CookiePanelContainer = styled.div`
  width: 100%;
  max-width: 768px;
  padding: 26px 35px;
  display: block;
  background-color: var(--white);
  box-shadow: 0px 0px 48px rgba(23, 68, 130, 0.12);
  border-radius: 12px;

  ${Media.Phone` 
      border-radius: 0;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 20px;
    `}

  & a {
    color: var(--blue-50);
  }
`

export const CookiePanelTitle = styled.h4`
  display: flex;
  align-items: center;
  ${Typography.Body};
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 12px;

  &:after {
    content: '';
    position: relative;
    top: -1px;
    margin-left: 4px;
    display: block;
    vertical-align: baseline;
    background-image: url('~images/landing/icons/lock.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
  }
`

export const CookiePanelText = styled.div`
  letter-spacing: 0.3px;
  ${Typography.Body};
  color: var(--neutral-100);
  margin-bottom: 16px;
`

/* Modal */

export const ModalContainer = styled.div`
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(0, 0, 0, 0.16);
  cursor: default;
  margin: auto;
  position: relative;
  width: 680px;
`

export const ModalContent = styled.div`
  position: absolute;
  top: 8px;
  right: 12px;
  padding: 12px;
  cursor: default;
  border-radius: 6px;
  width: fit-content;
`

export const CookieSettingsModalTitle = styled.h4`
  align-items: center;
  ${Typography.Body};
  font-weight: 600;
  margin-bottom: 20px;
`

export const CookieSettingsModalText = styled.div`
  ${Typography.Tiny};
  letter-spacing: 0.3px;
  margin-bottom: 28px;

  & p {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

export const CookieSettingsModalContainer = styled.form`
  padding: 24px 36px 52px;

  & a {
    color: var(--blue-50);
  }
`

export const CookieSettingsModalPlaceholder = styled.span`
  ${Typography.Caption};
  color: var(--neutral-50);
  white-space: nowrap;
  margin-left: auto;
`

export const CookieSettingsModalButtonPanel = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 28px;
`

export const CookieCollapseHeader = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid var(--neutral-20);
`

export const CookieCollapseToggle = styled.div`
  ${Typography.Caption};
  position: relative;
  flex-grow: 1;
  display: flex;
  font-weight: 600;
  padding: 16px 0px;
  transition: color 0.125s ease;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: var(--blue-50);
  }
`

export const CookieCollapseBody = styled.div`
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding-left: 8px;
  padding-right: 8px;

  &.is-open {
    height: auto;
    opacity: 1;
  }
`

export const CookieCollapseBodyInner = styled.div`
  ${Typography.Tiny};
  letter-spacing: 0.3px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const CheckboxCheckmark = styled.div`
  position: relative;
  width: 30px;
  height: 18px;
  border: 0;
  border-radius: 9px;
  background-color: var(--neutral-20);
  transition: background-color 0.125s ease;

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    border: 0;
    border-radius: 50%;
    background: var(--white);
    transform: none;
    transition: transform 0.125s ease;
  }

  &:before {
    content: '';
    position: absolute;
    width: 150%;
    height: 180%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`

export const CheckboxToggle = styled.label`
  padding-left: 0;
  margin-left: auto;
  min-height: 18px;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  & input:checked ~ .checkbox__checkmark {
    border: 0;
    background-color: var(--blue-50);

    &::after {
      transform: translateX(12px);
    }
  }
`

export const Collapse = styled.ul`
  display: block;
  padding: 0;
  margin: 0 0 14px;
`

export const CollapseItem = styled.li`
  display: block;

  &.is-open {
    & .collapse__toggle {
      &:hover {
        color: var(--blue-50);
      }
    }

    & .collapse__arrow {
      transform: translateY(-50%) rotate(0deg);
      color: var(--blue-50);
    }
  }
`

export const CookieSettingsInput = styled.input`
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
`

export const MarketingLinksContainer = styled.div`
  display: flex;
  gap: 16px;
`

export const DescriptionLink = styled.p`
  color: var(--blue-50);
  display: inline;
  cursor: pointer;
`
