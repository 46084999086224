import { selectors, ConfigScopesEnum } from 'builder/modules/init'
import { UserData } from 'builder/modules/user'
import { useTypedSelector } from './useTypedSelector'

type User = UserData & {
  isPaidUser: boolean
  isTrialUser: boolean
  isTopResumeUser: boolean
}

export const useUser = (): User | null => {
  const user = useTypedSelector(state => state.user.data)
  const config = useTypedSelector(state => selectors.config(state, ConfigScopesEnum.app))

  if (!user) return null

  const isPaidUser = () => {
    if (
      !user?.billingInfo?.cancelledAt &&
      !user?.billingInfo?.nextPaymentAt &&
      (user?.billingInfo?.recurringAmount === 'USD0.0' || !user?.billingInfo?.recurringAmount)
    ) {
      return false
    }

    return !config?.promoted?.billingItems?.length
  }

  const isTopResumeUser = () => {
    return isPaidUser() && !user?.scopes?.chargebeeCustomer && user?.scopes?.kind === 'TR'
  }

  const isTrialUser = () => {
    return user?.billingInfo?.onTrial || false
  }

  return {
    ...user,
    isPaidUser: isPaidUser(),
    isTrialUser: isTrialUser(),
    isTopResumeUser: isTopResumeUser(),
  }
}
