import { useI18n } from 'builder/hooks/useI18n'
import { ButtonTheme } from 'builder/components/Button'
import {
  TitleContainer,
  Title,
  SubTitle,
  ButtonContainer,
  DownloadButton,
  ArrowTargetIconWrapper,
  ArrowTargetIcon,
  Button,
} from './styles'

interface BottomContainerProps {
  onDownload: () => void
  onStart: () => void
}
const FeaturingAutoTailorOnDownloadBottomView = ({ onDownload, onStart }: BottomContainerProps) => {
  const { i18n } = useI18n()

  return (
    <>
      <TitleContainer>
        <Title>{i18n.t('builder.resume_optimizer.job_posting.featuring_tailor.title')}</Title>
        <SubTitle>
          {i18n.t('builder.resume_optimizer.job_posting.featuring_tailor.sub_title')}
        </SubTitle>
      </TitleContainer>
      <ButtonContainer>
        <DownloadButton theme="ghost" onClick={onDownload}>
          {i18n.t('builder.resume_optimizer.job_posting.featuring_tailor.download_button_text')}
        </DownloadButton>
        <Button theme={ButtonTheme.alternative} onClick={onStart}>
          <ArrowTargetIconWrapper>
            <ArrowTargetIcon />
          </ArrowTargetIconWrapper>
          {i18n.t('builder.resume_optimizer.job_posting.featuring_tailor.continue_button_text')}
        </Button>
      </ButtonContainer>
    </>
  )
}

export default FeaturingAutoTailorOnDownloadBottomView
