import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useUser } from 'builder/hooks/useUser'

import {
  selectors as renderingSelectors,
  actions as renderingActions,
} from 'builder/modules/rendering'
import { useIsDashboardOpen } from 'builder/hooks/useIsDashboardOpen'
import { useRedirectToAutoApply } from './useRedirectToAutoApply'

const MODAL_SHOWN_STORAGE_KEY = 'rd_get_started_shown'

export const useResumeDistributionGetStartedModal = () => {
  const wasRdGetStartedShown = localStorage.getItem(MODAL_SHOWN_STORAGE_KEY)

  const user = useUser()
  const dispatch = useDispatch()
  const rdUpsellGetStartedModalVisible = useTypedSelector(
    renderingSelectors.rdUpsellGetStartedModalVisible,
  )

  const isDashboardOpen = useIsDashboardOpen()

  const { redirectToAutoApply: handleRedirectToAutoApply } = useRedirectToAutoApply()

  const shouldModalOpen = useCallback(() => {
    return !wasRdGetStartedShown && rdUpsellGetStartedModalVisible && !!isDashboardOpen
  }, [wasRdGetStartedShown, rdUpsellGetStartedModalVisible, isDashboardOpen])

  const [isOpen, setIsOpen] = useState(shouldModalOpen)

  function setRDGetStartedShown() {
    localStorage.setItem(MODAL_SHOWN_STORAGE_KEY, 'true')
  }

  function handleModalClose() {
    setRDGetStartedShown()
    dispatch(renderingActions.closeRDUpsellGetStartedModal())
    setIsOpen(false)
  }

  function redirectToAutoApply() {
    trackInternalEvent('click_tjs_upgrade_button')
    handleModalClose()
    handleRedirectToAutoApply()
  }

  const showModalAfterPayment = useCallback(() => {
    if (
      user?.scopes?.autoApply &&
      !wasRdGetStartedShown &&
      !user.billingInfo.premiumEndsAt &&
      !!isDashboardOpen &&
      user?.isTopResumeUser
    ) {
      setIsOpen(true)
    }
  }, [
    user?.billingInfo.premiumEndsAt,
    user?.scopes?.autoApply,
    user?.isTopResumeUser,
    wasRdGetStartedShown,
    isDashboardOpen,
  ])

  useEffect(() => {
    setIsOpen(shouldModalOpen())
    showModalAfterPayment()
  }, [rdUpsellGetStartedModalVisible, shouldModalOpen, showModalAfterPayment])

  return {
    isOpen,
    handleModalClose,
    redirectToAutoApply,
  }
}
