import styled from 'styled-components'
import { animated } from '@react-spring/web'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
import Icon24 from 'builder/components/Icon'

export const BannerContainer = styled(animated.div)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const Banner = styled.div`
  width: 100%;
  height: 100%;
  background: ${Colors.Indigo10};
  display: flex;
  border-radius: ${Sizes['2XS']};
  justify-content: space-between;
`

export const Content = styled.div`
  flex: 1 1 242px;
  display: flex;
  flex-direction: column;
  gap: ${Sizes.L};
  padding: 24px;

  ${Media.Phone`
    flex: 0 1 170px;
    padding: 16px;
  `}
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes['3XS']};
`

export const Title = styled.div`
  ${Typography.Body};
  ${FontWeights.DemiBold};
  color: ${Colors.Indigo80};
`

export const Description = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const Footer = styled.div`
  display: flex;
  gap: ${Sizes.XS};
`

export const ImageContainer = styled.div`
  flex: 0 1 240px;
  position: relative;
  margin-top: 16px;
  margin-right: 16px;
  overflow: hidden;

  ${Media.Phone`
    margin-right: 0;
  `}
`

export const Image = styled.img`
  height: 172px;
  position: absolute;
  top: 0;
  left: 0;
`

export const RightFade = styled.div`
  width: 59px;
  height: 100%;
  background: linear-gradient(90deg, rgba(239, 240, 253, 0) 0%, ${Colors.Indigo10} 100%);
  position: absolute;
  right: 0;
  top: 0;

  ${Media.Phone`
    width: 10px;
  `}
`

export const BottomFade = styled.div`
  width: 100%;
  height: 9px;
  background: linear-gradient(180deg, rgba(239, 240, 253, 0) 0%, ${Colors.Indigo10} 100%);
  position: absolute;
  bottom: 0;
  left: 0;
`

export const TailoringIcon = styled(Icon24.ArrowTargetAutoTailoring)`
  margin-right: 4px;
`
