import throttle from 'lodash/throttle'
import { createRef, Fragment, PureComponent, RefObject } from 'react'

import { EventCode, trackInternalEvent } from '@rio/tracking'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Preview from 'builder/components/Preview'
import { A4_FACTOR } from 'builder/modules/constants'
import { actions as uiActions } from 'builder/modules/ui'
import { i18n as I18n } from 'builder/utils/i18n'

import { withConfig } from 'builder/components/Config'
import EscapeHandler from 'builder/components/EscapeHandler'
import Icon, { Icon32 } from 'builder/components/Icon'
import { withMediaQueries } from 'builder/components/MediaQueries'

import { Store } from 'builder/modules/store'

import { baseClient } from 'builder/modules/apiClient'
import { TemplateCategories } from 'builder/modules/resumeEditor'
import templateHeightFactorSelector from '../Helper/utils/templateHeightFactorSelector'
import { TemplatesPreviewList } from '../TemplatesList/TemplatesPreviewList'

import { Icon40 } from '../Icon/Icon'
import Colors from './Colors'
import { CurrentTemplate, DocumentPreviewModalProps } from './Constants'
import SpacingControl from './SpacingControl'
import ToolbarRight from './components/ToolbarRight'
import {
  Back,
  BackIcon,
  BackText,
  Container,
  Content,
  ContentInner,
  Divider,
  Main,
  Pagination,
  PaginationCounter,
  PaginationNext,
  PaginationPrev,
  PreviewBox,
  Sidebar,
  SidebarInner,
  Toolbar,
  ToolbarCenter,
  ToolbarLeft,
} from './styles'

const PREVIEW_WIDTH = 928

export const TEMPLATE_DATA: Record<
  `${TemplateCategories}`,
  { name: string; icon: JSX.Element; id: string }
> = {
  [TemplateCategories.all]: {
    name: 'All templates',
    icon: <Icon32.Templates width={24} height={24} />,
    id: TemplateCategories.all,
  },
  [TemplateCategories.simple]: {
    name: 'Simple',
    icon: <Icon.DoubleStars width={24} height={24} />,
    id: TemplateCategories.simple,
  },
  [TemplateCategories.ats]: {
    name: 'ATS',
    icon: <Icon.ArrowTarget width={24} height={24} color="#5C6373" />,
    id: TemplateCategories.ats,
  },
  [TemplateCategories.creative]: {
    name: 'Creative',
    icon: <Icon40.Unicorn width={24} height={24} color="#5C6373" />,
    id: TemplateCategories.creative,
  },
  [TemplateCategories.modern]: {
    name: 'Modern',
    icon: <Icon.HandModern width={24} height={24} />,
    id: TemplateCategories.modern,
  },
  [TemplateCategories.professional]: {
    name: 'Professional',
    icon: <Icon.WorkBag width={24} height={24} color="white" />,
    id: TemplateCategories.professional,
  },
  [TemplateCategories.free]: {
    name: 'Free',
    icon: <Icon.Deal width={24} height={24} />,
    id: TemplateCategories.free,
  },
}

class DocumentPreviewModal extends PureComponent<DocumentPreviewModalProps> {
  sidebarRef: RefObject<HTMLDivElement>
  contentRef: RefObject<HTMLDivElement>
  preview: RefObject<{
    previousPage: () => void
    resize: (width: number) => void
    nextPage: () => void
  }>

  previewBoxRef: HTMLDivElement | null

  state = {
    isColorPickerOpen: false,
    currentPage: 1,
    totalPagesNumber: 1,
    width: PREVIEW_WIDTH,
    height: PREVIEW_WIDTH / A4_FACTOR,
    templateCategories: {},
    selectedCategory: 'all',
  } as {
    [x: string]: any
    templateCategories:
      | Record<string, never>
      | Record<`${TemplateCategories}`, { template_names: [] }>
  }

  constructor(props: DocumentPreviewModalProps) {
    super(props)
    this.sidebarRef = createRef()
    this.contentRef = createRef()
    this.preview = createRef()
  }

  componentDidMount() {
    window.addEventListener('resize', this.handlePreviewResize)
    this.handlePreviewResize()
    if (this.contentRef.current) disableBodyScroll(this.contentRef.current)

    baseClient
      .get<{ grouped_categories: Record<`${TemplateCategories}`, { template_names: [] }> }>(
        'templates/categories',
      )
      .then(response =>
        this.setState({
          templateCategories: { ...response.data.grouped_categories, free: { template_names: [] } },
        }),
      )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlePreviewResize)
    if (this.contentRef.current) enableBodyScroll(this.contentRef.current)
  }

  handleCombineClick = () => {
    const { type, openMergeModal, currentDocument } = this.props
    const documentKey = type === 'resume' ? 'resumeId' : 'coverLetterId'

    openMergeModal({ [documentKey]: currentDocument.id })
    trackInternalEvent('open_documents_merging_modal')
  }

  handlePreviewResize = throttle(() => {
    if (!this.previewBoxRef) return
    const previewWidth = this.previewBoxRef.offsetWidth
    const width = Math.min(previewWidth, PREVIEW_WIDTH)
    const height =
      Math.min(previewWidth, PREVIEW_WIDTH) /
      templateHeightFactorSelector(this.props?.currentDocument)
    this.setState({ width, height })

    if (!this.preview.current) return
    this.preview.current?.resize(width)
  }, 50)

  handleColorSelect = (color: string) => {
    this.props.updateSimpleField('color', color, false)
  }

  handleSpacingChange = (spacing: number) => {
    // Use custom debounce interval (350 ms) instead of the default one (1,000 ms)
    // to display the new version of the resume faster and prevent extra requests
    this.props.updateSimpleField('spacing', spacing, 350)
  }

  handlePaginationStateChange = (state: { currentPage: number; totalPagesCount: number }) => {
    this.setState({ currentPage: state.currentPage + 1, totalPagesNumber: state.totalPagesCount })
  }

  handlePreviousPageClick = () => {
    if (!this.preview.current) return
    this.preview.current?.previousPage()
    trackInternalEvent(`change_${this.props.type}_preview_page` as EventCode)
  }

  handleNextPageClick = () => {
    if (!this.preview.current) return
    this.preview.current?.nextPage()
    trackInternalEvent(`change_${this.props.type}_preview_page` as EventCode)
  }

  get isResume() {
    return this.props.type === 'resume'
  }

  get templates() {
    const { editorState } = this.props
    return this.isResume ? editorState.resumeTemplates : editorState.coverLetterTemplates
  }

  get currentTemplate() {
    const { currentDocument } = this.props

    return this.templates.find(template => template.id === currentDocument.template)
  }

  get colorSettings() {
    const { currentDocument } = this.props
    const currentTemplate = this.currentTemplate as unknown as CurrentTemplate
    const templateSettings = currentTemplate?.settings

    let selected = currentDocument ? currentDocument.color : null

    if (!selected && templateSettings?.color) selected = templateSettings?.color.default

    const options = templateSettings?.color ? templateSettings?.color.values : []

    return { selected, options, supportsCustomColor: currentTemplate?.supportsCustomColor }
  }

  render() {
    const { currentPage, totalPagesNumber, height, width } = this.state
    const { currentDocument, editorState, type, ui, onClose } = this.props
    const isDataLoaded = currentDocument && editorState.isLoaded
    const hasPreviousPage = currentPage > 1
    const hasNextPage = currentPage < totalPagesNumber

    return (
      <Container>
        <EscapeHandler onPress={onClose} />

        <Toolbar>
          <ToolbarLeft>
            <Back onClick={onClose}>
              <BackIcon />
              <BackText>{I18n.t('builder.resume_editor.back_to_editor')}</BackText>
            </Back>
          </ToolbarLeft>

          {isDataLoaded && (
            <ToolbarCenter>
              {this.isResume && (
                <Fragment>
                  <SpacingControl
                    isSupported={this?.currentTemplate?.supportsSpacing}
                    value={currentDocument.spacing}
                    isCustomizationModal
                    onChange={this.handleSpacingChange}
                  />
                  <Divider />
                </Fragment>
              )}
              <Colors onSelect={this.handleColorSelect} {...this.colorSettings} />
            </ToolbarCenter>
          )}
          <ToolbarRight type={type} onClose={onClose} />
        </Toolbar>

        <Main>
          <Sidebar>
            <SidebarInner ref={this.contentRef}>
              <TemplatesPreviewList
                type={this.props.type}
                selectedTemplates={
                  this.state.templateCategories[
                    this.state.selectedCategory as `${TemplateCategories}`
                  ]?.template_names || []
                }
                selectedCategory={this.state.selectedCategory}
              />
            </SidebarInner>
          </Sidebar>

          <Content>
            <ContentInner ref={this.contentRef}>
              <PreviewBox ref={ref => (this.previewBoxRef = ref)}>
                {isDataLoaded && (
                  <Preview
                    {...{ height, width, type }}
                    document={currentDocument}
                    documentId={currentDocument.id}
                    ref={this.preview}
                    onPaginationChange={this.handlePaginationStateChange}
                  />
                )}
              </PreviewBox>
            </ContentInner>

            <Pagination isVisible={!ui?.isSnackBarOpen}>
              <PaginationPrev onClick={this.handlePreviousPageClick} isDisabled={!hasPreviousPage}>
                <Icon.Chevron />
              </PaginationPrev>
              <PaginationCounter>
                {currentPage} / {totalPagesNumber}
              </PaginationCounter>
              <PaginationNext onClick={this.handleNextPageClick} isDisabled={!hasNextPage}>
                <Icon.Chevron />
              </PaginationNext>
            </Pagination>
          </Content>
        </Main>
      </Container>
    )
  }
}

const mapStateToProps = (state: Store) => ({
  ui: state.ui,
})

const mapDispatchToProps = {
  openMergeModal: uiActions.openMergeModal,
}

export default compose(
  withConfig,
  withMediaQueries,
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentPreviewModal)
