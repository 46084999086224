import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Container, Wrapper } from './styles'
import { Navbar } from './Navbar/Navbar'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions as userActions, selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

export const Start = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isUserFetched = useTypedSelector(userSelectors.isFetched)

  useEffect(() => {
    dispatch(userActions.fetchUserRequest({ redirectOnFailure: false }))
  }, [])

  if (isUserFetched) {
    // logged in users should not visit start pages except /start/ready page
    const { pathname } = location
    if (!pathname.endsWith('/ready')) {
      const loggedInUserPath = pathname.replace('/start', '')
      Object.assign(location, { pathname: loggedInUserPath })
      navigate(location)
    }
  }

  return (
    <Wrapper>
      <Navbar />
      <Container>
        <Outlet />
      </Container>
    </Wrapper>
  )
}
