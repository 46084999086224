import { useMutation } from '@tanstack/react-query'
import { useRef, useEffect } from 'react'
import { queryClient } from 'builder/components/Providers/Providers'
import { baseClient } from 'builder/modules/apiClient'
import { InterviewAnswer } from 'builder/modules/interview/types'
import { getMimeType } from '../utils/getMimeType'
import { useGetStream, UserMediaType } from '../components/InterviewPlayerView/hooks/useGetStream'

interface Params {
  questionId: number
}
export const useCreateInterviewAnswerQuestion = () => {
  const audioStream = useGetStream(UserMediaType.audio)
  const audioVideoStream = useGetStream(UserMediaType.video)

  const mimeTypeForVideoPromiseRef = useRef<Promise<string>>()
  const mimeTypeForAudioPromiseRef = useRef<Promise<string>>()

  useEffect(() => {
    const processingMimeType = async () => {
      if (audioVideoStream.stream === undefined || audioStream.stream === undefined) {
        return
      }

      mimeTypeForVideoPromiseRef.current =
        audioVideoStream.stream === null
          ? Promise.resolve('')
          : getMimeType(audioVideoStream.stream, 'video')

      mimeTypeForAudioPromiseRef.current =
        audioStream.stream === null ? Promise.resolve('') : getMimeType(audioStream.stream, 'audio')
    }
    processingMimeType()
  }, [audioVideoStream?.stream, audioStream?.stream])

  return useMutation({
    mutationFn: async ({ questionId }: Params): Promise<InterviewAnswer> => {
      const [mimeTypeForVideo, mimeTypeForAudio] = await Promise.all([
        mimeTypeForVideoPromiseRef.current || Promise.resolve(''),
        mimeTypeForAudioPromiseRef.current || Promise.resolve(''),
      ])

      const response = await baseClient.post(`/interview_prep/questions/${questionId}/answer`, {
        audio_content_type: mimeTypeForAudio,
        video_content_type: mimeTypeForVideo,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['useQuestionTemplate'])
    },
  })
}
