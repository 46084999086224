export enum TABS {
  voice_input = 'voice_input',
  text_input = 'text_input',
}

export const ALLOWED_FILES =
  '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'

export const FILL_BY_URL_PARAM = 'fillByUrl'

export enum FillByUrlParamValues {
  createWithAi = 'createWithAi',
  uploadResume = 'uploadResume',
  useExample = 'useExample',
}
