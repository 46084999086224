import trim from 'lodash/trim'
import { trackInternalEvent } from '@rio/tracking'
import { Optional, ResumeSkill } from 'packages/types'
import { useSuggestions } from './hooks'
import { APPEAR_ANIMATION, LEAVE_ANIMATION } from './constants'
import { Container, Content, List, Skill, SkillAddIcon, SkillTickIcon } from './styles'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  onAddSkill: (skill: string) => void
  locale: string
  position: Optional<string>
  skills: ResumeSkill[]
}

export const SkillAssistant = (props: Props) => {
  const { locale, position, skills, onAddSkill } = props
  const currentSkillNames = skills.map(item => (item.skill ? trim(item.skill) : '')).filter(Boolean)
  const suggestions = useSuggestions({ locale, position, currentSkillNames })

  if (!suggestions.length) return null

  const addSkill = (skill: string) => {
    onAddSkill(skill)
    trackInternalEvent('add_suggested_skill')
  }

  return (
    <Container {...props} onClick={event => event.stopPropagation()}>
      <Content>
        <List
          appearAnimation={APPEAR_ANIMATION}
          leaveAnimation={LEAVE_ANIMATION}
          duration={275}
          staggerDelayBy={25}
          easing="ease-out"
        >
          {suggestions.map(skill => {
            const isActive = currentSkillNames.includes(skill)

            return (
              <Skill key={skill} isActive={isActive} onClick={() => addSkill(skill)}>
                {skill}
                {!isActive && <SkillAddIcon />}
                {isActive && <SkillTickIcon />}
              </Skill>
            )
          })}
        </List>
      </Content>
    </Container>
  )
}
