import { memo, useContext, useEffect, useRef, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import throttle from 'lodash/throttle'
import { useI18n } from 'builder/hooks/useI18n'
import { DocumentTypes } from 'builder/modules/constants'
import { GuestResumeContext } from 'builder/context/GuestResumeContext'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import templateHeightFactorSelector from '../Helper/utils/templateHeightFactorSelector'
import { CurrentDocument, MODES, PreviewRefProps } from './types'

import {
  Container,
  PreviewBox,
  PreviewWrapper,
  BlurredTemplate,
  UnlockContainer,
  UnlockText,
  UnlockButton,
  TemplateContainer,
} from './styles'
import TopbarLayout from './components/TopbarLayout'
import BottomBarLayout from './components/BottomBarLayout'

const defaultThumbnail =
  '/assets/templates/london-439731a637642c0540010c1e00960577fa3f5914c0b0baa48f130ed0284401e0.jpg'

const PreviewPanelGuest = (): JSX.Element => {
  const previewRef = useRef<PreviewRefProps>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)
  const { i18n } = useI18n()
  const {
    toggleEmailRequiredError,
    resume: { template },
  } = useContext(GuestResumeContext)
  const currentDocument: CurrentDocument & { workExperiences: [] } = {
    id: 0,
    color: '',
    spacing: 0,
    workExperiences: [],
  }
  const { resumeTemplates } = useTypedSelector(state => state.generalEditor)
  const thumbnail = resumeTemplates.find(t => t.id === template)?.thumbnail || defaultThumbnail

  const handlePreviewResize = throttle(() => {
    if (!containerRef.current) return
    const pdfHeightFactor = templateHeightFactorSelector({ template })
    const previewHeight = containerRef.current.offsetHeight
    const previewWidth = containerRef.current.offsetWidth

    const width = Math.min(previewWidth, previewHeight * pdfHeightFactor)
    setWidth(Math.round(width))
    setHeight(Math.round(width / pdfHeightFactor))

    if (!previewRef.current) return
    previewRef.current.resize(width)
  }, 50)

  useEffect(() => {
    window.addEventListener('resize', handlePreviewResize)
    handlePreviewResize()
    return () => {
      window.removeEventListener('resize', handlePreviewResize)
    }
  }, [handlePreviewResize])

  const handleActions = () => {
    toggleEmailRequiredError()
  }

  const handleDownloadAction = () => {
    trackInternalEvent('click_download_resume_button', {
      source: 'resume_editor',
      template,
      format: 'pdf',
    })
    toggleEmailRequiredError()
  }

  return (
    <Container mode={MODES.editor} id="preview-panel-container">
      <PreviewBox ref={containerRef}>
        <PreviewWrapper>
          <TopbarLayout
            {...{
              width,
              type: 'resume' as DocumentTypes,
              documentId: 0,
              isDownloading: false,
              handleFullSize: handleActions,
              customAction: handleActions,
              customDownloadAction: handleDownloadAction,
              currentDocument,
            }}
          />
          <TemplateContainer>
            <BlurredTemplate src={thumbnail} width={width} height={height} />
            <UnlockContainer $height={height} $width={width}>
              <UnlockText>{i18n.t('builder.sign_up.sign_up_guest_banner.description')}</UnlockText>
              <UnlockButton
                onClick={() => {
                  handleActions()
                  trackInternalEvent('click_provide_email')
                }}
              >
                {i18n.t('builder.sign_up.sign_up_guest_banner.button')}
              </UnlockButton>
            </UnlockContainer>
          </TemplateContainer>
          <BottomBarLayout
            {...{
              type: 'resume' as DocumentTypes,
              currentPage: 1,
              totalPagesNumber: 2,
              mode: MODES.editor,
              documentId: 0,
              previewRef,
              customAction: handleActions,
              currentDocument,
            }}
          />
        </PreviewWrapper>
      </PreviewBox>
    </Container>
  )
}

export default memo(PreviewPanelGuest)
