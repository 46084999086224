import { useEffect, useState } from 'react'
import { EVENT_VOICE_ANIMATION_FOR_INTERVIEW_PREP } from 'builder/views/Interview/constants'
import { VoiceAnimationEnum, VoiceAnimationEvent } from 'builder/modules/interview/types'
import { Animation, Wrapper } from './styles'

interface Props {
  children: React.ReactChild
}

export const VoiceAnimation = ({ children }: Props) => {
  const [scale, setScale] = useState(0)
  const [blur, setBlur] = useState(50)

  useEffect(() => {
    let animationFrameId: number
    let timeId: NodeJS.Timeout
    const listener = (event: CustomEvent<VoiceAnimationEvent>) => {
      if (event.detail.type === VoiceAnimationEnum.playing) {
        const waveform = event.detail.waveform
        const analyser = event.detail.analyser

        let timeCount = 1
        let prevTimeCount = 0
        timeId = setInterval(() => (timeCount += 1), 300)

        const MIN_AMPLITUDE = 0.1
        const MAX_AMPLITUDE = 0.7

        const updateAnimation = () => {
          analyser.getByteTimeDomainData(waveform)

          // Calculate average amplitude
          const avgAmplitude =
            waveform.reduce((sum, value) => sum + (value - 128), 0) / waveform.length

          // Normalize amplitude to 0-1
          let normalized = Math.abs(avgAmplitude)

          if (normalized < MIN_AMPLITUDE) normalized = MIN_AMPLITUDE
          if (normalized > MAX_AMPLITUDE) normalized = MAX_AMPLITUDE
          const calculatedScale =
            1 + ((normalized - MIN_AMPLITUDE) / (MAX_AMPLITUDE - MIN_AMPLITUDE)) * (5 - 1) // Scale from 1 to 5
          const calculatedBlur =
            50 - ((normalized - MIN_AMPLITUDE) / (MAX_AMPLITUDE - MIN_AMPLITUDE)) * (50 - 10) // Blur from 50px to 10px

          if (prevTimeCount !== timeCount) {
            prevTimeCount = timeCount

            setScale(calculatedScale)
            setBlur(calculatedBlur)
          }
          // Continue animation
          animationFrameId = requestAnimationFrame(updateAnimation)
        }

        // Start animation loop
        updateAnimation()
      }

      if (event.detail.type === VoiceAnimationEnum.reset) {
        cancelAnimationFrame(animationFrameId)
        clearTimeout(timeId)
        setScale(0)
        setBlur(50)
      }
    }

    window.addEventListener(EVENT_VOICE_ANIMATION_FOR_INTERVIEW_PREP, listener as EventListener)

    return () => {
      cancelAnimationFrame(animationFrameId)
      clearTimeout(timeId)
      window.removeEventListener(
        EVENT_VOICE_ANIMATION_FOR_INTERVIEW_PREP,
        listener as EventListener,
      )
    }
  }, [])

  return (
    <Wrapper>
      <Animation style={{ transform: `scale(${scale})`, filter: `blur(${blur}px)` }} />
      {children}
    </Wrapper>
  )
}
