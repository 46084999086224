import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useEffect } from 'react'
import { ErrorLayout } from './ErrorLayout'
import NO_ROLE from './assets/no_role.png'
import { NoRoleImage } from './styles'
import { ErrorComponentProps } from './types'

export const NoTargetRole = ({ CustomContainer }: ErrorComponentProps) => {
  const navigate = useNavigate()

  useEffect(() => {
    trackInternalEvent('show_missing_error', {
      label: CustomContainer ? 'recommended_jobs_dashboard' : 'recommended_jobs',
      missing_info: 'target_role',
    })
  }, [])

  return (
    <ErrorLayout
      CustomContainer={CustomContainer}
      Images={<NoRoleImage src={NO_ROLE} alt="no_role" />}
      translationKey="no_target_role"
      onClick={() => {
        trackInternalEvent('view_profile_editor', { label: 'recommended_jobs' })
        navigate(
          '/career-profile/edit?section=jobPreferences&returnUrl=/job-search?view=recommendation',
        )
      }}
    />
  )
}
