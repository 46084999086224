import styled, { css } from 'styled-components'
import { gradient } from 'builder/styles/keyframes'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { FontWeights, Typography } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import Sizes from 'builder/styles/sizes'
import { Z_INDEX_SEARCH_BAR } from '../SearchBar/styles'

export const Grid = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 16px;

  ${Media.Tablet`
    display: block;
  `}
`

export const JobsList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
  width: 40%;

  > li:not(:last-child) {
    margin-bottom: 8px;
  }

  /* Second last child because last child is empty li ref for infinite scroll */
  > li:nth-last-child(1) {
    margin-bottom: 20px;
  }

  ${Media.Tablet`
    width: 100%;
  `}
`

export const Details = styled.div<{ $isSearching: boolean; hasActiveJob: boolean }>`
  width: 656px;

  ${({ $isSearching, hasActiveJob }) =>
    !$isSearching &&
    !hasActiveJob &&
    Media.Tablet`
    display: none;
  `}
`

export const DetailsFixedContainer = styled.div<{
  $isDashboardV2: boolean
  $isSearching: boolean
}>`
  border-radius: 8px;
  width: inherit;

  background-color: ${Colors.White};

  ${({ $isDashboardV2 }) => Media.AboveTablet`
    box-shadow: 0px 2px 4px -1px rgba(15, 56, 113, 0.08), 0px 8px 20px -4px rgba(15, 56, 113, 0.12);
    filter: drop-shadow(0px 0px 1px rgba(15, 56, 113, 0.32));
    position: sticky;
    top: ${$isDashboardV2 ? 155 : 90}px;
    overflow: auto;
    width: 100%;
    height: calc(100vh - ${$isDashboardV2 ? 178 : 120}px);
  `}

  ${({ $isDashboardV2 }) => Media.Tablet`
    position: fixed;
    top: 70px;
    left: ${$isDashboardV2 ? 60 : 0}px;
    background-color: ${Colors.White};
    width: calc(100vw - ${$isDashboardV2 ? 60 : 0}px);
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: ${Z_INDEX_SEARCH_BAR + 1};
    overflow: auto;
    height: calc(100vh - ${$isDashboardV2 ? 57 : 0}px);
  `}

  ${({ $isDashboardV2 }) => Media.Phone`
    position: fixed;
    top: 70px;
    left: 0;
    background-color: ${Colors.White};
    width: 100%;
    bottom: ${$isDashboardV2 ? 70 : 0}px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: ${Z_INDEX_SEARCH_BAR + 1};
    overflow: auto;
    height: unset;
  `}

  ${({ $isSearching }) =>
    $isSearching &&
    Media.Tablet`
    display: none;
  `}
`

export const Skeleton = styled.div<{ width?: number; height?: number; marginTop?: number }>`
  animation: ${gradient} 1s linear infinite;
  background: ${Colors.Neutral10};
  background: linear-gradient(
    to right,
    ${Colors.Neutral10} 5%,
    #eaeef6 15%,
    ${Colors.Neutral10} 25%
  );
  border-radius: 2px;
  width: ${p => (p.width ? `${p.width}px` : '100%')};
  height: ${p => (p.height ? `${p.height}px` : '100%')};
  background-size: 100vw 100vh;
  ${p =>
    p.marginTop &&
    css`
      margin-top: ${p.marginTop}px;
    `}
`

// export const HeadingContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;

//   ${Media.Phone`
//     align-items: center;
//     flex-direction: column;
//     align-items: flex-start;
//     margin-top: ${Sizes.L};
//     width: 100%;
//   `}
// `

export const HeadingContainer = styled.div`
  display: flex;
  margin-top: ${Sizes.L};

  ${Media.Tablet`
    margin-top: ${Sizes.M};
    flex-direction: column;
    align-items: center;
  `}

  ${Media.Phone`
    gap: 8px
  `}

  &:empty {
    display: none;
  }
`

export const SavedJobs = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${Colors.Blue50};

  svg {
    margin-right: 6px;
  }

  ${Media.Phone`
    margin-top: 8px;
  `}
`

export const SavedJobsCount = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px;
  width: 24px;
  height: 24px;
  background: ${Colors.Blue20};
  border-radius: 32px;
  margin: 0 5px;

  ${FontWeights.Regular};
  ${Typography.Caption};

  align-items: center;
  text-align: center;
  color: ${Colors.Blue50};
`

export const DialogContainer = styled.div<{ opacity?: string; visibility?: string }>`
  opacity: ${p => p.opacity};
  visibility: ${p => p.visibility};
  padding: 0px;
  width: 360px;
  height: 378px;
  background: ${Colors.White};
  border-radius: 12px;
  position: fixed;
  z-index: 1;
  right: 15px;
  bottom: 15px;
  transition: all 0.6s ease-out;
  box-shadow: 0px 0px 1px rgba(15, 56, 113, 0.32), 0px 2px 4px -1px rgba(15, 56, 113, 0.08),
    0px 8px 20px -4px rgba(15, 56, 113, 0.12);

  ${Media.Phone`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    height: 100%;
    border-radius: 0;
  `}
`

export const VideoContainer = styled.div`
  background: #e5e5f680;
  overflow: hidden;
  border-radius: 6px 6px 0 0;
  padding-top: 44%;
  position: relative;
`

export const VideoItem = styled.video<{ active?: boolean }>`
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease;
  transition-delay: 0;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      transition: opacity 0.2s ease;
      transition-delay: 0.2s;
    `}
`

export const ContentDialog = styled.div`
  padding: 32px;

  ${Media.Phone`
    flex-grow: 1;
    padding: 20px 20px 32px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  `}
`

export const Controls = styled.div`
  float: right;

  & ${Button} {
    margin-left: 12px;
  }

  ${Media.Phone`
    margin-top: auto;
  `}
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
  margin-bottom: 8px;
`

export const Description = styled.div`
  min-height: 50px;

  & a {
    color: ${Colors.Blue50};
  }

  & a:hover {
    color: ${Colors.Blue70};
  }
`

export const Close = styled.button`
  padding: 0;
  border: none;
  width: 36px;
  height: 36px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${hexToRgba(Colors.Neutral100, 0.3)};

  ${Media.Phone`
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
  `}
`

export const SimilarJobsTitle = styled.div<{ withMarginTop: boolean }>`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
  margin-bottom: ${Sizes['2XS']};
  ${({ withMarginTop }) =>
    withMarginTop &&
    css`
      margin-top: ${Sizes.L};
    `}
`

export const RecommendedJobsDescriptionContainer = styled.div`
  gap: ${Sizes['3XS']};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${Sizes.XS};
`

export const RecommendedJobsDescription = styled.span`
  color: ${Colors.Neutral90};
  ${Typography.Body};
  text-align: center;
  width: 100%;
  a {
    color: ${Colors.Blue50};
    cursor: pointer;
  }

  ${Media.Phone`
    width: 248px;
  `}
`

export const SearchResultsTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
  margin-bottom: ${Sizes['2XS']};
`

export const SearchResultsSubtitle = styled.div`
  margin-bottom: ${Sizes.S};
  color: ${Colors.Neutral50};
`

export const SearchForInsteadLink = styled.a`
  color: ${Colors.Blue50};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const HeadingContainerJobAlertButton = styled.div`
  flex-shrink: 0;
  align-items: center;
  display: flex;

  ${Media.Tablet`
    padding-top: 16px;
	  padding-bottom: 16px;
  `}
`
export const ManagerJobAlertInsideResults = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;
  flex-shrink: 0;
`

export const MissingInfoWrapper = styled.div`
  margin-bottom: ${Sizes.XS};
`
