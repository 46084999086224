import { apiClient, ApiResponse } from 'organizations/utils/apiClient'
import { RequestStatuses } from 'organizations/constants'
import { Dispatch, navigate } from 'organizations/store'
import type { Optional } from '@rio/types'
import { AccountCredentials, SerializedAccount } from './types'
import { actions } from './slice'

type FetchResponse = ApiResponse<{ account: SerializedAccount }>
type LoginResponse = ApiResponse<{ account: Optional<SerializedAccount> }>

/**
 * Fetches the account data via API and write it to the store.
 * If user is not signed in, this action will redirect him to the authorization page.
 */
export const fetchAccount = () => async (dispatch: Dispatch) => {
  try {
    const { data } = await apiClient.get<FetchResponse>('/auth/account')
    dispatch(actions.setUser(data.account))
  } catch (e) {
    navigate('/sign-in', { replace: true })
  }
}

/**
 * Attempts to sign the user in using the given credentials.
 */
export const login = (credentials: AccountCredentials) => async (dispatch: Dispatch) => {
  dispatch(actions.setLoginStatus(RequestStatuses.loading))
  dispatch(actions.setLoginError(null))

  const { data } = await apiClient.post<LoginResponse>('/auth/login', credentials)

  if (data.success && data.account) {
    dispatch(actions.setUser(data.account))
    navigate('/', { replace: true })
  } else {
    dispatch(actions.setLoginStatus(RequestStatuses.failed))
    dispatch(actions.setLoginError(data.errorMessage))
  }
}

/**
 * Logs a user out of the application and redirects to the login page.
 */
export const logout = () => async () => {
  await apiClient.delete('/auth/logout')
  window.location.assign('/orgs/sign-in')
}
