import { useConfig } from 'builder/hooks/useConfig'

export const useTailoringBannerTriggerConfig = () => {
  const config = useConfig()
  const roBannerTrigger = config?.features?.roBannerTrigger
  const availableToTriggerBannerType =
    roBannerTrigger && roBannerTrigger !== 'control' ? roBannerTrigger : null
  const isBannerTriggerAvailable = !!availableToTriggerBannerType

  return { availableToTriggerBannerType, isBannerTriggerAvailable }
}
