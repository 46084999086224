import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'

export const ToggleWrapper = styled.div<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? 'block' : 'none')};
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  margin-bottom: 24px;
`
export const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
`
export const VideoContainer = styled.div`
  position: relative;
`

export const Video = styled.video`
  flex-direction: column;
  max-width: 304px;
  border-radius: 10px;
  overflow: hidden;

  ${Media.Phone`
    max-width: 220px;
  `};
`

export const WebCamContainer = styled.div`
  position: absolute;
  bottom: 11px;
  left: 11px;
`

export const VideoBlank = styled.div`
  background: ${Colors.Neutral90};
  margin-top: 24px;
  width: 304px;
  height: 228px;
  border-radius: 10px;
  overflow: hidden;
`

export const IconContainer = styled.div`
  margin-right: 8px;
  & svg {
    fill: none;
  }
`

export const TestSoundText = styled.span`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Blue50};
  ${Media.Hover`
    &:hover {
      cursor: pointer;
    }
  `};
  padding-bottom: 16px;
  display: inline-block;
`

export const LineContainer = styled.div`
  padding: 24px 0;
`

export const Text = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  width: 304px;
  height: fit-content;
  border-radius: 10px;
  overflow: hidden;
  height: 24px;
  width: 100%;
  align-items: center;
  display: flex;
`

export const Hint = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  padding-top: 12px;
  width: 304px;
  height: fit-content;
  border-radius: 10px;
  overflow: hidden;
  color: ${Colors.Neutral50};
  text-align: center;
`

export const LargeButton = styled(Button)`
  width: 100%;
`

export const AllGoodButton = styled(LargeButton)`
  margin-top: 8px;
  width: 100%;
`

export const Line = styled.div`
  display: flex;
`

export const HelpImage = styled.img`
  margin: 16px 0;
`
