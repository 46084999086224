import { ChangeEventHandler, FormEventHandler, useContext, useState } from 'react'
import { UserDocumentTemplateId } from 'packages/types'
import { trackInternalEvent } from '@rio/tracking'
import Checkbox from 'builder/components/Checkbox'
import { TextField } from 'builder/components/TextField'
import { useI18n } from 'builder/hooks/useI18n'
import { validateEmail } from 'builder/components/SimpleEmailForm/utils/validateEmail'
import { apiClient } from 'builder/modules/apiClient'
import { navigate } from 'builder/modules/navigate'
import Button from 'builder/components/Button'
import { APP_BASE_PATH } from 'builder/modules/constants'
import { useSignUpCopyright } from 'builder/components/SignUpCopyright'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import ErrorLogger from 'builder/services/ErrorLogger'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { GuestResumeContext } from 'builder/context/GuestResumeContext'
import ModalOverlay from '../ModalOverlay'
import { FillByUrlParamValues, FILL_BY_URL_PARAM } from '../FillResumeModal/constants'
import RioCioImg from './assets/rio_cio.png'
import {
  Container,
  Form,
  ManualSection,
  ManualSectionEmailContainer,
  ManualSectionFooter,
  ManualSectionHeader,
  Copyright,
  Separator,
  SocialProfilesSection,
  Description,
  Title,
  CloseButton,
  SocialButtons,
  ConsentCheckboxWrapper,
  Modal,
  RightModalContainer,
  RioCioImage,
} from './styles'

type Props = {
  templateId: UserDocumentTemplateId
  prefillOption: FillByUrlParamValues
  onClose: () => void
}

export const SignUpBuilderGuestModal = (props: Props) => {
  const { templateId, onClose, prefillOption } = props
  const { i18n } = useI18n()
  const [isConsentChecked, setIsConsentChecked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [email, setEmail] = useState('')
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const copyrightMarkup = useSignUpCopyright({ isEmailOnly: true })
  const { isPhone } = useMediaQueries()
  const { resetContext } = useContext(GuestResumeContext)

  const logInWord = i18n.t('builder.sign_up.log_in')
  const logInLink = `<a href="${APP_BASE_PATH}/auth/sign-in">${logInWord}</a>`
  const logInMarkup = i18n.t('builder.sign_up.sign_up_guest_modal.already_have_account', {
    logInLink,
  })

  useEffectOnMount(() => {
    trackInternalEvent('show_sign_up_banner', { label: 'general' })
  })

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = event => {
    setEmailErrorMessage('')
    setEmail(event.target.value)
  }

  const onSignUpSubmit: FormEventHandler = async event => {
    event.preventDefault()

    if (!email) {
      setEmailErrorMessage(i18n.t('builder.sign_up.field_is_required'))
      return
    }

    setIsSubmitting(true)

    try {
      const { message } = await validateEmail(email, { allowAvailableOnly: true })

      if (message) {
        setIsSubmitting(false)
        setEmailErrorMessage(message)
        return
      }

      const { data } = await apiClient.post(`/sign-up/register`, {
        signUp: {
          email,
          confirm_email: isConsentChecked,
          showPrefillResumeModal: false,
        },
      })

      if (data.success) {
        const params = new URLSearchParams()
        params.append(FILL_BY_URL_PARAM, prefillOption)
        setIsSubmitting(false)
        resetContext()
        navigate(`/resumes/${data.firstDocumentId}/edit?${params}`, {
          replace: true,
        })
      }
    } catch (error) {
      ErrorLogger.log(error)
      setIsSubmitting(false)
    }
  }

  return (
    <ModalOverlay
      overlayFadeDuration={300}
      contentSlideDuration={300}
      onClick={onClose}
      fullScreen={isPhone}
    >
      <Modal>
        <Container>
          <Form onSubmit={onSignUpSubmit}>
            <ManualSection>
              <ManualSectionHeader>
                <Title>{i18n.t('builder.sign_up.sign_up_guest_modal.title')}</Title>
                <Description>
                  {i18n.t('builder.sign_up.sign_up_guest_modal.description')}
                </Description>
              </ManualSectionHeader>

              <ManualSectionEmailContainer>
                <TextField
                  label={i18n.t('builder.sign_up.email')}
                  type="email"
                  value={email || ''}
                  name="email"
                  onChange={onEmailChange}
                  error={emailErrorMessage}
                />
                <ConsentCheckboxWrapper>
                  <Checkbox
                    label={i18n.t('builder.sign_up.consent_checkbox_label')}
                    checked={isConsentChecked}
                    onChange={event => setIsConsentChecked(event.target.checked)}
                    name="confirm_email"
                  />
                </ConsentCheckboxWrapper>
              </ManualSectionEmailContainer>

              <ManualSectionFooter>
                <Button type="submit" isLoading={isSubmitting} isDisabled={isSubmitting}>
                  {i18n.t('builder.sign_up.sign_up_guest_modal.button_text')}
                </Button>

                <Description dangerouslySetInnerHTML={{ __html: logInMarkup }} />
              </ManualSectionFooter>
            </ManualSection>

            <Separator>{i18n.t('builder.sign_up.sign_up_guest_modal.separator_text')}</Separator>

            <SocialProfilesSection>
              <SocialButtons documentType="resume" templateId={templateId} />
              <Copyright dangerouslySetInnerHTML={{ __html: copyrightMarkup }} />
            </SocialProfilesSection>
          </Form>
        </Container>
        <RightModalContainer>
          <RioCioImage src={RioCioImg} />
        </RightModalContainer>
        <CloseButton onClick={onClose} />
      </Modal>
    </ModalOverlay>
  )
}
