export const QUESTION_DURATION = 5
export const QUESTION_SHOW_LIMIT = 3
export const POLLING_TIMEOUT_IN_MILLIS = 60 * 1000 // one minute
export const POLLING_REFRESH_INTERVAL_IN_MILLIS = 1000 // one second
export const ANNOUNCEMENT_POLLING_REFRESH_INTERVAL_IN_MILLIS = 500
export const MAX_QUESTION_TIME_DURATION_IN_SECONDS = 60 * 5 // five minutes
export const SHOW_DIALOG_RUNNING_OUT_TIME_IN_SECONDS = 60 // four minutes
export const ALL_INTERVAL_REFETCH = 10 * 1000
export const QUESTION_REGULAR_POLL_INTERVAL = 10 * 1000
export const RECORD_DATA_READY_INTERVAL = 3 * 1000
export const MIN_BLOB_SIZE = 5242880
export const LIMIT_QUESTIONS_ON_QUESTIONS_LIBRARY = 50
export const LIMIT_QUESTIONS_FREE_USER_SEES_ON_QUESTIONS_LIBRARY = 5
export const DELAY_FOR_FEEDBACK = 5 * 1000
export const DAILY_REGULAR_POLL_INTERVAL = 10 * 1000
export const TEST_INTERVIEW_QUESTIONS = 1
export const CUSTOM_INTERVIEW_QUESTIONS = 8
export const PARAM_EMAIL = 'email'
export const PARAM_JOB_TITLE_ID = 'jobTitleId'
export const PARAM_COMPANY_NAME = 'companyName'
export const PARAM_COMPANY_LOGO = 'companyLogo'
export const PARAM_JOB_TITLE_TEXT = 'jobTitleText'
export const PARAM_TYPE_OF_QUESTION = 'typeOfQuestions'
export const PARAM_IS_REAL_COMPANY = 'isRealCompany'
export const INTERVAL_MESSAGES_LENGTH = 3
export const EVENT_VOICE_ANIMATION_FOR_INTERVIEW_PREP = 'EVENT_VOICE_ANIMATION_FOR_INTERVIEW_PREP'
