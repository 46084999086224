import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Wrapper = styled.div`
  background: ${Colors.White};
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`

export const Image = styled.img`
  height: 255px;
  flex-shrink: 0;
`

export const PageTitle = styled.h2`
  padding-top: 32px;
  ${Typography.M};
  color: ${Colors.Neutral90};
  ${FontWeights.Medium};
`

export const JobTitle = styled.p`
  display: inline-flex;
  ${Typography.M};
  color: ${Colors.Neutral90};
  ${FontWeights.Medium};
  align-items: center;
  & strong {
    color: ${Colors.Neutral50};
    ${FontWeights.Medium};
  }
`

export const ButtonContainer = styled.div`
  padding-top: 32px;
`
