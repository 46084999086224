import { useDispatch } from 'react-redux'
import React, { useCallback, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useNavigate } from 'react-router-dom'
import Button, { ButtonTheme } from 'builder/components/Button'
import Icon24 from 'builder/components/Icon'
import Tooltip from 'builder/components/Tooltip'
import {
  actions,
  selectors as jobTrackingSelector,
  ColumnName,
  JobCreatePayload,
} from 'builder/modules/jobTracking'

import { stripHtml } from 'builder/utils/stripHTML'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import InterviewMock from 'builder/components/JobTracking/InterviewMock/InterviewMock'
import { usePortalNode } from 'builder/hooks/usePortalNode'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { useI18n } from 'builder/hooks/useI18n'
import CircleCheck from 'builder/components/FindJob/JobDetails/icon/CircleCheck.svg'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import { Job } from '../../JobSearchResult/types'
import TickImage from '../Shape.svg'
import HeartIconOutline from '../icon/HeartOutlineBlue.svg'
import HeartIconFilled from '../icon/HeartFilledBlue.svg'
import { TabType } from '../../types'
import { ApplyButtonIcon } from '../styles'
import {
  AppliedButton,
  CheckIcon,
  SaveButton,
  SaveJobButtonText,
  AutoAppliedButton,
  AutoAppliedIcon,
  ActionButtonsContainer,
  AutoApplyInfoContainer,
  AutoApplyInfoLink,
  AutoApplyInfoText,
} from './styles'

type Props = {
  job: Job & { position?: number }
  setApplyDialog: (showDialog: boolean) => void
  mobileVertical?: boolean
  selectedTabId?: TabType
  showSaveButton: boolean
}

const ActionButtons = React.forwardRef((props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { job, setApplyDialog, mobileVertical, selectedTabId, showSaveButton } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { i18n } = useI18n()
  const {
    isAutoApplyLimitReached,
    redirectToBillingPlans,
    isAutoApplyFeatureEnabled,
    redirectToAutoApplyQuestionnaire,
  } = useAutoApply()

  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)

  const portalNode = usePortalNode()
  const [isInterviewPopupOpen, setInterviewPopupOpen] = useState<boolean>(false)
  const alreadyAppliedJob = useTypedSelector(jobTrackingSelector.selectAlreadyAppliedJob)
  const newTrackerCard: JobCreatePayload = {
    status: ColumnName.wishlist,
    title: job.job_title,
    company: job.company,
    link: job.url,
    description: stripHtml(job.description),
    open: true,
    external_slug_id: job.external_slug_id,
    location: job.location,
    logo: job.company_logo,
  }

  // check if current job is added
  const cards = useTypedSelector(jobTrackingSelector.selectJobs)
  const jobTrackingCard = cards.find(
    card =>
      card.externalSlugId === newTrackerCard.external_slug_id &&
      card.status !== ColumnName.recommended,
  )
  const isJobAutoApplied = cards.find(
    card =>
      card.company === newTrackerCard.company &&
      card.title === newTrackerCard.title &&
      card.externalSlugId === job.external_slug_id &&
      (card.status === ColumnName.auto_apply || card.autoApplyStatus !== null),
  )

  const appliedCard = cards
    .filter(({ status }) => status === ColumnName.applied)
    .find(
      ({ company, title }) => company === newTrackerCard.company && title === newTrackerCard.title,
    )

  const handleAddJobBoard = () => {
    if (jobTrackingCard) {
      dispatch(actions.deleteJobRequest({ id: jobTrackingCard.id }))
    } else {
      trackInternalEvent('add_to_job_tracker', {
        title: job.job_title,
        label: 'save',
        position: job.position,
        parameter: selectedTabId,
      })
      const createPayload = { ...newTrackerCard, open: false, location: job.location }
      dispatch(actions.createJobRequest(createPayload))
    }
  }

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleApply = useCallback(() => {
    openInNewTab(job.url)
    trackInternalEvent('apply_for_job', {
      title: job.job_title,
      position: job.position,
      parameter: selectedTabId,
      label: 'job_search',
    })
    setApplyDialog(true)
  }, [job, setApplyDialog])

  const handleAutoApply = useCallback(() => {
    if (redirectToBillingPlans()) {
      return
    }
    if (redirectToAutoApplyQuestionnaire()) {
      return
    }
    if (isAutoApplyLimitReached()) {
      return
    }
    trackInternalEvent('add_to_job_tracker', {
      title: job.job_title,
      label: 'save',
      position: job.position,
      parameter: selectedTabId,
    })
    trackInternalEvent('auto_apply_for_job', {
      title: job.job_title,
      label: 'job_search',
      position: job.position,
      parameter: selectedTabId,
    })
    if (jobTrackingCard) {
      const patchPayload = {
        id: jobTrackingCard.id,
        status: ColumnName.auto_apply,
        dummy: autoApplyBubbles.running,
      }
      dispatch(actions.patchJobRequest(patchPayload))
    } else {
      const createPayload = {
        ...newTrackerCard,
        status: ColumnName.auto_apply,
        open: false,
        location: job.location,
        dummy: autoApplyBubbles.running,
      }
      dispatch(actions.createJobRequest(createPayload))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job, jobTrackingCard, autoApplyBubbles])

  const applyButtons = useMemo(() => {
    if (isAutoApplyFeatureEnabled) {
      const manualApplyButton =
        alreadyAppliedJob || appliedCard ? (
          <AppliedButton>
            <CheckIcon src={TickImage} />
            {i18n.t('builder.job_search.auto_apply.action_buttons.manual_applied')}
          </AppliedButton>
        ) : (
          <Button onClick={handleApply} theme={ButtonTheme.secondary}>
            <ApplyButtonIcon />
            {i18n.t('builder.job_search.auto_apply.action_buttons.manual_apply')}
          </Button>
        )

      const getAutoApplyButtonComponent = (disabled: boolean) => (
        <Button isDisabled={disabled} onClick={handleAutoApply} id="auto_apply_button">
          <Icon24.Simple style={{ marginRight: '4px', width: '18px' }} />
          {i18n.t('builder.job_search.auto_apply.action_buttons.auto_apply')}
        </Button>
      )

      const autoApplyButtonForNotAppliedJobs = job.is_easy_apply ? (
        <Tooltip
          textAlign="center"
          position="top"
          style={{
            display: 'inline-flex',
          }}
          multiline={true}
          value={i18n.t('builder.job_search.auto_apply_disabled')}
        >
          {getAutoApplyButtonComponent(true)}
        </Tooltip>
      ) : (
        getAutoApplyButtonComponent(false)
      )

      const autoApplyButton = isJobAutoApplied ? (
        <AutoAppliedButton>
          <AutoAppliedIcon src={CircleCheck} />
          {i18n.t('builder.job_search.auto_apply.action_buttons.applied')}
        </AutoAppliedButton>
      ) : (
        autoApplyButtonForNotAppliedJobs
      )

      return (
        <>
          {autoApplyButton}
          {manualApplyButton}
        </>
      )
    }

    if (alreadyAppliedJob || appliedCard) {
      return (
        <AppliedButton>
          <CheckIcon src={TickImage} />
          {i18n.t('builder.job_search.auto_apply.action_buttons.applied')}
        </AppliedButton>
      )
    }

    return (
      <Button onClick={handleApply}>
        <ApplyButtonIcon />
        {i18n.t('builder.job_search.auto_apply.action_buttons.apply')}
      </Button>
    )
  }, [
    alreadyAppliedJob,
    appliedCard,
    handleApply,
    handleAutoApply,
    i18n,
    isAutoApplyFeatureEnabled,
    isJobAutoApplied,
    job.is_easy_apply,
  ])

  return (
    <>
      <ActionButtonsContainer
        $flexBasis={isAutoApplyFeatureEnabled && job.is_easy_apply ? '33.3%' : '0'}
        $mobileVertical={mobileVertical}
        ref={ref}
      >
        {applyButtons}

        {showSaveButton && (
          <SaveButton
            theme={ButtonTheme.secondary}
            onClick={handleAddJobBoard}
            isDisabled={
              !!(
                jobTrackingCard &&
                isAutoApplyFeatureEnabled &&
                isJobAutoApplied &&
                jobTrackingCard.autoApplyStatus
              )
            }
          >
            <img src={jobTrackingCard ? HeartIconFilled : HeartIconOutline} />
            <SaveJobButtonText
              $isAutoApplyEnabled={isAutoApplyFeatureEnabled}
              $mobileVertical={mobileVertical}
            >
              {jobTrackingCard ? 'Saved' : 'Save Job'}
            </SaveJobButtonText>
          </SaveButton>
        )}
        {ReactDOM.createPortal(
          <InterviewMock
            visible={isInterviewPopupOpen}
            selectedJob={newTrackerCard}
            onClose={() => setInterviewPopupOpen(false)}
            trackingLabel="job_search"
          />,
          portalNode,
        )}
      </ActionButtonsContainer>
      {isJobAutoApplied && (
        <AutoApplyInfoContainer>
          <Icon24.Info />
          <AutoApplyInfoText>
            {i18n.t('builder.job_search.auto_apply.action_buttons.info_text')}{' '}
            <AutoApplyInfoLink onClick={() => navigate('/job-tracking')}>
              {i18n.t('builder.job_search.auto_apply.action_buttons.link_text')}
            </AutoApplyInfoLink>
          </AutoApplyInfoText>
        </AutoApplyInfoContainer>
      )}
    </>
  )
})

export default ActionButtons
