import { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

import ExploreAndTrackJobsPaywall from 'builder/components/ExploreAndTrackJobsPaywall'
import { DashboardLayout } from 'builder/components/DashboardLayout'
import { SideMenu } from 'builder/features/navigation/components/SideMenu/SideMenu'
import { NavBar } from 'builder/features/navigation/components/NavBar'
import { NavBarMobile } from 'builder/features/navigation/components/NavBarMobile'
import { MobileMenu } from 'builder/features/navigation/components/MobileMenu/MobileMenu'
import { DashboardTabs } from 'builder/features/navigation/components/NavBar/DashboardTabs'

import { ResumeDistributionPromoModal } from 'builder/components/ResumeDistributionPromoModal'
import { ResumeDistributionGetStartedModal } from 'builder/components/ResumeDistributionGetStartedModal'
import { AutoApplyCreditsModal } from 'builder/components/AutoApplyCreditsModal/AutoApplyCreditsModal'
import { ResumeDistributionGotCreditsModal } from 'builder/components/ResumeDistributionGotCreditsModal'
import { ResumeDistributionNotEnoughCreditsModal } from 'builder/components/ResumeDistributionNotEnoughCreditsModal'
import { AutoApplyUpsellModal } from 'builder/components/AutoApplyUpsellModal'
import { Container, Content } from './styles'

export const NavigationView = ({ children }: { children?: React.ReactNode }) => {
  const { isPhone, isTablet } = useMediaQueries()
  const { features } = useFeaturesConfig()
  const [isOpen, setIsOpen] = useState(false)
  const [isUserDropdownOpen, toggleUserDropdown] = useState(false)

  const location = useLocation()
  const prevLocation = useRef(location)

  // Close all dropdowns and popups when user navigates to another route
  useEffect(() => {
    if (prevLocation.current === location) return
    prevLocation.current = location
    toggleUserDropdown(false)
  }, [location])

  if (
    !(
      features.international ||
      features.superApp ||
      (features.dashboardV3 && !features.dashboardV2)
    )
  ) {
    return <DashboardLayout />
  }

  return (
    <>
      <AutoApplyUpsellModal />
      <AutoApplyCreditsModal />
      <ResumeDistributionPromoModal />
      <ResumeDistributionGetStartedModal />
      <ResumeDistributionNotEnoughCreditsModal />
      <ExploreAndTrackJobsPaywall />
      <ResumeDistributionGotCreditsModal />
      <NavBar isUserDropdownOpen={isUserDropdownOpen} onUserDropdownToggle={toggleUserDropdown} />
      <NavBarMobile />
      {isPhone && <MobileMenu />}
      <Container>
        {!isPhone && <SideMenu isOpen={isOpen} setIsOpen={setIsOpen} />}
        <Content isOpen={isOpen}>
          {!isPhone && !isTablet && features.dashboardNavigationTabs && <DashboardTabs />}
          {children || <Outlet />}
        </Content>
      </Container>
    </>
  )
}
