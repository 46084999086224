import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(247, 249, 252, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(15px); /* Adjust blur intensity */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1555;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  overflow: hidden;
`

export const Header = styled.div`
  ${FontWeights.Medium};
  ${Typography.Subhead};
  color: ${Colors.Neutral100};
`

export const Body = styled.div`
  ${FontWeights.Regular};
  ${Typography.Subhead};
  color: ${Colors.Neutral70};
  width: 356px;
  text-align: center;
  margin-top: 20px;
`

// Define the pulsating animation using keyframes
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
`

export const PulsatingCircle = styled.div`
  width: 100px;
  height: 100px;
  background-color: rgba(26, 145, 240, 0.5);
  border-radius: 50%;
  animation: ${pulse} 2s infinite ease-in-out;
  position: absolute;
  filter: blur(30px);
`
