import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  selectors,
  actions,
  AvailableAISuggestions,
  ResumeFieldSuggest,
  SectionNames,
} from 'builder/modules/resumeEditor'
import { getEmptyCard } from 'builder/modules/resumeEditor/resumeScoreRules/sectionRule'
import AnchorManager from 'builder/services/AnchorManager'
import { generateRandomId } from 'builder/utils/generateRandomId'
import { FetchStatuses } from 'builder/modules/constants'
import { useConfig } from 'builder/hooks/useConfig'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useKeywords } from 'builder/components/AiFeatures/Keywords'
import { camelToSnake } from 'builder/utils/camelToSnake'
import FocusManager from 'builder/services/FocusManager'
import { useAutoTailoring } from 'builder/components/AutoTailoring/hooks/useAutoTailoring'
import { useTailoringBannerTriggerConfig } from 'builder/hooks/useTailoringBannerTriggerConfig'
import { SuggestionTypeAnalytics } from '../constants'

const useImproveResumePanel = () => {
  const dispatch = useDispatch()
  const config = useConfig()
  const resume = useTypedSelector(selectors.resume)
  const { isPhone } = useMediaQueries()
  const { visibleSkills, visibleJobTitle } = useTypedSelector(selectors.jobPostingAPIData)
  const isLinkedToJobPosting = useTypedSelector(selectors.tailoredJobPostingId)
  const { firstName, lastName, email, workExperiences = [], skipOptimizerBanner } = resume || {}
  const resumeScore = resume?.resumeScore || 0
  const isImproveResumePanelOpen = useTypedSelector(selectors.isImproveResumePanelOpen)
  const isPageScrolled = useTypedSelector(selectors.isPageScrolled)
  const isResumeOptimizerFeatureEnabled = config?.features.resumeOptimizer
  const isAIProfileWriterActive = config?.features.aiProfileSummary
  const aiCoverLetters = config?.features.aiCoverLetters
  const isJobPostingDataFetched =
    useTypedSelector(state => state.resumeEditor.optimizerSection.fetchJobPostingDataStatus) ===
    FetchStatuses.loaded
  const isSuggestionsActive = resumeScore < 100
  const { allKeywords, addedKeywords, isKeywordsAvailable } = useKeywords()
  const score = useTypedSelector(selectors.resumeScore)
  const showAISuggestedJobTitleButton = !!visibleJobTitle
  const hasSkillsToTailor = !!visibleSkills.length
  const { showApplyAllButton, tryToStartAutoTailoring } = useAutoTailoring()
  const { isBannerTriggerAvailable } = useTailoringBannerTriggerConfig()

  const { summary: generatedContent } = useTypedSelector(selectors.aiProfile)
  const handleClosePanel = useCallback(() => {
    if (isImproveResumePanelOpen) {
      dispatch(actions.setIsImproveResumePanelOpen({ status: false }))
    }
  }, [dispatch, isImproveResumePanelOpen])

  const handleSuggestionClick = useCallback(
    (suggestion: ResumeFieldSuggest) => {
      const sectionName = suggestion.sectionName
      dispatch(actions.setIsImproveResumePanelOpen({ status: false }))
      if (suggestion.type === 'section' && sectionName) {
        const emptyCard = resume && getEmptyCard(sectionName, resume)
        if (emptyCard) {
          const cardId = emptyCard.cid || emptyCard.id
          dispatch(actions.openCard({ id: cardId, sectionId: sectionName }))
          AnchorManager.scrollToAnchor(cardId)
          FocusManager.focus(cardId)
        } else {
          dispatch(
            actions.addCard({
              sectionName,
              cardId: generateRandomId(),
              options: { scrollIntoViewport: true, shouldOpen: true },
            }),
          )
        }
        trackInternalEvent('click_suggestion_recommendations_panel', {
          tailored: !!isLinkedToJobPosting,
          score,
          suggestion_type: camelToSnake(sectionName),
        })
      }

      if (suggestion.type === 'simpleField' && suggestion.fieldId) {
        dispatch(actions.openSection(SectionNames.details))

        AnchorManager.scrollToAnchor(suggestion.sectionId || suggestion.fieldId)
        FocusManager.focus(suggestion.fieldId)

        trackInternalEvent('click_suggestion_recommendations_panel', {
          tailored: !!isLinkedToJobPosting,
          score,
          suggestion_type: camelToSnake(suggestion.fieldId),
        })
      }
    },
    [dispatch, isLinkedToJobPosting, resume, score],
  )

  const getSuggestionScore = useCallback(
    (suggestion: ResumeFieldSuggest) => {
      const requiredScore = 100 - resumeScore

      if (suggestion.points > requiredScore) {
        suggestion.points = requiredScore
      }
      if (isLinkedToJobPosting) {
        return suggestion.key === 'position_8' || suggestion.sectionName === 'skills'
          ? suggestion.points
          : Math.ceil(suggestion.points / 2)
      } else {
        return suggestion.points
      }
    },
    [resumeScore, isLinkedToJobPosting],
  )

  const handleToggleImproveResumePanel = useCallback(() => {
    if (!isImproveResumePanelOpen) {
      dispatch(actions.setIsImproveResumePanelOpen({ status: true }))
      trackInternalEvent('click_improve_resume_recommendations_panel', {
        tailored: !!isLinkedToJobPosting,
        score,
      })
    } else {
      dispatch(actions.setIsImproveResumePanelOpen({ status: false }))
      trackInternalEvent('click_close_recommendations_panel', {
        tailored: !!isLinkedToJobPosting,
        score,
      })
    }
  }, [dispatch, isImproveResumePanelOpen, isLinkedToJobPosting, score])

  const isAICoverLetterActive = useMemo(() => {
    if (!aiCoverLetters) {
      return false
    }

    if (!firstName || !lastName || !email) {
      return false
    }

    const hasWorkExperience =
      workExperiences.findIndex(
        ({ title, employer, dateFrom }) => title && employer && dateFrom,
      ) !== -1

    return hasWorkExperience
  }, [aiCoverLetters, email, firstName, lastName, workExperiences])

  const showResumeOptimizerBanner = useMemo(() => {
    return (
      isResumeOptimizerFeatureEnabled &&
      isJobPostingDataFetched &&
      !isLinkedToJobPosting &&
      (!isBannerTriggerAvailable || skipOptimizerBanner)
    )
  }, [
    isJobPostingDataFetched,
    isLinkedToJobPosting,
    isResumeOptimizerFeatureEnabled,
    isBannerTriggerAvailable,
    skipOptimizerBanner,
  ])

  const handleClickCoverLetter = useCallback(() => {
    handleClosePanel()
    dispatch(actions.setIsClickedOnAiCVBanner(true))
    trackInternalEvent('click_suggestion_recommendations_panel', {
      tailored: !!isLinkedToJobPosting,
      score,
      suggestion_type: 'ai_cover_letter',
    })
  }, [dispatch, handleClosePanel, isLinkedToJobPosting, score])

  const handleClickAIWriteProfileSummary = useCallback(() => {
    handleClosePanel()
    if (generatedContent) {
      dispatch(actions.setIsAIProfileSummaryResultOpen({ status: true }))
      dispatch(actions.setAIProfileResultContentAnimation(false))
    } else {
      dispatch(actions.setIsHelpWithWritingOpen({ status: true }))
    }
    trackInternalEvent('click_suggestion_recommendations_panel', {
      tailored: !!isLinkedToJobPosting,
      score,
      suggestion_type: 'ai_profile_summary',
    })
    AnchorManager.scrollToAnchor('profile')
  }, [handleClosePanel, generatedContent, isLinkedToJobPosting, score, dispatch])

  const hintTooltipTextKey = useMemo(() => {
    return isImproveResumePanelOpen
      ? 'builder.resume_editor.keywords.improve_resume_panel.hint.opened_panel_hint_text'
      : 'builder.resume_editor.keywords.improve_resume_panel.hint.closed_panel_hint_text'
  }, [isImproveResumePanelOpen])

  const handleAddTailored = useCallback(
    type => {
      handleClosePanel()
      let suggestionType
      if (type in SuggestionTypeAnalytics) {
        suggestionType = SuggestionTypeAnalytics[type as keyof typeof SuggestionTypeAnalytics]
      } else {
        suggestionType = type
      }

      if (type === AvailableAISuggestions.skills || type === AvailableAISuggestions.jobTitle) {
        dispatch(actions.setOpenedAISuggestionsPopup(type))
      } else {
        dispatch(actions.setOpenedKeywordsModalType(type))
      }
      trackInternalEvent('click_suggestion_recommendations_panel', {
        tailored: !!isLinkedToJobPosting,
        score,
        suggestion_type: `${camelToSnake(suggestionType)}_tailored`,
      })
    },
    [dispatch, handleClosePanel, isLinkedToJobPosting, score],
  )

  const keywordsCount = useMemo(() => {
    return isKeywordsAvailable ? `${addedKeywords.length}/${allKeywords.length}` : null
  }, [addedKeywords.length, allKeywords.length, isKeywordsAvailable])

  const handleApplyAll = useCallback(() => {
    tryToStartAutoTailoring()

    trackInternalEvent('click_apply_all_auto_tailoring', {
      label: 'recommendations_panel',
    })
  }, [tryToStartAutoTailoring])

  return {
    handleSuggestionClick,
    getSuggestionScore,
    handleToggleImproveResumePanel,
    isImproveResumePanelOpen,
    isPageScrolled,
    showResumeOptimizerBanner,
    isAICoverLetterActive,
    handleClickCoverLetter,
    isAIProfileWriterActive,
    handleClickAIWriteProfileSummary,
    hintTooltipTextKey,
    handleAddTailored,
    isSuggestionsActive,
    isPhone,
    handleClosePanel,
    keywordsCount,
    hasSkillsToTailor,
    showAISuggestedJobTitleButton,
    handleApplyAll,
    isResumeOptimizerFeatureEnabled,
    showApplyAllButton,
    isLinkedToJobPosting,
  }
}

export default useImproveResumePanel
