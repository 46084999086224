import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { SearchSuggestionType } from '../AutoSuggestField/types'
import { SearchQuery } from '../JobSearchResult/types'
import { useGetJob } from './hooks/useGetJob'

import { JobAlertPage } from './JobAlertPage'
import { useGetRecommendationJob } from './hooks/useGetRecommendationJob'
import { useGetSimilarJobs } from './hooks/useGetSimilarJobs'

export const RecommendJobAlertPage = () => {
  const { externalSlugId } = useParams()
  const job = useGetJob(externalSlugId)
  const [nearMeLocation, setNearMeLocation] = useState<SearchSuggestionType | null>()

  const recommendations = useGetRecommendationJob(
    typeof nearMeLocation === 'object' || nearMeLocation == null,
  )
  const [params, setParams] = useState<Omit<SearchQuery, 'correct_term' | 'view'> | undefined>()

  const similarJobs = useGetSimilarJobs(params)

  useEffect(() => {
    // fallback
    if (job.data && recommendations.error && !params) {
      setParams({
        query: job.data?.job_title,
        location_id: job.data?.location_id ? String(job.data?.location_id) : undefined,
        external_slug_id: externalSlugId,
      })
    }
  }, [job.data, recommendations, params, externalSlugId])

  const jobs = recommendations.error ? similarJobs : recommendations

  return (
    <JobAlertPage
      job={job.data}
      cardJobs={jobs}
      nearMeLocation={nearMeLocation}
      setNearMeLocation={setNearMeLocation}
    />
  )
}
