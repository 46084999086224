import { useNavigate } from 'react-router-dom'
import { EventCode, trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { ButtonTheme } from 'builder/components/Button'
import { actions, selectors } from 'builder/modules/careerCoaching'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { trackingInfo, avatarUrl } from 'builder/modules/careerCoaching/constants'
import { MouseClickEvent } from 'builder/modules/ui'
import { NoCoachAvailable } from '../NoCoachAvailable'
import ContentFailedToDownload from '../ContentFailedToDownload'
import { getPhrase, getPackageValidity } from '../../utils'

import coachStar from '../../assets/img/coach_star.svg'
import {
  CoachDetails,
  ActionDetails,
  CoachImageWrapper,
  CoachStar,
  CoachCaption,
  CoachAvatar,
  Heading,
  Caption,
  Star,
  StyledButton,
  SessionLeft,
  CoachName,
  WarningCaption,
} from './styles'

const sessionButtons = [
  'Message Coach',
  'Try another coach',
  'Request a Session',
  'Book again',
  'Find a Coach',
]

export const CoachAvailable = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const coaches = useTypedSelector(selectors.coaches)
  const { status, response } = useTypedSelector(selectors.coachingSessions)
  const {
    upcoming: upcomingSessions,
    coach_details: coachDetails,
    package_details: packageDetails,
  } = response

  if (!status) {
    return <ContentFailedToDownload />
  }

  if (!Object.keys(coachDetails).length) {
    return (
      <NoCoachAvailable coachesAvailable={coaches.length > 0} {...{ navigate, packageDetails }} />
    )
  }

  const { email, avatar, name: coachName, coach_availability: isCoachAvailable } = coachDetails

  const {
    total_sessions: totalSessions,
    expiration_date: expirationDate,
    remaining_sessions: remainingSessions,
  } = packageDetails

  const getEventConfig = (innerText: string) => {
    let config = {
      name: '',
      sendCoachName: false,
    }

    switch (innerText) {
      case sessionButtons[0]:
        config = {
          name: 'click_message_coach_button',
          sendCoachName: true,
        }
        break
      case sessionButtons[1]:
        config = {
          name: 'click_try_another_coach',
          sendCoachName: true,
        }
        break
      case sessionButtons[2]:
        config = {
          name: 'click_request_session_button',
          sendCoachName: true,
        }
        break
      case sessionButtons[3]:
        config = {
          name: 'click_book_again',
          sendCoachName: true,
        }
        break
      default:
        config = {
          name: 'click_find_a_coach',
          sendCoachName: false,
        }
    }

    return config
  }

  const dispatchEvent = (innerText: string) => {
    const eventConfig = getEventConfig(innerText)

    if (eventConfig.sendCoachName) {
      trackInternalEvent(eventConfig.name as EventCode, {
        ...trackingInfo,
        coach_name: coachName,
      })

      return
    }

    trackInternalEvent(eventConfig.name as EventCode, {
      ...trackingInfo,
    })
  }

  const handleClick = (e: MouseClickEvent) => {
    const target = e.target as HTMLButtonElement

    switch (target.innerText) {
      case sessionButtons[0]:
      case sessionButtons[2]: {
        window.open(`mailto:${email}`, '_self')
        dispatchEvent(target.innerText)
        break
      }

      case sessionButtons[1]:
      case sessionButtons[4]: {
        dispatch(actions.setUserRedirectState(false))
        navigate('/onboarding-form')
        dispatchEvent(target.innerText)
        break
      }

      case sessionButtons[3]: {
        navigate('/packages?on_success_redirect=/app/sessions&payment_reason=book_again_coach')
        dispatchEvent(target.innerText)
        break
      }
    }
  }

  const getNextButton = () => {
    if (!remainingSessions) {
      return <StyledButton onClick={handleClick}>{sessionButtons[3]}</StyledButton>
    }

    if (remainingSessions < +totalSessions && !upcomingSessions.length) {
      return <StyledButton onClick={handleClick}>{sessionButtons[2]}</StyledButton>
    }

    return null
  }

  return (
    <>
      <CoachDetails>
        <CoachImageWrapper>
          <CoachAvatar src={avatar || avatarUrl} />
          <CoachStar>
            <Star src={coachStar} />
          </CoachStar>
        </CoachImageWrapper>
        <CoachCaption>
          <Heading>
            <SessionLeft>
              {remainingSessions} {getPhrase(remainingSessions)} left with
            </SessionLeft>{' '}
            <CoachName>{coachName}</CoachName>
          </Heading>
          {isCoachAvailable ? (
            <Caption>
              {/* NOTE: DO NOT ADD EMPTY SPACE */}
              {totalSessions} {getPhrase(+totalSessions)} package
              {getPackageValidity(expirationDate)}
            </Caption>
          ) : (
            <WarningCaption>Coach is no longer available for booking</WarningCaption>
          )}
        </CoachCaption>
      </CoachDetails>
      {isCoachAvailable ? (
        <ActionDetails>
          <StyledButton theme={ButtonTheme.ghost} onClick={handleClick}>
            {remainingSessions ? sessionButtons[0] : sessionButtons[1]}
          </StyledButton>
          {getNextButton()}
        </ActionDetails>
      ) : (
        <StyledButton onClick={handleClick}>{sessionButtons[4]}</StyledButton>
      )}
    </>
  )
}
