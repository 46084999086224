import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import Button, { ButtonSize } from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { stripHtml } from 'builder/utils/stripHTML'
import { selectors as userSelectors } from 'builder/modules/user'
import { useConfig } from 'builder/hooks/useConfig'
import { actions, ColumnName, JobCreatePayload, selectors } from 'builder/modules/jobTracking'
import { useI18n } from 'builder/hooks/useI18n'
import { Job } from '../FindJob/JobSearchResult/types'
import {
  ButtonContainer,
  Close,
  Container,
  Description,
  DescriptionSection,
  Header,
  SubTitle,
  Title,
} from './styles'

type PaywallProps = {
  job: Job & { position?: number }
  applyDialog: boolean
  setApplyDialog: (val: boolean) => void
  setLimitPaywallOpen: (val: boolean) => void
  parameterTracking: 'search' | 'recommendation'
}

const translationKey = 'builder.job_search.apply_modal'

const ApplyModal: React.FC<PaywallProps> = ({
  job,
  applyDialog,
  setApplyDialog,
  setLimitPaywallOpen,
  parameterTracking,
}) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()

  const user = useSelector(userSelectors.userData)
  const config = useConfig()
  const isSuperApp = config?.features.superApp
  const isFreePlan = !user?.hasPremiumFeatures && isSuperApp

  // Get Count to check for 3rd and 10th job save
  const appliedCount = useTypedSelector(selectors.selectAppliedCount) + 1

  const newTrackerCard: JobCreatePayload = {
    status: ColumnName.applied,
    title: job.job_title,
    company: job.company,
    link: job.url,
    description: stripHtml(job.description),
    open: false,
    external_slug_id: job.external_slug_id,
    location: job.location,
    logo: job.company_logo,
  }

  const cards = useTypedSelector(selectors.selectJobs)
  const isJobTrackingCard = cards.find(
    card =>
      card.company === newTrackerCard.company &&
      card.title === newTrackerCard.title &&
      card.status === ColumnName.wishlist,
  )

  const handleAddToTracker = () => {
    setApplyDialog(false)

    if (isFreePlan && appliedCount > 3) {
      setLimitPaywallOpen(true)
      return true
    }

    trackInternalEvent('add_to_job_tracker', {
      title: job.job_title,
      position: job.position,
      label: 'job_search',
      parameter: parameterTracking,
    })
    dispatch(actions.createJobRequest(newTrackerCard))
  }

  const handleCancel = () => {
    trackInternalEvent('close_apply_banner', { label: 'search' })
    setApplyDialog(false)
  }

  useEffect(() => {
    trackInternalEvent('view_apply_banner', { label: 'search' })
  }, [])

  if (!applyDialog) {
    return null
  }
  const ButtonText = isJobTrackingCard
    ? i18n.t(`${translationKey}.move_to_applied_button`)
    : i18n.t(`${translationKey}.add_button`)

  return (
    <ModalOverlay overlayFadeDuration={150} contentSlideDuration={150}>
      <Container>
        <Close onClick={handleCancel}>
          <Icon20.Close />
        </Close>
        <DescriptionSection>
          <Description>
            <Header>
              <Title>{i18n.t(`${translationKey}.title`)}</Title>
            </Header>
            <SubTitle>{i18n.t(`${translationKey}.subtitle`)}</SubTitle>
          </Description>
          <ButtonContainer>
            <Button
              size={!isPhone ? ButtonSize.default : ButtonSize.small}
              theme="ghost"
              onClick={handleCancel}
            >
              {i18n.t(`${translationKey}.cancel_button`)}
            </Button>
            <Button
              size={!isPhone ? ButtonSize.default : ButtonSize.small}
              onClick={handleAddToTracker}
            >
              {ButtonText}
            </Button>
          </ButtonContainer>
        </DescriptionSection>
      </Container>
    </ModalOverlay>
  )
}

export default ApplyModal
