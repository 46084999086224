import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { InterviewPlayerBadge, InterviewPlayerModalType } from 'builder/modules/interview/types'
import { actions } from 'builder/modules/interview/interviewModule'

export function useTimer(
  running: boolean,
  questionId: number | undefined,
  setInterviewPlayerBadge: React.Dispatch<React.SetStateAction<InterviewPlayerBadge | undefined>>,
  runOutOfTime: () => void,
) {
  const dispatch = useDispatch()

  const [totalSeconds, setTotalSeconds] = useState(0)
  const [questionSeconds, setQuestionSeconds] = useState(0)

  const runOutOfTimeCBRef = useRef<() => void>()
  runOutOfTimeCBRef.current = runOutOfTime

  // when the question is change
  useEffect(() => {
    setQuestionSeconds(0)
    setInterviewPlayerBadge(undefined)
  }, [questionId])

  // when the timer is running
  useEffect(() => {
    let intervalIdForTotalSeconds: NodeJS.Timeout
    let intervalIdForQuestionSeconds: NodeJS.Timeout
    if (running) {
      intervalIdForTotalSeconds = setInterval(() => setTotalSeconds(s => s + 1), 1000)
      intervalIdForQuestionSeconds = setInterval(() => setQuestionSeconds(s => s + 1), 1000)
    }

    return () => {
      intervalIdForTotalSeconds && clearInterval(intervalIdForTotalSeconds)
      intervalIdForQuestionSeconds && clearInterval(intervalIdForQuestionSeconds)
    }
  }, [running])

  // Time side effect show modal, show badge
  useEffect(() => {
    if (!running) {
      return
    }
    const MINUTES_IN_SECONDS = 60

    if (questionSeconds === 10 * MINUTES_IN_SECONDS && runOutOfTimeCBRef.current) {
      runOutOfTimeCBRef.current()
    } else if (questionSeconds === 9 * MINUTES_IN_SECONDS) {
      dispatch(actions.setInterviewPlayerModal(InterviewPlayerModalType.session_modal))
    } else if (questionSeconds >= 4 * MINUTES_IN_SECONDS) {
      setInterviewPlayerBadge(InterviewPlayerBadge.hurry_up)
    } else if (questionSeconds >= 1 * MINUTES_IN_SECONDS) {
      setInterviewPlayerBadge(InterviewPlayerBadge.great_timing)
    }
  }, [questionSeconds])

  return {
    time: totalSeconds,
  }
}
