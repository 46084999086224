import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon20, Icon24 } from 'builder/components/Icon'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from '../Button'

export const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  left: 0;
  background: #000000ba;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
`

export const RootModalContainer = styled.div`
  background-color: ${Colors.White};
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 528px;
  max-height: 100%;

  ${Media.Tablet`
    max-width: 528px;
  `}

  ${Media.Phone`
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
  `}
`

export const ModalSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 24px;
  padding: 32px 40px;
  position: relative;

  ${Media.Tablet`
    width: 100%;
    font-size: 21px;
  `}

  ${Media.Phone`
    width: 100%;
    padding: 16px 20px;
    overflow: auto;
  `}
`

export const CloseIcon = styled(Icon24.CloseBig)`
  display: block;
  color: ${Colors.Neutral50};
  transition: color 0.3s;
  padding: 2px;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const Close = styled.div`
  display: flex;
  justify-self: flex-end;
  right: 0;
  height: fit-content;
  width: fit-content;
  background: ${Colors.White};
  border-radius: 32px;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${Colors.Blue10};
  }

  ${Media.Phone`
    margin: 20px;
  `}
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;

  ${Media.Phone`
    top: 2px;
    right: 0;
    z-index: 99999;
  `}
`

export const UpsellHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  width: 80%;

  ${Media.Phone`
    gap: 8px;
    width: 100%;
    text-align: center;
    align-items: center;
  `}
`

export const UpsellTitle = styled.h2`
  ${Typography.M}
  ${FontWeights.DemiBold}
  
  color: ${Colors.Neutral90};
`

export const UpsellSubTitle = styled.h2`
  ${Typography.Caption}
  ${FontWeights.Regular}
  
  color: ${Colors.Neutral50};
`

export const UpsellFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const FeaturesHeader = styled.h2`
  ${Typography.Caps}
  ${FontWeights.DemiBold}
  
  color: ${Colors.Neutral50};
`

export const UpsellFeatures = styled.ul`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  list-style: none;
  margin: 0;
  padding: 16px 48px 16px 16px;
  background-color: ${Colors.Neutral5};
`

export const UpsellFeature = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
  
  color: ${Colors.Neutral90};
  display: flex;
  gap: 6px;
`

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
`

export const TickIcon = styled(Icon20.Tick)`
  color: ${Colors.Green40};
`

export const NavBarContainer = styled.div`
  top: 0;
  position: sticky;
`
export const UpgradeContainer = styled.div`
  padding: 8px 20px 16px;
  position: sticky;
  bottom: 0;
  background: ${Colors.White};
`

export const UpgradeButton = styled(Button)`
  width: 100%;
`
