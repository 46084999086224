import styled, { keyframes } from 'styled-components'
import Media from 'builder/styles/media'
import Icon, { Icon24 } from 'builder/components/Icon'
import { ButtonProps } from '../../types'

const rotation = keyframes`
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
`

const ButtonContent = styled.span`
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  gap: inherit;
`

const Spinner = styled(Icon24.Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: ${rotation} 0.5s infinite linear;
`

const Revert = styled(Icon.Revert)`
  position: absolute;
  left: 10%;

  ${Media.Phone`
    width: 19px;
    height: 19px;
    left: 7px;
  `}
`

export const BaseButton = (props: ButtonProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, isLoading, isDisabled, size, theme, as, isTryAgain, ...otherProps } = props
  const Component = as || 'button'

  return (
    <Component {...otherProps}>
      {isTryAgain && <Revert />}
      <ButtonContent style={{ opacity: isLoading ? 0 : 1, marginLeft: isTryAgain ? '15px' : 0 }}>
        {children}
      </ButtonContent>
      {isLoading && <Spinner />}
    </Component>
  )
}
