import { FC, memo } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import Icon from 'builder/components/Icon'
import MobileSlideModal from 'builder/components/MobileSlideModal'

import {
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalTitle,
  ModalCaption,
  ModalCloseButton,
  ModalDoneButton,
} from './styles'

type Props = {
  isOpen?: boolean
  onClose?: () => void
  title?: string
  caption?: string
}

export const CardModal: FC<Props> = memo(props => {
  const { isOpen, title, caption, onClose, children } = props
  const { i18n } = useI18n()

  return (
    <MobileSlideModal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton onClick={onClose}>
            <Icon.Chevron />
          </ModalCloseButton>

          <ModalTitle>{title}</ModalTitle>
          <ModalCaption>{caption}</ModalCaption>
        </ModalHeader>

        {children}
      </ModalContent>

      <ModalFooter>
        <ModalDoneButton onClick={onClose}>{i18n.t('builder.resume_editor.done')}</ModalDoneButton>
      </ModalFooter>
    </MobileSlideModal>
  )
})
