import { useEffect, useState, useRef, useCallback, Fragment } from 'react'

import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Resume } from '@rio/types'
import { trackInternalEvent } from '@rio/tracking'

import { AsyncAutosuggest, createSuggestionsApiFetcher } from 'builder/components/AsyncAutosuggest'
import AvatarEditorModal from 'builder/components/AvatarEditorModal'
import EditorField from 'builder/components/EditorField'
import EditorRow from 'builder/components/EditorRow'
import Foldable from 'builder/components/Foldable'
import PhotoUploadField from 'builder/components/PhotoUploadField'
import SectionTitle from 'builder/components/SectionTitle'
import { SectionBodyFoldableContainer, SectionHeader } from 'builder/components/Section'
import { TunerSectionTooltips } from 'builder/components/Tuner'
import { TextField } from 'builder/components/TextField'

import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import FocusManager from 'builder/services/FocusManager'

import { actions as uiActions } from 'builder/modules/ui'
import {
  actions as editorActions,
  selectors as editorSelectors,
  AvatarUploadPayload,
  SectionNames,
  AvailableAISuggestions,
} from 'builder/modules/resumeEditor'
import {
  Container,
  ShowButton,
  ShowButtonText,
  ShowButtonIcon,
  AdditionalFields,
} from 'builder/components/PersonalDetailsSection/styles'
import { AISuggestionsToggler } from 'builder/components/AiFeatures/Suggestions'
import { displayFieldPerTemplateConfig } from '../builderSectionsConfig'
import { FieldObject, FieldType, PersonalDetailRow } from './types'
import { isAJSXElement } from './helpers'

type Props = {
  resume: Resume
  isFoldableSectionsEnabled?: boolean
}

export const PersonalDetailsSection = (props: Props) => {
  const { resume, isFoldableSectionsEnabled } = props
  const dispatch = useDispatch()

  const { i18n } = useI18n()
  const templates = useTypedSelector(editorSelectors.templates)
  const { isUploadingPhoto } = useTypedSelector(state => state.resumeEditor)
  const isOnline = useTypedSelector(state => state.application.isOnline)

  const container = useRef<HTMLDivElement | null>(null)
  const [areAdditionalFieldsVisible, setAdditionalFieldsVisibility] = useState(false)
  const [isAvatarEditorVisible, setAvatarEditorVisibility] = useState(false)

  const { locale, sectionTitles } = resume
  const currentTemplate = templates.find(template => template.id === resume.template)
  const supportsPhoto = !!currentTemplate?.supportsPhoto
  const { isPhone } = useMediaQueries()
  const openedSection = useTypedSelector(editorSelectors.openedSection)
  const isOpened = openedSection === SectionNames.details
  const foldableProps = { isFoldableSectionsEnabled, isOpened }

  // Connect to FocusManager
  useEffect(() => {
    const focus = (name: string) => {
      const targetField = container.current?.querySelector<HTMLInputElement>(`[name=${name}]`)
      if (targetField) targetField.focus()
    }

    const focusableFieldNames: Array<keyof Resume> = ['position', 'email', 'phoneNumber']
    focusableFieldNames.forEach(name => FocusManager.subscribe(name, () => focus(name)))
    return () => focusableFieldNames.forEach(name => FocusManager.unsubscribe(name))
  }, [])

  // Disable photo uploader window if user is offline
  const handleTogglePhotoUploader = useCallback(() => {
    if (!isOnline) return dispatch(uiActions.setConnectionLostSnackbarOpen(true))
    setAvatarEditorVisibility(value => !value)
  }, [dispatch, isOnline])

  // Handle avatar editor payload
  const handleUploadPhoto = useCallback(
    (payload: AvatarUploadPayload) => {
      dispatch(editorActions.uploadPhoto(payload))
      trackInternalEvent('change_avatar')
      setAvatarEditorVisibility(false)
    },
    [dispatch],
  )

  // Clear resume avatar
  const handleDeletePhoto = useCallback(() => {
    if (!window.confirm(i18n.t('builder.resume_editor.delete_photo_confirm'))) return
    dispatch(editorActions.deletePhoto())
    trackInternalEvent('delete_avatar')
  }, [dispatch, i18n])

  // Handle input values update
  const handleSimpleFieldUpdate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(editorActions.updateSimpleField({ name: name as any, value, debounce: true }))
    },
    [dispatch],
  )

  // Set custom section name
  const handleSectionRename = useCallback(
    (value: string | null) => {
      dispatch(editorActions.renameSection({ id: SectionNames.details, value }))
    },
    [dispatch],
  )

  const handleToggle = useCallback(() => {
    dispatch(editorActions.openSection(isOpened ? null : SectionNames.details))
  }, [dispatch, isOpened])

  const personalDetailsRows = [
    {
      index: 0,
      items: [
        {
          label: i18n.t('builder.resume_editor.job_title'),
          name: 'position',
          type: FieldType.ASYNC,
          value: resume.position || '',
          placeholder: i18n.t('builder.resume_editor.job_title_placeholder'),
          hint: i18n.t('builder.resume_editor.job_title_hint'),
          badge: <AISuggestionsToggler type={AvailableAISuggestions.jobTitle} />,
          fetchItems: createSuggestionsApiFetcher('job-title', { limit: 4, locale }),
        },
        <>
          <PhotoUploadField
            imageUrl={resume.avatar.blank ? '' : resume.avatar.thumbUrl}
            isUploading={isUploadingPhoto}
            isLocked={!supportsPhoto}
            onUpload={handleUploadPhoto}
            onDelete={handleDeletePhoto}
            onClick={isPhone ? null : handleTogglePhotoUploader}
          />
          {isAvatarEditorVisible && !isPhone && (
            <AvatarEditorModal
              avatar={resume.avatar}
              onSubmit={handleUploadPhoto}
              onClose={handleTogglePhotoUploader}
            />
          )}
        </>,
      ],
    },
    {
      index: 1,
      items: [
        {
          label: i18n.t('builder.resume_editor.first_name'),
          name: 'firstName',
          type: FieldType.TEXT,
          value: resume.firstName || '',
        },
        {
          label: i18n.t('builder.resume_editor.last_name'),
          name: 'lastName',
          type: FieldType.TEXT,
          value: resume.lastName || '',
        },
      ],
    },
    {
      index: 2,
      items: [
        {
          label: i18n.t('builder.resume_editor.email'),
          name: 'email',
          type: FieldType.EMAIL,
          value: resume.email || '',
        },
        {
          label: i18n.t('builder.resume_editor.phone'),
          name: 'phoneNumber',
          type: FieldType.PHONE,
          value: resume.phoneNumber || '',
        },
      ],
    },
    {
      index: 3,
      items: [
        {
          label: i18n.t('builder.resume_editor.country'),
          name: 'countryName',
          type: FieldType.ASYNC,
          value: resume.countryName || '',
          fetchItems: createSuggestionsApiFetcher('country', { locale }),
        },
        {
          label: i18n.t('builder.resume_editor.city'),
          name: 'city',
          type: FieldType.ASYNC,
          value: resume.city || '',
          fetchItems: createSuggestionsApiFetcher('city', {
            locale,
            country: resume.countryName || '',
          }),
        },
      ],
    },
    {
      index: 4,
      items: [
        {
          label: i18n.t('builder.resume_editor.address'),
          name: 'address',
          type: FieldType.TEXT,
          value: resume.address || '',
        },
        {
          label: i18n.t('builder.resume_editor.postal_code'),
          name: 'postalCode',
          type: FieldType.TEXT,
          value: resume.postalCode || '',
        },
      ],
    },
    {
      index: 5,
      items: [
        {
          label: i18n.t('builder.resume_editor.driving_license'),
          name: 'drivingLicense',
          type: FieldType.TEXT,
          value: resume.drivingLicense || '',
          hint: i18n.t('builder.resume_editor.driving_license_hint'),
        },
        {
          label: i18n.t('builder.resume_editor.nationality'),
          name: 'nationality',
          type: FieldType.ASYNC,
          value: resume.nationality || '',
          hint: i18n.t('builder.resume_editor.nationality_hint'),
          fetchItems: createSuggestionsApiFetcher('nationality', { locale }),
        },
      ],
    },
    {
      index: 6,
      items: [
        {
          label: i18n.t('builder.resume_editor.birth_place'),
          name: 'birthPlace',
          type: FieldType.TEXT,
          value: resume.birthPlace || '',
        },
        {
          label: i18n.t('builder.resume_editor.birth_date'),
          name: 'birthDate',
          type: FieldType.TEXT,
          value: resume.birthDate || '',
          hint: i18n.t('builder.resume_editor.birth_date_hint'),
        },
      ],
    },
    {
      index: 7,
      items: [
        {
          label: 'Linkedin',
          name: 'linkedin',
          type: FieldType.TEXT,
          value: resume.linkedin || '',
        },
      ],
    },
  ] as PersonalDetailRow[]

  const getRowItemComponent = (item: FieldObject) => {
    const { label, name, type, value, placeholder, hint, fetchItems } = item
    switch (type) {
      case FieldType.TEXT:
      case FieldType.EMAIL:
      case FieldType.PHONE:
        return (
          <TextField
            {...{ label, type, value, name, onChange: handleSimpleFieldUpdate, placeholder, hint }}
          />
        )
      case FieldType.ASYNC:
        return (
          <AsyncAutosuggest
            highlightedQuery
            {...{ name, fetchItems, value, onChange: handleSimpleFieldUpdate }}
          >
            {inputProps => <TextField {...inputProps} {...{ label, placeholder, hint }} />}
          </AsyncAutosuggest>
        )
    }
  }

  const renderRowItemsPerTemplateConfig = ({ index, items }: PersonalDetailRow) => {
    const itemOne = items[0]
    const itemTwo = items[1] ? items[1] : null
    const itemNames: string[] = []
    !isAJSXElement(itemOne) && itemNames.push((itemOne as FieldObject).name)
    itemTwo && !isAJSXElement(itemTwo) && itemNames.push((itemTwo as FieldObject).name)

    if (
      itemNames.some(field =>
        displayFieldPerTemplateConfig(resume.template, SectionNames.details, field),
      )
    ) {
      return (
        <EditorRow key={index}>
          <EditorField>
            {isAJSXElement(itemOne) ? itemOne : getRowItemComponent(itemOne as FieldObject)}
          </EditorField>
          {itemTwo ? (
            <EditorField>
              {isAJSXElement(itemTwo) ? itemTwo : getRowItemComponent(itemTwo as FieldObject)}
            </EditorField>
          ) : (
            <></>
          )}
        </EditorRow>
      )
    }
    return <Fragment key={index} />
  }

  return (
    <Fragment>
      <Container ref={container} {...foldableProps}>
        {/* Section header */}
        <SectionHeader {...foldableProps} onClick={handleToggle}>
          <SectionTitle
            title={i18n.t('builder.resume_editor.personal_details')}
            customTitle={sectionTitles.details}
            onRename={handleSectionRename}
            renamable
            {...foldableProps}
          />
        </SectionHeader>
        <SectionBodyFoldableContainer {...foldableProps}>
          {personalDetailsRows.slice(0, 4).map(row => renderRowItemsPerTemplateConfig(row))}

          {/* Additional fields */}
          <Foldable isOpen={areAdditionalFieldsVisible}>
            <AdditionalFields>
              {personalDetailsRows.slice(4).map(row => renderRowItemsPerTemplateConfig(row))}
            </AdditionalFields>
          </Foldable>

          {/* Additional fields expand button */}
          <ShowButton onClick={() => setAdditionalFieldsVisibility(value => !value)}>
            <ShowButtonText>
              {areAdditionalFieldsVisible
                ? i18n.t('builder.resume_editor.hide_details')
                : i18n.t('builder.resume_editor.show_details')}
            </ShowButtonText>
            <ShowButtonIcon data-open={areAdditionalFieldsVisible} />
          </ShowButton>

          <TunerSectionTooltips sectionId={SectionNames.details} />
        </SectionBodyFoldableContainer>
      </Container>
    </Fragment>
  )
}
