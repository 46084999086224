import { createAction, createReducer } from '@reduxjs/toolkit'
import type { Resume, CoverLetter } from '@rio/types'
import { RENDERERS } from 'builder/modules/constants'
import { Store } from 'builder/modules/store'
import { selectors as appSelectors } from 'builder/modules/init'
import { RenderingStore, DownloadTypes } from './types'

// ---

// Selectors
// ---

export const selectors = {
  renderer: (state: Store) => {
    const preferredRenderer = appSelectors.feature(state, 'renderer')
    if (preferredRenderer && preferredRenderer in RENDERERS) {
      return preferredRenderer
    }
    return RENDERERS.client
  },
  fallback: () => {
    console.info('SSR Mode')
    return RENDERERS.server
  },
  rdUpsellPromoModalVisible: (state: Store) => state.rendering.rdUpsellPromoModalVisible,
  showUpsellRdPromoModal: (state: Store) => state.rendering.showUpsellRdPromoModal,
  rdUpsellGetStartedModalVisible: (state: Store) => state.rendering.rdUpsellGetStartedModalVisible,
  rdUpsellModalVisible: (state: Store) => state.rendering.rdUpsellModalVisible,
  rdGotCreditsModalVisible: (state: Store) => state.rendering.rdGotCreditsModalVisible,
  rdNotEnoughCreditsModalVisible: (state: Store) => state.rendering.rdNotEnoughCreditsModalVisible,
  showAutoApplyUpsellModal: (state: Store) => state.rendering.showAutoApplyUpsellModal,
  isDocumentDownloading: (state: Store) => state.rendering.isDocumentDownloading,
  isPreviewGenerating: (state: Store) => state.rendering.isPreviewGenerating,
}

// ---
// Action creators
// ---
export const actions = {
  // Generate preview
  setPreviewGenerating: createAction<boolean>('builder/RENDERING_SET_PREVIEW_GENERATING'),
  fetchClientPreview: createAction<{ document: Resume | CoverLetter }>(
    'builder/RENDERING_FETCH_CLIENT_PREVIEW',
  ),
  fetchServerPreview: createAction<{ document: Resume | CoverLetter; page: number; size: number }>(
    'builder/RENDERING_FETCH_SERVER_PREVIEW',
  ),

  fetchPreviewDone: createAction('builder/RENDERING_FETCH_PREVIEW_DONE'),

  // Download document
  setDocumentDownloading: createAction<boolean>('builder/RENDERING_SET_DOCUMENT_DOWNLOADING'),
  download: createAction<DownloadTypes>('builder/RENDERING_DOWNLOAD'),
  downloadMergedDocuments: createAction<{
    resume: Resume
    coverLetter: CoverLetter
    showSnackBar?: boolean
  }>('builder/RENDERING_DOWNLOAD_MERGED_DOCUMENTS'),

  // Fallback rendering engine (client → server)
  fallback: createAction<{ reason?: string; message?: string }>('builder/RENDERING_FALLBACK'),
  fallbackComplete: createAction('builder/RENDERING_FALLBACK_COMPLETE'),

  // Check document compatibility with an active rendering engine
  checkDocument: createAction<Resume | CoverLetter>('builder/RENDERING_CHECK_DOCUMENT'),
  checkDocumentComplete: createAction('builder/RENDERING_CHECK_DOCUMENT_COMPLETE'),
  showRDUpsellPromoModal: createAction('builder/RENDERING_SHOW_RD_UPSELL_PROMO_MODAL'),
  closeRDUpsellPromoModal: createAction('builder/RENDERING_CLOSE_RD_UPSELL_PROMO_MODAL'),
  toggleRDUpsellPromoModal: createAction<boolean>('builder/RENDERING_TOGGLE_RD_UPSELL_PROMO_MODAL'),
  showRDUpsellGetStartedModal: createAction('builder/RENDERING_SHOW_RD_UPSELL_GET_STARTED_MODAL'),
  closeRDUpsellGetStartedModal: createAction('builder/RENDERING_CLOSE_RD_UPSELL_GET_STARTED_MODAL'),
  showRDUpsellModal: createAction('builder/RENDERING_SHOW_RD_UPSELL_MODAL'),
  closeRDUpsellModal: createAction('builder/RENDERING_CLOSE_RD_UPSELL_MODAL'),
  showRDGotCreditsModal: createAction('builder/RENDERING_SHOW_RD_GOT_CREDITS_MODAL'),
  closeRDGotCreditsModal: createAction('builder/RENDERING_CLOSE_RD_GOT_CREDITS_MODAL'),
  showRDNotEnoughCreditsModal: createAction('builder/RENDERING_SHOW_RD_NOT_ENOUGH_CREDITS_MODAL'),
  closeRDNotEnoughCreditsModal: createAction('builder/RENDERING_CLOSE_RD_NOT_ENOUGH_CREDITS_MODAL'),
  toggleAutoApplyUpsellModal: createAction<boolean>('builder/TOGGLE_AUTO_APPLY_UPSELL_MODAL'),
}

// ---
// Reducer
// ---
export const reducer = createReducer<RenderingStore>(
  {
    isDocumentDownloading: false,
    isPreviewGenerating: false,
    rdUpsellPromoModalVisible: false,
    showUpsellRdPromoModal: false,
    rdUpsellGetStartedModalVisible: false,
    rdUpsellModalVisible: false,
    rdGotCreditsModalVisible: false,
    rdNotEnoughCreditsModalVisible: false,
    showAutoApplyUpsellModal: false,
  },
  reducer => {
    reducer.addCase(actions.setPreviewGenerating, (draft, action) => {
      draft.isPreviewGenerating = action.payload
    })

    reducer.addCase(actions.setDocumentDownloading, (draft, action) => {
      draft.isDocumentDownloading = action.payload
    })

    reducer.addCase(actions.showRDUpsellPromoModal, draft => {
      draft.rdUpsellPromoModalVisible = true
    })

    reducer.addCase(actions.toggleRDUpsellPromoModal, (draft, action) => {
      draft.showUpsellRdPromoModal = action.payload
    })

    reducer.addCase(actions.closeRDUpsellPromoModal, draft => {
      draft.rdUpsellPromoModalVisible = false
    })

    reducer.addCase(actions.showRDUpsellGetStartedModal, draft => {
      draft.rdUpsellGetStartedModalVisible = true
    })

    reducer.addCase(actions.closeRDUpsellGetStartedModal, draft => {
      draft.rdUpsellGetStartedModalVisible = false
    })

    reducer.addCase(actions.showRDUpsellModal, draft => {
      draft.rdUpsellModalVisible = true
    })

    reducer.addCase(actions.closeRDUpsellModal, draft => {
      draft.rdUpsellModalVisible = false
    })

    reducer.addCase(actions.showRDGotCreditsModal, draft => {
      draft.rdGotCreditsModalVisible = true
    })

    reducer.addCase(actions.closeRDGotCreditsModal, draft => {
      draft.rdGotCreditsModalVisible = false
    })

    reducer.addCase(actions.showRDNotEnoughCreditsModal, draft => {
      draft.rdNotEnoughCreditsModalVisible = true
    })

    reducer.addCase(actions.closeRDNotEnoughCreditsModal, draft => {
      draft.rdNotEnoughCreditsModalVisible = false
    })

    reducer.addCase(actions.toggleAutoApplyUpsellModal, (draft, action) => {
      draft.showAutoApplyUpsellModal = action.payload
    })
  },
)
