import { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'

import { useConfig } from 'builder/hooks/useConfig'
import { ConfigScopesEnum, SignUpConfig } from 'builder/modules/init'

interface IProps {
  element: ReactElement
  feature: {
    name: keyof SignUpConfig['features']
    compareMethod?: 'is' | 'not'
  }
}

export const AuthRouterGuard = ({ element, feature }: IProps) => {
  const config = useConfig(ConfigScopesEnum.signUp)

  const isFeatureActive = () => {
    const signUpFeature = config?.features[feature.name]
    return feature.compareMethod === 'not' ? !signUpFeature : signUpFeature
  }

  return isFeatureActive() ? element : <Navigate to="/auth/sign-in" />
}
