import { formatSecondsToMMSS } from 'builder/utils/formatTime'
import { Icon20 } from 'builder/components/Icon'
import { Button, Container, Timer } from './styles'

interface Props {
  running: boolean
  seconds: number
  disabledButton: boolean
  onPause: () => void
  onResume: () => void
}

export const TimeDisplay = (props: Props) => {
  return (
    <Container>
      <Timer $isPaused={!props.running}>{formatSecondsToMMSS(props.seconds)}</Timer>

      <Button
        $isPaused={!props.running}
        onClick={props.disabledButton ? undefined : props.running ? props.onPause : props.onResume}
      >
        {props.running ? <Icon20.Pause /> : <Icon20.Play />}
      </Button>
    </Container>
  )
}
