import { ChangeEvent, memo } from 'react'
import { useDispatch } from 'react-redux'
import { i18n } from 'builder/utils/i18n'
import Toggle from 'builder/components/Toggle'
import {
  actions,
  selectors,
  UpdateSimpleFieldFunc,
  UpdateSimpleFieldPayload,
} from 'builder/modules/resumeEditor'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { ExtraContainer } from './styles'

export const ReferenceExtra = memo(() => {
  const dispatch = useDispatch()
  const resume = useTypedSelector(selectors.resume)
  const hideReferences = !!resume?.referencesUponRequest

  const updateSimpleField: UpdateSimpleFieldFunc = (name, value, debounce) => {
    dispatch(actions.updateSimpleField({ name, value, debounce } as UpdateSimpleFieldPayload))
  }

  return (
    <ExtraContainer>
      <Toggle
        label={i18n.t('builder.resume_editor.references_section.hide')}
        checked={hideReferences}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          updateSimpleField('referencesUponRequest', event.target.checked, false)
        }
        onClick={event => event.stopPropagation()}
      />
    </ExtraContainer>
  )
})
