import { useDispatch } from 'react-redux'
import { useI18n } from 'builder/hooks/useI18n'
import { InterviewPlayerModalType } from 'builder/modules/interview/types'
import { actions } from 'builder/modules/interview/interviewModule'

import { Modal } from 'builder/views/Interview/components/Modal/Modal'
import { AllGoodButton, HelpImage, Title } from './styles'
import Image from './assets/Image.svg'
const TRANSLATION_PREFIX = 'builder.interview_prep_view.video_mic_modal'

export const VideoMicModalHelp = () => {
  const { i18n } = useI18n()

  const dispatch = useDispatch()

  const onHelpClick = () => {
    dispatch(actions.setInterviewPlayerModal(InterviewPlayerModalType.permission_modal_initial))
  }

  const onClose = () => {
    dispatch(actions.setInterviewPlayerModal(InterviewPlayerModalType.permission_modal_initial))
  }

  return (
    <Modal
      onClose={onClose}
      width={352}
      title={<Title>{i18n.t(`${TRANSLATION_PREFIX}.help_title`)}</Title>}
      disableAnimation
    >
      <HelpImage src={Image} />
      {i18n.t(`${TRANSLATION_PREFIX}.help_content`)}
      <AllGoodButton onClick={onHelpClick}>{i18n.t(`${TRANSLATION_PREFIX}.dismiss`)}</AllGoodButton>
    </Modal>
  )
}
