import {
  ContentBlock,
  DraftBlockType,
  DraftEditorCommand,
  DraftHandleValue,
  EditorState,
} from 'draft-js'
import PluginEditor from '@draft-js-plugins/editor/lib/Editor'
import { Optional } from 'packages/types'
import { MouseClickEvent } from 'builder/modules/ui'
import { SectionNames } from 'builder/modules/resumeEditor'

export enum StyleTypes {
  Bold = 'BOLD',
  Italic = 'ITALIC',
  Underline = 'UNDERLINE',
  Strike = 'STRIKETHROUGH',
  OrderedList = 'ordered-list-item',
  UnorderedList = 'unordered-list-item',
}

export type SuggestionScope = 'profile' | 'workExperience'

export type SuggestionConfig = {
  mode: 'phrases'
  scope: SuggestionScope
  wide?: boolean
  onOpenAiProfileButtonClick?: () => void
  onOpenSuggestionVariants?: () => void
  keywordsConfig?: Optional<{
    section: SectionNames
    buttonText: string
  }>
}

export type IncorrectWord = {
  word: string
  suggestions: string[]
}

export type DecoratorCallback = (start: number, end: number) => void

export type Ranges = {
  bad: {
    start: number
    end: number
  }
  good: {
    start: number
    end: number
  }
}

export type SpellCheckStrategy = (contentBlock: ContentBlock, callback: DecoratorCallback) => void

export type SpellCheckStore = {
  getEditorState: (() => EditorState) | undefined
  setEditorState: ((state: EditorState) => void) | undefined
}

export type SpellCheckSuggestion = {
  wordId: string
  word: string
  position: {
    top: number
    left: number
  }
  wordStartIndex: number
  wordEndIndex: number
  blockKey: string
}

export type RichTextAreaActions = {
  editorId: React.MutableRefObject<string>
  editorRef: React.RefObject<PluginEditor>
  animationPlaceholderRef: React.RefObject<HTMLTextAreaElement>
  editorState: EditorState
  isHintsOpened: boolean
  isSuggestionsOpen: boolean

  isFocused: boolean
  isSpellCheckerEnabled: boolean
  isSpellCheckerPending: boolean
  isSpellingChecked: boolean
  suggestionQuery: string
  textLength: number
  shouldHidePlaceholder: boolean
  focusEditor: () => void
  onChangeSuggestionQuery: (text: string) => void
  handleChange: (editorStateProp: EditorState) => void
  handleOpenHints: () => void
  handleCloseHints: () => void
  handleToggleInline: (inlineStyle: StyleTypes) => void
  handleToggleBlock: (blockType: DraftBlockType) => void
  handleToggleSpellChecker: () => void
  handleFocus: () => void
  handleBlur: () => void
  handleKeyBinding: (e: React.KeyboardEvent<Element>) => Optional<string>
  handleKeyCommand: (command: DraftEditorCommand, editorState: EditorState) => DraftHandleValue
  supportsLocale: (locale: string) => boolean
  toggleSuggestions: (state: { isOpen?: boolean; trigger?: string }) => void
  toggleAiProfileSummary: (state: { isOpen?: boolean; trigger?: string }) => void
  toggleAiProfileSummaryResult: (state: { isOpen?: boolean }) => void
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>
  isSuggestionVariantsOpen: boolean
  toggleSuggestionVariants: (newState?: { isOpen?: boolean }) => void
}

export type ToolbarActions = {
  addPhrase: (item: { phrase: string; isSubphrase: boolean }) => void
  replacePhrase: (phrase: string) => void
  handleClickBlock: (event: MouseClickEvent, type: StyleTypes) => void
  handleClickLink: (event: MouseClickEvent) => void
  handleClickMark: (event: MouseClickEvent, type: StyleTypes) => void
  handleClickSpellCheck: () => void
}

export type RichTextEditorInstanceWrapper = {
  focusEditor: () => void
}

export interface TextAreaProps {
  instanceRef?: React.MutableRefObject<RichTextEditorInstanceWrapper | null>
  autoFocus?: boolean
  recommendedLength?: number
  jobTitle?: Optional<string>
  suggestionConfig?: Optional<SuggestionConfig>
  label?: string
  placeholder?: string
  value?: string
  onChange: (html: string) => void
  locale?: string
  height?: number
  labelBadges?: JSX.Element[]
  supportedLocales?: string[]
  boldButtonDisable?: boolean
  italicButtonDisable?: boolean
  linkButtonDisable?: boolean
  disableToolbar?: boolean
  ranges?: Ranges
  recommendedRangeLabel?: string
  isAnimating?: boolean
  onAnimationEnd?: () => void
  onTextLengthChange?: (length: number) => void
}
