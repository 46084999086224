import styled from 'styled-components'
import Sizes from 'builder/styles/sizes'

export const Wrapper = styled.div`
  width: 48px;
  height: 48px;
  box-shadow: 0px 2px 4px 0px rgba(15, 56, 113, 0.08), 0px 1px 2px 0px rgba(15, 56, 113, 0.12),
    0px 0px 1px 0px rgba(15, 56, 113, 0.32);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${Sizes.XS};
  img {
    width: 40px;
    height: 40px;
    border-radius: ${Sizes.XS};
  }
`
