import { useI18n } from 'builder/hooks/useI18n'

import { LoadingScreen } from './LoadingScreen'

export const TRANSLATION_PREFIX =
  'builder.interview_prep_view.interview_player_v2.interrupted_loading_modal'

export const InterruptedLoading = () => {
  const { i18n } = useI18n()

  return (
    <div>
      <LoadingScreen
        content={{
          title: i18n.t(`${TRANSLATION_PREFIX}.title`),
          content: i18n.t(`${TRANSLATION_PREFIX}.subtitle`),
          customContentWidth: 500,
        }}
      />
    </div>
  )
}
