import styled from 'styled-components'
import { animated } from '@react-spring/web'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const SuggestionContainer = styled.div`
  display: block;
  user-select: none;
  position: sticky;
  top: 0px;
  background-color: ${Colors.White};
  z-index: 2;

  ${Media.Phone`
    &:before,
    &:after {
      width: 20px;
    }
  `};

  &:before {
    right: 100%;
  }

  &:after {
    left: 100%;
  }
`

export const PercentSuggestion = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-content: center;
  ${Typography.Caption};
  font-size: 14px;
  font-weight: 400;
  color: ${Colors.Green60};
  background-color: ${Colors.Green10};
  font-variant-numeric: tabular-nums;
  border-radius: 4px;
  min-width: 32px;
  &:after {
    content: '%';
  }
`

export const SuggestionList = styled.div`
  display: flex;
  flex-flow: row wrap;

  ${Media.Phone`
    gap: 16px;
  `};
`

export const Suggestion = styled.div`
  flex: 0 0 50%;
  display: flex;
  gap: 8px;
  padding: 6px 0;
  cursor: pointer;
  width: 100%;

  ${Typography.Caption};

  ${Media.Phone`
    flex: 0 0 100%;
    padding: 0;
  `};

  &:hover {
    color: ${Colors.Blue50};
  }

  &:hover ${PercentSuggestion} {
    color: ${Colors.Blue50};
  }
`
