import { TailorResumeBanner } from 'builder/components/ImproveResumePanel'
import { useTailoringBannerTriggerConfig } from 'builder/hooks/useTailoringBannerTriggerConfig'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/resumeEditor'

export const WorkExperienceTailorResumeBanner = () => {
  const { bannerTriggeredFrom } = useTypedSelector(selectors.optimizerSection)
  const { isBannerTriggerAvailable } = useTailoringBannerTriggerConfig()
  const isVisible = !!isBannerTriggerAvailable && bannerTriggeredFrom === 'workExperiences'
  return <TailorResumeBanner isVisible={isVisible} />
}
