import styled, { css } from 'styled-components'

import Media from 'builder/styles/media'

import Colors from 'builder/styles/colors'
import { Button } from 'builder/components/Button'
import {
  Cards,
  PromotionTopic,
} from 'builder/components/SubscriptionCancellationFormNew/common/types'
import Typography, { FontWeights } from 'builder/styles/typography'
import { getLineHeightRatio } from 'builder/components/SubscriptionCancellationFormNew/common/utils'
import usePromotionCardValues from 'builder/components/SubscriptionCancellationFormNew/hooks/usePromotionCardValues'

export const PromotionLinksV7 = styled(Button)<{
  topic: PromotionTopic
  isActive: boolean
}>(({ topic, isActive }) => {
  const { getCardStyles } = usePromotionCardValues()
  const cardStyles = getCardStyles(topic)
  const phoneViewHeight = [Cards.FeatureOrProblem, Cards.OtherReasons].includes(topic as Cards)
    ? '36px'
    : '48px'

  return css`
    ${Typography.ButtonM}
    ${FontWeights.Regular}
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: start;
    line-height: ${getLineHeightRatio(22, 18)};
    color: ${Colors.Neutral90};
    background-color: ${isActive ? Colors.Blue10 : 'transparent'};
    border-color: ${isActive ? Colors.Blue20 : 'transparent'};
    border-width: ${isActive ? '1px' : '0px'};
    border-style: solid;
    border-radius: 4px;
    border-style: solid;
    height: 48px;
    width: 436px;
    padding-left: 12px;
    padding-right: 24px;

    svg {
      margin-right: 12px;
    }

    &:hover {
      background-color: ${cardStyles.buttonHoverColor};
    }

    ${Media.Tablet`
        width: 476px;
        padding-left: unset !important;
        padding-right: unset !important;

    `}

    ${Media.Phone`
        height: ${phoneViewHeight};
        font-size: 15px;
        line-height: 18px;
        width: 100%;
    `}
  `
})
