import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import Media from 'builder/styles/media'

export const Description = styled.div`
  a {
    color: ${Colors.Blue50};
    font-weight: 500;
  }
  display: flex;
  color: ${Colors.Neutral50};
  ${Typography.Body}
  margin-top:  ${Sizes.S};
`

export const ContentWrapper = styled.div`
  position: relative;
`

export const NoResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.White};
  padding: 48px 0px;
  text-align: center;
  ${Media.Phone`
    padding: 32px 0;
  `}
`

export const MissingInfoWrapper = styled.div<{ isSliderActive?: boolean }>`
  width: 268px;
  height: ${({ isSliderActive }) => isSliderActive && '100%'};
`
