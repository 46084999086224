import { createSelector } from '@reduxjs/toolkit'
import { Store } from '../store'
import { getScoreSuggestions } from './resumeScoreOracle'
import { ResumeOpenedCard } from './types'

const wizardStepsSelector = (state: Store) => state.resumeEditor.wizardSteps
const currentWizardStepIdSelector = (state: Store) => state.resumeEditor.currentWizardStepId
const keywordsForAutoTailoringSelector = createSelector(
  (state: Store) => state.resumeEditor.autoTailoring.keywordsMap,
  keywordsMap => {
    return Object.entries(keywordsMap).map(([keyword, isAdded]) => ({ keyword, isAdded }))
  },
)
const isCardOpenSelector = createSelector(
  (state: Store) => state.resumeEditor.openedCard,
  (state: Store, cardToCheck: ResumeOpenedCard) => cardToCheck,
  (openedCard, cardToCheck) =>
    !!openedCard &&
    openedCard.id === cardToCheck.id &&
    openedCard.sectionId === cardToCheck.sectionId,
)

export const selectors = {
  isResumeLoading: (state: Store) => state.resumeEditor.isLoading,
  resume: (state: Store) => state.resumeEditor.resume,
  templates: (state: Store) => state.generalEditor.resumeTemplates,
  resumeScore: (state: Store) => state.resumeEditor.resume?.resumeScore,
  scoreSuggestions: (state: Store) => getScoreSuggestions(state.resumeEditor.resume),
  wizardSteps: wizardStepsSelector,
  currentWizardStep: createSelector(
    currentWizardStepIdSelector,
    wizardStepsSelector,
    (currentWizardStepId, wizardSteps) =>
      wizardSteps.find(step => step.id === currentWizardStepId) || wizardSteps[0],
  ),
  currentWizardStepId: currentWizardStepIdSelector,
  openedCard: (state: Store) => state.resumeEditor.openedCard,
  openedSection: (state: Store) =>
    state.resumeEditor.openedSection || state.resumeEditor.openedCard?.sectionId || null,
  isCardOpen: isCardOpenSelector,
  prefill: (state: Store) => state.resumeEditor.prefill,
  country: (state: Store) => state.application.configs.country,
  isJobPostingOpen: (state: Store) => state.resumeEditor.isOptimizerModalOpen,
  isFromJobTracker: (state: Store) => state.resumeEditor.isFromJobTracker,
  isOpenSavingInfoBanner: (state: Store) => state.resumeEditor.isOpenSavingInfoBanner,
  jobPostingAPIData: (state: Store) => state.resumeEditor.optimizerSection.jobPostingAPIData,
  saveJobPostingDataStatus: (state: Store) =>
    state.resumeEditor.optimizerSection.saveJobPostingDataStatus,
  linkedInData: (state: Store) => state.resumeEditor.aiResume.linkedInData,
  questionnaireData: (state: Store) => state.resumeEditor.aiResume.questionnaireData,
  resumeScoreAPIData: (state: Store) => state.resumeEditor.optimizerSection.resumeScoreAPIData,
  tailoredJobPostingId: (state: Store) =>
    state.resumeEditor.optimizerSection.jobPostingAPIData.jobPostingId,
  optimizerSection: (state: Store) => state.resumeEditor.optimizerSection,
  locale: (state: Store) => state.resumeEditor.resume?.locale,
  iso_language_code: (state: Store) => {
    let locale = state.resumeEditor.resume?.locale
    switch (locale) {
      // Map the resume locale to the correct ISO 639 locale code for certain APIs
      case 'cs':
        locale = 'cz'
        break
      case 'da':
        locale = 'dk'
        break
      default:
        // Keep the original value of locale
        break
    }
    return locale
  },
  openedAISuggestionsPopup: (state: Store) => state.resumeEditor.openedAISuggestionsPopup,
  aiProfile: (state: Store) => state.resumeEditor.aiProfile,
  highlightedElementData: (state: Store) => state.resumeEditor.highlightedElementData,
  openedKeywordsModalType: (state: Store) => state.resumeEditor.openedKeywordsModalType,
  isImproveResumePanelOpen: (state: Store) => state.resumeEditor.improveResumePanel.isOpen,
  isImproveResumeBadgeAnimating: (state: Store) =>
    state.resumeEditor.improveResumePanel.isBadgeAnimating,
  isHelpWithWritingOpen: (state: Store) => state.resumeEditor.isHelpWithWritingOpen,
  isAIProfileSummaryResultOpen: (state: Store) => state.resumeEditor.isAIProfileSummaryResultOpen,
  autoTailoringOpenedModal: (state: Store) => state.resumeEditor.autoTailoring.openedModal,
  isAutoTailoringApproveModalOpen: (state: Store) =>
    state.resumeEditor.autoTailoring.isApproveModalOpen,
  autoTailoringFetchStatus: (state: Store) => state.resumeEditor.autoTailoring.fetchStatus,
  autoTailoredResume: (state: Store) => state.resumeEditor.autoTailoring.autoTailoredResume,
  originalResume: (state: Store) => state.resumeEditor.originalResume,
  jobPostingDataForAutoTailoring: (state: Store) => state.resumeEditor.autoTailoring.jobPostingData,
  keywordsForAutoTailoring: keywordsForAutoTailoringSelector,
  addedKeywordsForAutoTailoring: createSelector(keywordsForAutoTailoringSelector, keywords =>
    keywords.filter(item => item.isAdded).map(item => item.keyword),
  ),
  approveAutoTailoredResumeStatus: (state: Store) =>
    state.resumeEditor.autoTailoring.autoTailoredResumeApproveStatus,
  resumeEventTrackedSections: (state: Store) => state.resumeEditor.resumeEventTrackedSections,
  resumeDownloadData: (state: Store) => state.resumeEditor.resumeDownloadData,
  isFeaturingAutoTailorModalOpenOnDownload: (state: Store) =>
    state.resumeEditor.autoTailoring.isFeaturingAutoTailorModalOpenOnDownload,
  isSyncing: (state: Store) => state.resumeEditor.isSyncing,
  isPageScrolled: (state: Store) => state.resumeEditor.isPageScrolled,
}
