import { apiClient, ApiResponse } from 'organizations/utils/apiClient'
import { Dispatch, Store, navigate } from 'organizations/store'
import { RequestStatuses } from 'organizations/constants'
import { Invite } from './types'
import { actions } from './slice'

type FetchResponse = ApiResponse<{
  invites: Invite[]
  page: number
  total: number
  perPage: number
}>

type CreateResponse = ApiResponse<{
  invite: Invite
}>

interface NewInviteInput {
  email: string
  firstName?: string
  lastName?: string
}

/**
 * Gets a paginated list of users from the API
 */
export const fetchPage =
  (page = 1) =>
  async (dispatch: Dispatch) => {
    dispatch(actions.setFetchStatus(RequestStatuses.loading))

    const params = { page, perPage: 10, status: 'pending' }
    const { data } = await apiClient.get<FetchResponse>('/invites/', { params })
    const { invites, total, perPage } = data
    const pageCount = Math.ceil(total / perPage)

    dispatch(actions.setList(invites))
    dispatch(actions.setPagination({ page, pageCount }))
    dispatch(actions.setFetchStatus(RequestStatuses.complete))
  }

/**
 * Send an invitation using the given input (email, first and last name)
 */
export const createInvite =
  (input: NewInviteInput) => async (dispatch: Dispatch, getState: () => Store) => {
    dispatch(actions.setCreatingStatus(RequestStatuses.loading))

    const { data } = await apiClient.post<CreateResponse>('/invites/', input)

    if (data.success) {
      // Update and show invites list
      await dispatch(fetchPage(getState().invites.page))
      navigate('/invites', { replace: true })
      // Reset modal state
      dispatch(actions.setCreatingStatus(RequestStatuses.notAsked))
      dispatch(actions.setCreatingError(null))
      dispatch(actions.toggleCreatingModal(false))
    } else {
      dispatch(actions.setCreatingStatus(RequestStatuses.failed))
      dispatch(actions.setCreatingError(data.errorMessage))
    }
  }

/**
 * Changes status of the invitation from "pending" to "cancelled" by ID
 */
export const cancelInvite = (id: number) => async (dispatch: Dispatch, getState: () => Store) => {
  // Cancel invite via API
  await apiClient.post(`/invites/${id}/cancel`)

  // Refresh the invites list.
  let { list, page } = getState().invites
  const shouldSwitchPage = list.length <= 1 && page > 1 // it is the last invite on 2nd+ page?
  await dispatch(fetchPage(shouldSwitchPage ? page - 1 : page))
}
