import type { Optional } from '@rio/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequestStatuses } from 'organizations/constants'
import { InvitesStore, Invite } from './types'

const initialState: InvitesStore = {
  // Pending invitations table
  list: [],
  fetchStatus: RequestStatuses.notAsked,
  page: 1,
  pageCount: 0,
  // Creating modal
  creatingModalOpen: false,
  creatingStatus: RequestStatuses.notAsked,
  creatingError: null,
}

const { reducer, actions } = createSlice({
  name: 'invites',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<Invite[]>) => {
      state.list = action.payload
    },
    setFetchStatus: (state, action: PayloadAction<RequestStatuses>) => {
      state.fetchStatus = action.payload
    },
    setPagination: (state, action: PayloadAction<{ page: number; pageCount: number }>) => {
      state.page = action.payload.page
      state.pageCount = action.payload.pageCount
    },
    setCreatingStatus: (state, action: PayloadAction<RequestStatuses>) => {
      state.creatingStatus = action.payload
    },
    setCreatingError: (state, action: PayloadAction<Optional<string>>) => {
      state.creatingError = action.payload
    },
    toggleCreatingModal: (state, action: PayloadAction<boolean>) => {
      state.creatingModalOpen = action.payload
    },
  },
})

export { reducer, actions }
