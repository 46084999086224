import { useI18n } from 'builder/hooks/useI18n'

import { Plans, TRANSLATION } from '../constants'
import {
  Container,
  CreditBadge,
  CreditBox,
  CreditCount,
  CreditsContainer,
  CreditText,
} from './styles'

type Props = {
  selectedPlan: Plans
  onSelectPlan: (plan: Plans) => void
}

export const CreditsList = ({ selectedPlan, onSelectPlan }: Props) => {
  const { i18n } = useI18n()

  return (
    <Container>
      <CreditsContainer>
        <CreditBox
          onClick={() => onSelectPlan(Plans.CREDITS_10)}
          $isSelected={selectedPlan === Plans.CREDITS_10}
          $boxPosition="left"
          $color="indigo"
        >
          <CreditBadge $color="indigo">{i18n.t(`${TRANSLATION}.credits_10_badge`)}</CreditBadge>
          <CreditCount>10</CreditCount>
          <CreditText>{i18n.t(`${TRANSLATION}.credits_title`)}</CreditText>
        </CreditBox>
        <CreditBox
          onClick={() => onSelectPlan(Plans.CREDITS_20)}
          $isSelected={selectedPlan === Plans.CREDITS_20}
          $boxPosition="center"
          $color="green"
        >
          <CreditBadge $color="green">{i18n.t(`${TRANSLATION}.credits_20_badge`)}</CreditBadge>
          <CreditCount>20</CreditCount>
          <CreditText>{i18n.t(`${TRANSLATION}.credits_title`)}</CreditText>
        </CreditBox>
        <CreditBox
          onClick={() => onSelectPlan(Plans.CREDITS_50)}
          $isSelected={selectedPlan === Plans.CREDITS_50}
          $boxPosition="right"
          $color="indigo"
        >
          <CreditBadge $color="indigo">{i18n.t(`${TRANSLATION}.credits_50_badge`)}</CreditBadge>
          <CreditCount>50</CreditCount>
          <CreditText>{i18n.t(`${TRANSLATION}.credits_title`)}</CreditText>
        </CreditBox>
      </CreditsContainer>
    </Container>
  )
}
