import { Header } from '../Header/Header'
import { IProps } from '../ResumeSideMenu/types'

export const ResumeSideMenuGuest = ({
  isOnline,
  builderType,
  onClick,
}: IProps & { onClick: () => void }) => {
  return (
    <Header
      isOnline={isOnline}
      builderType={builderType}
      isOpen={false}
      setOpen={onClick}
      handleDashboardOnClick={onClick}
    />
  )
}
