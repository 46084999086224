import { getMediaRecorder } from './getMediaRecorder'

// Recording something to determine the MIME type used, we can suggest a MIME type,
// but the browser does not guarantee that it will be respected
export function getMimeType(stream: MediaStream, media: 'audio' | 'video'): Promise<string> {
  return new Promise(resolve => {
    const mediaRecorder = getMediaRecorder(stream, media)
    mediaRecorder.ondataavailable = event => resolve(event.data.type)
    mediaRecorder.start()
    setTimeout(() => mediaRecorder.stop(), 200)
  })
}
