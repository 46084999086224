import {
  actions as editorActions,
  selectors as editorSelectors,
  actions,
} from 'builder/modules/resumeEditor'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { sortableContainer } from 'react-sortable-hoc'
import { generateRandomId } from 'builder/utils/generateRandomId'
import { SortableSection, groupSectionsBy } from 'builder/components/SectionsList'
import { SectionsListContainer } from 'builder/components/SectionsList/styles'
import { getJapaneseSectionsList } from './JapaneseSections'

const Droppable = sortableContainer(({ children }) => <div>{children}</div>)

class JapaneseSectionsList extends Component {
  static propTypes = {
    resume: PropTypes.object.isRequired,
    updateCard: PropTypes.func.isRequired,
    deleteCard: PropTypes.func.isRequired,
    addCard: PropTypes.func.isRequired,
    moveCard: PropTypes.func.isRequired,
    moveSection: PropTypes.func.isRequired,
    levels: PropTypes.object,
    renameSection: PropTypes.func.isRequired,
    deleteSection: PropTypes.func.isRequired,
    openCard: PropTypes.func.isRequired,
    openedCard: PropTypes.object,
  }

  state = {
    draggableId: null,
  }

  handleCardAdd = (sectionId, options) => {
    const cardId = generateRandomId()
    const { scrollIntoViewport = false } = options || {}
    this.props.addCard(cardId, sectionId, { scrollIntoViewport, shouldOpen: true })
  }

  handleCardToggle = (cardId, sectionId) => {
    const isOpen = this.isCardOpen(cardId, sectionId)
    this.props.openCard(isOpen ? null : { id: cardId, sectionId })
  }

  isCardOpen = (cardId, sectionId) => {
    if (this.state.isDragging || !this.props.openedCard) return false

    return this.props.openedCard.id === cardId && this.props.openedCard.sectionId === sectionId
  }

  preventSelection = e => {
    e.preventDefault()
    return false
  }

  handleSortStart = ({ node }) => {
    // Fix cursor
    document.addEventListener('selectstart', this.preventSelection)

    const { id } = node.dataset
    return this.setState({ draggableId: id })
  }

  handleSectionMove = ({ oldIndex, newIndex }) => {
    document.removeEventListener('selectstart', this.preventSelection)

    this.props.moveSection({
      offset: newIndex - oldIndex,
      sectionId: this.state.draggableId,
    })

    this.setState({ draggableId: null })
  }

  render() {
    const { resume, levels, renameSection, deleteSection, moveCard, deleteCard, updateCard } =
      this.props
    const { hideSkillLevel, locale } = resume
    const { draggableId } = this.state

    const sections = getJapaneseSectionsList(resume)
    const groups = groupSectionsBy(sections, 'sortable')

    return (
      <SectionsListContainer>
        {groups.map((group, groupIndex) => {
          const content = group.sections.map((section, sectionIndex) => (
            <SortableSection
              item={section}
              key={section.id}
              index={sectionIndex}
              isDragging={draggableId === section.sortId || draggableId === section.id}
              section={section}
              levels={levels}
              hideSkillLevel={hideSkillLevel}
              locale={locale}
              onRename={renameSection}
              onDelete={deleteSection}
              onAdd={this.handleCardAdd}
              isCardOpen={this.isCardOpen}
              onCardChange={updateCard}
              onCardDelete={deleteCard}
              onCardMove={moveCard}
              onCardToggle={this.handleCardToggle}
            />
          ))

          if (!group.sortable) return <div key={groupIndex}>{content}</div>

          return (
            <Droppable
              key={groupIndex}
              updateBeforeSortStart={this.handleSortStart}
              onSortEnd={this.handleSectionMove}
              useWindowAsScrollContainer
              useDragHandle
            >
              {content}
            </Droppable>
          )
        })}
      </SectionsListContainer>
    )
  }
}

const mapStateToProps = state => ({
  openedCard: editorSelectors.openedCard(state),
})

const mapDispatchToProps = dispatch => {
  return {
    openCard: payload => dispatch(editorActions.openCard(payload)),
    updateCard: (sectionId, cardId, values, debounce, isCustom) =>
      dispatch(actions.updateCard({ sectionId, cardId, values, debounce, isCustom })),
    addCard: (cardId, sectionName, options) =>
      dispatch(actions.addCard({ sectionName, cardId, options })),
    deleteCard: (cardId, sectionName, isCustom = false) =>
      dispatch(actions.deleteCard({ sectionName, cardId, isCustom })),
    moveCard: payload => dispatch(actions.moveCard(payload)),
    moveSection: payload => dispatch(actions.moveSection(payload)),
    renameSection: payload => dispatch(actions.renameSection({ ...payload, debounce: true })),
    deleteSection: (id, options) => dispatch(actions.deleteSection({ id, options })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(JapaneseSectionsList)
