import styled, { css } from 'styled-components'
import Sizes from 'builder/styles/sizes'
import { foldableSectionMixin, WithFoldableSections } from '../Section'

export const SectionContainer = styled.div<WithFoldableSections>(props => {
  return css`
    position: relative;

    ${props.isFoldableSectionsEnabled
      ? foldableSectionMixin
      : css`
          margin-bottom: 40px;
        `}
  `
})

export const SectionContent = styled.div``

export const BannerContainer = styled.div`
  margin-top: ${Sizes.S};
`
