import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useRef } from 'react'

export enum UserMediaType {
  audio = 'audio',
  video = 'video',
}

const getConfig = (media: UserMediaType): MediaStreamConstraints => {
  switch (media) {
    case UserMediaType.audio:
      return { audio: true, video: false }
    case UserMediaType.video:
      return {
        video: {
          width: { ideal: 1080 }, // Reduce video resolution
          height: { ideal: 720 },
          frameRate: { ideal: 24 }, // Reduce frame rate
          facingMode: { ideal: 'user' }, // on mobile choose the self webcam
        },
        audio: true,
      }
  }
}

export const useGetStream = (media: UserMediaType) => {
  const queryKey = ['useGetStream', media]

  const realStreamRef = useRef<MediaStream>()
  const queryClient = useQueryClient()

  const forceStreamToBeStopped = useCallback(async (stream: MediaStream) => {
    for (const track of stream.getTracks()) {
      track.stop()
    }
  }, [])

  const disableStream = useCallback(async () => {
    if (realStreamRef.current) {
      forceStreamToBeStopped(realStreamRef.current)
    }
    queryClient.setQueryData(queryKey, null)
  }, [queryKey, queryClient])

  const {
    data: realStream,
    isLoading: isGettingPermission,
    refetch: askForPermission,
  } = useQuery({
    queryKey,
    queryFn: async (): Promise<MediaStream | null> => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(getConfig(media))
        if (realStreamRef.current) {
          forceStreamToBeStopped(realStreamRef.current)
        }
        realStreamRef.current = stream
        stream.getTracks().forEach(track => {
          track.addEventListener('ended', disableStream)
        })

        return stream
      } catch (error) {
        console.error(error)
        return null
      }
    },
    enabled: false,
    staleTime: Infinity,
    retry: 0,
  })

  const handleStream = () => {
    if (realStream && realStream.active === false) {
      return undefined
    }
    return realStream
  }
  return {
    stream: handleStream(),
    isGettingPermission,
    askForPermission,
    disableStream,
  }
}
