import styled, { css } from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div<{ $isPrimary: boolean }>`
  display: inline-flex;
  background-color: ${Colors.Neutral10};
  border-radius: ${Sizes['3XL']};
  padding: ${Sizes.XS} ${Sizes.S};
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral100};
  gap: 10px;
  height: 50px;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  &:hover {
    background-color: ${Colors.Neutral15};
  }

  ${({ $isPrimary }) =>
    $isPrimary &&
    css`
      background-color: ${Colors.Blue10};
      color: ${Colors.Blue50};

      &:hover {
        background-color: ${Colors.Blue20};
      }
    `}
`
