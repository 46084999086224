import { Component } from 'react'
import { sortableContainer } from 'react-sortable-hoc'
import { SortableSection } from './SortableSection'
import AdditionalSections from 'builder/components/AdditionalSections'
import constructSectionsDataGuest from './constructSectionsDataGuest'
import { groupSectionsBy } from './constructSectionsData'

import { SectionsListContainer } from './styles'
import { displaySectionPerTemplateConfig } from '../DocumentEditor/ATSResumeEditor/builderSectionsConfig'
import { GuestResumeContext } from 'builder/context/GuestResumeContext'

export const DroppableSectionContainer = sortableContainer(({ children }) => <div>{children}</div>)

class NewSectionsListGuest extends Component {
  static contextType = GuestResumeContext

  render() {
    const { resume, toggleEmailRequiredError: onClick } = this.context
    const { locale = 'en', template = 'stockholm' } = resume

    const sections = constructSectionsDataGuest(template)
    const filteredSections = sections.filter(section =>
      displaySectionPerTemplateConfig(template, section.id),
    )
    const groups = groupSectionsBy(filteredSections, 'sortable')

    return (
      <SectionsListContainer>
        {groups.map((group, groupIndex) => {
          const content = group.sections.map((section, sectionIndex) => (
            <SortableSection
              key={section.id}
              index={sectionIndex}
              isDragging={false}
              item={section}
              levels={{}}
              skills={[]}
              position=""
              hideSkillLevel
              locale={locale}
              onRename={onClick}
              onDelete={onClick}
              onAdd={onClick}
              isCardOpen={() => {}}
              onCardChange={onClick}
              onCardDelete={onClick}
              onCardMove={onClick}
              onCardToggle={onClick}
              template={template}
              updateSimpleField={() => {}}
            />
          ))

          if (!group.sortable) return <div key={groupIndex}>{content}</div>

          return (
            <DroppableSectionContainer
              key={groupIndex}
              updateBeforeSortStart={onClick}
              onSortEnd={onClick}
              useWindowAsScrollContainer
              useDragHandle
            >
              {content}
            </DroppableSectionContainer>
          )
        })}
        <AdditionalSections sections={filteredSections} onAdd={onClick} onAddCustom={onClick} />
      </SectionsListContainer>
    )
  }
}

export default NewSectionsListGuest
