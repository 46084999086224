import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const JOB_SUMMARY_HEIGHT = 568
export const Container = styled.div`
  position: relative;
`

export const Body = styled.div<{ isHTMLContent: boolean }>`
  ${Typography.Body}
  color: ${Colors.Neutral60};
  ${({ isHTMLContent }) => !isHTMLContent && `white-space: pre-line;`}

  b,
  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${FontWeights.DemiBold}
    color: ${Colors.Neutral90};

    ${Media.Tablet`
      ${Typography.Subhead}
    `}
  }

  p {
    margin: 12px 0px;

    ${Media.Tablet`
      ${Typography.Subhead}
    `}
  }

  li {
    ${Media.Tablet`
      ${Typography.Subhead}
    `}
  }
`
