import { Interview } from 'builder/modules/interview/types'

function getNumberOfMainQuestionOfInterviewV1(interview: Interview) {
  switch (interview.kind) {
    case 'custom':
      return 8
    case 'one_question':
    case 'test_interview':
      return 1
    default:
      throw new Error(
        `Interview must have kind. interviewId:${interview.id} kind:${interview.kind}`,
      )
  }
}

function getNumberOfMainQuestionOfInterviewV2(interview: Interview) {
  switch (interview.kind) {
    case 'custom':
      return 3
    case 'one_question':
    case 'test_interview':
      return 1
    default:
      throw new Error(
        `Interview must have kind. interviewId:${interview.id} kind:${interview.kind}`,
      )
  }
}

export function getNumberOfQuestionOfInterview(interview: Interview | undefined) {
  // No follow up questions
  if (interview?.version === 1) {
    const maxMainQuestions = getNumberOfMainQuestionOfInterviewV1(interview)
    return {
      maxMainQuestions: maxMainQuestions,
      totalQuestions: maxMainQuestions,
    }
  }
  // Has follow up questions
  if (interview?.version === 2) {
    const maxMainQuestions = getNumberOfMainQuestionOfInterviewV2(interview)
    const mainQuestionCount = 1
    const followUpQuestionCount = 2
    return {
      maxMainQuestions: maxMainQuestions,
      totalQuestions: maxMainQuestions * (mainQuestionCount + followUpQuestionCount),
    }
  }

  return null
}
